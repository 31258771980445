import React, { useState, useEffect, useRef } from 'react';
import cls from 'classname';
import { Row, Col, Spin, Checkbox, Divider } from 'antd';
import Search from '../../../../Components/Search';
import utils from '../../../../Utils/utils';

export default function BDModal({
  handleSearch,
  icons,
  getBdNext,
  fetching,
  next,
  setSearchText,
  searchText,
  iconsBtn,
  setIconsBtn,
  getBd,
  drop_id,
  product_id,
  saveInfoData,
  b_and_d,
}) {
  const iconsRef = useRef();
  const [bd, setBd] = useState({ benefits: [], drawbacks: [] });

  const benefitsList = icons?.filter(
    (el) => el._type === 'Benefits' && !el?.selected,
  );
  const drawbacksList = icons?.filter(
    (el) => el._type === 'Drawbacks' && !el?.selected,
  );
  const iconList = iconsBtn === 'benefits' ? benefitsList : drawbacksList;

  const b_and_dLength = {
    benefits: b_and_d?.filter((el) => el._type === 'Benefits')?.length || 0,
    drawbacks: b_and_d?.filter((el) => el._type === 'Drawbacks')?.length || 0,
  };
  const commonLength = b_and_dLength[iconsBtn];

  function disabledCheckBox(id) {
    return (
      !bd?.[iconsBtn]?.includes(id) &&
      bd?.[iconsBtn]?.length >= 6 - commonLength
    );
  }

  useEffect(() => {
    const bdContent = iconsRef?.current;

    if (bdContent) {
      bdContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      bdContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next, fetching]);

  useEffect(() => {
    scrollToTop();
  }, [searchText, iconsBtn]);

  useEffect(() => {
    const iconsCheckedList = bd?.benefits.concat(bd?.drawbacks);
    saveInfoData(iconsCheckedList);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [bd]);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll * 0.95 && !fetching && !!next) {
      getBdNext(next);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  function scrollToTop() {
    const bdContent = iconsRef?.current;
    if (bdContent) {
      bdContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
  function changeButton(name) {
    setIconsBtn(name);
    setSearchText('');
    getBd({
      drop_id,
      product_id,
      ...(iconsBtn && { _type: name === 'benefits' ? '1' : '2' }),
      page_size: 20,
    });
  }

  function onChange(checkedValues) {
    iconsBtn === 'benefits'
      ? setBd({ ...bd, benefits: checkedValues })
      : setBd({ ...bd, drawbacks: checkedValues });
  }
  return (
    <div className="bd-icon-modal">
      <Search
        placeholder="Search..."
        handleSearch={(e) => {
          handleSearch(e);
          setSearchText(e.target.value);
        }}
        value={searchText}
      />
      <div className={`configure-buttons-wrapper ${iconsBtn}`}>
        <div className={`configure-buttons-slider  ${iconsBtn}`} />
        <div
          className={cls('bd-btn', {
            active: iconsBtn === 'benefits',
          })}
          onClick={() => changeButton('benefits')}
        >
          Benefits
        </div>
        <div
          className={cls('bd-btn', {
            active: iconsBtn === 'drawbacks',
          })}
          onClick={() => changeButton('drawbacks')}
        >
          Drawbacks
        </div>
      </div>
      <Spin spinning={fetching} size="default">
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
          <div className="bd-icons-list" ref={iconsRef}>
            <Row
              gutter={[
                { xs: 16, sm: 16 },
                { xs: 16, sm: 16 },
              ]}
              className="bd-icons-wrapper"
            >
              {iconList?.map((icon) => (
                <React.Fragment key={icon?.id}>
                  <Col key={icon?.id} xs={24} sm={24}>
                    <div
                      className={cls('bd-icon-card', {
                        drawback: iconsBtn === 'drawbacks',
                        benefit: iconsBtn === 'benefits',
                        used: !icon?.icon?.is_used,
                      })}
                    >
                      <div
                        className={cls('bd-icon-img', {
                          drawback: iconsBtn === 'drawbacks',
                          benefit: iconsBtn === 'benefits',
                          used: !icon?.icon?.is_used,
                        })}
                        dangerouslySetInnerHTML={{
                          __html: icon?.icon?.content,
                        }}
                      />
                      <div className="bd-text-block">
                        <span
                          className={cls('bd-icon-text', {
                            used: icon?.is_used,
                          })}
                        >
                          {utils.toUpperLatter(icon?.title || '')}
                        </span>
                        <span
                          className={cls('bd-icon-description', {
                            used: icon?.is_used,
                          })}
                        >
                          {utils.toUpperLatter(icon?.description || '')}
                        </span>
                      </div>
                      <Checkbox
                        value={icon?.id}
                        disabled={
                          !icon?.icon?.is_used || disabledCheckBox(icon?.id)
                        }
                      />
                    </div>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </div>
        </Checkbox.Group>
      </Spin>
      <Divider />
      <div className="bd-icon-counter">
        <div>
          Benefits{' '}
          <span>{bd?.benefits.length + b_and_dLength?.benefits}/6</span>
        </div>
        <div>
          Drawbacks{' '}
          <span>{bd?.drawbacks.length + b_and_dLength?.drawbacks}/6</span>
        </div>
      </div>
    </div>
  );
}
