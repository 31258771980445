import React from 'react';
import cls from "classname";

const ChartIcon = ({width, height, color}) => {
  return (
    <svg width={width}
         height={height}
         className={cls('icon icon-chart')}
         viewBox="0 0 16 16"
         xmlns="http://www.w3.org/2000/svg"
         fill={color ? color : '#707BA0'}
    >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.66667 2C3.03486 2 3.33333 2.29848 3.33333 2.66667V11.3333C3.33333 12.0697 3.93029 12.6667 4.66667 12.6667H13.3333C13.7015 12.6667 14 12.9651 14 13.3333C14 13.7015 13.7015 14 13.3333 14H4.66667C3.19391 14 2 12.8061 2 11.3333V2.66667C2 2.29848 2.29848 2 2.66667 2Z"
            // fill="#707BA0"
      />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.5437 2.03439C13.893 2.15082 14.0818 2.52837 13.9653 2.87767L12.6475 6.831C12.1774 8.24147 10.3873 8.66405 9.33596 7.61275L8.38696 6.66375C8.03652 6.31331 7.43981 6.45418 7.2831 6.92433L5.96532 10.8777C5.84889 11.227 5.47134 11.4157 5.12204 11.2993C4.77275 11.1829 4.58398 10.8053 4.70041 10.456L6.01819 6.5027C6.48834 5.09223 8.27846 4.66964 9.32976 5.72094L10.2788 6.66995C10.6292 7.02038 11.2259 6.87952 11.3826 6.40936L12.7004 2.45603C12.8168 2.10673 13.1944 1.91796 13.5437 2.03439Z"
            // fill="#707BA0"
      />
    </svg>
  );
};

export default ChartIcon;
