import React from 'react';

const ArrowDatepickerPrev = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       fill={color ? color : '#225aea'}
       className={className}
       style={style}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00002 12.0196C8.99926 12.1506 9.02448 12.2805 9.07425 12.4017C9.12401 12.523 9.19734 12.6333 9.29002 12.7263L13.29 16.7073C13.3833 16.8001 13.4939 16.8737 13.6158 16.9239C13.7376 16.9742 13.8682 17 14 17C14.1319 17 14.2624 16.9742 14.3843 16.9239C14.5061 16.8737 14.6168 16.8001 14.71 16.7073C14.8033 16.6145 14.8772 16.5043 14.9277 16.3831C14.9781 16.2619 15.0041 16.1319 15.0041 16.0007C15.0041 15.8694 14.9781 15.7395 14.9277 15.6182C14.8772 15.497 14.8033 15.3868 14.71 15.294L11.4 12.0196L14.58 8.73528C14.6823 8.64372 14.7645 8.53215 14.8215 8.40753C14.8785 8.28291 14.909 8.14793 14.9112 8.01103C14.9134 7.87413 14.8872 7.73825 14.8342 7.61189C14.7812 7.48554 14.7026 7.37143 14.6032 7.2767C14.5039 7.18196 14.386 7.10864 14.2569 7.06132C14.1278 7.01401 13.9903 6.99371 13.853 7.0017C13.7156 7.00969 13.5814 7.04579 13.4588 7.10776C13.3361 7.16972 13.2276 7.25621 13.14 7.36182L9.28002 11.3429C9.1037 11.5249 9.00359 11.7668 9.00002 12.0196Z"
    />
  </svg>
);

export default ArrowDatepickerPrev;
