import React from 'react';

const PortfolioDashboard = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width ? width : 40}
         height={height ? height : 40}
         viewBox="0 0 40 40"
         fill="none">
      <rect x="5" y="11.6666" width="30" height="23.3333" rx="5" fill="#661AC6"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.2664 10.2255C6.37464 10.6057 3.33336 13.887 3.33336 17.8786V29.633C3.33336 33.88 6.77626 37.3229 11.0233 37.3229H28.9816C33.2287 37.3229 36.6716 33.88 36.6716 29.633V17.8786C36.6716 13.8856 33.6282 10.6034 29.7345 10.2251C29.7112 10.2228 29.6878 10.2207 29.6644 10.2186C29.6371 9.95567 29.5923 9.69794 29.5311 9.44662C29.1802 8.00568 28.2916 6.77525 27.09 5.97985C26.1751 5.3739 25.0781 5.02026 23.897 5.01868C23.8943 5.01868 23.8917 5.01868 23.889 5.01868H16.112C14.929 5.01868 13.8287 5.37243 12.9109 5.97992C11.7094 6.7753 10.8209 8.00564 10.47 9.44646C10.4087 9.69794 10.3639 9.95584 10.3366 10.219C10.3132 10.221 10.2898 10.2232 10.2664 10.2255ZM29.1799 13.4844H26.3187H13.6824H10.8239L10.6271 13.5018C8.38752 13.7 6.62904 15.5858 6.62904 17.8786V29.633C6.62904 32.0598 8.59641 34.0272 11.0233 34.0272H28.9816C31.4085 34.0272 33.3759 32.0598 33.3759 29.633V17.8786C33.3759 15.585 31.6162 13.6987 29.3756 13.5016L29.1799 13.4844ZM25.5407 8.93399C25.109 8.55709 24.5471 8.32513 23.9323 8.31472C23.9179 8.31448 23.9035 8.31436 23.889 8.31436H16.112C15.4796 8.31436 14.9018 8.54815 14.4603 8.93401C14.0872 9.26008 13.8115 9.69475 13.6824 10.1887H16.112H23.889H26.3187C26.1896 9.69474 25.9138 9.26007 25.5407 8.93399Z"
            fill="#661AC6"/>
      <rect x="18.3334" y="21.6666" width="3.33333" height="5" rx="1.66667" fill="white"/>
      <path
        d="M6.66664 18.3333L13.2843 20.3185C14.3556 20.6399 15.5168 20.4026 16.376 19.6865L17.866 18.4449C19.1022 17.4147 20.8978 17.4147 22.1339 18.4449L23.6239 19.6865C24.4832 20.4026 25.6443 20.6399 26.7157 20.3185L33.3333 18.3333"
        stroke="white" strokeWidth="3.33333" strokeLinecap="round"/>
    </svg>
  );
};

export default PortfolioDashboard;
