import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const ExportIcon = ({ width, height, className, outline, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-export')}
  >
      <path fillRule="evenodd" clipRule="evenodd" d="M2 10.1605L2 7.99968C2 7.63149 2.29848 7.33301 2.66667 7.33301C3.03486 7.33301 3.33333 7.63149 3.33333 7.99968L3.33333 10.133C3.33333 10.7041 3.33385 11.0923 3.35837 11.3923C3.38225 11.6846 3.42553 11.8341 3.47866 11.9383C3.60649 12.1892 3.81046 12.3932 4.06135 12.521C4.16561 12.5741 4.31508 12.6174 4.60736 12.6413C4.90742 12.6658 5.29561 12.6663 5.86667 12.6663H10.1333C10.7044 12.6663 11.0926 12.6658 11.3926 12.6413C11.6849 12.6174 11.8344 12.5741 11.9387 12.521C12.1895 12.3932 12.3935 12.1892 12.5213 11.9383C12.5745 11.8341 12.6178 11.6846 12.6416 11.3923C12.6662 11.0923 12.6667 10.7041 12.6667 10.133L12.6667 7.99967C12.6667 7.63148 12.9651 7.33301 13.3333 7.33301C13.7015 7.33301 14 7.63148 14 7.99967V10.1604C14 10.6971 14 11.1401 13.9705 11.5009C13.9399 11.8756 13.8742 12.2201 13.7094 12.5436C13.4537 13.0454 13.0457 13.4534 12.544 13.709C12.2204 13.8739 11.876 13.9396 11.5012 13.9702C11.1404 13.9997 10.6975 13.9997 10.1609 13.9997H5.83914C5.30249 13.9997 4.85958 13.9997 4.49878 13.9702C4.12405 13.9396 3.77958 13.8739 3.45603 13.709C2.95426 13.4534 2.54631 13.0454 2.29065 12.5437C2.12579 12.2201 2.06008 11.8756 2.02946 11.5009C1.99998 11.1401 1.99999 10.6972 2 10.1605Z"
            fill={color}/>
      <path d="M6.47401 5.13807C6.21366 5.39842 5.79155 5.39842 5.5312 5.13807C5.27085 4.87772 5.27085 4.45561 5.5312 4.19526L7.5312 2.19526C7.79155 1.93491 8.21366 1.93491 8.47401 2.19526L10.474 4.19526C10.7344 4.45561 10.7344 4.87772 10.474 5.13807C10.2137 5.39842 9.79155 5.39842 9.5312 5.13807L8.66927 4.27614V10C8.66927 10.3682 8.37079 10.6667 8.0026 10.6667C7.63441 10.6667 7.33594 10.3682 7.33594 10V4.27614L6.47401 5.13807Z"
            fill={color}/>
  </svg>
);

ExportIcon.defaultProps = {
  width: 24,
  height: 24,
  outline: 0,
  color: '#707BA0',
};

ExportIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  outline: PropTypes.number,
  color: PropTypes.string,
  // fill: PropTypes.string,
};

export default ExportIcon;
