import React from 'react';
//import cls from 'classname';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Switch } from 'antd';
import Icon from '../../../Icon';
import './ChartBlock.less';

//diff titles for diff reports
const countTitle = {
  revenue: 'Total Revenue',
  sales: 'Total Sales',
  unique_products_sold: 'Total Unique Products Sold',
  ad_spot: 'Ad Engagement',
  ad_spot_date: 'Ad Creation Date',
};

const title = {
  revenue: 'Revenue and Returns',
  sales: 'Sales and Returns',
  unique_products_sold: 'Unique Products Sold and Returns',
  ad_spot: 'Ad Engagement',
  ad_spot_date: 'Ad Creation Date',
};

const ChartBlock = (
  {
    type,
    subTitle,
    totalCount,
    link,
    isMobile = false,
    adsInfo = false,
    adsQuantity = 4,
    sameLandingPageToggleText = null,
    historicalDataViewToggleText = null,
    sameLandingPage = false,
    setSameLandingPage = () => null,
    historicalDataView = true,
    setHistoricalDataView = () => null,
    id = null,
    showRawData = false,
    rawTotalCount,
    returnsCount,
    rawReturnsCount,
    withReturns = false,
    ...props
  }) => {

  const { t } = useTranslation();

  //total number format
  let totalFormat = (value) => new Intl.NumberFormat(
    'en-US',
    type === 'revenue'
      ? { style: 'currency', currency: 'USD' }
      : { style: 'decimal', maximumFractionDigits: 0 },
  ).format(value);

  return (
    <div className="chart-block"
         id={id}
    >
      <div className="chart-block_header">
        <span className="chart-block_title">
          {
            t(title[type])
          }
          <span style={{ display: isMobile ? 'block' : 'inline-block', paddingLeft: isMobile ? 0 : 16 }}
                className="chart-block_sub-title">{subTitle}</span>
        </span>
        {
          adsInfo ?
            <span className="ads_quantity">
              {
                adsQuantity === 1 ?
                  t('Based On: _quantity_ Ad With Post Link', { quantity: adsQuantity })
                  :
                  t('Based On: _quantity_ Ads With Post Link', { quantity: adsQuantity })
              }
            </span>
            :
            null
        }
        {
          //link to report page
          link && <Link className="chart-block_link"
                        to={
                          {
                            pathname: link,
                            state: {
                              name: t(title[type]),
                              link: `/sales-tracker/store/${title}`,
                            },
                          }}>
            {t('View Report')}
            <Icon type={'arrow_link'} role={'icon'} />
          </Link>
        }
        {
          !sameLandingPageToggleText && !historicalDataViewToggleText ?
            null
            :
            !sameLandingPageToggleText && historicalDataViewToggleText ?
              <div className="switch-option"
                   style={{
                     ...(!sameLandingPageToggleText && !isMobile && {
                       marginRight: 'auto',
                       marginLeft: 'auto',
                     }),
                   }}
              >
                {
                  isMobile ?
                    null
                    :
                    <p onClick={() => setHistoricalDataView((prev) => !prev)}
                    >
                      {historicalDataViewToggleText?.[0]}
                    </p>
                }
                <Switch checked={historicalDataView}
                        onChange={(state) => setHistoricalDataView(state)}
                />
                <p onClick={() => setHistoricalDataView((prev) => !prev)}
                >
                  {
                    isMobile ?
                      historicalDataViewToggleText?.[historicalDataView ? 1 : 0]
                      :
                      historicalDataViewToggleText?.[1]
                  }
                </p>
              </div>
              :
              <Dropdown dropdownRender={() => (
                <div className={'actions-options-dropdown'}>
                  <div className="switch-option">
                    <Switch checked={sameLandingPage}
                            onChange={(state) => setSameLandingPage(state)}
                    />
                    <p onClick={() => setSameLandingPage((prev) => !prev)}
                    >
                      {sameLandingPageToggleText}
                    </p>
                  </div>
                  <div className="switch-option">
                    <Switch checked={historicalDataView}
                            onChange={(state) => setHistoricalDataView(state)}
                    />
                    <p onClick={() => setHistoricalDataView((prev) => !prev)}
                    >
                      {
                        historicalDataViewToggleText?.[historicalDataView ? 1 : 0]
                      }
                    </p>
                  </div>
                </div>
              )}
                        destroyPopupOnHide={true}
                        trigger={['click']}
                        placement="bottomRight"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        overlayClassName={'filter-preset-cell-menu-wrapper'}
              >
                <span className={'actions-options'}>
                  <Icon type="three_dots" role="button" width={24} height={24} />
                </span>
              </Dropdown>
        }
      </div>
      {
        type.includes('ad_spot') ?
          <span className="chart-block_total-count">
            {
              totalFormat(totalCount)
            }
          </span>
          :
          <div className={'chart-block_total-count-wrap'}>
            <div className="chart-block_total-count-wrapper basic">
              <div className={'chart-block_total-count-title-wrapper'}>
          <span className={'chart-block_total-count-color'}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <rect x="6"
                    y="6"
                    width="8"
                    height="8"
                    rx="2"
                    fill="#225AEA"
              />
            </svg>
          </span>
                <span className={'chart-block_total-count-title'}>
            {
              t(countTitle[type])
            }
          </span>
              </div>
              <span className={'chart-block_total-count'}>
          {
            totalFormat(totalCount)
          }
        </span>
            </div>
            {
              showRawData ?
                <div className="chart-block_total-count-wrapper basic raw">
                  <div className={'chart-block_total-count-title-wrapper'}>
              <span className={'chart-block_total-count-color'}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <rect x="6"
                        y="6"
                        width="8"
                        height="8"
                        rx="2"
                        fill="#225AEA"
                  />
                </svg>
              </span>
                    <span className={'chart-block_total-count-title'}>
                {
                  t(`Raw ${countTitle[type]}`)
                }
              </span>
                  </div>
                  <span className={'chart-block_total-count'}>
              {
                totalFormat(rawTotalCount)
              }
            </span>
                </div>
                :
                null
            }
            {
              withReturns ?
                <div className="chart-block_total-count-wrapper returns">
                  <div className={'chart-block_total-count-title-wrapper'}>
              <span className={'chart-block_total-count-color'}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <rect x="6"
                        y="6"
                        width="8"
                        height="8"
                        rx="2"
                        fill="#225AEA"
                  />
                </svg>
              </span>
                    <span className={'chart-block_total-count-title'}>
                {
                  t('Total Returns')
                }
              </span>
                  </div>
                  <span className={'chart-block_total-count'}>
              {
                totalFormat(returnsCount)
              }
            </span>
                </div>
                :
                null
            }
            {
              showRawData && withReturns ?
                <div className="chart-block_total-count-wrapper returns raw">
                  <div className={'chart-block_total-count-title-wrapper'}>
              <span className={'chart-block_total-count-color'}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <rect x="6"
                        y="6"
                        width="8"
                        height="8"
                        rx="2"
                        fill="#225AEA"
                  />
                </svg>
              </span>
                    <span className={'chart-block_total-count-title'}>
                {
                  t('Raw Total Returns')
                }
              </span>
                  </div>
                  <span className={'chart-block_total-count'}>
              {
                totalFormat(rawReturnsCount)
              }
            </span>
                </div>
                :
                null
            }
          </div>
      }
      <Divider type={'horizontal'} style={{ margin: '16px 0' }} />

      {
        props.children
      }
    </div>
  );
};

export default ChartBlock;
