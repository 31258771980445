import React, { useCallback } from 'react';
import { Upload, message, Spin } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import Icon from '../../../../Icon';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.less';

import { DragableUploadListItem } from './components';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function ImagesBlock({
  images,
  openModal,
  onChange,
  onUploadImage,
  updateLoading,
  saveInfo,
}) {
  const { t } = useTranslation();

  const imgList = images?.length
    ? [...images].map((img) => ({ ...img, uid: img?.url || img?.uid }))
    : [];

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = imgList[dragIndex];
      onChange({
        images: update(imgList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      });
    },
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [imgList],
  );
  const uploadImageRequest = ({ file, onSuccess }) => {
    onUploadImage(file);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('You can upload file only in JPG/ PNG formats'));
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Image must be less than 2 MB'));
      return Upload.LIST_IGNORE;
    }
    return isJpgOrPng && isLt2M;
  };

  function showModal(data) {
    openModal('image_delete');
    saveInfo(data);
    return false;
  }

  return (
    <div className="details-images-block">
      <div className="details-images-block_title">
        Images <span>*</span>
      </div>
      <div className="details-images-block_images">
        <div
          className={cls('images-list', {
            empty_list: !imgList?.length,
          })}
        >
          <DndProvider backend={HTML5Backend}>
            <Upload
              name="media"
              listType="picture-card"
              fileList={imgList}
              className="images-list_upload"
              customRequest={uploadImageRequest}
              beforeUpload={beforeUpload}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                removeIcon: (
                  <Icon
                    type="delete_basket"
                    role="icon"
                    width={24}
                    height={24}
                  />
                ),
              }}
              onRemove={showModal}
              itemRender={(originNode, file, currFileList) => (
                <DragableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                />
              )}
            >
              <Spin spinning={updateLoading} indicator={antIcon}>
                <div className="images-add">
                  {!updateLoading ? (
                    <>
                      <span>
                        <Icon
                          type="plus_icon"
                          color="#225AEA"
                          role="icon"
                          width={24}
                          height={24}
                        />
                      </span>
                      <span>Add New</span>
                    </>
                  ) : null}
                </div>
              </Spin>
            </Upload>
          </DndProvider>
        </div>
      </div>
    </div>
  );
}
