import React from 'react';

const ArrowForwardOnboarding = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
      <path
        d="M1.6714e-05 5.01963C-0.000744179 5.15062 0.0244814 5.28046 0.074246 5.40172C0.124011 5.52298 0.197335 5.63327 0.290017 5.72627L4.29002 9.7073C4.38326 9.8001 4.49395 9.87371 4.61577 9.92393C4.73759 9.97415 4.86816 10 5.00002 10C5.13188 10 5.26244 9.97415 5.38427 9.92393C5.50609 9.87371 5.61678 9.8001 5.71002 9.7073C5.80326 9.61451 5.87722 9.50434 5.92768 9.3831C5.97814 9.26185 6.00411 9.1319 6.00411 9.00067C6.00411 8.86943 5.97814 8.73948 5.92768 8.61824C5.87722 8.497 5.80326 8.38683 5.71002 8.29403L2.40002 5.01963L5.58002 1.73528C5.68231 1.64372 5.76452 1.53215 5.8215 1.40753C5.87849 1.28291 5.90902 1.14793 5.9112 1.01103C5.91338 0.874125 5.88716 0.738248 5.83417 0.611895C5.78118 0.485541 5.70256 0.371429 5.60323 0.276695C5.50391 0.181961 5.38601 0.108641 5.25692 0.0613232C5.12783 0.0140057 4.99031 -0.00629234 4.85297 0.00169754C4.71564 0.00968742 4.58144 0.0457945 4.45876 0.107759C4.33608 0.169724 4.22757 0.256214 4.14002 0.361821L0.280016 4.34286C0.103701 4.52486 0.00359263 4.76683 1.6714e-05 5.01963Z"
        fill={color ? color : "#225AEA"}/>
    </svg>
  );
};

export default ArrowForwardOnboarding;
