import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spin, Grid } from 'antd';
import { Link } from 'react-router-dom';
import cls from 'classname';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Drop from '../DropTimer';
import { WaveOther } from '../../../../Components/Wave';
import { getSuffix } from '../../../../Utils/utils';
import {useSelector} from "react-redux";

const { useBreakpoint } = Grid;

const getClassForWeek = ({ interactable, drop_date, latest, comming_up }) => {
  let className = '';
  const dropDate = dayjs(drop_date);

  const startOfMonth = dayjs().clone().startOf('month');
  const endOfMonth = dayjs().clone().endOf('month');

  if (comming_up) {
    className += ' week--coming-up';
  }

  if (latest) {
    className += ' week--latest';
  }

  if (dropDate <= endOfMonth && dropDate >= startOfMonth) {
    className += ' week--in_current_month';
  }

  className += interactable ? ' week--enabled' : ' week--disabled';

  return className;
};

const isDisabledDropLink = (drops) =>
  !drops.some((value) => value.interactable);

const getStyleForWave = (isMobile, dayOfWeek) => {
  const obj = {};

  if (isMobile) {
    obj.width = `${(dayOfWeek * 100) / 7}%`;
  } else {
    obj.height = `${(dayOfWeek * 100) / 7}%`;
  }
  return obj;
};

const DropsComponent = (
  {
    drops = [],
    category_timer,
    summary,
    getNextList,
    next,
    dropsLoading,
    isMobile,
    category,
}) => {
  const dateNow = dayjs().format('YYYY-MM-DD hh:mm:ss');
  const dayOfWeek = dayjs().day();
  const screen = useBreakpoint();
  const { t } = useTranslation();
  const dropsRef = useRef();
  const userInfo = useSelector(state => state.auth.userInfo);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !dropsLoading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const dropsContent = dropsRef.current;

    if (dropsContent) {
      dropsContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (dropsContent) {
        dropsContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  const dropContent = (item) => {
    return (
      <>
        <div className="week_wrap">
          <div
            className={cls("week_num",
              {'active': isMobile ? dayOfWeek >= 1 : dayOfWeek > 5})}
          >
            {item.week}
            <span className="week_suffix">{getSuffix(Number(item.week))}</span>
          </div>
          <div
            className={cls("week_text",
              {'active':  isMobile ? dayOfWeek >= 1 : dayOfWeek > 4})}
          >{t('portfolio.week')}</div>
        </div>
        <div className="week_wrap">
          <div
            className={cls("week_day",
              {'active': isMobile ? dayOfWeek > 6 : dayOfWeek > 3})}
          >{item.day}</div>
          <div
            className={cls("week_month",
              {'active': isMobile ? dayOfWeek > 6 : dayOfWeek > 2})}
          >
            {isMobile ? item.month : item.short_month}
          </div>
        </div>
        <div
          className={cls("week_id",
            {'active': isMobile ? dayOfWeek > 3 :dayOfWeek > 1})}
        >
          {item.drop_id}
        </div>
      </>
    );
  };

  return (
    <Col span={24} className="drops">
      <div className="drops_timer">
        <Spin spinning={false}>
          <Drop
            timerStart={category_timer?.start || dateNow}
            timerEnd={category_timer?.end || dateNow}
            drop={{
              week: category_timer?.week,
              month: category_timer?.month,
              year: category_timer?.year,
            }}
            totalProducts={summary?.total_products || 0}
            totalDrops={summary?.total_drops || 0}
            max={category_timer?.drop_period || 7}
            isCanceled={!category_timer}
            category={category}
          />
        </Spin>
      </div>
      <Spin spinning={dropsLoading}>
        <div ref={dropsRef} className="drops_wrapper">
          <div className={cls("drops_wrapper_block", {
            'disabled-transition': isDisabledTransition
          })}>
            <Row gutter={{ xs: 0, sm: 16 }}>
              {drops.map((value, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col xs={24} xl={12} key={`drop-item-${index}`}>
                  <div className="calendar">
                    <div className="calendar_header">
                      <div className="calendar_date">
                        <div className="calendar_month">{t(value.month)}</div>
                        <div className="calendar_year">{value.year}</div>
                      </div>
                      <Link
                        to={{
                          pathname: isDisabledDropLink(value.drops)
                            ? '/portfolio/'
                            : `/portfolio/product-list/?month=${value.month}&year=${value.year}&category=${category?.id}`
                        }}
                        state={{
                          name: isDisabledDropLink(value.drops)
                            ? null
                            : `${value.month} ${value.year}`,
                          link: isDisabledDropLink(value.drops)
                            ? null
                            : `/portfolio/product-list/?month=${value.month}&year=${value.year}&category=${category?.id}`,
                        }}
                        className={cls('calendar_link', {
                          'calendar_link--disabled': isDisabledDropLink(
                            value.drops,
                          ) || !Boolean(userInfo?.subscriptions?.[0])
                        })}
                      >
                        {t('View All')}
                      </Link>
                    </div>
                    <div className="calendar_wrapper">
                      {value.drops.map((item) => (
                        <div
                          className="calendar_item-wrapper"
                          key={`week-key-${item.drop_id}`}
                        >
                          {item.interactable ?
                            <Link
                              to={{
                                pathname: !item.interactable
                                  ? '/portfolio/'
                                  : `/portfolio/product-list/?drop_id=${item.drop_id}`
                              }}
                              state={{
                              name: !item.interactable ? null : item.drop_id,
                              link: !item.interactable
                                ? null
                                : `/portfolio/product-list/?drop_id=${item.drop_id}`,
                            }}
                              className={cls(
                                'week calendar_item',
                                getClassForWeek(item),
                              )}
                            >
                              <div className="week_glob_wrap">
                                {dropContent(item)}
                              </div>
                            </Link>
                            :
                            <div
                              className={cls(
                                'week calendar_item',
                                getClassForWeek(item),
                              )}
                            >
                              <div className="week_glob_wrap disabled">
                                {dropContent(item)}
                              </div>
                              {/* WAVE */}
                              {item.comming_up && (
                                <div
                                  className="week-wave"
                                  style={getStyleForWave(
                                    (screen.sm && !screen.md) ||
                                    (!screen.sm && screen.xs),
                                    dayOfWeek,
                                  )}
                                >
                                  <WaveOther />
                                </div>)}
                            </div>
                          }
                          {/* COMING-UP TAG */}
                          {item.comming_up && (
                            <div
                              className="week-tag week-tag--coming-up"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {t('portfolio.coming_up')}
                            </div>
                          )}

                          {/* LATEST TAG */}
                          {item.latest && (
                            <div
                              className="week-tag week-tag--latest"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {t('portfolio.latest')}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Spin>
    </Col>
  );
};

DropsComponent.propTypes = {
  drops: PropTypes.array.isRequired,
  dropsLoading: PropTypes.bool,
  category_timer: PropTypes.object,
  summary: PropTypes.object,
  getNextList: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  next: PropTypes.string,
};

export default DropsComponent;
