import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Col, Row, Spin } from 'antd';
import EmptySearch from '../../../../Components/EmptyState';
import Collection from '../collection';
import './styles.less';

// REDUCER
import CollectionsActions from '../../reducer';

export const CollectionsComponent = ({
  collections,
  loading,
  getNextCollections,
  next,
  dispatch,
  setDeleteModalVisible,
}) => {
  const collectionsRef = useRef();

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextCollections();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const collectionsContent = collectionsRef.current;

    if (collectionsContent) collectionsContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (collectionsContent) collectionsContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SET AS DEFAULT HANDLER
  const setAsDefaultHandler = ({ collectionID, collectionName }) => {
    dispatch(
      CollectionsActions.setCollectionAsDefaultRequest({
        collectionID,
        collectionName,
      }),
    );
  };

  // RENAME HANDLER
  const renameCollectionHandler = ({ collectionID, name }) => {
    dispatch(
      CollectionsActions.renameCollectionRequest({
        collectionID,
        collectionName: name,
      }),
    );
  };

  // REMOVE HANDLER
  const removeCollectionHandler = ({ collectionID, collectionName }) => {
    setDeleteModalVisible(true);
    dispatch(
      CollectionsActions.saveCollection({ collectionID, collectionName }),
    );
  };

  const saveCollection = ({ collectionID, collectionName }) => {
    dispatch(
      CollectionsActions.saveCollection({ collectionID, collectionName }),
    );
  };

  return (
    <div
      className={cls('collections-list', {
        'collections-list--empty': collections.length === 0,
      })}
    >
      <Spin spinning={loading}>
        <Row
          className="collections-list_wrapper"
          ref={collectionsRef}
          gutter={{ xs: 0, sm: 16 }}
        >
          {collections.length > 0 ? (
            collections.map((value, i) => (
              // COLLECTIONS
              <Col xs={24} md={12} lg={8} key={`col-${value.id}-${i}`}>
                <Collection
                  {...value}
                  setAsDefaultFunc={setAsDefaultHandler}
                  renameCollectionFunc={renameCollectionHandler}
                  removeCollectionFunc={removeCollectionHandler}
                  saveCollection={saveCollection}
                />
              </Col>
            ))
          ) : (
            <EmptySearch />
          )}
        </Row>
      </Spin>
    </div>
  );
};

CollectionsComponent.defaultProps = {
  collections: [],
  loading: false,
};

CollectionsComponent.propTypes = {
  collections: PropTypes.array,
  loading: PropTypes.bool,
  getNextCollections: PropTypes.func.isRequired,
  next: PropTypes.string,
  dispatch: PropTypes.func,
};

export default CollectionsComponent;
