import React from 'react';

const Bulb = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
    >
      <path
        d="M7.24542 4.96644C8.47046 3.72331 10.1535 3.00171 11.9969 3.00171C13.8571 3.00171 15.5476 3.73522 16.7726 4.92828C17.9905 6.11428 18.7516 7.75927 18.7516 9.58102C18.7516 11.5206 17.8757 13.2456 16.3249 14.7372L15.8648 16.5001H8.19881L7.85436 15.055C6.23099 13.361 5.33393 11.7291 5.24889 9.8736L5.24756 9.84466L5.24847 9.81571C5.30949 7.87294 6.0476 6.18195 7.24542 4.96644ZM8.55632 18.0001L8.90603 19.4673L8.91693 19.4997C9.06735 19.9461 9.35574 20.3166 9.71865 20.5762C10.0949 20.8453 10.5554 20.9985 11.0421 20.9985H11.0442L13.1194 20.9926L13.1505 20.9899C13.6225 20.9493 14.0609 20.7643 14.4105 20.4715C14.7571 20.1811 15.019 19.7814 15.131 19.3119L15.4733 18.0001H8.55632Z"
        fill="white"/>
    </svg>
  );
};

export default Bulb;
