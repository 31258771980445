import React from 'react';

const Clock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16" height="16"
         viewBox="0 0 16 16"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7.99992 2.66659C5.0544 2.66659 2.66659 5.0544 2.66659 7.99992C2.66659 10.9454 5.0544 13.3333 7.99992 13.3333C10.9454 13.3333 13.3333 10.9454 13.3333 7.99992C13.3333 5.0544 10.9454 2.66659 7.99992 2.66659ZM1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992Z"
            fill="#707BA0"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7.99992 4C8.36811 4 8.66659 4.29848 8.66659 4.66667V7.72386L9.80466 8.86193C10.065 9.12228 10.065 9.54439 9.80466 9.80474C9.54431 10.0651 9.1222 10.0651 8.86185 9.80474L7.52851 8.4714C7.40349 8.34638 7.33325 8.17681 7.33325 8V4.66667C7.33325 4.29848 7.63173 4 7.99992 4Z"
            fill="#707BA0"/>
    </svg>
  );
};

export default Clock;
