import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PaymentCard from './PaymentCard';
import ModalComponent from '../Modal';
import Icon from '../../Icon';
import ChangeCreditCardForm from './ChangeCreditCardForm';
import openNotification from '../Notification';
import './styles-payment-method.less';

function PaymentMethod(
  { user,
    getCountries,
    isMobile,
    saveFetching,
    theme,
    changePayments,
    saveResult,
    errors,
    resetErrors,
    resetResult,
    setChargebeeCardFetching,
    defaultUserCountry,
    onBoarding,
    clearIntent,
    createIntent,
    errorSave,
    changeStripeCard
  }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errors?.change_payments) {
      openNotification({
        type: 'error',
        message: t('setting_billing.Something went wrong, kindly try adding your new payment method again'),
        style: { minWidth: '716px' },
      });
      resetErrors();
    }
    if (saveResult?.change_payments) {
      openNotification({
        type: 'success',
        message: t('setting_billing.New payment method has been successfully added!'),
        style: { minWidth: '716px' },
      });
      resetResult();
    }
    if(visible) handleClose();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  const { card } = user;

  const handleClose = () => {
    if (onBoarding?.intentResult) clearIntent();
    setVisible(false);
  };
  const handleSubmit = (data) => {
    changePayments(data)
  };

  return (
    <Card className="payment-method">
      <div className="billing-title-text">
        {card
          ? t('setting_billing.Payment Method')
          : t('setting_billing.No Payment Method Added')}
      </div>
      <Spin spinning={onBoarding?.stripeLoading}>
        <div className="payment-card-block">
        <PaymentCard
          payment={user?.card}
          billing={card?.billing_address}
        />
        <div className="replace-card" onClick={() => {
          if (user?.use_stripe) {
            changeStripeCard();
        } else {
            setVisible(true);
          }
        }}>
          <div>
            <Icon
              role="icon"
              type={card ? 'sync_arrows' : 'plus_icon'}
              width={24}
              height={24}
              color="#225aea"
            />
          </div>
          <div className="replace-text">
            {card
              ? t('setting_billing.Replace')
              : t('setting_billing.Add')}
          </div>
        </div>

        <ModalComponent
          width={550}
          className="company-detail-modal"
          title={
            card
              ? t('setting_billing.Replace Credit Card')
              : t('setting_billing.Add Credit Card')
          }
          isMobile={isMobile}
          handleClose={handleClose}
          open={visible}
          onCancel={handleClose}
          destroyOnClose
          footer={false}
        >
          <Spin size="large" spinning={saveFetching || onBoarding?.chargebeeCardFetching}>
            <ChangeCreditCardForm
              customClass={'add-card'}
              paymentMethod={card}
              billingInfo={{ country: defaultUserCountry}}
              onCancel={handleClose}
              onSubmit={handleSubmit}
              getCountries={getCountries}
              countries={onBoarding.countries}
              isMobile={isMobile}
              theme={theme}
              setChargebeeCardFetching={setChargebeeCardFetching}
              errorSave={errorSave}
              intent={{
                result: onBoarding?.intentResult,
                error: onBoarding?.intentError,
                createIntent: createIntent,
                clearIntent: clearIntent,
                amount: null,
              }}
            />
          </Spin>
        </ModalComponent>

      </div>
      </Spin>
    </Card>
  );
}

export default React.memo(PaymentMethod)
