import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../Icon';
import ButtonComponent from "../../../Components/Button";
import './modal.less';

export default function ChooseMethodModalContent({setSelectedMethodAdd}) {
  return (
    <>
      <p key="text" style={{ margin: '0 0 32px 0' }}>
        What method would you like to use to add products?
      </p>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <ButtonComponent className='method-add-product-card'
                  onClick={() => setSelectedMethodAdd('link')}
          >
            <Icon role='icon' type='method_link'/>
            <p className='method-add-product-card_title'>Link</p>
            <p className='method-add-product-card_text'>Add via link</p>
          </ButtonComponent>
        </Col>
        <Col span={12}>
          <ButtonComponent className='method-add-product-card disabled'
                  disabled={true}
                  onClick={() => setSelectedMethodAdd('database')}
          >
            <Icon role='icon' type='method_database'/>
            <p className='method-add-product-card_title'>Database <span className='coming-soon'>SOON</span></p>
            <p className='method-add-product-card_text'>Add existing products</p>
          </ButtonComponent>
        </Col>
      </Row>
    </>
  );
}
