import React from 'react';

const SalesSettingsSkeleton = ({ view, isMobile }) => {
  return (
    <>
      <div className="sales-tracker-settings-wrapper">
        <div className="sales-tracker-search-wrapper" style={{height: isMobile ? 'unset' : 40, display: "flex", alignItems: "center", flexDirection: isMobile ? 'column' : 'row'}}>
          <span className="link skeleton" style={{width: isMobile ? '100%' : 'calc(100% - 147px - 16px)', height: 40}} />
          <span className="link skeleton" style={{marginLeft: isMobile ? 0 : 16, width: isMobile ? '100%' : 147, height: 40, marginTop: isMobile ? 12 : 0}} />
        </div>
        <div className="sales-tracker-page-view-wrapper">
          <span className="link skeleton" style={{width: '100%', height: 40}} />
        </div>
      </div>
    </>
  );
};

export default SalesSettingsSkeleton;
