import Image from '../../Images';

export const images = {
  random: Image.randomSmall,
  household: Image.householdSmall,
  fashion: Image.fashionSmall,
  fashion_men: Image.fashionMenSmall,
  fashion_women: Image.fashionWomenSmall,
  baby: Image.babySmall,
  electronics: Image.electronicsSmall,
  beauty: Image.beautySmall,
  pets: Image.petsSmall,
};

export const imageBig = {
  random: Image.random300,
  household: Image.household300,
  fashion: Image.fashion300,
  fashion_men: Image.fashionMen300,
  fashion_women: Image.fashionWomen300,
  baby: Image.baby300,
  electronics: Image.electronics300,
  beauty: Image.beauty300,
  pets: Image.pets300,
};

export const tabs = [
  {
    id: 1,
    text: 'Random',
    imageName: Image.randomSmall,
    className: 'portfolio_header-item--random',
  },
  {
    id: 2,
    text: 'Household',
    imageName: Image.householdSmall,
    className: 'portfolio_header-item--household',
  },
  {
    id: 3,
    text: 'Fashion',
    imageName: Image.fashionSmall,
    className: 'portfolio_header-item--fashion',
  },
  {
    id: 4,
    text: 'Baby',
    imageName: Image.babySmall,
    className: 'portfolio_header-item--baby',
  },
  {
    id: 5,
    text: 'Electronics',
    imageName: Image.electronicsSmall,
    className: 'portfolio_header-item--electronics',
  },
  {
    id: 6,
    text: 'Beauty',
    imageName: Image.beautySmall,
    className: 'portfolio_header-item--beauty',
  },
  {
    id: 7,
    text: 'Pets',
    imageName: Image.petsSmall,
    className: 'portfolio_header-item--pets',
  },
];

export const panels = [
  {
    category: 'Random',
    text: 'Random',
    imageName: Image.random300,
  },
  {
    category: 'Household',
    text: 'Household',
    imageName: Image.household300,
  },
  {
    category: 'Fashion',
    text: 'Fashion',
    imageName: Image.fashion300,
  },
  {
    category: 'Baby',
    text: 'Baby',
    imageName: Image.baby300,
  },
  {
    category: 'Electronics',
    text: 'Coming Soon',
    imageName: Image.electronics300,
  },
  {
    category: 'Beauty',
    text: 'Coming Soon',
    imageName: Image.beauty300,
  },
  {
    category: 'Pets',
    text: 'Coming Soon',
    imageName: Image.pets300,
  },
];
