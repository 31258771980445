import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TweenLite, TweenMax, Expo } from 'gsap';

TweenLite.defaultEase = Expo.easeOut;

export const NumbersComponent = ({ str }) => {
  const firstNumber = useRef(null);
  const lastNumber = useRef(null);

  useEffect(() => {
    updateTimerDisplay(str[0], str[1]);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [str]);

  function updateTimerDisplay(first, last) {
    animate(firstNumber.current, first);
    animate(lastNumber.current, last);
  }

  function animate(group, value) {
    TweenMax.killTweensOf(group);
    TweenMax.to(group, 1, {
      y: -group.querySelector(`div.num-${value}`)?.offsetTop || 0,
    });
  }

  return (
    <div className="number-group">
      <div className="first number-grp">
        <div className="number-grp-wrp" ref={firstNumber}>
          <div className="num num-0">0</div>
          <div className="num num-1">1</div>
          <div className="num num-2">2</div>
          <div className="num num-3">3</div>
          <div className="num num-4">4</div>
          <div className="num num-5">5</div>
          <div className="num num-6">6</div>
          <div className="num num-7">7</div>
          <div className="num num-8">8</div>
          <div className="num num-9">9</div>
        </div>
      </div>
      <div className="second number-grp">
        <div className="number-grp-wrp" ref={lastNumber}>
          <div className="num num-0">0</div>
          <div className="num num-1">1</div>
          <div className="num num-2">2</div>
          <div className="num num-3">3</div>
          <div className="num num-4">4</div>
          <div className="num num-5">5</div>
          <div className="num num-6">6</div>
          <div className="num num-7">7</div>
          <div className="num num-8">8</div>
          <div className="num num-9">9</div>
        </div>
      </div>
    </div>
  );
};

NumbersComponent.propTypes = {
  str: PropTypes.string.isRequired,
};

export default NumbersComponent;
