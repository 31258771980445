import React from 'react';

const ShopifyDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40"
         fill="none">
      <g clipPath="url(#clip0_31084_464382)">
        <path
          d="M32.1255 9.629C32.1011 9.45515 31.9463 9.3587 31.8178 9.3482C31.6903 9.3377 29.1919 9.30046 29.1919 9.30046C29.1919 9.30046 27.1025 7.31002 26.8961 7.10754C26.6897 6.90506 26.2866 6.96618 26.1298 7.01203C26.1279 7.01299 25.7375 7.13142 25.0803 7.33103C24.9703 6.98147 24.8086 6.55167 24.5778 6.11996C23.834 4.72743 22.7445 3.99105 21.4281 3.98914C21.4262 3.98914 21.4252 3.98914 21.4233 3.98914C21.3317 3.98914 21.2412 3.99774 21.1497 4.00537C21.1108 3.95953 21.0718 3.91464 21.0309 3.8707C20.4574 3.26899 19.7224 2.97578 18.8412 3.00156C17.1412 3.04932 15.4481 4.2537 14.0753 6.39312C13.1094 7.89836 12.3743 9.78948 12.166 11.2536C10.2138 11.8467 8.84878 12.2612 8.8186 12.2708C7.83328 12.5745 7.80212 12.6041 7.6736 13.5153C7.57819 14.2039 5 33.7606 5 33.7606L26.6059 37.4262L35.9703 35.1426C35.9703 35.1426 32.1498 9.80285 32.1255 9.629ZM23.9986 7.65958C23.501 7.81049 22.9353 7.98241 22.3219 8.16866C22.3093 7.32435 22.207 6.14958 21.8059 5.1343C23.096 5.37403 23.7308 6.80573 23.9986 7.65958ZM21.1915 8.51249C20.0592 8.8563 18.8237 9.23166 17.5842 9.608C17.9328 8.29855 18.5939 6.99484 19.4059 6.14002C19.7077 5.82198 20.1303 5.46763 20.6307 5.26515C21.101 6.22789 21.2032 7.59082 21.1915 8.51249ZM18.8752 4.11139C19.2744 4.10279 19.6104 4.18875 19.8976 4.37404C19.438 4.60804 18.994 4.94424 18.5774 5.38263C17.4976 6.5192 16.67 8.28327 16.3399 9.98525C15.3108 10.2976 14.304 10.6042 13.3771 10.885C13.9623 8.20591 16.2513 4.18589 18.8752 4.11139Z"
          fill="#95BF47"/>
        <path
          d="M31.8187 9.34917C31.6912 9.33867 29.1929 9.30143 29.1929 9.30143C29.1929 9.30143 27.1035 7.31099 26.897 7.10851C26.8201 7.03305 26.716 6.99389 26.6069 6.97766L26.6079 37.4253L35.9713 35.1426C35.9713 35.1426 32.1508 9.80383 32.1265 9.62997C32.1021 9.45612 31.9463 9.35967 31.8187 9.34917Z"
          fill="#5E8E3E"/>
        <path
          d="M21.4164 14.0721L20.3289 18.0635C20.3289 18.0635 19.1158 17.5219 17.6777 17.6107C15.5688 17.7416 15.5464 19.0463 15.5678 19.3739C15.6827 21.1589 20.47 21.5486 20.7388 25.7301C20.9501 29.0194 18.9599 31.2696 16.0926 31.4473C12.6508 31.6603 10.7561 29.6689 10.7561 29.6689L11.4854 26.6259C11.4854 26.6259 13.3927 28.0376 14.9194 27.943C15.9164 27.881 16.2727 27.0854 16.2367 26.5228C16.0868 24.1942 12.1883 24.3318 11.942 20.5057C11.7346 17.2861 13.8903 14.0234 18.6464 13.7292C20.4788 13.6137 21.4164 14.0721 21.4164 14.0721Z"
          fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_31084_464382">
          <rect width="31" height="35" fill="white" transform="translate(5 3)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShopifyDashboard;
