import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import {Button} from "antd";
import './BannerBlock.less';

export const ImagesBlock = ({ skeleton, theme, isMobile, setVisible }) => {
  const { t } = useTranslation();

  const renderSkeleton =
    isMobile ?
        <>
          <div className='info-wrp' style={{display: 'flex', flexDirection: 'column'}}>
            {[
              {width: 100, height: 24, mt: 0},
              {width: 25, height: 24, mt: 8},
              {width: 100, height: 16, mt: 4},
              {width: 100, height: 16, mt: 4},
              {width: 80, height: 16, mt: 4},
              {width: 100, height: 40, mt: 24},
            ]
              .map(el => (
                <span key={el.width * Math.random() * (5 - 1) + 1} className="link skeleton" style={{width: `${el.width}%`, height: el.height, marginTop: el.mt}} />
              ))}
          </div>
          <span className="link skeleton skeleton-banner-rectangle" />
        </>
    :
        <>
          <div className='info-wrp' style={{display: 'flex', flexDirection: 'column'}}>
            {[
              {width: 380, height: 32, mt: 0},
              {width: 379, height: 20, mt: 8},
              {width: 351, height: 20, mt: 4},
              {width: 107, height: 20, mt: 4},
              {width: 137, height: 40, mt: 24},
            ]
              .map(el => (
                <span key={el.width} className="link skeleton" style={{width: el.width, height: el.height, marginTop: el.mt}} />
              ))}
          </div>
          <span className="link skeleton skeleton-banner-rectangle-shopify" />
        </>

  const banner = (
    <>
      <div className='info-wrp'>

        <span className='title'>{t('competitors_banner_title')}</span>
        <p className={'text'}>{t('Get inspiration from product listings, research your competitors or study the market of your next potentially winning product.')}</p>

        <Button type='primary'
                className='btn-primary'
                onClick={() => setVisible(true)}
                style={{minWidth: 'auto', padding: '0 24px', maxWidth: 146, maxHeight: 40}}
        >
          {t('Watch Tutorial')}
        </Button>
      </div>
      {!isMobile && <div className="image-wrapper"/>}
    </>
  )

  return (
    <div className={cls('competitor-research-empty-block', {
      dark: theme === 'dark',
      'skeleton-banner': skeleton
    })}>
      {
        skeleton ? renderSkeleton : banner
      }
    </div>
  );
};

export default ImagesBlock;
