import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import acc from 'accounting';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import EmptyState from './empty-state';
import SaveToCollection from '../SaveToCollections/save-to-collections';
import { ImageCellComponent as Image } from '../Image';
import Sort from './sort';
import Icon from '../../Icon';
import './styles-overview.less';

export const ProductsComponent = (
  {
    products,
    loading,
    tooltipsText,
    onSort,
    searchText,
    getNextList,
    next,
    addToCollectionFunc,
    isAdmin,
    breadcrumbs,
    isAdminEdit,
    onOpenUpgradePlan,
    isMobile,
  }) => {
  const productsRef = useRef();
  const { t } = useTranslation();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const path =
    isAdmin && !isAdminEdit
      ? '/admin/portfolio'
      : breadCrumbs?.filter((el) => !!el.link)[0].link || '/portfolio';

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight - 10;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (productsContent) {
        productsContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SCROLL TO TOP WHEN INPUT TEXT
  useEffect(() => {
    scrollToTop();
  }, [searchText]);

  // SCROLL TO TOP
  const scrollToTop = () => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  // ONSORT HANDLER
  const onSortHandler = (...props) => {
    scrollToTop();
    onSort(...props);
  };

  const lockedContent = (
    <div className={cls('block-locked block-locked_content')}>
      <div className="block-locked-info-wrap">
        <Icon role="icon" type="locked_block"/>
        <div className="block-locked-info-wrap block-locked-info-wrap_text">
          <span className="block-locked-title">{t('Locked Content')}</span>
          <span className="block-locked-text">{t('Upgrade your plan to unlock')}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={cls('products-overview', {
        'products-overview--empty': products.length === 0,
      })}
    >
      <div className="products-overview_sort">
        {products.length > 0 ? (
          <Sort tooltipsText={tooltipsText} onSort={onSortHandler} isMobile={isMobile}/>
        ) : null}
      </div>
      <Spin spinning={loading}>
        <div className="products-overview_wrapper"
             ref={productsRef}
        >
          <Row
            className="products-overview_wrapper_internal-block"
            gutter={{ xs: 0, sm: 16 }}
          >
            {products.length > 0 ? (
              products.map((value) => (
                <Col xs={24} md={12} lg={8} key={`col-${value.product_id}`}>
                  <div className="product" key={`product-${value.product_id}`}>
                    {
                      value['is_locked']
                        ? (
                          <div className="product_image">
                            {lockedContent}
                          </div>)
                        : (
                          <Link
                            to={`${path}/product/${value.product_id}`}
                            className="product_image"
                            state={value}
                          >
                            {value.image && <Image src={value?.image?.url}/>}
                          </Link>)
                    }

                    <div className="product_wrap" id="product_wrap">
                      {
                        value['is_locked']
                          ? (
                            <span className="product_name">
                              {value.title}
                            </span>)
                          : (
                            <Tooltip
                              overlayClassName="product_name-tooltip details-tooltip"
                              title={value.title}
                              trigger={isMobile ? 'click' : 'hover'}
                              destroyTooltipOnHide={true}
                              getPopupContainer={() =>
                                document.getElementById('product_wrap')
                              }
                            >
                              <Link
                                to={`${path}/product/${value.product_id}`}
                                className="product_name"
                              >
                                {value.title}
                              </Link>
                            </Tooltip>
                          )
                      }
                      {
                        isAdmin
                          ? (
                            <div className={cls('product_status', value?.status?.name)}/>)
                          : (
                            <div className="product_favorite">
                              <SaveToCollection
                                isDisabled={value['is_locked']}
                                saveFunc={(isSaved) =>
                                  addToCollectionFunc(
                                    value.product_id,
                                    isSaved,
                                    value.title,
                                  )
                                }
                                isSave={value.saved}
                              />
                            </div>
                          )}
                    </div>
                    <div className="product_details">
                      <div className="product_row">
                        <div className="product-detail">
                          <div className="product-detail_title">{t('Cost')}</div>
                          <div className="product-detail_value">{value.cost ? value?.cost?.replaceAll('.', ',') : 0}</div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">{t('Price')}</div>
                          <div className="product-detail_value">
                            ${acc.formatNumber(value.price, 2, ' ', ',')}
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">{t('P/C Ratio')}</div>
                          <div className="product-detail_value">
                            {acc.formatNumber(value.p_c_ratio, 2, ' ', '.')}X
                          </div>
                        </div>
                      </div>
                      <div className="product_row">
                        <div className="product-detail">
                          <div className="product-detail_title">{t('BECPA')}</div>
                          <div className="product-detail_value">
                            ${acc.formatNumber(value.BECPA, 2, ' ', ',')}
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">{t('BEROAS')}</div>
                          <div className="product-detail_value">
                            {acc.formatNumber(value.BEROAS, 2, ' ', '.')}
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="product-detail_title">{t('POT Profit')}</div>
                          <div className="product-detail_value">
                            ≈${acc.formatNumber(value.pot_profit, 0, ',', ',')}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      value['is_locked']
                        ? (
                          <div className={'product_view-details'}
                               onClick={() => onOpenUpgradePlan(value['is_locked'])}
                          >
                            {t('Upgrade Plan')}
                          </div>)
                        : (
                          <Link
                            to={`${path}/product/${value.product_id}`}
                            state={{...value}}
                            className="product_view-details"
                          >
                            {t(isAdminEdit ? 'Edit' : 'View Details')}
                          </Link>)
                    }
                  </div>
                </Col>
              ))
            ) : (
              <EmptyState isAdmin={isAdmin}/>
            )}
          </Row>
        </div>
      </Spin>
    </div>
  );
};

ProductsComponent.defaultProps = {
  products: [],
  loading: false,
};

ProductsComponent.propTypes = {
  isAdmin: PropTypes.bool,
  products: PropTypes.array,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  tooltipsText: PropTypes.object,
  searchText: PropTypes.string,
  getNextList: PropTypes.func.isRequired,
  addToCollectionFunc: PropTypes.func.isRequired,
  next: PropTypes.string,
};

export default ProductsComponent;
