import React from 'react';
import cls from 'classname';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { getSuffix } from '../../../../Utils/utils';
import Timer from '../timer';

const WeekCard = ({ link, drop, isEmptyTimer, maxProducts }) => {
  const content = (<div className="admin-drops-week_glob_wrap">
    <div className="admin-drops-week_wrap admin-drops-week_wrap_header">
          <span>
            {`${drop.week}${getSuffix(Number(drop.week))} Week`}
          </span>
      <span className="admin-drops-week_text">
            {`${drop['product_count']}/${maxProducts}`}
          </span>
    </div>

    <span className={cls('admin-drops-week_status', drop['drop_status'])}>
          {drop['drop_status']}
        </span>

    <Timer date={drop['drop_date']}
           disabled={['Released', 'Pending'].includes(drop['drop_status'])}
           isEmpty={isEmptyTimer}
    />

    <div className="admin-drops-week_wrap admin-drops-week_wrap_footer">
          <span>
            {
              drop['drop_date']
                ? dayjs(drop['drop_date']).format('MMMM DD, YYYY')
                : '-'
            }
          </span>

      <span>
            {
              drop['drop_id'] || '-'
            }
          </span>
    </div>
  </div>);

  if (link) {
    return (
      <Link to={link} state={{...link?.state}} className="admin-drops-week">
        {content}
      </Link>
    );
  } else {
    return (
      <div className="admin-drops-week">
        {content}
      </div>
    );
  }
};

export default WeekCard;
