import React, {useEffect} from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Image from "../../../Components/Image";
import { columnTitles } from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";
import {Dropdown} from "antd";
import IconWithText from "../../../Components/Text";
import Creators from "../../AutoDS/reducer";
import {useDispatch, useSelector} from "react-redux";

dayjs.extend(utc)

//competitors table columns
export const getColumns = (
  {
    sortOrder,
    setRecordToCalc,
    setVisible,
    setModal,
    disabled,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    toggleConnectStore,
    toggleConnectProduct,
    t,
    isAdmin,
    isMobile=false,
    visibleDropdown,
    setVisibleDropdown
  }) => {

  let result = [
    {
      title: () => 'Product',
      dataIndex: 'title',
      key: 'name',
      width: isMobile ? 'calc(100vw - 48px)' : 400,
      fixed: isMobile ? false : 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className={'product-database-table-image'}
               style={{cursor: 'default'}}
          >
            {
              record.main_image
                ? <img src={utils.changeImageSize(record?.main_image, 124)}
                       onError={utils.addDefaultSrc}
                       alt=""
                       width="62" height="62"/>
                : <Image src={Images.defaultImg} small={true}/>
            }
          </div>
          <div className="product-image-quantity">
            <Icon type={'database_product_images'}
                  role={'icon'}
                  color={'#707ba0'}
                  width={16}
                  height={16}
            />
            <span>{record.images}</span>
          </div>
          <div className={'product-info-wrapper'} style={{cursor: 'default'}}>
            <p className={'product-name'}>{record.title}
              <span className="product-is-deleted">
                    {record?.is_deleted_from_st ?
                      <Icon role="button"
                            type={`unavailable_store_triangle`}
                            className={`unavailable-store-triangle`}
                            width={18}
                            height={18}
                            titleText={t('This store has been closed or paused.')}
                            tooltipProps={{
                              placement: 'right',
                              trigger: 'hover',
                              destroyTooltipOnHide: true,
                              overlayClassName: cls('details-tooltip', `unavailable-store-triangle-tooltip`),
                              getPopupContainer: () => document.getElementById('product-database-table-body'),
                            }}
                      />
                      :
                      null}
                  </span>
            </p>
            <p>
              <a href={`https://${record.store.original_domain }/products/${record.handle}`}
                 target={'_blank'}
                 rel="noopener noreferrer"
              >
                  <span className={'product-url'}>
                    {
                      record?.store?.all_domains?.length > 0 ?
                        <>
                          {record?.store?.custom_domain}
                          <TooltipColumnIcon record={record} />
                        </>
                        :
                        record?.store?.custom_domain
                    }
                  </span>
              </a>
              <span className={'product-variants'}>{record.variants} {record.variants === 1 ? 'Variant': 'Variants'}</span>
            </p>
          </div>
        </div>
      ),
    },
    {
      title: '',
      fixed: isMobile ? false : 'left',
      dataIndex: 'quick_search',
      key: 'suppliers',
      width: 136,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-suppliers">
            {record.quick_search.map(el => {
              if (el?.platform === 'aliexpress') return (
                <a
                  key={el.platform}
                  href={el.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link with-img"
                >
                  <Icon role="button"
                        type={`search_${el.platform}`}
                        className={`${el.platform}-icon`}
                        width={el.platform === 'alibaba' ? 24 : 16}
                        height={el.platform === 'alibaba' ? 24 : 16}
                        titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>{el.platform}</span></>}
                        tooltipProps={{
                          placement: 'bottom',
                          trigger: 'hover',
                          destroyTooltipOnHide: true,
                          overlayClassName: cls('details-tooltip', `${el.platform}-tooltip`),
                          getPopupContainer: () => document.getElementById('product-database-table-body'),
                        }}
                  />
                </a>
              )
            })}
            <div onClick={() => {
              handleOpenModalAutoDSConnect({
                'source': 'shopify',
                'product_id': `${record?.store?.id}_${record?.id}`
              })
            }}>
              <Icon role="button"
                    type={'auto_ds'}
                    isTooltip={true}
                    width={21}
                    height={20}
                    titleText={() => <>{t('Import Product to AutoDS')}</>}
                    tooltipProps={{
                      placement: 'bottom',
                      trigger: 'hover',
                      destroyTooltipOnHide: true,
                      overlayClassName: cls('details-tooltip', `aliexpress-tooltip`),
                      getPopupContainer: () => document.getElementById('product-database-table-body'),
                    }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let  result = 'Price'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'usd_price',
          disabled: disabled
        })
      },
      dataIndex: 'usd_price',
      key: 'usd_price',
      width: 216,
      sorter: true,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={() => {
               setRecordToCalc(record);
               setVisible(true);
               setModal('numbersBreakdown');
             }}
        >
          <div className="product-price">
            <p>${acc.format(record.usd_price, 2, ',', '.')} {record.usd_price_max &&
              <span>- ${acc.format(record.usd_price_max, 2, ',', '.')}</span>}</p>
            <p style={{marginTop: 8, fontSize: 12, lineHeight: '16px', color: '#707ba0'}}>
              {(record.original_price > 0 || record.original_price_max > 0) && acc.format(record.original_price, 2, ',', '.')} {record.original_price_max > 0 &&
              <span>- {acc.format(record.original_price_max, 2, ',', '.')}</span>} {(record.original_price > 0 || record.original_price_max > 0) && record.store.currency}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Store Info'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'store_products_count',
          disabled: disabled
        })
      },
      dataIndex: 'store',
      key: 'store_products_count',
      width: 216,
      sorter: true,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-store-info">
            <p>{acc.format(record.store.products_count, 0, ',', '.')} Products</p>
            <p style={{ textTransform: 'capitalize' }}>{record.store.language}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Product Creation Date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
          disabled: disabled
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 256,
      sorter: true,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-creation-date">
            {
              record.created_at ?  dayjs(record.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Store Creation Date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'store_created_at',
          disabled: disabled
        })
      },
      dataIndex: ['store']['created_at'],
      key: 'store_created_at',
      width: 216,
      sorter: true,
      render: (text, record) => (
        <div className="product-database-table-cell">
          <div className="store-creation-date">
            {
              record.store.created_at ?  dayjs(record.store.created_at).format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Product Type'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'product_type',
          disabled: disabled
        })
      },
      dataIndex: 'product_type',
      key: 'product_type',
      width: 216,
      sorter: true,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-type">
            {record.product_type}
          </div>
        </div>
      ),
    },
  ];
  if (!isAdmin) result.push({
    title: '',
    key: 'actions',
    width: isMobile ? 112 : 162,
    sorter: false,
    fixed: 'right',
    render: (text, record) => (
      <div className={'product-database-table-cell cell-actions competitor-actions'}>
        <div className="product-actions">
            <span>
              <DropdownRender record={record}
                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                              handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                              t={t}
                              visibleDropdown={visibleDropdown}
                              setVisibleDropdown={setVisibleDropdown}
              />
            </span>
          <span>
              <DropdownRenderBtn record={record}
                                 toggleConnectProduct={toggleConnectProduct}
                                 toggleConnectStore={toggleConnectStore}
                                 t={t}
                                 cls={cls}
                                 isMobile={isMobile}
                                 visibleDropdown={visibleDropdown}
                                 setVisibleDropdown={setVisibleDropdown}
              />
            </span>
        </div>
      </div>
    ),
  });
  return result
}

const DropdownRender = ({record, handleOpenModalShopifyConnect, handleOpenModalAutoDSConnect, t, visibleDropdown, setVisibleDropdown}) => {

  const dispatch = useDispatch();
  const autoStores = useSelector(store => store?.autoDS?.stores) || [];

  const handleInnerVisibleChange = (value) => {
    if (value) setVisibleDropdown({[record?.id]: {first: value}});
    else setVisibleDropdown({});
  };

  useEffect(() => {
    if (visibleDropdown?.[record?.id]?.first && !autoStores?.length) {
      dispatch(Creators.getAutoStoreRequest());
    }
  }, [visibleDropdown]);

  return (
    <Dropdown
      open={visibleDropdown?.[record?.id]?.first}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
          <li className="action-dropdown_item no-hover"
              onClick={() => {
                setVisibleDropdown({});
                handleOpenModalShopifyConnect(`${record?.store?.id}_${record?.id}`)
              }}>
            <IconWithText
              size={24}
              icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
              text={t('Import Product to Shopify')}
            />
          </li>
          <li className="action-dropdown_item"
              onClick={() => {
                setVisibleDropdown({});
                handleOpenModalAutoDSConnect({
                  'source': 'shopify',
                  'product_id': `${record?.store?.id}_${record?.id}`
                })
              }}>
            <IconWithText
              icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
              text={t('Import Product to AutoDS')}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById('product-database-table-body')}
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" width={24} height={24}/>
      </span>
    </Dropdown>
  )
}

const DropdownRenderBtn = ({record, toggleConnectProduct, toggleConnectStore, t, cls, isMobile, visibleDropdown, setVisibleDropdown}) => {

  const handleInnerVisibleChange = (value) => {
    if (value) setVisibleDropdown({[record?.id]: {second: value}});
    else setVisibleDropdown({});
  };

  return (
    <Dropdown
      open={visibleDropdown[record?.id]?.second}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
          <li className={cls("action-dropdown_item",{'tracked': record?.is_tracked})}
              onClick={() => {
                setVisibleDropdown({})
                toggleConnectProduct(record)
              }}
          >
            <IconWithText
              size={24}
              icon={() => <Icon type={'database_price'} role={'icon'} /> }
              text={record?.is_tracked ? t('Stop Tracking Product') : t('Start Tracking Product')}
            />
          </li>
          <li className={cls("action-dropdown_item",{'tracked': record?.store?.is_tracked})}
              onClick={() => {
                setVisibleDropdown({})
                toggleConnectStore(record)
              }}
          >
            <IconWithText
              size={24}
              icon={() => <Icon type={'tracking_store_database'} role={'icon'} /> }
              text={record?.store?.is_tracked ? t('Stop Tracking Store') : t('Start Tracking Store')}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      placement="bottomRight"
      trigger={['click']}
      getPopupContainer={() => document.getElementById('product-database-table-body')}
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
                <span className={cls('product-actions-options-btn', {
                  'product-actions-options-btn--tracked': record?.is_tracked && record.store.is_tracked
                })}>
                  {
                    isMobile ?
                      null
                      :
                    record?.is_tracked && record.store.is_tracked ?
                      'Stop'
                      :
                      'Track'
                  }
                  <Icon type="gps" role="icon" width={24} height={24} outline={!(record?.is_tracked || record.store.is_tracked)}/>
                </span>
    </Dropdown>
  )
}
