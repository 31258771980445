import React, { useEffect, useState } from 'react';
import cls from 'classname';
import { Form } from 'antd';
import utils from '../../Utils/utils';
import Icon from "../../Icon";
import ButtonComponent from "../Button";
import InputComponent from "../Input";

function RestorePasswordForm({ error, ...props }) {

  const [errorText, setErrorText] = useState(null);
  const [emailIconColor, setEmailIconColor] = useState('#707BA0');
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.
  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
        setEmailIconColor('#D71313');
      }
    }
  }, [error]);

  const onFinish = values => {
    props.onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setEmailIconColor('#707BA0');
  }

  function handleChange(e) {
    const { name, value } = e.target;

    handleClearError(e, name);

    if (utils.validateEmail(value)) {
      if (isDisabled) setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }


  return (
    <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="login-form restore"
          layout="vertical"
    >

      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text', { 'error-text_empty': !errorText })}>
          {errorText && <Icon type={'alert_triangle_error'} role={'icon'} />}
          {errorText}
        </div>}
      </Form.Item>

      <Form.Item
        label={'Email'}
        name="email"
        validateStatus={errorText ? 'error' : null}
        rules={[
          { type: 'email', message: 'The email you entered is incorrect' },
        ]}
      >
        <InputComponent
          placeholder="Email Address"
          name="email"
          type="email"
          prefix={<Icon type={'email'} color={errorText ? '#D71313' : emailIconColor} />}
          onChange={(e) => {
            handleChange(e)
            setEmailIconColor('#225AEA')
          }}
          onFocus={() => setEmailIconColor('#225AEA')}
          onBlur={() => setEmailIconColor('#707BA0')}
        />
      </Form.Item>

      <ButtonComponent type="primary"
              htmlType="submit"
              className="btn-primary"
              style={{ width: '100%' }}
              disabled={isDisabled || !form.getFieldValue('email')}
      >
        Send Email
      </ButtonComponent>

      {
        props.children
      }

    </Form>
  );
}

export default RestorePasswordForm;
