import CategoryCreators from '../../Redux/CategoryRedux'
import PlanCreators from '../../Redux/PlanRedux'
import OnboardingCreators from '../../Redux/OnboardingRedux'
import NavCreators from '../../Redux/NavRedux';
import UserCreators from '../User/reducer';

/* eslint-disable import/no-named-as-default-member */
export default {
  getCategory: CategoryCreators.categoriesRequest,
  getPlan: PlanCreators.plansRequest,
  setSelectedPlan: OnboardingCreators.setSelectedPlan,
  setSwitchPlan: OnboardingCreators.setSwitchPlan,
  saveSubscription: OnboardingCreators.saveSubscriptionRequest,
  updateSubscription: OnboardingCreators.updateSubscriptionRequest,
  deleteSubscription: OnboardingCreators.deleteSubscriptionRequest,
  undoDeleteSubscription: OnboardingCreators.undoDeleteSubscriptionRequest,
  changeSubscriptionReset: OnboardingCreators.changeSubscriptionReset,
  changeNavSetProp: NavCreators.navSetProp,
  getUserInfo: UserCreators.userRequest,
};
