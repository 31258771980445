/* eslint-disable import/no-unresolved */
import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import dayjs from 'dayjs';
import { Avatar } from 'antd';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import Images from '../../Images';

export const columnsPaymentsDesktop = (showModal, t) => [
  {
    title: t('setting_billing.Amount'),
    dataIndex: 'total',
    key: 'total',
    fixed: true,
    width: 60,
    sorter: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record?.total/100, 2, ' ', ',')}</span>
    ),
  },
  {
    title: t('setting_billing.Status'),
    dataIndex: 'status',
    key: 'status',
    fixed: false,
    width: 80,
    sorter: false,
    render: (_, record) => (
      <span
        className={cls('status', {
          complited: record?.status?.id === 1,
          failed: record?.status?.id === 0 || record?.status?.id === 2,
        })}
      >
        {record?.status?.name}
      </span>
    ),
  },
  {
    title: t('setting_billing.Plan'),
    dataIndex: 'plan',
    key: 'plan',
    fixed: false,
    width: 110,
    sorter: false,
    render: (_, record) => {
      return (
        <span>
          {utils.toUpperLatter(record?.plan)}
        </span>
      );
    },
  },
  {
    title: t('setting_billing.Date'),
    dataIndex: 'date',
    key: 'date',
    fixed: false,
    width: 80,
    sorter: false,
    render: (_, record) => (
      <span>{dayjs(record.issued_date).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    title: t('setting_billing.Payment Method'),
    dataIndex: 'payment_method',
    key: 'payment_method',
    fixed: false,
    width: 120,
    sorter: false,
    render: (_, record) => (
      <span>
        {
          record.payment_method
            ? `${utils.toUpperLatter(record.payment_method?.card_type)} *${record.payment_method?.last_4}`
            : ''
        }
      </span>
    ),
  },
  {
    title: t('setting_billing.Invoice'),
    dataIndex: 'invoice',
    key: 'invoice',
    fixed: false,
    width: 110,
    sorter: false,
    render: (_, record) => (
      <span className="payment-invoice">
        <span>{record.invoice_id}</span>
        <Icon role="button" type="download" onClick={() => showModal(record)} />
      </span>
    ),
  },
];
export const columnsPaymentsMobile = (showModal, t) => [
  {
    title: t('setting_billing.Amount'),
    dataIndex: 'total',
    key: 'total',
    fixed: true,
    width: 90,
    sorter: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record?.total/100, 2, ' ', ',')}</span>
    ),
  },
  {
    title: t('setting_billing.Status'),
    dataIndex: 'status',
    key: 'status',
    fixed: false,
    width: 120,
    sorter: false,
    render: (_, record) => (
      <span
        className={cls('status', {
          complited: record?.status?.id === 1,
          failed: record?.status?.id === 0 || record?.status?.id === 2,
        })}
      >
        {t(record?.status?.name)}
      </span>
    ),
  },
  {
    title: t('setting_billing.Category'),
    dataIndex: 'category',
    key: 'category',
    fixed: false,
    width: 120,
    sorter: false,
    render: (_, record) => (
      <Avatar.Group>
        {record?.categories?.map((category) => (
          <Avatar
            src={Images[`${category?.name}Full`]}
            alt=""
            className={category?.name}
            key={category?.id}
          />
        ))}
      </Avatar.Group>
    ),
  },
  {
    title: t('setting_billing.Plan'),
    dataIndex: 'plan',
    key: 'plan',
    fixed: false,
    width: 110,
    sorter: false,
    render: (_, record) => {
      return (
      <span>
        {utils.toUpperLatter(t(record?.plan))}
      </span>
      );
    },
  },
  {
    title: t('setting_billing.Date'),
    dataIndex: 'date',
    key: 'date',
    fixed: false,
    width: 130,
    sorter: false,
    render: (_, record) => (
      <span>{dayjs(record.issued_date).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    title: t('setting_billing.Payment Method'),
    dataIndex: 'payment_method',
    key: 'payment_method',
    fixed: false,
    width: 180,
    sorter: false,
    render: (_, record) => (
      <span>
        {
          record.payment_method
            ? `${utils.toUpperLatter(record.payment_method?.card_type)} *${record.payment_method?.last_4}`
            : ''
        }
      </span>
    ),
  },
  {
    title: t('setting_billing.Invoice'),
    dataIndex: 'invoice',
    key: 'invoice',
    fixed: false,
    width: 190,
    sorter: false,
    render: (_, record) => (
      <span className="payment-invoice">
        <span>{record.invoice_id}</span>
        <Icon role="button" type="download" onClick={() => showModal(record)} />
      </span>
    ),
  },
];

export const changeColumns = (isMobile, showModal, t) => (
  isMobile
    ? columnsPaymentsMobile(showModal, t)
    : columnsPaymentsDesktop(showModal, t)
)
