import React, {useEffect, useState} from 'react';
import BreadcrumbsActions from "../Breadcrumbs/actions";
import {connect} from "react-redux";
import {useParams} from "react-router";
import {useNavigate, useLocation} from "react-router-dom";
import {BreadcrumbsComponent} from "../Breadcrumbs";
import UniversityCourseProgress from "./components/UniversityCourseProgress";
import UniversityCourseChapterCard from "./components/UniversityCourseChapterCard";
import UniversityCourseChapterSkeleton from "./components/skeletons/UniversityCourseChapterSkeleton";
import Creators from "./reducer";

const UniversityChapterPage = (
  {
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    loading,
    getChapterByID,
    chapterInfo,
    isMobile
  }
) => {

  const {courseID, chapter} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;

  const [firstLoading, setFirstLoading] = useState(true);

  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  useEffect(() => {
      if (breadCrumbs.length > 3) {
        removeBreadCrumb()
      }
      else if (breadCrumbs.length === 1) {
        addBreadCrumb({
          name: courseID,
          link: `/dropship-university/${courseID}`,
        });
        addBreadCrumb({
          name: state?.name ? state?.name : chapter,
          link: `/dropship-university/${courseID}/${chapter}`,
        });
      }
      else if (breadCrumbs.length < 3) {
        addBreadCrumb({
          name: state?.name ? state?.name : chapter,
          link: `/dropship-university/${courseID}/${chapter}`,
        });
      }
    document.title = 'University - Dropship';
    getChapterByID(chapter);
    if (firstLoading) {
      setTimeout(() => {
        setFirstLoading(false);
      }, 1000)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const clickHandler = (id, name) => navigate(`/dropship-university/${courseID}/${chapter}/${id}`, {
    state: {
      name: name
    }
  });

  if (firstLoading || loading) return (<UniversityCourseChapterSkeleton isMobile={isMobile} />)

  return (
    <div className={'product-database-page university-page course-chapter-page'}>

      <BreadcrumbsComponent
        breadCrumbs={breadCrumbs}
        isMobile={isMobile}
        isAdmin={false}
      />

      <h1 className="course-chapter-page">
        {chapterInfo?.title}
      </h1>
      <div className="course-chapter-page-content">
        <div className="course-chapter-page-chapter-wrapper">
          {
            chapterInfo?.lessons?.map(el => (
              <UniversityCourseChapterCard key={el?.id}
                                           isChapter={true}
                                           info={el}
                                           onClick={() => clickHandler(el?.id, el?.title)}
                                           isMobile={isMobile}
              />
            ))
          }
        </div>
        {
          isMobile ?
            null
            :
            <UniversityCourseProgress info={chapterInfo?.course} />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  loading: state.universityPage.loading,
  chapterInfo: state.universityPage.selectedChapter,
  isMobile: state.nav.isMobile,
})

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getChapterByID: (data) => dispatch(Creators.getChapterByIDRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UniversityChapterPage);
