import React from 'react';

const AdSpotEye = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.27002 10.7399C3.13935 8.78756 5.89147 4 12.0002 4C18.1091 4 20.861 8.78782 21.7302 10.7401C22.0885 11.5448 22.0885 12.4552 21.7302 13.2599C20.861 15.2122 18.1091 20 12.0002 20C5.89147 20 3.13935 15.2124 2.27002 13.2601C1.91162 12.4552 1.91162 11.5448 2.27002 10.7399ZM11.9999 16C14.2091 16 15.9999 14.2091 15.9999 12C15.9999 9.79086 14.2091 8 11.9999 8C9.7908 8 7.99994 9.79086 7.99994 12C7.99994 14.2091 9.7908 16 11.9999 16Z"
            fill="#225AEA"/>
      <path
        d="M12 13C11.788 13 11.5914 12.934 11.4296 12.8215C12.0626 12.5677 12.5677 12.0626 12.8215 11.4296C12.934 11.5914 13 11.788 13 12C13 12.5523 12.5523 13 12 13Z"
        fill="#225AEA" stroke="#225AEA" strokeWidth="2"/>
    </svg>
  );
};

export default AdSpotEye;
