import React from 'react';
import { Divider } from 'antd';

const DatabaseProductsSkeleton = ({isMobile=false}) => (
  <div className="product-database-table-wrapper">
    <div className="product-database-table-header">
      <div className={'product-database-table-header-skeleton'}>
        {
          [
            { width: 68, height: 20 },
            { width: 70, height: 32 },
            { width: 64, height: 20 },
          ].map(el => (<span key={el.width * el.height} className="link skeleton"
                             style={{ width: el.width, height: el.height }}/>))
        }
      </div>
      <div className={'product-database-table-header-skeleton'}>
        {
          [
            { width: 34, height: 20 },
            { width: 70, height: 32 },
            { width: 53, height: 20 },
            { width: 32, height: 32 },
            { width: 32, height: 32 },
          ].map((el, index) => (<span key={el.width * el.height * index}
                                      className="link skeleton" style={{ width: el.width, height: el.height }}/>))
        }
      </div>
    </div>

    <div className="product-database-table-subheader">
      <div style={{ width: '45%', paddingLeft: 24 }}>
        <span className="link skeleton" style={{ width: 62, height: 24 }}/>
      </div>
      {
        isMobile ?
          null
          :
          <div style={{ width: '14%' }}>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
            <span className="link skeleton" style={{ width: 62, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '20%' }}>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
            <span className="link skeleton" style={{ width: 109, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '15%' }}>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
            <span className="link skeleton" style={{ width: 133, height: 24 }}/>
          </div>
      }
    </div>
    <Divider type={'horizontal'} style={{ margin: '16px auto 12px' }}/>

    <div style={{ marginTop: 0 }} className="product-database-table-body">
      {[
        { id: 1, last: 68, third: { first: 74 } },
        { id: 2, last: 79, third: { first: 54, second: 61 } },
        { id: 3, last: 70, third: { first: 48, second: 49 } },
        { id: 4, last: 70, third: { first: 111, second: 119 } },
        { id: 5, last: 75, third: { first: 27, second: 66 } },
        { id: 6, last: 75, third: { first: 48 } },
        { id: 7, last: 75, third: { first: 49 } },
      ].map(row => (
        <div key={row.id} className={'product-database-table-skeleton-row'}>
          <div className={'product-database-table-skeleton-cell-wrapper'}>
            <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
              <div style={{ position: 'relative' }}>
                <span className="link skeleton" style={{ width: 64, height: 64 }}/>
                <span className="link skeleton"
                      style={{ position: 'absolute', width: 40, height: 20, left: 32, top: -4 }}
                />
              </div>

              <div style={{ marginLeft: 16 }}>
                <span className="link skeleton" style={{ width: isMobile ? 190 : 280, height: 20, marginTop: 10 }}/>
                <span className="link skeleton" style={{ width: 102, height: 20 }}/>
                <span className="link skeleton" style={{ width: 57, height: 20, marginLeft: 20 }}/>
              </div>
            </div>
            {
              isMobile ?
                null
                :
                <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'}>
                  <span className="link skeleton" style={{ width: 20, height: 20 }}/>
                  <span className="link skeleton" style={{ width: 20, height: 20, marginTop: 10 }}/>
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'}>
                  <span className="link skeleton" style={{ width: row.third.first, height: 20 }}/>
                  {row.third.second && <span className="link skeleton"
                                             style={{
                                               display: 'block',
                                               width: row.third.second,
                                               height: 20,
                                               marginTop: 10,
                                             }}
                  />}
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'}>
                  <span className="link skeleton" style={{ width: 36, height: 20 }}/>
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                  <span className="link skeleton" style={{ width: row.last, height: 20 }}/>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <span className="link skeleton" style={{ width: 20, height: 20 }}/>
                    <span className="link skeleton" style={{ width: 20, height: 20, marginTop: 21 }}/>
                  </div>
                </div>
            }
          </div>
          <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
        </div>
      ))}
    </div>
  </div>
);

export default DatabaseProductsSkeleton;
