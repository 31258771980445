import React from 'react';
import Icon from '../../../Icon';

export default function Footer({ scrollToTop }) {
  return (
    <div className="footer-block" onClick={scrollToTop}>
      <span>
        <Icon role="icon" type="arrow_left" />
      </span>
      <div>Scroll To Top</div>
      <span>
        <Icon role="icon" type="arrow_left" />
      </span>
    </div>
  );
}
