import React from 'react';

const ShopifyConnectNewAi = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M18.3333 10.3335C18.3333 9.78121 17.8856 9.3335 17.3333 9.3335C16.781 9.3335 16.3333 9.78121 16.3333 10.3335C16.3333 13.5679 15.6188 15.6685 14.3102 16.9771C13.0017 18.2856 10.9011 19.0002 7.66666 19.0002C7.11437 19.0002 6.66666 19.4479 6.66666 20.0002C6.66666 20.5524 7.11437 21.0002 7.66666 21.0002C10.9011 21.0002 13.0017 21.7147 14.3102 23.0232C15.6188 24.3318 16.3333 26.4324 16.3333 29.6668C16.3333 30.2191 16.781 30.6668 17.3333 30.6668C17.8856 30.6668 18.3333 30.2191 18.3333 29.6668C18.3333 26.4324 19.0478 24.3318 20.3564 23.0232C21.665 21.7147 23.7656 21.0002 27 21.0002C27.5523 21.0002 28 20.5524 28 20.0002C28 19.4479 27.5523 19.0002 27 19.0002C23.7656 19.0002 21.665 18.2856 20.3564 16.9771C19.0478 15.6685 18.3333 13.5679 18.3333 10.3335Z"
        fill="#225AEA" />
      <path
        d="M7.99999 7.3335C7.99999 6.96531 7.70151 6.66683 7.33332 6.66683C6.96513 6.66683 6.66666 6.96531 6.66666 7.3335C6.66666 8.641 6.37704 9.4303 5.90359 9.90376C5.43013 10.3772 4.64083 10.6668 3.33332 10.6668C2.96513 10.6668 2.66666 10.9653 2.66666 11.3335C2.66666 11.7017 2.96513 12.0002 3.33332 12.0002C4.64083 12.0002 5.43013 12.2898 5.90359 12.7632C6.37704 13.2367 6.66666 14.026 6.66666 15.3335C6.66666 15.7017 6.96513 16.0002 7.33332 16.0002C7.70151 16.0002 7.99999 15.7017 7.99999 15.3335C7.99999 14.026 8.2896 13.2367 8.76306 12.7632C9.23652 12.2898 10.0258 12.0002 11.3333 12.0002C11.7015 12.0002 12 11.7017 12 11.3335C12 10.9653 11.7015 10.6668 11.3333 10.6668C10.0258 10.6668 9.23652 10.3772 8.76306 9.90376C8.2896 9.4303 7.99999 8.641 7.99999 7.3335Z"
        fill="#225AEA" />
      <path
        d="M14.6667 2.00016C14.6667 1.63197 14.3682 1.3335 14 1.3335C13.6318 1.3335 13.3333 1.63197 13.3333 2.00016C13.3333 2.84471 13.1456 3.30067 12.8897 3.55654C12.6338 3.8124 12.1779 4.00016 11.3333 4.00016C10.9651 4.00016 10.6667 4.29864 10.6667 4.66683C10.6667 5.03502 10.9651 5.3335 11.3333 5.3335C12.1779 5.3335 12.6338 5.52126 12.8897 5.77712C13.1456 6.03299 13.3333 6.48895 13.3333 7.3335C13.3333 7.70169 13.6318 8.00016 14 8.00016C14.3682 8.00016 14.6667 7.70169 14.6667 7.3335C14.6667 6.48895 14.8544 6.03299 15.1103 5.77712C15.3661 5.52126 15.8221 5.3335 16.6667 5.3335C17.0348 5.3335 17.3333 5.03502 17.3333 4.66683C17.3333 4.29864 17.0348 4.00016 16.6667 4.00016C15.8221 4.00016 15.3661 3.8124 15.1103 3.55654C14.8544 3.30067 14.6667 2.84471 14.6667 2.00016Z"
        fill="#225AEA" />
    </svg>
  );
};

export default ShopifyConnectNewAi;
