import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../reducer';
import Chart from '../../../Components/Charts/Chart';
import dayjs from 'dayjs';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import Icon from '../../../Icon';
import { Divider } from 'antd';
import AppUtils from '../../../Utils/utils';

const PriceHistory = ({ product, isPD = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector(store => store?.nav?.isMobile);
  const loading = useSelector(store => store?.productDatabase?.productPriceLoading);
  const data = useSelector(store => store?.productDatabase?.productPriceData?.chart) || [];
  const productPriceData = useSelector(store => store?.productDatabase?.productPriceData) || {};

  useEffect(() => {
    dispatch(Creators.getProductPriceHistoryRequest({
      isPD: isPD,
      store_id: product?.store?.id,
      product_id: product?.id,
    }));

    return () => {
      dispatch(Creators.resetProductPrice());
    };
  }, []);

  return (
    <div className={'load-filter-preset-wrapper breakdown-database-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Price Dynamics')}
      </div>
      <div className={'price-modal-info'}>
        <div className={'price-modal-info-logo'}>
          {
            product?.main_image ?
              <img src={product?.main_image}
                   alt=""
                   onError={utils.addDefaultSrc}
                   width="62" height="62"
              />
              :
              <Image src={Images.defaultImg} small={true} />
          }
        </div>
        <span className="price-modal-info-title">
          {product?.title}
        </span>
      </div>
      {
        loading ?
          <div className={'price-modal-chart-wrapper'}>
            <div className={'price-modal-period'}>
              <span className="link skeleton" style={{ width: 192, height: 20, display: 'block' }} />
              <span className="link skeleton" style={{ width: 238, height: 20, display: 'block' }} />
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 6 }}>
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                  <span className="link skeleton" style={{ width: 33, height: 16, marginTop: 10 }} />
                </div>
                <div style={{ width: 'calc(100% - 39px)', height: 214 }}>
                  <span className="link skeleton" style={{ width: '100%', height: '100%', display: 'block' }} />
                </div>
              </div>
              <div style={{
                width: 'calc(100% - 39px)',
                marginLeft: 39,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
                  <span key={el * index} className="link skeleton" style={{ width: el, height: 16 }} />
                ))}
              </div>
            </div>
          </div>
          :
          <div className={'price-modal-chart-wrapper'}>
            <div className={'price-modal-period'}>
              <p>
                {dayjs(data?.[0]?.date).format('MMM DD, YYYY')} - {dayjs(data?.[data?.length - 1]?.date).format('MMM DD, YYYY')}
              </p>
              <p>
                <span style={{marginRight: 8}}>
                  {t('Updated')} {AppUtils.durationAsString(new Date().toISOString(), productPriceData?.last_updated, t)}
                </span>
                <Icon type={'attention_outline'}
                      role={'button'}
                      width={16}
                      height={16}
                      tooltipProps={{
                        overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                        placement: 'top',
                        title: () => (
                          <div className={'expandable-chart-info-tooltip'}>
                            <div className={'expandable-chart-info-tooltip-title'}>
                              {t('Dashboard data syncs every 2-3 hours. However, delays may occur when high volumes are being processed.')}
                            </div>
                            <Divider style={{ margin: '12px 0' }} />
                            <div className={'expandable-chart-info-tooltip-text'}>
                              <p>
                                {
                                  t('Last updated on')
                                }
                              </p>
                              <p>
                                {dayjs(productPriceData?.last_updated).format('lll')} UTC
                              </p>
                            </div>
                          </div>
                        ),
                        destroyTooltipOnHide: true,
                        getPopupContainer: (trigger) => trigger.parentNode,
                      }}
                />
              </p>
            </div>
            <Chart type={'price_dynamics'}
                   data={data.length ? [...data] : []}
                   loading={false}
                   isMobile={isMobile}
            />
          </div>
      }
    </div>
  );
};

export default PriceHistory;
