import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import utils, {categoryClassName} from '../../Utils/utils';

import './styles.less';

const Wave = ({ type, categoryName, position }) => {
  const delay = utils.randomNum(1, 9);

  if (type === 'horizontal') {
    return (
      <div className={`wave-wrapper horizontal`}>
        <div className="wave" style={{ right: position ? `-${position}%` : '10px', animationDelay: `-${delay}s` }}/>
      </div>
    );
  } else {
    return (
      <div className={cls('wave-wrapper', categoryClassName[categoryName])}>
        <div className="wave" style={{ top: position ? `-${position - 10}%` : '10px', animationDelay: `-${delay}s` }}/>
      </div>
    );
  }
};

export const WaveOther = ({ categoryName, positionTop }) => (
  <div className={cls('wave-wrapper', categoryClassName[categoryName])}>
    <div
      className="wave"
      style={positionTop ? { top: `-${positionTop - 10}%` } : { top: '-300px' }}
    />
  </div>
);

Wave.defaultProps = {
  categoryName: 'random',
};

Wave.propTypes = {
  type: PropTypes.string,
  categoryName: PropTypes.string,
  positionTop: PropTypes.number,
};

export default React.memo(Wave);
