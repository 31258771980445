import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import SettingCreators from '../../Containers/SettingPage/reducer';
import PlanDetails from './PlanDetails';
import PaymentMethod from './PaymentMethod';
import BillingAddress from './BillingAddress';
import CompanyDetails from './CompanyDetails';
import PaymentHistory from './PaymentHistory';
import OnboardingActions from '../../Redux/OnboardingRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import './styles.less';

function BillingSubPage(
  {
    user,
    payments,
    paymentDetails,
    fetching,
    saveResult,
    saveFetching,
    errors,
    theme,
    isMobile,
    onBoarding,
    defaultUserCountry,
    resetErrors,
    resetResult,
    getCountries,
    addCompanyDetails,
    changeCompanyDetails,
    getPaymentsNext,
    changeBillingAddress,
    changePayments,
    createIntent,
    clearIntent,
    setYearlySubscription,
    setChargebeeCardFetching,
    errorSave,
    getPaymentDetails,
    changeStripeCard,
  }) {
  return (
    <div className="billing-subpage">
      <Row
        gutter={[
          { xs: 0, md: 16 },
          { xs: 48, sm: 48, md: 16 },
        ]}
      >
        <Col xs={24} sm={24} xl={12}>
          <PlanDetails
            subscriptions={user?.subscriptions || []}
            setYearlySubscription={setYearlySubscription}
            isMobile={isMobile}
          />
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <PaymentMethod
            user={user}
            getCountries={getCountries}
            isMobile={isMobile}
            saveFetching={saveFetching}
            theme={theme}
            changePayments={changePayments}
            saveResult={saveResult}
            errors={errors}
            resetErrors={resetErrors}
            resetResult={resetResult}
            setChargebeeCardFetching={setChargebeeCardFetching}
            defaultUserCountry={defaultUserCountry}
            onBoarding={onBoarding}
            clearIntent={clearIntent}
            createIntent={createIntent}
            errorSave={errorSave}
            changeStripeCard={changeStripeCard}
          />
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <BillingAddress
            user={user}
            resetErrors={resetErrors}
            saveResult={saveResult}
            errors={errors}
            resetResult={resetResult}
            changeBillingAddress={changeBillingAddress}
            saveFetching={saveFetching}
            getCountries={getCountries}
            countries={onBoarding.countries}
            isMobile={isMobile}
          />
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <CompanyDetails
            user={user}
            isMobile={isMobile}
            getCountries={getCountries}
            countries={onBoarding.countries}
            addCompanyDetails={addCompanyDetails}
            changeCompanyDetails={changeCompanyDetails}
            saveResult={saveResult}
            errors={errors}
            saveFetching={saveFetching}
            resetErrors={resetErrors}
            resetResult={resetResult}
            defaultUserCountry={defaultUserCountry}
          />
        </Col>
        <Col xs={24} sm={24}>
          <PaymentHistory
            user={user}
            fetching={fetching}
            payments={payments}
            paymentDetails={paymentDetails}
            getPaymentsNext={getPaymentsNext}
            getPaymentDetails={getPaymentDetails}
            isMobile={isMobile}
          />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  payments: state.setting.payments,
  paymentDetails: state.setting.paymentDetailsResult,
  fetching: state.setting.fetching,
  saveResult: state.setting.saveResult,
  saveFetching: state.setting.saveFetching,
  errors: state.setting.errors,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
  onBoarding: state.onBoarding,
  defaultUserCountry: state.setting.userCountry,
});

const mapDispatchToProps = (dispatch) => ({
  resetErrors: () => dispatch(SettingCreators.settingsErrorsReset()),
  resetResult: () => dispatch(SettingCreators.settingsSaveResultReset()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  addCompanyDetails: (data) => dispatch(SettingCreators.addCompanyDetailsRequest(data)),
  changeCompanyDetails: (data) => dispatch(SettingCreators.changeCompanyDetailsRequest(data)),
  getPaymentsNext: (link) => dispatch(SettingCreators.getPaymentsNextRequest({ link })),
  changeBillingAddress: (data) => dispatch(SettingCreators.changeBillingAddressRequest(data)),
  changePayments: (data) => dispatch(SettingCreators.changePaymentsRequest(data)),
  createIntent: data => dispatch(OnboardingActions.createIntentRequest(data)),
  changeStripeCard: () => dispatch(OnboardingActions.changeStripeCardRequest()),
  clearIntent: () => dispatch(OnboardingActions.createIntentReset()),
  setYearlySubscription: (state) => dispatch(OnboardingActions.setYearlySubscription(state)),
  setChargebeeCardFetching: (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value)),
  errorSave: (error) => dispatch(ErrorsActions.errorSave(error)),
  getPaymentDetails: (id) => dispatch(SettingCreators.getPaymentDetailsRequest({ id })),
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingSubPage);
