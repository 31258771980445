import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProgressCircle from '../progressCircle';
import ImagesComponent from './images';
import ActionsComponent from './actions';

export const CollectionItemComponent = ({
  id,
  general,
  products,
  name,
  products_count,
  collection_statistic,
  setAsDefaultFunc,
  renameCollectionFunc,
  removeCollectionFunc,
  saveCollection,
  ...rest
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  // ON CLICK TAB HANDLER
  const onTabClickHandler = (index) => {
    setActiveTab(index);
  };

  // ON CHANGE HANDLER
  const onChangeHandler = ({ result, value }) => {
    if (result) {
      renameCollectionFunc({ collectionID: id, name: value });
    }
  };

  function handleResend({ collectionID, collectionName }) {
    navigate(`/collections/product-list/${collectionID}`, {
      state: {
        name: collectionName,
        link: `/collections/product-list/${collectionID}`,
      }
    });
    saveCollection({ collectionID, collectionName });
  }

  return (
    <div
      className={cls('collection-item', {
        'collection-item--empty-state': products.length === 0,
      })}
      key={`collection-item-${id}`}
    >
      {/* IMAGE */}
      <ImagesComponent
        id={id}
        products={products}
        handleResend={handleResend}
        name={name}
      />
      <div className="collection-item_wrapper">
        {/* NAME, STAR, SELECT */}
        <ActionsComponent
          isDefault={rest?.default}
          isGeneral={general}
          name={name}
          handleResend={handleResend}
          id={id}
          onChangeHandler={onChangeHandler}
          removeCollectionFunc={removeCollectionFunc}
          setAsDefaultFunc={setAsDefaultFunc}
        />

        {/* STATISTIC */}
        <div className="collection-item_statistic">
          <div className="collection-item_statistic-name">
            {t('collections.statistics')}
          </div>
          {/* eslint-disable-next-line camelcase */}
          <div className="collection-item_statistic-count">{`${products_count} ${t(
            'collections.products',
          )}`}</div>
        </div>

        {/* TABS */}
        <div
          className={cls('collection-item_tabs', {
            'collection-item_tabs--empty-state': !products.length,
          })}
        >
          {collection_statistic.map((tab, keyIndex) => (
            <div
              className={cls('collection-item_tab', {
                'collection-item_tab--active': activeTab === keyIndex && products.length,
              })}
              onClick={() => onTabClickHandler(keyIndex)}
              key={`collection-tab-${keyIndex}`}
              style={{ order: tab?.id }}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <div className="collection-item_tabs-wrapper">
          {/* COLLECTION INFO */}
          <ProgressCircle
            name={collection_statistic[activeTab]?.name}
            value={collection_statistic[activeTab]?.bar * 100}
            lowest={collection_statistic[activeTab]?.minimum}
            highest={collection_statistic[activeTab]?.maximum}
            average={collection_statistic[activeTab]?.average}
          />
        </div>
      </div>
    </div>
  );
};

CollectionItemComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  products: PropTypes.array,
  name: PropTypes.string,
  products_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  collection_statistic: PropTypes.array,
  setAsDefaultFunc: PropTypes.func,
  removeCollectionFunc: PropTypes.func,
  renameCollectionFunc: PropTypes.func,
};

export default CollectionItemComponent;
