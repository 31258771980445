import React from 'react';
import CascadingDropdown from "./CascadingDropdown";
import {Divider} from "antd";
import DatabaseProductsPagination from "../../ProductDatabasePage/components/DatabaseProductsPagination";

const Pagination = (
  {
    skeleton,
    sortOrder,
    setSortOrder,
    isMobile,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    totalAds,
    isTrial,
    disabled=false,
    isShort=false
  }
) => {

  return (
    <>
      <div className="fadspot-page-pagination-wrapper" style={{justifyContent: "space-between", display: skeleton ? 'flex' : 'none'}}>
        <span className="link skeleton" style={{width: 172, height: 32}}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: isShort ? "flex-end" : "space-between", width: 'calc(100% - 200px)'}}>
          <span className="link skeleton" style={{width: 232, height: 32}}/>
          {
            isShort ?
              null
              :
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span className="link skeleton" style={{width: 173, height: 32, marginRight: 16}}/>
                <span className="link skeleton" style={{width: 32, height: 32, marginRight: 8}}/>
                <span className="link skeleton" style={{width: 32, height: 32}}/>
              </div>
          }
        </div>
      </div>
      <div className="fadspot-page-pagination-wrapper" style={{display: skeleton ? 'none' : 'flex'}}>
        <CascadingDropdown value={sortOrder}
                           setValue={setSortOrder}
                           isMobile={isMobile}
                           isTrial={isTrial}
                           disabled={disabled}
        />

        {
          isMobile || isShort ?
            null
            :
            <Divider type={'vertical'} style={{margin: '0 16px', height: 32}} />
        }

        <DatabaseProductsPagination pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    totalProducts={totalAds}
                                    disabled={disabled}
                                    isTopStores={false}
                                    isTopProducts={false}
                                    isTrial={isTrial}
                                    isShort={isShort}
        />
      </div>
    </>
  );
};

export default Pagination;
