import React from 'react';

const SalesTrackerNoteSkeleton = () => {
  return (
    <div className='sales-tracker-note-wrapper' style={{ border: 'none', padding: 0 }}>
      <span className="link skeleton" style={{width: '100%', height: 84}}/>
    </div>
  );
};

export default SalesTrackerNoteSkeleton;
