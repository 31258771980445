import React from 'react';
import cls from 'classname';
import dayjs from 'dayjs';

import { Statistic } from 'antd';
import Countdown, { zeroPad } from 'react-countdown';

import './styles.less';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

const Timer = ({ date, disabled, isEmpty }) => {
  const nextDrop = +dayjs.utc(date).format('x');
  const isDisabled = disabled || isEmpty;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className={cls('drops-timer-wrapper', {disabled: isEmpty})}>
        <div className={`drops-timer-box`}>
          <div className={'ant-statistic'}>
            <div className={'ant-statistic-content'}>{zeroPad(days, 1)}</div>
          </div>
        </div>

        <div className={`drops-timer-box`}>
          <div className={'ant-statistic'}>
            <div className={'ant-statistic-content'}>{zeroPad(hours)}</div>
          </div>
        </div>

        <div className={`drops-timer-box`}>
          <div className={'ant-statistic'}>
            <div className={'ant-statistic-content'}>{zeroPad(minutes)}</div>
          </div>
        </div>

        <div className={`drops-timer-box`}>
          <div className={'ant-statistic'}>
            <div className={'ant-statistic-content'}>{zeroPad(seconds)}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'drops-timer-block'}>
      {isDisabled ? (
        <div className="drops-timer-wrapper disabled">
          <div className="drops-timer-box">
            <Statistic title="" value="-" />
          </div>

          <div className="drops-timer-box">
            <Statistic title="" value="-" />
          </div>

          <div className="drops-timer-box">
            <Statistic title="" value="-" />
          </div>

          <div className="drops-timer-box">
            <Statistic title="" value="-" />
          </div>
        </div>
      ) : (
        <Countdown date={nextDrop} renderer={renderer} onComplete={() => alert('countdown ends')}/>
      )}
    </div>
  );
};

export default Timer;
