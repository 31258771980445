import React from 'react';

const UniversityCourseChapterCardSkeleton = ({isMobile}) => {
  return (
    <div className='university-course-chapter-card-wrapper'>
      <span className='link skeleton' style={{width: 132, height: 74}}/>
      <div className="university-course-chapter-card-content">
        <div className="university-course-chapter-card-info">
          <span className='link skeleton' style={{width: 124, height: 24}}/>
          <span className='link skeleton' style={{width: 96, height: 16, marginTop: 16}}/>
        </div>
        {
          isMobile ?
            null
            :
            <span className='link skeleton' style={{width: 97, height: 20}}/>
        }
      </div>
    </div>
  );
};

export default UniversityCourseChapterCardSkeleton;
