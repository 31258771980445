import React, {useEffect} from 'react';
import BreadcrumbsActions from "../Breadcrumbs/actions";
import {connect} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {useParams} from "react-router";
import Creators from "./reducer";
import UniversityCourseChapterCard from "./components/UniversityCourseChapterCard";
import UniversityCourseChapterSkeleton from "./components/skeletons/UniversityCourseChapterSkeleton";
import {BreadcrumbsComponent} from "../Breadcrumbs";
import UniversityCourseProgress from "./components/UniversityCourseProgress";
import './UniversityCoursePage.less';

const UniversityCoursePage = (
  {
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    clearBreadCrumbs,
    getCourseByID,
    courseInfo,
    loading,
    isMobile
  }
) => {

  const {courseID} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;

  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  useEffect(() => {
    if (breadCrumbs.length > 3) {
      clearBreadCrumbs();
      addBreadCrumb({
        name: 'Dropship University',
        link: `/dropship-university`,
      });
      addBreadCrumb({
        name: state ? state?.name : courseInfo ? courseInfo?.title : courseID,
        link: `/dropship-university/${courseID}`,
      });
    }
    else if (breadCrumbs.length === 3) {
      removeBreadCrumb()
    }
    else if (breadCrumbs.length < 2) {
      addBreadCrumb({
        name: state ? state?.name : courseInfo ? courseInfo?.title : courseID,
        link: `/dropship-university/${courseID}`,
      });
    }
    document.title = 'University - Dropship';
    getCourseByID(courseID)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const clickHandler = (chapterId, title) => navigate(`/dropship-university/${courseID}/${chapterId}`, {
    state: {name: title}
  });

  if (loading) return (<UniversityCourseChapterSkeleton isMobile={isMobile} />)

  return (
    <div className={'product-database-page university-page course-chapter-page'}>

      <BreadcrumbsComponent
        breadCrumbs={breadCrumbs}
        isMobile={isMobile}
        isAdmin={false}
      />

      <h1 className="course-chapter-page">
        Course Chapters
      </h1>
      <div className="course-chapter-page-content">
        <div className="course-chapter-page-chapter-wrapper">
          {
            (courseInfo?.chapters ? courseInfo?.chapters : []).map(el => (
              <UniversityCourseChapterCard key={el?.id}
                                           info={el}
                                           onClick={() => clickHandler(el?.id, el?.title)}
                                           isMobile={isMobile}
              />
            ))
          }
        </div>
        {
          isMobile ?
            null
            :
            <UniversityCourseProgress info={courseInfo} />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  courseInfo: state.universityPage.selectedCourse,
  loading: state.universityPage.loading,
  isMobile: state.nav.isMobile,
})

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getCourseByID: (id) => dispatch(Creators.getCourseByIDRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UniversityCoursePage);
