import React from 'react';
import Icon from '../Icon';

export const titleSortFilters = ({ title, stateData, key }) => {
  let sortImage;

  if (stateData?.sortColumn?.dataIndex === key) {
    if (stateData?.sortOrder === 'descend') {
      sortImage = (
        <Icon type="arrow_downward" role="icon" className="icon-descend" />
      );
    } else if (stateData?.sortOrder === 'ascend') {
      sortImage = (
        <Icon type="arrow_downward" role="icon" className="icon-ascend" />
      );
    } else {
      sortImage = <Icon type="arrow_downward" role="icon" />;
    }
  } else {
    sortImage = <Icon type="arrow_downward" role="icon" />;
  }

  return (
    <div className="table-column-title">
      {sortImage}
      <span>{title}</span>
    </div>
  );
};

export const columnTitles = ({ sortOrder, title, key }) => {
  let sortImage;

  if (sortOrder?.includes(key)) {
    if (sortOrder === '-' + key) {
      sortImage = (
        <Icon type="arrow_downward" role="icon" className="icon-descend"/>
      );
    } else if (sortOrder === key) {
      sortImage = (
        <Icon type="arrow_downward" role="icon" className="icon-ascend"/>
      );
    } else {
      sortImage = <Icon type="arrow_downward" role="icon"/>;
    }
  } else {
    sortImage = <Icon type="arrow_downward" role="icon"/>;
  }

  return (
    <div className="table-column-title">
      {sortImage}
      <span>{title}</span>
    </div>
  );
};
