import React, {useEffect, useState} from 'react';
import {changeGreetingMessage} from "../../Utils/utils";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import BreadcrumbsActions from "../Breadcrumbs/actions";
import Creators from "./reducer";
import Icon from "../../Icon";
import UniversityVideoCard from "./components/UniversityVideoCard";
import UniversityPageSkeleton from "./components/skeletons/UniversityPageSkeleton";
import UniversityPageCourseWrapper from "./components/UniversityPageCourseWrapper";
import {useNavigate} from "react-router-dom";
import './styles.less'

const UniversityPage =
  ({
     isMobile,
     addBreadCrumb,
     clearBreadCrumbs,
     getCourses,
     getAdditionalChapters,
     additionalChapters,
     courses,
     loading,
     setSelectedChapter,
     setIsShort
  }) => {

  const {t} = useTranslation();

  const navigate = useNavigate();

  const [greetingMessage, setGreetingMessage] = useState('Welcome');

  useEffect(() => {
    changeGreetingMessage(greetingMessage, setGreetingMessage);
    getCourses();
    getAdditionalChapters();
    setIsShort(false);
    document.title = 'University - Dropship';
    clearBreadCrumbs();
    addBreadCrumb({
      name: 'Dropship University',
      link: `/dropship-university`,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  return (
    <div className={'product-database-page university-page'}>
      {
          loading ?
            <UniversityPageSkeleton isMobile={isMobile} />
            :
            <>
              <div className="sales-tracker-top-page-header">
                <div className="title-wrapper">
                  <div className="title-wrapper-icon">
                    <Icon type={`university_dashboard`} role={'icon'} />
                  </div>
                  <div className="sales-tracker-top-page-subheader">
                    <h3 className="sales-tracker-top-page-title">
                      {t(`Dropship University`)}
                    </h3>
                    <h4 className="sales-tracker-top-page-subtitle">
                      {t(`Learn dropshipping from experts`)}
                    </h4>
                  </div>
                </div>
                <div className={'sales-tracker-top-page-limits-wrapper'}>
                  <div style={{width: 260}} className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => navigate('/dropship-university/7fb9d010-ac02-4820-8cc2-0cf3ab1a9144')}
                  >
                    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                    <span>{t('Watch Dropshipping Course')}</span>
                  </div>
                </div>
              </div>

              {
                courses?.results?.map(el => (
                    <UniversityPageCourseWrapper info={el}
                                                 key={el?.id}
                                                 disabled={false}
                                                 skeleton={loading}
                                                 isComingSoon={false}
                    />
                  )
                )
              }
              {
                additionalChapters?.results?.map(el => (
                  <div key={el?.id}>
                    <div className="university-page-tools-wrapper">
                      <div className="university-page-tools-header">
                        <h4 className="university-page-tools-title">
                          {el?.title}
                        </h4>
                        <p className="university-page-tools-subtitle">
                          {el?.description}
                        </p>
                      </div>
                    </div>
                    <div className="university-page-cards" key={el?.[0]?.id}>
                            {
                              el?.lessons.map(lesson => <UniversityVideoCard key={lesson?.id}
                                                                      info={lesson}
                                                                      chapter={el}
                                                                      setSelected={setSelectedChapter}
                                                                      setIsShort={setIsShort}
                              />)
                            }
                    </div>
                  </div>
                ))
              }
            </>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  courses: state.universityPage.courses,
  additionalChapters: state.universityPage.additionalChapters,
  loading: state.universityPage.loading,
})

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getCourses: () => dispatch(Creators.getCoursesRequest()),
  getAdditionalChapters: () => dispatch(Creators.getAdditionalChaptersRequest()),
  setSelectedChapter: (data) => dispatch(Creators.setSelectedChapter(data)),
  setIsShort: (data) => dispatch(Creators.setIsShort(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UniversityPage);
