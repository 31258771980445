import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import utils from '../../../../Utils/utils';
import './styles.less';

export const ProgressCircle = ({ value, lowest, highest, average, name }) => {
  const { t } = useTranslation();
  const angle = value > 100 ? -90 + (180 * 100) / 100 : -90 + (180 * value) / 100;

  return (
    <div
      className={cls(`progress-circle p${value}`, {
        'progress-circle--empty': !value,
      })}
    >
      <div className="progress-circle_stat progress-circle_stat--lowest">
        <div className="progress-circle_stat_name">
          {t('collections.lowest')}
        </div>
        <div className="progress-circle_stat_count">
          {value ? `$${utils.convertNumber(lowest, 1).replace('.', ',')}` : '-'}
        </div>
      </div>
      <div className="progress-circle_stat progress-circle_stat--highest">
        <div className="progress-circle_stat_name">
          {t('collections.highest')}
        </div>
        <div className="progress-circle_stat_count">
          {value ? `$${utils.convertNumber(highest, 1).replace('.', ',')}` : '-'}
        </div>
      </div>
      <div className="progress-circle_stat progress-circle_stat--average">
        <div className="progress-circle_stat_name">
          {t('collections.average')}
        </div>
        <div className="progress-circle_stat_count">
          {value ? `$${utils.convertNumber(average, 1).replace('.', ',')}` : '-'}
        </div>
      </div>
      <div className="left-half-clipper">
        <div
          className="value-bar"
          style={{ transform: `rotate(${angle}deg)` }}
        />
      </div>
    </div>
  );
};

ProgressCircle.defaultProps = {
  value: 0,
  lowest: '-',
  highest: '-',
  average: '-',
};

ProgressCircle.propTypes = {
  value: PropTypes.number,
  lowest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  average: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProgressCircle;
