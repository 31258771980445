import React from 'react';
import {Divider} from "antd";

const UniversityCourseProgress = ({info}) => {

  const {title, thumbnail, lessons_count, completed_lessons_count, instructor} = info;

  const progressWidth = completed_lessons_count === 0 ? '8px'
    :
    `${(completed_lessons_count * 100)/lessons_count}%`

  return (
    <div className="course-chapter-page-progress-wrapper">
      <div className="course-chapter-page-progress">
        <div className="course-chapter-page-progress-image"
             style={{backgroundImage: `url("${thumbnail}")`}}
        />
        <div className="course-chapter-page-progress-info">
          <h4 className="course-chapter-page-progress-info-title">
            {title}
          </h4>
          <p className="course-chapter-page-progress-info-subtitle">
            {`${completed_lessons_count} of ${lessons_count} Lessons Completed`}
          </p>
          <div className="course-chapter-page-progress-info-bar">
            <span style={{width: progressWidth,}} />
          </div>
        </div>
      </div>
      <div className="course-chapter-page-instructor">
        <h4 className="course-chapter-page-instructor-title">
          Instructor
        </h4>
        <div className="course-chapter-page-instructor-info">
          <div className="course-chapter-page-instructor-image"
               style={{backgroundImage: `url("${instructor?.avatar}")`}}
          />
          <div className="course-chapter-page-instructor-bio">
            <div className="course-chapter-page-instructor-name">
              {instructor?.name}
            </div>
            <div className="course-chapter-page-instructor-subtitle">
              {instructor?.job_position}
            </div>
          </div>
        </div>
        <Divider type={'horizontal'} className={'course-chapter-page-instructor-divider'} />
        <div className="course-chapter-page-instructor-text">
          {instructor?.description}
        </div>
      </div>
    </div>
  );
};

UniversityCourseProgress.defaultProps = {
  info: {
    title: 'title',
    thumbnail: null,
    lessons_count: 1,
    completed_lessons_count: 1,
    instructor: {
      avatar: null,
      name: 'name',
      job_position: 'job',
      description: 'description',
    },
  }
}

export default UniversityCourseProgress;
