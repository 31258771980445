import React from 'react';

const AdSpotViews = (
  {
    color='#707ba0'
  }
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12 6C7.14475 6 4.88981 9.77258 4.09683 11.5534C3.96902 11.8405 3.96902 12.1595 4.09683 12.4466C4.88981 14.2274 7.14475 18 12 18C16.8553 18 19.11 14.2273 19.9028 12.4465C20.0306 12.1595 20.0306 11.8405 19.9028 11.5535C19.11 9.77274 16.8553 6 12 6ZM2.26978 10.7399C3.13911 8.78756 5.89123 4 12 4C18.1089 4 20.8608 8.78782 21.7299 10.7401C22.0882 11.5448 22.0882 12.4552 21.7299 13.2599C20.8608 15.2122 18.1089 20 12 20C5.89123 20 3.13911 15.2124 2.26978 13.2601C1.91138 12.4552 1.91138 11.5448 2.26978 10.7399Z"
            fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM11.9153 10.0018C11.9434 10.0006 11.9716 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 11.9716 10.0006 11.9434 10.0018 11.9153C10.1577 11.9701 10.3253 12 10.5 12C11.3284 12 12 11.3284 12 10.5C12 10.3253 11.9701 10.1577 11.9153 10.0018Z"
            fill={color}/>
    </svg>
  );
};

export default AdSpotViews;
