import React from 'react';
import Icon from "../../../Icon";
import {useTranslation} from "react-i18next";
import './SalesTrackerUnavailableNote.less';

const SalesTrackerUnavailableNote = () => {
  const { t } = useTranslation();

  return (
    <div className='sales-tracker-unavailable-note-wrapper'>
      <p className='sales-tracker-unavailable-note-title'>
        <Icon type={'unavailable_store_triangle'} role={'icon'} width={18} height={18} isNote={true}/>
        <span>{t('This store has been closed or paused. You are still able to view their previous sales data.')}</span>
      </p>
    </div>
  );
};

export default SalesTrackerUnavailableNote;
