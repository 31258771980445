import React from 'react';
import cls from "classname";
import {formatDuration} from "../../../Utils/utils";
import Icon from "../../../Icon";

const UniversityPlaylistItem = ({data, current, onClick, index, theme}) => {

  return (
    <div className={cls('playlist-item', {
      'active': current?.id === data?.id
    })}
         onClick={onClick}
    >
      <p className={cls('playlist-item-number', {
        'hidden': current?.id === data?.id
      })}>{current?.id !== data?.id && index + 1}</p>
      <div className="image" style={{backgroundImage: `url(${data?.thumbnail})`}} />
      <div className="info-wrapper">
        <div className="title">
          <p>{data?.title}</p>
          <p className='playlist-item-duration'>{formatDuration(data?.duration)}</p>
        </div>
        <p className='playlist-item-status'>
          {data?.completed && <Icon role={'icon'}
                                    type={'university_watched'}
                                    color={theme === 'dark' ? 'white' : '#225aea'}/>}
        </p>
      </div>
    </div>
  );
};

export default UniversityPlaylistItem;
