import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Select } from 'antd';
import _ from 'lodash';
import cls from 'classnames';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import CreditCardForm from '../Forms/CreditCardForm';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import InputComponent from "../Input";
const { Option } = Select;

export default function ChangeCreditCardForm(
  {
    customClass,
    paymentMethod,
    billingInfo,
    onCancel,
    onSubmit,
    getCountries,
    countries,
    isMobile,
    theme,
    intent,
    setChargebeeCardFetching,
    errorSave,
    isSt=false,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectCountry, setSelectCountry] = useState('United States');
  const [data, setData] = useState({
    country: { id: 1450 },
  });
  const [disable, setDisable] = useState(true);
  const [showArrow, setShowArrow] = useState(true);
  const [showArr, setShowArr] = useState(true);

  const isPreset = !!billingInfo;

  useEffect(() => {
    if(!countries) getCountries();
    form.getFieldsValue();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (isPreset) {
     setSelectCountry(billingInfo?.country?.name);
      setData({
        ...billingInfo,
      });
      handleChangeDisabled({...billingInfo})
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isPreset]);

  const countriesArr = _.cloneDeep(countries);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  const optionsCountry = countriesArr
    ? countriesArr
     .sort(byField('name'))
     .map((el) => ({ label: el.name, value: el.name, id: el.id }))
    : [];

  const optionsState = countriesArr
    ? countriesArr
        .filter((country) => country.name === 'United States')[0]
        ['state']?.sort((a, b)=> a.localeCompare(b))
        .map((el) => ({
          label: el,
          value: el,
        }))
    : [];

  const onFinish = (value) => {
    onSubmit(value)
  };

  function handleChange(changedValues, allValues) {
    const {
      address_1,
      city,
      country,
      postal_code,
      state,
    } = allValues;

    handleChangeDisabled({address_1, city, country, postal_code, state})
    setData({
      ...data,
      ...allValues,
    });
  }

  function handleChangeDisabled({address_1, city, country, postal_code, state}){
    if (address_1 && city && country && postal_code) {
      if((country.id === 1450 || country.id === 'United States') && !state) {
        if(!disable) setDisable(true);
      }else{
        if(disable) setDisable(false);
      }
    } else {
      if(!disable) setDisable(true);
    }
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={handleChange}
      validateTrigger="onSubmit"
      className={cls('account-form payment-form on-boarding', customClass)}
      autoComplete="off"
      requiredMark={false}
      style={{paddingBottom: isMobile ? 140 :80}}
    >
      <Row
        className=""
        gutter={[
          { xs: 12, sm: 12, md: 16 },
          { xs: 12, sm: 12, md: 16 },
        ]}
      >

        <Col span={24} style={{margin: isMobile ? '0 0 12px' : '0 0 -4px'}}>
          <span className='sub-title'>{t('Credit Card Information')}</span>
        </Col>

        <CreditCardForm theme={theme}
                        onBack={onCancel}
                        onContinue={onFinish}
                        isMobile={isMobile}
                        fetching={false}
                        disable={disable}
                        amount={intent.amount}
                        isSettings={true}
                        setChargebeeCardFetching={setChargebeeCardFetching}
                        intent={intent}
                        billingInfo={{
                          ...data,
                          country: countries?.filter(country => country?.id === data?.country?.id)[0]
                        }}
                        btnText={{
                          cancel: isSt ? t('Back') : t('Cancel'),
                          submit: paymentMethod
                            ? t('setting_billing.Replace Credit Card')
                            : t('setting_billing.Add Credit Card')
                        }}
                        errorSave={errorSave}
        />

        <Col span={24} style={{margin: isMobile ? '-12px 0 12px' : '-16px 0 -4px'}}>
          <span className='sub-title'>{t('Billing Address')}</span>
        </Col>

        <Col xs={24} sm={24}>
          <Form.Item
            label={t('Street and House Number')}
            className={data?.['address_1'] ? 'active' : ''}
            name="address_1"
            initialValue={isPreset ? billingInfo?.['address_1'] : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'address_1')} />
          </Form.Item>
        </Col>

        <Col xs={12} sm={12}>
          <Form.Item
            label={t('setting_billing.City/Town')}
            className={data?.city ? 'active' : ''}
            name="city"
            initialValue={isPreset ? billingInfo.city : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'city')} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12}>
          <Form.Item
            label={t('setting_billing.Postal Code')}
            className={data?.postal_code ? 'active' : ''}
            name="postal_code"
            initialValue={isPreset ? billingInfo.postal_code : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'postal_code')} />
          </Form.Item>
        </Col>

        {selectCountry === 'United States' || selectCountry === 1450 ? (
          <Col xs={24} sm={24}>
            <Form.Item
              label={t('State')}
              name="state"
              initialValue={isPreset ? billingInfo.state : null}
              className={data?.state ? 'active' : ''}
            >
              <Select
                className="custom-select"
                popupClassName="custom-dropdown"
                showSearch
                showArrow
                listHeight={176}
                notFoundContent={
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="empty-state_icon-select">
                      <Icon width={24} height={24}
                            role="icon"
                            type="empty_warning"
                            fill={'#707ba0'}
                      />
                    </div>
                    <div style={{color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px'}} className="empty-state_wrapper-select">
                      No results found
                    </div>
                  </div>
                }
                suffixIcon={
                  <div
                    className={cls('arrow', {
                      arrow_selected: !showArrow,
                    })}
                  >
                    <ArrowSelectDown />
                  </div>
                }
                onFocus={(e) => {
                  handleClearError(e, 'state');
                }}
                onDropdownVisibleChange={() => {
                  setShowArrow(!showArrow);
                }}
                optionFilterProp="options"
                getPopupContainer={(trigger) => trigger.parentNode}
                onScroll={(e) => e.stopPropagation()}
                filterOption={(input, { value }) =>
                  utils.includeSearch(value, input)
                }
              >
                {optionsState.map((el) => (
                  <Option value={el.value} key={el.value}>
                    <span onClick={(e) => e.preventDefault()}>{el.label}</span>
                    {form.getFieldValue('state') === el.label ? (
                      <Icon
                        role="icon"
                        type="select_dropdown_check_mark"
                        className="check-icon"
                      />
                    ) : null}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}

        <Col xs={24} sm={24}>
          <Form.Item
            label={t('Country')}
            name={['country', 'id']}
            initialValue={isPreset ? billingInfo?.country?.id : 'United States'}
            className={data?.country?.id ? 'active' : ''}
          >
            <Select
              className="custom-select"
              popupClassName="custom-dropdown"
              showSearch
              showArrow
              listHeight={176}
              notFoundContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className="empty-state_icon-select">
                    <Icon width={24} height={24}
                          role="icon"
                          type="empty_warning"
                          fill={'#707ba0'}
                    />
                  </div>
                  <div style={{color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px'}} className="empty-state_wrapper-select">
                    No results found
                  </div>
                </div>
              }
              suffixIcon={
                <div
                  className={cls('arrow', {
                    arrow_selected: !showArr,
                  })}
                >
                  <ArrowSelectDown color={!showArr && '#225AEA'} />
                </div>
              }
              value={selectCountry}
              onChange={(value) => {
                setSelectCountry(value);
              }}
              onFocus={(e) => {
                handleClearError(e, ['country', 'id']);
              }}
              optionFilterProp="options"
              getPopupContainer={(trigger) => trigger.parentNode}
              onScroll={(e) => e.stopPropagation()}
              filterOption={(input, { label }) =>
                utils.includeSearch(label, input)
              }
              onDropdownVisibleChange={() => {
                setShowArr(!showArr);
              }}
              onSelect={() => setShowArr(!showArr)}
            >
              {optionsCountry.map((el) => (
                <Option value={el.id} key={el.value} label={el.value}>
                  <span onClick={ e => e.preventDefault()}>{el.label}</span>
                  {selectCountry === el.label ? (
                    <Icon
                      role="icon"
                      type="select_dropdown_check_mark"
                      className="check-icon"
                    />
                  ) : null}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
