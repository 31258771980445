import React, { useState } from 'react';
import {useNavigate, useParams} from "react-router";
import { useSelector } from 'react-redux';
import AdItemProductsGeneral from "./AdItemProductsGeneral";
import AdItemProductsDetailed from "./AdItemProductsDetailed";
import Icon from '../../../Icon';
import { Modal } from 'antd';
import PriceHistory from '../../ProductDatabasePage/components/PriceHistory';
import './AdItemProducts.less';

const AdItemProducts = (
  {}
) => {

  const isMobile = useSelector(store => store.nav.isMobile);
  const {id, mode} = useParams();
  const navigate = useNavigate();
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);

  const modalBlocksWidth = {
    priceHistory: 1040,
  }

  const modalBlocks = {
    priceHistory: <PriceHistory product={recordToPriceHistory} />
  }

  return (
    <div className='fadspot-page-inner-content fadspot-page-products'>
      {
        mode === 'general' || mode === 'products' ?
          <AdItemProductsGeneral id={id}
                                 setView={navigate}
                                 setRecordToPriceHistory={setRecordToPriceHistory}
                                 setVisibleModal={setVisible}
                                 setModal={setModal}
          />
          :
          <AdItemProductsDetailed setRecordToPriceHistory={setRecordToPriceHistory}
                                  setVisibleModal={setVisible}
                                  setModal={setModal}
          />
      }
      <Modal
        className="change-modal custom-modal"
        {...(isMobile ? { transitionName: '' } : null)}
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        {modalBlocks[modal]}
      </Modal>
    </div>
  );
};

export default AdItemProducts;
