import React, {memo} from 'react';
import cls from "classname";
import SalesTrackerTopPageTableItem from "./SalesTrackerTopPageTableItem";
import './SalesTrackerTopPageTable.less';
import SalesTrackerTopPageTableItemSkeleton from "./SalesTrackerTopPageTableItemSkeleton";
import Icon from "../../../../Icon";

const SalesTrackerTopPageTable = (
  {
    view,
    loading,
    period,
    data,
    setModal,
    setVisible,
    setRecordToPriceHistory,
    isTrial,
    setRecordToDelete,
    setStoreProductsToShow,
    connectStore,
    connectProduct,
    spinner,
    isBasicPlan,
    isMobile=false,
  }
) => {

  return (
    <div id={'product-database-table-body'} className={cls("sales-tracker-top-table-body", {
      'overflow-hidden': loading
    })}>
      {
        loading || (!data?.length && spinner) ?
          [1, 2].map(el => (<SalesTrackerTopPageTableItemSkeleton key={el} view={view} isMobile={isMobile} />))
          :
          data?.length ?

          data?.map((el, index) => (
            <SalesTrackerTopPageTableItem key={index}
                                          data={el}
                                          view={view}
                                          period={period}
                                          setModal={setModal}
                                          setVisible={setVisible}
                                          setRecordToPriceHistory={setRecordToPriceHistory}
                                          isTrial={isTrial}
                                          setRecordToDelete={setRecordToDelete}
                                          setStoreProductsToShow={setStoreProductsToShow}
                                          connectStore={connectStore}
                                          connectProduct={connectProduct}
                                          isBasicPlan={isBasicPlan}
            />
          ))
            :
            <div className={'sales-tracker-top-table-body-empty'}>
              <div className="empty-image_icon">
                <Icon width={64} height={64} role="icon" type="empty_warning" />
              </div>
              <div className={'sales-tracker-top-table-body-empty-text'}>
                <p>
                  No Results Found
                </p>
                <p>
                  Kindly change your search query and try again.
                </p>
              </div>
            </div>
      }
    </div>
  );
};

export default memo(SalesTrackerTopPageTable);
