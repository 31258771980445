import React from 'react';
import {useTranslation} from "react-i18next";
import {Divider} from "antd";
import ButtonComponent from "../../../Components/Button";

const GatheringDataModal = (props) => {
  const {setModal, setVisible} = props;
  const { t } = useTranslation();

  return (
    <div className={'load-filter-preset-wrapper delete-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Important')}
      </div>
      <p className="load-filter-preset-subtitle">
        {t('We are still collecting data. It may take up to 24 hours, please return later.')}
      </p>

      <Divider className={'delete-preset-divider'} />

      <div className="delete-filter-button-wrapper">
        <ButtonComponent className={'delete-preset-button-cancel delete-preset-button-cancel--orange'}
                         text={t('Ok')}
                         onClick={() => {
                           setModal('');
                           setVisible(false);
                         }}
        />
      </div>
    </div>
  );
};

export default GatheringDataModal;
