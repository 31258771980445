import React, {useEffect, useState, useRef} from 'react';
import {useNavigate} from "react-router";
import cls from "classname";
import Icon from "../../Icon";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {Modal, Spin} from "antd";
import {connect} from "react-redux";
import Creators from './reducer';
import acc from "accounting";
import FiltersBlock from "./components/FiltersBlock";
import AdItem from "./components/AdItem";
import LoadFilterPresetBlock from "../ProductDatabasePage/components/LoadFilterPresetBlock";
import DeleteFilterPresetBlock from "../ProductDatabasePage/components/DeleteFilterPresetBlock";
import SaveFilterPresetBlock from "../ProductDatabasePage/components/SaveFilterPresetBlock";
import openNotification, {openNotificationWithIcon} from "../../Components/Notification/index";
import {DefaultMsgSaga} from "../../Components/Notification/notification-message";
import BannerCreditsLimited from "../../Components/Banners/BannerCreditsLimited";
import { useDetectAdBlock } from "adblock-detect-react";
import Pagination from "./components/Pagination";
import QuickSearch from "./components/QuickSearch";
import './styles.less';

const AdSpotPage = (
  {
    isMobile,
    theme,
    userInfo,
    loading,
    filtersLoading,
    adsLoading,
    getFilters,
    getAds,
    updatePreset,
    getPresets,
    deleteFilter,
    saveFilterPreset,
    creditsLeft=0,
    resetCreateError,
    resetDeleteResult,
    deleteResult,
    cancelDeleteAdSpotPreset,
    ads = [],
    totalAds,
    setSelectedFilters,
    adSpot,
    selectedFilters,
    defaultSearch,
    setDefaultSearch,
    suggestions,
    suggestionsLoading,
    getSuggestions,
    resetSuggestions,
    quickSearch,
    setSelectedQuickPreset,
    selectedQuickPreset,
    sortOrder,
    setSortOrder,
  }
) => {
  const adBlockDetected = useDetectAdBlock();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const tableRef = useRef(null);
  const [changedFilters, setChangedFilters] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [modal, setModal] = useState(null)
  const [visible, setVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [loadedPreset, setLoadedPreset] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputFiltersSuggestions, setInputFiltersSuggestions] = useState(
    Object.keys(selectedFilters).length ?
      selectedFilters?.inputFiltersSuggestions
      :
      {
        page_name: [],
        domain_name: [],
      }
      );
  const [inputFilters, setInputFilters] = useState(
    Object.keys(selectedFilters).length ?
      selectedFilters?.inputFilters
      :
      {
        ad_copy: null,
        page_name: null,
        domain_name: null,
      });
  const [creativeFilters, setCreativeFilters] = useState(
    Object.keys(selectedFilters).length ?
      selectedFilters?.creativeFilters
      :
      'all'
  );
  const [engagementFilters, setEngagementFilters] = useState(
    Object.keys(selectedFilters).length ?
      selectedFilters?.engagementFilters
      :
      {
        reactions: {min: 0, max: 10000},
        shares: {min: 0, max: 10000},
        comments: {min: 0, max: 10000},
        video_length: {min: 0, max: 600},
      });
  const [dropdownFilters, setDropdownFilters] = useState(
    Object.keys(selectedFilters).length ?
      selectedFilters?.dropdownFilters
      :
      {
        ad_created_at: {min: null, max: null, id: null},
        call_to_action: 'All',
        website_provider: 'All',
        ad_copy_language: 'All',
        domain_tld: 'All',
      });

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const isPrem = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'premium';
  const isYearly = userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';

  const setFiltersHandler = () => {
    setSelectedFilters({
      inputFilters: inputFilters,
      creativeFilters: creativeFilters,
      engagementFilters: engagementFilters,
      dropdownFilters: dropdownFilters,
      inputFiltersSuggestions: inputFiltersSuggestions
    })
  }

  const checkIfChanged = () => {
    if (
      Object.keys(inputFilters).some((key) => inputFilters[key] !== null) ||
      creativeFilters !== 'all' ||
      Object.keys(engagementFilters).some((key) => engagementFilters[key].min !== 0 || engagementFilters[key].max !== (key === 'video_length' ? 600 : 10000)) ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'ad_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return !(dropdownFilters[key] === 'All' || (typeof dropdownFilters[key] === 'object' && !dropdownFilters[key]?.length));
      })
    ) {
      if (!changedFilters) setChangedFilters(true);
    } else if (changedFilters) setChangedFilters(firstLoading);
  };

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll*0.95) && !adsLoading && ads?.length < +totalAds && creditsLeft) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    document.title = `Ad Spot - Dropship`;
    if (firstLoading) setFirstLoading(false);
    getFilters();
    getPresets();
    resetCreateError();
    resetDeleteResult();
  }, []);

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && ads?.length < +totalAds && creditsLeft) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [ads?.length, totalAds, tableRef?.current, creditsLeft])

  useEffect(() => {
    if (deleteResult) {
      openNotificationWithIcon({
        deleteResult,
        className: 'notification preset-delete-notification',
        style: { minWidth: '716px' },
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                delete preset _name_ notification
              </span>
            }
            iconOutline={true}
            icon="remove_preset"
            undo={
              <span style={{ color: '#225aea', cursor: 'pointer', display: 'block' }}
                    onClick={() => cancelDeleteAdSpotPreset(deleteResult)}
              >
              {t('Undo Action')}
            </span>}
            preset={deleteResult.name}
            withTranslate={true}
          />
        ),
      });
      setTimeout(() => resetDeleteResult(), 1);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [deleteResult]);

  useEffect(() => {
    if (creditsLeft === 0 && !isMobile && (!firstLoading && !loading && !filtersLoading)) {
      openNotification({
        message: t('You have reached your search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [creditsLeft]);

  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inputFilters, creativeFilters, engagementFilters, dropdownFilters]);

  const loadPreset = (data) => {
    if (creditsLeft === 0 || !Boolean(userInfo?.subscriptions?.[0])) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach(val => {
        if (val === 'inputFilters') setInputFilters(state => ({...state, ...data.value[val]}));
        if (val === 'creativeFilters') setCreativeFilters(data.value[val]);
        if (val === 'engagementFilters') setEngagementFilters(state => ({...state, ...data.value[val]}));
        if (val === 'dropdownFilters') setDropdownFilters(state => ({...state, ...data.value[val]}));
      });
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                preset_load_name_
              </span>
            }
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    }
  };

  const setLoadedPresetHandler = data => {
    setLoadedPreset(data);
    setSelectedQuickPreset(data);
  }

  const modalBlocks = {
    loadFilterPreset: <LoadFilterPresetBlock fetching={loading}
                                             theme={theme}
                                             setVisible={setVisible}
                                             setRecordToDelete={setRecordToDelete}
                                             setModal={setModal}
                                             setPageSize={setPageSize}
                                             setPageNumber={setPageNumber}
                                             updatePreset={updatePreset}
                                             loadPreset={loadPreset}
                                             setLoadedPreset={setLoadedPreset}
                                             setSortOrder={setSortOrder}
                                             category={'adSpot'}
    />,
    deleteFilterPreset: <DeleteFilterPresetBlock setModal={setModal}
                                                 deleteFilter={deleteFilter}
                                                 record={recordToDelete}
    />,
    saveFilterPreset: <SaveFilterPresetBlock setVisible={setVisible}
                                             saveFilterPreset={saveFilterPreset}
                                             filters={{
                                               inputFilters: {...inputFilters},
                                               creativeFilters: creativeFilters,
                                               engagementFilters: { ...engagementFilters },
                                               dropdownFilters: { ...dropdownFilters },
                                             }}
                                             category={'adSpot'}
    />,
    tutorial: <ReactPlayer controls={true}
                           width={'100%'}
                           height={'100%'}
                           playing={true}
                           url={'https://www.youtube.com/watch?v=OftbjjbL0tc'}
    />
  };

  const modalBlocksWidth = {
    loadFilterPreset: 800,
    deleteFilterPreset: 512,
    saveFilterPreset: 566,
    tutorial: 512
  };

  const header = () => filtersLoading || firstLoading ?
    (<div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <span className={'link skeleton'} style={{width: 48, height: 48}}/>
        </div>
        <div className="sales-tracker-top-page-subheader">
          <span className={'link skeleton'} style={{width: 240, height: 32}}/>
          <span className={'link skeleton'} style={{width: 338, height: 24}}/>
        </div>
      </div>
      {/*<div className={'sales-tracker-top-page-limits-wrapper'}>*/}
      {/*  <span className={'link skeleton'} style={{width: 138, height: 40, marginRight: 12}}/>*/}
      {/*  <span className={'link skeleton'} style={{width: 169, height: 40}}/>*/}
      {/*</div>*/}
    </div>)
      :
    (<div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <Icon type={`ad_spot_dashboard`} role={'icon'} width={32} height={32} />
        </div>
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            Ad Spot
            <span>{t('NEW')}</span>
          </h3>
          <h4 className="sales-tracker-top-page-subtitle">
            {t(`Search our library with millions of Facebook ads`)}
          </h4>
        </div>
      </div>
      {/*<div className={'sales-tracker-top-page-limits-wrapper'}>*/}
      {/*  <div className={'sales-tracker-top-page-watch-tutorial'}*/}
      {/*       onClick={() => window.Intercom('startTour',433081)}*/}
      {/*       style={{width: 138}}*/}
      {/*  >*/}
      {/*    <Icon type={'take_tour'} role={'icon'}/>*/}
      {/*    <span>{t('Take Tour')}</span>*/}
      {/*  </div>*/}

      {/*  <div className={cls('sales-tracker-top-page-watch-tutorial', {*/}
      {/*    disabled: true*/}
      {/*  })}*/}
      {/*       onClick={() => {*/}
      {/*         // setVisible(true);*/}
      {/*         // setModal('tutorial');*/}
      {/*       }}*/}
      {/*  >*/}
      {/*    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>*/}
      {/*    <span>{t('Watch Tutorial')}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>)

  return (
    adBlockDetected ?
      <div className={'product-database-page sales-tracker-page fadspot-page empty'}>
        <div className={'adblock-warning'}>
          <div className={'adblock-warning-icon'}>
            <Icon width={64} height={64}
                  role="icon"
                  type="empty_warning"
                  fill={'#F6B60E'}
            />
          </div>
          <div className={'adblock-warning-text'}>
            <h3>
              {t('Disable Ad Blocker')}
            </h3>
            <p>
              {t('We noticed you have an ad blocker enabled.')}
            </p>
            <p>
              {t('To keep using the Ad Spot, please disable the ad blocker.')}
            </p>
            <p>
              {t('If this problem persists,')} <a href="https://app.dropship.io/?show_help" target={'_blank'}>
              {t('contact support')}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      :
      <div className={cls("product-database-page sales-tracker-page fadspot-page")}>

      {header()}

        <BannerCreditsLimited
          skeleton={filtersLoading || firstLoading || loading}
          className={''}
          text={`${t('Credits Remaining')}: ${acc.formatNumber(creditsLeft, 0, ',') || 0}`}
          btnText={t('Get More Credits')}
          onClick={() => navigate('/setting/plan')}
          isPrem={isPrem}
          isYearly={isYearly}
        />

        <FiltersBlock isMobile={isMobile}
                      adsLoading={adsLoading}
                      skeleton={filtersLoading || firstLoading}
                      setVisible={setVisible}
                      setModal={setModal}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      inputFilters={inputFilters}
                      setInputFilters={setInputFilters}
                      creativeFilters={creativeFilters}
                      setCreativeFilters={setCreativeFilters}
                      engagementFilters={engagementFilters}
                      setEngagementFilters={setEngagementFilters}
                      dropdownFilters={dropdownFilters}
                      setDropdownFilters={setDropdownFilters}
                      getAds={getAds}
                      defaultSearch={defaultSearch}
                      setDefaultSearch={setDefaultSearch}
                      disabledSearch={creditsLeft === 0 || !Boolean(userInfo?.subscriptions?.[0])}
                      loadedPreset={loadedPreset}
                      setLoadedPreset={setLoadedPresetHandler}
                      tableRef={tableRef}
                      setFilters={setFiltersHandler}
                      changedFilters={changedFilters}
                      suggestions={suggestions}
                      suggestionsLoading={suggestionsLoading}
                      getSuggestions={getSuggestions}
                      resetSuggestions={resetSuggestions}
                      inputFiltersSuggestions={inputFiltersSuggestions}
                      setInputFiltersSuggestions={setInputFiltersSuggestions}
        />

        <QuickSearch loadPreset={loadPreset}
                     setLoadedPreset={setLoadedPresetHandler}
                     loadedPreset={loadedPreset || selectedQuickPreset}
                     skeleton={filtersLoading}
                     isMobile={isMobile}
                     data={quickSearch || []}
        />

        <Pagination sortOrder={sortOrder}
                    skeleton={adsLoading || firstLoading}
                    setSortOrder={setSortOrder}
                    isMobile={isMobile}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalAds={totalAds}
                    isTrial={isTrial}
                    isShort={true}
                    disabled={creditsLeft === 0}
        />

        {
          filtersLoading || firstLoading || loading ?
            <div className={'fadspot-page-items-wrapper'}>
              <span className="fadspot-page-item-wrapper link skeleton"/>
              <span className="fadspot-page-item-wrapper link skeleton"/>
              <span className="fadspot-page-item-wrapper link skeleton"/>
              <span className="fadspot-page-item-wrapper link skeleton"/>
              <span className="fadspot-page-item-wrapper link skeleton"/>
              <span className="fadspot-page-item-wrapper link skeleton"/>
            </div>
            :
            <Spin spinning={adsLoading}>
              <div className={cls("fadspot-page-items-wrapper", {
                'fadspot-page-items-wrapper--empty': !ads?.length
              })}
                   ref={tableRef}
              >
                {
                  !ads?.length && !adsLoading && Object.keys(adSpot).length ?
                    <div className={cls('product-database-table-wrapper product-database-table-wrapper-empty')}>
                      <div className="empty-state empty-state-product-database">
                        <div className="empty-state-product-database-title">
                          {t('No Ads Found')}
                        </div>
                        <div className="empty-state-product-database-subtitle">
                          {t('kindly_change_search')}
                        </div>
                        <div className="empty-state_icon">
                          <Icon width={96} height={96}
                                role="icon"
                                type="empty_warning"
                                fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    ads?.map((el, index) => (
                      <AdItem data={{...el}}
                              key={el?.id}
                              isMobile={isMobile}
                              isAlive={el?.status}
                              disabledNavigate={creditsLeft === 0}
                      />
                    ))
                }
              </div>
            </Spin>
        }

      <Modal
        className={cls("change-modal custom-modal", {
          'video-modal': modal === 'tutorial'
        })}
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? {transitionName: ''} : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color={modal === 'tutorial' ? "#225aea" : "#707BA0"} opacity={1}/>
        }
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
        width={ modalBlocksWidth[modal] }
      >
        <Spin size="large" spinning={loading}>
          { modalBlocks[modal] }
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  loading: state.adSpot.loading,
  filtersLoading: state.adSpot.filtersLoading,
  adsLoading: state.adSpot.adsLoading,
  creditsLeft: state.adSpot.creditsLeft,
  deleteResult: state.adSpot.deleteResult,
  ads: state.adSpot.ads.results,
  totalAds: state.adSpot.ads.count,
  adSpot: state.adSpot.ads,
  selectedFilters: state.adSpot.selectedFilters,
  selectedQuickPreset: state.adSpot.selectedQuickPreset,
  sortOrder: state.adSpot.selectedSort,
  defaultSearch: state.adSpot.defaultSearch,
  suggestions: state.adSpot.suggestions,
  suggestionsLoading: state.adSpot.suggestionsLoading,
  quickSearch: state?.adSpot?.presets?.quick_search
});

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => dispatch(Creators.getAdSpotFiltersRequest()),
  getAds: (data) => dispatch(Creators.getAdsRequest(data)),
  updatePreset: (data) => dispatch(Creators.updateAdSpotPresetRequest(data)),
  getPresets: () => dispatch(Creators.getAdSpotPresetsRequest()),
  getSuggestions: (data) => dispatch(Creators.getSuggestionsRequest(data)),
  deleteFilter: (id) => dispatch(Creators.deleteAdSpotPresetRequest(id)),
  cancelDeleteAdSpotPreset: (id) => dispatch(Creators.cancelDeleteAdSpotPresetRequest(id)),
  saveFilterPreset: (data) => dispatch(Creators.createAdSpotPresetRequest(data)),
  resetSuggestions: () => dispatch(Creators.resetSuggestions()),
  resetCreateError: () => dispatch(Creators.resetCreateError()),
  resetDeleteResult: () => dispatch(Creators.resetDeleteResult()),
  setSelectedFilters: (data) => dispatch(Creators.setSelectedFilters(data)),
  setSelectedQuickPreset: (data) => dispatch(Creators.setSelectedQuickPreset(data)),
  setSortOrder: (data) => dispatch(Creators.setSelectedSort(data)),
  setDefaultSearch: (data) => dispatch(Creators.setDefaultSearch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdSpotPage);
