/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Card, Table } from 'antd';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { changeColumns } from './PaymentHistoryColums';
import InvoicePDF from './InvoicePDF';
import Invoice from './Invoice';
import ModalComponent from '../Modal';
import Icon from '../../Icon';
import './styles-payment-history.less';
import {useSelector} from "react-redux";

function PaymentHistory(
  {
    user,
    fetching,
    payments,
    paymentDetails,
    getPaymentsNext,
    getPaymentDetails,
    isMobile,
  }) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const tableRef = useRef();
  const { next, results } = payments;
  const data = results || [];
  const theme = useSelector(state => state.nav.theme);

  const handleClose = () => {
    setVisible(false);
  };

  const showModal = (value) => {
    getPaymentDetails(value?.['invoice_id']);
    setTimeout(()=> setVisible(true), 100)
  };
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= maxTopScroll && !fetching && !!next) {
      getPaymentsNext();
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }
  const dataSource = data?.length
    ? data?.map((u) => ({ ...u, key: u?.['invoice_id'] || u?.id }))
    : [];

  return (
    <Card className={cls("payment-history", {
      "payment-history--dark": theme === 'dark',
    })}>
      <div className="billing-title-text">
        {t('setting_billing.Payment History')}
      </div>
      <div className="payment-list">
        <Table
          className="payment-list-table"
          rowClassName="payments-row"
          components={{
            table: ({ className, children, ...other }) => (
              <table
                {...other}
                ref={tableRef}
                className={cls(className, 'other-wrapper')}
              >
                {children}
              </table>
            ),
          }}
          columns={changeColumns(isMobile, showModal, t)}
          loading={fetching}
          dataSource={dataSource}
          pagination={false}
          sticky={true}
          scroll={{ scrollToFirstRowOnChange: true, y: true }}
        />
      </div>
      <ModalComponent
        width={595}
        className="invoice-modal"
        isMobile={isMobile}
        handleClose={handleClose}
        open={visible && !!paymentDetails}
        footer={false}
      >
        <Invoice
          user={user}
          invoice={paymentDetails}
          isModalHistory
          onCancel={handleClose}
        />
        <PDFDownloadLink
          document={<InvoicePDF user={user} invoice={paymentDetails}/>}
          fileName={`invoice_${paymentDetails?.['invoice_id']}.pdf`}
          className="invoice-pdf-btn"
          onClick={handleClose}
        >
          {({ loading }) =>
            loading ? '...' : <Icon role="icon" type="download"/>
          }
        </PDFDownloadLink>
      </ModalComponent>
    </Card>
  );
}

export default React.memo(PaymentHistory)
