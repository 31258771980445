import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

store()
  .then((s) => {
    root.render(<App store={s} />);
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err.message);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
