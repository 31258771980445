import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import {useSelector} from "react-redux";

export const Portfolio = ({ width, height, className, fill, outline }) => {
  const theme = useSelector(state => state.nav.theme)
  return outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cls(className, 'icon icon-portfolio icon-portfolio-outline')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15988 6.13511C3.82409 6.36252 1.99854 8.33164 1.99854 10.7271V17.7797C1.99854 20.328 4.06427 22.3937 6.61249 22.3937H17.3875C19.9357 22.3937 22.0015 20.328 22.0015 17.7797V10.7271C22.0015 8.33186 20.1762 6.36288 17.8408 6.13517C17.8267 6.13381 17.8127 6.1325 17.7987 6.13126C17.7823 5.97339 17.7553 5.81864 17.7186 5.66775C17.508 4.80324 16.9748 4.06504 16.2539 3.58785C15.7033 3.22343 15.0431 3.01123 14.3334 3.01123H9.66719C8.9575 3.01123 8.29736 3.22343 7.74677 3.58784C7.02578 4.06503 6.49263 4.80324 6.28203 5.66775C6.24528 5.81863 6.21835 5.97335 6.20196 6.1312C6.18792 6.13244 6.17389 6.13374 6.15988 6.13511ZM17.5069 8.0906H15.7912H8.20945H6.49328L6.37563 8.10097C5.03149 8.21948 3.97594 9.35113 3.97594 10.7271V17.7797C3.97594 19.2359 5.15637 20.4163 6.61249 20.4163H17.3875C18.8436 20.4163 20.0241 19.2359 20.0241 17.7797V10.7271C20.0241 9.35126 18.9687 8.21969 17.6247 8.10101L17.5069 8.0906ZM15.3287 5.36417C15.0633 5.13043 14.7149 4.98864 14.3334 4.98864H9.66719C9.28571 4.98864 8.93734 5.13042 8.67191 5.36417C8.45024 5.55938 8.28641 5.81872 8.20945 6.11319H9.66719H14.3334H15.7912C15.7142 5.81873 15.5504 5.55938 15.3287 5.36417Z"
      />
      <rect x="11" y="13" width="2" height="3" rx="1"/>
      <path
        d="M3 11L8.01475 12.2537C8.63395 12.4085 9.28986 12.2585 9.78019 11.8498L10.7196 11.067C11.4613 10.4489 12.5387 10.4489 13.2804 11.067L14.2198 11.8498C14.7101 12.2585 15.366 12.4085 15.9853 12.2537L21 11"
        stroke="#6E7DAE"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cls(className, 'icon icon-portfolio icon-portfolio-bold')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="7" width="18" height="14" rx="3" fill="#225aea"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15988 6.13511C3.82409 6.36252 1.99854 8.33164 1.99854 10.7271V17.7797C1.99854 20.328 4.06427 22.3937 6.61249 22.3937H17.3875C19.9357 22.3937 22.0015 20.328 22.0015 17.7797V10.7271C22.0015 8.33186 20.1762 6.36288 17.8408 6.13517C17.8267 6.13381 17.8127 6.1325 17.7987 6.13126C17.7823 5.97339 17.7553 5.81864 17.7186 5.66775C17.508 4.80324 16.9748 4.06504 16.2539 3.58785C15.7033 3.22343 15.0431 3.01123 14.3334 3.01123H9.66719C8.9575 3.01123 8.29736 3.22343 7.74677 3.58784C7.02578 4.06503 6.49263 4.80324 6.28203 5.66775C6.24528 5.81863 6.21835 5.97335 6.20196 6.1312C6.18792 6.13244 6.17389 6.13374 6.15988 6.13511ZM17.5069 8.0906H15.7912H8.20945H6.49328L6.37563 8.10097C5.03149 8.21948 3.97594 9.35113 3.97594 10.7271V17.7797C3.97594 19.2359 5.15637 20.4163 6.61249 20.4163H17.3875C18.8436 20.4163 20.0241 19.2359 20.0241 17.7797V10.7271C20.0241 9.35126 18.9687 8.21969 17.6247 8.10101L17.5069 8.0906ZM15.3287 5.36417C15.0633 5.13043 14.7149 4.98864 14.3334 4.98864H9.66719C9.28571 4.98864 8.93734 5.13042 8.67191 5.36417C8.45024 5.55938 8.28641 5.81872 8.20945 6.11319H9.66719H14.3334H15.7912C15.7142 5.81873 15.5504 5.55938 15.3287 5.36417Z"
        fill="#225aea"
      />
      <rect x="11" y="13" width="2" height="3" rx="1" fill={theme === 'light' ? 'white' : '#000E58'}/>
      <path
        d="M4 11L7.97058 12.1912C8.61339 12.384 9.31008 12.2416 9.82564 11.812L10.7196 11.067C11.4613 10.4489 12.5387 10.4489 13.2804 11.067L14.1744 11.812C14.6899 12.2416 15.3866 12.384 16.0294 12.1912L20 11"
        stroke={theme === 'light' ? 'white' : '#000E58'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Portfolio.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

Portfolio.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Portfolio;
