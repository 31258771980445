import React from 'react';
import {Divider} from "antd";

const SalesProductsSkeleton = ({isMobile}) => {
  return (
    <div className="product-database-table-wrapper" style={{marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
      <div className="product-database-table-header" style={{paddingTop: 9, paddingBottom: 9}}>
        <div style={{ width: '44.5%' }}>
          <span className="link skeleton" style={{ width: 60, height: 24 }}/>
        </div>
        <div style={{ width: '14.5%', display: isMobile ? "none" : "flex" }}>
          <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          <span className="link skeleton" style={{ width: 62, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '21%', display: isMobile ? "none" : "flex" }}>
          <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          <span className="link skeleton" style={{ width: 109, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '20%', display: isMobile ? "none" : "flex" }}>
          <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          <span className="link skeleton" style={{ width: 133, height: 24, marginLeft: 2 }}/>
        </div>
      </div>

      <div style={{ marginTop: 20, maxHeight: 'calc((100vh - 96px) - 80px)', overflow: 'hidden' }} className="product-database-table-body">
        {[
          { id: 1, last: 68, third: { first: 74 } },
          { id: 2, last: 79, third: { first: 54, second: 60 } },
          { id: 3, last: 70, third: { first: 48, second: 49 } },
          { id: 4, last: 70, third: { first: 110, second: 120 } },
          { id: 5, last: 75, third: { first: 67, second: 27 } },
          { id: 6, last: 75, third: { first: 48 } },
          { id: 7, last: 75, third: { first: 48 } },
          { id: 8, last: 75, third: { first: 49 } },
          { id: 9, last: 68, third: { first: 74 } },
          { id: 10, last: 79, third: { first: 54, second: 60 } },
          { id: 11, last: 70, third: { first: 48, second: 49 } },
          { id: 12, last: 70, third: { first: 110, second: 120 } },
          { id: 13, last: 75, third: { first: 67, second: 27 } },
          { id: 14, last: 75, third: { first: 48 } },
          { id: 15, last: 75, third: { first: 48 } },
          { id: 16, last: 75, third: { first: 49 } },
        ].map(row => (
          <div key={row.id} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>
              <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
                <div style={{ position: 'relative' }}>
                  <span className="link skeleton" style={{ width: 64, height: 64 }}/>
                  <span className="link skeleton"
                        style={{ position: 'absolute', width: 40, height: 20, left: 32, top: -4 }}
                  />
                </div>

                <div style={{ marginLeft: 16 }}>
                  <span className="link skeleton" style={{ width: 280, height: 16, marginTop: 10, marginBottom: 6 }}/>
                  <span className="link skeleton" style={{ width: 101, height: 16, display: 'inline-block' }}/>
                  <span className="link skeleton" style={{ width: 57, height: 16, marginLeft: 21, display: 'inline-block' }}/>
                </div>
              </div>
              <div key={row.id + 20} style={{display: isMobile ? 'none' : 'flex'}} className={'product-database-table-skeleton-cell-2'}>
                <span className="link skeleton" style={{ width: 24, height: 24 }}/>
                <span className="link skeleton" style={{ width: 24, height: 24, marginTop: 2 }}/>
                <span className="link skeleton" style={{ width: 24, height: 24, marginTop: 2 }}/>
              </div>
              <div key={row.id + 30} style={{display: isMobile ? 'none' : 'flex'}} className={'product-database-table-skeleton-cell-3'}>
                <span className="link skeleton" style={{ width: row.third.first, height: 20 }}/>
                {row.third.second && <span className="link skeleton"
                                           style={{
                                             display: 'block',
                                             width: row.third.second,
                                             height: 20,
                                             marginTop: 10,
                                           }}
                />}
              </div>
              <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'} style={{display: isMobile ? 'none' : 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <span className="link skeleton" style={{width: 67, height: 14}}/>
                <span className="link skeleton" style={{width: 60, height: 14, marginTop: 10}}/>
              </div>
              <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'} style={{display: isMobile ? 'none' : 'flex', paddingRight: 0, width: '21.5%'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span className="link skeleton" style={{width: 67, height: 16}}/>
                  <span className="link skeleton" style={{width: 60, height: 16, marginTop: 10}}/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                  <span className="link skeleton" style={{ width: 12, height: 16, borderRadius: '6px 0 0 6px' }}/>
                  <span className="link skeleton" style={{ width: 12, height: 16, marginTop: 6, borderRadius: '6px 0 0 6px' }}/>
                </div>
              </div>
            </div>
            <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
          </div>
        ))}
        <div className={'product-database-table-skeleton-row'} style={{flexDirection: 'row', alignItems: 'flex-end'}}>
          <div style={{ position: 'relative', width: '35%' }}>
            <span className="link skeleton" style={{width: 64, height: 9, borderRadius: '6px 6px 0 0'}}/>
            <span className="link skeleton"
                  style={{ position: 'absolute', width: 40, height: 13, left: 32, top: -4, borderRadius: '6px 6px 0 0' }}
            />
          </div>
          <div className={'product-database-table-skeleton-cell-2'}>
            <span className="link skeleton" style={{ width: 24, height: 13, borderRadius: '6px 6px 0 0' }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesProductsSkeleton;
