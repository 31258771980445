import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import cls from 'classname';
import _ from 'lodash';
import EmptyData from '../EmptyData';
import { competitorsShopifyColumns } from './Columns';

export default function CompetitorsShopifyTable({
  products,
  onSort,
  fetching,
  onChange,
  searchText,
  next,
  getShopifyTargetNext,
}) {
  const tableRef = useRef();

  const dataSource = products?.length
    ? products?.map((u) => ({ ...u, key: u.id }))
    : [];

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll*0.95) && !fetching && !!next) {
      getShopifyTargetNext(next);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[next]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [searchText]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (products) {
      const data = [...products];
      const arr = data?.filter((el) => el.selected).map((el) => el.id);
      setSelectedRowKeys(arr);
    }
  }, [products]);

  const rowSelection = {
    onChange: (selectedRowKeys_, selectedRows) => {
      if (selectedRowKeys.length < selectedRowKeys_.length) {
        const diff = (_.difference(selectedRowKeys_, selectedRowKeys));
        const res = [];
        diff.forEach(diffEl => {
          const item = products.find(el => el.id === diffEl);
          res.push(item);
        })
        onChange('add', res);
      } else {
        const diff = (_.difference(selectedRowKeys, selectedRowKeys_));
        const res = [];
        diff.forEach(diffEl => {
          const item = products.find(el => el.id === diffEl);
          res.push(item);
        })
        onChange('delete', res);
      }
      setSelectedRowKeys(selectedRowKeys_);
    },
    selectedRowKeys,
  };

  return (
    <>
      {products?.length || fetching ? (
        <div
          className={cls('products-details', 'shopify-products', {
            'products-details--empty': dataSource.length === 0,
          })}
        >
            <Table
              components={{
                // eslint-disable-next-line react/prop-types
                table: ({ className, children, ...other }) => (
                  <table
                    {...other}
                    ref={tableRef}
                    className={cls(className, 'other-wrapper')}
                  >
                    {children}
                  </table>
                ),
              }}
              className="list-table shopify"
              loading={fetching}
              rowClassName="details-row"
              dataSource={dataSource}
              columns={competitorsShopifyColumns()}
              pagination={false}
              scroll={{ scrollToFirstRowOnChange: true, y: true }}
              sticky={true}
              onChange={(pagination, filters, sorter) => {
                onSort(sorter);
              }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
                hideSelectAll: 'true',
              }}
            />
        </div>
      ) : (
        <EmptyData text="No Competitors Added" />
      )}
    </>
  );
}
