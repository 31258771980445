import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignin from 'react-apple-signin-auth';
import { useGoogleLogin } from '@react-oauth/google';
import { Row, Col, Card, Result } from 'antd';
import cls from 'classname';
import Icon from '../../Icon';
import openNotification from '../Notification';
import utils from '../../Utils/utils';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import './styles-linked.less';
import Modal from "../Modal";

export default function LinkedAccounts({
  userInfo,
  socialAuthConnect,
  socialAuthDelete,
  socialAuthReset,
  socialAuthConnectResult,
  socialAuthDeleteResult,
  isMobile,
  socialAccount,
  socialAccountSave,
}) {
  const { accounts, password_created } = userInfo;
  const [visible, setVisible] = useState(false);
  const [account, setAccount] = useState('');

  useEffect(() => {
    if (socialAuthDeleteResult) {
      openNotification({
        type: 'warning',
        message: t(
          'Your _account_ account has been successfully disconnected',
          {
            account: utils.toUpperLatter(socialAccount),
          },
        ),

        style: { minWidth: '716px' },
      });
      socialAuthReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [socialAuthDeleteResult]);

  useEffect(() => {
    if (socialAuthConnectResult) {
      openNotification({
        type: 'success',
        message: t('Your account has been successfully linked to _social_', {
          social: utils.toUpperLatter(socialAccount),
        }),

        style: { minWidth: '716px' },
      });
      socialAuthReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [socialAuthConnectResult]);

  const { t } = useTranslation();

  function disconnectAccount(acc) {
    setVisible(false);
    const accountsNum = Object.values(accounts)?.filter((el) => !!el)?.length;
    if (!password_created && accountsNum < 2) {
      openNotification({
        type: 'error',
        message: (
          <span className="custom-notification-text">
            {t('Action can not be completed.')}
          </span>
        ),
        style: { minWidth: '716px', maxWidth: '716px', maxHeight: '120px' },
      });
    } else {
      socialAccountSave(acc);
      socialAuthDelete(acc);
    }
  }

  function connectAccount(acc, dataResponse) {
    socialAuthConnect(dataResponse, acc);
    socialAccountSave(acc);
  }

  const responseApple = (response) => {
    if (response?.authorization?.id_token) {
        connectAccount('apple', response.authorization?.id_token);
    }
  };
  const responseFacebook = (response) => {
    if (response?.accessToken) {
      connectAccount('facebook', response.accessToken);
    }
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: (response) => {
      if (response && response?.access_token) {
        connectAccount('google', response.access_token);
      }
    },
    onFailure: (response) => {
      if (response && response?.access_token) {
        connectAccount('google', response.access_token);
      }
    }
  });

  function showModal(acc) {
    setAccount(acc);
    setVisible(true);
  }

  return (
    <Card className="account-linked">
      <div className="account-title">{t('Linked Accounts')}</div>
      <Row
        gutter={[
          { xs: 0, md: 16 },
          { xs: 16, sm: 16, md: 0 },
        ]}
        style={{justifyContent: 'flex-start'}}
      >
        <Col xs={24} md={8}>
          <div className="linked-block">
                <ButtonComponent
                  className="btn-primary linked-btn"
                  type="primary white"
                  onClick={accounts?.google ? null : responseGoogle}
                >
                  {accounts?.google ? (
                    <div
                      className="linked-accounts-icon"
                      onClick={() => showModal('google')}
                    >
                      <Icon role="icon" type="setting_icon" />
                    </div>
                  ) : null}
                  <div className="linked-btn-block">
                    <Icon role="icon" type="google_icon" />
                    <span
                      className={cls('connected-status', {
                        active: accounts?.google,
                      })}
                    >
                      {accounts?.google ? t('Connected') : t('Not Connected')}
                    </span>
                  </div>
                </ButtonComponent>
          </div>
        </Col>
        {
          accounts?.facebook ?
            <Col xs={24} md={8}>
              <div className="linked-block">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  scope="public_profile,email"
                  textButton
                  cssClass="my-social-btn"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <ButtonComponent
                      className="btn-primary linked-btn"
                      type="primary white"
                      onClick={accounts?.facebook ? null : renderProps.onClick}
                    >
                      {accounts?.facebook ? (
                        <div
                          className="linked-accounts-icon"
                          onClick={() => showModal('facebook')}
                        >
                          <Icon role="icon" type="setting_icon" />
                        </div>
                      ) : null}
                      <div className="linked-btn-block">
                        <Icon role="icon" type="fb_icon" />
                        <span
                          className={cls('connected-status', {
                            active: accounts?.facebook,
                          })}
                        >
                      {accounts?.facebook ? t('Connected') : t('Not Connected')}
                    </span>
                      </div>
                    </ButtonComponent>
                  )}
                />
              </div>
            </Col>
            :
            null
        }
        <Col xs={24} md={8}>
          <div className="linked-block">
            <AppleSignin
              authOptions={{
                clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                scope: 'email name',
                redirectURI: process.env.REACT_APP_BASE_URI + '/login',
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
              }}
              className="apple-auth-btn"
              noDefaultStyle={false}
              onSuccess={responseApple}
              onError={responseApple}
              skipScript={false}
              render={(renderProps) => (
                <ButtonComponent
                  className="btn-primary linked-btn"
                  type="primary white"
                  onClick={accounts?.apple ? null : renderProps.onClick}
                >
                  {accounts?.apple ? (
                    <div
                      className="linked-accounts-icon"
                      onClick={() => showModal('apple')}
                    >
                      <Icon role="icon" type="setting_icon" />
                    </div>
                  ) : null}
                  <div className="linked-btn-block">
                    <Icon role="icon" type="apple_icon" />
                    <span
                      className={cls('connected-status', {
                        active: accounts?.apple,
                      })}
                    >
                      {accounts?.apple ? t('Connected') : t('Not Connected')}
                    </span>
                  </div>
                </ButtonComponent>
              )}
            />
          </div>
        </Col>
      </Row>
      <Modal
        className="disconnect-modal"
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={438}
        footer={null}
        closable="true"
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Result
          title={t('Preferences')}
          subTitle={t(
            'Your _account_ account integration can be used to sign in to Dropship faster',
            { account: utils.toUpperLatter(account) },
          )}
          icon={<></>}
          extra={[
            <>
              <div>
                {t('Linked _account_ Account', {
                  account: utils.toUpperLatter(account),
                })}
              </div>
              <div className="disconnect-container">
                <InputComponent
                  disabled
                  value={accounts?.[account] || 'example.dropship.com'}
                />
                {isMobile ? (
                  <div className="double-btn-wrp">
                    <ButtonComponent
                      danger
                      key="cancel"
                      htmlType="button"
                      className="border-only"
                      onClick={() => setVisible(false)}
                    >
                      {t('Cancel')}
                    </ButtonComponent>
                    <ButtonComponent
                      type="primary"
                      key="delete"
                      htmlType="submit"
                      danger
                      onClick={() => disconnectAccount(account)}
                    >
                      {t('Disconnect')}
                    </ButtonComponent>
                  </div>
                ) : (
                  <ButtonComponent
                    className="btn-popup"
                    key="delete"
                    type="primary"
                    danger
                    onClick={() => disconnectAccount(account)}
                  >
                    {t('Disconnect')}
                  </ButtonComponent>
                )}
              </div>
            </>,
          ]}
        />
      </Modal>
    </Card>
  );
}
