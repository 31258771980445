import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../Icon';
import EditableText from '../editableText';
import IconWithText from '../../../../Components/Text';

export const ActionsComponent = ({
  id,
  name,
  setAsDefaultFunc,
  isDefault,
  isGeneral,
  onChangeHandler,
  removeCollectionFunc,
  handleResend,
}) => {
  const dropdown = useRef();
  const [isChange, setChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="collection-item_block">
      {/* STAR */}
      <div
        className="collection-item_set-as-default"
        onClick={() =>
          !isDefault
            ? setAsDefaultFunc({ collectionID: id, collectionName: name })
            : null
        }
      >
        <Icon type="favorite" outline={isDefault} role="icon" />
      </div>
      {/* NAME */}
      <div className="collection-item_name">
        <EditableText
          text={name === 'All Products' && isGeneral ? t('collections.all_products') : name}
          isChange={isChange}
          setChange={(result) => {
            // eslint-disable-next-line react/prop-types
            onChangeHandler(result);
            setChange(false);
          }}
          collection={{
            collectionID: id,
            collectionName: name === 'All Products' && isGeneral ? t('collections.all_products') : name
          }}
          onResend={handleResend}
        />
      </div>
      {/* ACTION */}
      {!isGeneral ? (
        <div
          ref={dropdown}
          className={cls('collection-item_action', {
            'collection-item_action--editable': isChange,
          })}
        >
          <Dropdown
            placement="bottomLeft"
            getPopupContainer={() => dropdown.current}
            destroyPopupOnHide={true}
            open={visible}
            onOpenChange={(flag) => {
              setVisible(flag);
            }}
            dropdownRender={() => (
              <ul className="action-dropdown">
                <li
                  className="action-dropdown_item"
                  onClick={() => {
                    setVisible(false);
                    setTimeout(() => setChange(true), 300);
                  }}
                >
                  <IconWithText
                    text={t('common.rename_collection')}
                    size={24}
                    icon={() => <Icon type="folder" role="icon" />}
                  />
                </li>
                <li
                  className="action-dropdown_item action-dropdown_item--red"
                  onClick={() => {
                    setVisible(false);
                    removeCollectionFunc({
                      collectionID: id,
                      collectionName: name,
                    });
                  }}
                >
                  <IconWithText
                    text={t('common.remove')}
                    size={24}
                    icon={() => <Icon type="trash" role="icon" />}
                  />
                </li>
              </ul>
            )}
            trigger={['click']}
          >
            <span
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Icon type="three_dots" role="icon" />
            </span>
          </Dropdown>
        </div>
      ) : null}
    </div>
  );
};

ActionsComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  setAsDefaultFunc: PropTypes.func,
  isDefault: PropTypes.bool,
  isGeneral: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  removeCollectionFunc: PropTypes.func,
  handleResend: PropTypes.func,
};

export default ActionsComponent;
