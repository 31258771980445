import React from 'react';

const DatabaseAction = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.5924 5H6.60245C5.75086 5 5.28898 5.99649 5.83938 6.64632L9.65222 11.1479C10.2641 11.8703 10.5999 12.7864 10.5999 13.7332V17.9978C10.5999 18.7271 11.3553 19.2111 12.0178 18.9063L14.0179 17.9863C14.3726 17.8231 14.5999 17.4683 14.5999 17.0778V13.7325C14.5999 12.7866 14.9352 11.8712 15.5462 11.149L19.3558 6.64587C19.9057 5.99594 19.4437 5 18.5924 5ZM6.60245 3H18.5924C21.1463 3 22.5323 5.98782 20.8827 7.93762L17.0731 12.4408C16.7676 12.8019 16.5999 13.2595 16.5999 13.7325V17.0778C16.5999 18.2493 15.918 19.3137 14.8537 19.8033L12.8537 20.7233C10.8661 21.6376 8.59994 20.1855 8.59994 17.9978V13.7332C8.59994 13.2598 8.43203 12.8018 8.12608 12.4405L4.31325 7.93896C2.66205 5.98949 4.04767 3 6.60245 3Z"
            fill="#707BA0"/>
    </svg>
  );
};

export default DatabaseAction;
