import React from 'react';

const AdSpotLike = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0001 1.71387C9.27213 1.71387 6.65592 2.79754 4.72698 4.72649C2.79803 6.65543 1.71436 9.27164 1.71436 11.9996C1.71436 14.7275 2.79803 17.3438 4.72698 19.2727C6.65592 21.2016 9.27213 22.2853 12.0001 22.2853C14.728 22.2853 17.3443 21.2016 19.2732 19.2727C21.2021 17.3438 22.2858 14.7275 22.2858 11.9996C22.2858 9.27164 21.2021 6.65543 19.2732 4.72649C17.3443 2.79754 14.728 1.71387 12.0001 1.71387Z"
        fill={`url(#paint0_linear_36566_241343_${id})`}/>
      <path
        d="M17.351 11.1487C17.5773 11.3069 17.7856 11.4637 17.7856 12.0153C17.7856 12.5681 17.4911 12.7919 17.1761 12.9474C17.3048 13.1574 17.346 13.4095 17.2906 13.6494C17.1916 14.0917 16.7866 14.435 16.4266 14.5366C16.5821 14.786 16.631 15.0316 16.4459 15.3337C16.208 15.713 16.001 15.857 15.0856 15.857H11.357C10.0867 15.857 9.42843 15.155 9.42843 14.5713V11.5691C9.42843 9.98772 11.3146 8.64415 11.3146 7.54486L11.1783 6.17557C11.1719 6.092 11.1886 5.88757 11.2529 5.82843C11.3557 5.72686 11.6399 5.57129 12.0693 5.57129C12.3496 5.57129 12.536 5.624 12.7559 5.72943C13.5029 6.08557 13.697 6.98686 13.697 7.712C13.697 8.06043 13.1647 9.10443 13.0927 9.46572C13.0927 9.46572 14.2074 9.21886 15.5086 9.20986C16.8727 9.20215 17.7573 9.45415 17.7573 10.2924C17.7573 10.628 17.4757 10.9649 17.351 11.1487ZM6.34272 10.7141H7.37129C7.57588 10.7141 7.7721 10.7954 7.91677 10.9401C8.06145 11.0848 8.14272 11.281 8.14272 11.4856V16.3713C8.14272 16.5758 8.06145 16.772 7.91677 16.9168C7.7721 17.0615 7.57588 17.1427 7.37129 17.1427H6.34272C6.13812 17.1427 5.94191 17.0615 5.79724 16.9168C5.65256 16.772 5.57129 16.5758 5.57129 16.3713V11.4856C5.57129 11.281 5.65256 11.0848 5.79724 10.9401C5.94191 10.7954 6.13812 10.7141 6.34272 10.7141Z"
        fill="white"/>
      <defs>
        <linearGradient id={`paint0_linear_36566_241343_${id}`} x1="12.0001" y1="1.71387" x2="12.0001" y2="22.2853"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#18AFFF"/>
          <stop offset="1" stopColor="#0062DF"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotLike;
