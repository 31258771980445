import React from 'react';
import cls from "classname";
import {Divider} from "antd";

const SalesTrackerTopPageTableItemSkeleton = ({view, isMobile}) => {
  return (
    <div className={cls('sales-tracker-top-table-item')}>
      <div style={{background: 'transparent'}} className="sales-tracker-top-table-item-header">
        <div className="general-info">
          <div className="general-info-logo">
            <span className="link skeleton" style={{width: 64, height: 64, display: "block"}}/>
          </div>
          <div className="general-info-title-wrapper">
            <div className="general-info-title">
              <span className="link skeleton" style={{width: 188, height: 24, display: "block"}}/>
            </div>
            <div className="general-info-domain">
              <span className="link skeleton" style={{width: 180, height: 20, display: "block", marginTop: 2}}/>
            </div>
          </div>
        </div>

        <div className="total-info">
          {[1,2,3,4].map(el => (
            <React.Fragment key={el}>
              <div className="total-info-block">
                <div className="title">
                  <span className="link skeleton" style={{width: 95, height: 20, display: "block"}}/>
                </div>
                <div className={cls("value")}>
                  <span className="link skeleton" style={{width: 85, height: 24, display: "block", marginTop: 2}}/>
                </div>
              </div>
              { !isMobile && el < 4 ? <Divider type={'vertical'} style={{margin: 'auto 24px', height: 32}} /> : null }
            </React.Fragment>
          ))}
        </div>

        <div className="actions">
          <span className="link skeleton" style={{width: 40, height: 40, display: "block"}}/>
          <span className="link skeleton" style={{width: 167, height: 40, display: "block"}}/>
        </div>
      </div>
      <div className="sales-tracker-top-table-item-chart">
        <div style={{marginTop: 16}}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8}}>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
            </div>
            <div style={{width: 'calc(100% - 39px)', height: 227}}>
              <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
            </div>
          </div>
          <div style={{width: 'calc(100% - 39px)', marginLeft: 39, display: "flex", justifyContent: "space-between"}}>
            {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
              <span key={el * index} className="link skeleton" style={{width: el, height: 16}}/>
            ))}
          </div>
        </div>
      </div>
      {
        view === 'stores' ?
          <Divider style={{margin: '0 16px', width: 'calc(100% - 32px)', minWidth: 'unset'}} type={'horizontal'} />
          :
          null
      }
      {
        view === 'stores' ?
          <div className={'top-performing-products-wrapper'}>
            <h3>
              <span className="link skeleton" style={{width: 196, height: 24}}/>
            </h3>
            <div className={'top-performing-products'}>
              {
                [1,2,3].map(el => (
                  <div key={el} className={'top-performing-product'}>
                    <div className={"image-wrapper"}>
                      <span className="link skeleton" style={{width: 64, height: 64}}/>
                      <div className="product-image-quantity">
                        <span className="link skeleton" style={{width: 40, height: 20}}/>
                      </div>
                    </div>
                    <div className="title-wrapper">
                      <span className="link skeleton" style={{width: 48, height: 20}}/>
                      <div className={'price-info'}>
                        <span className="link skeleton" style={{width: 42, height: 16}}/>
                        <Divider style={{margin: '0 8px'}} type={'vertical'} />
                        <span className="link skeleton" style={{width: 90, height: 16}}/>
                      </div>

                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          null
      }
    </div>
  );
};

export default SalesTrackerTopPageTableItemSkeleton;
