import React from 'react';

const DatabaseProvider = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M18.6203 9.20979C18.0086 7.8056 16.9533 6.64079 15.6161 5.89395C14.2788 5.14711 12.7336 4.85946 11.2172 5.07511C9.70083 5.29077 8.29703 5.99781 7.22105 7.08785C6.14506 8.17788 5.45629 9.59074 5.26033 11.1098C4.30692 11.3381 3.47055 11.9086 2.91007 12.713C2.34958 13.5173 2.10401 14.4995 2.21999 15.473C2.33597 16.4465 2.80542 17.3435 3.5392 17.9936C4.27297 18.6438 5.21996 19.0019 6.20033 18.9998H17.2003C18.3997 18.9965 19.5579 18.5622 20.4637 17.7761C21.3695 16.99 21.9626 15.9045 22.1348 14.7175C22.3069 13.5306 22.0467 12.3213 21.4016 11.3102C20.7564 10.2992 19.7693 9.55371 18.6203 9.20979ZM17.2003 16.9998H6.20033C5.6699 16.9998 5.16119 16.7891 4.78612 16.414C4.41104 16.0389 4.20033 15.5302 4.20033 14.9998C4.20033 14.4694 4.41104 13.9606 4.78612 13.5856C5.16119 13.2105 5.6699 12.9998 6.20033 12.9998C6.46555 12.9998 6.7199 12.8944 6.90744 12.7069C7.09497 12.5194 7.20033 12.265 7.20033 11.9998C7.20288 10.8171 7.62462 9.67354 8.3906 8.77237C9.15658 7.8712 10.2172 7.27074 11.3841 7.07766C12.5509 6.88459 13.7485 7.1114 14.7639 7.7178C15.7793 8.3242 16.5469 9.27093 16.9303 10.3898C16.989 10.5598 17.0924 10.7108 17.2297 10.8269C17.3669 10.9431 17.533 11.0201 17.7103 11.0498C18.4433 11.185 19.0997 11.5883 19.5516 12.1811C20.0036 12.7738 20.2187 13.5135 20.155 14.2562C20.0913 14.9988 19.7535 15.6912 19.2073 16.1984C18.6611 16.7056 17.9457 16.9913 17.2003 16.9998Z"
        fill="#707BA0"/>
    </svg>
  );
};

export default DatabaseProvider;
