import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Icon from '../../../Icon';
import Images from '../../../Images';
import ProductDetailsCard from './ProductDetailsCard';
import './SaturationBlock.less';

export const SaturationBlock = ({ skeleton, isMobile, theme, saturation }) => {
  const { t } = useTranslation();

  function checkSaturation(value) {
    let result;
    switch (true) {
      case value >= 66:
        result = 'high';
        break;

      case value < 36:
        result = 'low';
        break;

      default:
        result = 'medium';
        break;
    }
    return result;
  }

  const renderHeader = (
    <div>
      <span className="title">{t('Saturation')}</span>
      <Icon
        type={'attention_outline'}
        role={'button'}
        titleText={t('tooltips.saturation')}
        btnType={'header-tooltip'}
        tooltipProps={{
          placement: 'top',
          trigger: isMobile ? 'click' : 'hover',
          overlayClassName: cls('details-tooltip', 'saturation'),
          getPopupContainer: () =>
            document.getElementById('product-details-block-saturation-id'),
        }}
      />
    </div>
  );

  const renderHeaderSkeleton = (
    <div className={'title skeleton'} style={{ width: 141 }} />
  );

  return (
    <ProductDetailsCard
      header={skeleton ? renderHeaderSkeleton : renderHeader}
      withLine={!isMobile}
    >
      <div className="saturation-card">
        <div className="saturation-card-indicator-line">
          {['low', 'medium', 'high'].map((el) => (
            <span
              key={el}
              className={cls('saturation-card-indicator-line-item', {
                [`active ${el}`]: checkSaturation(saturation) === el,
                skeleton: skeleton,
              })}
            >
              {skeleton ? null : t(el)}
            </span>
          ))}
        </div>

        <div
          className={cls('saturation-card-speedometer-wrap', {
            skeleton: skeleton,
          })}
        >
          {skeleton ? null : (
            <>
              <img
                src={
                  Images[theme === 'dark' ? 'speedometerDark' : 'speedometer']
                }
                className="speedometer-img"
                alt=""
              />

              <div className="speedometer-arrow">
                <div
                  className={'speedometer-arrow-icon-wrap'}
                  style={{
                    transform: `translate(-50%, -50%) rotate(${
                      ((saturation / 100) * 180 > 180
                        ? 180
                        : (saturation / 100) * 180) - 90
                    }deg)`,
                  }}
                >
                  <Icon
                    role="icon"
                    type="speedometer_arrow"
                    className="speedometer-arrow-icon"
                  />
                </div>
                <span className="speedometer-arrow-count">{saturation}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </ProductDetailsCard>
  );
};

export default SaturationBlock;
