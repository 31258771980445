import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InfoHeader from "../components/InfoHeader";
import Pagination from "../components/Pagination";
import {Divider, Spin} from "antd";
import AdItem from "../components/AdItem";
import AdItemPageSkeleton from "../components/AdItemPageSkeleton";
import ButtonComponent from "../../../Components/Button";
import Icon from "../../../Icon";
import {OverviewAdvertiserWrapper} from "../components/OverviewAdvertiser";

const SLIDE_PAGE_SIZE = 8;
const AdItemAllAdsPage = (
  {
    isMobile,
    userInfo,
    getAds,
    selectedAdv,
    loading,
    getOverviewMainInfo,
    mainInfo,
    creditsLeft
  }
) => {
  const {t} = useTranslation();
  const {search} = useLocation();
  let params = new URLSearchParams(search);
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const tableRef = useRef(null);
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sortOrder, setSortOrder] = useState('most_recent');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [firstLoading, setFirstLoading] = useState(true);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll*0.95) && !loading && selectedAdv?.results?.length < +selectedAdv?.count) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    getOverviewMainInfo({
      same: search.includes('same='),
      id: params.get('same'),
      ad_id: params.get('ad_id'),
      domain: params.get('domain'),
      ...(params.get('internal_shop_id') && {internal_shop_id: params.get('internal_shop_id')})
    })
  },[search])

  useEffect(() => {
    if (!loading) getAds({
      same: search.includes('same='),
      id: params.get('same'),
      domain: params.get('domain'),
      page_number: pageNumber,
      page_size: pageSize,
      sort: sortOrder,
    })
  }, [pageNumber, pageSize, search])

  useEffect(() => {
    if (firstLoading) setFirstLoading(false)
    else {
      if (pageNumber === 1) {
        getAds({
          same: search.includes('same='),
          id: params.get('same'),
          domain: params.get('domain'),
          page_number: pageNumber,
          page_size: pageSize,
          sort: sortOrder,
        })
      } else setPageNumber(1)
    }
  }, [sortOrder])

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && selectedAdv?.results?.length < +selectedAdv?.count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [selectedAdv?.results?.length, selectedAdv?.count, tableRef?.current])

  useEffect(() => {
    if (creditsLeft === 0 && !isMobile && (!firstLoading && !loading)) {
      openNotification({
        message: 'You have reached your search limit, upgrade to unlock unlimited searches',
        style: { minWidth: '716px' },
        type: 'error',
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [creditsLeft]);

  if ((loading || mainInfo?.mainInfoLoading) && pageNumber === 1) return <AdItemPageSkeleton isMobile={isMobile} />

  return (
    <div className='fadspot-page-inner-content fadspot-page-all-ads'>
      <InfoHeader data={mainInfo}
                  trackBrand={console.log}
                  selectedAdv={selectedAdv}
      />

      {
         !Boolean(mainInfo?.related_advertisers) || mainInfo?.related_advertisers?.length <= 1 ?
          null
          :
          <div className="fadspot-page-overview-advertisers fadspot-page-overview-info-block">
            <div className={'fadspot-page-overview-advertisers-title-wrapper'}>
              <div>
                <h3>
                  {
                    mainInfo?.related_advertisers?.length === 1 ?
                    t('Same domain advertiser')
                      :
                    t('Same domain advertisers')
                  }
                  <Icon
                    type={'attention_outline'}
                    role="button"
                    width={16}
                    height={16}
                    tooltipProps={{
                      trigger: 'hover',
                      placement: isMobile ? 'bottom' : 'right',
                      overlayClassName: 'subscription-tooltip scheduled',
                      getPopupContainer: (trigger) => trigger.parentNode,
                    }}
                    titleText={t('Advertiser pages which have posts with links leading to the same domain')}
                  />
                </h3>
                <Divider style={{margin: '0 8px', height: 24}} type={'vertical'}/>
                <span className={'fadspot-page-overview-advertisers-title-value'}>
                  {
                    mainInfo?.related_advertisers?.length
                  }
                </span>
              </div>
              <div className={'fadspot-page-overview-advertisers-title-buttons'}>
                <ButtonComponent className={'fadspot-page-overview-advertisers-title-button-prev'}
                                 text={''}
                                 disabled={currentSlide === 0}
                                 onClick={() => carouselRef.current.prev()}
                                 icon={<Icon width={15} height={15} type="arrow_forward_university" role="icon"/>}
                />
                <ButtonComponent className={'fadspot-page-overview-advertisers-title-button-next'}
                                 text={''}
                                 disabled={currentSlide === Math.ceil(mainInfo?.related_advertisers?.length / SLIDE_PAGE_SIZE) - 1 || mainInfo?.related_advertisers?.length < SLIDE_PAGE_SIZE}
                                 onClick={() => carouselRef.current.next()}
                                 icon={<Icon width={15} height={15} type="arrow_forward_university" role="icon"/>}
                />
              </div>
            </div>
            <OverviewAdvertiserWrapper data={mainInfo?.related_advertisers || []}
                                       pageSize={SLIDE_PAGE_SIZE}
                                       carouselRef={carouselRef}
                                       afterChange={setCurrentSlide}
            />
      </div>
      }

      {
        (selectedAdv?.results?.length && !loading) || loading ?
          <Pagination sortOrder={sortOrder}
                      skeleton={firstLoading}
                      setSortOrder={setSortOrder}
                      isMobile={isMobile}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      totalAds={selectedAdv?.count}
                      isTrial={isTrial}
                      isShort={true}
                      disabled={creditsLeft === 0}
          />
          :
          null
      }

      <Spin spinning={loading}>
        {
          (selectedAdv?.results?.length && !loading) || loading ?
            <div className="fadspot-page-items-wrapper"
                 ref={tableRef}
            >
              {
                selectedAdv?.results?.map(el => (
                  <AdItem data={{ ...el }}
                          isInnerPage={false}
                          isMobile={isMobile}
                          isAlive={el?.status}
                  />
                ))
              }
            </div>
            :
            null
        }
      </Spin>
    </div>
  );
};

export default AdItemAllAdsPage;
