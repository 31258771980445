import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import cls from 'classname';
import utils from '../../Utils/utils'
import {Breadcrumb, Divider} from 'antd';
import Icon from '../../Icon';
import './styles.less';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export const BreadcrumbsComponent = ({ breadCrumbs, isAdmin, isMobile, skeleton, timeInfo = null, hideTime=false }) => {

  const { t } = useTranslation();
  const filteredBreadCrumbs = breadCrumbs ? breadCrumbs?.filter(({name}) => name)?.map(el => ({...el, breadcrumbName: el?.name})) : [];
  const finalBreadCrumbs = isMobile ? filteredBreadCrumbs?.slice(-2,-1) : filteredBreadCrumbs

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last && !isMobile ? (
      <span>{route.name}</span>
    ) : (
      <>
        {isMobile ? <Icon role="icon" type="arrow_breadcrumb"/> : null}
        <Link to={route.link}>{route.name}</Link>
      </>
    );
  }

  const breadcrumb = (
    <Breadcrumb
      separator={<Icon role="icon" type="arrow_breadcrumb"/>}
      className={cls('custom-breadcrumbs', {
        'custom-breadcrumbs_admin': isAdmin,
        'skeleton': skeleton
      })}
      itemRender={itemRender}
      routes={finalBreadCrumbs}
    />
  )

  if (filteredBreadCrumbs?.length > 1) {
    return timeInfo === null
      ? breadcrumb
      : (
        <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
          {
            breadcrumb
          }
          {
            hideTime ?
              null
              :
              <div id={`expandable-chart-info-${timeInfo}`} className={'expandable-chart-info'}>
                <span>Updated {utils.durationAsString(new Date().toISOString(), timeInfo, t)}</span>
                <Icon type={'attention_outline'}
                      role={'button'}
                      width={16}
                      height={16}
                      tooltipProps={{
                        overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                        placement: "bottomRight",
                        title: () => (
                          <div className={'expandable-chart-info-tooltip'}>
                            <div className={'expandable-chart-info-tooltip-title'}>
                              {t('Dashboard data syncs every 2-3 hours. However, delays may occur when high volumes are being processed.')}
                            </div>
                            <Divider style={{margin: '12px 0'}}/>
                            <div className={'expandable-chart-info-tooltip-text'}>
                              <p>
                                {
                                  t('Last updated on')
                                }
                              </p>
                              <p>
                                {dayjs(timeInfo).format('lll')} UTC
                              </p>
                            </div>
                          </div>
                        ),
                        destroyTooltipOnHide: true,
                        getPopupContainer: () => document.getElementById(`expandable-chart-info-${timeInfo}`),
                      }}
                />
              </div>
          }
        </div>)
  } else {
    return null;
  }
};

BreadcrumbsComponent.defaultProps = {};

BreadcrumbsComponent.propTypes = {
  breadCrumbs: PropTypes.array,
  isAdmin: PropTypes.bool,
};
