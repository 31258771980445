import React from 'react';

const LockTopStores = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.8821 12H21.1182C21.8211 12 22.4275 12 22.9269 12.0408C23.454 12.0838 23.9783 12.1789 24.4828 12.436C25.2354 12.8195 25.8474 13.4314 26.2309 14.184C26.4879 14.6885 26.583 15.2128 26.6261 15.74C26.6669 16.2394 26.6669 16.8458 26.6668 17.5486V23.7849C26.6669 24.4877 26.6669 25.094 26.6261 25.5934C26.583 26.1205 26.4879 26.6448 26.2309 27.1493C25.8474 27.9019 25.2354 28.5139 24.4828 28.8974C23.9783 29.1544 23.454 29.2495 22.9269 29.2926C22.4274 29.3334 21.8211 29.3334 21.1182 29.3333H10.8821C10.1792 29.3334 9.57289 29.3334 9.07346 29.2926C8.54634 29.2495 8.02204 29.1544 7.51754 28.8974C6.76489 28.5139 6.15297 27.9019 5.76947 27.1493C5.51242 26.6448 5.41733 26.1205 5.37426 25.5934C5.33346 25.094 5.33348 24.4876 5.3335 23.7848V17.5486C5.33348 16.8457 5.33346 16.2394 5.37426 15.74C5.41733 15.2128 5.51242 14.6885 5.76947 14.184C6.15297 13.4314 6.76489 12.8195 7.51754 12.436C8.02204 12.1789 8.54634 12.0838 9.07346 12.0408C9.57287 12 10.1793 12 10.8821 12ZM16.0002 20C15.2638 20 14.6668 20.597 14.6668 21.3333V22.6667C14.6668 23.403 15.2638 24 16.0002 24C16.7365 24 17.3335 23.403 17.3335 22.6667V21.3333C17.3335 20.597 16.7365 20 16.0002 20Z"
            fill="#225AEA"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.6665 8.00002C10.6665 5.0545 13.0543 2.66669 15.9998 2.66669C18.9454 2.66669 21.3332 5.0545 21.3332 8.00002V12H18.6665V8.00002C18.6665 6.52726 17.4726 5.33335 15.9998 5.33335C14.5271 5.33335 13.3332 6.52726 13.3332 8.00002V12H10.6665V8.00002Z"
            fill="#225AEA"/>
    </svg>
  );
};

export default LockTopStores;
