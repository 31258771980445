import React, {useState} from 'react';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import {Divider, Dropdown} from "antd";
import acc from "accounting";
import dayjs from "dayjs";
import ButtonComponent from "../../../Components/Button";
import Icon from "../../../Icon";
import cls from "classname";
import {useTranslation} from "react-i18next";
import IconWithText from "../../../Components/Text";

const AdItemProductCard = (
  {
    data,
    search,
    id,
    active,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setView,
    storeId,
    setModal,
    setVisibleModal,
    setRecordToPriceHistory
  }
) => {

  const {t} = useTranslation();
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [visible, setVisible] = useState(false);

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisibleTooltip(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    setVisibleTooltip(false);
    target.classList.remove('overflowing')
  }

  return (
    <div className='product-cards-page-item' key={id}>
      <div className="item-logo">
        {
          active === 'most_ads' ?
            <div className="item-logo-rank">
              #{id + 1}
            </div>
            :
            null
        }
        <a  href={`https://${data?.store?.custom_domain}/products/${data?.handle}`}
            target={'_blank'}
            rel="noopener noreferrer"
        >
          {
            data?.main_image
              ? <img src={utils.changeImageSize(data?.main_image, 496)}
                     alt=""
                     onError={utils.addDefaultSrc}
              />
              : <Image src={Images.defaultImg} small={false}/>
          }
        </a>
      </div>

      <div className="item-content"
           style={{position: visibleTooltip ? 'relative' : 'static'}}
      >
        <div className="item-title"
             data-text={data?.title}
             onMouseOver={mouseOver}
             onMouseLeave={mouseLeave}
        >
          {data?.title}
        </div>
        <Divider style={{margin: '12px auto'}} type={'horizontal'} />
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Price')}
          </span>
          <span className="item-stat-value clickable"
                onClick={() => {
                  setRecordToPriceHistory(data);
                  setVisibleModal(true);
                  setModal('priceHistory');
                }}
          >
            ${acc.formatNumber(data?.usd_price, 2, ',', '.')}
            {
              data?.currency !== 'USD' &&
              <span>({acc.formatNumber(data?.original_price, 2, ',', '.')} {data?.currency})</span>
            }
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Engagement')}
          </span>
          <span className="item-stat-value clickable"
                onClick={() => setView(`/ad-spot/${storeId}/detailed/${data?.id}/${search}`)}
          >
            {acc.formatNumber(data?.engagement_count, 0, ',', '.')}
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Ad Count')}
          </span>
          <span className="item-stat-value clickable"
                onClick={() => setView(`/ad-spot/${storeId}/detailed/${data?.id}/${search}`)}
          >
            {acc.formatNumber(data?.ad_count, 0, ',', '')}
          </span>
        </div>
        <div className="item-stat">
          <span className="item-stat-key">
            {t('Creation Date')}
          </span>
          <span className="item-stat-value">
            {dayjs(data?.created_at).format('MMMM DD, YYYY')}
          </span>
        </div>
        <div className="item-btn-wrapper">

          <Dropdown
            open={visible}
            onOpenChange={setVisible}
            dropdownRender={() => (
              <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
                <li className="action-dropdown_item no-hover"
                    onClick={() => {
                      setVisible(false);
                      handleOpenModalShopifyConnect(`${data?.internal_shop_id}_${data?.id}`)
                    }}>
                  <IconWithText
                    size={24}
                    icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
                    text={t('Import Product to Shopify')}
                  />
                </li>
                <li className="action-dropdown_item"
                    onClick={() => {
                      setVisible(false);
                      handleOpenModalAutoDSConnect({
                        'source': 'shopify',
                        'product_id': `${data?.store?.id}_${data?.id}`
                      })
                    }}>
                  <IconWithText
                    icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
                    text={t('Import Product to AutoDS')}
                  />
                </li>
              </ul>
            )}
            destroyPopupOnHide={true}
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentNode }
            overlayClassName={'filter-preset-cell-menu-wrapper'}
          >
            <ButtonComponent onClick={() => null}
                             text={null}
                             className={'item-btn import'}
            >
              <Icon type={'export_icon_database'} role={'icon'} />
              {t('Import')}
            </ButtonComponent>
          </Dropdown>

          <ButtonComponent onClick={() => {
            setView(`/ad-spot/${storeId}/detailed/${data?.id}/${search}`);
          }}
                           text={null}
                           className={cls('item-btn track view-details')}
          >
            {t('View details')}
          </ButtonComponent>
        </div>
      </div>

    </div>
  );
};

export default AdItemProductCard;
