import React from 'react';

const Energy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
    >
      <path
        d="M13.4926 3.62014L12.2135 10.3719H16.8323L10.5075 19.5407L11.7866 12.115L7.16784 12.0798L13.4926 3.62014ZM13.4926 2.48401H13.3868C13.2216 2.50815 13.0545 2.57869 12.8893 2.6975C12.8187 2.74577 12.7463 2.81631 12.6758 2.91099L12.6052 2.98153L6.24335 11.4022C5.95932 11.7809 5.92405 12.1782 6.13753 12.5921C6.35102 13.0061 6.68146 13.214 7.13257 13.214L10.437 13.2493L9.40666 19.3253C9.3584 19.5852 9.39552 19.834 9.51248 20.0716C9.62943 20.3092 9.82064 20.4744 10.0805 20.5691C10.2235 20.6397 10.3646 20.6749 10.5075 20.6749C10.8621 20.6749 11.1591 20.5097 11.3967 20.1774L17.7568 11.0476C18.0408 10.6689 18.0761 10.2772 17.8626 9.87436C17.6491 9.47152 17.3187 9.25804 16.8676 9.2339H13.5632L14.5935 3.90416C14.6176 3.80949 14.6287 3.71481 14.6287 3.62014C14.6287 3.31197 14.5229 3.05207 14.3094 2.83859C14.0959 2.6251 13.836 2.50629 13.5279 2.48401H13.4926Z"
        fill="#F6B60E"/>
      <path d="M13 3L14 3.5L13 9.5L17 10L17.5 10.5L12 18.5L11 20H10L11 12.5H7L6.5 11.5L13 3Z" fill="#F6B60E"/>
    </svg>
  );
};

export default Energy;
