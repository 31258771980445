import React from 'react';
import cls from "classname";

const TrackingStoreDatabase = (props) => {
  return (
    <svg width={props.width ? props.width : '24'}
         height={props.height ? props.height : '24'}
         fill={props.color ? props.color : '#707BA0'}
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg"
         className={cls(props.className, 'icon icon-database-filter')}
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M6 17V10H4V17C4 19.2091 5.79086 21 8 21H16C18.2091 21 20 19.2091 20 17V10H18V17C18 18.1046 17.1046 19 16 19H15V17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17V19H8C6.89543 19 6 18.1046 6 17ZM11 19H13V17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V19Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0145 8.90583C15.5491 8.92187 16.0551 9.15142 16.4193 9.54319C16.543 9.67629 16.6856 9.78711 16.8476 9.86479C17.0027 9.93912 17.2104 10.0003 17.5 10.0003C17.8832 10.0003 18.2362 9.81776 18.5231 9.44887C18.8165 9.07172 19 8.5407 19 8.00031C19 6.14206 17.5417 5.00053 16.0005 5.00124C15.5865 5.00144 14.3126 5.00111 12.893 5.00074L12.8896 5.00074C10.8817 5.00022 8.58432 4.99962 8.00118 5.00031C6.45732 5.00213 5 6.14321 5 8.00031C5 8.5407 5.18351 9.07172 5.47685 9.44887C5.76376 9.81776 6.11681 10.0003 6.5 10.0003C6.78962 10.0003 6.9973 9.93911 7.15242 9.86477C7.3145 9.78708 7.45705 9.67623 7.58079 9.54312C7.94496 9.15134 8.45088 8.92176 8.98553 8.90568C9.52018 8.8896 10.039 9.08835 10.4261 9.45752C10.694 9.71309 11.158 10.0002 12.0001 10.0003C12.8422 10.0004 13.3061 9.71338 13.5739 9.45782C13.961 9.08859 14.4798 8.88979 15.0145 8.90583ZM15.0201 10.9737C15.556 11.5211 16.362 12.0003 17.5 12.0003C18.6142 12.0003 19.5119 11.4353 20.1019 10.6768C20.6834 9.92909 21 8.96017 21 8.00031C21 4.86797 18.4686 3.00009 15.9995 3.00124C15.5862 3.00144 14.3118 3.00111 12.8901 3.00074C10.8836 3.00022 8.58385 2.99962 7.99882 3.00031C5.53222 3.00322 3 4.86696 3 8.00031C3 8.96017 3.31663 9.92909 3.89815 10.6768C4.48809 11.4353 5.38576 12.0003 6.5 12.0003C7.63822 12.0003 8.44441 11.5208 8.98026 10.9734C9.00253 10.9506 9.02433 10.9277 9.04567 10.9048C9.06893 10.927 9.09263 10.949 9.11679 10.9709C9.74414 11.5392 10.6774 12.0001 11.9999 12.0003C13.3224 12.0005 14.2557 11.5396 14.8831 10.9713C14.9074 10.9493 14.9311 10.9272 14.9545 10.9049C14.9759 10.928 14.9978 10.9509 15.0201 10.9737Z"
        />
    </svg>
  );
};

export default TrackingStoreDatabase;
