import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OnboardingActions from '../Redux/OnboardingRedux';
import CategoryActions from '../Redux/CategoryRedux';
import PlanActions from '../Redux/PlanRedux';
import {Layout, Row, Col, Spin} from 'antd';
import StepTitle from '../Components/StepTitle';
import LoginHeader from '../Components/LoginHeader';
import PlanCardOnboarding from '../Components/PlanCardOnboarding';
import SwitchSubscriptionPeriod from '../Components/SwitchSubscriptionPeriod';
import {descriptionPlanWithoutName} from '../Utils/utils'
import {useParams} from "react-router";
import AuthActions from "./LoginPage/reducer";

function OnboardingPlanNew(props) {
  const { isMobile, onBoarding, category, plan, fetching, confirmEmail, userInfo } = props;

  const { focusCategory, selectedCategory, selectedPlan, isYearlySubscription, stripeLoading } = onBoarding;
  const priceType = isYearlySubscription ? 'yearly' : 'monthly'

  let navigate = useNavigate();;
  const { t } = useTranslation();
  const { hash } = useParams();

  const step = { step: 1, title: 'Plans & Pricing', text: 'Select a plan that suits your e-commerce venture' };

  const categoriesList = category?.categories || [];
  const plansList = plan?.plans || [];

  useEffect(() => {
    document.title = 'Onboarding - Dropship';
    if (!userInfo) {
      if (hash) {
        confirmEmail(hash);
      }
    } else {
      if (window.location.pathname.includes('confirm')) navigate('/onboarding/plan')
      if (!categoriesList?.length) props.getCategories();
      if (!plansList?.length) props.getPlans();
    }
    let head = document.querySelector('head');
    let script = document.createElement('script')
    script.text = "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','oepmf');"
    head.appendChild(script)
    return () => {
      head.removeChild(script);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (categoriesList?.length) {
      props.setFocusCategory(categoriesList?.[0]);
      if (!selectedCategory.includes(categoriesList?.[0]?.id)) props.setSelectedCategory([categoriesList?.[0]?.id]);
      handleFocusCategory(categoriesList?.[0]);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [categoriesList?.length]);

  function handleNavigate(path) {
    navigate(path);
  }

  function handleFocusCategory(category) {
    if (!category.status) return;
    props.setFocusCategory(category);
  }

  function handleNext(selectedPlan) {
    if (selectedCategory.length === Object.keys(selectedPlan).length) {
      let isSomePaid = Object.values(selectedPlan).some(({ is_trial }) => !is_trial);
      if (isSomePaid) {
        handleNavigate('/onboarding/checkout/billing-address');
      } else {
        let [categoryId, plan] = Object.entries(selectedPlan)[0]
        let categoryIdWithPeriod = category?.categoriesMap?.[categoryId]?.prices?.[priceType]?.id
        props.saveSubscription({
          'plan': { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
        });
      }
    }
  }

  function handleDeleteSelectPlan() {
    const newSelectedPlans = { ...selectedPlan };
    delete newSelectedPlans[focusCategory.id];
    props.setSelectedPlan(newSelectedPlans);
  }

  function handleSelectPlan(plan) {
    if (selectedPlan?.[focusCategory?.id]?.id === plan?.id) {
      handleDeleteSelectPlan();
    } else {
      if (userInfo?.use_stripe) {
        props.setSelectedPlan({ ...selectedPlan, [focusCategory?.id]: plan });
        props.createStripeSession({
          plan_pricing: plan?.prices?.[priceType]?.id,
          ...(props.offerId && {promo_offer_id: props.offerId})
        })
      } else {
        props.setSelectedPlan({ ...selectedPlan, [focusCategory?.id]: plan });
        handleNext({ ...selectedPlan, [focusCategory?.id]: plan });
      }
    }
  }

  return (
    <Layout>
      <div className="login-row">
        <LoginHeader withLogout={true} />

        <Spin spinning={fetching || stripeLoading}>
          <div className="login-content category plans">
            <div className="content-main">

              <div className="content-left">
                <div className="top-row m-small">
                  <StepTitle stepInfo={step}/>

                  <SwitchSubscriptionPeriod/>

                </div>
                <div className="category-card">

                  <Row gutter={[{ sm: 0, md: 16 }, { xs: 99, sm: 99, md: 0 }]}>
                    {
                      userInfo ?
                        plansList
                        ?.filter(el => el?.status)
                        .map((el, index) => (
                          <Col xs={24} sm={24} md={8} key={el.id}>
                            <PlanCardOnboarding
                              plan={el}
                              selectedPlan={selectedPlan?.[focusCategory?.id]}
                              planIndex={index}
                              description={t(descriptionPlanWithoutName[el?.name])}
                              onSelectPlan={handleSelectPlan}
                              maxProducts={plan?.maxProducts}
                              isMobile={isMobile}
                              isOnboarding={true}
                            />
                          </Col>
                        ))
                        :
                        null
                    }
                  </Row>

                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
    userName: state.auth.userInfo?.first_name,
    userInfo: state.auth.userInfo,
    offerId: state.auth.offerId,
    onBoarding: state.onBoarding,
    category: state.category,
    plan: state.plan,
    fetching: state?.auth?.fetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(CategoryActions.categoriesRequest()),
  confirmEmail: (hash) => dispatch(AuthActions.confirmEmailRequest(hash)),
  getPlans: () => dispatch(PlanActions.plansRequest()),
  setSelectedCategory: (category) => dispatch(OnboardingActions.setSelectedCategory(category)),
  setFocusCategory: (category) => dispatch(OnboardingActions.focusCategory(category)),
  setSelectedPlan: (plan) => dispatch(OnboardingActions.setSelectedPlan(plan)),
  createStripeSession: (data) => dispatch(OnboardingActions.createStripeSessionRequest(data)),
  setFocusPlan: (plan) => dispatch(OnboardingActions.focusPlan(plan)),
  focusPlanReset: () => dispatch(OnboardingActions.focusPlanReset()),
  saveSubscription: (plan) => dispatch(OnboardingActions.saveSubscriptionRequest(plan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPlanNew);
