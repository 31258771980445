import React from 'react';

const ShopifyConnectNewDont = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M26.6667 21.3333C26.6667 20.597 26.0697 20 25.3333 20C24.597 20 24 20.597 24 21.3333V24H21.3333C20.597 24 20 24.597 20 25.3333C20 26.0697 20.597 26.6667 21.3333 26.6667H24V29.3333C24 30.0697 24.597 30.6667 25.3333 30.6667C26.0697 30.6667 26.6667 30.0697 26.6667 29.3333V26.6667H29.3333C30.0697 26.6667 30.6667 26.0697 30.6667 25.3333C30.6667 24.597 30.0697 24 29.3333 24H26.6667V21.3333Z"
        fill="#225AEA" />
      <path
        d="M13.0105 5.33307C13.553 5.33257 14.0313 5.33212 14.4959 5.44366C14.904 5.54165 15.2942 5.70327 15.6521 5.92258C16.0595 6.17223 16.3974 6.51078 16.7807 6.89475L17.8856 7.99981L23.7848 7.99981C24.4876 7.99979 25.094 7.99976 25.5934 8.04057C26.1205 8.08364 26.6448 8.17873 27.1493 8.43578C27.902 8.81928 28.5139 9.4312 28.8974 10.1839C29.1544 10.6884 29.2495 11.2126 29.2926 11.7398C29.3334 12.2392 29.3334 12.8455 29.3334 13.5484V16.2665C29.3334 16.6399 29.3334 16.8266 29.2607 16.9692C29.1968 17.0946 29.0948 17.1966 28.9694 17.2605C28.8267 17.3332 28.6401 17.3332 28.2667 17.3332H25.3334C20.9151 17.3332 17.3334 20.9149 17.3334 25.3332V25.5998C17.3334 25.9732 17.3334 26.1599 17.2607 26.3025C17.1968 26.4279 17.0948 26.5299 16.9693 26.5938C16.8267 26.6665 16.6401 26.6665 16.2667 26.6665H8.21517C7.51237 26.6665 6.90604 26.6665 6.40665 26.6257C5.87953 26.5827 5.35524 26.4876 4.85073 26.2305C4.09808 25.847 3.48616 25.2351 3.10267 24.4824C2.84561 23.9779 2.75052 23.4536 2.70745 22.9265C2.66665 22.4271 2.66667 21.8207 2.66669 21.1179V10.8817C2.66667 10.1789 2.66665 9.57251 2.70745 9.07311C2.75052 8.54598 2.84561 8.02169 3.10267 7.51718C3.48616 6.76453 4.09808 6.15261 4.85073 5.76912C5.35524 5.51206 5.87953 5.41697 6.40665 5.3739C6.90608 5.3331 7.51244 5.33312 8.2153 5.33314L13.0105 5.33307Z"
        fill="#225AEA" />
    </svg>
  );
};

export default ShopifyConnectNewDont;
