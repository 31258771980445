import React from 'react';
import cls from "classname";
import {OverlayScrollbar} from "../../../Components/ScrollBar";

const AdItemPageSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <OverlayScrollbar visibility="hidden">
      <div className={cls("fadspot-page-inner-content")}>
        <span className="link skeleton" style={{width: '100%', height: isMobile ? 142 : 88, margin: '24px auto'}}/>
        <span className="link skeleton" style={{width: '100%', height: isMobile ? 116 : 116, marginTop: 16}}/>
        <div className={'fadspot-page-items-wrapper'}>
          <span className="fadspot-page-item-wrapper link skeleton"/>
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
        </div>
      </div>
    </OverlayScrollbar>
  );
};

export default AdItemPageSkeleton;
