import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import cls from 'classname';
import { Dropdown, Checkbox, DatePicker } from 'antd';
import Icon from '../../../Icon';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import ButtonComponent from '../../../Components/Button';
import { langMapISO, calendarLocale } from '../../../Utils/utils';
import './DropdownDateBlock.less';

const { RangePicker } = DatePicker;

export default function DropdownDateBlock(
  {
    label,
    iconType,
    data,
    id,
    customOverlayClassName,
    customInnerOverlayClassName,
    dropdownFilters,
    setDropdownFilters,
    hideTimeZone=false
  }) {
  //inner dropdown period ref
  const dropdownInner = useRef();
  const { t } = useTranslation();
  const [clickSame, setClickSame] = useState(null);
  //show/hide outer dropdown
  const [visible, setVisible] = useState(false);
  //show/hide inner dropdown
  const [innerVisible, setInnerVisible] = useState(false);
  //set checkbox checked
  const [checkedList, setCheckedList] = useState(null);
  //main checkbox state
  const [checkAll, setCheckAll] = useState(false);
  //dayjs date value
  const [dateValue, setDateValue] = useState(null);
  const [isApply, setIsApply] = useState(false);

  //set custom state to inner checkbox if period selected
  const lang = useSelector(store => store?.auth?.userInfo?.language) || 'en';
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  //calendar display settings
  dayjs.updateLocale('en-us', {
    week: {
      dow: 1,
    },
  });

  useEffect(() => {
    setInitialValue();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dropdownFilters]);

  const setInitialValue = () => {
    //dropdownFilters[id]?.id - check if option range was selected (last 30 days, etc)
    if (!dropdownFilters[id]?.id) {
      if (!dropdownFilters[id].min && !dropdownFilters[id].max) {
        setCheckedList(data);
        setCheckAll(true);
        setDateValue(null);
      } else {
        setDateValue(dropdownFilters[id]);
      }
    } else {
      setCheckedList([dropdownFilters[id]?.id]);
      setCheckAll(false);
      let quantity = dropdownFilters[id]?.id.name.split(' ')[1];
      let value = dropdownFilters[id]?.id.name.split(' ')[2];
      let max = dayjs().format('YYYY-MM-DD');
      let min = dayjs().subtract(quantity, value).format('YYYY-MM-DD');
      setDateValue({ min: min, max: max });
    }
  };

  //toggle outer dropdown show/hide
  const handleVisibleChange = (value) => {
    if (value) setIsApply(false);
    if (!value && innerVisible) setInnerVisible(false);
    if (!value && !isApply) handleCancel();
    setVisible(value);
  };

  //toggle inner dropdown show/hide
  const handleInnerVisibleChange = (value) => {
    setInnerVisible(value);
  };

  //period checkbox handler + calculations min max
  const onChange = e => {
    const { value } = e.target;
    let val = value;

    if ((checkedList && checkedList[0].name === val?.name && !checkAll) || !val) {
      setCheckAll(true);
      setDateValue(null);
      setCheckedList(data);
    } else {
      setCheckedList([val]);
      let quantity = val.name.split(' ')[1];
      let value = val.name.split(' ')[2];
      let max = dayjs().format('YYYY-MM-DD');
      let min = dayjs().subtract(quantity, value).format('YYYY-MM-DD');
      setDateValue({ min: min, max: max });
      setCheckAll(false);
    }
    setInnerVisible(false);
  };

  const onCheckAllChange = e => {
    if (checkAll) return;
    const checked = e.target.checked;
    setCheckedList(checked ? data : null);
    setCheckAll(true);
    setDateValue(null);
    setCheckedList(data);
    setInnerVisible(false);
  };

  //string to display in outer dropdown after change is made
  function getSelectValue() {
    return dateValue
      ? dayjs(dateValue?.min).isSame(dayjs(dateValue?.max), 'year') //display or not year
        ? `${dayjs(dateValue?.min).locale(langMapISO[lang]).format('DD MMM')} - ${dayjs(dateValue?.max).locale(langMapISO[lang]).format('DD MMM')}`
        : `${dayjs(dateValue?.min).locale(langMapISO[lang]).format('DD MMM YYYY')} - ${dayjs(dateValue?.max).locale(langMapISO[lang]).format('DD MMM YYYY')}`
      : checkedList //if selected all
        ? checkedList?.length === data.length
          ? t('All')
          : t(checkedList?.[0]['name'])
        : t('All');
  }

  //string to display in inner dropdown after change is made
  function getSelectValueInner() {
    return checkedList?.length === data.length ? t('All') : t(checkedList?.[0]['name']);
  }

  function handleCancel() {
    let currentPeriod;
    if (dropdownFilters[id]?.id) {
      currentPeriod = [dropdownFilters[id]?.id];
    } else {
      if (dropdownFilters[id]?.min && dropdownFilters[id]?.max) {
        currentPeriod = [{
          days: 0,
          id: 999,
          name: 'Custom',
          value: null,
        }];
      } else {
        currentPeriod = data;
      }
    }
    setCheckedList(currentPeriod);
    setTimeout(() => setInitialValue(), 0);
    if (visible) setVisible(false);
  }

  const handleApply = () => {
    setIsApply(true);
    if (checkAll) {
      setDropdownFilters(state => ({ ...state, [id]: { min: null, max: null, id: null } }));
    } else {
      if (checkedList?.length === 1) {
        if (checkedList[0].name === 'Custom') {
          setDropdownFilters(state => ({ ...state, [id]: { ...dateValue, id: null } }));
        } else {
          setDropdownFilters(state => ({ ...state, [id]: { min: null, max: null, id: checkedList[0] } }));
        }
      }
    }
    setVisible(false);
  };

  return (
    <div className={cls("select-block", {
      'disabled-transition': isDisabledTransition
    })}>
      {label && <span className="select-block_label">{t(label)}</span>}

      <Dropdown
        overlayClassName={customOverlayClassName || 'dropdown-database-wrapper'}
        destroyPopupOnHide={true}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        dropdownRender={() => (
          <div ref={dropdownInner} className={'dropdown-date-wrapper'}>

            <div className="dropdown-date-title">
              {t('Date Range')}
            </div>

            <Dropdown
              overlayClassName={customInnerOverlayClassName || '.dropdown-database-wrapper-inner'}
              destroyPopupOnHide={true}
              getPopupContainer={(trigger) => trigger.parentNode}
              onOpenChange={handleInnerVisibleChange}
              trigger={['click']}
              open={innerVisible}
              dropdownRender={() => (
                <div className="dropdown-database-menu">

                  <ul className="dropdown-database-list">
                    {
                      checkedList?.[0]?.name === 'Custom' &&
                      <div className="dropdown-database-list-item">
                        <Checkbox checked={true}>
                          {t('Custom')}
                        </Checkbox>
                      </div>
                    }
                    <div className="dropdown-database-list-item">
                      <Checkbox onChange={onCheckAllChange}
                                checked={checkAll}
                      >
                        {t('All')}
                      </Checkbox>
                    </div>
                    {
                      data.map(el => (
                        <div key={el.id} className="dropdown-database-list-item">
                          <Checkbox
                            value={el}
                            checked={checkedList?.some(checked => checked.id === el.id)}
                            onChange={onChange}
                          >
                            {t(el.name)}
                          </Checkbox>
                        </div>
                      ))
                    }

                  </ul>
                </div>
              )}
            >
              <div className={cls('dropdown-database', { 'ant-dropdown-open': innerVisible })}>
                <Icon role="icon" type={iconType}/>
                <span className="dropdown-database_value">
                  {
                    getSelectValueInner()
                  }
                </span>
                <ArrowSelectDown/>
              </div>
            </Dropdown>

            <div className="rangepicker-title">
              <span>{t('Start Date')}</span>
              <span>{t('End Date')}</span>
            </div>

            <RangePicker className={'dropdown-date-rangepicker'}
                         value={[dateValue?.min ? dayjs(dateValue?.min) : null, dateValue?.max ? dayjs(dateValue?.max) : null]}
                         open={true}
                         format={'YYYY-MM-DD'}
                         locale={calendarLocale(lang)}
                         nextIcon={<Icon type={'arrow_datepicker_next'} role={'icon'} width={24} height={24} color={'#707ba0'}/>}
                         prevIcon={<Icon type={'arrow_datepicker_prev'} role={'icon'} width={24} height={24} color={'#707ba0'}/>}
                         clearIcon={<Icon role="icon" type="close_modal" color="#707BA0" width={10} height={10} opacity={1}/>}
                         onCalendarChange={(date, strings, info) => {
                           if (date.filter(el => el !== null).length) {
                             const inputs = document.querySelectorAll('.ant-picker-input');
                             //if date selected, set local and parent states
                             let min = (info?.range === 'end' && !date[0]) ? date[1]?.format('YYYY-MM-DD') : date[0]?.format('YYYY-MM-DD');
                             let max = info?.range === 'start' ? date[0]?.format('YYYY-MM-DD') : date[1]?.format('YYYY-MM-DD');
                             setDateValue({ min: min, max: max });

                             if (checkAll) setCheckAll(false);
                             setCheckedList([{
                               days: 0,
                               id: 999,
                               name: 'Custom',
                               value: null,
                             }]);

                             if (info.range === 'start') {
                               return inputs?.[1]?.firstElementChild?.focus();
                             } else if (info.range === 'end' && strings[0] === strings[1]) {
                               return inputs?.[0]?.firstElementChild?.focus();
                             }
                           } else {
                             setDateValue(null);
                           }
                         }}
                         getPopupContainer={() => dropdownInner.current}
                         placeholder={[t('Select Start Date'), t('Select End Date')]}
                         dateRender={current => {
                           return (
                             <div onClick={() => {
                               if (new Date(current.endOf('day')?.['$d']).toISOString() === dateValue?.min
                                 || new Date(current.endOf('day')?.['$d']).toISOString() === dateValue?.max) {
                                 setClickSame(new Date(current.endOf('day')?.d).toISOString());
                                 setTimeout(() => setClickSame(null), 400);
                               } else setClickSame(null);
                             }} className={cls('ant-picker-cell-inner', {
                               //style days after today
                               'ant-picker-future': dayjs().endOf('day') < current,
                               'ant-picker-cell-already-selected': clickSame === new Date(current.endOf('day')?.['$d']).toISOString(),
                             })}
                             >
                               {current.date()}
                             </div>
                           );
                         }}
                         disabledDate={current => dayjs().endOf('day') < current}
            />

            <div className="dropdown-date-button-wrapper">
              <div className="dropdown-date-info-wrapper">
                <p className="dropdown-date-info-date">
                  {
                    checkAll
                      ? t('All Dates')
                      : `${dayjs(dateValue?.min).locale(langMapISO[lang]).format('MMM DD, YYYY')} - ${dayjs(dateValue?.max).locale(langMapISO[lang]).format('MMM DD, YYYY')}`
                  }
                </p>
                {
                  hideTimeZone ?
                    null
                    :
                    <p className="dropdown-date-info-timezone">
                      {t('Store Timezone')}: UTC +0:00
                    </p>
                }
              </div>
              <ButtonComponent className={'dropdown-date-button'}
                               text={t('Cancel')}
                               onClick={handleCancel}
              />
              <ButtonComponent className={'dropdown-date-button apply'}
                               text={t('Apply')}
                               onClick={handleApply}
              />
            </div>
          </div>
        )}
        trigger={['click']}
        open={visible}
        placement={'bottomLeft'}
      >
        <div className={cls('dropdown-database', { 'ant-dropdown-open': visible }, id)}>
          <Icon role="icon" type={iconType}/>
          <span className="dropdown-database_value">
            {
              getSelectValue()
            }
          </span>
          <ArrowSelectDown/>
        </div>
      </Dropdown>
    </div>
  );
}
