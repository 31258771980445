import React from 'react';

const AdSpotClock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H12C12.5523 13 13 12.5523 13 12V7Z"
            fill="#225AEA"/>
    </svg>
  );
};

export default AdSpotClock;
