import React from 'react';

const AdSpotCheckmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.18505 15.2593C6.24498 15.6793 5.13968 15.3161 4.63199 14.4204L4.58958 14.3455C4.30651 13.8461 3.82734 13.4878 3.26818 13.3577C2.27005 13.1253 1.59586 12.1928 1.68802 11.1722L1.69968 11.043C1.75227 10.4606 1.56938 9.87898 1.19452 9.43018C0.505065 8.6047 0.505065 7.3953 1.19452 6.56982C1.56938 6.12102 1.75227 5.53943 1.69968 4.95705L1.68802 4.82784C1.59586 3.80717 2.27005 2.8747 3.26818 2.64233C3.82734 2.51215 4.30651 2.15393 4.58958 1.65447L4.63199 1.57964C5.13968 0.68388 6.24498 0.320656 7.18505 0.740658C7.70365 0.972357 8.29635 0.972357 8.81495 0.740658C9.75502 0.320656 10.8603 0.68388 11.368 1.57964L11.4104 1.65447C11.6935 2.15394 12.1727 2.51215 12.7318 2.64233C13.7299 2.8747 14.4041 3.80717 14.312 4.82784L14.3003 4.95704C14.2477 5.53943 14.4306 6.12102 14.8055 6.56982C15.4949 7.3953 15.4949 8.6047 14.8055 9.43018C14.4306 9.87898 14.2477 10.4606 14.3003 11.043L14.312 11.1722C14.4041 12.1928 13.7299 13.1253 12.7318 13.3577C12.1727 13.4878 11.6935 13.8461 11.4104 14.3455L11.368 14.4204C10.8603 15.3161 9.75502 15.6793 8.81495 15.2593C8.29636 15.0276 7.70365 15.0276 7.18505 15.2593ZM5.88545 9.28951C6.62116 10.0603 7.85157 10.0603 8.58728 9.28951L10.86 6.90862C11.133 6.6226 11.1369 6.17371 10.8689 5.88295C10.5756 5.56463 10.0743 5.56025 9.77541 5.87338L8.00872 7.7242C7.58657 8.16645 6.88187 8.17059 6.45455 7.73333L6.20898 7.48206C5.91588 7.18214 5.43252 7.18498 5.14296 7.48833C4.86962 7.77468 4.86962 8.22532 5.14296 8.51167L5.88545 9.28951Z"
        fill="#3897F0"/>
    </svg>
  );
};

export default AdSpotCheckmark;
