import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuthActions from './LoginPage/reducer';
import { Layout, Spin, Card, Statistic } from 'antd';
import LoginHeader from '../Components/LoginHeader';
import Images from '../Images';
import utils from '../Utils/utils';
import ButtonComponent from "../Components/Button";

const { Countdown } = Statistic;

function VerifyCodePage(props) {
  const { isMobile, fetching } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { t } = useTranslation();

  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);


  useEffect(() => {
    document.title = 'Verify Email - Dropship';
  }, []);


  function handleResendEmail() {
    if (state) {
      setDeadline(Date.now() + 1000 * 30);
      setResendFetching(true);
      props.checkPassword(state)
    } else {
      navigate('/restore-password');
    }
  }

  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">
          <LoginHeader />
          <div className="login-content">
            <div className="content-main">
              <div className="content-left content-left_32">
                <Card className="login-card">
                  <h2 className='card-title' style={{ marginBottom: 12 }}>{t('Verify Email')}</h2>
                  <div className="text-content">
                    {t('Please check your email')}<br /> <span className='mark-text'>{state}</span>
                  </div>
                  <div className="verify-btn-block">
                    <ButtonComponent className="btn-primary" type="primary white"
                      href={`https://mail.${utils.checkMailDomain(state).link}.com`} target="_blank" rel="noopener noreferrer">
                      <img className="btn-icon icon-space" src={Images[utils.checkMailDomain(state).type]} alt="" />
                      {t('Go To')} {utils.checkMailDomain(state).name}
                    </ButtonComponent>
                  </div>
                  <div className='sign-up-link check-mail' style={{ justifyContent: 'space-between' }}>
                    {
                      resendFetching
                        ? <div className='resend-timer'>
                          <Countdown title=""
                            value={deadline}
                            onFinish={onFinish}
                            format='ss'
                            prefix={<span>{t('Resend In')} </span>}
                            suffix={<span> {t('Sec...')}</span>}
                          />
                          <Spin />
                        </div>
                        : <span className='text-link' onClick={handleResendEmail}>{t('Resend Code')}</span>
                    }
                    <Link to="/restore-password">{t('Change Email')}</Link>
                  </div>
                </Card>
              </div>
              <div className="content-right large">
                <div className={'bg'} style={{
                  backgroundImage: `url(${isMobile ? Images.verifyCodeMobile : Images.verifyCode})`,
                  maxWidth: 613,
                  margin: '0 auto',
                }} />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
    error: state.auth.error,
    fetching: state.auth.fetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmPassword: (code) => dispatch(AuthActions.confirmPasswordRequest(code)),
  checkPassword: (email) => dispatch(AuthActions.checkPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodePage);