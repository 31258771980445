import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import Icon from '../../../Icon';
import './ProductDetailsCrad.less';

export const ProductDetailsCard = (
  {
    skeleton,
    isMobile,
    header,
    headerClassName,
    bodyClassName,
    wrapperClassName,
    withLine,
    isLocked,
    onOpenUpgradePlan,
    ...props }) => {

  const { t } = useTranslation();

  if (skeleton) {
    return <div className={'product-details-card skeleton'}/>;
  } else {
    return (
      <div className={cls('product-details-card', wrapperClassName)}>
        <div className={cls('product-details-card-header', headerClassName)}>
          {header}
        </div>

        {withLine ? <Divider style={{ margin: '0 0 24px' }}/> : null}

        <div className={cls('product-details-card-body', bodyClassName)}
        >
          {
            props.children
          }
        </div>
        {
          isLocked
            ? <div className='block-locked'>
              <div className='block-locked-info-wrap'>
                <Icon role="icon" type="locked_block" />
                <div className='block-locked-info-wrap block-locked-info-wrap_text'>
                  <span className='block-locked-title'>{t('Locked Content')}</span>
                  <span className='block-locked-text'>
                    <span className={'block-locked-text block-locked-text_clickable'}
                          onClick={()=> onOpenUpgradePlan(isLocked)}
                    >
                      {t('Upgrade')}
                    </span>
                    {t('your plan to unlock')}
                  </span>
                </div>
              </div>
            </div>
            : null
        }
      </div>
    );
  }
};

export default ProductDetailsCard;
