import React from 'react';

const SwitchSubscriptionPeriodSkeleton = () => {
  return (
    <div className={'switch-block'}>
      <div className={'switch-block-wrap'}>
        <span className={'link skeleton'} style={{width: 238, height: 40, margin: '0 auto'}} />
      </div>
      <span className="link skeleton" style={{width: 225, height: 20, margin: '0 auto'}} />

    </div>
  );
};

export default SwitchSubscriptionPeriodSkeleton;
