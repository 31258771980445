import React, {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cls from "classname";
import dayjs from 'dayjs';
import Creators from "./reducer";
import BreadcrumbsActions from '../Breadcrumbs/actions';
import ProductDatabaseActions from '../ProductDatabasePage/reducer';
import openNotification from '../../Components/Notification';
import {OverlayScrollbar} from '../../Components/ScrollBar';
import AdminSalesStorePage from "./AdminSalesStorePage";
import {Spin} from "antd";
import AdminFiltersBlock from "./components/AdminFiltersBlock";
import './styles.less';

export const AdminStoreTable = (
  {
    fetching,
    errors,
    searchErrors,
    userInfo,
    stores,
    getStores,
    salesTracker,
    getExpandableChartProductTable,
    resetStores,
    getFilters,
    ...props
  }) => {

  let max = new Date(dayjs()).toISOString();
  let min = new Date(dayjs().subtract(30, 'days')).toISOString();

  const [sortOrder, setSortOrder] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    document.title = `Store Table - Dropship`;
    if (props?.filters?.report_period.min !== min || props?.filters?.report_period.max !== max) props.setFilters({
      report_period: {
        min,
        max
      }
    })
    if (props?.period === null || props?.period?.[0]?.id !== 4) props.setPeriod([{
      id: 4,
      name: 'Last 30 days',
      value: 'Last 30 days',
      days: 30
    }])
    props.clearBreadCrumbs();
    props.addBreadCrumb({
      name: 'Store Table',
      link: `/admin/store-table`,
    });
    resetStores();
    getFilters();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  function handleOpenNotAvailable() {
    openNotification({
      type: 'warning',
      message: 'Data is being collected and will be displayed within 24 hours',
      style: {minWidth: '716px'},
    });
  }

  const handleSort = useCallback(({order, columnKey}) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const handleChangePageSize = useCallback(value =>
      setPageSize(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);
  const handleChangePageNumber = useCallback(value =>
      setPageNumber(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);

  return (
//custom scrollbars
    <Spin spinning={fetching}>
      <OverlayScrollbar visibility="hidden">
        <div className={cls("product-database-page competitors-page sales-tracker-page admin-store-table")}>
          <AdminFiltersBlock
            data={stores?.results || []}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            getStores={getStores}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={handleChangePageSize}
            setPageNumber={handleChangePageNumber}
          />

          {stores?.results?.length ?
            <AdminSalesStorePage fetching={fetching}
                                 handleOpenNotAvailable={handleOpenNotAvailable}
                                 onSort={handleSort}
                                 sortOrder={sortOrder}
                                 pageSize={pageSize}
                                 pageNumber={pageNumber}
                                 setPageSize={handleChangePageSize}
                                 setPageNumber={handleChangePageNumber}
                                 isChanged={isChanged}
                                 total={stores?.count || 0}
            />
            :
            null}
        </div>
      </OverlayScrollbar>
    </Spin>
  );
};

AdminStoreTable.propTypes = {
  isAdmin: PropTypes.bool,
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  fetching: state.adminStoreTable.loading,
  errors: state.adminStoreTable.errors,
  salesTracker: state.adminStoreTable,
  searchErrors: state.adminStoreTable.searchErrors,
  stores: state.adminStoreTable.stores,
  filters: state.adminStoreTable.filters,
  period: state.adminStoreTable.checkedList,
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (data) => dispatch(Creators.setAdminFilters(data)),
  setPeriod: (data) => dispatch(Creators.setAdminCheckedList(data)),
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  resetStores: () => dispatch(Creators.resetStores()),
  getStores: (data) => dispatch(Creators.getAdminTrackingStoresRequest(data)),
  getFilters: () => dispatch(ProductDatabaseActions.getProductDatabaseFiltersRequest()),
  getExpandableChartProductTable: (data) => dispatch(Creators.getAdminExpandableChartRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoreTable);
