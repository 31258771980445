import {createActions, createReducer} from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions(
  {
    getAutoStoreRequest: null,
    getAutoStoreSuccess: ['payload'],
    getAutoStoreFailure: ['payload'],

    recheckAutoStoresRequest: null,
    recheckAutoStoresSuccess: ['payload'],
    recheckAutoStoresFailure: ['payload'],

    connectAutoStoreRequest: ['payload'],
    connectAutoStoreSuccess: ['payload'],
    connectAutoStoreFailure: ['payload'],

    importProductToAutoStoreRequest: ['payload'],
    importProductToAutoStoreSuccess: ['payload'],
    importProductToAutoStoreFailure: ['payload'],

    deleteAutoStoreRequest: null,
    deleteAutoStoreSuccess: ['payload'],
    deleteAutoStoreFailure: ['payload'],

    changeVisibleModalAuto: ['payload'], // { isVisibleModal, initialUrl, importProductId }

    resetState: null
  }
);

export const AutoDSStoreTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loading: false,
  errors: false,
  deleteErrors: false,
  deleteResult: false,
  email: '',
  stores: [],
  isVisibleModal: null,
  importProductId: null,
  importProductResult: null,
});

export const AutoDSSelectors = {
  getAutoDS: (state) => state?.autoDS
}

const resetState = (state) =>
  state.merge({...INITIAL_STATE, stores: state?.stores, email: state?.email});

const deleteAutoStoreRequest = (state) =>
  state.merge({loading: true});

const deleteAutoStoreSuccess = (state, { payload: data }) =>
  state.merge({...INITIAL_STATE, isVisibleModal: state?.isVisibleModal, deleteResult: true});

const deleteAutoStoreFailure = (state, { payload: { errors } } ) =>
  state.merge({...INITIAL_STATE, deleteErrors: errors});

const getAutoStoreRequest = (state) =>
  state.merge({loading: true});

const getAutoStoreSuccess = (state, { payload: data }) =>
  state.merge({loading: false, email: data?.email, stores: data?.stores});

const getAutoStoreFailure = (state, { payload: { errors } } ) =>
  state.merge({loading: false, errors});

const recheckAutoStoresRequest = (state) =>
  state.merge({loading: true});

const recheckAutoStoresSuccess = (state, { payload: data }) =>
  state.merge({loading: false, email: data?.email, stores: data?.stores});

const recheckAutoStoresFailure = (state, { payload: { errors } } ) =>
  state.merge({loading: false, errors});

const connectAutoStoreRequest = (state) =>
  state.merge({loading: true});

const connectAutoStoreSuccess = (state) =>
  state.merge({loading: false});

const connectAutoStoreFailure = (state, { payload: { errors } }) =>
  state.merge({loading: false, errors});

const importProductToAutoStoreRequest = (state) =>
  state.merge({loading: true});

const importProductToAutoStoreSuccess = (state, { payload: data }) =>
  state.merge({loading: false, importProductResult: {...data}});

const importProductToAutoStoreFailure = (state, { payload: { errors } }) =>
  state.merge({loading: false, errors});

const changeVisibleModalAuto = (state, { payload: { isVisibleModal, importProductId } }) =>
  state.merge({ isVisibleModal, importProductId });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_VISIBLE_MODAL_AUTO]: changeVisibleModalAuto,

  [Types.RESET_STATE]: resetState,

  [Types.DELETE_AUTO_STORE_REQUEST]: deleteAutoStoreRequest,
  [Types.DELETE_AUTO_STORE_SUCCESS]: deleteAutoStoreSuccess,
  [Types.DELETE_AUTO_STORE_FAILURE]: deleteAutoStoreFailure,

  [Types.GET_AUTO_STORE_REQUEST]: getAutoStoreRequest,
  [Types.GET_AUTO_STORE_SUCCESS]: getAutoStoreSuccess,
  [Types.GET_AUTO_STORE_FAILURE]: getAutoStoreFailure,

  [Types.RECHECK_AUTO_STORES_REQUEST]: recheckAutoStoresRequest,
  [Types.RECHECK_AUTO_STORES_SUCCESS]: recheckAutoStoresSuccess,
  [Types.RECHECK_AUTO_STORES_FAILURE]: recheckAutoStoresFailure,

  [Types.CONNECT_AUTO_STORE_REQUEST]: connectAutoStoreRequest,
  [Types.CONNECT_AUTO_STORE_SUCCESS]: connectAutoStoreSuccess,
  [Types.CONNECT_AUTO_STORE_FAILURE]: connectAutoStoreFailure,

  [Types.IMPORT_PRODUCT_TO_AUTO_STORE_REQUEST]: importProductToAutoStoreRequest,
  [Types.IMPORT_PRODUCT_TO_AUTO_STORE_SUCCESS]: importProductToAutoStoreSuccess,
  [Types.IMPORT_PRODUCT_TO_AUTO_STORE_FAILURE]: importProductToAutoStoreFailure,
});
