import React, { useEffect, useInsertionEffect, useState } from 'react';
import {connect} from 'react-redux'
import {Link} from "react-router-dom"
import { useTranslation } from 'react-i18next';
import AuthActions from './LoginPage/reducer';
import {Layout, Card, Spin} from 'antd'
import RestorePasswordForm from '../Components/Forms/RestorePasswordForm'
import LoginHeader from '../Components/LoginHeader'
import Images from '../Images'

function RestorePasswordPage(props) {

  const {error, fetching, isMobile} = props

  const { t } = useTranslation();

  const [firstLoading, setFirstLoading] = useState(true);

  function handleSubmit(data) {
    if (fetching) return
    props.checkPassword(data.email)
  }

  useInsertionEffect(() => {
    document.title = 'Forgot Password - Dropship';
    if (firstLoading) {
      props.resetError();
      setFirstLoading(false);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[])

  useEffect(() => {
    if (error) props.resetError()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error])

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">
            <LoginHeader/>
          <div className="login-content">
            <div className="content-main">
              <div className="content-left content-left_32">
                <Card className="login-card">
                  <h2 className='card-title'>{t('Reset Password')}</h2>
                  <div className="text-content">
                    {t('Enter the email associated with your Dropship account, and we’ll send an email with instructions to reset your password')}
                  </div>
                  <RestorePasswordForm onSubmit={handleSubmit} error={!firstLoading && error?.message}>
                    <div className="sign-up-link" style={{marginTop: 16}}>
                      {t('Remember Password?')} <Link to="/login">{t('Sign In')}</Link>
                    </div>
                  </RestorePasswordForm>
                </Card>
              </div>
              <div className="content-right large">
                <div className={'bg'} style={{
                  backgroundImage: `url(${isMobile ? Images.forgotPasswordMobile : Images.forgotPassword})`,
                  maxWidth: 515,
                  margin: '0 auto',
                }}/>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    isMobile: state.nav.isMobile,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  checkPassword: (email) => dispatch(AuthActions.checkPasswordRequest(email)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestorePasswordPage)
