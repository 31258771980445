import React from 'react';
import cls from 'classname';

const ArrowDouble = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       fill={color ? color : '#225AEA'}
       className={cls(className, 'icon')}
       style={style} viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0041 12.0196C12.0049 12.1506 11.9796 12.2805 11.9299 12.4017C11.8801 12.523 11.8068 12.6333 11.7141 12.7263L7.71409 16.7073C7.62085 16.8001 7.51016 16.8737 7.38834 16.9239C7.26652 16.9742 7.13595 17 7.00409 17C6.87223 17 6.74166 16.9742 6.61984 16.9239C6.49802 16.8737 6.38733 16.8001 6.29409 16.7073C6.20085 16.6145 6.12689 16.5043 6.07643 16.3831C6.02597 16.2619 6 16.1319 6 16.0007C6 15.8694 6.02597 15.7395 6.07643 15.6182C6.12689 15.497 6.20085 15.3868 6.29409 15.294L9.60409 12.0196L6.42409 8.73528C6.3218 8.64372 6.23959 8.53215 6.18261 8.40753C6.12562 8.28291 6.09508 8.14793 6.0929 8.01103C6.09072 7.87412 6.11695 7.73825 6.16994 7.61189C6.22293 7.48554 6.30155 7.37143 6.40087 7.27669C6.5002 7.18196 6.61809 7.10864 6.74719 7.06132C6.87628 7.014 7.0138 6.99371 7.15113 7.0017C7.28847 7.00969 7.42267 7.04579 7.54535 7.10776C7.66802 7.16972 7.77653 7.25621 7.86409 7.36182L11.7241 11.3429C11.9004 11.5249 12.0005 11.7668 12.0041 12.0196Z"
    />
    <path
      d="M18.0041 12.0196C18.0049 12.1506 17.9796 12.2805 17.9299 12.4017C17.8801 12.523 17.8068 12.6333 17.7141 12.7263L13.7141 16.7073C13.6209 16.8001 13.5102 16.8737 13.3883 16.9239C13.2665 16.9742 13.136 17 13.0041 17C12.8722 17 12.7417 16.9742 12.6198 16.9239C12.498 16.8737 12.3873 16.8001 12.2941 16.7073C12.2009 16.6145 12.1269 16.5043 12.0764 16.3831C12.026 16.2619 12 16.1319 12 16.0007C12 15.8694 12.026 15.7395 12.0764 15.6182C12.1269 15.497 12.2009 15.3868 12.2941 15.294L15.6041 12.0196L12.4241 8.73528C12.3218 8.64372 12.2396 8.53215 12.1826 8.40753C12.1256 8.28291 12.0951 8.14793 12.0929 8.01103C12.0907 7.87412 12.1169 7.73825 12.1699 7.61189C12.2229 7.48554 12.3015 7.37143 12.4009 7.27669C12.5002 7.18196 12.6181 7.10864 12.7472 7.06132C12.8763 7.014 13.0138 6.99371 13.1511 7.0017C13.2885 7.00969 13.4227 7.04579 13.5453 7.10776C13.668 7.16972 13.7765 7.25621 13.8641 7.36182L17.7241 11.3429C17.9004 11.5249 18.0005 11.7668 18.0041 12.0196Z"
    />
  </svg>
);

export default ArrowDouble;
