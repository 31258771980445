import React from 'react';

const AdSpotChartComments = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_37799_211190)">
        <path d="M3 4H21V18H10.5L3 20V18V4Z" fill="#225AEA"/>
        <path
          d="M12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12Z"
          fill="white"/>
        <path
          d="M16 12C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12Z"
          fill="white"/>
        <path
          d="M8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
          fill="white"/>
        <path
          d="M19 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V21C2.00031 21.1772 2.04769 21.3511 2.1373 21.504C2.22691 21.6569 2.35553 21.7832 2.51 21.87C2.65946 21.9547 2.82821 21.9995 3 22C3.17948 21.9999 3.35564 21.9516 3.51 21.86L8 19.14C8.16597 19.0412 8.35699 18.9926 8.55 19H19C19.7956 19 20.5587 18.6839 21.1213 18.1213C21.6839 17.5587 22 16.7956 22 16V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7956 3 19 3ZM20 16C20 16.2652 19.8946 16.5196 19.7071 16.7071C19.5196 16.8946 19.2652 17 19 17H8.55C8.00382 16.9996 7.46789 17.1482 7 17.43L4 19.23V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V16Z"
          fill="#225AEA"/>
      </g>
      <defs>
        <clipPath id="clip0_37799_211190">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdSpotChartComments;
