import React, {useState, useCallback, useEffect} from 'react';
import {Divider, Dropdown, Slider, Spin, Tooltip} from "antd";
import cls from "classname";
import {useTranslation} from "react-i18next";
import ArrowSelectDown from "../../../../Icon/img/ArrowSelectDown";
import Icon from "../../../../Icon";
import InputComponent from "../../../../Components/Input";
import ButtonComponent from "../../../../Components/Button";
import acc from "accounting";
import useDebounce from "../../../../hooks/use-debounce";
import './DropdownBarFilter.less';

const DropdownBarFilter = (
  {
    title,
    tooltip=null,
    position,
    id,
    isStore=false,
    min,
    max,
    value,
    setValue,
    disabled,
    countLoading,
    count=0,
    handleCount,
    isStandard=false
  }
) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [localValue, setLocalValue] = useState({});

  const debouncedValue = useDebounce(localValue, 500);

  const inputChangeHandler = useCallback((e, field) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setLocalValue(state => ({ ...state, [field]: e.target.value.trim().length ? +parsedValue : null }));
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleVisibleChange = (value) => {
    if (!value) setLocalValue({min: value?.[id]?.min, max: value?.[id]?.max });
    setVisible(value);
  }

  useEffect(() => {
    setLocalValue({
      min: value?.[id]?.min ? value?.[id]?.min : min,
      max: value?.[id]?.max ? value?.[id]?.max : max,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (debouncedValue?.min < min || debouncedValue?.max > max || debouncedValue?.min > debouncedValue?.max) {
        if (debouncedValue?.min < min) {
          setLocalValue(state => ({...state, min: value?.[id]?.min ? value?.[id]?.min : min}));
        } else if (debouncedValue?.max < max) {
          setLocalValue(state => ({...state, max: value?.[id]?.max ? value?.[id]?.max : max}));
        } else {
          setLocalValue({
            min: value?.[id]?.min ? value?.[id]?.min : min,
            max: value?.[id]?.max ? value?.[id]?.max : max,
          });
        }
      } else {
        handleCount({
          id: id === 'products_count' ? 'products' : id,
          value: {min: debouncedValue?.min, max: debouncedValue?.max === max ? null : debouncedValue?.max}
        })
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [debouncedValue])

  return (
    <div className={cls('main-filter-dropdown', id, {
      'product': !isStore
    })}>
      <Dropdown
        overlayClassName={'main-filter-dropdown-content-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={position}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
          <div className='main-filter-dropdown-content'>
            <Spin spinning={countLoading}>
            <div className="main-filter-dropdown-content-title">
              {t(`Filter By: ${title === 'Revenue' ? 'Revenue (USD)' : title}`)}
              {
                tooltip ?
                  <Icon
                    type={'attention_outline'}
                    role="button"
                    width={16}
                    height={16}
                    tooltipProps={{
                      trigger: 'hover',
                      placement: 'bottom',
                      overlayClassName: 'subscription-tooltip dropdown-bar-tooltip',
                      getPopupContainer: (trigger) => trigger.parentNode,
                    }}
                    titleText={t(tooltip)}
                  />
                :
                null
              }
            </div>
              <div className="main-filter-dropdown-content-bars-wrapper">

              <Slider defaultValue={[localValue?.min, localValue?.max]}
                      value={[localValue?.min, localValue?.max]}
                      range={{ draggableTrack: true }}
                      min={min}
                      max={max}
                      tooltip={{ open: false }}
                      onChange={(value) => setLocalValue({
                        min: value[0],
                        max: value[1]
                      })}
              />
            </div>

              <div className={'slider-legend-wrapper'}>
                <span className="slider-legend">
                  {acc.formatNumber(min, 0, ',', '.')}
                </span>
                <span className="slider-legend">
                  {acc.formatNumber(max, 0, ',', '.')}+
                </span>
              </div>

              <div className="min-max-input-wrapper">
                <InputComponent
                  placeholder={t(title)}
                  name={title + '_min'}
                  prefix={<span>Min<Divider type='vertical' className={'min-max-divider'}/></span>}
                  value={acc.formatNumber(localValue?.min, '0', ',', '.')}
                  onChange={e => inputChangeHandler(e, 'min')}
                />

                <div className="cost-divider" style={{
                  width: 58,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transform: 'rotate(180deg)'
                }}>
                  <Icon type="min_max_input_divider"
                        role={'icon'}
                        width={7}
                        height={10}
                  />
                </div>

                <InputComponent
                  placeholder={t(title)}
                  name={title + '_max'}
                  prefix={<span>Max<Divider type='vertical' className={'min-max-divider'}/></span>}
                  value={acc.formatNumber(localValue?.max, '0', ',', '.')}
                  onChange={e => inputChangeHandler(e, 'max')}
                />
              </div>
              <Divider style={{margin: '16px 0'}} type={'horizontal'}/>
              <div className="main-filter-dropdown-content-footer">
                <div className="results">
                  {t('Results')}: {acc.formatNumber(count, 0, ',', '.')}
                </div>
                <div className="main-filter-dropdown-content-button-wrapper">
                  <ButtonComponent text={t('Cancel')}
                                   className={'button-cancel'}
                                   onClick={() => {
                                     setVisible(false);
                                   }}
                  />

                  {
                    disabled ?
                      <Tooltip placement='top'
                               title={isStandard ? 'To preform this action, you would need to upgrade to Premium plan.' : 'To perform this action, you would need to upgrade to Standard or Premium plan.'}
                               overlayClassName={'subscription-tooltip scheduled skip'}
                               arrow={true}
                               trigger={'hover'}
                               getPopupContainer={(trigger) => trigger.parentNode}
                               destroyTooltipOnHide={true}
                      >
                        <ButtonComponent text={t('Apply Filter')}
                                         className={cls('button-apply', {
                                           'button-apply-disabled': disabled
                                         })}
                                         onClick={() => null}
                        />
                      </Tooltip>
                      :
                      <ButtonComponent text={t('Apply Filter')}
                                       className={cls('button-apply', {
                                         'button-apply-disabled': disabled
                                       })}
                                       onClick={() => {
                                         setVisible(false);
                                         if (!(min === localValue?.min && max === localValue?.max && (Boolean(value) && value?.[id]?.min === min && (value?.[id]?.max === max || value?.[id]?.max === null)))) {
                                           setValue(prev => ({
                                             ...prev,
                                             [id]: {
                                               min: localValue?.min,
                                               max: localValue?.max === max ? null : localValue?.max
                                             }
                                           }))
                                         }
                                       }}
                                       disabled={false}
                      />
                  }
                </div>
              </div>
            </Spin>
          </div>
          )}
        >
        <div className='main-filter-dropdown-inner'>
          {title}
          <ArrowSelectDown />
        </div>
      </Dropdown>
    </div>
  );
};

export default DropdownBarFilter;
