import React from 'react';

const SalesTrackerDashboard = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width ? width : 40}
         height={height ? height : 40}
         viewBox="0 0 40 40"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20 3.33337C19.0795 3.33337 18.3333 4.07957 18.3333 5.00004V6.76986C12.3021 7.52194 7.5219 12.3022 6.76983 18.3334H5C4.07953 18.3334 3.33334 19.0796 3.33334 20C3.33334 20.9205 4.07953 21.6667 5 21.6667H6.76983C7.5219 27.6979 12.3021 32.4781 18.3333 33.2302V35C18.3333 35.9205 19.0795 36.6667 20 36.6667C20.9205 36.6667 21.6667 35.9205 21.6667 35V33.2302C27.6979 32.4781 32.4781 27.6979 33.2302 21.6667H35C35.9205 21.6667 36.6667 20.9205 36.6667 20C36.6667 19.0796 35.9205 18.3334 35 18.3334H33.2302C32.4781 12.3022 27.6979 7.52194 21.6667 6.76986V5.00004C21.6667 4.07957 20.9205 3.33337 20 3.33337ZM20 23.3334C21.841 23.3334 23.3333 21.841 23.3333 20C23.3333 18.1591 21.841 16.6667 20 16.6667C18.1591 16.6667 16.6667 18.1591 16.6667 20C16.6667 21.841 18.1591 23.3334 20 23.3334Z"
            fill="#D71313"/>
    </svg>
  );
};

export default SalesTrackerDashboard;
