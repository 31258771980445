import React from 'react';

const AdSpotLove = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0001 1.71387C9.27213 1.71387 6.65592 2.79754 4.72698 4.72649C2.79803 6.65543 1.71436 9.27164 1.71436 11.9996C1.71436 14.7275 2.79803 17.3438 4.72698 19.2727C6.65592 21.2016 9.27213 22.2853 12.0001 22.2853C14.728 22.2853 17.3443 21.2016 19.2732 19.2727C21.2021 17.3438 22.2858 14.7275 22.2858 11.9996C22.2858 9.27164 21.2021 6.65543 19.2732 4.72649C17.3443 2.79754 14.728 1.71387 12.0001 1.71387Z"
        fill={`url(#paint0_linear_36566_241350_${id})`}/>
      <path
        d="M15.1804 6.85742C12.3544 6.85742 12.0009 9.20256 12.0009 9.20256C12.0009 9.20256 11.6486 6.85742 8.82257 6.85742C6.10457 6.85742 5.31257 9.71428 5.64429 11.2417C6.51857 15.2789 12.0009 18.1074 12.0009 18.1074C12.0009 18.1074 17.4844 15.2789 18.3587 11.2417C18.6891 9.71428 17.8958 6.85742 15.1804 6.85742Z"
        fill="white"/>
      <defs>
        <linearGradient id={`paint0_linear_36566_241350_${id}`} x1="12.0001" y1="1.71387" x2="12.0001" y2="22.2853"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6680"/>
          <stop offset="1" stopColor="#E61739"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotLove;
