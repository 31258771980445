import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Warning } from '../../Icon/img';
import ButtonComponent from "../Button";
import {useTranslation} from "react-i18next";
import './styles.less';
import Icon from "../../Icon";

export const BannerCreditsLimited = (
  {
    className,
    text,
    btnText,
    onClick,
    skeleton,
    isPrem=false,
    isYearly=false
  }) => {

  const {t} = useTranslation();

  if (skeleton) return <div className='limited-banner skeleton'/>
  return (
    <div className={cls(className, 'limited-banner credits-limited-banner')}>
      <Warning isCircle={true} />
      <span className='text'>
        {text}
        <Icon
          type={'attention_outline'}
          role="button"
          width={16}
          height={16}
          tooltipProps={{
            trigger: 'hover',
            placement: 'bottom',
            overlayClassName: 'subscription-tooltip',
            getPopupContainer: (trigger) => trigger.parentNode,
          }}
          titleText={t(
            isYearly ?
              'Each ad seen is 1 credit used. Credits reset every 30 days.'
              :
              'Each ad seen is 1 credit used. Credits reset on your next billing cycle.'
          )}
        />
      </span>
      {
        isPrem ?
          null
          :
          <ButtonComponent type={'primary'}
                           className={cls('btn-primary white')}
                           onClick={onClick}
          >
            {btnText}
          </ButtonComponent>
      }
    </div>
  )
}

BannerCreditsLimited.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  skeleton: PropTypes.bool,
};

export default BannerCreditsLimited;
