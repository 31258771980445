import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import Icon from '../../Icon';
import './styles.less';

export const BannerSimple = ({ className, text, description, skeleton }) => {
  if (skeleton) return <div className='note-banner skeleton'/>
  return (
    <div className={cls(className, 'note-banner')}>
      <p className='text'>
        <Icon type={'attention_outline'} role={'icon'} width={24} height={24} color={'#707ba0'}/>{text}
      </p>
      <p className='description'>{description}</p>
    </div>
  )
}

BannerSimple.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  skeleton: PropTypes.bool,
};

export default BannerSimple;
