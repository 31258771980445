import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import IconWithText from '../../../Components/Text';
import ThemeSwitcher from '../../../Components/ThemeSwitcher';
import NavCreators from '../../../Redux/NavRedux';
import { Divider } from 'antd';
import { useDispatch } from 'react-redux';

const MenuDropdown = (
  {
    theme,
    changeTheme,
    setDisabledTransition,
    logout,
    isAdmin,
    isMobile,
    shopifyStore,
    autoDS,
    openModalShopifyConnect,
    openModalAutoDSConnect,
    disabledConnect,
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storeList = shopifyStore?.results || [];

  const handleOpenConnectModal = () => {
    if (disabledConnect) return;
    openModalShopifyConnect();
  };

  const handleOpenConnectAuto = () => {
    if (disabledConnect) return;
    openModalAutoDSConnect();
  };

  if (isAdmin) {
    return (
      <div className="user">
        <div className="user_logout user_logout_admin" onClick={logout}>
          <IconWithText
            text={t('Log out')}
            icon={() => <Icon type="logout" role="icon"/>}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="user">
        <div className="user_links">
          <div className="user_link-wrap">
            <Link to="/setting/account"
                  className="user_link"
                  onClick={() => dispatch(NavCreators.navSetProp('isShowMenu', false))}
            >
              <IconWithText
                text={t('Manage Account')}
                size={16}
                icon={() => <Icon type="user" role="icon"/>}
              />
            </Link>
            <div className="btn-wrap">
              <ThemeSwitcher theme={theme} changeTheme={changeTheme} setDisabledTransition={setDisabledTransition}/>
              <div className="user_logout" onClick={logout}>
                <Icon type="logout" width={16} height={16} role="icon"/>
              </div>
            </div>
          </div>
        </div>

        {
          storeList?.length
            ? (<>
              <Divider style={{ margin: '16px 0' }}/>
              <ul className="user_store-list">
                {
                  storeList.map(({ id, name, domain }) => (
                    <li key={id} className="user_store-item">
                      <div>
                        <span className="user_store-name">{name}</span>
                        <span className="user_store-link">{domain}</span>
                      </div>
                      <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />
                    </li>
                  ))
                }
              </ul>
            </>)
            : null
        }

        {
          autoDS?.email
            ? (<div className="user_store-item">
                <div>
                  <span className="user_store-name">AutoDS</span>
                  <span className="user_store-link">{autoDS?.email}</span>
                </div>
              <Icon type={'auto_ds'} role={'icon'} />
              </div>)
            : null
        }

        <>
          <Divider style={{margin: '16px 0'}}/>
          <span className={cls('text-link', {disabled: disabledConnect})} onClick={handleOpenConnectModal}>
                  <Icon type="plus" role="icon" width={16} height={16}/>
            {t('Connect Shopify Store')}
                </span>
          {
            autoDS?.email ?
              null
              :
              <span className={cls('text-link', {disabled: disabledConnect})} onClick={handleOpenConnectAuto}>
                <Icon type="plus" role="icon" width={16} height={16}/>
                {t('Connect AutoDS Account')}
              </span>
          }
        </>
      </div>
    );
  }
};

MenuDropdown.propTypes = {
  theme: PropTypes.string.isRequired,
  changeTheme: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MenuDropdown;
