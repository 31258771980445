import React from 'react';

const TakeTour = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15 5C13.8954 5 13 5.89543 13 7V17C13 19.2091 11.2091 21 9 21C6.79086 21 5 19.2091 5 17V6H7V17C7 18.1046 7.89543 19 9 19C10.1046 19 11 18.1046 11 17V7C11 4.79086 12.7909 3 15 3C17.2091 3 19 4.79086 19 7V17H17V7C17 5.89543 16.1046 5 15 5Z"
            fill="#151E3A"/>
      <path
        d="M6.86399 3.48075C6.47812 2.81924 5.52231 2.81924 5.13644 3.48075L3.37747 6.49611C2.98859 7.16277 3.46946 7.99998 4.24125 7.99998H7.75918C8.53097 7.99998 9.01184 7.16277 8.62296 6.49612L6.86399 3.48075Z"
        fill="#151E3A"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.0002 15C16.3434 15 15.0002 16.3431 15.0002 18C15.0002 19.6569 16.3434 21 18.0002 21C19.6571 21 21.0002 19.6569 21.0002 18C21.0002 16.3431 19.6571 15 18.0002 15ZM17.0002 18C17.0002 17.4477 17.4479 17 18.0002 17C18.5525 17 19.0002 17.4477 19.0002 18C19.0002 18.5523 18.5525 19 18.0002 19C17.4479 19 17.0002 18.5523 17.0002 18Z"
            fill="#151E3A"/>
    </svg>
  );
};

export default TakeTour;
