import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import cls from "classname";
import {Modal, Spin, Table} from "antd";
import SalesProductsSkeleton from "./components/SalesProductsSkeleton";
import {getColumns} from "./components/SalesProductsTableColumns";
import Icon from "../../Icon";
import NumbersBreakdownDatabaseBlock from "../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock";
import BannerBlock from "./components/BannerBlock";
import Creators from "./reducer";
import ReactPlayer from "react-player";
import SearchComponent from "../../Components/Search";
import _ from 'lodash';
import ButtonComponent from "../../Components/Button";
import PriceHistory from '../ProductDatabasePage/components/PriceHistory';

const LocalTable = (props) => <Table {...props}/>

const MemoTable = React.memo(LocalTable, (prev, next) =>{
  return _.isEqual(prev?.dataSource, next?.dataSource) && prev?.loading === next?.loading && prev?.expandable?.expandedRowKeys?.[0] === next?.expandable?.expandedRowKeys?.[0] && prev?.chartLoading === next?.chartLoading && prev?.sortOrder === next?.sortOrder
});

const SalesProductsPage = (props) => {
  const {
    skeleton,
    isMobile,
    theme,
    fetching,
    setRecordToDelete,
    setVisible,
    setModal,
    getProducts,
    getProductsNext,
    visibleVideo,
    setVisibleVideo,
    getExpandableChartProductTable,
    spinner,
    isTrial,
    productsNewTab,
    searchFetching,
    resetSearchResults,
    getShopifyStore,
    getAutoStore
  } = props;

  const {t} = useTranslation();
  const tableRef = useRef(null);
  //tracked products
  const products = useSelector(state => state.salesTracker.products) || {results: [], next: null};
  const {results, next, searchResults} = products;
  const [searchValue, setSearchValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);

  //numbers breakdown show/hide
  const [showLocalModal, setShowLocalModal] = useState(false);
  const [localModal, setLocalModal] = useState(false);
  //numbers breakdown row to calc
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const isAvailable = useSelector(state => state.salesTracker.isAvailable);
  const filterDates = useSelector(state => state.salesTracker.filters.report_period);
  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;

  const [expandedRow, setExpandedRow] = useState(null);
  const [newTab, setNewTab] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  const chartLoading = useSelector(store => store.salesTracker.expandableChartLoading);

  const handleSort = useCallback(({order, columnKey}) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, [])

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && !fetching && !!next) {
      getProductsNext(next);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleSearch = e => {
    setSearchValue(e.target.value);
    if (!e.target.value.length) {
      setShowSearch(false)
      resetSearchResults()
    }
  }

  const searchSubmit = () => {
    getProducts({page: 1, page_size: 50, search: searchValue.trim()})
    setSearchValue(prev => prev.trim());
    setShowSearch(true)
  }

  useEffect(() => {
    if (expandedRow) setExpandedRow(null)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [fetching])

  useEffect(() => {
    getProducts({page: 1, page_size: 100});
    getShopifyStore();
    getAutoStore();
    if (firstLoading) {
      setNewTab(false);
      productsNewTab.current = false;
      dispatch(Creators.resetIsAvailable());
      setFirstLoading(false)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next, fetching, spinner]);

  useEffect(() => {
    if (!isAvailable && typeof isAvailable !== 'object') {
      setSelected(null);
    }
    dispatch(Creators.resetIsAvailable());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isAvailable, selected?.id])

  async function handleExpandRow(expanded, record) {
    if (!expanded || (expanded && expandedRow === record.id)) return setExpandedRow([]);
    if (expanded) {
      await getExpandableChartProductTable({
        store_id: record?.store?.id,
        product_id: record?.id,
        date_range: {min: minDate, max: maxDate}
      });
      setExpandedRow(record.id)
    }
  }

  const modalBlocks = {
    breakdown: <Spin size="large" spinning={fetching}><NumbersBreakdownDatabaseBlock product={recordToCalc}/></Spin>,
    priceHistory: <PriceHistory product={recordToPriceHistory} isPD={false} />
  }

//check if there are no products, to display default banner
  if (!results?.length && !fetching)
    return (
      <Spin spinning={spinner}>
        <BannerBlock skeleton={skeleton}
                     isMobile={isMobile}
                     theme={theme}
                     setVisible={setVisibleVideo}
                     searchPlaceholder={'Search Tracked Products...'}
        />
        <Modal
          className="change-modal custom-modal video-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visibleVideo}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
          }
          footer={null}
          closable="true"
          onCancel={() => setVisibleVideo(false)}
          destroyOnClose
        >
          <ReactPlayer controls={true}
                       width={'100%'}
                       height={'100%'}
                       playing={true}
                       url={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
          />
        </Modal>
      </Spin>
    );

  return (
    <div className={'sales-tracker-data-wrapper'}>
      {
        !isMobile ?
          skeleton || (fetching && !newTab) ?
            <div style={{width: '100%'}}>
              <span className="link skeleton"
                    style={{width: '100%', height: 40, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}/>
            </div>
            :
            <SearchComponent handleSearch={handleSearch}
                             onKeyUp={null}
                             value={searchValue}
                             onFocusCallback={null}
                             placeholder={t('Search Tracked Products...')}
                             button={<ButtonComponent onClick={searchSubmit}
                                                      className={'sales-tracker-search'}
                                                      text={t('Search')}
                                                      disabled={fetching || spinner || searchFetching || !searchValue.length}
                             />}
            />
          :
          null
      }
      {skeleton || (fetching && !newTab) ? <SalesProductsSkeleton isMobile={isMobile}/> : null}
      {!searchResults?.length && !searchFetching && searchValue && showSearch ?
        <div className={cls('product-database-table-wrapper', {
          'product-database-table-wrapper-empty': !searchResults?.length,
        })}
             style={{marginTop: 4, paddingTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}
        >
          <div className="empty-state empty-state-product-database">
            <div className="empty-state-product-database-title">
              {t('common.no_products')}
            </div>
            <div className="empty-state-product-database-subtitle">
              {t('kindly_change_search')}
            </div>
            <div className="empty-state_icon">
              <Icon width={96} height={96}
                    role="icon"
                    type="empty_warning"
                    fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
              />
            </div>
          </div>
        </div>
        :
        <div className={cls('products-database-table-wrapper sales-tracker-stores-table-wrapper', {
          'product-database-table-wrapper-hidden': (fetching && !newTab),
        })}>
          <div id={'product-database-table-body'}
               className="product-database-table-body sales-tracker-stores-table-body sales-tracker-products-table-body">
            <MemoTable
              components={{
                // eslint-disable-next-line react/prop-types
                table: ({className, children, ...other}) => (
                  <table
                    {...other}
                    ref={tableRef}
                    className={cls(className, 'other-wrapper')}
                  >
                    {children}
                  </table>
                ),
              }}
              sortOrder={sortOrder}
              className="list-table"
              loading={spinner || searchFetching}
              rowClassName={'product-database-table-row'}
              rowKey={(record) => record.id}
              dataSource={showSearch && searchValue && searchResults.length ?
                searchResults?.length ?
                  [...searchResults]
                  :
                  []
                :
                results?.length ?
                  [...results]
                  :
                  []
              }
              columns={getColumns({
                sortOrder,
                setRecordToDelete,
                setVisible,
                setModal,
                setLocalModal,
                setShowLocalModal,
                setRecordToCalc,
                setRecordToPriceHistory,
                t,
                theme,
                isTrial,
                isMobile
              })}
              pagination={false}
              locale={{emptyText: '-'}}
              scroll={{
                x: 1600, //'max-content',
                y: 'calc(100vh - 96px - 120px)',
              }}
              sticky={{
                offsetHeader: 0,
                offsetScroll: 0,
                getContainer: () => document.querySelector('.product-database-table-body')
              }}
              sortDirections={['descend', 'ascend', null]}
              onChange={(pagination, filters, sorter) => {
                handleSort(sorter);
              }}
              chartLoading={chartLoading}
            />
          </div>
        </div>}
      <Modal
        className="change-modal custom-modal"
        getContainer={() => document.getElementById('global-wrap')}
        open={showLocalModal}
        {...(isMobile ? {transitionName: ''} : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={1040}
        footer={null}
        closable="true"
        onCancel={() => setShowLocalModal(false)}
        destroyOnClose
      >
        {
          modalBlocks[localModal]
        }
      </Modal>
    </div>
  );
};

export default SalesProductsPage;
