import React from 'react';
import Icon from "../Icon";
import './SocialProofCheckout.less'

const SocialProofCheckout = () => {
  return (
    <div className='social-proof-wrapper'>
      <span className="title-block">
        Join 45,000+ Merchants Using Dropship
      </span>
      <div className='social-proof-text'>
        ‘’An excellent resource for researching products based on data’’
      </div>
      <Icon role='icon'
            type='forbes'
      />
    </div>
  );
};

export default SocialProofCheckout;
