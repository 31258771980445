import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Row, Col, Grid } from 'antd';
import PropTypes from 'prop-types';
import cls from 'classname';
import EmptyState from './empty-state';
import { changeColumns } from './columns';
import './styles-details.less';

export default function ProductDetails(
  {
    products,
    loading,
    next,
    isMobile,
    getNextList,
    onSort,
    isShowSideBar,
    searchText,
    theme,
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    onOpenUpgradePlan,
}) {
  const [cols, setCols] = useState(false);
  const tableRef = useRef();
  let navigate = useNavigate();
  const { t } = useTranslation();

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [searchText]);

  const dataSource = products?.length
    ? products?.map((u) => ({ ...u, key: u.product_id }))
    : [];
  return (
    <div
      className={cls('products-details', 'products', {
        'details-close': isShowSideBar,
        'products-details--empty': dataSource.length === 0,
      })}
    >
      {dataSource.length ? (
        <>
          {(isMobile || window.innerWidth >= 1600) ? null : (
            <Row
              className={cls('filters-indicator', {
                active: cols,
              })}
            >
              <Col span={12} className="indicator-left" />
              <Col span={12} className="indicator-right" />
            </Row>
          )}
          <Table
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table
                  {...other}
                  ref={tableRef}
                  className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            className="list-table"
            loading={loading}
            rowClassName={(record)=> record['is_locked'] ? "details-row details-row_locked": "details-row"}
            dataSource={dataSource}
            columns={changeColumns({ colType: cols, isMobile, fullTable: window.innerWidth >= 1600 })({
              theme,
              cols,
              setCols,
              addToCollectionFunc,
              isAdmin,
              isAdminEdit,
              t,
              onOpenUpgradePlan,
            })}
            pagination={false}
            scroll={{ scrollToFirstRowOnChange: true, y: true }}
            sticky={true}
            onChange={(pagination, filters, sorter) => {
              onSort(sorter);
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  if(![...event.target.classList].includes('bookmark') && !record['is_locked']) {
                    navigate(`${isAdminEdit ? '/admin' : ''}/portfolio/product/${record.product_id}`);
                  }
                }, // click row
              };
            }}
          />
        </>
      ) : (
        <EmptyState isAdmin={isAdmin} />
      )}
    </div>
  );
}

ProductDetails.propTypes = {
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  products: PropTypes.array,
  next: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getNextList: PropTypes.func,
  isMobile: PropTypes.bool,
  isShowSideBar: PropTypes.bool,
  theme: PropTypes.string,
  searchText: PropTypes.string,
  onSort: PropTypes.func,
  addToCollectionFunc: PropTypes.func.isRequired,
};
