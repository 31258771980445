import React from 'react';
import {useTranslation} from "react-i18next";
import {Divider} from "antd";
import ButtonComponent from "../../../Components/Button";

const FreePlanErrorModal = (props) => {
  const {setModal, setVisible, reset} = props;
  const { t } = useTranslation();

  return (
    <div className={'load-filter-preset-wrapper delete-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Seven-day waiting period')}
      </div>
      <p className="load-filter-preset-subtitle">
        {t('The Free plan requires a seven-day waiting period before a store/product can be removed from tracking.')}
      </p>

      <Divider className={'delete-preset-divider'} />

      <div className="delete-filter-button-wrapper">
        <ButtonComponent className={'delete-preset-button-cancel close-error-modal'}
                         text={t('Close')}
                         onClick={() => {
                           setModal('');
                           setVisible(false);
                           reset();
                         }}
        />
      </div>
    </div>
  );
};

export default FreePlanErrorModal;
