/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Spin } from 'antd';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import ModalComponent from '../Modal';
import ChangeBillingAddressForm from './ChangeBillingAddressForm';
import openNotification from '../Notification';
import './styles-billing-address.less';

function BillingAddress({
  user,
  resetErrors,
  saveResult,
  errors,
  resetResult,
  changeBillingAddress,
  saveFetching,
  getCountries,
  countries,
  isMobile,
}) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const [visible, setVisible] = useState(false);

  const { first_name, last_name, billing_info } = user;

  useEffect(() => {
    if (errors?.change_billing_address) {
      openNotification({
        type: 'error',
        message: t('Something went wrong, kindly try again'),
        style: { minWidth: '716px' },
      });
      resetErrors();
    }
    if (saveResult?.change_billing_address) {
      openNotification({
        type: 'success',
        message: t(
          'setting_billing.Billing address has been successfully changed!',
        ),
        style: { minWidth: '716px' },
      });
      resetResult();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  function replaceValue(value) {
    const defaultValue = value ? value : '-';
    return hidden ? defaultValue : utils.starsReplace(null);
  }

  const handleClose = () => {
    setVisible(false);
  };
  const handleSubmit = (data) => {
    if (saveFetching) return
    changeBillingAddress(data);
    setTimeout(() => {
      if (!saveFetching) {
        setVisible(false);
      }
    }, 500);
  };

  return (
    <Card className="billing-address-block">
      <div className="billing-address-header">
        <div className="billing-title-text">
          {t('setting_billing.Billing Address')}
        </div>
        <Icon
          type={hidden ? 'eye' : 'eye_invisible'}
          width={24}
          height={24}
          role="button"
          onClick={() => setHidden(!hidden)}
          color="#707BA0"
        />
        <div className="edit-btn" onClick={() => setVisible(true)}>
          {billing_info
            ? t('setting_billing.Edit Address')
            : t('setting_billing.Add Address')}
        </div>
      </div>

      <Row
        gutter={[
          { xs: 21, sm: 21, md: 15 },
          { xs: 16, sm: 16 },
        ]}
      >
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('First Name')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.first_name || first_name)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('Last Name')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.last_name || last_name)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">{t('Country')}</div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.country?.name)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.City')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.city)}
          </div>
        </Col>
        {billing_info?.country?.name === 'United States' ? (
          <Col xs={12} sm={12} md={8}>
            <div className="billing-subtitle-text left-text">
              {t('setting_billing.State')}
            </div>
            <div className="billing-dark-text botom-text">
              {replaceValue(billing_info?.state)}
            </div>
          </Col>
        ) : null}
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('Postal Code')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.postal_code)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={24}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Street and House Number')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(billing_info?.address)}
          </div>
        </Col>
        <ModalComponent
          width={438}
          className="company-detail-modal"
          title={t('setting_billing.Edit Billing Address')}
          isMobile={isMobile}
          handleClose={handleClose}
          open={visible}
          onCancel={handleClose}
          destroyOnClose
          footer={false}
        >
          <Spin size="large" spinning={saveFetching}>
            <ChangeBillingAddressForm
              billingInfo={billing_info}
              firstName={first_name}
              lastName={last_name}
              onCancel={handleClose}
              onSubmit={handleSubmit}
              getCountries={getCountries}
              countries={countries}
              isMobile={isMobile}
            />
          </Spin>
        </ModalComponent>
      </Row>
      <div
        className="edit-btn-mobile blue-btn"
        onClick={() => setVisible(true)}
      >
        {billing_info ? t('setting_billing.Edit') : t('setting_billing.Add')}
      </div>
    </Card>
  );
}

export default React.memo(BillingAddress)
