import React, {useState} from 'react';
import cls from "classname";
import {useSelector} from "react-redux";
import {Dropdown, Tooltip} from "antd";
import Icon from "../../../Icon";
import ArrowSelectDown from "../../../Icon/img/ArrowSelectDown";
import './CascadingDropdown.less';
import {useTranslation} from "react-i18next";

const options = [
  {
    title: 'Reactions',
    key: 'reactions',
    items: ['like', 'love', 'care', 'haha', 'wow', 'sad', 'angry']
  },
  {
    title: 'Most Recent',
    key: 'most_recent'
  },
  {
    title: 'Oldest',
    key: 'oldest'
  },
  {
    title: 'Comments',
    key: 'comments'
  },
  {
    title: 'Shares',
    key: 'shares'
  }, {
    title: 'Views',
    key: 'views'
  }
]

const CascadingDropdown = (
  {
    value,
    setValue,
    isMobile,
    isTrial,
    disabled=false,
  }
) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  const handleVisibleChange = (value) => !isTrial && !disabled && setVisible(value);
  const handleInnerVisibleChange = (value) => setInnerVisible(value);

  const getSelectValue = () => {
    let result = options.filter(el => el?.key === value);
    if (result?.length) return t(result?.[0]?.title);
    else return t(value);
  }

  return (
    <div className={cls("cascading-wrapper", {
      'disabled-transition': isDisabledTransition,
      'disabled': isTrial || disabled
    })}>
      <Dropdown
        overlayClassName={'cascading-dropdown-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottom'}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
          <div className="cascading-dropdown-menu-wrapper">
            <ul className="cascading-dropdown-menu">
              {
                options.map(el =>
                  el?.items ?
                    isMobile ?
                      null
                      :
                      (
                        <div className={cls('cascading-dropdown-menu-item--advanced', {
                          'cascading-dropdown-menu-item--advanced--active': el?.items?.some(item => item === value)
                        })}
                             onClick={() => setInnerVisible(!innerVisible)}
                        >
                          <Dropdown
                            overlayClassName={'cascading-dropdown-wrapper-inner'}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onOpenChange={handleInnerVisibleChange}
                            placement={'bottom'}
                            trigger={['click']}
                            open={innerVisible}
                            dropdownRender={() => (
                              <div className="cascading-dropdown-menu-wrapper-inner">
                                <ul className="cascading-dropdown-menu-inner">
                                  {
                                    el?.items.map(elem => (
                                      <li
                                        className={cls('cascading-dropdown-menu-item cascading-dropdown-menu-item-inner', {
                                          'cascading-dropdown-menu-item--active': elem === value
                                        })}
                                        onClick={() => {
                                          setVisible(false);
                                          setTimeout(() => setValue(elem), 100);
                                        }}
                                      >
                                        <Icon role="icon" type={`ad_spot_${elem}`}/>
                                        <span>{t(elem)}</span>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            )}
                          >
                            <div className={cls('cascading-dropdown-inner', {'ant-dropdown-open-inner': innerVisible})}>
                            <span>
                              <Icon role="icon" type={`ad_spot_${el?.key}`}/>
                              {t(el?.title)}
                            </span>
                              <ArrowSelectDown/>
                            </div>
                          </Dropdown>
                        </div>
                      )
                    :
                    (
                      <li className={cls('cascading-dropdown-menu-item', {
                        'cascading-dropdown-menu-item--active': el?.key === value
                      })}
                          onClick={() => {
                            setVisible(false);
                            setTimeout(() => setValue(el?.key), 300);
                          }}
                      >
                        <Icon role="icon" type={`ad_spot_${el?.key}`}/>
                        <span>{t(el?.title)}</span>
                      </li>
                    ))
              }
            </ul>
          </div>
        )}
      >
        <Tooltip placement="top"
                 title={isTrial ? 'Feature disabled during Trial': null}
                 destroyTooltipOnHide={true}
        >
          <div className={cls('cascading-dropdown', {'ant-dropdown-open': visible})}>
            <span className={cls("cascading-dropdown-value", {
              'cascading-dropdown-value-inner': options.some(el => el?.items?.includes(value))
            })}>
              <Icon role="icon" type={`ad_spot_${value}`}/>
              {getSelectValue()}
            </span>
            <ArrowSelectDown/>
          </div>
        </Tooltip>
      </Dropdown>

    </div>
  );
};

export default CascadingDropdown;
