import React from 'react';
import { Divider } from 'antd';

const DatabaseProductsSkeleton = ({report}) => {

  if (report === 'revenue') return (
    <div className="product-database-table-wrapper">
      <div className="product-database-table-header">
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 68, height: 20 },
              { width: 70, height: 32 },
              { width: 64, height: 20 },
            ].map(el => (<span key={el.width * el.height} className="link skeleton"
                               style={{ width: el.width, height: el.height }}/>))
          }
        </div>
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 34, height: 20 },
              { width: 70, height: 32 },
              { width: 53, height: 20 },
              { width: 32, height: 32 },
              { width: 32, height: 32 },
            ].map((el, index) => (<span key={el.width * el.height * index}
                                        className="link skeleton" style={{ width: el.width, height: el.height }}/>))
          }
        </div>
      </div>

      <div className="product-database-table-subheader">
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 62, height: 24 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 42, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 64, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '15%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 168, height: 24, marginLeft: 2 }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 0' }}/>


      <div className="product-database-table-subheader" style={{marginTop: 20}}>
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 64, height: 20 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 20  }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 76, height: 20  }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 12px' }}/>

      <div style={{ marginTop: 0 }} className="product-database-table-body">
        {[
          { id: 1, first: 64 , second: 25, third: 75, fourth: 39, },
          { id: 2, first: 87, second: 10, third: 40, fourth: 19, },
          { id: 3, first: 87, second: 13, third: 40, fourth: 25, },
          { id: 4, first: 87, second: 13, third: 46, fourth: 25, },
          { id: 5, first: 87, second: 13, third: 40, fourth: 17, },
          { id: 6, first: 87, second: 10, third: 39, fourth: 18, },
          { id: 7, first: 89, second: 10, third: 46, fourth: 16, },
          { id: 8, first: 89, second: 10, third: 40, fourth: 18, },
          { id: 9, first: 90, second: 10, third: 40, fourth: 8, },
          { id: 10, first: 90, second: 10, third: 40, fourth: 20, },
          { id: 11, first: 90, second: 10, third: 40, fourth: 17, },
        ].map(row => (
          <div key={row.id} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>

              <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
                <span className="link skeleton" style={{ width: row.first, height: 20 }}/>
              </div>

              <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'} />

              <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'}>
                <span className="link skeleton" style={{ width: row.second, height: 20 }}/>
              </div>

              <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'} style={{width: '13.5%'}}>
                <span className="link skeleton" style={{ width: row.third, height: 20 }}/>
              </div>

              <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                <span className="link skeleton" style={{ width: row.fourth, height: 20 }}/>
              </div>
            </div>
            <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
          </div>
        ))}
      </div>
    </div>
  );

  if (report === 'unique_products_sold') return (
    <div className="product-database-table-wrapper">
      <div className="product-database-table-header">
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 68, height: 20 },
              { width: 70, height: 32 },
              { width: 64, height: 20 },
            ].map(el => (<span key={el.width * el.height} className="link skeleton"
                               style={{ width: el.width, height: el.height }}/>))
          }
        </div>
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 34, height: 20 },
              { width: 70, height: 32 },
              { width: 53, height: 20 },
              { width: 32, height: 32 },
              { width: 32, height: 32 },
            ].map((el, index) => (<span key={el.width * el.height * index}
                                        className="link skeleton" style={{ width: el.width, height: el.height }}/>))
          }
        </div>
      </div>

      <div className="product-database-table-subheader">
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 62, height: 24 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 42, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 64, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '15%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 168, height: 24, marginLeft: 2 }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 0' }}/>


      <div className="product-database-table-subheader" style={{marginTop: 20}}>
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 64, height: 20 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 20  }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 76, height: 20  }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 12px' }}/>

      <div style={{ marginTop: 0 }} className="product-database-table-body">
        {[
          { id: 1, first: 64 , second: 0, third: 0, fourth: 39, },
          { id: 2, first: 87, second: 0, third: 0, fourth: 19, },
          { id: 3, first: 87, second: 13, third: 40, fourth: 25, },
          { id: 4, first: 87, second: 13, third: 46, fourth: 25, },
          { id: 5, first: 87, second: 13, third: 40, fourth: 17, },
          { id: 6, first: 87, second: 10, third: 39, fourth: 18, },
          { id: 7, first: 89, second: 10, third: 46, fourth: 16, },
          { id: 8, first: 87, second: 10, third: 40, fourth: 18, },
          { id: 9, first: 90, second: 10, third: 40, fourth: 8, },
          { id: 10, first: 90, second: 10, third: 40, fourth: 20, },
          { id: 11, first: 90, second: 10, third: 40, fourth: 17, },
        ].map(row => (
          <div key={row.id} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>

              <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
                <span className="link skeleton" style={{ width: row.first, height: 20 }}/>
              </div>

              <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'} />

              <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'}>
                <span className="link skeleton" style={{ width: row.second, height: 20 }}/>
              </div>

              <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'} style={{width: '13.5%'}}>
                <span className="link skeleton" style={{ width: row.third, height: 20 }}/>
              </div>

              <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                <span className="link skeleton" style={{ width: row.fourth, height: 20 }}/>
              </div>
            </div>
            <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
          </div>
        ))}
      </div>
    </div>
  );

  if (report === 'product') return (
    <div className="product-database-table-wrapper">
      <div className="product-database-table-header">
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 68, height: 20 },
              { width: 70, height: 32 },
              { width: 64, height: 20 },
            ].map(el => (<span key={el.width * el.height} className="link skeleton"
                               style={{ width: el.width, height: el.height }}/>))
          }
        </div>
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 34, height: 20 },
              { width: 70, height: 32 },
              { width: 53, height: 20 },
              { width: 32, height: 32 },
              { width: 32, height: 32 },
            ].map((el, index) => (<span key={el.width * el.height * index}
                                        className="link skeleton" style={{ width: el.width, height: el.height }}/>))
          }
        </div>
      </div>

      <div className="product-database-table-subheader">
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 36, height: 24 }}/>
        </div>
        <div style={{ width: '27%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 42, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '15%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 66, height: 24, marginLeft: 2 }}/>
        </div>
      </div>

      <Divider type={'horizontal'} style={{ margin: '16px auto 12px' }}/>

      <div style={{ marginTop: 0 }} className="product-database-table-body">
        {[
          { id: 1, first: 64 , second: 25, third: 75 },
          { id: 2, first: 87, second: 10, third: 40 },
          { id: 3, first: 87, second: 13, third: 40 },
          { id: 4, first: 87, second: 13, third: 46 },
          { id: 5, first: 87, second: 13, third: 40 },
          { id: 6, first: 87, second: 10, third: 39 },
          { id: 7, first: 89, second: 10, third: 46 },
          { id: 8, first: 87, second: 10, third: 40 },
          { id: 9, first: 90, second: 10, third: 40 },
          { id: 10, first: 90, second: 10, third: 40 },
          { id: 11, first: 90, second: 10, third: 40 },
        ].map(row => (
          <div key={row.id} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>

              <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
                <span className="link skeleton" style={{ width: row.first, height: 20 }}/>
              </div>

              <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'} />

              <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'} style={{width: '28%'}}>
                <span className="link skeleton" style={{ width: row.second, height: 20 }}/>
              </div>

              <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                <span className="link skeleton" style={{ width: row.third, height: 20 }}/>
              </div>
            </div>
            <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="product-database-table-wrapper">
      <div className="product-database-table-header">
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 68, height: 20 },
              { width: 70, height: 32 },
              { width: 64, height: 20 },
            ].map(el => (<span key={el.width * el.height} className="link skeleton"
                               style={{ width: el.width, height: el.height }}/>))
          }
        </div>
        <div className={'product-database-table-header-skeleton'}>
          {
            [
              { width: 34, height: 20 },
              { width: 70, height: 32 },
              { width: 53, height: 20 },
              { width: 32, height: 32 },
              { width: 32, height: 32 },
            ].map((el, index) => (<span key={el.width * el.height * index}
                                        className="link skeleton" style={{ width: el.width, height: el.height }}/>))
          }
        </div>
      </div>

      <div className="product-database-table-subheader">
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 62, height: 24 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 42, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 64, height: 24, marginLeft: 2 }}/>
        </div>
        <div style={{ width: '15%' }}>
          <span className="link skeleton" style={{ width: 24, height: 24  }}/>
          <span className="link skeleton" style={{ width: 168, height: 24, marginLeft: 2 }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 0' }}/>


      <div className="product-database-table-subheader" style={{marginTop: 20}}>
        <div style={{ width: '45%', paddingLeft: 24 }}>
          <span className="link skeleton" style={{ width: 64, height: 20 }}/>
        </div>
        <div style={{ width: '14%' }}>
          <span className="link skeleton" style={{ width: 24, height: 20  }}/>
        </div>
        <div style={{ width: '13%' }}>
          <span className="link skeleton" style={{ width: 76, height: 20  }}/>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '16px auto 12px' }}/>

      <div style={{ marginTop: 0 }} className="product-database-table-body">
        {[
          { id: 1, last: 85, third: { first: 35 }, fourth: 68 },
          { id: 2, last: 88, third: { first: 25 }, fourth: 79 },
          { id: 3, last: 83, third: { first: 27 }, fourth: 70 },
          { id: 4, last: 88, third: { first: 27 }, fourth: 70 },
          { id: 5, last: 87, third: { first: 26 }, fourth: 76 },
          { id: 6, last: 89, third: { first: 27 }, fourth: 76 },
          { id: 7, last: 89, third: { first: 27 }, fourth: 76 },
        ].map(row => (
          <div key={row.id} className={'product-database-table-skeleton-row'}>
            <div className={'product-database-table-skeleton-cell-wrapper'}>
              <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
                <div style={{ position: 'relative' }}>
                  <span className="link skeleton" style={{ width: 64, height: 64 }}/>
                  <span className="link skeleton"
                        style={{ position: 'absolute', width: 40, height: 20, left: 32, top: -4 }}
                  />
                </div>

                <div style={{ marginLeft: 16 }}>
                  <span className="link skeleton" style={{ width: 246, height: 16, marginTop: 10, marginBottom: 8 }}/>
                  <span className="link skeleton" style={{ width: 56, height: 16, display: "inline-block" }}/>
                  <span className="link skeleton" style={{ width: 58, height: 16, marginLeft: 20, display: "inline-block" }}/>
                </div>
              </div>
              <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'}>
                <span className="link skeleton" style={{ width: 24, height: 24 }}/>
                <span className="link skeleton" style={{ width: 24, height: 24, marginTop: 2 }}/>
                <span className="link skeleton" style={{ width: 24, height: 24, marginTop: 2 }}/>
              </div>
              <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'}>
                <span className="link skeleton" style={{ width: row.third.first, height: 20 }}/>
                {row.third.second && <span className="link skeleton"
                                           style={{
                                             display: 'block',
                                             width: row.third.second,
                                             height: 20,
                                             marginTop: 10,
                                           }}
                />}
              </div>
              <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'} style={{width: '13.5%'}}>
                <span className="link skeleton" style={{ width: row.fourth, height: 16 }}/>
              </div>
              <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                <span className="link skeleton" style={{ width: row.last, height: 16 }}/>
                <div style={{ display: 'flex', justifyContent:"center", alignItems: "center" }}>
                  <span className="link skeleton" style={{ width: 24, height: 24 }}/>
                </div>
              </div>
            </div>
            <Divider type={'horizontal'} style={{ margin: '12px auto' }}/>
          </div>
        ))}
        <div className={'product-database-table-skeleton-row'} style={{flexDirection: 'row', alignItems: 'flex-end'}}>
          <div style={{ position: 'relative', width: '35%' }}>
            <span className="link skeleton" style={{width: 64, height: 9, borderRadius: '6px 6px 0 0'}}/>
            <span className="link skeleton"
                  style={{ position: 'absolute', width: 40, height: 13, left: 32, top: -4, borderRadius: '6px 6px 0 0' }}
            />
          </div>

          <div className={'product-database-table-skeleton-cell-2'}>
            <span className="link skeleton" style={{ width: 24, height: 13, borderRadius: '6px 6px 0 0' }}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatabaseProductsSkeleton;
