import React from 'react';
import './OrderNow.less';

const content = [
  {
    title: 'Stunning Branded Website',
    text: 'Designed to sell products online without having to buy any inventory...'
  },
  {
    title: '30 Profitable Products Picked By Our Experts',
    text: 'added to your store (Including Product Descriptions)...'
  },
  {
    title: 'Exclusive Access To Our Trusted Shipping Partners',
    text: 'Who store the products you sell and ship them to your customers cheap and fast…...'
  },
  {
    title: 'Hands Off “Backend Order Automation”',
    text: 'So you can deliver products to your customers without lifting a finger...'
  },
  {
    title: 'Award Winning 24/7 Customer Support',
    text: 'So you can have a team of 7 figure eCommerce experts on your side to help you make your first sales online...'
  },
]

const OrderNow = () => {
  return (
    <div className={'order-now-wrapper'}>
      <h3>
        Order Now And You'll Get
      </h3>
      <div className="order-now-content">
        {
          content.map(el => (
            <div className="order-now-content-item"
                 key={el?.title}
            >
              <div className={'order-now-content-item-border'} />
              <div className={'order-now-content-item-text'}>
                <h4>
                  {el?.title}
                </h4>
                <p>
                  {
                    el?.text
                  }
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default OrderNow;
