import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { ImageCellComponent as Image } from '../../../../Components/Image';
import EmptyImage from './empty-image';

export const ImagesComponent = ({ products, handleResend, id, name }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cls('collection-item_images', {
        collection__one: products.length === 1,
        collection__multiple: products.length > 1,
        collection__empty: products.length === 0,
      })}
      onClick={() => {
        handleResend({ collectionID: id, collectionName: name });
      }}
    >
      {/* A FEW IMAGES */}
      {products.length > 1 && (
        <>
          <div className="collection-item_image">
            {products[0] && <Image src={products[0].image} />}
          </div>
          <div className="collection-item_image">
            {products[1] && <Image src={products[1].image} />}
          </div>
          <div className="collection-item_image">
            {products[2] && <Image src={products[2].image} />}
          </div>
          <div className="collection-item_image">
            {products[3] && <Image src={products[3].image} />}
          </div>
        </>
      )}

      {/* NO IMAGE */}
      {products.length === 0 && (
        <EmptyImage
          className="empty-image"
          text={t('common.no_product_image')}
        />
      )}

      {/* ONE IMAGE */}
      {products.length === 1 && <Image src={products[0].image} />}
    </div>
  );
};

ImagesComponent.propTypes = {
  products: PropTypes.array,
  handleResend: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export default ImagesComponent;
