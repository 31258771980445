import React from 'react';
import { Statistic, Row, Col } from 'antd';
import cls from 'classname';
import dayjs from 'dayjs';
import {categoryClassName} from '../../../Utils/utils';
import Images from '../../../Images';
import TimerCountdownAnimation from '../../../Components/TimerCountdownAnimation';
import ButtonComponent from "../../../Components/Button";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

export default function DropCard({
  name,
  title,
  status,
  category_timer,
  drop_id,
  id,
  onChange,
  setTimer,
}) {
  const hour = dayjs.utc(category_timer?.end).format('HH');
  const day = dayjs.utc(category_timer?.end).format('DD');
  const month = dayjs.utc(category_timer?.end).format('MM');
  const year = dayjs.utc(category_timer?.end).format('YYYY');

  const handleSet = () => {
    onChange({ name, title, status, category_timer, drop_id, id });
    setTimer({ hour, day, month, year });
  };
  return (
    <div
      className={cls('drop-card', categoryClassName[name], {
        empty: !status,
      })}
    >
      <div className="drop-card-timer">
        <div className="drop-card-title">{title || ''}</div>
        <div className="timer-block-next">
          <div>
            <div>Next Drop</div>
            <div>
              {category_timer?.end
                ? dayjs.utc(category_timer?.end).format('HH:mm DD.MM.YYYY')
                : '-'}
            </div>
          </div>
          <div>
            <div>Drop ID</div>
            <div>{drop_id ? drop_id : '-'}</div>
          </div>
        </div>
        <div className="timer-block">
          {!status ? (
            <Row gutter={8} className="drop-timer-wrapper disabled">
              <Col span={6} className="drop-timer-box">
                <Statistic title="Days" value="-" />
              </Col>

              <Col span={6} className="drop-timer-box">
                <Statistic title="Hours" value="-" />
              </Col>

              <Col span={6} className="drop-timer-box">
                <Statistic title="Min" value="-" />
              </Col>

              <Col span={6} className="drop-timer-box">
                <Statistic title="Sec" value="-" />
              </Col>
            </Row>
          ) : (
            <TimerCountdownAnimation
              nextDrop={category_timer?.end}
              categoryName={name}
            />
          )}
        </div>
      </div>
      <div className="drop-card-category">
        <div className="drop-card-image">
          <img src={Images[`${name}Full${status ? '' : 'Disabled'}`]} alt="" />
        </div>
        <ButtonComponent
          className="drop-card-button"
          onClick={() => (status ? handleSet() : null)}
          disabled={!status}
        >
          <span>Change</span>
        </ButtonComponent>
      </div>
    </div>
  );
}
