import React from 'react';
import {useNavigate} from "react-router-dom";
import {formatDuration} from "../../../Utils/utils";
import ButtonComponent from "../../../Components/Button";
import Icon from "../../../Icon";
import cls from "classname";
import './UniversityCourseChapterCard.less';

const UniversityCourseChapterCard = ({info, isChapter = false, onClick, isMobile}) => {

  const {title, lessons_count, thumbnail, completed, duration, is_blocked} = info;

  const navigate = useNavigate();

  const redirectHandler = () => navigate('/setting/plan');

  return (
    <div className={cls('university-course-chapter-card-wrapper', {
      'blocked': is_blocked
    })}
         onClick={() => is_blocked ? redirectHandler() : onClick()}
    >

      <div className="university-course-chapter-card-image"
           style={{backgroundImage: `url("${thumbnail}")`}}
      />
      <div className="university-course-chapter-card-content">
        <div className="university-course-chapter-card-info">
          {
            is_blocked && <p className='blocked-info'>
              Chapter is locked on trial, only paid members can access it.
            </p>
          }
          <h4 className="university-course-chapter-card-title">
            {title}
            {is_blocked && <Icon type={'university_lock'} role={'icon'} />}
            {completed &&
              <span>- Completed<Icon role={'icon'} type={'university_watched'}/></span>}
          </h4>
          <p className="university-course-chapter-card-desc">
            <Icon type={ isChapter ? 'clock' : 'watch_tutorial'}
                  role={'icon'}
                  color={'#707ba0'}
            />
            <span>
              {isChapter ? formatDuration(duration) : `${lessons_count} ${lessons_count === 1 ? 'lesson' : 'lessons'}`}
            </span>
          </p>

        </div>
        {
          is_blocked ?
            <ButtonComponent onClick={e => console.log(e)}
                             className={'university-course-chapter-card-button blocked-button'}
                             text={'Skip Trial To Unlock'}
            />
            :
            <ButtonComponent onClick={e => console.log(e)}
                             className={'university-course-chapter-card-button'}
            >
              {isMobile ? null : <span>Watch Now</span>}
              <Icon type="arrow_forward_university" role="icon" width={11} height={11}/>
            </ButtonComponent>
        }
      </div>
    </div>
  );
};

UniversityCourseChapterCard.defaultProps = {
  info: {
    title: 1,
    lessons_count: 1,
    thumbnail: null,
    completed: true,
  }
}

export default UniversityCourseChapterCard;
