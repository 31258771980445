import React from 'react';
import { Row, Col, InputNumber } from 'antd';
import Icon from '../../../../Icon';
import Images from '../../../../Images';
import utils from '../../../../Utils/utils';
import InputComponent from "../../../../Components/Input";

export default function CompetitorsList({
  competitors,
  onChangeInput,
  openDeleteModal,
  saveInfo,
}) {
  return (
    <div className="competitors-list">
      <Row
        className="competitor-wrapper"
        gutter={[0, 16]}
        justify="space-between"
      >
        {competitors?.map((competitor) => (
          <React.Fragment key={competitor?.id}>
            <Col span={12} className="competitor-wrapper_link">
              <img
                alt=""
                src={competitor?.favicon || Images.defaultImg}
                onError={utils.addDefaultSrc}
              />
              <a href={competitor?.link || '#'} target="_blank" rel="noopener noreferrer">
                {competitor?.domain}
              </a>
            </Col>
            <Col span={12} className="competitor-wrapper_main">
              <InputComponent
                placeholder="Platform"
                value={competitor?.platform}
                className="competitor-input"
                onChange={(e) => {
                  onChangeInput({ ...competitor, platform: e?.target?.value });
                }}
              />
              <InputComponent
                value={competitor?.currency}
                className="competitor-input-prefix-currency"
                onChange={(e) => {
                  onChangeInput({
                    ...competitor,
                    currency: e.target.value.toUpperCase(),
                  });
                }}
              />
              <InputNumber
                placeholder="Price"
                value={competitor?.price}
                className="competitor-input-prefix"
                min={0}
                onChange={(price) => {
                  onChangeInput({ ...competitor, price });
                }}
              />
              <Icon
                type="delete_basket"
                role="button"
                width={24}
                height={24}
                onClick={() => {
                  openDeleteModal();
                  saveInfo({ id: competitor?.id });
                }}
              />
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
}
