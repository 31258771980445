import React, { useEffect, useRef, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import cls from 'classname';
import { Badge } from 'antd';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import ProductDetailsCard from './ProductDetailsCard';
import Icon from '../../../Icon';
import { langMapISO } from '../../../Utils/utils';
import ButtonComponent from "../../../Components/Button";
import './VideosBlock.less';

export const VideosBlock = ({ fetching, isMobile, videos, lang, onOpenUpgradePlan }) => {

  /* TikTok, Facebook */
  const [selected, setSelected] = useState('Facebook');

  const [facebookInstances, setFacebookInstances] = useState([]);
  const [tiktokData, setTiktokData] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(false);
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  const isEmptyState = !videos?.[selected.toLowerCase()]?.length;
  const firstTiktokId = videos?.['tiktok'].length ? videos?.['tiktok'][0].url : null;
  const firstFacebookId = videos?.['facebook'].length ? videos?.['facebook'][0].url : null;

  const [videosList, setVideosList] = useState(null);
  const skeletonList = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (firstTiktokId) {
      if(Object.keys(tiktokData)?.length) setTiktokData({});
      try {
        videos['tiktok'].forEach(el => {
          createEmbedMarkup(el);
        });
      } catch (e) {
        console.log('some err', e)
      }
    }
    if (firstTiktokId || firstFacebookId) {
      setVideosList([...videos['facebook'], ...videos['tiktok']])
    }
    if (firstFacebookId) {
      initFbVideo();
    }else{
      if(selected === 'Facebook') handleChangeSelected('TikTok')
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [firstTiktokId, firstFacebookId]);

  function createEmbedMarkup(dataLink) {
    if (!loading) setLoading(true);

    if(!dataLink?.is_locked){
      try {
        fetch(`https://www.tiktok.com/oembed?url=${dataLink?.url}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setTiktokData((tiktokData) => ({
              ...tiktokData,
              [dataLink.id]: data.html,
            }));
          });
      } catch (e) {
        console.log('tiktok error', e)
      }
    }
      setLoading(false);
  }

  const handleChangeSelected = (platform) => {
    if (platform === selected) return;
    setSelected(platform);
    if(platform === 'TikTok' && facebookInstances?.length){
      facebookInstances.forEach(video => video.pause())
    }
    if (disabledNext) setDisabledNext(false);
    if (scrollRef && scrollRef.current) {
      const { position } = scrollRef.current.osInstance().scroll();
      if(position.x){
        if (!disabledPrev) setDisabledPrev(true);
        scrollRef.current
          .osInstance()
          .scroll({ x: 0 });
      }
    }
  };

  function handleNavigateVideo(step, isDisabled) {
    if (isDisabled) return;
    if (scrollRef) {
      const { max, position } = scrollRef.current.osInstance().scroll();

      if (step === 'next') {
        if (max.x > 0 && max.x < 340) {
          if (disabledPrev) setDisabledPrev(false);
          if (!disabledNext) setDisabledNext(true);
        } else if (max.x - 340 > position.x) {
          if (disabledPrev) setDisabledPrev(false);
          if (disabledNext) setDisabledNext(false);
        } else {
          if (!disabledNext) setDisabledNext(true);
          if (disabledPrev) setDisabledPrev(false);
        }
        scrollRef.current
          .osInstance()
          .scroll({ x: position.x + 340 });
      } else {
        if (position.x > 340) {
          if (disabledNext) setDisabledNext(false);
        } else {
          if (!disabledPrev) setDisabledPrev(true);
          if (disabledNext) setDisabledNext(false);
        }
        scrollRef.current
          .osInstance()
          .scroll({ x: position.x - 340 });
      }
    }
  }

  const selectedPlatformBlock = (
    <div className="suppliers-card-badges-block"
         style={{marginLeft: 'auto'}}
    >
      {
        firstTiktokId &&
        <Badge
          className={cls('custom-badge', { active: (selected === 'TikTok' && !fetching), skeleton: fetching })}
          status={(selected === 'TikTok' && !fetching) ? 'processing' : 'default'}
          text={'TikTok'}
          onClick={() => handleChangeSelected('TikTok')}
        />
      }

      {
        firstFacebookId &&
        <Badge
          className={cls('custom-badge', { active: (selected === 'Facebook' && !fetching), skeleton: fetching })}
          status={(selected === 'Facebook' && !fetching) ? 'processing' : 'default'}
          text={'Facebook'}
          onClick={() => handleChangeSelected('Facebook')}
        />
      }
    </div>
  )

  const renderHeader = () => {
    let result;

    if (isEmptyState) {
      result = <>
        <span className="title">
          {t('No Videos Found')}
        </span>

        {selectedPlatformBlock}

      </>;
    } else {
      result = <>
        <span className="title">
          {t('Content')}
        </span>

        {selectedPlatformBlock}

        {
          isMobile || videos[selected.toLowerCase()].length < 4
            ? null
            : (
              <div className={'btn-wrapper'}
                   style={{marginLeft: 24}}
              >
                <span onClick={() => handleNavigateVideo('previous', disabledPrev)}>
                  <Icon role={'icon'}
                        type={'arrow_slider_prev'}
                        className={cls('nav-btn prev', {
                          disabled: disabledPrev,
                        })}
                  />
                </span>
                <span onClick={() => handleNavigateVideo('next', disabledNext)}>
                  <Icon role={'icon'}
                        type={'arrow_slider_next'}
                        className={cls('nav-btn', {
                          disabled: disabledNext,
                        })}
                  />
                </span>
              </div>)
        }
      </>;
    }

    return result;
  };

  const renderHeaderSkeleton = (
    <>
      <span className="title skeleton" style={{ width: 80 }}/>
      <span className="link skeleton" style={{ width: 62 }}/>
    </>
  );

  function initFbVideo() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : process.env.REACT_APP_FACEBOOK_APP_ID,
        xfbml      : true,
        status      : true,
        version    : 'v3.2'
      });

      // Get Embedded Video Player API Instance
      window.FB.Event.subscribe('xfbml.ready', function(msg) {
        if (msg.type === 'video') {
          setFacebookInstances(facebookInstances => [...facebookInstances, msg.instance])
        }
      });
    };
  }

  function addFBScript() {
    if (videos?.['facebook']?.length) {
      return (
        <Helmet>
          <script async defer src={`https://connect.facebook.net/${langMapISO[lang]}/sdk.js`}/>
        </Helmet>
      );
    } else {
      return null;
    }
  }

  const lockedVideo = (
      <div className={cls('block-locked block-locked_video', {'tiktok': selected === 'TikTok'})}>
        <div className='block-locked-info-wrap'>
          <Icon role="icon" type="locked_block" />
          <div className='block-locked-info-wrap block-locked-info-wrap_text'>
            <span className='block-locked-title'>{t('Locked Content')}</span>
            <span className='block-locked-text'>{t('Upgrade your plan to unlock')}</span>
          </div>
        </div>

        <ButtonComponent type="primary"
                className="btn-primary"
                onClick={() => onOpenUpgradePlan(true)}
        >
          {t('Upgrade Plan')}
        </ButtonComponent>
      </div>
  )

  if (fetching || loading) {
    return (
      <ProductDetailsCard
        header={loading ? renderHeader() : renderHeaderSkeleton}
        withLine={!isMobile}
      >
        <div className="videos-card-wrap" style={{ overflow: 'hidden' }}>
          {
            skeletonList.map((el, index) => (
              <div key={`skeleton_${index}`} className={cls('videos-card skeleton', selected)}/>
            ))
          }
        </div>
      </ProductDetailsCard>
    );
  } else {
    return (
      <ProductDetailsCard
        header={renderHeader()}
        withLine={!isMobile}
        bodyClassName={'with-scroll-body'}
      >
        <OverlayScrollbar visibility="hidden" ref={scrollRef}>
          <div className="videos-card-wrap">
            {addFBScript()}
            {
              !loading && videosList?.map( video => (
                <div key={`${video.platform}_${video?.id}`}
                     className={cls('videos-card', {
                       disabled: video.platform !== selected.toLowerCase() || isEmptyState
                     })}
                >
                  {
                     video.platform === 'tiktok'
                      ? (selected === 'TikTok' && !video?.is_locked ? <InnerHTML html={tiktokData?.[video?.id]}/> : lockedVideo)
                      : (
                        video?.is_locked
                          ? lockedVideo
                          : <div className='fb-video'
                                 data-href={video.url}
                                 data-allowfullscreen="true"
                                 data-width="290"
                                 data-show-text="true"
                          />)
                  }
                </div>
              ))
            }

            {
              isEmptyState
                ? (
                  <div className={'videos-card empty'}>
                    <Icon role={'icon'} type={'competitors_empty'}/>
                  </div>)
                : null
            }
          </div>
        </OverlayScrollbar>
      </ProductDetailsCard>
    );
  }
};

export default VideosBlock;
