import React from 'react';

// COMPONENTS
import Skeleton from '../../../Components/Skeleton';

// STYLES
import './styles.less';

export const PortfolioSkeletons = () => {

  const dropsCount = new Array(4).fill(0);
  //const tabsCount = new Array(7).fill(0);

  return (
    <div className="skeleton skeleton--portfolio-page">
      <div className="skeleton_block skeleton_block--drop-info">
        <div className="skeleton_block skeleton_block--drop-info-data">
          <Skeleton width={69} height={24} borderRadius={6} />
          <Skeleton width={132} height={32} borderRadius={6} />
          <Skeleton width={145} height={32} borderRadius={6} />
        </div>
        <div className="skeleton_row">
          <div className="divider" />
          <div className="skeleton_block skeleton_block--timer">
            <Skeleton width={24} height={12} borderRadius={2} />
            <Skeleton width={48} height={24} borderRadius={6} />
            <Skeleton width={64} height={8} borderRadius={6} />
          </div>
          <div className="skeleton_block skeleton_block--timer">
            <Skeleton width={24} height={12} borderRadius={2} />
            <Skeleton width={48} height={24} borderRadius={6} />
            <Skeleton width={64} height={8} borderRadius={6} />
          </div>
          <div className="skeleton_block skeleton_block--timer">
            <Skeleton width={24} height={12} borderRadius={2} />
            <Skeleton width={48} height={24} borderRadius={6} />
            <Skeleton width={64} height={8} borderRadius={6} />
          </div>
          <div className="skeleton_block skeleton_block--timer">
            <Skeleton width={24} height={12} borderRadius={2} />
            <Skeleton width={48} height={24} borderRadius={6} />
            <Skeleton width={64} height={8} borderRadius={6} />
          </div>
          <div className="divider" />
        </div>
        <div className="skeleton_block skeleton_block--drop-info-text">
          <div className="skeleton_row">
            <Skeleton width={76} height={24} borderRadius={6} />
            <Skeleton width={54} height={16} borderRadius={4} />
          </div>
          <div className="skeleton_row">
            <Skeleton width={76} height={20} borderRadius={6} />
            <Skeleton width={16} height={20} borderRadius={4} />
          </div>
          <div className="skeleton_row">
            <Skeleton width={76} height={20} borderRadius={6} />
            <Skeleton width={16} height={20} borderRadius={4} />
          </div>
        </div>
      </div>
      <div className="skeleton_block skeleton_block--drops">
        {dropsCount.map((_, i) => (
          <React.Fragment key={i}>
            <div className="skeleton_block skeleton_block--drop">
              <div className="skeleton_row">
                <div className="skeleton_left">
                  <Skeleton width={62} height={24} borderRadius={6} />
                  <Skeleton
                    width={4}
                    height={4}
                    borderRadius="50%"
                    className="skeleton-item--dot"
                  />
                  <Skeleton width={38} height={24} borderRadius={6} />
                </div>
                <div className="skeleton_right">
                  <Skeleton width={54} height={24} borderRadius={6} />
                </div>
              </div>
              <div className="skeleton_row">
                <Skeleton width={'100%'} height={147} />
                <Skeleton
                  width={'100%'}
                  height={147}
                  className="skeleton-item--type"
                />
                <Skeleton
                  width={'100%'}
                  height={147}
                  className="skeleton-item--type"
                />
                <Skeleton width={'100%'} height={147} />
              </div>
            </div>
            <div className="skeleton_block skeleton_block--drop">
              <div className="skeleton_row">
                <div className="skeleton_left">
                  <Skeleton width={62} height={24} borderRadius={6} />
                  <Skeleton
                    width={4}
                    height={4}
                    borderRadius="50%"
                    className="skeleton-item--dot"
                  />
                  <Skeleton width={38} height={24} borderRadius={6} />
                </div>
                <div className="skeleton_right">
                  <Skeleton width={54} height={24} borderRadius={6} />
                </div>
              </div>
              <div className="skeleton_row">
                <Skeleton width={'100%'} height={147} />
                <Skeleton
                  width={'100%'}
                  height={147}
                  className="skeleton-item--type"
                />
                <Skeleton
                  width={'100%'}
                  height={147}
                  className="skeleton-item--type"
                />
                <Skeleton width={'100%'} height={147} />
                <Skeleton width={'100%'} height={147} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PortfolioSkeletons;
