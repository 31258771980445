import React from 'react';
import { Row, Col, Card, Divider } from 'antd';
import './styles.less';

export default function AccountSkeleton() {
  return (
    <div className="account-subpage skeleton-page">
      <Row className="account-main">
        <Card className="account-top">
          <div className="account-top_header">
            <div className="top-block-header">
              <div className="user-avatar-wrapper">
                <span className="upload-avatar">
                  <div className="ant-upload"/>
                  <span className="user_avatar"/>
                  <div className="edit-block"/>
                </span>
              </div>
              <div className="user-detailed">
                <div className="user-detailed_fullname"/>
                <div className="user-detailed_member"/>
              </div>
            </div>
          </div>
          <Divider />
          <Row
            gutter={[{ xs: 0, md: 48 }, { xs: 0 }]}
            className="personal-block"
          >
            <Col xs={24} md={12} className="personal-block_info">
              <div className="account-title"/>
              <Row
                gutter={[0, 24]}
                className="personal-information personal-full-name"
              >
                <Col
                  span={24}
                  style={{ height: '80px' }}
                  className="personal-information-block"
                >
                  <div className="personal-subtitle"/>
                  <span />
                </Col>
                <Col
                  span={24}
                  style={{ height: '80px' }}
                  className="personal-information-block"
                >
                  <div className="personal-subtitle"/>
                  <span />
                </Col>
              </Row>
              <div className="personal-subtitle"/>
            </Col>
            <Col xs={24} md={12} className="personal-block_settings">
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
                className="personal-block_header"
              >
                <div className="account-title"/>
                <span className="account-custom-select select-lang" />
              </div>
              <Row gutter={[0, 24]} className="personal-block_mail">
                <Col span={24} className="email-row">
                  <div className="personal-subtitle"/>
                  <div className="personal-email">
                    <span className="personal-email-text"/>
                    <span className="personal-email-btn"/>
                  </div>
                </Col>
                <Col span={24} className="password-row">
                  <div className="personal-subtitle"/>
                  <div className="personal-email">
                    <span className="personal-email-text"/>
                    <span className="personal-email-btn"/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={[{ sm: 0, md: 16 }, { sm: 0 }]}>
        <Col xs={24} md={12}>
          <Card className="account-linked">
            <div className="account-title"/>
            <Row
              gutter={[
                { xs: 0, md: 16 },
                { xs: 16, sm: 16, md: 0 },
              ]}
            >
              <Col xs={24} md={8}>
                <div className="linked-block"/>
              </Col>
              <Col xs={24} md={8}>
                <div className="linked-block"/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="account-shopify">
            <Row gutter={[{ xs: 0, md: 12 }, { xs: 0 }]}>
              <Col xs={16} md={18}>
                <div className="shopify-header">
                  <span className="account-title" />
                  <span className="shopify-tag" />
                </div>
                <div className="shopify-subtitle">
                  <div />
                  <div />
                  <div className="block-for-mobile" />
                  <div />
                </div>
              </Col>
              <Col xs={8} md={6}>
                <span className="shopify-icon" />
              </Col>
            </Row>
            <div className='wrap-store'>
              <span className="input-store"/>
              <span className="btn-store-skeleton"/>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[{ sm: 0, md: 16 }, { sm: 0 }]}
           style={{marginTop: 16}}
      >
        <Col xs={24} md={12}>
          <Card className="account-shopify">
            <Row gutter={[{ xs: 0, md: 12 }, { xs: 0 }]}>
              <Col xs={16} md={18}>
                <div className="shopify-header">
                  <span className="account-title" />
                  <span className="shopify-tag" />
                </div>
                <div className="shopify-subtitle">
                  <div />
                  <div />
                  <div className="block-for-mobile" />
                  <div />
                </div>
              </Col>
              <Col xs={8} md={6}>
                <span className="shopify-icon" />
              </Col>
            </Row>
            <div className='wrap-store'>
              <span className="input-store"/>
              <span className="btn-store-skeleton"/>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
