import React from 'react'
import './styles.less'

const Checkbox = ({type, checked, onChange, children, ...props}) => {

  let icon = ''

  switch (type) {
    case 'bounce': {
      icon = (
        <svg className={'checkbox-animation-svg'} viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6"/>
        </svg>
      )
      break
    }
    case 'path': {
      icon = (
        <svg className={'checkbox-animation-svg'} viewBox="0 0 21 21">
          <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"/>
        </svg>
      )
      break
    }

    default: {
      break
    }
  }

  return (
    <label className={`checkbox-animation ${type}`}>
      <input className={'checkbox-animation-input'} type="checkbox" checked={checked} onChange={onChange}/>

      {icon}

      {children}
    </label>
  )
}

export default Checkbox
