import React, { useState, useEffect } from 'react';
import cls from 'classname';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { debounce } from '../../Utils/utils';
import { Input, Divider, Select, Spin } from 'antd';
import BreadcrumbsActions from '../Breadcrumbs/actions';

// COMPONENTS
import { Container } from '../../Components/Blocks';
import Icon from '../../Icon';
import ProductDetailsSkeletons from './components/skeleton-details';
import ProductOverviewSkeletons from './components/skeleton-overview';
import ProductsOverview from '../../Components/Products/overview';
import ProductDetails from '../../Components/Products/details';
import ProductView from './components/product-view';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import {ButtonComponent} from '../../Components/Button';
import ModalComponent from '../../Components/Modal';
import { BreadcrumbsComponent } from '../Breadcrumbs';

// REDUCERS
import Creators from './reducer';
import CollectionsActions from '../CollectionsPage/reducer';
import CategoryActions from '../../Redux/CategoryRedux';

import utils from '../../Utils/utils';

// STYLES
import './styles.less';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import ChooseMethodModalContent from './components/chooseMethodModalContent';
import AddProductsModalContent from './components/addProductsModalContent';
import openNotification from '../../Components/Notification';

const { Search } = Input;
const { Option } = Select;

const modalTitle = {
  choose_method: 'Choose Method',
  add_products: 'Add Products',
};

const getProductUrlParams = ({ pathname, search }) => {
  let searchString = null;

  if (!search) {
    const index = pathname.search(/\?/);
    searchString = pathname.slice(index);
  }

  const p = new URLSearchParams(search || searchString);
  const paramsName = ['drop_id', 'month', 'year', 'category'];
  const paramsObj = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const v of paramsName) {
    paramsObj[v] = p.get(v);
  }

  return paramsObj;
};

export const ProductPageComponent = (
  {
    dispatch,
    loading,
    skeletonLoading,
    loadingCol,
    loadingAddProducts,
    addProductsResult,
    errors,
    products,
    isMobile,
    isShowSideBar,
    theme,
    collection,
    breadcrumbs,
    isAdmin,
    categoryData,
    updateSubscriptionResult,
}) => {
  const [view, setView] = useState('overview');
  const location = useLocation();
  const { drop_id, month, year, category } = getProductUrlParams(location);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [firstLoading, setFirstLoading] = useState(true);
  const [firsLoading, setFirsLoading] = useState(true);
  const [inputText, setInputText] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterOrder, setFilterOrder] = useState(null);
  const [isOpenModalAddProduct, setIsOpenModalAddProduct] = useState(false);
  const [addLinks, setAddLinks] = useState(null);
  const [focusedSearch, setFocusedSearch] = useState(false);

  /* choose_method or add_products */
  const [modal, setModal] = useState('choose_method');
  const [selectedMethodAdd, setSelectedMethodAdd] = useState(null);

  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const pageSize = 40;

  const { state } = location;
  const isAdminEdit = isAdmin && location?.search.includes('drop_id');

  const optionsSelect = categoryData?.categories
    ? [
        { label: 'All Categories', value: 'all', id: 'all', isDisabled: false },
        ...categoryData?.categories.map((el) => ({
          label: el.title,
          value: el.name,
          id: el.id,
          isDisabled: !el.status,
        })),
      ]
    : [{ label: 'All Categories', value: 'all', id: 'all', isDisabled: false }];

  useEffect(() => {
    let pageName = utils.toUpperLatter(location.pathname.split('/')[isAdmin ? 2 : 1])
    if (breadCrumbs === null) navigate(`/${pageName.toLowerCase()}`, {replace: true});
    if (firsLoading) setTimeout(() => setFirsLoading(false), 300);
    document.title = pageName ? `${pageName} - Dropship` : 'Dropship'
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (breadCrumbs?.length === 3 && !state) dispatch(BreadcrumbsActions.removeBreadCrumb())
    else if (breadCrumbs?.length >= 2 && state) {
      dispatch(BreadcrumbsActions.clearBreadCrumbs());
      dispatch(BreadcrumbsActions.addBreadCrumb({
        name: state?.from ? state?.from?.split('/')[1]   : location.pathname.split('/')[isAdmin ? 2 : 1],
        link: state?.from ? `/${state?.from?.split('/')[1]}` : isAdmin ? `/admin/${location.pathname.split('/')[isAdmin ? 2 : 1]}` : `/${location.pathname.split('/')[isAdmin ? 2 : 1]}`
      }))
      dispatch(
        BreadcrumbsActions.addBreadCrumb({
          name: state?.name || 'All Products',
          link: state?.link || '/portfolio',
        }),
      );
    } else if (breadCrumbs?.length < 2) {
      dispatch(
        BreadcrumbsActions.addBreadCrumb({
          name: state?.name || 'All Products',
          link: state?.link || '/portfolio',
        }),
      );
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state]);

  // GET PRODUCT REQUEST
  useEffect(() => {
    if (isAdmin) dispatch(CategoryActions.categoriesRequest());
    getProductsRequest()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [location.pathname]);

  useEffect(() => {
    if (updateSubscriptionResult) getProductsRequest()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!updateSubscriptionResult]);

  useEffect(() => {
    if (addProductsResult) {
      if (products?.invalid?.length) {
        for (const link of products?.invalid) {
          openNotification({
            message: `Invalid link - ${link}`,
            style: { minWidth: '716px' },
            type: 'error'
          });
        }
      } else if (products?.not_saved?.length) {
        for (const link of products?.not_saved) {
          openNotification({
            message: `Link not saved - ${link}`,
            style: { minWidth: '716px' },
            type: 'error'
          });
        }
      } else {
        handleCloseModal();
      }
      dispatch(Creators.clearAddProducts());
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!addProductsResult]);

  useEffect(() => {
    if (errors) {
      openNotification({
        message: errors,
        style: { minWidth: '716px' },
        type: 'error'
      });
      dispatch(Creators.clearAddProducts());
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!errors]);

  function getProductsRequest(){
    if (location.pathname === '/dashboard/product-list/') {
      dispatch(
        Creators.getProductsRequest({
          isAdmin,
          page_size: pageSize,
          // eslint-disable-next-line camelcase
          ...(drop_id && { drop_id }),
          ...(category && { category }),
          ...(month && { month }),
          ...(year && { year }),
        }),
      );
    } else {
      dispatch(
        Creators.getProductsRequest({
          isAdmin,
          page_size: pageSize,
          // eslint-disable-next-line camelcase
          ...(drop_id && { drop_id }),
          ...(category && { category }),
          ...(month && { month }),
          ...(year && { year }),
          ...(collection?.collectionID && {
            collection_id: collection?.collectionID,
          }),
        }),
      );
    }
  }

  // GET NEXT PRODUCT ACTION HANDLER
  const getNextList = () => {
    if (products?.next) {
      dispatch(
        Creators.getProductsNextRequest({
          link: products.next,
        }),
      );
    }
  };

  // SEARCH HANDLER
  const handleSearch = debounce((e) => {
    const inputSearch = e.target.value.trim().replace('\\', '');
    if (firstLoading) setFirstLoading(false);
    // set text to local state
    setInputText(inputSearch);

    // get products after search
    dispatch(
      Creators.getProductsRequest({
        isAdmin,
        // eslint-disable-next-line camelcase
        ...(drop_id && { drop_id }),
        ...(category && { category }),
        ...(month && { month }),
        ...(year && { year }),
        ...(inputSearch && { q: inputSearch }),
        ...(sortOrder && { ordering: sortOrder }),
        ...(collection?.collectionID && {
          collection_id: collection?.collectionID,
          ...(filterOrder && { category: filterOrder }),
        }),
        page_size: pageSize,
      }),
    );
  }, 500);

  // ADD/REMOVE ACTION HANDLER
  const addToCollectionFunc = ({ id, isSave, name }) => {
    if (!isSave) {
      dispatch(
        CollectionsActions.addToCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    } else {
      dispatch(
        CollectionsActions.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  function handleSort({ order, field }) {
    if (firstLoading) setFirstLoading(false);
    if (order) {
      const ordering = order === 'ascend' ? field : `-${field}`;
      setSortOrder(ordering);
      dispatch(
        Creators.getProductsRequest({
          isAdmin,
          // eslint-disable-next-line camelcase
          ...(drop_id && { drop_id }),
          ...(category && { category }),
          ...(month && { month }),
          ...(year && { year }),
          ordering,
          ...(inputText && { q: inputText }),
          ...(collection?.collectionID && {
            collection_id: collection?.collectionID,
          }),
          ...(filterOrder && { category: filterOrder }),
          page_size: pageSize,
        }),
      );
    } else {
      setSortOrder(null);
      dispatch(
        Creators.getProductsRequest({
          isAdmin,
          // eslint-disable-next-line camelcase
          ...(drop_id && { drop_id }),
          ...(category && { category }),
          ...(month && { month }),
          ...(year && { year }),
          ...(inputText && { q: inputText }),
          ...(collection?.collectionID && {
            collection_id: collection?.collectionID,
          }),
          ...(filterOrder && { category: filterOrder }),
          page_size: pageSize,
        }),
      );
    }
  }

  const handleFilter = (filter) => {
    if (filter === filterOrder) return;
    let filtered = null;
    if (filter !== 'all') {
      filtered = filter;
    }
    if (firstLoading) setFirstLoading(false);
    if (filter !== filterOrder) setFilterOrder(filtered);

    dispatch(
      Creators.getProductsRequest({
        isAdmin,
        // eslint-disable-next-line camelcase
        ...(drop_id && { drop_id }),
        ...(category && { category }),
        ...(month && { month }),
        ...(year && { year }),
        ...(inputText && { q: inputText }),
        ...(collection?.collectionId && {
          collection_id: collection?.collectionId,
        }),
        ...(filtered && { category: filtered }),
        page_size: pageSize,
      }),
    );
  };

  const onCreateProduct = () => {
    if (!isOpenModalAddProduct) {
      if(loadingAddProducts) dispatch(Creators.clearAddProducts())
      setIsOpenModalAddProduct(true);
    }
  };

  const handleSelectMethod = (method) => {
    if (method === selectedMethodAdd) {
    } else {
      setSelectedMethodAdd(method);
      setModal('add_products');
    }
  };

  const handleChangeLink = debounce((e) => {
    const value = e.target.value.trim();
    setAddLinks(value);
  }, 500);

  const handleCloseModal = () => {
    if (loadingAddProducts) return;
    setIsOpenModalAddProduct(false);
    setModal('choose_method');
    setSelectedMethodAdd(null);
  };

  const handleCancel = () => {
    if (loadingAddProducts) return;
    setModal('choose_method');
    if (addLinks) setAddLinks(null);
    setSelectedMethodAdd(null);
  };

  const handleOk = () => {
    if (products?.drop_id && addLinks && !loadingAddProducts) {
      dispatch(
        Creators.addProductsRequest({
          dropId: products?.drop_id,
          urls: addLinks,
        }),
      );
    }
  };

  const handleSetVisible = () => {
    navigate('/setting/plan')
  };

  const modalBlock = {
    choose_method: (
      <ChooseMethodModalContent setSelectedMethodAdd={handleSelectMethod} />
    ),
    add_products: (
      <AddProductsModalContent value={addLinks} onChange={handleChangeLink} />
    ),
  };

  const modalFooter = {
    choose_method: null,
    add_products: footerModal({
      isMobile,
      handleCancel,
      textBtnCancel: 'Cancel',
      handleOk,
      textBtnOk: (
        <>
          <Icon
            role="icon"
            type="arrow_add"
            color={!addLinks ? '#6E7DAE' : null}
            style={{ marginRight: 8 }}
          />{' '}
          Add
        </>
      ),
      isDisabledBtnOk: !addLinks || loadingAddProducts,
      deleteType: false,
      width: '354px',
      divider: false,
    }),
  };

  // SHOW SKELETON
  if ((skeletonLoading && firstLoading) || firsLoading) {
    return (
      <Container
        className={cls('', {
          'container container--table-view': view === 'details',
          'container container--list-view': view === 'overview',
        })}
      >
        {view === 'overview' ? (
          <ProductOverviewSkeletons isMobile={isMobile} />
        ) : (
          <ProductDetailsSkeletons isMobile={isMobile} />
        )}
      </Container>
    );
  }

  return (
      <Container
        className={cls('', {
          'container container--table-view': view === 'details',
          'container container--list-view': view === 'overview',
        })}
      >
        <div
          className={cls('page-top-line-wrapper', {
            'page-top-line-wrapper_admin': isAdmin,
          })}
        >

          <BreadcrumbsComponent
            breadCrumbs={breadCrumbs?.length ? breadCrumbs : []}
            isMobile={isMobile}
            isAdmin={isAdmin}
          />

          {isAdmin ? (
            <div className="status-count-block">
              <span className="status-count-block-item">
                Ready: {products?.count?.ready}
              </span>
              <span className="status-count-block-item">
                In Progress: {products?.count?.in_progress}
              </span>
              <span className="status-count-block-item">
                Total Products: {products?.count?.total}
              </span>
            </div>
          ) : null}
        </div>

        <div className="search">
          <div className="search_wrapper">
            <Search
              placeholder={t('common.search_products')}
              className={cls("search-input", {
                'disabled-transition': isDisabledTransition
              })}
              onChange={(e) => {
                e.persist();
                handleSearch(e);
              }}
              onFocus={() => setFocusedSearch(true)}
              onBlur={() => setFocusedSearch(false)}
              prefix={
                <>
                  <Icon type="search_icon" role="icon" color={focusedSearch ? '#225AEA' : '#707BA0'}/>
                  <Divider type="vertical" className="search-divider" />
                </>
              }
            />

            {isAdmin ? (
              isAdminEdit ? (
                <>
                  <ButtonComponent
                    type="primary"
                    className="btn-primary"
                    onClick={onCreateProduct}
                    style={{ minWidth: 166, marginRight: 16 }}
                    text={
                      <>
                        <Icon type="plus" role="icon" />
                        <span className="btn_text" style={{ paddingLeft: 10 }}>
                          Add Product
                        </span>
                      </>
                    }
                  />

                  <ModalComponent
                    width={512}
                    title={modalTitle[modal]}
                    isMobile={isMobile}
                    handleClose={handleCloseModal}
                    open={isOpenModalAddProduct}
                    destroyOnClose
                    footer={modalFooter[modal]}
                  >
                    <Spin spinning={loadingAddProducts}>
                      {modalBlock[modal]}
                    </Spin>
                  </ModalComponent>
                </>
              ) : (
                <Select
                  className="custom-select category-admin-select"
                  popupClassName="custom-dropdown category-admin-dropdown"
                  showArrow
                  suffixIcon={<ArrowSelectDown />}
                  optionFilterProp="options"
                  defaultValue="all"
                  onSelect={(value) => handleFilter(value)}
                  listHeight={176}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {optionsSelect.map((el) => (
                    <Option value={el.id} key={el.id} disabled={el.isDisabled}>
                      <Icon
                        role="icon"
                        type="select_dropdown_check_mark_v2"
                        className="check-icon"
                      />
                      <span onClick={(e) => e.preventDefault()}>
                        {el.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              )
            ) : null}

            <div className="search_product-view">
              <ProductView isMobile={isMobile} view={view} setView={setView} />
            </div>
          </div>
        </div>
        <div
          className={cls('product-list', {
            'product-list-breadcrumb': breadcrumbs?.length,
          })}
        >
          {view === 'overview' ? (
            <ProductsOverview
              products={products?.results || []}
              loading={loading || loadingCol}
              theme={theme}
              isMobile={isMobile}
              getNextList={getNextList}
              next={products?.next}
              onSort={handleSort}
              searchText={inputText}
              tooltipsText={products?.descriptions}
              breadcrumbs={breadcrumbs}
              addToCollectionFunc={(id, isSave, name) =>
                addToCollectionFunc({ id, isSave, name })
              }
              isAdmin={isAdmin}
              isAdminEdit={isAdminEdit}
              onOpenUpgradePlan={handleSetVisible}
            />
          ) : (
            <ProductDetails
              products={products.results || []}
              loading={loading || loadingCol}
              getNextList={getNextList}
              next={products?.next}
              onSort={handleSort}
              searchText={inputText}
              isMobile={isMobile}
              isShowSideBar={isShowSideBar}
              breadcrumbs={breadcrumbs}
              addToCollectionFunc={(id, isSave, name) =>
                addToCollectionFunc({ id, isSave, name })
              }
              theme={theme}
              isAdmin={isAdmin}
              isAdminEdit={isAdminEdit}
              onOpenUpgradePlan={handleSetVisible}
            />
          )}
        </div>
      </Container>
  );
};

ProductPageComponent.propTypes = {
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  skeletonLoading: PropTypes.bool,
  loadingAddProduct: PropTypes.bool,
  loadingCol: PropTypes.bool,
  products: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  dispatch: PropTypes.func,
  isShowSideBar: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  categoryData: PropTypes.object,
  updateSubscriptionResult: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  loading: state.products.loading,
  skeletonLoading: state.products.skeletonLoading,
  loadingAddProducts: state.products.addProductsLoading,
  addProductsResult: state.products.addProductsResult,
  loadingCol: state.collections.loading,
  errors: state.products.errors,
  products: state.products.products,
  productsCollection: state.products.collections,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  isShowSideBar: state.nav.isShowSideBar,
  collection: state.collections.collection,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  categoryData: state.category,
  updateSubscriptionResult: state.onBoarding.updateSubscriptionResult,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductPageComponent);
