import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Layout, Spin } from 'antd';
import OnboardingActions from '../Redux/OnboardingRedux';
import ErrorsActions from '../Redux/ErrorsRedux';
import StepBillingForm from '../Components/Forms/StepBillingForm';
import CategoryCardCheckout from '../Components/CategoryCardCheckout';
import OrderSummaryCard from '../Components/OrderSummaryCard';
import openNotification from '../Components/Notification';
import DiscountCard from '../Components/DiscountCard';
import StepTitle from '../Components/StepTitle';
import LoginHeader from '../Components/LoginHeader';
import CreditCardForm from '../Components/Forms/CreditCardForm';
import utils from '../Utils/utils';
import ButtonComponent from "../Components/Button";
import './TemplatesPage.less';
import Icon from "../Icon";
import SocialProofCheckout from "../Components/SocialProofCheckout";

function OnboardingCheckout({ isMobile, userInfo, settings, category, plan, onBoarding, ...props }) {

  const { countries, presetBillingAddress, discountResult, focusCategory, selectedCategory, selectedPlan, isYearlySubscription } = onBoarding;
  let navigate = useNavigate();;
  const { t } = useTranslation();
  const priceType = isYearlySubscription ? 'yearly' : 'monthly'

  const price = Object.values(onBoarding?.selectedPlan).reduce((accumulator, currentValue) => (accumulator + currentValue?.prices?.[priceType].price * 100), 0) * utils.multiplyPriceType[priceType]
  let discountCash = 0;

  if (discountResult) {
    discountCash = discountResult.type === 'value' ? discountResult.value : (price / 100) * discountResult.value;
  }

  /*billing-address or payment*/
  let { mode } = useParams();
  const step = { step: 2, title: 'Start Trial', text: 'Fill the forms to start your free trial' };

  useEffect(() => {
    document.title = 'Onboarding - Dropship';
    if (userInfo && userInfo?.onboarding_finished) navigate('/dashboard');
    if (!countries?.length) props.getCountries();
    handleClearError();
    handleClearDiscount();
    if(onBoarding.chargebeeCardFetching) props.setChargebeeCardFetching(false)
    let head = document.querySelector('head');
    let script = document.createElement('script')
    script.text = "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('config','oepmf');"
    head.appendChild(script)
    return () => {
      head.removeChild(script);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (selectedCategory.length) return;

    navigate('/onboarding/plan');
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [selectedCategory?.length]);

  function handleContinue(data) {
    if (mode === 'billing-address') {
      props.saveBillingAddress(data);
      navigate('/onboarding/checkout/payment');
    } else {
      onSubmitPayment(data);
    }
  }

  function handleBack(data) {
    if (!!data) props.saveBillingAddress(data);
    if (onBoarding?.intentResult) props.clearIntent();
    if (selectedCategory.includes(focusCategory.id) || mode === 'payment') {
      navigate(mode === 'payment' ? '/onboarding/checkout/billing-address' : '/onboarding/plan');
    } else {
      if (selectedCategory.length) {
        let lastCategoryId = selectedCategory[selectedCategory.length - 1];
        props.setFocusCategory(category.categoriesMap[lastCategoryId]);
        props.setFocusPlan(selectedPlan[lastCategoryId]);
        history.goBack();
      } else {
        props.setFocusCategory(null);
        props.setFocusPlan(null);
        navigate('/onboarding/category');
      }
    }
  }

  function handleCheckDiscount(code) {
    props.checkDiscountCode(code);
  }

  function handleClearError() {
    if (onBoarding.discountError) props.checkDiscountCodeReset();
  }

  function handleClearDiscount() {
    if (onBoarding.discountResult) props.checkDiscountCodeReset();
  }

  const linkInfoToObject = (str) => {
    let arr = str.split('&');
    let res = {};
    arr.forEach(el => {
      let key = el.split('=')[0];
      let value = el.split('=')[1];
      res[key] = value;
    })
    return res;
  }

  const onSubmitPayment = (data) => {
    let [categoryId, plan] = Object.entries(selectedPlan)[0]
    let categoryIdWithPeriod = category?.categoriesMap?.[categoryId]?.prices?.[priceType]?.id
    let cookies = document.cookie.split(';');
    let googleClickID = cookies.filter(el => /_gcl/.test(el) && /GCL/.test(el));
    let refLinkInfo = localStorage.getItem('ref_link_info');

    props.saveSubscription({
      'billing_address': {
        ...onBoarding.presetBillingAddress.address,
        'first_name': onBoarding.presetBillingAddress.first_name,
        'last_name': onBoarding.presetBillingAddress.last_name,
      },
      'send_news': onBoarding.presetBillingAddress.send_news,
      'country_code': onBoarding.presetBillingAddress.country_code || null,
      'phone': onBoarding.presetBillingAddress.phone || null,
      'discount_code': discountResult?.id || null,
      'plan': { [categoryIdWithPeriod]: plan?.prices?.[priceType] },
      'activate_trial': true,
      ...(googleClickID.length && {
        'google_click_id': googleClickID[0].split('=')[1]
      }),
      ...(refLinkInfo && {
        'conversion_campaign': linkInfoToObject(refLinkInfo)
      }),
      ...(data.intent_id && {
        'payment': {
          'type': 'card',
          'intent_id': data.intent_id,
        },
      }),
    });
  };

  function handleDeletePlan(category) {
    const newSelectedPlans = { ...onBoarding?.selectedPlan };
    delete newSelectedPlans[category.id];
    props.setSelectedPlan(newSelectedPlans);

    const newSelectedCategory = onBoarding?.selectedCategory.filter(id => id !== category.id);
    props.setSelectedCategory(newSelectedCategory);

    openNotification({
      type: 'success',
      message: `Plan has been successfully removed`,
      getContainer: document.body,
    });

  }

  const address = presetBillingAddress?.address || null;

  const categoryCardsList = onBoarding.selectedCategory.map(categoryId => (
    <CategoryCardCheckout key={categoryId}
                          category={category?.categoriesMap?.[categoryId]}
                          plan={onBoarding?.selectedPlan?.[categoryId]}
                          maxProducts={plan.maxProducts}
                          onDeletePlan={handleDeletePlan}
                          priceType={priceType}
    />
  ));

  return (
    <Layout>
      <Spin size="large"
            spinning={onBoarding.saveSubscriptionFetching || onBoarding.chargebeeCardFetching}
            wrapperClassName={onBoarding.saveSubscriptionFetching || onBoarding.chargebeeCardFetching ? 'spin-wrap hidden' : 'spin-wrap auto'}
      >
        <div className="login-row">
          <LoginHeader withLogout={true} />
          <div className="login-content category checkout">
            <div className="content-main">
              <div className="content-left">
                <StepTitle stepInfo={step}
                           style={mode === 'payment' ? { flex: 'none' } : {}}
                />

                {
                  isMobile
                    ? (
                      <OrderSummaryCard
                        userInfo={userInfo}
                        plan={onBoarding?.selectedPlan}
                        discount={onBoarding.discountResult}
                        isMobile={isMobile}
                        priceType={priceType}
                        fetching={
                          onBoarding.saveSubscriptionFetching
                        }
                      >
                        <>
                          {
                            categoryCardsList
                          }
                          <div className={'divider'}/>
                          <DiscountCard
                            isMobile={isMobile}
                            onSubmit={handleCheckDiscount}
                            error={onBoarding.discountError}
                            clearError={handleClearError}
                            discount={onBoarding.discountResult}
                            clearDiscount={handleClearDiscount}
                            fetching={onBoarding.discountFetching}
                          />
                        </>
                      </OrderSummaryCard>
                    )
                    : null
                }

                {mode === 'billing-address' ? (
                  <StepBillingForm
                    theme={props.theme}
                    countries={countries}
                    countryCode={countries}
                    onClose={handleBack}
                    onSubmit={handleContinue}
                    userInfo={userInfo}
                    isMobile={isMobile}
                    presetBillingAddress={presetBillingAddress}
                    saveAddress={props.saveBillingAddress}
                  />
                ) : null}

                {mode === 'payment' ? (
                  <>
                    <div className={'saved-users-information-block'}>
                      <div className="row">
                        <div className="info-wrap">
                          <span className="name">Contact</span>
                          <span className="info">
                          {presetBillingAddress?.email}
                        </span>
                        </div>
                        <ButtonComponent className="only-text disabled"
                                onClick={() => handleBack(null)}
                                disabled
                        >
                          Change
                        </ButtonComponent>
                      </div>
                      <div className="row">
                        <div className="info-wrap">
                          <span className="name">Billing</span>
                          <span className="info">
                          {address ? `${address.address}, ${address.postal_code}, ${onBoarding?.countries?.filter(el => el.id === address.country)[0]?.name}` : ''}
                        </span>
                        </div>
                        <ButtonComponent className="only-text"
                                onClick={() => handleBack(null)}
                        >
                          Change
                        </ButtonComponent>
                      </div>
                    </div>

                    <CreditCardForm
                      theme={'light'}
                      onBack={handleBack}
                      onContinue={handleContinue}
                      isMobile={isMobile}
                      amount={price - discountCash}
                      onBoarding={true}
                      intent={{
                        result: onBoarding?.intentResult,
                        error: onBoarding?.intentError,
                        createIntent: props.createIntent,
                        clearIntent: props.clearIntent,
                      }}
                      fetching={onBoarding?.saveSubscriptionFetching}
                      setChargebeeCardFetching={props.setChargebeeCardFetching}
                      errorSave={props.errorSave}
                    />
                  </>
                ) : null}
              </div>

              {

                isMobile
                  ? null
                  : (
                    <div className="content-right">
                    <SocialProofCheckout />
                      <span className="title-block">
                      Plan
                    </span>

                      <div className={'category-card-container'}>
                        {
                          categoryCardsList
                        }
                      </div>

                      <span className="title-block">{t('Summary')}</span>
                      <OrderSummaryCard
                        userInfo={userInfo}
                        plan={onBoarding?.selectedPlan}
                        discount={onBoarding.discountResult}
                        fetching={onBoarding.saveSubscriptionFetching}
                        priceType={priceType}
                      >
                        <DiscountCard
                          onSubmit={handleCheckDiscount}
                          error={onBoarding.discountError}
                          clearError={handleClearError}
                          discount={onBoarding.discountResult}
                          clearDiscount={handleClearDiscount}
                          fetching={onBoarding.discountFetching}
                          isMobile={isMobile}
                        />
                      </OrderSummaryCard>

                      <ul className="footer-nav-link">
                        <li className="footer-nav-link-item">
                          <Icon role='icon'
                                type='hour_support'
                          />
                          24-Hour Support
                        </li>
                        <li className="footer-nav-link-item">
                          <Icon role='icon'
                                type='day_free_trial'
                          />
                          7-Day Free Trial
                        </li>
                        <li className='footer-nav-link-item'>
                          <Icon role='icon'
                                type='day_money_back'
                          />
                          Cancel Anytime
                      </li>
                      </ul>

                    </div>)
              }
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = state => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  category: state.category,
  plan: state.plan,
  onBoarding: state.onBoarding,
  filters: state.filters,
});

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  saveBillingAddress: data => dispatch(OnboardingActions.saveBillingAddress(data)),
  clearBillingAddress: () => dispatch(OnboardingActions.clearBillingAddress()),
  checkDiscountCode: code => dispatch(OnboardingActions.checkDiscountCodeRequest(code)),
  checkDiscountCodeReset: () => dispatch(OnboardingActions.checkDiscountCodeReset()),
  setSelectedCategory: (category) => dispatch(OnboardingActions.setSelectedCategory(category)),
  setFocusCategory: (category) => dispatch(OnboardingActions.focusCategory(category)),
  setSelectedPlan: (plan) => dispatch(OnboardingActions.setSelectedPlan(plan)),
  setFocusPlan: (plan) => dispatch(OnboardingActions.focusPlan(plan)),
  saveSubscription: data => dispatch(OnboardingActions.saveSubscriptionRequest(data)),
  createIntent: data => dispatch(OnboardingActions.createIntentRequest(data)),
  clearIntent: () => dispatch(OnboardingActions.createIntentReset()),
  setChargebeeCardFetching: (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value)),
  errorSave: (error) => dispatch(ErrorsActions.errorSave(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingCheckout);
