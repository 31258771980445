import React, {useEffect, useState, useRef} from 'react';
import Icon from "../../../Icon";
import {useTranslation} from "react-i18next";
import utils, {dateOptions} from "../../../Utils/utils";
import Creators from '../reducer';
import ShopifyCreators from "../../ShopifyStore/reducer";
import acc from "accounting";
import dayjs from "dayjs";
import cls from "classname";
import {connect, useDispatch} from "react-redux";
import AutoDSCreators from "../../AutoDS/reducer";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import {Divider, Spin, Dropdown} from "antd";
import ButtonComponent from "../../../Components/Button";
import ChartBlock from "../../SalesTrackerPage/components/ChartBlock";
import Chart from "../../../Components/Charts/Chart";
import Pagination from "../components/Pagination";
import AdItem from "../components/AdItem";
import MainBlockSkeleton from "../../SalesTrackerPage/components/MainBlockSkeleton";
import MainBlockAdSpot from "../components/MainBlockAdSpot";
import IconWithText from "../../../Components/Text";

const RadialChart = (
  {
    data
  }) => {

  const {t} = useTranslation();

  let percentage = {};
  let dataToBuild = [];
  let result = [];
  const {total, ...keys} = data;

  const calcDegrees = (key) => {
    return percentage[key] = Math.floor(((data[key] || 0)/total)*360);
  }

  const calcSectorsSkew = (key, percentage) => {
    if (percentage[key] > 90) {
      let count = Math.floor(percentage[key] / 90);
      for (let i = 1; i <= count; i++) {
        dataToBuild.push({
          key: key,
          skew: 0
        })
      }
      dataToBuild.push({
        key: key,
        skew: 90 - (percentage[key] - 90 * count) - 2
      })
    } else {
      dataToBuild.push({
        key: key,
        skew: 90 - percentage[key] - 2
      })
    }
  }

  const calcSectorsRotation = (data) => {
    for (let i = 0; i < data?.length; i++) {
      if (i === 0) {
        result.push({
          ...data[i],
          rotation: -180
        })
      } else {
        result.push({
          ...data[i],
          rotation: result[i-1]?.rotation + (90 - result[i-1]?.skew) - 1
        })
      }
    }
  }

  const buildSectors = () => {
    for (let key in keys) {
      calcDegrees(key)
    }
    for (let key in keys) {
      calcSectorsSkew(key, percentage)
    }
    calcSectorsRotation(dataToBuild)
    return result.map((el, index) => (
      <div key={index} className={`sector ${el?.key}`} style={{transform: `rotate(${el?.rotation}deg) skew(${el?.skew}deg)`}} />
    ))
  }

  return (
    <div className="chart">
      {
        buildSectors()
      }
      <div className="total-info">
        <h4>
          {t('Total')}
        </h4>
        <span>
          {data.total}
        </span>
      </div>
    </div>
  )
}

const AdItemProductsDetailed = (
  {
    isMobile,
    theme,
    userInfo,
    productInfo,
    getProductInfoAdSpot,
    getProductChartAdSpot,
    getProductReportAdSpot,
    getProductAdsAdSpot,
    innerPagesInfo,
    setInnerPagesDateFilters,
    setInnerPagesDateCheckedList,
    shopifyStores,
    changeVisibleModalShopify,
    creditsLeft,
    getOverviewCardInfo,
    overviewInfo,
    autoDS,
    setRecordToPriceHistory,
    setVisibleModal,
    setModal,
  }
) => {

  const {t} = useTranslation();
  const {productId} = useParams();
  const {search} = useLocation();

  let params = new URLSearchParams(search);

  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const initialUrl = document?.location?.pathname + (document?.location?.search || '');
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [historicalDataView, setHistoricalDataView] = useState(true);
  const [sortOrder, setSortOrder] = useState('most_recent');
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [firstLoading, setFirstLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const minDate = innerPagesInfo?.dateFilters?.report_period?.min;
  const maxDate = innerPagesInfo?.dateFilters?.report_period?.max;

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId });
    } else {
      changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null });
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if ((currentTopScroll >= maxTopScroll*0.95) && !productInfo?.adsInfo?.adsInfoLoading && productInfo?.adsInfo?.results?.length < +productInfo?.adsInfo?.count) {
      setPageNumber(prev => prev + 1);
      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    if (firstLoading) setTimeout(() => setFirstLoading(false), 1000);
    getOverviewCardInfo({
        ad_id: params.get('ad_id'),
      });
  }, [search]);

  useEffect(() => {
    let min = dayjs().diff(dayjs(overviewInfo?.cardInfo?.min_calendar_date), 'days') < 30 ?
      dayjs(overviewInfo?.cardInfo?.min_calendar_date).format('YYYY-MM-DD')
      :
      dayjs().subtract(30, 'days').format('YYYY-MM-DD');
    let max = dayjs().format('YYYY-MM-DD');
    setInnerPagesDateFilters({report_period: {min, max}});
    setInnerPagesDateCheckedList([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}]);
  }, [overviewInfo?.cardInfo?.min_calendar_date])

  useEffect(() => {
    if (minDate && maxDate) {
      getProductInfoAdSpot({
        internal_shop_id: params.get('internal_shop_id'),
        shopify_product_id: productId,
        date__gte: minDate,
        date__lte: maxDate
      });
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    getProductChartAdSpot({
      internal_shop_id: params.get('internal_shop_id'),
      shopify_product_id: productId,
      date__gte: minDate,
      date__lte:maxDate,
      historical_data_view: historicalDataView
    });
  }, [minDate, maxDate, historicalDataView])

  useEffect(() => {
    getProductAdsAdSpot({
      internal_shop_id: params.get('internal_shop_id'),
      shopify_product_id: productId,
      page_number: pageNumber,
      page_size: pageSize,
      sort: sortOrder
      });
  }, [pageNumber, pageSize, sortOrder])

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && productInfo?.adsInfo?.results?.length < +productInfo?.adsInfo?.count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      if (tableContent && !firstLoading) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    }
  }, [productInfo?.adsInfo?.results?.length, productInfo?.adsInfo?.count, tableRef?.current])

  return (
    <div className={'fadspot-page-products-detailed'}>
      {
        productInfo?.productInfo?.productInfoLoading ?
          <span className="link skeleton" style={{width: '100%', height: 84}}/>
          :
          <div className="fadspot-page-overview-banner">
            <Icon type={'attention_outline'} role={'icon'} width={24} height={24} color={'#707ba0'}/>
            <div>
              <h3>
                {t('Facebook post links may not be present in every ad')}
              </h3>
              <p>
                {t('Ad Engagement chart relies on ads with post links; more ads may exist ')}
              </p>
            </div>
          </div>
      }
      {
        productInfo?.productInfo?.productInfoLoading ?
          <div className="fadspot-page-product-info-wrapper">
            <div className="fadspot-page-product-image">
              <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
            </div>
            <div className="fadspot-page-product-info">
              <div className="main">
                <div className="title">
                  <span className="link skeleton" style={{width: isMobile ? 190 : 332, height: 32}}/>
                  <span className="link skeleton" style={{width: 146, height: 24}}/>
                </div>
                {
                  isMobile ?
                    null
                    :
                    <Divider style={{margin: '24px auto'}} type={'horizontal'} />
                }
                <div className="stats">
                  <div className="stat">
                    <span className="link skeleton" style={{width: 34, height: 20}}/>
                    <span className="link skeleton" style={{width: 160, height: 24}}/>
                  </div>
                  <div className="stat">
                    <span className="link skeleton" style={{width: 85, height: 20}}/>
                    <span className="link skeleton" style={{width: 55, height: 24}}/>
                  </div>
                  <div className="stat">
                    <span className="link skeleton" style={{width: 63, height: 20}}/>
                    <span className="link skeleton" style={{width: 11, height: 24}}/>
                  </div>
                  <div className="stat">
                    <span className="link skeleton" style={{width: 93, height: 20}}/>
                    <span className="link skeleton" style={{width: 97, height: 24}}/>
                  </div>
                </div>
              </div>

              <div className="search">
                <div>
                  <span className="link skeleton" style={{width: 100, height: 24}}/>
                  <div>
                    {[1,2,3,4].map((el, index) => {
                      return (
                        <span key={el} className="link skeleton" style={{width: 24, height: 24}}/>
                      )})}
                  </div>
                </div>
                <span className="link skeleton" style={{width: isMobile ? '100%' : 128, height: 48}}/>
              </div>
            </div>

          </div>
          :
          <div className="fadspot-page-product-info-wrapper">
            <div className="fadspot-page-product-image">
              {
                productInfo?.productInfo?.main_image ?
                  <img src={utils.changeImageSize(productInfo?.productInfo?.main_image, 496)}
                       alt=""
                       onError={utils.addDefaultSrc}
                  />
                  : <Image src={Images.defaultImg} small={false}/>
              }
            </div>
            <div className="fadspot-page-product-info">
              <div className="main">
                <div className="title">
                  <h3>
                    {productInfo?.productInfo?.title}
                  </h3>
                  <span onClick={() => {
                    window.open(productInfo?.productInfo?.landing_page_url?.[0], '_blank');
                  }}>
                    {t('View Landing Page')}
                  </span>
                </div>
                {
                  isMobile ?
                    null
                    :
                    <Divider style={{margin: '24px auto'}} type={'horizontal'} />
                }
                <div className="stats">
                  <div className="stat price">
                    <h4>
                      {t('Price')}
                    </h4>
                    <span className={'clickable'}
                          onClick={() => {
                            setRecordToPriceHistory(productInfo?.productInfo);
                            setVisibleModal(true);
                            setModal('priceHistory');
                          }}
                    >
                  ${
                    acc.formatNumber(productInfo?.productInfo?.usd_price, '2', ',', '.')
                  }
                      {
                        productInfo?.productInfo?.currency !== 'USD' ?
                          ` (${acc.formatNumber(productInfo?.productInfo?.original_price, '2', ',', '.')} ${productInfo?.productInfo?.currency || 'GBP'})`
                          :
                          null
                      }
                </span>
                  </div>
                  <div className="stat">
                    <h4>
                      {t('Engagement')}
                    </h4>
                    <span className={'clickable'}
                          onClick={() => {
                            document.getElementById('additional-info-block-chart').scrollIntoView({
                              behavior: 'smooth',
                            })
                          }}
                    >
                  {
                    acc.formatNumber(productInfo?.productInfo?.engagement_count, '0', ',', '.')
                  }
                </span>
                  </div>
                  <div className="stat">
                    <h4>
                      {t('Ad Count')}
                    </h4>
                    <span className={'clickable'}
                          onClick={() => {
                            document.getElementById('fadspot-page-items-wrapper').scrollIntoView({
                              behavior: 'smooth',
                            })
                          }}
                    >
                  {
                    acc.formatNumber(productInfo?.productInfo?.ad_count, '0', ',', '.')
                  }
                </span>
                  </div>
                  <div className="stat">
                    <h4>
                      {t('Creation Date')}
                    </h4>
                    <span>
                  {
                    dayjs(productInfo?.productInfo?.created_at).format('MMM DD, YYYY')
                  }
                </span>
                  </div>
                </div>
              </div>

              <div className="search">
                <div>
                  <h4>
                    {t('Quick Search')}
                  </h4>
                  <div>
                    {
                      productInfo?.productInfo?.quick_search?.length ?
                        productInfo?.productInfo?.quick_search.map((el, index) => {
                      return (
                        <a
                          key={el.platform}
                          href={el?.link || '#'}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link with-img"
                        >
                          <Icon role="button"
                                type={`search_${el.platform}`}
                                theme={theme}
                                className={`${el.platform}-icon`}
                                titleText={() => <>Search on <span style={{textTransform: 'capitalize'}}>{el.platform}</span></>}
                                tooltipProps={{
                                  placement: 'bottom',
                                  destroyTooltipOnHide: true,
                                  trigger: 'hover',
                                  overlayClassName: cls('details-tooltip', `${el.name}-tooltip`),
                                  getPopupContainer: (trigger) => trigger.parentNode}}
                          />
                        </a>
                      )})
                        :
                        null
                    }
                  </div>
                </div>
                <Dropdown
                  open={visible}
                  onOpenChange={setVisible}
                  dropdownRender={() => (
                    <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
                      <li className="action-dropdown_item no-hover"
                          onClick={() => {
                            setVisible(false);
                            handleOpenModalShopifyConnect(`${productInfo?.productInfo?.internal_shop_id}_${productInfo?.productInfo?.id}`)
                          }}>
                        <IconWithText
                          size={24}
                          icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} /> }
                          text={t('Import Product to Shopify')}
                        />
                      </li>
                      <li className="action-dropdown_item"
                          onClick={() => {
                            setVisible(false);
                            handleOpenModalAutoDSConnect({
                              'source': 'shopify',
                              'product_id': `${productInfo?.productInfo?.store?.id}_${productInfo?.productInfo?.id}`
                            })
                          }}>
                        <IconWithText
                          icon={() => <Icon type={'auto_ds'} role={'icon'} /> }
                          text={t('Import Product to AutoDS')}
                        />
                      </li>
                    </ul>
                  )}
                  destroyPopupOnHide={true}
                  trigger={['click']}
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentNode }
                  overlayClassName={'filter-preset-cell-menu-wrapper'}
                >
                  <ButtonComponent type="primary"
                                   className="btn-primary fadspot-page-product-import"
                                   disabled={false}
                                   onClick={() => null}
                  >
                    {t('Import')}
                    <Icon type={'export_icon_database'} role={'icon'} />
                  </ButtonComponent>
                </Dropdown>
              </div>
            </div>

          </div>
      }

      {
        productInfo?.productInfo?.productInfoLoading ?
          <MainBlockSkeleton isMobile={isMobile} />
          :
          <MainBlockAdSpot
            data={{
              internal_shop_id: params.get('internal_shop_id'),
              shopify_product_id: productId,
              ad_headline: productInfo?.productInfo?.title,
              timeZone: productInfo?.productInfo?.store_timezone,
              timeInfo: overviewInfo?.cardInfo?.min_calendar_date,
              dates: {min: minDate, max: maxDate},
            }}
            downloadReport={getProductReportAdSpot}
            dateOptions={dateOptions}
            fileLoading={productInfo?.reportLoading}
            isProduct={true}
          />
      }

      {
        productInfo?.chartInfo?.chartInfoLoading ?
          <div className={'chart-block'}>
            <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
              <div style={{display: "flex", alignItems: "center", gap: '8px'}}>
                <span className="link skeleton" style={{width: 122, height: 24}}/>
                <span className="link skeleton" style={{width: 186, height: 20}}/>
              </div>
              <span className="link skeleton" style={{width: 107, height: 16}}/>
            </div>
            <span className="link skeleton" style={{width: 141, height: 40, margin: '8px 0 16px'}}/>
            <span className="link skeleton" style={{width: '100%', height: 235}}/>
            <Divider type={'horizontal'} style={{margin: '16px auto'}} />
            <div className="fadspot-page-overview-chart-stats">
              {
                [1,2,3].map(el => (
                  <div key={el} className="fadspot-page-overview-chart-stat">
                    <div className="fadspot-page-overview-chart-stat-icon"/>
                    <div className="fadspot-page-overview-chart-stat-info">
                      <span className="link skeleton" style={{width: 65, height: 20}}/>
                      <span className="link skeleton" style={{width: 79, height: 24}}/>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          <ChartBlock type={'ad_spot'}
                      subTitle={!isMobile ?
                        `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                        :
                        null
                      }
                      totalCount={productInfo?.chartInfo?.stats?.total_engagements || 0}
                      link={null}
                      adsInfo={true}
                      historicalDataViewToggleText={[t('Daily'), t('Cumulative')]}
                      historicalDataView={historicalDataView}
                      setHistoricalDataView={setHistoricalDataView}
                      adsQuantity={productInfo?.chartInfo?.stats?.total_ads}
                      id={'additional-info-block-chart'}
                      isMobile={isMobile}
          >
            <Chart data={productInfo?.chartInfo?.charts?.engagements?.length ? [...productInfo?.chartInfo?.charts?.engagements] : []}
                   type={'ad_spot'}
                   animation={false}
                   isMobile={isMobile}
                   externalTooltip={false}
            />
            <Divider style={{margin: '16px auto'}} type={'horizontal'} />
            <div className="fadspot-page-overview-chart-stats">
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon type={'ad_spot_chart_like'} role={'icon'} />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Reactions')}
                    <Icon
                      type={'attention_outline'}
                      role="button"
                      width={16}
                      height={16}
                      tooltipProps={{
                        trigger: 'hover',
                        placement: 'right',
                        overlayClassName: 'subscription-tooltip scheduled',
                        getPopupContainer: (trigger) => trigger.parentNode,
                      }}
                      titleText={t('We calculate Reactions based on the sum of reactions the ad receives, such as Like, Love, Haha, Wow, Sad, and Angry. ')}
                    />
                  </p>
                  <span>
                {acc.formatNumber(productInfo?.chartInfo?.stats?.total_reactions_count, 0, ',', '')}
              </span>
                </div>
              </div>
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon type={'ad_spot_chart_comments'} role={'icon'} />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Comments')}
                  </p>
                  <span>
                {acc.formatNumber(productInfo?.chartInfo?.stats?.comments_count, 0, ',', '')}
              </span>
                </div>
              </div>
              <div className="fadspot-page-overview-chart-stat">
                <div className="fadspot-page-overview-chart-stat-icon">
                  <Icon type={'ad_spot_chart_shares'} role={'icon'} />
                </div>
                <div className="fadspot-page-overview-chart-stat-info">
                  <p>
                    {t('Shares')}
                  </p>
                  <span>
                {acc.formatNumber(productInfo?.chartInfo?.stats?.shares_count, 0, ',', '')}
              </span>
                </div>
              </div>
            </div>
          </ChartBlock>
      }

      {
        productInfo?.chartInfo?.chartInfoLoading ?
          <div className='fadspot-page-product-additional-info'>
            <div className="additional-info-block">
              <div className="landing-pages ad-types">
                <span className="link skeleton" style={{width: 131, height: 24}}/>
                <Divider type={'horizontal'} style={{margin: '8px auto 16px'}}/>
                <div className="ad-types-info">
                  <span className="link skeleton"
                        style={{width: isMobile ? 136 : 172, height: isMobile ? 136 : 172, borderRadius: '50%'}}/>
                  <div className="stats">
                    <div className="stat">
                      <div className="title">
                        <div>
                          <span className="link skeleton" style={{width: 20, height: 20, marginRight: 4}}/>
                          <span className="link skeleton" style={{width: 38, height: 20}}/>
                        </div>
                        <div>
                          <span className="link skeleton" style={{width: 9, height: 20}}/>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span className="link skeleton" style={{width: 29, height: 20}}/>
                        </div>
                      </div>
                      <span className="link skeleton" style={{width: '100%', height: 6}}/>
                    </div>
                    <div className="stat">
                      <div className="title">
                        <div>
                          <span className="link skeleton" style={{width: 20, height: 20, marginRight: 4}}/>
                          <span className="link skeleton" style={{width: 38, height: 20}}/>
                        </div>
                        <div>
                          <span className="link skeleton" style={{width: 9, height: 20}}/>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span className="link skeleton" style={{width: 29, height: 20}}/>
                        </div>
                      </div>
                      <span className="link skeleton" style={{width: '100%', height: 6}}/>
                    </div>
                    <div className="stat">
                      <div className="title">
                        <div>
                          <span className="link skeleton" style={{width: 20, height: 20, marginRight: 4}}/>
                          <span className="link skeleton" style={{width: 38, height: 20}}/>
                        </div>
                        <div>
                          <span className="link skeleton" style={{width: 9, height: 20}}/>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span className="link skeleton" style={{width: 29, height: 20}}/>
                        </div>
                      </div>
                      <span className="link skeleton" style={{width: '100%', height: 6}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="additional-info-block">
              <div className="landing-pages">
                <span className="link skeleton" style={{width: 131, height: 24}}/>
                <span className="link skeleton" style={{width: 20, height: 40}}/>
                <Divider type={'horizontal'} style={{margin: '16px auto'}}/>
                <span className="link skeleton" style={{width: '100%', height: 24}}/>
                <span className="link skeleton" style={{width: '100%', height: 24, marginTop: 8}}/>
                <span className="link skeleton" style={{width: '100%', height: 24, marginTop: 8}}/>
                <span className="link skeleton" style={{width: '100%', height: 24, marginTop: 8}}/>
              </div>
            </div>
            <div className="additional-info-block wide">
              <div className="landing-pages">
                <span className="link skeleton" style={{width: 131, height: 24}}/>
                <span className="link skeleton" style={{width: 20, height: 40}}/>
                <Divider type={'horizontal'} style={{margin: '16px auto'}}/>
                <span className="link skeleton" style={{width: '100%', height: 160}}/>
              </div>
            </div>
            {/*<div className="additional-info-block">*/}
            {/*  <div className="landing-pages ad-status">*/}
            {/*    <span className="link skeleton" style={{width: 131, height: 24}}/>*/}
            {/*    <Divider type={'horizontal'} style={{margin: '16px auto'}} />*/}
            {/*    <div className="info-stats">*/}
            {/*      <div className="info-stat-wrapper">*/}
            {/*        <span className="link skeleton" style={{width: 8, height: 84}}/>*/}
            {/*        <div className={'stats'}>*/}
            {/*          <span className="link skeleton" style={{width: 71, height: 20}}/>*/}
            {/*          <span className="link skeleton" style={{width: 124, height: 40}}/>*/}
            {/*          <div className="types">*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="info-stat-wrapper">*/}
            {/*        <span className="link skeleton" style={{width: 8, height: 84}}/>*/}
            {/*        <div className={'stats'}>*/}
            {/*          <span className="link skeleton" style={{width: 71, height: 20}}/>*/}
            {/*          <span className="link skeleton" style={{width: 124, height: 40}}/>*/}
            {/*          <div className="types">*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <span className="link skeleton" style={{width: 20, height: 20, marginRight: 2}}/>*/}
            {/*              <span className="link skeleton" style={{width: 24, height: 20}}/>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <span className="link skeleton" style={{width: '100%', height: 72, marginTop: 16}}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          :
          <div className='fadspot-page-product-additional-info'>
            <div className="additional-info-block">
              <div className="landing-pages ad-types">
                <h4>
                  {t('Ad Types')}
                </h4>
                <Divider type={'horizontal'} style={{margin: '8px auto 16px'}}/>
                <div className="ad-types-info">
                  <RadialChart data={{
                    total: productInfo?.chartInfo?.stats?.total_ads,
                    video: productInfo?.chartInfo?.ad_type?.video,
                    photo: productInfo?.chartInfo?.ad_type?.image,
                    carousel: productInfo?.chartInfo?.ad_type?.carousel,
                  }}/>
                  <div className="stats">
                    <div className="stat">
                      <div className="title">
                        <div>
                          <Icon type={'ad_spot_video'} role={'icon'} color={'#225aea'}/>
                          <span>
                        {t('Video')}
                      </span>
                        </div>
                        <div>
                      <span>
                        {acc.formatNumber(productInfo?.chartInfo?.ad_type?.video, 0, ',')}
                      </span>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span>
                        {
                          acc.formatNumber((productInfo?.chartInfo?.ad_type?.video / productInfo?.chartInfo?.stats?.total_ads) * 100, 0, '', '')
                        }%
                      </span>
                        </div>
                      </div>
                      <div className="track">
                        <div className="active"
                             style={{
                               width: `${Math.floor((productInfo?.chartInfo?.ad_type?.video / productInfo?.chartInfo?.stats?.total_ads) * 100)}%`,
                               background: '#225aea'
                             }}
                        />
                      </div>
                    </div>
                    <div className="stat">
                      <div className="title">
                        <div>
                          <Icon type={'ad_spot_photo'} role={'icon'} color={'#103FB6'}/>
                          <span>
                        {t('Photo')}
                      </span>
                        </div>
                        <div>
                      <span>
                        {acc.formatNumber(productInfo?.chartInfo?.ad_type?.image, 0, ',')}
                      </span>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span>
                        {
                          acc.formatNumber((productInfo?.chartInfo?.ad_type?.image / productInfo?.chartInfo?.stats?.total_ads) * 100, 0, '', '')
                        }%
                      </span>
                        </div>
                      </div>
                      <div className="track">
                        <div className="active"
                             style={{
                               width: `${Math.floor((productInfo?.chartInfo?.ad_type?.image / productInfo?.chartInfo?.stats?.total_ads) * 100)}%`,
                               background: '#103FB6'
                             }}
                        />
                      </div>
                    </div>
                    <div className="stat">
                      <div className="title">
                        <div>
                          <Icon type={'ad_spot_carousel'} role={'icon'} color={'#102D78'}/>
                          <span>
                        {t('Carousel')}
                      </span>
                        </div>
                        <div>
                      <span>
                        {acc.formatNumber(productInfo?.chartInfo?.ad_type?.carousel, 0, ',')}
                      </span>
                          <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                          <span>
                        {
                          acc.formatNumber((productInfo?.chartInfo?.ad_type?.carousel / productInfo?.chartInfo?.stats?.total_ads) * 100, 0, '', '')
                        }%
                      </span>
                        </div>
                      </div>
                      <div className="track">
                        <div className="active"
                             style={{
                               width: `${Math.floor((productInfo?.chartInfo?.ad_type?.carousel / productInfo?.chartInfo?.stats?.total_ads) * 100)}%`,
                               background: '#102D78'
                             }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="additional-info-block">
              <div className="landing-pages">
                <h4>
                  {
                    productInfo?.chartInfo?.ad_landing_page?.total === 1 ?
                    t('Ad Landing Page')
                      :
                    t('Ad Landing Pages')
                  }
                </h4>

                <span>
                  {
                    acc.formatNumber(productInfo?.chartInfo?.ad_landing_page?.total, 0, ',')
                  }
                </span>

                <Divider type={'horizontal'} style={{margin: '16px auto'}}/>

                <div className='links'>
                  {
                    productInfo?.chartInfo?.ad_landing_page?.results?.length ?
                      [...productInfo?.chartInfo?.ad_landing_page?.results].map(el => (
                        <div className='link' key={el?.id}>
                          <Icon type={'ad_spot_link'} role={'icon'}/>
                          <div>
                            <a className={'ad-landing-pages-link'} href={el?.landing_page_url} target={'_blank'}>
                              {el?.landing_page_url}
                            </a>
                            <div>
                        <span>
                          {
                            acc.formatNumber(el?.count, 0, ',')
                          }
                        </span>
                              <Divider style={{margin: '0 8px'}} type={'vertical'}/>
                              <span>
                          {
                            acc.formatNumber(el?.percent, 0, ',')
                          }%
                        </span>
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      null
                  }
                </div>
              </div>
            </div>
            <div className="additional-info-block wide">
              <ChartBlock type={'ad_spot_date'}
                          subTitle={null}
                          totalCount={productInfo?.chartInfo?.stats?.total_ads}
                          link={null}
              >
                <Chart
                  data={productInfo?.chartInfo?.charts?.creation_date?.length ? [...productInfo?.chartInfo?.charts?.creation_date] : []}
                  type={'ad_spot_date'}
                  animation={false}
                  isMobile={isMobile}
                  height={isMobile ? null : '160px'}
                />
              </ChartBlock>
            </div>
            {/*<div className="additional-info-block">*/}
            {/*  <div className="landing-pages ad-status">*/}
            {/*    <h4>*/}
            {/*      {t('Ad Status')}*/}
            {/*    </h4>*/}
            {/*    <Divider type={'horizontal'} style={{margin: '8px auto 16px'}} />*/}
            {/*    <div className="info-stats">*/}
            {/*      <div className="info-stat-wrapper">*/}
            {/*        <div className="bar active" />*/}
            {/*        <div className={'stats'}>*/}
            {/*      <span className="title">*/}
            {/*        {t('Active Ads')}*/}
            {/*      </span>*/}
            {/*          <span className="value">*/}
            {/*            {*/}
            {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.total, 0, ',')*/}
            {/*            }*/}
            {/*            <span>({*/}
            {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.percent, 0, ',')*/}
            {/*            }%)</span>*/}
            {/*          </span>*/}
            {/*          <div className="types">*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_video'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.video, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_photo'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.image, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_carousel'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.active?.types?.carousel, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="info-stat-wrapper">*/}
            {/*        <div className="bar" />*/}
            {/*        <div className={'stats'}>*/}
            {/*      <span className="title">*/}
            {/*        {t('Inactive Ads')}*/}
            {/*      </span>*/}
            {/*          <span className="value">*/}
            {/*            {*/}
            {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.total, 0, ',')*/}
            {/*            }*/}
            {/*            <span>({*/}
            {/*              acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.percent, 0, ',')*/}
            {/*            }%)</span>*/}
            {/*          </span>*/}
            {/*          <div className="types">*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_video'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.video, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_photo'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.image, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*            <div className="type">*/}
            {/*              <Icon type={'ad_spot_carousel'} role={'icon'}/>*/}
            {/*              <span>{acc.formatNumber(productInfo?.chartInfo?.ad_status?.inactive?.types?.carousel, 0, ',', '')}</span>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="diagram">*/}
            {/*      <div className="active" style={{width: `${productInfo?.chartInfo?.ad_status?.active?.percent}%`}} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
      }

      <Pagination sortOrder={sortOrder}
                  skeleton={productInfo?.adsInfo?.adsInfoLoading}
                  setSortOrder={setSortOrder}
                  isMobile={isMobile}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalAds={productInfo?.adsInfo?.count}
                  isTrial={isTrial}
                  isShort={true}
                  disabled={creditsLeft === 0}
      />

      {
        productInfo?.chartInfo?.chartInfoLoading || productInfo?.productInfo?.productInfoLoading || firstLoading ?
          <div className="fadspot-page-items-wrapper">
            {
              [1, 2, 3, 4, 5, 6].map(el => (
                <span key={el} className="fadspot-page-item-wrapper link skeleton"/>
              ))
            }
          </div>
          :
          <Spin spinning={productInfo?.adsInfo?.adsInfoLoading}>
            <div className="fadspot-page-items-wrapper"
                 ref={tableRef}
                 id={'fadspot-page-items-wrapper'}
            >
              {
                productInfo?.adsInfo?.results?.length ?
                  [...productInfo?.adsInfo?.results]?.map((el, index) => (
                    <AdItem key={el?.id + index}
                            data={{...el}}
                            isInnerPage={false}
                            isAlive={el?.status}
                    />
                  ))
                  :
                  null
              }
            </div>
          </Spin>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  userInfo: state.auth.userInfo,
  theme: state?.nav?.theme,
  productInfo: state?.adSpot?.innerPagesInfo?.product,
  innerPagesInfo: state?.adSpot?.innerPagesInfo,
  shopifyStores: state?.shopifyStore?.results,
  autoDS: state?.autoDS,
  creditsLeft: state.adSpot.creditsLeft,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
});

const mapDispatchToProps = (dispatch) => ({
  getProductInfoAdSpot: (data) => dispatch(Creators.getProductInfoAdSpotRequest(data)),
  getOverviewCardInfo: (data) => dispatch(Creators.getOverviewCardInfoRequest(data)),
  getProductChartAdSpot: (data) => dispatch(Creators.getProductChartAdSpotRequest(data)),
  getProductReportAdSpot: (data) => dispatch(Creators.getProductReportAdSpotRequest(data)),
  getProductAdsAdSpot: (data) => dispatch(Creators.getProductAdsAdSpotRequest(data)),
  setInnerPagesDateFilters: (data) => dispatch(Creators.setInnerPagesDateFilters(data)),
  setInnerPagesDateCheckedList: (data) => dispatch(Creators.setInnerPagesDateCheckedList(data)),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdItemProductsDetailed);
