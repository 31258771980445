import React, {useRef, useState} from 'react';
import {Divider, Dropdown, Tag} from "antd";
import Icon from "../../../Icon";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import cls from "classname";
import InputComponent from "../../../Components/Input";
import './InputProductFilter.less';

const InputProductFilter = ({el, isPd=false, onChangeInputFilter, onPasteInputFilter, inputFilters, setInputFilters, type, setCompetitor}) => {
  //input ref for autofocus on wrapper click
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const [list, setList] = useState([]);

  //check if competitor from product database was set
  const competitor = useSelector(store => store.productDatabase.competitor);
  //input local value
  const [value, setValue] = useState('');
  //focus/blur input handler
  const [focused, setFocused] = useState(false);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  //initial value
  const tagData = inputFilters[el.toLowerCase()][type]?.split(',').filter(el => el.length && el !== 'null') || []

  //tag cross icon click handler
  const handleTagClose = tag => {
    let keyOfRemoved = dropdownOptions.find(e => e?.text === tag);
    if (keyOfRemoved) {
      let newList = list.filter(e => e !== keyOfRemoved?.key)
      setList(newList)
    }
    //remove competitor if it was removed manually from input
    if (tag === competitor) setCompetitor(false);
    const newValue = tagData.filter(el => el !== tag).join(',');
    setInputFilters(state => ({ ...state, [el.toLowerCase()]: {...state[el.toLowerCase()], [type]: newValue.length ? newValue : null}}));
  }

  //input focus handler on wrapper click
  const focus = () => inputRef.current && inputRef.current.focus()

  //input change handler
  const handleChange = e => {
    setValue(e.target.value)
  }

  //188 - ','; 13 - 'enter'
  const onKeyUp = e => {
    if (e.key === ',' || e.keyCode === 13) {
      if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
        setValue('')
        return
      }
      if(e.target.value) onChangeInputFilter(e, el.toLowerCase(), type)
      setValue('')
    }
  }

  const doesContain = val => {
    let trimmedVal = val.trim();
    let valArray = trimmedVal.split(',')[0];
    return tagData.includes(trimmedVal) || tagData.includes(valArray.trim());
  }

  //create tag on blur
  const onBlur = (e) => {
      if(e.target.value) {
        if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
          setValue('')
          setFocused(false)
          return
        }
        onChangeInputFilter(e, el.toLowerCase(), type)
      }
      setValue('')
    setFocused(false)
  }

  //remove tag by backspace
  const onKeyDown = e => {
    if (e.keyCode === 8 && !value) {
      if (tagData.length) {
        const newValue = [...tagData];
        let removed = newValue.pop();
        let keyOfRemoved = dropdownOptions.find(e => e?.text === removed);
        if (keyOfRemoved) {
          let newList = list.filter(e => e !== keyOfRemoved?.key)
          setList(newList)
        }
        //remove competitor if it was removed from input
        if (removed === competitor) setCompetitor(false);
        setInputFilters(state => ({ ...state, [el.toLowerCase()]: {...state[el.toLowerCase()], [type]: newValue.length ? newValue.join(',') : null}}));
      }
    }
  }

  const onPaste = e => {
    e.persist();
    setValue('');
    setFocused(false);
    onPasteInputFilter(e, el.toLowerCase(), type);
  }

  let result = `${type === 'include' ? 'Include' : 'Exclude'} ` + el + ' Keywords';

  const dropdownOptions = useSelector(state => state?.productDatabase?.presets?.smart_suggestions?.[0]?.keywords) ||
    [
    {text: '™', key: 'tm', isSup: false},
    {text: 'Portable', key: 'portable', isSup: false},
    {text: 'Adjustable', key: 'adjustable', isSup: false},
    {text: 'Multifunctional', key: 'multifunctional', isSup: false},
    {text: 'Multi', key: 'multi', isSup: false},
    {text: 'Anti', key: 'anti', isSup: false},
    {text: 'Professional', key: 'professional', isSup: false},
    {text: 'Smart', key: 'smart', isSup: false},
    {text: '3 in 1', key: '3_in_1', isSup: false},
    {text: '4 in 1', key: '4_in_1', isSup: false},
  ]

  return (
    type === 'include' && el === 'Title' && isPd ?

      <div className={cls('input-product-filter-wrapper', {
        'disabled-transition': isDisabledTransition
      })}>
        <div className='input-product-filter-title'>
          {t(result)}
        </div>


        <Dropdown
          overlayClassName={'dropdown-input-wrapper'}
          getPopupContainer={(trigger) => trigger.parentNode}
          placement={'bottom'}
          dropdownRender={() => (
            <div className="dropdown-input-menu" style={{ height: 248 }}>
            <span className="dropdown-input-menu-title">
              <Icon type={'energy'} role={'icon'} />
              {t('Smart Suggestions')}
            </span>

              <Divider type={'horizontal'} style={{margin: '10px auto 4px'}} />

              <div className="dropdown-input-menu-items">
                {dropdownOptions.filter(e => !list.includes(e?.key)).map(elem => (
                  <div key={elem?.key}
                       className={'dropdown-input-menu-item'}
                       onClick={() => {
                         let reg = new RegExp(`\b${elem?.text.toLowerCase()}\b`);
                         if (inputFilters[el?.toLowerCase()]?.[type] &&  reg.test(inputFilters[el?.toLowerCase()]?.[type])) {
                           let newData = inputFilters[el?.toLowerCase()]?.[type].split(',').filter(e => e !== elem?.text).join(',')
                           setInputFilters(state => ({ ...state, [el?.toLowerCase()]: { ...state[el?.toLowerCase()], [type]: newData}}));
                           let newList = list.filter(e => e !== elem?.key);
                           setList(newList);
                         }
                         else {
                           setList(prev => [...prev, elem?.key])
                           setInputFilters(state => ({...state,
                             [el?.toLowerCase()]: {
                               ...state[el?.toLowerCase()],
                               [type]: state[el?.toLowerCase()][type] === null ? elem?.text + ',' : state[el?.toLowerCase()][type] + ',' + elem?.text
                             }
                           }));
                         }
                       }}
                  >
                  <span className={'dropdown-input-menu-item-title'}>
                    {
                      elem?.isSup ?
                        <sup>{elem?.text}</sup>
                        :
                        elem?.text
                    }
                  </span>
                    <span className={'dropdown-input-menu-item-button'}
                    >
                    + { t('Add')}
                  </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          trigger={['hover']}
        >
          <ul className='input-product-filter-list' onClick={e => {
          if (e.target === e.currentTarget) focus()
          }}>
          {tagData && tagData.map(item =>
            <li key={item}>
              <Tag closable
                   onClose={() => handleTagClose(item)}
                   closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
              >
                {item}
              </Tag>
            </li>
          )}

          <li style={{width: focused ? value.length + 1 + 'ch' : tagData.length ? 0 : '100%', height: !focused && tagData.length ? 0 : 30}}
          >
            <InputComponent name={el.toLowerCase() + '_' + type}
                            placeholder={tagData.length || focused ? '' : t('Separate search query by commas')}
                            onChange={handleChange}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            onPaste={onPaste}
                            onBlur={onBlur}
                            onFocus={() => setFocused(true)}
                            value={value}
                            ref={inputRef}
                            autoComplete='off'
            />
          </li>
        </ul>
        </Dropdown>
      </div>
      :
      <div className={cls('input-product-filter-wrapper', {
        'disabled-transition': isDisabledTransition
      })}>
        <div className='input-product-filter-title'>
          {t(result)}
        </div>
        <ul className='input-product-filter-list' onClick={e => {
          if (e.target === e.currentTarget) focus()
        }}>
          {tagData && tagData.map(item =>
            <li key={item}>
              <Tag closable
                   onClose={() => handleTagClose(item)}
                   closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
              >
                {item}
              </Tag>
            </li>
          )}

          <li style={{width: focused ? value.length + 1 + 'ch' : tagData.length ? 0 : '100%', height: !focused && tagData.length ? 0 : 30}}
          >
            <InputComponent name={el.toLowerCase() + '_' + type}
                            placeholder={tagData.length || focused ? '' : t('Separate search query by commas')}
                            onChange={handleChange}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            onPaste={onPaste}
                            onBlur={onBlur}
                            onFocus={() => setFocused(true)}
                            value={value}
                            ref={inputRef}
                            autoComplete='off'
            />
          </li>
        </ul>
      </div>)

}

export default InputProductFilter;
