import React from 'react';
import { Carousel } from 'antd';
import './HowItWorks.less';

const cards = [
  {
    icon: (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6 17V10H4V17C4 19.2091 5.79086 21 8 21H16C18.2091 21 20 19.2091 20 17V10H18V17C18 18.1046 17.1046 19 16 19H15V17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17V19H8C6.89543 19 6 18.1046 6 17ZM11 19H13V17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V19Z"
            fill="#225AEA" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.0145 8.90583C15.5491 8.92187 16.0551 9.15142 16.4193 9.54319C16.543 9.67629 16.6856 9.78711 16.8476 9.86479C17.0027 9.93912 17.2104 10.0003 17.5 10.0003C17.8832 10.0003 18.2362 9.81776 18.5231 9.44887C18.8165 9.07172 19 8.5407 19 8.00031C19 6.14206 17.5417 5.00053 16.0005 5.00124C15.5865 5.00144 14.3126 5.00111 12.893 5.00074L12.8896 5.00074C10.8817 5.00022 8.58432 4.99962 8.00118 5.00031C6.45732 5.00213 5 6.14321 5 8.00031C5 8.5407 5.18351 9.07172 5.47685 9.44887C5.76376 9.81776 6.11681 10.0003 6.5 10.0003C6.78962 10.0003 6.9973 9.93911 7.15242 9.86477C7.3145 9.78708 7.45705 9.67623 7.58079 9.54312C7.94496 9.15134 8.45088 8.92176 8.98553 8.90568C9.52018 8.8896 10.039 9.08835 10.4261 9.45752C10.694 9.71309 11.158 10.0002 12.0001 10.0003C12.8422 10.0004 13.3061 9.71338 13.5739 9.45782C13.961 9.08859 14.4798 8.88979 15.0145 8.90583ZM15.0201 10.9737C15.556 11.5211 16.362 12.0003 17.5 12.0003C18.6142 12.0003 19.5119 11.4353 20.1019 10.6768C20.6834 9.92909 21 8.96017 21 8.00031C21 4.86797 18.4686 3.00009 15.9995 3.00124C15.5862 3.00144 14.3118 3.00111 12.8901 3.00074C10.8836 3.00022 8.58385 2.99962 7.99882 3.00031C5.53222 3.00322 3 4.86696 3 8.00031C3 8.96017 3.31663 9.92909 3.89815 10.6768C4.48809 11.4353 5.38576 12.0003 6.5 12.0003C7.63822 12.0003 8.44441 11.5208 8.98026 10.9734C9.00253 10.9506 9.02433 10.9277 9.04567 10.9048C9.06893 10.927 9.09263 10.949 9.11679 10.9709C9.74414 11.5392 10.6774 12.0001 11.9999 12.0003C13.3224 12.0005 14.2557 11.5396 14.8831 10.9713C14.9074 10.9493 14.9311 10.9272 14.9545 10.9049C14.9759 10.928 14.9978 10.9509 15.0201 10.9737Z"
            fill="#225AEA" />
    </svg>),
    title: 'We Setup Your Website And Add 30 Products',
    p1: 'Order your eCommerce Website from us and we\'ll completely set it up for you for only $49.',
    p2: 'Once we design the website, we\'ll add 30 products you can start selling right away.'
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2 7C2 5.34315 3.34315 4 5 4H10C11.6569 4 13 5.34315 13 7V16C13 17.1046 12.1046 18 11 18H8V16H11V7C11 6.44772 10.5523 6 10 6H5C4.44772 6 4 6.44772 4 7V15C4 15.5523 4.44772 16 5 16H6V18H5C3.34315 18 2 16.6569 2 15V7Z"
              fill="#225AEA" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11 7H16.2251C16.9729 7 17.6938 7.27929 18.2464 7.78313L21.0213 10.3132C21.6447 10.8816 22 11.6863 22 12.5301V15C22 16.6569 20.6569 18 19 18H18V16H19C19.5523 16 20 15.5523 20 15V12.5301C20 12.2488 19.8816 11.9806 19.6738 11.7911L16.8989 9.26104C16.7147 9.0931 16.4744 9 16.2251 9H13V16H16V18H11V7Z"
              fill="#225AEA" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17ZM10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17C4 15.3431 5.34315 14 7 14C8.65685 14 10 15.3431 10 17ZM18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17ZM20 17C20 18.6569 18.6569 20 17 20C15.3431 20 14 18.6569 14 17C14 15.3431 15.3431 14 17 14C18.6569 14 20 15.3431 20 17Z"
              fill="#225AEA" />
      </svg>
    ),
    title: 'Supplier Ships Your Orders When You Make Sales',
    p1: 'Let\'s say you had a Makeup brush listed on your Cosmetics website for $39.99',
    p2: 'We will integrate a Supplier that can Ship that exact Makeup brush to anyone in the world for only $9.99.'
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="#225AEA" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C14.6569 7 16 8.34315 16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H12H10.7434C10.3328 9 10 9.33284 10 9.74342C10 10.0634 10.2048 10.3475 10.5083 10.4487L14.1241 11.6539C15.2444 12.0274 16 13.0757 16 14.2566C16 15.7717 14.7717 17 13.2566 17H13C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C9.34315 17 8 15.6569 8 14C8 13.4477 8.44772 13 9 13C9.55228 13 10 13.4477 10 14C10 14.5523 10.4477 15 11 15H12H13.2566C13.6672 15 14 14.6672 14 14.2566C14 13.9366 13.7952 13.6525 13.4917 13.5513L9.87587 12.346C8.75562 11.9726 8 10.9243 8 9.74342C8 8.22827 9.22827 7 10.7434 7L11 7Z"
              fill="#225AEA" />
      </svg>
    ),
    title: 'Keep The Profits And Grow Your Store',
    p1: 'If you do all this correctly, this will be a good platform for a high-returns with just a minimum amount of attention!',
    p2: 'Reinvest back into your business and repeat what\'s working!'
  }
]

const HowItWorks = ({ isMobile }) => {
  return (
    <div className={'how-it-works-wrapper'}>
      <div className="how-it-works-title-wrapper">
        <div className="how-it-works-title">
          <h4>
            How it Works
          </h4>
          <h5>
            Our team of eCommerce experts have designed over 102,000+ dropshipping stores and are here to help you start
            your store next for only.
          </h5>
        </div>
        <div className={'how-it-works-btn'}
             onClick={() => document.getElementById('pick-your-stores-form').scrollIntoView({
               behavior: 'smooth',
             })}
        >
          <span>
            Only $49? Yes, please!
          </span>
        </div>
      </div>
      {
        isMobile ?
          <div className="how-it-works-content">
            <Carousel autoplay={true}>
              {
                cards.map((el, index) => (
                  <div className="how-it-works-card"
                       key={el?.title}
                  >
                    <div className="how-it-works-card-icon">
                      {
                        el?.icon
                      }
                    </div>
                    <div className="how-it-works-card-step">
                      Step {index + 1}
                    </div>
                    <h4 className="how-it-works-card-title">
                      {
                        el?.title
                      }
                    </h4>
                    <p>
                      {
                        el?.p1
                      }
                    </p>
                    <p>
                      {
                        el?.p2
                      }
                    </p>
                  </div>
                ))
              }
            </Carousel>
          </div>
          :
          <div className="how-it-works-content">
            {
              cards.map((el, index) => (
                <div className="how-it-works-card"
                     key={el?.title}
                >
                  <div className="how-it-works-card-icon">
                    {
                      el?.icon
                    }
                  </div>
                  <div className="how-it-works-card-step">
                    Step {index + 1}
                  </div>
                  <h4 className="how-it-works-card-title">
                    {
                      el?.title
                    }
                  </h4>
                  <p>
                    {
                      el?.p1
                    }
                  </p>
                  <p>
                    {
                      el?.p2
                    }
                  </p>
                </div>
              ))
            }
          </div>
      }
    </div>
  );
};

export default HowItWorks;
