import React from 'react';
import cls from 'classname';

const NotificationSuccess = (props) => (
  <svg
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(props.className, 'icon icon-notification-success')}
  >
    <rect width="48" height="48" rx="6" fill="#E7FFF1" />
    <path
      d="M21.7102 23.2905C21.5219 23.1022 21.2665 22.9964 21.0002 22.9964C20.7339 22.9964 20.4785 23.1022 20.2902 23.2905C20.1019 23.4788 19.9961 23.7342 19.9961 24.0005C19.9961 24.2668 20.1019 24.5222 20.2902 24.7105L23.2902 27.7105C23.3836 27.8032 23.4944 27.8765 23.6163 27.9263C23.7381 27.976 23.8686 28.0012 24.0002 28.0005C24.1371 27.9961 24.2716 27.9637 24.3955 27.9052C24.5193 27.8467 24.6299 27.7634 24.7202 27.6605L31.7202 19.6605C31.8818 19.4598 31.9597 19.2045 31.9377 18.9478C31.9157 18.691 31.7955 18.4527 31.602 18.2825C31.4086 18.1123 31.1569 18.0233 30.8995 18.0341C30.642 18.0449 30.3987 18.1546 30.2202 18.3405L24.0002 25.5405L21.7102 23.2905Z"
      fill="#16895A"
    />
    <path
      d="M33.0008 23C32.7356 23 32.4812 23.1054 32.2937 23.2929C32.1061 23.4804 32.0008 23.7348 32.0008 24C32.0008 26.1217 31.1579 28.1566 29.6576 29.6569C28.1574 31.1571 26.1225 32 24.0008 32C22.4209 31.9993 20.8766 31.5308 19.5626 30.6535C18.2486 29.7763 17.2237 28.5297 16.6173 27.0708C16.0108 25.6119 15.8499 24.0061 16.1549 22.4559C16.4598 20.9057 17.217 19.4805 18.3308 18.36C19.0715 17.6094 19.9545 17.014 20.928 16.6088C21.9016 16.2036 22.9462 15.9966 24.0008 16C24.6402 16.004 25.2773 16.0778 25.9008 16.22C26.0313 16.2604 26.1686 16.2736 26.3044 16.2588C26.4401 16.244 26.5714 16.2016 26.6902 16.1342C26.8089 16.0667 26.9126 15.9757 26.9948 15.8666C27.077 15.7576 27.136 15.6328 27.1682 15.5001C27.2003 15.3674 27.205 15.2295 27.1818 15.0949C27.1586 14.9603 27.1081 14.8319 27.0334 14.7176C26.9587 14.6033 26.8613 14.5055 26.7474 14.4302C26.6334 14.355 26.5053 14.3038 26.3708 14.28C25.5939 14.0972 24.7988 14.0033 24.0008 14C22.0251 14.0103 20.0968 14.6055 18.4591 15.7107C16.8214 16.8158 15.5478 18.3813 14.7989 20.2095C14.0501 22.0378 13.8595 24.0469 14.2514 25.9834C14.6433 27.9198 15.6 29.6968 17.0008 31.09C18.8575 32.9475 21.3744 33.9939 24.0008 34C26.653 34 29.1965 32.9464 31.0719 31.0711C32.9472 29.1957 34.0008 26.6522 34.0008 24C34.0008 23.7348 33.8954 23.4804 33.7079 23.2929C33.5204 23.1054 33.266 23 33.0008 23Z"
      fill="#16895A"
    />
  </svg>
);

export default NotificationSuccess;
