import React, { useEffect, useRef } from 'react';
import cls from 'classname';
import { Row, Col, Spin } from 'antd';
import Search from '../../../Components/Search';
import utils from '../../../Utils/utils';
import './style-modal.less';

export default function ChooseIconModal({
  handleSearch,
  icons,
  getBdIconsNext,
  fetching,
  next,
  searchIcon,
  iconsBtn,
  onSelectIcon,
  selectedId,
}) {
  const iconsRef = useRef();

  useEffect(() => {
    const bdContent = iconsRef?.current;

    if (bdContent) {
      bdContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      bdContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next, fetching]);

  useEffect(() => {
    scrollToTop();
  }, [searchIcon]);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if ((currentTopScroll >= maxTopScroll*0.95) && !fetching && !!next) {
      getBdIconsNext(next);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  function scrollToTop() {
    const bdContent = iconsRef?.current;
    if (bdContent) {
      bdContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className="choose-icon-modal">
      <Search placeholder="Search..." handleSearch={handleSearch} />
      <Spin spinning={fetching} size="small">
        <div className="bd-icons-list" ref={iconsRef}>
          <Row
            gutter={[
              { xs: 11, sm: 11 },
              { xs: 11, sm: 11 },
            ]}
            className="bd-icons-wrapper"
          >
            {icons?.map((icon) => (
              <Col key={icon?.id} xs={8} sm={8}>
                <div
                  className={cls('bd-icon-card', {
                    drawback: iconsBtn === 'drawbacks',
                    benefit: iconsBtn === 'benefits',
                    used: icon?.is_used,
                    selected: icon?.id === selectedId,
                  })}
                  disabled={icon?.is_used}
                  onClick={() => (!icon?.is_used ? onSelectIcon(icon) : null)}
                >
                  <div
                    className={cls('bd-icon-img', {
                      drawback: iconsBtn === 'drawbacks',
                      benefit: iconsBtn === 'benefits',
                      used: icon?.is_used,
                    })}
                    dangerouslySetInnerHTML={{ __html: icon?.content }}
                  />
                  <span
                    className={cls('bd-icon-text', {
                      used: icon?.is_used,
                    })}
                  >
                    {utils.toUpperLatter(icon?.title || '')}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
    </div>
  );
}
