import React from 'react';
import { Row, Col, Card } from 'antd';
import './styles.less';

export default function NotificationsSceleton() {
  return (
    <div className="notifications-subpage skeleton-page">
      <Card className="notifications-card">
        <div className="notifications-title"/>
        <Row
          gutter={[
            { xs: 0, md: 48 },
            { xs: 24, sm: 24, md: 32 },
          ]}
        >
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-1"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-1"/>
            <div className="notifications-checkbox-subtitle add-subtitle-1"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-2"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-2"/>
            <div className="notifications-checkbox-subtitle add-subtitle-2"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-3"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-3"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-4"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-4"/>
          </Col>

          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-5"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-5"/>
          </Col>

          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-6"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-6"/>
            <div className="notifications-checkbox-subtitle add-subtitle-6"/>
          </Col>
        </Row>
      </Card>
      <Card className="notifications-card">
        <div className="notifications-title"/>
        <Row
          gutter={[
            { xs: 0, md: 48 },
            { xs: 24, sm: 24, md: 32 },
          ]}
        >
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-1"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-1"/>
            <div className="notifications-checkbox-subtitle add-subtitle-1"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-2"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-2"/>
            <div className="notifications-checkbox-subtitle add-subtitle-2"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-3"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-3"/>
          </Col>
          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-4"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-4"/>
          </Col>

          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-5"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-5"/>
          </Col>

          <Col xs={24} md={12}>
            <div className="notifications-checkbox"/>
            <div className="notifications-checkbox-title checkbox-title-6"/>
            <div className="notifications-checkbox-subtitle checkbox-subtitle-6"/>
            <div className="notifications-checkbox-subtitle add-subtitle-6"/>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
