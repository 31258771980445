import React from 'react';
import NotificationClose from "../../Icon/img/NotificationClose";
import {Warning} from "../../Icon/img";

const ConfirmEmail = ({setVisible, isMobile}) => {
  return (
    <div className={'confirm-email-banner'}>
      {
        isMobile ?
          <span>
            <Warning color={'#F6B60E'}/>
            <a href="/setting/account">Verify your e-mail </a> to secure your account.
          </span>
          :
          <span>
            <Warning color={'#F6B60E'}/>
            Please <a href="/setting/account">verify your e-mail </a> today to secure your account.
          </span>
      }
      <span onClick={() => setVisible(false)}>
        <NotificationClose />
      </span>
    </div>
  );
};

export default ConfirmEmail;
