import React, {useState} from 'react';
import {Divider} from "antd";
import Icon from "../../../Icon";
import './SupplierCard.less';
import { useTranslation } from 'react-i18next';

const SupplierCard = ({info}) => {

  const {
    name,
    is_verified,
    avatar,
    job_position,
    description,
    skype,
    years_in_business,
    location,
    orders_shipped,
    transaction_volume,
    shipping_time,
    order_processing_time,
    staff,
    warehouses,
    floorspace,
    on_time_delivery_rate,
    ships_to,
  } = info;

  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisible(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    setVisible(false);
    target.classList.remove('overflowing')
  }

  return (
    <div className='suppliers-page-card-wrapper'>
      <div className="suppliers-page-card-bio">
        <div className="suppliers-page-card-image"
             style={{backgroundImage: `url("${avatar}")`}}
        />
        <div className="suppliers-page-card-name-wrapper"
             style={{position: visible ? 'relative' : 'static'}}
        >
          <h3 className="suppliers-page-card-name">
            <span>{name}</span>
            {is_verified && <Icon type={'suppliers_verified'} role={'icon'} />}
          </h3>
          <p data-text={job_position} className="suppliers-page-card-job"
             onMouseOver={mouseOver}
             onMouseLeave={mouseLeave}
          >
            {job_position}
          </p>
        </div>
      </div>

      <Divider type={'horizontal'} className={'suppliers-page-card-divider'} />

      <p className="suppliers-page-card-description">
        {description}
      </p>
      <a target={'_blank'} href={`skype:${skype}?chat`} className="suppliers-page-card-button">
          <span>
            {
              t('Chat On Skype')
            }
          </span>
          <Icon role={'icon'} type={'skype'} />
      </a>
      <div className="suppliers-page-card-info-wrapper">
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Years In Business')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {years_in_business}
            </p>
          </div>
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Supplier Location')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {location}
            </p>
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Orders Shipped')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {orders_shipped}
            </p>
          </div>
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Transaction Volume')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {transaction_volume}
            </p>
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Shipping Time')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {shipping_time}
            </p>
          </div>
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Order Processing Time')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {order_processing_time}
            </p>
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Staff')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {staff}
            </p>
          </div>
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Warehouses')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {warehouses}
            </p>
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('Floorspace')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {floorspace}m<sup>2</sup>
            </p>
          </div>
          <div className="suppliers-page-card-info">
            <p className="suppliers-page-card-info-title">
              {
                t('On-Time Delivery Rate')
              }
            </p>
            <p className="suppliers-page-card-info-value">
              {on_time_delivery_rate}
            </p>
          </div>
        </div>
      </div>

      <Divider type={'horizontal'} className={'suppliers-page-card-divider'} />

      <div className="suppliers-page-card-country-wrapper">
        <p className="suppliers-page-card-info-title">
          {
            t('Ships From')
          }
        </p>
        <div className="suppliers-page-card-country-info-wrapper">
        {
          ships_to.map(el => (
            <div className="suppliers-page-card-country-info" key={el?.city}>
              <div className="suppliers-page-card-country">
                <div className="suppliers-page-card-country-image"
                     style={{backgroundImage: `url("${el?.flag}")`}}
                />
                <span>{el?.country_code}</span>
              </div>
              <p className="suppliers-page-card-city">
                <span>{el?.city} {el?.state && `(${el?.state})`} {el?.warehouses_count && `(${el?.warehouses_count} ${el?.warehouses_count === 1 ? 'Warehouse' : 'Warehouses'})`}</span>
              </p>
            </div>
          ))
        }
        </div>
      </div>
    </div>
  );
};

export default SupplierCard;
