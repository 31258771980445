import React from 'react';

const AdSpotHaha = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M22.2858 11.9996C22.2858 14.7275 21.2021 17.3438 19.2732 19.2727C17.3443 21.2016 14.728 22.2853 12.0001 22.2853C9.27213 22.2853 6.65592 21.2016 4.72698 19.2727C2.79803 17.3438 1.71436 14.7275 1.71436 11.9996C1.71436 9.27164 2.79803 6.65543 4.72698 4.72649C6.65592 2.79754 9.27213 1.71387 12.0001 1.71387C14.728 1.71387 17.3443 2.79754 19.2732 4.72649C21.2021 6.65543 22.2858 9.27164 22.2858 11.9996Z" fill={`url(#paint0_linear_36566_241383_${id})`}/>
      <path d="M5.57129 12.0099C5.57129 14.6006 6.86472 19.7139 11.9999 19.7139C17.1337 19.7139 18.4284 14.6006 18.4284 12.0099C18.4284 11.8054 16.3584 10.7139 11.9999 10.7139C7.64129 10.7139 5.57129 11.8054 5.57129 12.0099Z" fill={`url(#paint1_linear_36566_241383_${id})`}/>
      <path d="M7.55371 17.786C8.58743 19.0653 10.0056 19.7146 12.0139 19.7146C14.0234 19.7146 15.4274 19.0653 16.4611 17.786C15.7527 17.03 14.4053 15.8574 12.0139 15.8574C9.62243 15.8574 8.26343 17.03 7.55371 17.786Z" fill={`url(#paint2_linear_36566_241383_${id})`}/>
      <path d="M9.70225 7.04187C10.0404 7.28358 10.3477 7.62687 10.2294 8.05501C10.1381 8.38158 9.97996 8.52944 9.68682 8.53201C8.68396 8.54615 7.49082 8.85987 6.46482 9.31887C6.38125 9.35615 6.21154 9.43201 6.03796 9.42815C5.87854 9.4243 5.71525 9.35358 5.61754 9.12344C5.53139 8.92158 5.52368 8.62458 5.97239 8.35458C6.67311 7.9303 7.58854 7.73744 8.42682 7.57801C7.90041 7.18902 7.33355 6.85796 6.73611 6.59058C6.18711 6.34115 6.24754 6.00301 6.32082 5.81915C6.48411 5.41158 7.10382 5.56715 7.70682 5.85258C8.41017 6.18108 9.07868 6.57951 9.70225 7.04187ZM14.3013 7.04187C14.9238 6.57932 15.5915 6.18086 16.2941 5.85258C16.8984 5.56715 17.5156 5.41158 17.6801 5.81915C17.7534 6.00301 17.8139 6.34115 17.2649 6.59058C16.668 6.85769 16.1021 7.18877 15.5767 7.57801C16.4124 7.73615 17.3291 7.9303 18.0286 8.35458C18.4773 8.62458 18.4683 8.9203 18.3834 9.12344C18.2844 9.35487 18.1224 9.4243 17.963 9.42815C17.7894 9.43201 17.6197 9.35615 17.5361 9.31887C16.5114 8.85987 15.3183 8.54744 14.3167 8.53201C14.0235 8.52944 13.8654 8.38158 13.7741 8.0563C13.6571 7.62815 13.9631 7.28487 14.3013 7.04315V7.04187Z" fill="#2A3755"/>
      <defs>
        <linearGradient id={`paint0_linear_36566_241383_${id}`} x1="12.0001" y1="3.82244" x2="12.0001" y2="22.2853" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEEA70"/>
          <stop offset="1" stopColor="#F69B30"/>
        </linearGradient>
        <linearGradient id={`paint1_linear_36566_241383_${id}`} x1="11.9999" y1="10.7139" x2="11.9999" y2="19.7139" gradientUnits="userSpaceOnUse">
          <stop stopColor="#472315"/>
          <stop offset="1" stopColor="#8B3A0E"/>
        </linearGradient>
        <linearGradient id={`paint2_linear_36566_241383_${id}`} x1="12.0074" y1="15.8574" x2="12.0074" y2="19.0164" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC607C"/>
          <stop offset="1" stopColor="#D91F3A"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotHaha;
