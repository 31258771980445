import React from 'react';
import Icon from "../../../Icon";
import {Divider} from "antd";
import cls from "classname";
import {useNavigate} from "react-router";
import './NavHeader.less';
import {useTranslation} from "react-i18next";

const NavHeader = ({active, setActive, isMobile}) => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div className='st-nav-header'>
      <div className="st-nav-header-back"
           onClick={() => navigate('/sales-tracker/stores')}
      >
        <Icon width={10} height={10} type="arrow_forward_university" role="icon"/>
        {t('Back')}
      </div>
      <Divider type={'vertical'} style={{margin: '0 16px 0 12px', height: 32}}/>
      <div className="st-nav-header-btn-wrapper">
        <div className={cls("st-nav-header-btn", {
          active: active === 'overview'
        })}
             onClick={() => setActive('overview')}>
          {t('Overview')}
        </div>
        <div className={cls("st-nav-header-btn", {
          active: active === 'products'
        })}
             onClick={() => setActive('products')}
        >
          {t('Products')}
        </div>
        {
          isMobile ?
            null
            :
            <div className="st-nav-header-btn disabled">
              {t('Store Analysis')} <span>{t('SOON')}</span>
            </div>
        }
      </div>
    </div>
  );
};

export default NavHeader;
