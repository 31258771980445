import React, { useState } from 'react';
import cls from 'classname';
import ImageGallery from 'react-image-gallery';
import './ImagesBlock.less';

export const ImagesBlock = ({ skeleton, images }) => {

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const imagesList = images?.map(el => ({
    original: el.url,
    thumbnail: el.url,
    thumbnailHeight: 48,
    originalClass: 'custom-original-img',
    thumbnailClass: 'custom-thumbnail-img',
  }));

  const renderSkeleton = (
    <>
      <div className="image skeleton"/>
      <div className="image-gallery-thumbnails">
        {
          [1, 2, 3, 4, 6, 7, 8].map(el => (
            <div key={el} className="image-thumbnail-image skeleton"/>
          ))
        }
      </div>
    </>
  );

  return (
    <div className={cls('product-details-images-block', {
      withBefore: images?.length > 6 && activeImageIndex !== 0,
      withAfter: skeleton || (images?.length > 6 && (activeImageIndex + 1) !== images?.length),
    })}>
      {
        skeleton
          ? renderSkeleton
          : <ImageGallery
            items={imagesList}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            onSlide={(currentIndex) => setActiveImageIndex(currentIndex)}
          />
      }
    </div>
  );
};

export default ImagesBlock;
