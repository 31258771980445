import React from 'react';
import { NavLink } from 'react-router-dom';
import cls from 'classname';
import Icon from '../../Icon';
import ArrowSelectDown from "../../Icon/img/ArrowSelectDown";
import {Collapse} from "antd";

const {Panel} = Collapse;

export const NavLinkWithIcon = ({
  url,
  iconType,
  text,
  active,
  theme,
  isOpened,
  isSoon,
  markText,
  markTextBordered,
  openSidebar,
  location,
  isDropdown,
  options,
  activeKey,
  setActiveKey,
  flag,
  t,
  ...props
}) => (
  isDropdown ?
    <CollapsibleNavLink url={url}
                        iconType={iconType}
                        text={text}
                        active={active}
                        theme={theme}
                        isOpened={isOpened}
                        isSoon={isSoon}
                        markTextBordered={markTextBordered}
                        openSidebar={openSidebar}
                        location={location}
                        options={options}
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                        flag={flag}
                        t={t}
    />
    :
    <NavLink
      to={url}
      onClick={(e) => {
        setActiveKey([]);
        if (isSoon) {
          return (e.preventDefault())
        } else {
          if (openSidebar)
            openSidebar()
          return (e)
        }
      }}
      className={cls('sidebar-link', {
        disabled: isSoon,
        selected: (url === '/sales-tracker/stores' && location?.includes('sales-tracker')) || (url === '/setting/account' && location?.includes('setting'))
      })}
    >
      <Icon
        className="sidebar-link_icon"
        type={iconType}
        role="icon"
        outline={active}
        theme={theme}
        {...props}
      />
      <span
        className={cls('sidebar-link_text', {
          'is-open': !isOpened,
          'is-closed': isOpened,
        })}
      >
      {text}
        {!!markText ? (
          <span className={cls("sidebar-link_mark", {
            'mark-text-bordered': markTextBordered
          })}>{markText}</span>
        ) : null}
    </span>
    </NavLink>
);

const CollapsibleNavLink = (
  {
    url,
    iconType,
    text,
    active,
    theme,
    isOpened,
    isSoon,
    markTextBordered,
    openSidebar,
    location,
    options,
    activeKey,
    setActiveKey,
    flag,
    t
  }
) => {

  return (
    <Collapse collapsible={'header'}
              ghost={true}
              activeKey={activeKey}
    >
      <Panel key={flag} showArrow={false} header={
        <NavLink
          to={url}
          onClick={(e) => {
            setActiveKey([`${flag}`]);
            if (isSoon) {
              return (e.preventDefault())
            } else {
              return (e)
            }
          }}
          className={cls('sidebar-link sidebar-dropdown-link-main', {
            disabled: isSoon,
            'sidebar-dropdown-link-main-selected': options?.some(option => location?.includes(option?.url)) || location.includes(url)
          })}
        >
          <Icon
            className="sidebar-link_icon"
            type={iconType}
            role="icon"
            outline={active}
            theme={theme}
          />
          <span
            className={cls('sidebar-link_text', {
              'is-open': !isOpened,
              'is-closed': isOpened,
            })}
          >
      {t(text)}
            <ArrowSelectDown />
    </span>
        </NavLink>
      }>
        {
          options.map(el => (
            <NavLink
              to={el?.url}
              key={el?.url}
              onClick={(e) => {
                if (el?.isSoon) {
                  return (e.preventDefault())
                } else {
                  if (openSidebar)
                    openSidebar()
                  return (e)
                }
              }}
              className={cls('sidebar-link sidebar-dropdown-link', {
                disabled: isSoon,
                active: el?.key === 'general' && flag === 'salesTracker' ? (location.includes('sales-tracker/stores') || location.includes('sales-tracker/products')) : location === el?.url
              })}
            >
              <span className={cls('sidebar-link_text')}>
      {t(el?.text)}
                {!!el?.markText ? (
                  <span className={cls("sidebar-link_mark", {
                    'mark-text-bordered': markTextBordered
                  })}>{t(el?.markText)}</span>
                ) : null}
    </span>
            </NavLink>
          ))
        }
      </Panel>
    </Collapse>
  )
}

export const NavLinkOnlyIcon = ({
  url,
  iconType,
  active,
  theme,
  isOpened,
  ...props
}) => (
  <NavLink to={url} className={({ isActive }) => isActive ? 'sidebar-link selected' : 'sidebar-link'}>
    <div className="sidebar-link_wrap">
      <Icon
        className="sidebar-link_icon"
        type={iconType}
        role="icon"
        outline={active}
        theme={theme}
        {...props}
      />
    </div>
  </NavLink>
);

export const navIconsArr = {
  demo: [
    {
      url: '/dashboard',
      iconMob: 'dashboard_mobile',
      iconDesk: 'home',
      text: 'sidebar.dashboard',
    },
    {
      url: '/portfolio',
      iconMob: 'portfolio_mobile',
      iconDesk: 'portfolio',
      text: 'sidebar.portfolio',
    },
    {
      url: '/collections',
      iconMob: 'collection_mobile',
      iconDesk: 'folder',
      text: 'sidebar.collections',
      withDivider: true,
    },
    {
      url: '/suppliers',
      iconMob: 'suppliers',
      iconDesk: 'suppliers',
      text: 'sidebar.suppliers',
    },
  ],
  desktop: [
    {
      url: '/dashboard',
      iconMob: 'dashboard_mobile',
      iconDesk: 'home',
      text: 'sidebar.dashboard',
    },
    {
      url: '/ad-spot',
      iconMob: 'speaker',
      iconDesk: 'speaker',
      text: 'Ad Spot',
      withDivider: true
    },
    {
      url: '/product-database',
      iconMob: 'database_mobile',
      iconDesk: 'database',
      text: 'Product Database',
    },
    {
      url: '/sales-tracker/stores',
      iconMob: 'database',
      iconDesk: 'gps',
      text: 'Sales Tracker',
      comingSoon: false,
      flag: 'salesTracker',
      dropdown: true,
      options: [
        {text: 'My Trackings', url: '/sales-tracker/stores', key: 'general'},
        {text: 'Top Stores', url: '/sales-tracker/top/stores', markText: null},
        {text: 'Top Products', url: '/sales-tracker/top/products', markText: null}
      ]
    },
    {
      url: '/competitor-research',
      iconMob: 'competitor_mobile',
      iconDesk: 'competitor',
      text: 'sidebar.competitor_research',
      comingSoon: false,
      withDivider: false,
    },
    {
      url: '/portfolio',
      iconMob: 'database',
      iconDesk: 'portfolio',
      text: 'Portfolio',
      comingSoon: false,
      flag: 'portfolio',
      markTextBordered: true,
      withDivider: true,
      dropdown: true,
      options: [
        {text: 'My Portfolio', url: '/portfolio', key: 'general'},
        {text: 'Collections', url: '/collections', markText: null},
      ]
    },
    {
      url: '/dropship-university',
      iconMob: 'university',
      iconDesk: 'university',
      text: 'sidebar.university',
    },
    {
      url: '/suppliers',
      iconMob: 'suppliers',
      iconDesk: 'suppliers',
      text: 'sidebar.suppliers',
    },
  ],
  mobile: [
    {
      url: '/dashboard',
      iconMob: 'dashboard_mobile',
      iconDesk: 'home',
      text: 'sidebar.dashboard',
    },
    {
      url: '/portfolio',
      iconMob: 'portfolio_mobile',
      iconDesk: 'portfolio',
      text: 'sidebar.portfolio',
    },
    {
      url: '/collections',
      iconMob: 'collection_mobile',
      iconDesk: 'folder',
      text: 'sidebar.collections',
    },
    {
      url: '/dropship-university',
      iconMob: 'university',
      iconDesk: 'university',
      text: 'sidebar.university',
    },
    {
      url: '/suppliers',
      iconMob: 'suppliers',
      iconDesk: 'suppliers',
      text: 'sidebar.suppliers',
    },
   /* {
      url: '/upcoming-drops',
      iconMob: 'upcoming_drops_mobile',
      iconDesk: 'calendar',
      text: 'sidebar.upcoming_drops',
    },*/
    {
      url: '/competitor-research',
      iconMob: 'competitor_mobile',
      iconDesk: 'competitor',
      text: 'sidebar.competitor_research',
      comingSoon: false,
    },
    {
      url: '/setting/account',
      iconMob: 'settings_mobile',
      iconDesk: 'settings',
      text: 'sidebar.settings',
    },
  ],
};

export const navIconsAdmin = [
  {
    url: '/admin/portfolio',
    iconMob: 'portfolio_mobile',
    iconDesk: 'portfolio',
    text: 'Portfolio',
  },
  {
    url: '/admin/database',
    iconMob: 'database_mobile',
    iconDesk: 'database',
    text: 'Database',
    comingSoon: true,
    markText: 'soon',
  },
  {
    url: '/admin/droptimes',
    iconMob: 'upcoming_drops_mobile',
    iconDesk: 'calendar',
    text: 'Drop Times',
  },
  {
    url: '/admin/bd',
    iconMob: 'b_d_mobile',
    iconDesk: 'b_d',
    text: 'Benefits & Drawbacks',
  },
  {
    url: '/admin/shopify',
    iconMob: 'ali_drops_mobile',
    iconDesk: 'ali_drops',
    text: 'Shopify',
  },
  {
    url: '/admin/store-manager',
    iconMob: 'settings_mobile',
    iconDesk: 'settings',
    text: 'Store Manager',
  },
  {
    url: '/admin/store-table',
    iconMob: 'database',
    iconDesk: 'gps',
    text: 'Store Table',
  },
];
