import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getAdminTrackingStores = (data) => request.api.post('/admin-panel/sales_tracker/stores/', encryptedData(data));

export const getAdminDetailedStoreInfo =  (data) => request.api.post(`/sales_tracker/stores/${data.id}/detailed_charts/`, encryptedData({date_range: data.date_range}));

export const getAdminDetailedStoreInfoCheck =  (data) => request.api.post(`/sales_tracker/stores/${data.id}/statistic_exists/`, encryptedData({date_range: data.date_range}));

export const getAdminProductListByStore =  (data) => request.api.post(`/sales_tracker/products/store/${data.store_id}/`,
  encryptedData({page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: data.date_range}));
export const getAdminExpandableChart = (data) => request.api.post(`/sales_tracker/products/chart/${data.store_id}/${data.product_id}/`, encryptedData({date_range: data.date_range}));

export const getAdminSalesReport = (data) => request.api.post(`/admin-panel/sales_tracker/stores/${data.id}/store_report/`, encryptedData({date_range: data.date_range}), {responseType: 'blob'});
export const getAdminRevenueReport = (data) => request.api.post(`/admin-panel/sales_tracker/stores/${data.id}/download_report/`, encryptedData({date_range: data.date_range}), {responseType: 'blob'});
