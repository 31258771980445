import React from 'react';
import { InputNumber } from 'antd';
import { categoryClassName } from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Images from '../../../Images';

export default function DropModal({ timerData, onChange, setTimer, timer }) {
  const { name, title, category_timer, drop_id } = timerData;

  const hour = dayjs(category_timer?.end).format('HH');
  const day = dayjs(category_timer?.end).format('DD');
  const month = dayjs(category_timer?.end).format('MM');
  const year = dayjs(category_timer?.end).format('YYYY');

  return (
    <div className="drop-modal-wrapper">
      <div className="drop-modal-info">
        <div className="drop-modal-image">
          <img src={Images[`${name}Full`]} alt="" />
        </div>
        <div className="modal-title-wrapper">
          <div
            className={cls('modal-title', categoryClassName[name])}
          >
            {title || ''}
          </div>
          <div className="timer-block-next">
            <div>
              <div>Next Drop</div>
              <div>
                {category_timer?.end
                  ? dayjs(category_timer?.end).format('HH:mm DD.MM.YYYY')
                  : '-'}
              </div>
            </div>
            <div>
              <div>Drop ID</div>
              <div>{drop_id ? drop_id : '-'}</div>
            </div>
          </div>
        </div>
        <div className="modal-timer-block" />
      </div>
      <div className="drop-modal-timer">
        <div className="timer-col">
          <div className="timer-text">Time</div>
          <InputNumber
            className="timer-count"
            defaultValue={hour}
            onChange={(e) => setTimer({ ...timer, hour: e })}
            formatter={(value) => `${value}:00`}
            parser={(value) => value.replace(':00', '')}
            min={1}
            max={23}
          />
        </div>
        <div className="timer-col">
          <div className="timer-text">Day</div>
          <InputNumber
            className="timer-count"
            defaultValue={day}
            onChange={(e) => setTimer({ ...timer, day: e })}
            min={1}
            max={31}
          />
        </div>
        <div className="timer-col">
          <div className="timer-text">Month</div>
          <InputNumber
            className="timer-count"
            defaultValue={month}
            value={timer.month}
            onChange={(e) => setTimer({ ...timer, month: e })}
            min={1}
            max={12}
          />
        </div>
        <div className="timer-col">
          <div className="timer-text">Year</div>
          <InputNumber
            className="timer-count"
            defaultValue={year}
            value={timer.year}
            onChange={(e) => setTimer({ ...timer, year: e })}
            min={year}
            max={2050}
          />
        </div>
      </div>
    </div>
  );
}
