import React, { useEffect, useRef } from 'react';
import { Table, Checkbox } from 'antd';
import EmptyData from '../EmptyData';
import cls from 'classname';
import { interestTargetingColumns } from './Columns';

export default function InterestTargetingTable({
  interests,
  onSort,
  fetching,
  onChange,
  searchText,
  next,
  getInterestTargetNext,
  approvedIds,
}) {
  const tableRef = useRef();
  const dataSource = interests?.length
    ? interests?.map((u) => ({ ...u, key: u.id }))
    : [];

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= (maxTopScroll*0.95) && !fetching && !!next) {
      getInterestTargetNext(next);

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;

    if (tableContent) {
      tableContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [searchText]);

  return (
    <>
      {interests?.length ? (
        <div
          className={cls('products-details', 'interest-products', {
            'products-details--empty': dataSource.length === 0,
          })}
        >
          <Checkbox.Group
            defaultValue={approvedIds}
          >
            <Table
              components={{
                // eslint-disable-next-line react/prop-types
                table: ({ className, children, ...other }) => (
                  <table
                    {...other}
                    ref={tableRef}
                    className={cls(className, 'other-wrapper')}
                  >
                    {children}
                  </table>
                ),
              }}
              className="list-table"
              loading={fetching}
              rowClassName="details-row"
              dataSource={dataSource}
              columns={interestTargetingColumns({onChangeInterest: onChange})}
              pagination={false}
              scroll={{ scrollToFirstRowOnChange: true, y: true }}
              sticky={true}
              onChange={(pagination, filters, sorter) => {
                onSort(sorter);
              }}
            />
          </Checkbox.Group>
        </div>
      ) : (
        <EmptyData text="No Interest Targeting Found" />
      )}
    </>
  );
}
