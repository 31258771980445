import React from 'react';
import Skeleton from '../../../Components/Skeleton';
import './skeleton-details.less';

export const ProductDetailsSkeletons = ({ isMobile }) => {

  const productsCount = new Array(7).fill(0);

  return (
    <div className="skeleton skeleton--product-detailed-page">
      <div className="skeleton_block skeleton_block--breadcrumbs">
        <div className="skeleton_row">
          <Skeleton width={64} height={24} borderRadius={6} />
          <Skeleton width={24} height={24} borderRadius={6} />
          <Skeleton width={64} height={24} borderRadius={6} />
        </div>
      </div>
      <div className="skeleton_block skeleton_block--search">
        <div className="skeleton_row">
          <Skeleton width={800} height={48} borderRadius={6} />
          <Skeleton width={223} height={48} borderRadius={6} />
        </div>
      </div>
      <div className="skeleton_block skeleton_block--filter">
        <div className="skeleton_row">
          <div className="skeleton_block skeleton_block--left-part">
            <Skeleton width={64} height={24} borderRadius={6} />
          </div>
          <div className="skeleton_block skeleton_block--middle-part">
            <div className="skeleton_row">
              <Skeleton width={60} height={24} borderRadius={6} />
              <Skeleton width={63} height={24} borderRadius={6} />
              {!isMobile && (
                <>
                  <Skeleton width={96} height={24} borderRadius={6} />
                  <Skeleton width={109} height={24} borderRadius={6} />
                  <Skeleton width={138} height={24} borderRadius={6} />
                </>
              )}
            </div>
          </div>
          {!isMobile && (
            <div className="skeleton_block skeleton_block--right-part">
              <Skeleton width={64} height={32} borderRadius={6} />
            </div>
          )}
        </div>
      </div>
      <div className="skeleton_block skeleton_block--product-detailed">
        {productsCount.map((_, index) => (
          <div key={index} className="skeleton_row">
            <div className="skeleton_block skeleton_block--left-part">
              <div className="skeleton_row">
                {isMobile && (
                  <Skeleton width={24} height={24} borderRadius={6} />
                )}
                <Skeleton width={64} height={64} borderRadius={6} />
                <div className="skeleton_block">
                  <Skeleton width={104} height={20} borderRadius={6} />
                  {isMobile && (
                    <Skeleton width={37} height={18} borderRadius={6} />
                  )}
                </div>
              </div>
            </div>
            <div className="skeleton_block skeleton_block--middle-part">
              <div className="skeleton_row">
                <Skeleton width={50} height={20} borderRadius={6} />
                <Skeleton width={48} height={20} borderRadius={6} />
                {!isMobile && (
                  <>
                    <Skeleton width={40} height={20} borderRadius={6} />
                    <Skeleton width={51} height={20} borderRadius={6} />
                    <Skeleton width={80} height={20} borderRadius={6} />
                  </>
                )}
              </div>
            </div>
            {!isMobile && (
              <div className="skeleton_block skeleton_block--right-part">
                <div className="skeleton_row">
                  <Skeleton width={24} height={24} borderRadius={6} />
                  <Skeleton width={24} height={24} borderRadius={6} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailsSkeletons;
