import React from 'react';

const IncreaseLimit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_34548_66393)">
        <path
          d="M11.9765 11.9959C11.8455 11.9951 11.7156 12.0204 11.5944 12.0701C11.4731 12.1199 11.3628 12.1932 11.2698 12.2859L7.28879 16.2859C7.19599 16.3791 7.12238 16.4898 7.07216 16.6117C7.02194 16.7335 6.99609 16.864 6.99609 16.9959C6.99609 17.1278 7.02194 17.2583 7.07216 17.3802C7.12238 17.502 7.19599 17.6127 7.28879 17.7059C7.38159 17.7991 7.49175 17.8731 7.613 17.9236C7.73424 17.974 7.86419 18 7.99543 18C8.12666 18 8.25661 17.974 8.37785 17.9236C8.4991 17.8731 8.60926 17.7991 8.70206 17.7059L11.9765 14.3959L15.2608 17.5759C15.3524 17.6782 15.4639 17.7604 15.5886 17.8174C15.7132 17.8744 15.8482 17.9049 15.9851 17.9071C16.122 17.9093 16.2578 17.8831 16.3842 17.8301C16.5106 17.7771 16.6247 17.6985 16.7194 17.5991C16.8141 17.4998 16.8875 17.3819 16.9348 17.2528C16.9821 17.1237 17.0024 16.9862 16.9944 16.8489C16.9864 16.7115 16.9503 16.5773 16.8883 16.4547C16.8264 16.332 16.7399 16.2235 16.6343 16.1359L12.6532 12.2759C12.4712 12.0996 12.2293 11.9995 11.9765 11.9959Z"
          fill="#151E3A"/>
        <path
          d="M11.9804 5.99591C11.8494 5.99515 11.7195 6.02037 11.5983 6.07014C11.477 6.1199 11.3667 6.19323 11.2737 6.28591L7.2927 10.2859C7.1999 10.3791 7.12629 10.4898 7.07607 10.6117C7.02585 10.7335 7 10.864 7 10.9959C7 11.1278 7.02585 11.2583 7.07607 11.3802C7.12629 11.502 7.1999 11.6127 7.2927 11.7059C7.38549 11.7991 7.49566 11.8731 7.6169 11.9236C7.73815 11.974 7.8681 12 7.99933 12C8.13057 12 8.26051 11.974 8.38176 11.9236C8.503 11.8731 8.61317 11.7991 8.70597 11.7059L11.9804 8.39591L15.2647 11.5759C15.3563 11.6782 15.4679 11.7604 15.5925 11.8174C15.7171 11.8744 15.8521 11.9049 15.989 11.9071C16.1259 11.9093 16.2618 11.8831 16.3881 11.8301C16.5145 11.7771 16.6286 11.6985 16.7233 11.5991C16.818 11.4998 16.8914 11.3819 16.9387 11.2528C16.986 11.1237 17.0063 10.9862 16.9983 10.8489C16.9903 10.7115 16.9542 10.5773 16.8922 10.4547C16.8303 10.332 16.7438 10.2235 16.6382 10.1359L12.6571 6.27591C12.4751 6.09959 12.2332 5.99948 11.9804 5.99591Z"
          fill="#151E3A"/>
      </g>
      <defs>
        <clipPath id="clip0_34548_66393">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IncreaseLimit;
