import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import cls from 'classname';
import Icon from '../../Icon/Icon';
import {useDispatch} from "react-redux";
import Creators from "../../Containers/AutoDS/reducer";
import ButtonComponent from "../Button";
import Images from "../../Images";
import './styles-shopify.less';

export default function AutoDsStore({ theme, userInfo, autoDs, getAutoStore }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disabledImport = !userInfo?.subscriptions?.[0]?.plan?.price
    && userInfo?.subscriptions?.[0]?.plan?.plan?.name !== 'free'
    && userInfo?.role?.id !== 4 ;

  let isConnected = autoDs?.email || null;

  const shopifyStatus = isConnected ? 'connected' : 'not_connected';
  const shopifyTags = {
    coming: t('COMING SOON'),
    not_connected: t('Not Connected'),
    connected: t('Connected'),
  };

  useEffect(() => {
    getAutoStore();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const shopifyIcons = {
    coming: (
      // <img src={Images[theme === 'dark' ? 'autoConnectDark' : 'autoConnect']}
      //      alt=""
      // />
      <Icon type={'auto_ds'} role={'icon'} />
    ),
    not_connected: (
      // <img src={Images[theme === 'dark' ? 'autoConnectDark' : 'autoConnect']}
      //      alt=""
      // />
      <Icon type={'auto_ds'} role={'icon'} />
    ),
    connected: (
      <Icon type={'auto_ds'} role={'icon'} />
    ),
  };

  const handleDisconnect = () => {
    dispatch(Creators.changeVisibleModalAuto({ isVisibleModal: 'disconnect', importProductId: null }))
  }

  const shopifyComponent = {
    coming: (
      <div className="input-store-wrapper">

        <ButtonComponent
          type="primary"
          className='btn-store btn-store-auto disabled'
        >
          {t('Connect')}
        </ButtonComponent>

      </div>),
    not_connected: (
      <div className="input-store-wrapper">

        <ButtonComponent
          type="primary"
          className={cls('btn-store btn-store-auto', {
            disabled: disabledImport
          })}
          disabled={disabledImport}
          onClick={() => dispatch(Creators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId: null }))}
        >
          {t('Connect')}
        </ButtonComponent>

      </div>),
    connected: (
      <div className="input-store-wrapper">
        <ButtonComponent
          type="primary"
          className={cls('btn-store btn-store-auto', {
            disabled: disabledImport
          })}
          disabled={disabledImport}
          onClick={handleDisconnect}
        >
          {t('Disconnect')}
        </ButtonComponent>
      </div>
    ),
  }

  return (
    <Card className="account-shopify account-auto">
      <div className="account-shopify-wrap">
        <div className="account-shopify-info-block">
          <div className="shopify-header">
            <span className="account-title">
              AutoDS
              <span
                className={cls('shopify-tag', {
                  coming: shopifyStatus === 'coming',
                  not_connected: shopifyStatus === 'not_connected',
                  connected: shopifyStatus === 'connected',
                })}
              >
              {shopifyTags[shopifyStatus]}
            </span>
            </span>
          </div>
          <div
            className={cls('shopify-subtitle', {
              coming: shopifyStatus === 'coming',
            })}
          >
            {
              shopifyStatus === 'connected'
                ? t('Your AutoDS account (_name_) is currently connected to Dropship.', {name: autoDs?.email})
                : t('Connect your AutoDS account to start importing products in just a few clicks.')
            }
          </div>
        </div>
        <div className="shopify-icons">
          {shopifyIcons[disabledImport ? 'coming' : shopifyStatus]}
        </div>
      </div>
      {
        shopifyComponent[shopifyStatus]
      }
    </Card>
  );
}
