import React from 'react';
import { Card } from 'antd';
import cls from 'classname';
import Icon from '../../../Icon';
import './styles-bd.less';

export default function BDCard({
  id,
  title,
  description,
  _type,
  icon,
  onChange,
}) {
  return (
    <Card className="bd-card">
      <div className="bd-icons-block">
        <div
          className={cls('benefit-drawback-img', {
            drawback: _type === 'Drawbacks',
            benefit: _type === 'Benefits',
            used: icon?.is_used,
          })}
          dangerouslySetInnerHTML={{ __html: icon?.content }}
        />
        <Icon
          role="button"
          type="edit_pensil"
          onClick={() => onChange({ id, icon, title, _type, description })}
        />
      </div>
      <div className="bd-card-title">{title}</div>
      <div className="bd-card-subtitle">{description}</div>
    </Card>
  );
}
