import React from 'react';

const Energy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
    >
      <path
        d="M7.24542 4.96635C8.47046 3.72322 10.1535 3.00162 11.9969 3.00162C13.8571 3.00162 15.5476 3.73513 16.7726 4.92819C17.9905 6.11419 18.7516 7.75918 18.7516 9.58093C18.7516 11.5205 17.8757 13.2455 16.3249 14.7372L15.8648 16.5H8.19881L7.85436 15.0549C6.23099 13.3609 5.33393 11.729 5.24889 9.87351L5.24756 9.84457L5.24847 9.81562C5.30949 7.87285 6.0476 6.18186 7.24542 4.96635ZM8.55632 18L8.90603 19.4672L8.91693 19.4996C9.06735 19.946 9.35574 20.3165 9.71865 20.5761C10.0949 20.8452 10.5554 20.9984 11.0421 20.9984H11.0442L13.1194 20.9925L13.1505 20.9898C13.6225 20.9492 14.0609 20.7642 14.4105 20.4714C14.7571 20.181 15.019 19.7813 15.131 19.3118L15.4733 18H8.55632Z"
        fill="#225AEA"/>
    </svg>
  );
};

export default Energy;
