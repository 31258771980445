import React from 'react';

const AdSpotOldest = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22 13C22 9.13401 18.866 6 15 6H5C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H15C17.7614 8 20 10.2386 20 13C20 15.7614 17.7614 18 15 18C12.2386 18 10 15.7614 10 13C10 12.4477 9.55229 12 9 12C8.44771 12 8 12.4477 8 13C8 16.866 11.134 20 15 20C18.866 20 22 16.866 22 13Z"
            fill="#707BA0"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.29289 6.29289C1.90237 6.68342 1.90237 7.31658 2.29289 7.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L4.41421 7L5.70711 5.70711C6.09763 5.31658 6.09763 4.68342 5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289L2.29289 6.29289Z"
            fill="#707BA0"/>
    </svg>
  );
};

export default AdSpotOldest;
