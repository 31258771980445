import React from 'react';
import Icon from "../../../Icon";
import {Divider} from "antd";
import cls from "classname";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import utils from "../../../Utils/utils";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

const NavHeader = (
  {
    navigate,
    isMobile,
    mainInfo
  }) => {

  const {t} = useTranslation();
  const {id, mode} = useParams();
  const {search} = useLocation();

  const views = search?.includes('internal_shop_id') ?
    [
      {
        name: 'Overview',
        key: 'overview'
      },
      {
        name: 'All Ads',
        key: 'all_ads'
      },
      {
        name: 'Products',
        key: 'products',
        sub: [
          'general',
          'detailed'
        ]
      }
    ]
    :
    [
      {
        name: 'Overview',
        key: 'overview'
      },
      {
        name: 'All Ads',
        key: 'all_ads'
      },
    ]

  return (
    <div className='st-nav-header'>
      <div className="st-nav-header-back"
           onClick={() => {
             if (mode === 'detailed') navigate(-1);
             else navigate('/ad-spot')
           }}
      >
        <Icon width={10} height={10} type="arrow_forward_university" role="icon"/>
        {t('Back')}
      </div>
      <Divider type={'vertical'} style={{margin: '0 16px 0 12px', height: 32}}/>
      <div className="st-nav-header-btn-wrapper">
        {
          views.map(el => (
            <div className={cls("st-nav-header-btn", {
              active: el?.key === mode || (el?.sub && el?.sub?.some(elem => mode.includes(elem)))
            })}
                 key={el?.key}
                 onClick={() => {
                   if (el?.key !== mode || (el?.sub && el?.sub?.some(elem => !mode.includes(elem)))) {
                     navigate(`/ad-spot/${id}/${el?.key}/${search.split('&').filter(s => !s.includes('same')).join('&')}`)
                 }}}
            >
              {t(el?.name)}
            </div>
          ))
        }
        {/*{*/}
        {/*  isMobile ?*/}
        {/*    null*/}
        {/*    :*/}
        {/*    <div className="st-nav-header-btn disabled">*/}
        {/*      {t('Store Analysis')} <span>{t('SOON')}</span>*/}
        {/*    </div>*/}
        {/*}*/}
      </div>
      {
        mode === 'overview' ?
          <div className='st-nav-header-info'>
            <span>
              Updated {utils.durationAsString(new Date().toISOString(), mainInfo?.last_updated, t)}
            </span>
            <Icon type={'attention_outline'}
                  role={'button'}
                  width={16}
                  height={16}
                  tooltipProps={{
                    overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                    placement: "top",
                    title: () => (
                      <div className={'expandable-chart-info-tooltip'}>
                        <div className={'expandable-chart-info-tooltip-title'}>
                          {t('Dashboard data syncs every 24 hours. However, delays may occur when high volumes are being processed.')}
                        </div>
                        <Divider style={{margin: '12px 0'}}/>
                        <div className={'expandable-chart-info-tooltip-text'}>
                          <p>
                            {
                              t('Last updated on')
                            }
                          </p>
                          {
                            mainInfo?.last_updated === null ?
                              <p>
                                {
                                  t('Just now')
                                }
                              </p>
                              :
                              <p>
                                {dayjs(mainInfo?.last_updated).format('lll')} UTC
                              </p>
                          }
                        </div>
                      </div>
                    ),
                    destroyTooltipOnHide: true,
                    getPopupContainer: (trigger) => trigger.parentNode,
                  }}
            />
          </div>
          :
          null
      }
    </div>
  );
};

export default NavHeader;
