import React from 'react';
import { Col, Divider, Row } from 'antd';
import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';

const FiltersBlockSkeleton = ({isMobile=false}) => {
  const renderHeaderSkeleton = (
    <>
      <span className="title skeleton competitor-research">
        {
          isMobile ?
            null
            :
            <span className="header-tag skeleton competitor-research"/>
        }
      </span>

      {
        isMobile ?
          null
          :
          <div className="link-block skeleton competitor-research">
            <span className='link skeleton competitor-research'/>
            <span className='link skeleton competitor-research'/>
          </div>
      }
    </>
  );

  return (
    <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
      <div className={'skeleton-body'} style={{marginTop: isMobile ? 12 : 0}}>
        <Row gutter={[48, 0]}>

          <Col xs={24} md={12} style={{paddingRight: isMobile ? 24 : 12}}>
            <div className="input-filter-wrapper">
              {[150, 196, 171]
                .map(el => (
                  <div key={el} className="input-filter-skeleton-wrapper">
                    <span className="link skeleton" style={{width: el, height: 20}} />
                    <div>
                      <span className="link skeleton" style={{width: 184, height: 20}} />
                    </div>
                  </div>
                ))}
            </div>
          </Col>

          <Col xs={24} md={12} style={{paddingLeft: isMobile ? 24 : 12, marginTop: isMobile ? 12 : 0}}>
            <div className="input-filter-wrapper">
              {[154, 200, 175]
                .map(el => (
                  <div key={el} className="input-filter-skeleton-wrapper">
                    <span className="link skeleton" style={{width: el, height: 20}} />
                    <div>
                      <span className="link skeleton" style={{width: 184, height: 20}} />
                    </div>
                  </div>
                ))}
            </div>
          </Col>

          <div className="advanced-filters-toggle-wrapper" style={{marginTop: 8, marginBottom: 16}}>
            <div className="advanced-filters-toggle-divider"/>
            <span className='link skeleton competitor-research' style={{width: 129, height: 24}}/>
            <div className="advanced-filters-toggle-divider"/>
          </div>

          <Divider style={{ margin: '8px 23px 24px', minWidth: 'auto' }}/>

          <Col xs={24} md={24}>
            <div className="switch-wrapper">
              <div className="switch-options-wrapper">
                {[
                  131]
                  .map(el => (
                    <div key={el * Math.random() * (2 - 1) + 1} style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
                      <span className="link skeleton" style={{width: 36, height: 24, marginRight: 5}} />
                      <span className="link skeleton" style={{width: el, height: 20}} />
                    </div>
                  ))}
              </div>
              <div className="switch-button-wrapper">
                <span className="link skeleton" style={{width: 116, height: 40, marginRight: 12}} />
                <span className="link skeleton" style={{width: 87, height: 40}} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </ProductDetailsCard>
  )
}

export default FiltersBlockSkeleton
