import React from 'react';
import acc from "accounting";
import {useTranslation} from "react-i18next";
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import Icon from "../../../Icon";
import ButtonComponent from "../../../Components/Button";

const InfoHeader = (
  {
    data,
    trackBrand,
    selectedAdv
  }
) => {

  const {t} = useTranslation();

  return (
    <div className={'fadspot-page-inner-info-wrapper'}>
      <div className="fadspot-page-inner-info-store">
        <div className="fadspot-page-inner-info-store-icon">
          {
            data?.advertiser?.icon ?
              <img src={utils.changeImageSize(data?.advertiser?.icon, 496)}
                   alt=""
                   onError={utils.addDefaultSrc}
              />
              : <Image src={Images.defaultImg} small={false}/>
          }
        </div>
        <div className="fadspot-page-inner-info-store-title">
          {data?.advertiser?.name}
          {
            data?.advertiser?.is_verified ?
              <Icon role={'icon'} type={'ad_spot_checkmark'}/>
              :
              null
          }
        </div>
      </div>
      <div className="fadspot-page-inner-info-count">
        <span className="value">
          {acc.formatNumber(selectedAdv?.count, 0, ',')}
        </span>
        <span>
          {
            selectedAdv?.count === 1 ?
            t('Ad Found')
              :
            t('Ads Found')
          }
        </span>
      </div>
      {/*<ButtonComponent className={'fadspot-page-inner-info-track-brand'}*/}
      {/*                 text={null}*/}
      {/*                 onClick={() => trackBrand(`Track Brand data:`, data)}*/}
      {/*>*/}
      {/*  <Icon type={'ad_spot_views'} role={'icon'} color={'#ffffff'} />*/}
      {/*  {*/}
      {/*    t('Track Website')*/}
      {/*  }*/}
      {/*</ButtonComponent>*/}

    </div>
  );
};

export default InfoHeader;
