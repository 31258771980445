import React from 'react';
import './EcommerceExperts.less';

const EcommerceExperts = ({ isMobile }) => {
  return (
    <div className={'experts-wrapper'}>
      <h3>
        Want Our Ecommerce Experts To Launch You A Fully Optimized Shopify Store In The Next 24 Hours For Only $49?
      </h3>
      <div className={'experts-image-wrapper'} />
      <p>
        Our team of eCommerce experts have designed over 102,000+ dropshipping stores and are here to help you start your store next for only $49!
      </p>
      {
        isMobile ?
          <div className={'experts-btn'}
               onClick={() => document.getElementById('pick-your-stores-form').scrollIntoView({
                 behavior: 'smooth',
               })}
          >
            <p>
              Get Your Store Now!
            </p>
            <span>
              365 Day Money Back Guarantee
            </span>
          </div>
          :
          null
      }
    </div>
  );
};

export default EcommerceExperts;
