import React from 'react';
import UniversityPageCourseWrapperSkeleton from "./UniversityPageCourseWrapperSkeleton";

const UniversityPageSkeleton = ({isMobile}) => {
  return (
    <>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          <div className="title-wrapper-icon">
            <span className={'link skeleton'} style={{width: 46, height: 48}}/>
          </div>
          <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'} style={{width: 239, height: 32}}/>
            <span className={'link skeleton'} style={{width: 340, height: 24}}/>
          </div>
        </div>
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          <span className={'link skeleton'} style={{width: 261, height: 40}}/>
        </div>
      </div>

      <UniversityPageCourseWrapperSkeleton isMobile={isMobile} />

      <div className="university-page-tools-wrapper">
        <div className="university-page-tools-header">
          <span className='link skeleton' style={{width: 198, height: 24}}/>
          <span className='link skeleton' style={{width: 263, height: 20, marginTop: 4}}/>
        </div>
      </div>

      <div className="university-page-cards">
        {
          ( isMobile ? [1] : [1,2,3,4]).map(el => (
            <span className='link skeleton' style={{width: isMobile ? '100%' : 'calc((100% - 68px) / 4)', height: 279}} key={el}/>
          ))
        }
      </div>
    </>
  );
};

export default UniversityPageSkeleton;
