import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { Row, Col } from 'antd';

import { OverlayScrollbar } from '../../Components/ScrollBar';
import ImagesBlock from './components/ImagesBlock';
import NameProductBlock from './components/NameProductBlock';
import DescriptionBlock from './components/DescriptionBlock';
import OverviewBlock from './components/OverviewBlock';
import BenefitsBlock from './components/BenefitsBlock';
import SaturationBlock from './components/SaturationBlock';
import CompetitorsBlock from './components/CompetitorsBlock';
import SuppliersBlock from './components/SuppliersBlock';
import VideosBlock from './components/VideosBlock';
import NumbersBreakdownBlock from './components/NumbersBreakdownBlock';
import InterestBlock from './components/InterestBlock';
import MoreProductsBlock from './components/MoreProductsBlock';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import NavigateBlock from '../../Components/NavigateBlock';

import Creators from './reducer';
import SettingCreators from '../SettingPage/reducer';
import OnboardingActions from '../../Redux/OnboardingRedux';
import CollectionsActions from '../CollectionsPage/reducer';
import ShopifyStoreActions from '../ShopifyStore/reducer';
import AutoDSActions from '../AutoDS/reducer';

import utils from '../../Utils/utils';

import './styles.less';

export const ProductDetailsPage = (
  {
    product,
    fetching,
    error,
    userInfo,
    isMobile,
    theme,
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    clearProductDetails,
    getSubscriptionsList,
    activeSubscriptions,
    getCountries,
    getUserCountry,
    countries,
    getShopifyStore,
    getAutoStore,
    openModalShopify,
    openModalAutoDS,
    shopifyStores,
    autoDS,
    ...props
  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollRef = useRef(null);
  const { product_id } = useParams();
  const navigate = useNavigate();

  const breadCrumbs = breadcrumbs?.length ? breadcrumbs : null;
  const activeSubscriptionPlan = activeSubscriptions?.[0]?.plan?.name;
  const path = breadCrumbs?.length ? [...breadCrumbs]?.filter((el) => !!el.link)[0]?.link : '/dashboard';
  const disabledImport = !userInfo?.subscriptions?.[0]?.plan?.price
    && userInfo?.subscriptions?.[0]?.plan?.plan?.name !== 'free'
    && userInfo?.role?.id !== 4;

  useEffect(() => {
    let pageName = utils.toUpperLatter(location.pathname.split('/')[1]);
    if (breadCrumbs === null) navigate(`/${pageName.toLowerCase()}`, {replace: true});
    document.title = pageName ? `${pageName} - Dropship` : 'Dropship';
    getSubscriptionsList('active');
    if (!countries?.length && !props?.isAdmin) getCountries();
    getUserCountry();
    getShopifyStore();
    getAutoStore();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (typeof window.FB !== 'undefined') delete window.FB;
  }, [product_id, activeSubscriptionPlan]);

  useEffect(() => {
    if (breadcrumbs?.length <= 2) {
      addBreadCrumb({
        name: product?.title,
      });
    }
    if (breadcrumbs?.length === 3) {
      removeBreadCrumb();
      addBreadCrumb({
        name: product?.title,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [product?.title]);

  useEffect(() => {
    if (product_id && activeSubscriptionPlan) props.getProductDetails({ product_id });
    handleScrollToTop();
    return () => clearProductDetails();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [product_id, activeSubscriptionPlan]);

  const downloadFile = () => {
    props.exportInterests({ product_id: product?.product_id });
  };

  function handleScrollToTop() {
    if (scrollRef) scrollRef.current._osInstance.scroll(0, 500);
  }

  const addToCollectionFunc = ({ id, isSave, name }) => {
    if (!isSave) {
      dispatch(
        CollectionsActions.addToCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    } else {
      dispatch(
        CollectionsActions.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  const handleSetVisible = (visible) => {
    navigate('/setting/plan');
  };

  const getNextProduct = (type) => {
    if (product?.[type]) {
      let newProductId = product?.[type].split('/').slice(-1)[0];
      if (newProductId) navigate(`${path}/product/${newProductId}`, {replace: true});
    }
  };

  return (
      <OverlayScrollbar visibility="hidden" ref={scrollRef}>
        <div className="product-details-page">

          <div className="top-row">
            <BreadcrumbsComponent
              breadCrumbs={breadCrumbs?.length ? breadCrumbs : []}
              isMobile={isMobile}
              isAdmin={false}
              skeleton={fetching}
            />
            <div/>

            {
              isMobile
                ? null
                : <NavigateBlock skeleton={fetching}
                                 handleChangeMoreProducts={getNextProduct}
                                 previous={{
                                   disabled: !product?.previous,
                                   text: t('Previous'),
                                 }}
                                 next={{
                                   disabled: !product?.next,
                                   text: t('Next'),
                                 }}
                />
            }

          </div>

          <Row
            gutter={[
              { xs: 0, sm: 0, md: 16 },
              { xs: 0, sm: 0, md: 16 },
            ]}
          >
            <Col
              span={24}
              className="product-details-page-name-block-wrap_mobile"
            >
              <NameProductBlock
                title={product?.title}
                textLink={t('View Listing')}
                link={product?.initial_url}
                isMobile={isMobile}
                skeleton={fetching}
                addToCollectionFunc={(id, isSave, name) =>
                  addToCollectionFunc({ id, isSave, name })
                }
                product={product}
              />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}
                 className={cls('', {
                   'product-details-images-fix': !isMobile,
                 })}
            >
              <ImagesBlock images={product?.images || []} skeleton={fetching}/>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}
                 className={cls('', {
                   'product-details-fix': !isMobile,
                 })}
            >
              <Row gutter={[0, 16]} id="product-details-block-header">
                <Col span={24}>
                  <NameProductBlock
                    title={product?.title}
                    textLink={t('View Listing')}
                    link={product?.initial_url}
                    isMobile={isMobile}
                    skeleton={fetching}
                    addToCollectionFunc={(id, isSave, name) =>
                      addToCollectionFunc({ id, isSave, name })
                    }
                    product={product}
                  />
                </Col>

                <Col span={24}>
                  <DescriptionBlock
                    product={product}
                    theme={theme}
                    isMobile={isMobile}
                    skeleton={fetching}
                    isShopifyConnected={!!shopifyStores?.length}
                    openModalShopify={openModalShopify}
                    openModalAutoDS={openModalAutoDS}
                    autoDS={autoDS}
                    disabledImport={disabledImport || !shopifyStores?.length} //only test branch
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} id="product-details-block-overview-id">
              <OverviewBlock
                product={product}
                theme={theme}
                isMobile={isMobile}
                skeleton={fetching}
              />
            </Col>

            {product?.benefits?.length || product?.drawbacks?.length ? (
              <Col span={24}>
                <BenefitsBlock
                  product={product}
                  isMobile={isMobile}
                  skeleton={fetching}
                />
              </Col>
            ) : null}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              className={cls('', {
                'product-details-saturation-fix': !isMobile,
              })}
              id="product-details-block-saturation-id"
            >
              {product?.competitors?.length ? (
                <SaturationBlock
                  saturation={product?.saturation || 0}
                  theme={theme}
                  isMobile={isMobile}
                  skeleton={fetching}
                />
              ) : null}
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              className={cls('', {
                'product-details-competitors-fix': !isMobile,
              })}
            >
              {product?.competitors?.length ? (
                <CompetitorsBlock
                  product={product}
                  isMobile={isMobile}
                  skeleton={fetching}
                  onOpenUpgradePlan={handleSetVisible}
                />
              ) : null}
            </Col>

            {product?.videos?.tiktok?.length ||
            product?.videos?.facebook?.length ? (
              <Col span={24}>
                <VideosBlock
                  videos={product?.videos}
                  isMobile={isMobile}
                  fetching={fetching}
                  lang={userInfo?.language || 'en'}
                  onOpenUpgradePlan={handleSetVisible}
                />
              </Col>
            ) : null}

            <Col span={24}>
              <SuppliersBlock
                product={product}
                isMobile={isMobile}
                skeleton={fetching}
                onOpenUpgradePlan={handleSetVisible}
              />
            </Col>

            <Col span={24} id="product-details-block-numbers-id">
              <NumbersBreakdownBlock
                product={product}
                isMobile={isMobile}
                skeleton={fetching}
              />
            </Col>

            <Col span={24}>
              <InterestBlock
                product={product}
                isMobile={isMobile}
                onDownloadFile={downloadFile}
                skeleton={fetching}
                onOpenUpgradePlan={handleSetVisible}
              />
            </Col>

            <Col span={24}>
              <MoreProductsBlock
                product={product}
                isMobile={isMobile}
                scrollToTop={handleScrollToTop}
                skeleton={fetching}
                breadCrumbs={breadCrumbs}
                clearProductDetails={clearProductDetails}
              />
            </Col>
          </Row>

          <div className="empty-row"/>
        </div>
      </OverlayScrollbar>
  );
};

ProductDetailsPage.propTypes = {
  product: PropTypes.shape({
    BECPA: PropTypes.number,
    BEROAS: PropTypes.number,
    benefits: PropTypes.array,
    category: PropTypes.string,
    competitors: PropTypes.array,
    competitors_count: PropTypes.number,
    cost: PropTypes.string,
    description: PropTypes.string,
    drawbacks: PropTypes.array,
    extra_contents: PropTypes.array,
    fees: PropTypes.string,
    images: PropTypes.array,
    interests: PropTypes.array,
    more_products: PropTypes.array,
    profit_margin: PropTypes.number,
    p_c_ratio: PropTypes.number,
    pot_profit: PropTypes.number,
    price: PropTypes.number,
    product_id: PropTypes.string,
    quick_search: PropTypes.object,
    saturation: PropTypes.number,
    saved: PropTypes.bool,
    score: PropTypes.number,
    shipping: PropTypes.string,
    status: PropTypes.number,
    suppliers: PropTypes.object,
    title: PropTypes.string,
    variants: PropTypes.object,
  }),
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  fetching: state.productDetails.loading,
  error: state.productDetails.errors,
  product: state.productDetails?.productDetails,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  countries: state.onBoarding.countries,
  activeSubscriptions: state.setting.subscriptions,
  shopifyStores: state.shopifyStore.results,
  autoDS: state.autoDS
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (params) =>
    dispatch(Creators.getProductDetailsRequest(params)),
  exportInterests: (params) =>
    dispatch(Creators.exportInterestsRequest(params)),
  addBreadCrumb: (params) => dispatch(BreadcrumbsActions.addBreadCrumb(params)),
  removeBreadCrumb: (params) =>
    dispatch(BreadcrumbsActions.removeBreadCrumb(params)),
  clearProductDetails: () => dispatch(Creators.clearProductDetails()),
  getSubscriptionsList: (filter) =>
    dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  getUserCountry: () => dispatch(SettingCreators.getUserCountryRequest()),
  getShopifyStore: () => dispatch(ShopifyStoreActions.getShopifyStoresRequest()),
  openModalShopify: (modal, initialUrl, importProductId) => dispatch(ShopifyStoreActions.changeVisibleModalShopify({isVisibleModal: modal, initialUrl, importProductId})),
  openModalAutoDS: (modal, importProductId) => dispatch(AutoDSActions.changeVisibleModalAuto({isVisibleModal: modal, importProductId})),
  getAutoStore: () => dispatch(AutoDSActions.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
