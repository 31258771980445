import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Plan = ({ width, height, className, active }) =>
  active
    ? (
      <svg
        width={width}
        height={height}
        className={cls(className, 'icon')}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={'#FFFFFF'}
      >
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.83398 8C5.83398 7.44771 6.2817 7 6.83398 7H18.834C19.3863 7 19.834 7.44772 19.834 8V20.5C19.834 20.8568 19.471 21.0988 19.1417 20.9615L17.1542 20.1334C16.9468 20.047 16.7163 20.0329 16.4999 20.0935L13.6429 20.8935C13.1138 21.0417 12.5542 21.0417 12.0251 20.8935L9.16803 20.0935C8.95166 20.0329 8.72119 20.047 8.51378 20.1334L6.52629 20.9615C6.19695 21.0988 5.83398 20.8568 5.83398 20.5V8ZM8.83398 11C8.83398 10.4477 9.2817 10 9.83398 10H11.834C12.3863 10 12.834 10.4477 12.834 11C12.834 11.5523 12.3863 12 11.834 12H9.83398C9.2817 12 8.83398 11.5523 8.83398 11ZM15.834 10C15.2817 10 14.834 10.4477 14.834 11C14.834 11.5523 15.2817 12 15.834 12C16.3863 12 16.834 11.5523 16.834 11C16.834 10.4477 16.3863 10 15.834 10ZM8.83398 15C8.83398 14.4477 9.2817 14 9.83398 14H11.834C12.3863 14 12.834 14.4477 12.834 15C12.834 15.5523 12.3863 16 11.834 16H9.83398C9.2817 16 8.83398 15.5523 8.83398 15ZM15.834 14C15.2817 14 14.834 14.4477 14.834 15C14.834 15.5523 15.2817 16 15.834 16C16.3863 16 16.834 15.5523 16.834 15C16.834 14.4477 16.3863 14 15.834 14Z"
        />
        <path
          d="M3.83398 4C3.83398 3.44772 4.2817 3 4.83398 3H20.834C21.3863 3 21.834 3.44772 21.834 4C21.834 4.55228 21.3863 5 20.834 5H4.83398C4.2817 5 3.83398 4.55228 3.83398 4Z"
        />
      </svg>
    )
    : (
      <svg
        width={width}
        height={height}
        className={cls(className, 'icon')}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={'#6E7DAE'}
      >
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.83398 3H20.834V18.492C20.834 20.5484 18.4895 21.7254 16.8404 20.4968L16.1357 19.9718C15.958 19.8394 15.7143 19.8398 15.537 19.9727L15.234 20.2C13.8118 21.2667 11.8562 21.2667 10.434 20.2L10.131 19.9727C9.95368 19.8398 9.70999 19.8394 9.53227 19.9718L8.82756 20.4968C7.17848 21.7254 4.83398 20.5484 4.83398 18.492V3ZM6.83398 5V18.492C6.83398 18.9033 7.30288 19.1387 7.6327 18.8929L8.3374 18.3679C9.22605 17.7059 10.4445 17.7079 11.331 18.3727L11.634 18.6C12.3451 19.1333 13.3229 19.1333 14.034 18.6L14.337 18.3727C15.2235 17.7079 16.4419 17.7059 17.3306 18.3679L18.0353 18.893C18.3651 19.1387 18.834 18.9033 18.834 18.492V5H6.83398Z"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.83398 8C9.2817 8 8.83398 8.44772 8.83398 9C8.83398 9.55228 9.2817 10 9.83398 10H11.834C12.3863 10 12.834 9.55228 12.834 9C12.834 8.44772 12.3863 8 11.834 8H9.83398ZM15.834 8C15.2817 8 14.834 8.44772 14.834 9C14.834 9.55228 15.2817 10 15.834 10C16.3863 10 16.834 9.55228 16.834 9C16.834 8.44772 16.3863 8 15.834 8ZM8.83398 13C8.83398 12.4477 9.2817 12 9.83398 12H11.834C12.3863 12 12.834 12.4477 12.834 13C12.834 13.5523 12.3863 14 11.834 14H9.83398C9.2817 14 8.83398 13.5523 8.83398 13ZM15.834 12C15.2817 12 14.834 12.4477 14.834 13C14.834 13.5523 15.2817 14 15.834 14C16.3863 14 16.834 13.5523 16.834 13C16.834 12.4477 16.3863 12 15.834 12Z"/>
        <path
          d="M3.83398 4C3.83398 3.44772 4.2817 3 4.83398 3H20.834C21.3863 3 21.834 3.44772 21.834 4C21.834 4.55228 21.3863 5 20.834 5H4.83398C4.2817 5 3.83398 4.55228 3.83398 4Z"/>
      </svg>
    );

Plan.defaultProps = {
  width: 25,
  height: 24,
  active: false,
};

Plan.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  active: PropTypes.bool,
};

export default Plan;
