import React, { useEffect } from 'react';
import { Checkbox, Divider } from 'antd';
import acc from 'accounting';
import cls from 'classname';
import './PickYourStores.less';

const PickYourStores = (
  {
    checkedStores,
    setCheckedStores,
    checkedOffer,
    setCheckedOffer,
    loading,
    clickFunnelProducts,
    createSession
  }
) => {

  useEffect(() => {
    let offer = clickFunnelProducts.filter(el => el?.show_as_additional_propose)?.[0];
    if (checkedOffer) {
      setCheckedStores([...checkedStores, offer]);
    } else {
      let newStores = checkedStores.filter(el => el?.price_id !== offer?.price_id);
      setCheckedStores(newStores);
    }
  }, [checkedOffer]);

  return (
    <div className={'pick-your-stores-wrapper'}>
      <div className="pick-your-stores-form"
           id={'pick-your-stores-form'}
      >
        <h4 className={'pick-your-stores-form-title'}>
          Pick your stores
        </h4>
        <h5 className={'pick-your-stores-form-subtitle'}>
          <span>
            Store
          </span>
          <span>
            Price
          </span>
        </h5>
        <div className={'pick-your-stores-form-content'}>
          {
            loading ?
              [1,2,3,4,5,6,7,8,9,10].map(el => (
                <div className={'pick-your-stores-form-content-item'}
                     key={el}
                >
                  <span className="link skeleton" style={{ width: '100%', height: 24 }} />
                </div>
              ))
              :
              [...clickFunnelProducts.filter(el => !el?.show_as_additional_propose)].map(el => (
              <div className={'pick-your-stores-form-content-item'}>
                <Checkbox onChange={(e) => {
                  if (e?.target?.checked) {
                    if (el?.is_general) {
                      let newStores = checkedStores.filter(store => store?.show_as_additional_propose)
                      setCheckedStores([...newStores, el]);
                    } else {
                      if (checkedStores.some(store => store?.is_general)) {
                        let newStores = checkedStores.filter(store => !store?.is_general);
                        setCheckedStores([...newStores, el]);
                      } else {
                        setCheckedStores([...checkedStores, el]);
                      }
                    }
                  } else {
                    let newStores = checkedStores.filter(store => store?.price_id !== el?.price_id);
                    setCheckedStores(newStores);
                  }
                }}
                          checked={checkedStores.some(store => store?.price_id === el?.price_id)}
                >
                  {el?.name}
                  {
                    el?.sub_name ?
                      <span className={'pick-your-stores-form-content-item-subtext'}>
                        {el?.sub_name}
                      </span>
                      :
                      null
                  }
                </Checkbox>
                <span className={'pick-your-stores-form-content-item-price'}>
                  ${ acc.formatNumber(el?.price / 100, 0, ',', '.') }
                </span>
              </div>
            ))
          }
        </div>

        {
          checkedStores.length ?
            <h5 className={'pick-your-stores-form-subtitle'}>
              <span>Item</span>
              <span>Amount</span>
            </h5>
            :
            null
        }
        {
          checkedStores.filter(store => !store?.show_as_additional_propose)?.length ?
            <div className={'pick-your-stores-form-content selected'}>
              {
                checkedStores.map(el => (
                  <div className={'pick-your-stores-form-content-item selected'}>
                <span className={'pick-your-stores-form-content-item-title'}>
                  {el?.name}
                  {
                    el?.sub_name ?
                      <span className={'pick-your-stores-form-content-item-subtext'}>
                        {el?.sub_name}
                      </span>
                      :
                      null
                  }
                </span>
                    <span className={'pick-your-stores-form-content-item-price'}>
                  ${
                      acc.formatNumber(el?.price / 100, 2, ',', '.')
                    }
                </span>
                  </div>
                ))
              }
            </div>
            :
            null
        }
        {
          checkedStores.filter(store => !store?.show_as_additional_propose)?.length ?
            <Divider type={'horizontal'} style={{ margin: '12px auto' }} />
            :
            null
        }
        {
          checkedStores.filter(store => !store?.show_as_additional_propose)?.length ?
            <div className={'pick-your-stores-form-total'}>
          <span>
            Your total:
          </span>
              <span>
            ${acc.formatNumber(checkedStores.reduce((a, b) => a + b?.price / 100, 0 ), 2, ',', '.')}
          </span>
            </div>
            :
            null
        }
        {
          checkedStores.filter(store => !store?.show_as_additional_propose)?.length ?
            <div className="pick-your-stores-form-one-time-offer">
              <Checkbox onChange={(e) => setCheckedOffer(e?.target?.checked)}
                        checked={checkedOffer}
              />
              <p onClick={() => setCheckedOffer(!checkedOffer)}>
            <span>
              {
                clickFunnelProducts.filter(el => el?.show_as_additional_propose)?.[0]?.description?.split(':')?.[0]
              }:
            </span>
                {' '}
                {
                  clickFunnelProducts.filter(el => el?.show_as_additional_propose)?.[0]?.description?.split(':')?.[1]
                }
              </p>
            </div>
            :
            null
        }

        <div className={cls('pick-your-stores-form-submit', {
          disabled: !checkedStores.filter(store => !store?.show_as_additional_propose)?.length
        })}
             onClick={() => {
               if (checkedStores.filter(store => !store?.show_as_additional_propose)?.length) {
                 let data = [...checkedStores.map(el => el?.price_id)];
                 createSession({"item_prices": data})
               }
             }}
        >
          <span>
            Confirm order
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
            <path
              d="M10.0027 7.98695C10.0032 7.89963 9.98642 7.81307 9.95324 7.73223C9.92007 7.65139 9.87118 7.57786 9.80939 7.51586L7.14273 4.86184C7.08057 4.79998 7.00678 4.7509 6.92556 4.71742C6.84435 4.68394 6.7573 4.66671 6.66939 4.66671C6.58149 4.66671 6.49444 4.68394 6.41323 4.71742C6.33201 4.7509 6.25822 4.79998 6.19606 4.86184C6.1339 4.9237 6.08459 4.99715 6.05095 5.07798C6.01731 5.15881 6 5.24544 6 5.33293C6 5.42042 6.01731 5.50705 6.05095 5.58788C6.08459 5.66871 6.1339 5.74215 6.19606 5.80402L8.40273 7.98695L6.28273 10.1765C6.21453 10.2376 6.15973 10.3119 6.12174 10.395C6.08375 10.4781 6.06339 10.5681 6.06194 10.6594C6.06048 10.7506 6.07797 10.8412 6.11329 10.9254C6.14862 11.0097 6.20103 11.0858 6.26725 11.1489C6.33347 11.2121 6.41206 11.2609 6.49813 11.2925C6.58419 11.324 6.67586 11.3376 6.76742 11.3322C6.85898 11.3269 6.94845 11.3028 7.03023 11.2615C7.11202 11.2202 7.18436 11.1626 7.24273 11.0922L9.81606 8.43814C9.9336 8.3168 10.0003 8.15549 10.0027 7.98695Z"
              fill="#FFFFFF" />
          </svg>
        </div>

        <p className="subtle-text">
          By clicking this button, you agree that your card will be automatically charged for the "your total" above.
        </p>
      </div>

      <div className="pick-your-stores-partners" />
      <div className="pick-your-stores-comment" />
    </div>
  );
};

export default PickYourStores;
