/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col } from 'antd';

import cls from 'classname';
import Icon from '../../Icon';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import './styles-change-password.less';

function ChangeEmailForm({
  userError,
  saveResult,
  userSaveResultReset,
  onClose,
  onSubmit,
  userErrorsReset,
  clearForm,
  onChangeModal,
  onSaveConfirm,
  isMobile,
}) {
  const { t } = useTranslation();
  const currentText = t('Confirm your current password');
  const [errorText, setErrorText] = useState(null);
  const [emailIconColor, setEmailIconColor] = useState('#707BA0');
  const [passIconColor, setPassIconColor] = useState('#707BA0');
  const [currentError, setCurrentError] = useState({
    text: currentText,
    error: false,
  });

  const [values, setValues] = useState({ current: null, email: null });

  const [form] = Form.useForm();

  useEffect(() => {
    if (userError) {
      if (typeof userError === 'string') {
        if (userError.length > 100) {
          setErrorText('Server response error');
        } else {
          setErrorText(userError);
        }
      } else {
        if (userError?.detail === 'Current password is entered incorrectly') {
          setCurrentError({
            text: userError?.detail,
            error: true,
          })
          setPassIconColor('#D71313')
        } else {
          setErrorText(Object.values(userError)[0]);
          setEmailIconColor('#D71313')
        }
      }
    }
  }, [userError]);

  useEffect(() => {
    clearFormData();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [clearForm]);

  useEffect(() => {
    if (saveResult?.confirm) {
      onChangeModal('checkEmail');
      onSaveConfirm(values);
      userSaveResultReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  const onFinish = (value) => {
    const data = { ...value };
    userErrorsReset();
    onSubmit(data.current, data.email);
  };
  const handleClose = () => {
    clearFormData();
    onClose();
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0].errors);
    }
  };
  function clearFormData() {
    form.resetFields();
    setValues({ current: null, email: null });
    setErrorText(null);
    setCurrentError({ text: currentText, error: false });
    userErrorsReset();
    userSaveResultReset();
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
  }

  function onFocusHandler(e) {
    handleClearError(e, e.target.name);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'current') {
      userErrorsReset();
      setCurrentError({ text: currentText, error: false });
    }

    setValues({ ...values, [name]: value });
  }

  return (
    <Form
      className="change-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger="onSubmit"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label={t('Current Password')}
        className="change-form-item current-password"
        style={{ marginBottom: '16px' }}
        validateStatus={currentError.error ? 'error' : null}
      >
        <div
          className={cls('confirm-message-text', {
            error_text: currentError.error,
          })}
        >
          {(currentError.text !== 'Confirm your current password') && <Icon type={'alert_triangle_error'} role={'icon'} />}
          {currentError.text}
        </div>
        <Form.Item name="current" noStyle>
          <Input.Password
            size="large"
            name="current"
            placeholder={t('Password')}
            autoComplete="current-password"
            visibilityToggle
            iconRender={(visible) =>
              visible ? (
                <Icon type="eye" color="#C5CCE3" />
              ) : (
                <Icon type="eye_invisible" color="#C5CCE3" />
              )
            }
            prefix={<Icon type={'lock'} color={userError?.detail === 'Current password is entered incorrectly' ? '#D71313':passIconColor} />}
            onFocus={(e) => {
              onFocusHandler(e)
              setPassIconColor('#225AEA')
            }}
            onChange={(e) => {
              handleChange(e)
              setPassIconColor('#225AEA')
            }}
            onBlur={() => setPassIconColor('#707BA0')}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={t('New Email')}
        className="change-form-item new-email"
        rules={[{ required: true, message: 'Required field' }]}
        validateStatus={errorText ? 'error' : null}
      >
        {errorText ? (
          <div className="confirm-message-text  error_text">
            {errorText && <Icon type={'alert_triangle_error'} role={'icon'} />}
            {errorText === 'Enter a valid email address.'
              ? 'Email address you entered is incorrect'
              : errorText}
          </div>
        ) : null}
        <Form.Item name="email" noStyle>
          <InputComponent
            size="large"
            name="email"
            type="email"
            placeholder={t('New Email')}
            autoComplete="dontshow"
            prefix={<Icon type={'email'} color={emailIconColor} />}
            onFocus={(e) => {
              onFocusHandler(e)
              setEmailIconColor('#225AEA')
            }}
            onChange={(e) => {
              handleChange(e)
              setEmailIconColor('#225AEA')
            }}
            onBlur={() => setEmailIconColor('#707BA0')}
          />
        </Form.Item>
      </Form.Item>

      {isMobile ? (
        <div className="double-btn-wrp">
          <ButtonComponent
            type="primary"
            htmlType="button"
            className="btn-primary btn-cancel"
            onClick={handleClose}
          >
            {t('Cancel')}
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            className="btn-primary btn-submit"
            disabled={!values.email || !values.current}
          >
            {t('Continue')}
          </ButtonComponent>
        </div>
      ) : (
        <Row className="change-btn-block" gutter={[16, 0]} justify="end">
          <Col flex="95px">
            <ButtonComponent
              type="primary"
              htmlType="button"
              className="btn-primary btn-cancel border-only"
              style={{ width: '100%' }}
              onClick={handleClose}
            >
              {t('Cancel')}
            </ButtonComponent>
          </Col>
          <Col flex="109px">
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="btn-primary"
              style={{ width: '100%', margin: 0 }}
              disabled={!values.email || !values.current}
            >
              {t('Continue')}
            </ButtonComponent>
          </Col>
        </Row>
      )}
    </Form>
  );
}

export default ChangeEmailForm;
