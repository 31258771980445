import React from 'react';
import cls from 'classname';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import './save-to-collections.less';

export const SaveToCollectionComponent = ({ isSave, saveFunc, isDisabled }) => (
  <span
    className={cls('favorite', {disabled: isDisabled})}
    onClick={() => {
      if(isDisabled) return
      saveFunc(isSave);
    }}
  >
    <Icon type="bookmark" role="icon" outline={isSave} />
  </span>
);

SaveToCollectionComponent.defaultProps = {
  isSave: false,
};

SaveToCollectionComponent.propTypes = {
  isSave: PropTypes.bool,
  saveFunc: PropTypes.func.isRequired,
};

export default SaveToCollectionComponent;
