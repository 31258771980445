import React from 'react';
import Layout from '../Layout';
import { Container } from '../../Components/Blocks';

export default function AliDropsPageAdmin() {
  return (
    <Layout>
      <Container className="alidrops-page">Ali Drops</Container>
    </Layout>
  );
}
