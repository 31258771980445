import React from 'react';
import { Modal } from 'antd';
import cls from 'classname';
import ButtonComponent from '../Button';
import { useTranslation } from 'react-i18next';

const PendingPaymentModal = (
  {
    visible,
    isMobile,
    theme,
    callback
  }) => {

  const {t} = useTranslation();

  return (
    <Modal className="change-modal custom-modal trial-finished-modal pending-payment-modal"
           getContainer={() => document.getElementById('global-wrap')}
           {...(isMobile ? { transitionName: '' } : null)}
           open={visible}
           centered={!isMobile}
           width={512}
           footer={null}
           closable="false"
           onCancel={null}
           destroyOnClose
    >
      <div className="trial-finished-wrapper">
        <div className={cls('cancel-sub-image', {
          'cancel-sub-image--dark': theme === 'dark',
        })} />
        <div className="trial-finished-title">
          We couldn't process your payment
        </div>
        <div className="trial-finished-text">
          <p>Dont worry, it happens!</p>
          <p>Right now your account has limited functionality, until the payment is processed.</p>
          <p>You can try to change your payment details and/or retry the payment charge</p>
        </div>
        <div className={'pending-payment-buttons'}>
          <ButtonComponent text={t('Retry charge')}
                           onClick={() => callback('/setting/plan')}
                           className="trial-finished-button"
          />
          <ButtonComponent text={'Change details'}
                           onClick={() => callback('/setting/billing')}
                           className="trial-finished-button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PendingPaymentModal;
