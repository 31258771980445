import React from 'react';
import { Col, Row } from 'antd';
import { Container } from '../../../../Components/Blocks';
import './styles.less';

export default function CollectionsSkeletons() {
  return (
    <Container className="container collections-container collections-skeletons">
      <div className="sales-tracker-top-page-header">
        <div style={{maxWidth: 1648, width: '100%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className="title-wrapper" style={{minWidth: '100%'}}>
          <div className="title-wrapper-icon">
            <span className={'link skeleton'} style={{width: 48, height: 48}}/>
          </div>
          <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'} style={{width: 240, height: 32}}/>
            <span className={'link skeleton'} style={{width: 338, height: 24}}/>
          </div>
        </div>
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          <span className={'link skeleton'} style={{width: 172, height: 40}}/>
        </div>
        </div>
      </div>
      <div className="search">
        <div className="search_wrapper">
          <div />
          <div />
        </div>
      </div>
      <div className="collections-list">
        <Row
          className="collections-list_wrapper"
          gutter={[
            { xs: 8, sm: 16 },
            { xs: 0, sm: 50 },
          ]}
        >
          {[1, 2, 3, 4, 5, 6].map((value, i) => (
            <Col xs={24} md={12} lg={8} key={`col-${i}`}>
              <div className="collection-item">
                <div className="collection-item_images collection__multiple">
                  {[1, 2, 3, 4].map((_, i) => (
                    <div className="collection-item_image" key={i}/>
                  ))}
                </div>
                <div className="collection-item_wrapper">
                  <div className="collection-item_block">
                    <div />
                    <div />
                    <div />
                  </div>
                  <div className="collection-item_statistic">
                    <div />
                    <div />
                  </div>
                  <div className="collection-item_tabs">
                    {[1, 2, 3, 4].map((_, i) => (
                      <div className="collection-item_tab" key={i}>
                        <div />
                      </div>
                    ))}
                  </div>
                  <div className="collection-item_tabs-wrapper">
                    <div className="progress-circle">
                      <div />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}
