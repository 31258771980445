import React from 'react';
import cls from 'classname';
import Icon from '../../../../Icon';
import utils from '../../../../Utils/utils';

export default function BDListDetails({ list, onDelete, saveInfo }) {
  return (
    <div className="bd-list-block">
      {list?.map((item) => (
        <div className="bd-list-block_item" key={item?.id}>
          <div className="bd-list-block_item-main">
            <div
              className={cls('benefit-drawback-img', {
                drawback: item?._type === 'Drawbacks',
                benefit: item?._type === 'Benefits',
                used: item?.icon?.is_used,
              })}
              dangerouslySetInnerHTML={{
                __html: item?.icon?.content,
              }}
            />
            <div className="benefit-drawback-text">
              <div className="bd-item-title">
                {utils.toUpperLatter(item?.title || '')}
              </div>
              <div className="bd-item-subtitle">
                {utils.toUpperLatter(item?.description || '')}
              </div>
            </div>
          </div>
          <div>
            <Icon
              type="delete_basket"
              role="button"
              width={24}
              height={24}
              onClick={() => {
                saveInfo({ id: item?.id });
                onDelete();
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
