import React from 'react';

const UniversityLock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5.4413 6H10.5594C10.9108 5.99999 11.214 5.99998 11.4637 6.02038C11.7272 6.04192 11.9894 6.08946 12.2416 6.21799C12.618 6.40974 12.9239 6.7157 13.1157 7.09202C13.2442 7.34427 13.2917 7.60642 13.3133 7.86998C13.3337 8.11968 13.3337 8.42291 13.3337 8.77431V11.8924C13.3337 12.2438 13.3337 12.547 13.3133 12.7967C13.2917 13.0603 13.2442 13.3224 13.1157 13.5747C12.9239 13.951 12.618 14.2569 12.2416 14.4487C11.9894 14.5772 11.7272 14.6248 11.4637 14.6463C11.214 14.6667 10.9108 14.6667 10.5593 14.6667H5.44129C5.08986 14.6667 4.78669 14.6667 4.53698 14.6463C4.27341 14.6248 4.01127 14.5772 3.75901 14.4487C3.38269 14.2569 3.07673 13.951 2.88498 13.5747C2.75645 13.3224 2.70891 13.0603 2.68737 12.7967C2.66697 12.547 2.66698 12.2438 2.66699 11.8924V8.77428C2.66698 8.42287 2.66697 8.11969 2.68737 7.86998C2.70891 7.60642 2.75645 7.34427 2.88498 7.09202C3.07673 6.7157 3.38269 6.40974 3.75901 6.21799C4.01127 6.08946 4.27341 6.04192 4.53698 6.02038C4.78668 5.99998 5.08988 5.99999 5.4413 6ZM8.00033 10C7.63214 10 7.33366 10.2985 7.33366 10.6667V11.3333C7.33366 11.7015 7.63214 12 8.00033 12C8.36852 12 8.66699 11.7015 8.66699 11.3333V10.6667C8.66699 10.2985 8.36852 10 8.00033 10Z"
            fill="#E2E6F3"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5.33301 4.00016C5.33301 2.5274 6.52692 1.3335 7.99967 1.3335C9.47243 1.3335 10.6663 2.5274 10.6663 4.00016V6.00016H9.33301V4.00016C9.33301 3.26378 8.73605 2.66683 7.99967 2.66683C7.26329 2.66683 6.66634 3.26378 6.66634 4.00016V6.00016H5.33301V4.00016Z"
            fill="#E2E6F3"/>
    </svg>
  );
};

export default UniversityLock;
