import React from 'react';

const SuppliersPlug = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M4 13.6H13.6V18.4H4V13.6Z" fill="#151E3A"/>
      <path d="M22 12H26.75C27.4404 12 28 12.5596 28 13.25C28 13.9404 27.4404 14.5 26.75 14.5H22V12Z" fill="#C5CCE3"/>
      <path d="M22 18H26.75C27.4404 18 28 18.5596 28 19.25C28 19.9404 27.4404 20.5 26.75 20.5H22V18Z" fill="#C5CCE3"/>
      <path
        d="M11.2 15.9999C11.2 12.0235 14.4235 8.79993 18.3999 8.79993H23.2V23.1999H18.3999C14.4235 23.1999 11.2 19.9764 11.2 15.9999Z"
        fill="#151E3A"/>
    </svg>
  );
};

export default SuppliersPlug;
