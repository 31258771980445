import React from 'react';
import { Link } from 'react-router-dom';
import cls from 'classname';
import acc from 'accounting';
import SaveToCollection from '../SaveToCollections/save-to-collections';
import Icon from '../../Icon';
import { titleSortFilters } from '../titleSortFilters';
import utils, { checkSaturationIndex } from '../../Utils/utils';
import ButtonComponent from "../Button";

export const columnsMobile = ({ theme, addToCollectionFunc, isAdmin, t, onOpenUpgradePlan }) => [
  {
    title: 'Product',
    dataIndex: 'title',
    key: 'title',
    fixed: true,
    width: 180,
    sorter: false,
    render: (_, record) => (
      <span className="title-column">
        {
          isAdmin
            ? null
            : <SaveToCollection
              saveFunc={(isSave) =>
                addToCollectionFunc(record.product_id, isSave, record.title)
              }
              isSave={record.saved && !record['is_locked']}
              isDisabled={record['is_locked']}
            />
        }

        {
          record['is_locked']
            ? (
              <>
                <div className='title-column-img-wrap title-column-img-wrap_locked'>
                  <Icon role="icon" type="locked_block" width={30} height={30}/>
                </div>

                <span className="title-column-text">
                <span className={'block-locked-text block-locked-text_clickable'}
                      style={{display: 'block', lineHeight: '20px'}}
                      onClick={()=> onOpenUpgradePlan(record['is_locked'])}
                >
                  {t('Upgrade')}
                </span>
                  {t('Plan')}
                </span>
              </>)
            : (
              <>
                <img src={record?.image?.url} alt="images" onError={utils.addDefaultSrc}/>
                <span className="title-column-text">{record.title}</span>
              </>
            )
        }
      </span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Cost'),
        stateData: state,
        key: 'cost',
      }),
    dataIndex: 'cost',
    key: 'cost',
    width: 80,
    showSorterTooltip: false,
    sorter: true,
    render: (_, record) => <span>{record.cost ? record?.cost?.replaceAll('.',',') : 0}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Price'),
        stateData: state,
        key: 'price',
      }),
    dataIndex: 'price',
    key: 'price',
    width: 80,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record.price, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('P/C Ratio'),
        stateData: state,
        key: 'p_c_ratio',
      }),
    dataIndex: 'p_c_ratio',
    key: 'p_c_ratio',
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>{acc.formatNumber(record.p_c_ratio, 2, ' ', '.')}Х</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Profit Margin'),
        stateData: state,
        key: 'profit_margin',
      }),
    dataIndex: 'profit_margin',
    key: 'profit_margin',
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>{acc.formatNumber(record.profit_margin, 2, ' ', '.')}%</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Profit'),
        stateData: state,
        key: 'pot_profit',
      }),
    dataIndex: 'pot_profit',
    key: 'pot_profit',
    width: 200,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>≈${acc.formatNumber(record.pot_profit, 0, ',', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({ title: t('BECPA'), stateData: state, key: 'BECPA' }),
    dataIndex: 'BECPA',
    key: 'BECPA',
    width: 120,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record.BECPA, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({ title: t('BEROAS'), stateData: state, key: 'BEROAS' }),
    dataIndex: 'BEROAS',
    key: 'BEROAS',
    showSorterTooltip: false,
    width: 120,
    sorter: true,
    render: (_, record) => (
      <span>{acc.formatNumber(record.BEROAS, 2, ' ', '.')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Competitors'),
        stateData: state,
        key: 'competitors',
      }),
    dataIndex: 'competitors',
    key: 'competitors',
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => <span>{record.competitors}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Saturation'),
        stateData: state,
        key: 'saturation',
      }),
    dataIndex: 'saturation',
    key: 'saturation',
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <Icon
        type="ellipse"
        role="icon"
        outline={checkSaturationIndex(record.saturation)}
        theme={theme}
      />
    ),
  },
  {
    title: (state) =>
      titleSortFilters({ title: t('Score'), stateData: state, key: 'score' }),
    dataIndex: 'score',
    key: 'score',
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span className="score-counter">
        <Icon
          type="score_counter"
          role="icon"
          outline={record.score || 0}
          color={theme === 'dark' ? '#000E58' : '#F2F6FF'}
        />
        <span className="score-counter-num">{record.score || 0}</span>
      </span>
    ),
  },
];

export const columnsDesktopLeft = (
  {
    setCols,
    cols,
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    t,
    onOpenUpgradePlan,
}) => [
  {
    title: t('Product'),
    dataIndex: 'title',
    key: 'title',
    width: 280,
    sorter: false,
    render: (_, record) => (
      <span className="title-column">
        <div className={cls('title-column-img-wrap', {'title-column-img-wrap_locked': record['is_locked']})}
        >
          {
            record['is_locked']
            ? <Icon role="icon" type="locked_block" width={40} height={40}/>
            : <img
                src={record?.image?.url}
                alt="images"
                width={48}
                onError={utils.addDefaultSrc}
              />
          }
          {isAdmin ? <div className={cls('status', record?.status?.name)}/> : null}
        </div>
        <span className="title-column-text">
          {
            record['is_locked']
              ? (
                <>
                <span className={'block-locked-text block-locked-text_clickable'}
                      onClick={()=> onOpenUpgradePlan(record['is_locked'])}
                >
                  {t('Upgrade')}
                </span>
                  {t('your plan to unlock')}
                </>)
            : record.title
          }
        </span>
      </span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Cost'),
        stateData: state,
        key: 'cost',
      }),
    dataIndex: 'cost',
    key: 'cost',
    width: 80,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => <span>{record.cost ? record?.cost?.replaceAll('.',',') : 0}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Price'),
        stateData: state,
        key: 'price',
      }),
    dataIndex: 'price',
    key: 'price',
    width: 80,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record.price, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('P/C Ratio'),
        stateData: state,
        key: 'p_c_ratio',
      }),
    dataIndex: 'p_c_ratio',
    key: 'p_c_ratio',
    width: 120,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.p_c_ratio'),
      overlayClassName: 'details-tooltip p_c_ratio',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.p_c_ratio, 2, ' ', '.')}Х</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Profit Margin'),
        stateData: state,
        key: 'profit_margin',
      }),
    dataIndex: 'profit_margin',
    key: 'profit_margin',
    width: 130,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.profit_margin'),
      overlayClassName: 'details-tooltip p_c_ratio',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.profit_margin, 2, ' ', '.')}%</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Potential Profit'),
        stateData: state,
        key: 'pot_profit',
      }),
    dataIndex: 'pot_profit',
    key: 'pot_profit',
    width: 150,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.pot_profit'),
      overlayClassName: 'details-tooltip pot_profit',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => <span>≈${acc.formatNumber(record.pot_profit)}</span>,
  },
  {
    title: () => (
      <ButtonComponent
        onClick={() => setCols(!cols)}
        className={cls('details-btn', {
          'details-btn-open': cols,
        })}
      >
        <Icon type="arrow_forward" role="icon" />
      </ButtonComponent>
    ),
    dataIndex: 'saved-overview',
    key: 'saved-overview',
    width: isAdminEdit ? 100: 70,
    sorter: false,
    render: (_, record) => (
      <span className={cls('saved-overview', {disabled: record['is_locked']})}>
        {
          isAdmin
          ? null
          : (
            <SaveToCollection
              saveFunc={(isSave) =>
                addToCollectionFunc(record.product_id, isSave, record.title)
              }
              isDisabled={record['is_locked']}
              isSave={record.saved && !record['is_locked']}
            />)
        }
        <span>
         {
           record['is_locked']
             ? (
               <span>
                  <Icon type="external_link" role="icon" />
                </span>)
             : (
               <Link to={`${isAdminEdit ? '/admin' : ''}/portfolio/product/${record.product_id}`}>
                 {
                   isAdminEdit
                     ? <ButtonComponent type='primary' className='btn-primary' style={{minWidth:96}}>Edit</ButtonComponent>
                     : <Icon type="external_link" role="icon" />
                 }
               </Link>)
         }
        </span>
      </span>
    ),
  },
];

export const columnsDesktopRight = (
  {
    setCols,
    cols,
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    t,
    onOpenUpgradePlan
}) => [
  {
    title: t('Product'),
    dataIndex: 'title',
    key: 'title',
    width: 280,
    sorter: false,
    render: (_, record) => (
      <span className="title-column">
        <div className={cls('title-column-img-wrap', {'title-column-img-wrap_locked': record['is_locked']})}
        >
          {
            record['is_locked']
              ? <Icon role="icon" type="locked_block" width={40} height={40}/>
              : <img
                src={record?.image?.url}
                alt="images"
                width={48}
                onError={utils.addDefaultSrc}
              />
          }
          {isAdmin ? <div className={cls('status', record?.status?.name)}/> : null}
        </div>
        <span className="title-column-text">
          {
            record['is_locked']
              ? (
                <>
                <span className={'block-locked-text block-locked-text_clickable'}
                      onClick={()=> onOpenUpgradePlan(record['is_locked'])}
                >
                  {t('Upgrade')}
                </span>
                  {t('your plan to unlock')}
                </>)
              : record.title
          }
        </span>
      </span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('BECPA'),
        stateData: state,
        key: 'BECPA',
      }),
    dataIndex: 'BECPA',
    key: 'BECPA',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.BECPA'),
      overlayClassName: 'details-tooltip BECPA',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>${acc.formatNumber(record.BECPA, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('BEROAS'),
        stateData: state,
        key: 'BEROAS',
      }),
    dataIndex: 'BEROAS',
    key: 'BEROAS',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.BEROAS'),
      overlayClassName: 'details-tooltip BEROAS',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.BEROAS, 2, ' ', '.')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Competitors'),
        stateData: state,
        key: 'competitors',
      }),
    dataIndex: 'competitors',
    key: 'competitors',
    width: 140,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => <span>{record.competitors}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Saturation'),
        stateData: state,
        key: 'saturation',
      }),
    dataIndex: 'saturation',
    key: 'saturation',
    width: 130,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.saturation'),
      overlayClassName: 'details-tooltip saturation',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <Icon type="ellipse" role="icon" outline={checkSaturationIndex(record.saturation)} />
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Score'),
        stateData: state,
        key: 'score',
      }),
    dataIndex: 'score',
    key: 'score',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.score'),
      overlayClassName: 'details-tooltip score',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span className="score-counter">
        <Icon type="score_counter" role="icon" outline={record.score || 0} />
        <span className="score-counter-num">{record.score || 0}</span>
      </span>
    ),
  },
  {
    title: () => (
      <ButtonComponent
        onClick={() => setCols(!cols)}
        className={cls('details-btn', {
          'details-btn-open': cols,
        })}
      >
        <Icon type="arrow_forward" role="icon" />
      </ButtonComponent>
    ),
    dataIndex: 'saved-overview',
    key: 'saved-overview',
    width: isAdminEdit ? 100: 70,
    sorter: false,
    render: (_, record) => (
      <span className={cls('saved-overview', {disabled: record['is_locked']})}>
        {
          isAdmin
          ? null
          : (
            <SaveToCollection
              saveFunc={(isSave) =>
                addToCollectionFunc(record.product_id, isSave, record.title)
              }
              isDisabled={record['is_locked']}
              isSave={record.saved && !record['is_locked']}
            />)
        }
        <span>
          {
            record['is_locked']
              ? (
                <span>
                  <Icon type="external_link" role="icon" />
                </span>)
              : (
                <Link to={`${isAdminEdit ? '/admin' : ''}/portfolio/product/${record.product_id}`}>
                  {
                    isAdminEdit
                      ? <ButtonComponent type='primary' className='btn-primary' style={{minWidth:96}}>Edit</ButtonComponent>
                      : <Icon type="external_link" role="icon" />
                  }
                </Link>)
          }
        </span>
      </span>
    ),
  },
];

export const columnsDesktopFull = (
  {
    addToCollectionFunc,
    isAdmin,
    isAdminEdit,
    t,
    onOpenUpgradePlan,
  }) => [
  {
    title: t('Product'),
    dataIndex: 'title',
    key: 'title',
    width: 280,
    sorter: false,
    render: (_, record) => (
      <span className="title-column">
        <div className={cls('title-column-img-wrap', {'title-column-img-wrap_locked': record['is_locked']})}
        >
          {
            record['is_locked']
              ? <Icon role="icon" type="locked_block" width={40} height={40}/>
              : <img
                src={record?.image?.url}
                alt="images"
                width={48}
                onError={utils.addDefaultSrc}
              />
          }
          {isAdmin ? <div className={cls('status', record?.status?.name)}/> : null}
        </div>
        <span className="title-column-text">
          {
            record['is_locked']
              ? (
                <>
                <span className={'block-locked-text block-locked-text_clickable'}
                      onClick={()=> onOpenUpgradePlan(record['is_locked'])}
                >
                  {t('Upgrade')}
                </span>
                  {t('your plan to unlock')}
                </>)
              : record.title
          }
        </span>
      </span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Cost'),
        stateData: state,
        key: 'cost',
      }),
    dataIndex: 'cost',
    key: 'cost',
    width: 120,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => <span>{record.cost ? record?.cost?.replaceAll('.',',') : 0}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Price'),
        stateData: state,
        key: 'price',
      }),
    dataIndex: 'price',
    key: 'price',
    width: 120,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => (
      <span>${acc.formatNumber(record.price, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('P/C Ratio'),
        stateData: state,
        key: 'p_c_ratio',
      }),
    dataIndex: 'p_c_ratio',
    key: 'p_c_ratio',
    width: 120,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.p_c_ratio'),
      overlayClassName: 'details-tooltip p_c_ratio',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.p_c_ratio, 2, ' ', '.')}Х</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Profit Margin'),
        stateData: state,
        key: 'profit_margin',
      }),
    dataIndex: 'profit_margin',
    key: 'profit_margin',
    width: 140,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.profit_margin'),
      overlayClassName: 'details-tooltip p_c_ratio',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.profit_margin, 2, ' ', '.')}%</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Potential Profit'),
        stateData: state,
        key: 'pot_profit',
      }),
    dataIndex: 'pot_profit',
    key: 'pot_profit',
    width: 150,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.pot_profit'),
      overlayClassName: 'details-tooltip pot_profit',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => <span>≈${acc.formatNumber(record.pot_profit)}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('BECPA'),
        stateData: state,
        key: 'BECPA',
      }),
    dataIndex: 'BECPA',
    key: 'BECPA',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.BECPA'),
      overlayClassName: 'details-tooltip BECPA',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>${acc.formatNumber(record.BECPA, 2, ' ', ',')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('BEROAS'),
        stateData: state,
        key: 'BEROAS',
      }),
    dataIndex: 'BEROAS',
    key: 'BEROAS',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.BEROAS'),
      overlayClassName: 'details-tooltip BEROAS',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span>{acc.formatNumber(record.BEROAS, 2, ' ', '.')}</span>
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Competitors'),
        stateData: state,
        key: 'competitors',
      }),
    dataIndex: 'competitors',
    key: 'competitors',
    width: 140,
    sorter: true,
    showSorterTooltip: false,
    render: (_, record) => <span>{record.competitors}</span>,
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Saturation'),
        stateData: state,
        key: 'saturation',
      }),
    dataIndex: 'saturation',
    key: 'saturation',
    width: 130,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.saturation'),
      overlayClassName: 'details-tooltip saturation',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <Icon type="ellipse" role="icon" outline={checkSaturationIndex(record.saturation)} />
    ),
  },
  {
    title: (state) =>
      titleSortFilters({
        title: t('Score'),
        stateData: state,
        key: 'score',
      }),
    dataIndex: 'score',
    key: 'score',
    width: 100,
    sorter: true,
    showSorterTooltip: {
      title: t('tooltips.score'),
      overlayClassName: 'details-tooltip score',
      getPopupContainer: () => document.getElementById('global-wrap'),
    },
    render: (_, record) => (
      <span className="score-counter">
        <Icon type="score_counter" role="icon" outline={record.score || 0} />
        <span className="score-counter-num">{record.score || 0}</span>
      </span>
    ),
  },
  {
    title: () => <span/>,
    dataIndex: 'saved-overview',
    key: 'saved-overview',
    width: isAdminEdit ? 100: 70,
    sorter: false,
    render: (_, record) => (
      <span className={cls('saved-overview', {disabled: record['is_locked']})}>
        {
          isAdmin
            ? null
            : (
              <SaveToCollection
                saveFunc={(isSave) =>
                  addToCollectionFunc(record.product_id, isSave, record.title)
                }
                isDisabled={record['is_locked']}
                isSave={record.saved && !record['is_locked']}
              />)
        }
        <span>
          {
            record['is_locked']
              ? (
                <span>
                  <Icon type="external_link" role="icon" />
                </span>)
              : (
                <Link to={`${isAdminEdit ? '/admin' : ''}/portfolio/product/${record.product_id}`}>
                  {
                    isAdminEdit
                      ? <ButtonComponent type='primary' className='btn-primary' style={{minWidth:96}}>Edit</ButtonComponent>
                      : <Icon type="external_link" role="icon" />
                  }
                </Link>)
          }
        </span>
      </span>
    ),
  },
];

export const changeColumns = ({ colType, isMobile, fullTable }) => {
  if (isMobile) return columnsMobile;
  if (fullTable) return columnsDesktopFull;

  return colType ? columnsDesktopRight : columnsDesktopLeft;
};
