import React from 'react';

const Skype = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none">
      <path
        d="M12.0325 16.8153C9.35998 16.8153 8.13892 15.4613 8.13892 14.4682C8.13856 14.2313 8.23633 14.0047 8.40922 13.8417C8.58212 13.6788 8.81487 13.5938 9.05273 13.6068C10.197 13.6068 9.89767 15.3138 12.0325 15.3138C13.1238 15.3138 13.7648 14.6605 13.7648 14.0468C13.7648 13.678 13.5529 13.2565 12.8324 13.0879L10.4486 12.49C8.53358 12.0053 8.19984 10.9463 8.19984 9.96378C8.19984 7.92493 10.0751 7.18736 11.8604 7.18736C13.5052 7.18736 15.46 8.09089 15.46 9.31314C15.46 9.83998 15.0176 10.1218 14.5038 10.1218C13.5264 10.1218 13.6906 8.75733 11.7253 8.75733C10.7479 8.75733 10.2341 9.21304 10.2341 9.85051C10.2341 10.488 10.9942 10.704 11.6617 10.8489L13.4205 11.244C15.3487 11.676 15.8626 12.8008 15.8626 13.8782C15.8626 15.5351 14.5727 16.8153 12.0299 16.8153H12.0325ZM19.4119 13.3092C19.4882 12.8743 19.5263 12.4336 19.5258 11.9921C19.5351 9.77442 18.5553 7.66675 16.8504 6.23723C15.1456 4.80771 12.8912 4.20349 10.6949 4.58743C10.0199 4.2009 9.25425 3.99828 8.47531 4.00001C6.87644 4.00972 5.40266 4.86193 4.60323 6.23903C3.8038 7.61613 3.79863 9.31155 4.58964 10.6935C4.14826 13.1054 4.92143 15.5795 6.65975 17.3177C8.39808 19.056 10.8816 19.8384 13.3092 19.4126C13.9834 19.7988 14.7481 20.0014 15.5262 20C17.1242 19.9898 18.5971 19.1379 19.3964 17.7616C20.1956 16.3854 20.2015 14.6909 19.4119 13.3092Z"
        fill="white"/>
    </svg>
  );
};

export default Skype;
