import React from 'react';

const AdSpotSad = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.2858 11.9996C22.2858 14.7275 21.2021 17.3438 19.2732 19.2727C17.3443 21.2016 14.728 22.2853 12.0001 22.2853C9.27213 22.2853 6.65592 21.2016 4.72698 19.2727C2.79803 17.3438 1.71436 14.7275 1.71436 11.9996C1.71436 9.27164 2.79803 6.65543 4.72698 4.72649C6.65592 2.79754 9.27213 1.71387 12.0001 1.71387C14.728 1.71387 17.3443 2.79754 19.2732 4.72649C21.2021 6.65543 22.2858 9.27164 22.2858 11.9996Z"
        fill={`url(#paint0_linear_36566_241404_${id})`}/>
      <path
        d="M8.57129 18.1267C8.57129 18.3029 8.69215 18.4289 8.89272 18.4289C9.344 18.4289 9.96757 17.6253 12.0003 17.6253C14.033 17.6253 14.6578 18.4289 15.1078 18.4289C15.3084 18.4289 15.4293 18.3029 15.4293 18.1267C15.4293 17.6163 14.3506 15.8574 12.0003 15.8574C9.65 15.8574 8.57129 17.6163 8.57129 18.1267Z"
        fill={`url(#paint1_linear_36566_241404_${id})`}/>
      <path
        d="M6.34142 13.0284C6.34142 11.987 6.99585 11.1436 7.79942 11.1436C8.60556 11.1436 9.25742 11.987 9.25742 13.0284C9.25742 13.463 9.14299 13.8641 8.95142 14.183C8.86578 14.3268 8.72661 14.4309 8.56442 14.4723C8.38442 14.5198 8.11056 14.5713 7.79942 14.5713C7.48699 14.5713 7.21699 14.5198 7.03442 14.4723C6.87258 14.4308 6.73383 14.3268 6.64871 14.183C6.44357 13.8331 6.33736 13.434 6.34142 13.0284ZM14.7423 13.0284C14.7423 11.987 15.3954 11.1436 16.199 11.1436C17.0051 11.1436 17.657 11.987 17.657 13.0284C17.657 13.463 17.5425 13.8641 17.351 14.183C17.3087 14.2543 17.2527 14.3166 17.1863 14.3662C17.1199 14.4159 17.0443 14.4519 16.964 14.4723C16.4627 14.6022 15.9366 14.6022 15.4353 14.4723C15.3549 14.4519 15.2793 14.4159 15.213 14.3662C15.1465 14.3166 15.0906 14.2543 15.0483 14.183C14.8436 13.833 14.7378 13.4339 14.7423 13.0284Z"
        fill={`url(#paint2_linear_36566_241404_${id})`}/>
      <g filter={`url(#filter0_i_36566_241404_${id})`}>
        <path
          d="M6.34142 13.0284C6.34142 11.987 6.99585 11.1436 7.79942 11.1436C8.60556 11.1436 9.25742 11.987 9.25742 13.0284C9.25742 13.463 9.14299 13.8641 8.95142 14.183C8.86578 14.3268 8.72661 14.4309 8.56442 14.4723C8.38442 14.5198 8.11056 14.5713 7.79942 14.5713C7.48699 14.5713 7.21699 14.5198 7.03442 14.4723C6.87258 14.4308 6.73383 14.3268 6.64871 14.183C6.44357 13.8331 6.33736 13.434 6.34142 13.0284ZM14.7423 13.0284C14.7423 11.987 15.3954 11.1436 16.199 11.1436C17.0051 11.1436 17.657 11.987 17.657 13.0284C17.657 13.463 17.5425 13.8641 17.351 14.183C17.3087 14.2543 17.2527 14.3166 17.1863 14.3662C17.1199 14.4159 17.0443 14.4519 16.964 14.4723C16.4627 14.6022 15.9366 14.6022 15.4353 14.4723C15.3549 14.4519 15.2793 14.4159 15.213 14.3662C15.1465 14.3166 15.0906 14.2543 15.0483 14.183C14.8436 13.833 14.7378 13.4339 14.7423 13.0284Z"
          fill="black"/>
      </g>
      <path
        d="M7.64832 11.9816C7.81289 12.1423 7.82317 12.4599 7.67017 12.69C7.51589 12.9189 7.25874 12.9754 7.09417 12.8134C6.9296 12.6527 6.9206 12.3351 7.07232 12.1063C7.2266 11.8761 7.48374 11.8209 7.64832 11.9816ZM15.9914 11.9816C16.1559 12.1423 16.1662 12.4599 16.0145 12.69C15.8602 12.9189 15.6031 12.9754 15.436 12.8134C15.2727 12.6527 15.2637 12.3351 15.4167 12.1063C15.5697 11.8761 15.8268 11.8209 15.9914 11.9816Z"
        fill="#4E506A"/>
      <path
        d="M7.05933 8.33919C7.48618 8.14247 7.82561 8.05762 8.08918 8.05762C8.44533 8.05762 8.66904 8.21062 8.79633 8.45105C9.02133 8.87405 8.91975 8.96662 8.54176 9.04119C7.11976 9.32919 5.69133 10.2523 5.07161 10.8283C4.68461 11.1883 4.31433 10.7898 4.51104 10.476C4.70904 10.1623 5.50618 9.05533 7.05933 8.33919ZM15.2043 8.45105C15.3316 8.21062 15.5553 8.05762 15.9115 8.05762C16.1763 8.05762 16.5145 8.14247 16.9413 8.33919C18.4945 9.05533 19.2916 10.1623 19.4883 10.476C19.685 10.7898 19.316 11.1883 18.929 10.8283C18.308 10.2523 16.8809 9.32919 15.4576 9.04119C15.0796 8.96662 14.9806 8.87405 15.2043 8.45105Z"
        fill={`url(#paint3_linear_36566_241404_${id})`}/>
      <path
        d="M19.0716 22.2861C18.0071 22.2861 17.1431 21.3244 17.1431 20.1377C17.1431 18.9523 17.6008 18.1513 17.9698 17.3773C18.7386 15.7598 18.8904 15.5361 19.0716 15.5361C19.2529 15.5361 19.4046 15.7598 20.1735 17.3773C20.5425 18.1513 21.0002 18.9523 21.0002 20.1377C21.0002 21.3244 20.1362 22.2861 19.0716 22.2861Z"
        fill={`url(#paint4_linear_36566_241404_${id})`}/>
      <path
        d="M19.0718 19.2088C18.6501 19.2088 18.3081 18.8283 18.3081 18.3603C18.3081 17.8897 18.4895 17.5721 18.6361 17.2648C18.9395 16.6245 18.9998 16.5371 19.0718 16.5371C19.1438 16.5371 19.2042 16.6245 19.5077 17.2648C19.6542 17.5734 19.8355 17.8897 19.8355 18.359C19.8355 18.8283 19.4935 19.2088 19.0718 19.2088Z"
        fill={`url(#paint5_linear_36566_241404_${id})`}/>
      <defs>
        <filter id={`filter0_i_36566_241404_${id}`} x="6.34131" y="11.1436" width="11.3154" height="3.42773"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0411227 0 0 0 0 0.0430885 0 0 0 0 0.0922353 0 0 0 0.819684 0"/>
          <feBlend mode="normal" in2="shape" result={`effect1_innerShadow_36566_241404_${id}`}/>
        </filter>
        <linearGradient id={`paint0_linear_36566_241404_${id}`} x1="12.0001" y1="3.82244" x2="12.0001" y2="22.2853"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEEA70"/>
          <stop offset="1" stopColor="#F69B30"/>
        </linearGradient>
        <linearGradient id={`paint1_linear_36566_241404_${id}`} x1="12.0003" y1="15.8574" x2="12.0003" y2="18.4289"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#472315"/>
          <stop offset="1" stopColor="#8B3A0E"/>
        </linearGradient>
        <linearGradient id={`paint2_linear_36566_241404_${id}`} x1="11.9989" y1="11.1436" x2="11.9989" y2="14.5713"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#191A33"/>
          <stop offset="0.872" stopColor="#3B426A"/>
        </linearGradient>
        <linearGradient id={`paint3_linear_36566_241404_${id}`} x1="12.0001" y1="8.05814" x2="12.0001" y2="10.9703"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#E78E0D"/>
          <stop offset="1" stopColor="#CB6000"/>
        </linearGradient>
        <linearGradient id={`paint4_linear_36566_241404_${id}`} x1="19.0716" y1="21.0647" x2="19.0716" y2="16.7473"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#35CAFC"/>
          <stop offset="1" stopColor="#007EDB"/>
        </linearGradient>
        <linearGradient id={`paint5_linear_36566_241404_${id}`} x1="19.0716" y1="16.5367" x2="19.0716" y2="19.2084"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#6AE1FF" stopOpacity="0.287"/>
          <stop offset="1" stopColor="#A8E3FF" stopOpacity="0.799"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotSad;
