import {API, encryptedData} from '../../Services/Api';
import dayjs from "dayjs";
const request = new API();

export const getAdSpotFilters = () =>
  request.api.get(`ad_spot/choice_filters/`, null);
export const getAdSpotPresets = () =>
  request.api.get(`ad_spot/filter_presets/`, null);
export const createAdSpotPreset = (data) =>
  request.api.post(`ad_spot/filter_presets/`, encryptedData(data));
export const deleteAdSpotPreset = (record) =>
  request.api.delete(`ad_spot/filter_presets/${record.id}/`, null);
export const cancelDeleteAdSpotPreset = (record) =>
  request.api.delete(`ad_spot/filter_presets/${record.id}/`, null);
export const updateAdSpotPreset = (data) =>
  request.api.patch(`ad_spot/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));
export const getAds = (data) => {
  const {sort, page_size, page_number, ...others} = data;
  return data?.hasOwnProperty('creative_filters') ?
    request.api.post(`ad_spot/search/?sort=${sort}&page_size=${page_size}&page_number=${page_number}`, encryptedData({...others}))
    :
    request.api.get(`ad_spot/search/default/?sort=${sort}&page_size=${page_size}&page_number=${page_number}`)
}
export const getAdsByID = (data) =>
  data?.same ?
    request.api.get(`ad_spot/advertiser/${data?.id}/?page_number=${data?.page_number}&page_size=${data?.page_size}&sort=${data?.sort}&domain_fld=${data?.domain}`)
    :
    request.api.get(`ad_spot/domain/?page_number=${data?.page_number}&page_size=${data?.page_size}&sort=${data?.sort}&domain_fld=${data?.domain}`);

export const getSuggestions = (data) => {
  const {key, value} = data;
  return request.api.post(`ad_spot/search/suggestion/${key}/`, encryptedData({value: value}));
}

//Inner pages reqs
//AdSpot Overview main Block Info
export const getOverviewMainInfo = (data) =>
  data?.same ?
    request.api.get(`/ad_spot/ads/overview/`, {
      ...(data?.domain && { domain_fld: data?.domain }),
      ...(data?.id && { advertiser: data?.id }),
    })
    :
    request.api.get(`/ad_spot/ads/${data?.ad_id}/overview/`, {
      ...(data?.domain && { domain_fld: data?.domain }),
      ...(data?.internal_shop_id && { internal_shop_id: data?.internal_shop_id }),
    });

//AdSpot Overview Card Block Info
export const getOverviewCardInfo = (data) =>
  request.api.get(`/ad_spot/ads/${data?.ad_id}/`);

//AdSpot Overview Chart
export const getOverviewChartInfo = (data) =>
  request.api.get(`/ad_spot/ads/${data?.ad_id}/detailed_charts/`, {
    date__gte: dayjs(data?.date_range?.min).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date_range?.max).format('YYYY-MM-DD'),
    same_landing_pages: data?.same_landing_pages,
    historical_data_view: data?.historical_data_view
  });

//AdSpot Overview report
export const getOverviewReport = (data) =>
  request.api.get(`/ad_spot/ads/${data?.id}/detailed_charts/report/`, {
    date__gte: dayjs(data?.date_range?.min).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date_range?.max).format('YYYY-MM-DD'),
  });

//AdSpot Overview same posts
export const getOverviewSimilar = (data) =>
  request.api.get(`/ad_spot/ads/${data?.ad_id}/similar/`, {
    page_number: data?.page_number,
    page_size: data?.page_size,
    sort: data?.sort
  });

//AdSpot Overview products modal
export const getOverviewStoreProductsModal =  (data) => request.api.post(`/sales_tracker/products/store/${data.store_id}/`,
  encryptedData({popup: true, page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: { min: data?.date_range?.min, max: data?.date_range?.max }}));

//AdSpot get Products Tab
export const getProductsByStoreIdAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.sort}/`, {
    date__gte: dayjs(data?.date__gte).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date__lte).format('YYYY-MM-DD'),
    price__gte: data?.price__gte,
    price__lte: data?.price__lte,
    ads__gte: data?.ads__gte,
    ads__lte: data?.ads__lte,
    ...(data?.created_at__gte && {created_at__gte: dayjs(data?.created_at__gte).format('YYYY-MM-DD')}),
    ...(data?.created_at__lte && {created_at__lte: dayjs(data?.created_at__lte).format('YYYY-MM-DD')}),
    search: data?.search,
    page_number: data?.page_number,
    page_size: 50
  });

//AdSpot get products count
export const getProductsCountByStoreIdAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.sort}/count/`, {
    date__gte: dayjs(data?.date__gte).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date__lte).format('YYYY-MM-DD'),
    price__gte: data?.price?.min,
    price__lte: data?.price?.max,
    ads__gte: data?.ads?.min,
    ads__lte: data?.ads?.max,
    ...(data?.created_at__gte && {created_at__gte: dayjs(data?.created_at?.min).format('YYYY-MM-DD')}),
    ...(data?.created_at__lte && {created_at__lte: dayjs(data?.created_at?.max).format('YYYY-MM-DD')}),
    search: data?.search
  });

//AdSpot Products report
export const getProductsReportAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.id}/${data?.sort}/report/`, {
    date__gte: dayjs(data?.date_range?.min).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date_range?.max).format('YYYY-MM-DD'),
  });

//AdSpot get products filters
export const getProductsFiltersByStoreIdAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.sort}/filters/`, {
    date__gte: dayjs(data?.date__gte).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date__lte).format('YYYY-MM-DD'),
  });

//AdSpot get product info
export const getProductInfoAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.shopify_product_id}/detailed_charts/info/`, {
    date__gte: dayjs(data?.date__gte).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date__lte).format('YYYY-MM-DD'),
  });

//AdSpot product chart info
export const getProductChartAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.shopify_product_id}/detailed_charts/`, {
    date__gte: dayjs(data?.date__gte).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date__lte).format('YYYY-MM-DD'),
    historical_data_view: data?.historical_data_view
  });

//AdSpot product report
export const getProductReportAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.shopify_product_id}/detailed_charts/report/`, {
    date__gte: dayjs(data?.date_range?.min).format('YYYY-MM-DD'),
    date__lte: dayjs(data?.date_range?.max).format('YYYY-MM-DD'),
  });

//AdSpot product ads
export const getProductAdsAdSpot = (data) =>
  request.api.get(`/ad_spot/products/${data?.internal_shop_id}/${data?.shopify_product_id}/ads/`, {
    page_number: data?.page_number,
    page_size: data?.page_size,
    sort: data?.sort
  });
