import React from 'react';

const MinMaxInputDivider = ({width, height, color, className, style, ...props}) => {
  return (
    <svg width={width ? width : '48'}
         height={height ? height : '48'}
         className={className}
         style={style}
         viewBox="0 0 7 10"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00409 4.98037C6.00485 4.84939 5.97963 4.71954 5.92986 4.59828C5.8801 4.47702 5.80677 4.36673 5.71409 4.27373L1.71409 0.292698C1.62085 0.199902 1.51016 0.126292 1.38834 0.0760708C1.26652 0.0258493 1.13595 9.53674e-07 1.00409 9.53674e-07C0.872232 9.53674e-07 0.741664 0.0258493 0.619842 0.0760708C0.49802 0.126292 0.38733 0.199902 0.294091 0.292698C0.200853 0.385494 0.126892 0.49566 0.0764313 0.616904C0.0259709 0.738149 -1.96485e-09 0.868098 0 0.999332C1.96486e-09 1.13057 0.0259709 1.26052 0.0764313 1.38176C0.126892 1.50301 0.200853 1.61317 0.294091 1.70597L3.60409 4.98037L0.424091 8.26472C0.321802 8.35628 0.239593 8.46785 0.182607 8.59247C0.125621 8.71709 0.0950833 8.85207 0.0929041 8.98897C0.0907248 9.12588 0.11695 9.26175 0.169941 9.38811C0.222932 9.51446 0.301549 9.62857 0.400873 9.72331C0.500198 9.81804 0.618093 9.89136 0.747188 9.93868C0.876282 9.986 1.0138 10.0063 1.15113 9.9983C1.28847 9.99031 1.42267 9.95421 1.54535 9.89224C1.66802 9.83028 1.77653 9.74379 1.86409 9.63818L5.72409 5.65714C5.90041 5.47514 6.00052 5.23317 6.00409 4.98037Z"
        />
    </svg>
  );
};

export default MinMaxInputDivider;
