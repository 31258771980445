import React from 'react';
import SupplierCardSkeleton from "./SupplierCardSkeleton";

const SuppliersPageSkeleton = () => {
  return (
    <div>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          <div className="title-wrapper-icon">
            <span className={'link skeleton'} style={{width: 47, height: 48}}/>
          </div>
          <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'} style={{width: 241, height: 32}}/>
            <span className={'link skeleton'} style={{width: 339, height: 24}}/>
          </div>
        </div>
      </div>

      <div className="suppliers-page-cards-wrapper">
        {
          [1,2,3].map(el => <SupplierCardSkeleton id={el} key={el} />)
        }
      </div>
    </div>
  );
};

export default SuppliersPageSkeleton;
