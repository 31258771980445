import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Row, Col, Badge } from 'antd';
import cls from 'classname';
import actions from './actions';
import Logo from '../../Components/Logo';
import Sidebar from '../Sidebar';
import Icon from '../../Icon';
import User from '../User';
import ModalShopifyConnect from '../ShopifyStore/ModalShopifyConnect';
import ModalShopifyImport from '../ShopifyStore/ModalShopifyImport';
import ModalAutoDSImport from '../AutoDS/ModalAutoDSImport';
import ModalAutoDSUnavailable from '../AutoDS/ModalAutoDSUnavailable';
import ModalAutoDSConnect from "../AutoDS/ModalAutoDSConnect";
import ModalAutoDSDisconnect from "../AutoDS/ModalAutoDSDisconnect";
import ButtonComponent from "../../Components/Button";
import ConfirmEmail from "./ConfirmEmail";
import './styles.less';

export const LayoutComponent = (
  {
    auth,
    nav,
    children,
    openSidebar,
    openCartModal,
    selectedPlan,
    isAdmin,
    setShowConfirm
  }) => {
  const { theme, isShowSideBar, isShowMenu, isMobile, showConfirm } = nav;
  const { pathname } = useLocation();
  const isUpcomingPage = pathname === '/upcoming-drops';
  const isSelectedPlan = !!Object.keys(selectedPlan).length;

  function handleOpenCartModal() {
    if (isSelectedPlan) openCartModal(true);
  }

  useEffect(() => {
    if (!auth?.email_confirmed && showConfirm !== false) setShowConfirm(true)
    else setShowConfirm(false)
  }, [auth?.email_confirmed])

  return (
    <Layout
      id="global-wrap"
      className={cls('global-wrap', {
        'theme-light': theme === 'light',
        'theme-dark': theme === 'dark',
        'closed-sidebar': !isShowMenu,
        'opened-sidebar': isShowMenu,
        'small-sidebar': isShowSideBar,
        'big-sidebar': !isShowSideBar,
      })}
    >
      <header className="header">
        <div className="header_logo">
          <Logo theme={theme} isAdmin={isAdmin}/>
        </div>
        <div className="header_right-part">
          {isUpcomingPage && (
            <div
              className="header_item header_item--cart"
              onClick={handleOpenCartModal}
            >
              <Badge dot={isSelectedPlan}>
                <Icon type="cart_upcoming" role="icon"/>
              </Badge>
            </div>
          )}

          {isMobile && (
            <ButtonComponent
              className="menu-button"
              onClick={() => openSidebar(!nav.isShowMenu)}
              type="link"
            >
              <Icon type="menu" role="icon" className={cls({active: nav.isShowMenu})}/>
            </ButtonComponent>
          )}

        </div>
      </header>

      {
         showConfirm ?
          <ConfirmEmail setVisible={setShowConfirm} isMobile={isMobile} />
          :
          null
      }

      <div className="main-container">
        {
          auth &&
          <Sidebar openSidebar={openSidebar}>
            <User/>
          </Sidebar>
        }
        <main className={`main-content ${pathname.slice(1).replace('/', '_') === 'ad-spot' ? 'fad-spot' : pathname.slice(1).replace('/', '_')}`}>
          <Row>
            <Col flex="auto">{children}</Col>
          </Row>

          {
            isAdmin
              ? null
              : <>
                <ModalShopifyConnect/>
                <ModalShopifyImport/>
                <ModalAutoDSImport/>
                <ModalAutoDSUnavailable/>
                <ModalAutoDSConnect/>
                <ModalAutoDSDisconnect/>
              </>
          }
        </main>
      </div>
    </Layout>
  );
};

LayoutComponent.propTypes = {
  nav: PropTypes.shape({
    isMobile: PropTypes.bool,
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isShowCartModal: PropTypes.bool,
    theme: PropTypes.string,
  }).isRequired,
  openSidebar: PropTypes.func.isRequired,
  openCartModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedPlan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth.userInfo,
  nav: state.nav,
  selectedPlan: state.onBoarding.selectedPlan,
  isAdmin: state.auth.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  openSidebar(value) {
    dispatch(actions.changeNavSetProp('isShowMenu', value));
  },
  openCartModal(value) {
    dispatch(actions.changeNavSetProp('isShowCartModal', value));
  },
  setShowConfirm(value) {
    dispatch(actions.changeNavSetProp('showConfirm', value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
