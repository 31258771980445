import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import PushNotifications from './PushNotifications';
import EmailNotifications from './EmailNotifications';
import SettingCreators from '../../Containers/SettingPage/reducer';
import './styles.less';

function NotificationsSubPage(props) {
  const { t } = useTranslation();
  const notificationText = {
    upcoming_drops: t('Upcoming Drops'),
    support: t('Support'),
    messages: t('Messages'),
    billing: t('Billing'),
    new_features: t('New Features'),
    newsletter: t('Newsletter'),
  };

  return (
    <Spin size="large" spinning={props.saveFetching}>
      <div className="notifications-subpage">
        <PushNotifications {...props} notificationText={notificationText} />
        <EmailNotifications {...props} notificationText={notificationText} />
      </div>
    </Spin>
  );
}
const mapStateToProps = (state) => ({
  notifications: state.setting.notifications,
  fetching: state.setting.fetching,
  saveResult: state.setting.saveResult,
  saveFetching: state.setting.saveFetching,
  errors: state.setting.errors,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  changeNotifications: (notifications) =>
    dispatch(SettingCreators.changeNotificationsListRequest(notifications)),

  resetErrors: () => dispatch(SettingCreators.settingsErrorsReset()),
  resetResult: () => dispatch(SettingCreators.settingsSaveResultReset()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsSubPage);
