import React, {useState} from 'react';
import cls from "classname";
import './MainBlockStat.less';

const MainBlockStat = ({t, title, value, onClick}) => {

  const [visible, setVisible] = useState(false);

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisible(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    setVisible(false);
    target.classList.remove('overflowing')
  }

  return (
    <div className={'main-block-stat-wrapper'} style={{display: value !== undefined ? 'block':'none', position: visible ? 'relative' : 'static'}}>
      <h4 className="main-block-stat-title">
        {t(title)}
      </h4>
      <p className={cls("main-block-stat-value", {
        'main-block-stat-value--clickable': Boolean(onClick)
      })}
         data-text={value}
         onClick={onClick && onClick}
         onMouseOver={mouseOver}
         onMouseLeave={mouseLeave}
      >
        {title?.includes('Tracked By')  ?  `${value} Others` : value}
      </p>
    </div>
  );
};

export default MainBlockStat;
