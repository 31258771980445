/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {Form, Row, Col, Select, Checkbox} from 'antd';
import _ from 'lodash';
import Icon from '../../Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import utils from '../../Utils/utils';
import cls from "classname";
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import useAutocomplete from "../../hooks/useAutocomplete";
import './Form.less';

const { Option } = Select;

function StepBillingForm(props) {
  const { countries,
    presetBillingAddress, userInfo, isMobile, theme, saveAddress } = props;

  /*United States = id: 1450*/
  const [selectCountry, setSelectCountry] = useState(1450);
  const [state, setState] = useState({ address: { country: 1450 } });
  const [selected, setSelected] = useState(true);
  const [showArrow, setShowArrow] = useState(true);

  const [errorText, setErrorText] = useState(null)
  const [focused, setFocused] = useState(false)
  const [focusedAddress, setFocusedAddress] = useState(false)

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const isPreset = !!presetBillingAddress;
  useEffect(() => {
    form.getFieldsValue();
  }, []);

  useEffect(() => {
    if (isPreset) {
      setSelectCountry(presetBillingAddress['address']['country']);
      setState({ ...presetBillingAddress });
    } else {
      if(userInfo?.billing_info?.country?.id) setSelectCountry(userInfo?.billing_info?.country?.id)
      setState({ ...state,
        first_name: userInfo?.first_name,
        email: userInfo?.email,
        last_name: userInfo?.last_name,
        country_code: userInfo?.billing_info?.phone_code?.id,
        address: {country: userInfo?.billing_info?.country?.id || null},
      });
    }
  }, [isPreset]);

  useEffect(() => {
    if (!focused) {
      if ((!/^[a-zA-Z\s]+$/.test(state?.first_name) || !/^[a-zA-Z\s]+$/.test(state?.last_name)) && form.getFieldValue('first_name') &&
        form.getFieldValue('last_name')) {
        setErrorText(t('You can only use letters to insert your name'))
      }
      else {
        setErrorText(null)
      }
    }
  }, [state?.first_name, state?.last_name, focused])

  const onFinish = async values => {

    const newData = {
      name: `${values.first_name} ${values.last_name}`,
      address: values.address,
    };

    delete newData.address.country;

    props.onSubmit({ ...values, address: { ...values.address, country: Number(selectCountry) } });
  };

  const handleBack = () => {
    const values = form.getFieldsValue();
    const newData = {
      name: `${values.first_name} ${values.last_name}`,
      address: values.address,
    };
    delete newData.address.country;
    props.onClose({ ...values, address: { ...values.address, country: selectCountry } });
  };

  function handleChange(changedValues) {
    setState({ ...state, ...changedValues, address: { ...state?.address, ...changedValues?.address } });
    saveAddress({...form.getFieldsValue()});
  }

  function handleClearError(e, field) {
    if ((field === 'first_name' || field === 'last_name') && focused === field) setFocused(field);
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
  }

  function handleBlur() {
    setFocused(false)
  }
  const countriesArr = _.cloneDeep(countries);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  const optionsCountry = countriesArr
  ?
    countriesArr
    .sort(byField('name'))
    .map(el => ({
      label: el.name,
      value: el.id,
      code: el?.country_code
    })) : [];

  const optionsState = countriesArr ?
  countriesArr
   .filter(country => country.id === 1450)[0]['state']
   ?.sort((a, b)=> a.localeCompare(b))
   .map(el => ({
      label: el,
      value: el,
    }))
    : [];

  const addressInput = useRef(null);

  // const suggestions = useAutocomplete(addressInput?.current?.value, optionsCountry.filter(el => el?.value === selectCountry)?.[0]?.code);
  const suggestions = [];

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={handleChange}
      validateTrigger='onSubmit'
      className='account-form payment-form on-boarding on-boarding_checkout'
      autoComplete='off'
      requiredMark={false}
    >
      <Row gutter={{ xs: 12, md: 16 }} className='info-block'>
        <Col span={24}>
          <span className='block-title'>{t('Contact Information')}</span>
        </Col>
      </Row>

      <Row gutter={{ xs: 12, md: 16 }}>
        <Col span={24}>
          <Form.Item
            label={t('Email Address')}
            name='email'
            validateTrigger={'onBlur'}
            rules={[
              { type: 'email', message: t('The email you entered is incorrect') },
            ]}
            disabled
            initialValue={isPreset ? presetBillingAddress['email'] : userInfo?.email}
            className={state?.['email'] ? 'active' : ''}
          >
            <InputComponent
              disabled={true}
              onFocus={e => handleClearError(e, 'email')}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label=''
            name='send_news'
            valuePropName='checked'
            className={'checkbox-wrap step-billing-form-checkbox'}
            initialValue={isPreset ? presetBillingAddress['send_news'] : true}
          >

            <Checkbox>
              {t('Email me with news and offers')}
            </Checkbox>

          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 12, md: 16 }} className='info-block'>
        <Col span={24}>
          <span className='block-title' style={{ padding: '4px 0 0' }}>
            {t('Billing Address')}
          </span>
        </Col>
      </Row>

      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text onboarding-error-text', {'error-text_empty': !errorText})}
                    style={{display: errorText && !focused ? 'block' : 'none'}}
        >
          {errorText && <Icon type={'alert_triangle_error'} role={'icon'} />}
          {errorText}
        </div>}
      </Form.Item>

      <Row gutter={{ xs: 12, md: 16 }}>
        <Col span={Number(selectCountry) === 1450 ? 12 : 24}>
          <Form.Item
            label={t('Country')}
            name={['address', 'country']}
            rules={[{ required: true, message: 'Select a Country' }]}
            initialValue={isPreset ? presetBillingAddress['address']['country'] : (userInfo?.billing_info?.country?.id || 1450)}
            className={state?.['address']['country'] ? 'active' : ''}
          >
            <Select
              className={'custom-select'}
              popupClassName={`custom-dropdown${theme === 'dark' ? ' dark' : ''}`}
              showSearch
              showArrow={true}
              listHeight={176}
              open={!showArrow}
              suffixIcon={
                <div
                  className={cls('arrow', {
                    arrow_selected: !showArrow,
                  })}
                >
                  <ArrowSelectDown color={!showArrow && '#225AEA'} />
                </div>
              }
              notFoundContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className="empty-state_icon-select">
                    <Icon width={24} height={24}
                          role="icon"
                          type="empty_warning"
                          fill={'#707ba0'}
                    />
                  </div>
                  <div style={{color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px'}} className="empty-state_wrapper-select">
                    No results found
                  </div>
                </div>
              }
              style={{ width: '100%' }}
              value={Number(selectCountry)}
              onChange={value => setSelectCountry(value)}
              onFocus={e => handleClearError(e, ['address', 'country'])}
              optionFilterProp='options'
              onDropdownVisibleChange={() => {
                setShowArrow(!showArrow);
              }}
              onSelect={() => setShowArrow(!showArrow)}
              getPopupContainer={trigger => trigger.parentNode}
              onScroll={e => e.stopPropagation()}
              filterOption={(input, { key }) => utils.includeSearch(key, input)}
            >
              {optionsCountry.map(el => {
                return (
                  <Option value={el.value} key={el.label}>
                    <span onClick={e => e.preventDefault()}>{el.label}</span>
                    {selectCountry === el.label ? <Icon role={'icon'} type={'select_dropdown_check_mark'} className={'check-icon'}/> : null}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        {Number(selectCountry) === 1450 ? (
          <Col span={12}>
            <Form.Item
              label={t('State')}
              name={['address', 'state']}
              rules={[{ required: true, message: 'Select a State' }]}
              dependencies={['address', 'country']}
              initialValue={isPreset ? presetBillingAddress['address']['state'] : null}
              className={state?.['address']['state'] ? 'active' : ''}
            >
              <Select
                className={'custom-select'}
                popupClassName={`custom-dropdown${theme === 'dark' ? ' dark' : ''}`}
                showSearch
                showArrow={true}
                listHeight={176}
                suffixIcon={<ArrowSelectDown/>}
                style={{ width: '100%' }}
                onFocus={e => handleClearError(e, ['address', 'state'])}
                optionFilterProp='options'
                getPopupContainer={trigger => trigger.parentNode}
                onScroll={e => e.stopPropagation()}
                filterOption={(input, { value }) => utils.includeSearch(value, input)}
              >
                {optionsState.map(el => {
                  return (
                    <Option value={el.value} key={el.value}>
                      <span onClick={e => e.preventDefault()}>{el.label}</span>
                      {
                        form.getFieldValue('address')?.state === el.label
                          ? <Icon role={'icon'} type={'select_dropdown_check_mark'} className={'check-icon'}/>
                          : null
                      }
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item
            label={t('First Name')}
            name='first_name'
            rules={[
              {
                type: 'string',
                message: t('You can only use letters to insert your name'),
                pattern: /^[a-zA-Z ]+$/,
                transform(value) {
                  if (value) return value.trim();
                },
              },
            ]}
            initialValue={isPreset ? presetBillingAddress['first_name'] : userInfo?.first_name}
            className={state?.['first_name'] ? 'active' : ''}
            validateTrigger={'onBlur'}
          >
            <InputComponent onFocus={e => handleClearError(e, 'first_name')}
                            onBlur={() => handleBlur()}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Last Name')}
            name='last_name'
            rules={[
              {
                type: 'string',
                message: t('You can only use letters to insert your name'),
                pattern: /^[a-zA-Z ]+$/,
                transform(value) {
                  if (value) return value.trim();
                },
              },
            ]}
            initialValue={isPreset ? presetBillingAddress['last_name'] : userInfo?.last_name}
            className={state?.['last_name'] ? 'active' : ''}
            validateTrigger={'onBlur'}
          >
            <InputComponent onFocus={e => handleClearError(e, 'last_name')}
                            onBlur={() => handleBlur()}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('Address')}
            name={['address', 'address']}
            initialValue={isPreset ? presetBillingAddress['address']['address'] : null}
            className={state?.['address']['address'] ? 'active' : ''}
            validateTrigger={'onBlur'}
          >
            <input ref={addressInput}
                   onFocus={e => {
                     handleClearError(e, ['address', 'address']);
                     setSelected(false);
                     setFocusedAddress(true)
                   }}
                   onBlur={() => setTimeout(() => setFocusedAddress(false), 500)}
                   className={'google-autocomplete-input google-address'}
                   placeholder={''}
            />
          </Form.Item>
          {
            !selected && focusedAddress && suggestions?.items?.length ?
              <div className="suggestions-wrapper">
                {
                  suggestions?.items?.map(el => {
                    const title = () => {
                      let arr = el?.title.split(el?.title.slice(el?.highlights?.title?.[0]?.start, el?.highlights?.title?.[0]?.end))
                      return (
                        <span>
                          {
                            arr?.[0]
                          }
                          <b>
                            {el?.title.slice(el?.highlights?.title?.[0]?.start, el?.highlights?.title?.[0]?.end)}
                          </b>
                          {
                            arr?.[1]
                          }
                        </span>
                      )
                    }
                    return <div key={el?.title}
                         className='suggestions-item'
                         onClick={() => {
                           setSelected(true);
                           setState(prev => ({
                             ...prev,
                             address: {
                               ...prev?.address,
                               address: el?.address?.street ?
                                 `${el?.address?.street}${el?.address?.houseNumber
                                   ?
                                   ', ' + el?.address?.houseNumber
                                   :
                                   ''}`
                                 :
                                 el?.address?.district ? el?.address?.district : el?.address?.city ? el?.address?.city : el?.address?.county,
                               city: el?.address?.city ? el?.address?.city : 'City',
                               postal_code: el?.address?.postalCode ? el?.address?.postalCode : ''
                             }
                           }));
                           form.setFieldsValue({
                             "email": form.getFieldValue('email'),
                             "send_news": form.getFieldValue('send_news'),
                             "address": {
                               "country": form.getFieldValue(['address', 'country']),
                               address: el?.address?.street ? `${el?.address?.street}${el?.address?.houseNumber ? ', ' + el?.address?.houseNumber : ''}` : el?.address?.district ? el?.address?.district : el?.address?.city ? el?.address?.city : el?.address?.county,
                               city: el?.address?.city ? el?.address?.city : 'City',
                               postal_code: el?.address?.postalCode ? el?.address?.postalCode : ''
                             },
                             "first_name": form.getFieldValue('first_name'),
                             "last_name": form.getFieldValue('last_name')
                           });
                           saveAddress({...form.getFieldsValue()});
                         }}
                    >
                      {title()}
                    </div>
                  })
                }
              </div>
              :
              null
          }
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('City')}
            name={['address', 'city']}
            initialValue={isPreset ? presetBillingAddress['address']['city'] : null}
            className={state?.['address']['city'] ? 'active' : ''}
            validateTrigger={'onBlur'}
          >
            <input onFocus={e => handleClearError(e, ['address', 'city'])}
                   className={'google-autocomplete-input'}
                   placeholder={''}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('Postal Code')}
            name={['address', 'postal_code']}
            initialValue={isPreset ? presetBillingAddress['address']['postal_code'] : null}
            className={state?.['address']['postal_code'] ? 'active' : ''}
            validateTrigger={'onBlur'}
          >
            <InputComponent onFocus={e => handleClearError(e, ['address', 'postal_code'])}/>
          </Form.Item>
        </Col>
      </Row>
      {
        isMobile
          ? (
            <>
              <div className={'double-btn-wrp step-billing-form-btn-wrap'} style={{ marginTop: 48 }}>
                <ButtonComponent type={'primary'}
                        className={'btn-primary step-billing-form-prev'}
                        onClick={handleBack}
                >
                  <Icon role='icon' type='arrow_forward_onboarding' />
                  Return To Plans
                </ButtonComponent>

                <ButtonComponent type={'primary'}
                        className={'btn-primary step-billing-form-next'}
                        disabled={
                          form.getFieldsError().filter(({ errors }) => errors.length).length ||
                          !(
                            form.getFieldValue('first_name') &&
                            form.getFieldValue('last_name') &&
                            form.getFieldValue('address')?.address &&
                            form.getFieldValue('address')?.city &&
                            form.getFieldValue('address')?.postal_code &&
                            form.getFieldValue('address')?.country &&
                            (Number(selectCountry) === 1450 ? form.getFieldValue('address')?.state : true)
                          )
                        }
                        htmlType='submit'
                >
                  {t('Next Step')}
                  <Icon role='icon'
                        type='arrow_forward_onboarding'
                        color={'#FFFFFF'}
                        borderless
                        style={{ marginLeft: 16 }}/>
                </ButtonComponent>
              </div>
            </>)
          : (
            <div className='dialog-footer' style={{ justifyContent: 'space-between' }}>
              <ButtonComponent className={'btn-primary step-billing-form-prev'} onClick={handleBack}>
                <Icon role='icon' type='arrow_forward_onboarding' />
                Return To Plans
              </ButtonComponent>

              <ButtonComponent
                type="primary"
                className={'btn-primary btn-primary_animation small step-billing-form-next'}
                style={{ minWidth: 217 }}
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length).length ||
                  !(
                    form.getFieldValue('email') &&
                    form.getFieldValue('first_name') &&
                    form.getFieldValue('last_name') &&
                    form.getFieldValue('address')?.address &&
                    form.getFieldValue('address')?.city &&
                    form.getFieldValue('address')?.postal_code &&
                    form.getFieldValue('address')?.country &&
                    (Number(selectCountry) === 1450 ? form.getFieldValue('address')?.state : true)
                  ) || errorText
                }
                htmlType='submit'
              >
                {t('Next Step')}
                <Icon role='icon'
                      type='arrow_forward_onboarding'
                      color={'#FFFFFF'}
                      borderless
                      style={{ marginLeft: 16 }}/>
              </ButtonComponent>
            </div>
          )
      }
    </Form>
  );
}

export default StepBillingForm;
