import React from 'react'
import {useTranslation} from 'react-i18next'

import {Collapse} from 'antd'

import Icon from '../Icon'
import utils from '../Utils/utils';

import './OrderSummaryCard.less'

let acc = require('accounting')
const {Panel} = Collapse

function OrderSummaryCard({isMobile, plan, userInfo, onSubmit, discount, disabled, priceType, ...props}) {
  const price = Object.values(plan).reduce((accumulator, currentValue) => (accumulator + currentValue?.prices?.[priceType].price), 0) * utils.multiplyPriceType[priceType]

  const {t} = useTranslation()
  let discountCash = 0

  if (discount) {
    discountCash = discount.type === 'value' ? discount.value : (price / 100) * discount.value
  }

  const headerRow = (
    <div className={'summary-header-row'}>

      <span>{t('Summary')}</span>
      <Icon role={'icon'} type={'arrow_select_down'}/>

      <span style={{marginLeft: 'auto'}}>
        $0
      </span>
    </div>)

  const orderSummaryCard = (
    <div className='order-summary-card'>

      {
        props.children
      }

      <div className={'divider'}/>

      <div className='row' style={{marginBottom: 16}}>
        <span className='name'>{t('Total')}</span>
        <span className={`price${discount ? ' with-discount' : ''}`}>
          $0
        </span>
      </div>

      <div className='row'>
        <span className='name'>{t('Discount')}</span>
        <span className={`price${discount ? ' with-discount' : ''}`}>
          {
            discount
              ? (
                discount.type === 'value'
                  ? t('_value_ Discount Applied After Trial Ends', {value: discount.value})
                  : t('_value_ Discount Applied After Trial Ends', {value: `${discount.value}%`}))
              : '-'
          }
        </span>
      </div>

      <div className='row subtotal'>
        <div>
          <span className='name'>{t('Subtotal Today')}</span>
          <span className='sub-name'>And then {`$${acc.formatNumber(price - discountCash, discount ? 2 : 0, '.')}`}/{t(priceType === 'monthly' ? 'month' : 'year')} after trial ends</span>
        </div>

        <span className='price'>$0</span>
      </div>

    </div>
  )

  if (isMobile) {
    return (
      <Collapse
        className={'order-summary-card_mobile'}
        expandIcon={() => {
        }}
      >
        <Panel header={headerRow} key="1">
          {
            orderSummaryCard
          }
        </Panel>
      </Collapse>
    )
  } else {
    return orderSummaryCard
  }
}

export default OrderSummaryCard
