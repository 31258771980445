import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import Icon from '../../Icon';
import './styles.less';

export const DashboardWelcome = ({
  skeleton,
  userInfo,
  greetings,
  stInfo,
  handleNavigate,
  isMobile,
  isDisabled,
  isDemo
}) => {
  const { t } = useTranslation();

  const { avatar } = userInfo;

  const defaultAvatar = (
    <Icon
      type="user_default"
      isMobile={isMobile}
      role="icon"
      width={isMobile ? 48 : 64}
      height={isMobile ? 48 : 64}
    />
  );

  if (skeleton) {
    return (
      <div className="welcome-block skeleton">
        <div className="user-avatar-wrapper">
          <span className="user_avatar link skeleton" style={{borderRadius: '50%', width: isMobile ? 48 : 64, height: isMobile ? 48 : 64}}  />

          <div className="user-detailed">
            <div className="title" />
            <div className="text" />
          </div>
        </div>

        <div className="info-block-wrapper">
          <div className="info-block">
            <span className="text" />
            <span className="numbers clickable" />
          </div>

          <div className="info-block">
            <span className="text" />
            <span className="numbers clickable" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="welcome-block">
      <div className="user-avatar-wrapper">
        <Avatar
          className="user_avatar"
          size={isMobile ? 48 : 64}
          {...(avatar ? { src: avatar } : { icon: defaultAvatar })}
        />

        <div className="user-detailed">
          <span className="title">{greetings}</span>
          <span className="text">
            {t('Here’s an overview of your account')}
          </span>
        </div>
      </div>

      { !isDemo ?
        <div className="info-block-wrapper">
        <div className="info-block">
          <span className="text">{t('Tracked Stores')}</span>
          <span
            className="numbers clickable"
            onClick={() => {
              if (!isDisabled) handleNavigate('/sales-tracker/stores/', {
                state: {
                  name: 'All Products',
                  link: '/sales-tracker/stores/',
                }
              })
            }}
          >
            {stInfo?.stores?.used}/{stInfo?.stores?.allowed}
          </span>
        </div>

        <div className="divider"/>

        <div className="info-block">
          <span className="text">{t('Tracked Products')}</span>
          <span
            className="numbers clickable"
            onClick={() => {
              if (!isDisabled) handleNavigate('/sales-tracker/products/', {
                state: {
                  name: 'All Products',
                  link: '/sales-tracker/products/',
                }
              })
            }
            }
          >
            {stInfo?.products?.used}/{stInfo?.products?.allowed}
          </span>
        </div>
      </div>
        :
        null
      }
    </div>
  );
};

export default DashboardWelcome;
