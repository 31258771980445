import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { Layout, Spin } from 'antd'
import Images from '../Images'
import ButtonComponent from "../Components/Button";

function AlreadyRegisteredPage() {

  let navigate = useNavigate();;

  function handleNavigate(path) {
    navigate(path);
  }

  return (
    <Layout>
      <Spin size="large" spinning={false}>
      <div className="login-row"
           style={{ background: `center / cover url(${Images.loginBg})`, backgroundSize: 'cover' }}
      >
        <div className="login-col">
          <div className="login-logo">
            <img src={Images.logoLogin} alt=""/>
          </div>
          <div className="page-content">
            <img src={Images.stopWatch} style={{marginBottom: 56}} alt=""/>
            <div className="page-title" style={{color: '#EB5757'}}>Uh-oh!</div>
            <div className="page-text">It looks like the user with such email is already registered</div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <ButtonComponent type="primary login" className="btn-primary" style={{width: '165px'}} onClick={() => handleNavigate('/login')}>
                  to login
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
      </Spin>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.nav.isDesktop,
    isMobile: state.nav.isMobile,
  }
}

export default connect(mapStateToProps)(AlreadyRegisteredPage)
