import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import acc from 'accounting';
import cls from 'classname';
import {Select, Tooltip} from 'antd';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import InputComponent from '../../../Components/Input';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import useDebounce from '../../../hooks/use-debounce';
import {useTranslation} from 'react-i18next';

const {Option} = Select;

const DatabaseProductsPagination = (props) => {
  const {
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    totalProducts,
    disabled,
    isTopStores=false,
    isTopProducts=false,
    isTrial,
    isShort=false
  } = props;
  const [showArrow, setShowArrow] = useState(true);
  const {t} = useTranslation();

  const [page, setPage] = useState(1);

  const [visible, setVisible] = useState(false);

  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  const debouncedValue = useDebounce(page, 600);

  const totalPages = Math.ceil(totalProducts / pageSize) || 0;

  const optionsSelect = [50, 100, 150];
  const subscriptions = useSelector(store => store?.auth?.userInfo?.subscriptions);
  const isFreePlan = subscriptions?.[0]?.plan?.plan?.name === 'basic';
  const showTooltip = isTrial;

  useEffect(() => {
    setPageNumber(debouncedValue === 0 ? 1 : debouncedValue);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [debouncedValue]);

  useEffect(() => {
    if (page !== pageNumber) setPage(pageNumber);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber]);

  const pageNumberHandler = (e) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;

    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      if (+parsedValue > totalPages) {
        setPage(totalPages);
      } else {
        setPage(+parsedValue);
      }
    }
  };

  return (
    <div className="product-database-table-header">
        <span>
          {t('Displaying')}

          <Tooltip placement="top"
                   title={showTooltip ? 'Feature disabled during Trial' : null}
                   destroyTooltipOnHide={true}
          >
          <Select
            disabled={totalProducts === 0 || isTrial || (isFreePlan && isTopStores) || isTopProducts || disabled}
            className={cls("custom-select database-page-size-select", {
              'disabled-transition': isDisabledTransition
            })}
            popupClassName="custom-dropdown database-page-size-dropdown"
            showArrow="true"
            open={!showArrow}
            suffixIcon={
              <div
                className={cls('arrow', {
                  arrow_selected: !showArrow,
                })}
              >
                <ArrowSelectDown color={!showArrow && '#225AEA'}/>
              </div>
            }
            onDropdownVisibleChange={(open) => setShowArrow(!open)}
            optionFilterProp="options"
            key={pageSize}
            value={pageSize}
            onChange={value => {
              setPageSize(value);
              setPageNumber(1);
            }}
            listHeight={200}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
              {optionsSelect.map((el) => (
                <Option value={el} key={el}>
                  <span>{el}</span>
                </Option>
              ))}
            </Select>
            </Tooltip>

          {t('of')} {acc.format(totalProducts, '0', ',', '')}
        </span>

      {
        isShort ?
          null
          :
          <div style={{display: 'flex', alignItems: 'center'}}>
            {t('Page')}

            <Tooltip placement="top"
                     title={showTooltip ? 'Feature disabled during Trial': null}
                     destroyTooltipOnHide={true}
            >
          <span>
            <InputComponent onChange={(e) => pageNumberHandler(e)}
                            className={'database-page-number'}
                            placeholder={''}
                            disabled={totalProducts === 0 || isTrial || (isFreePlan && isTopStores) || isTopProducts}
                            value={acc.formatNumber(page, 0, ',', '')}
            />
        </span>
            </Tooltip>

            {t('of')} {acc.format(totalPages, '0', ',', '')}

            <Tooltip placement="top"
                     title={showTooltip ? 'Feature disabled during Trial': null}
                     destroyTooltipOnHide={true}
                     open={visible}
            >
              <div className="database-page-buttons" style={{position: "relative"}}>
                {showTooltip && (
                  <div style={{position: "absolute", left: 0, top: 0, width: '100%', height: '100%', zIndex: 100}}
                       onMouseEnter={() => setVisible(true)}
                       onMouseLeave={() => setVisible(false)}
                  />
                )}
                <ButtonComponent className={'database-page-button database-page-button-prev'}
                                 text={''}
                                 disabled={pageNumber <= 1 || totalProducts === 0 || isTrial}
                                 onClick={() => setPageNumber(prev => prev - 1)}
                                 icon={<Icon type="arrow_forward" role="icon"/>}
                />
                <ButtonComponent className={'database-page-button database-page-button-next'}
                                 text={''}
                                 disabled={pageNumber === totalPages || totalProducts === 0 || (disabled && !isFreePlan) || (disabled && isTopStores) || isTrial}
                                 onClick={() => setPageNumber(prev => prev + 1)}
                                 icon={<Icon type="arrow_forward" role="icon"/>}
                />
              </div>
            </Tooltip>
          </div>
      }
    </div>
  );
};

export default React.memo(DatabaseProductsPagination);
