import React from 'react';
import NumbersBreakdownBlock from "../../ProductDetailsPage/components/NumbersBreakdownBlock";
import {useTranslation} from "react-i18next";
import './NumbersBreakdownDatabaseBlock.less'
import { useSelector } from 'react-redux';

const NumbersBreakdownDatabaseBlock = ({product}) => {
  const { t } = useTranslation();
  const isMobile = useSelector(store => store?.nav?.isMobile);

  return (
    <div className={'load-filter-preset-wrapper breakdown-database-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Numbers Breakdown')}
      </div>
      <p className="load-filter-preset-subtitle" style={{width: isMobile ? '100%' : '70%'}}>
        {t('database_breakdown')}
      </p>
      <NumbersBreakdownBlock
        product={product}
        isMobile={false}
        skeleton={false}
        productDatabase={true}
      />
    </div>
  );
};

export default NumbersBreakdownDatabaseBlock;
