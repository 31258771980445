import React from 'react';

const ChromeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_32247_476756)">
        <path
          d="M14 20.6634C17.6819 20.6634 20.6666 17.6786 20.6666 13.9967C20.6666 10.3148 17.6819 7.33008 14 7.33008C10.3181 7.33008 7.33331 10.3148 7.33331 13.9967C7.33331 17.6786 10.3181 20.6634 14 20.6634Z"
          fill="white"/>
        <path
          d="M14 7.33332H25.5451C24.3752 5.30631 22.6923 3.62304 20.6655 2.45276C18.6387 1.28249 16.3395 0.666463 13.9991 0.666626C11.6587 0.666789 9.35962 1.28314 7.33299 2.45369C5.30637 3.62425 3.62365 5.30776 2.45404 7.33493L8.2266 17.3333L8.23176 17.332C7.64454 16.3194 7.33465 15.1698 7.33334 13.9992C7.33202 12.8287 7.63932 11.6784 8.22427 10.6645C8.80921 9.65054 9.65112 8.80872 10.6651 8.22389C11.6791 7.63905 12.8294 7.33187 14 7.33332Z"
          fill="url(#paint0_linear_32247_476756)"/>
        <path
          d="M14 19.2778C16.9148 19.2778 19.2778 16.9148 19.2778 14C19.2778 11.0852 16.9148 8.72223 14 8.72223C11.0852 8.72223 8.72223 11.0852 8.72223 14C8.72223 16.9148 11.0852 19.2778 14 19.2778Z"
          fill="#1A73E8"/>
        <path
          d="M19.773 17.3349L14.0004 27.3333C16.3408 27.3337 18.64 26.7178 20.6669 25.5477C22.6938 24.3775 24.3768 22.6944 25.5469 20.6675C26.7169 18.6406 27.3327 16.3413 27.3323 14.0009C27.3318 11.6606 26.7153 9.36155 25.5445 7.33505H13.9994L13.998 7.34022C15.1686 7.33796 16.3191 7.64435 17.3335 8.22849C18.3479 8.81263 19.1904 9.65388 19.7761 10.6674C20.3617 11.681 20.6698 12.831 20.6692 14.0016C20.6687 15.1722 20.3596 16.3219 19.773 17.3349Z"
          fill="url(#paint1_linear_32247_476756)"/>
        <path
          d="M8.227 17.3351L2.45445 7.33667C1.28396 9.36334 0.66769 11.6625 0.667603 14.0028C0.667515 16.3432 1.28361 18.6424 2.45395 20.6691C3.62429 22.6959 5.3076 24.3788 7.33465 25.5486C9.36169 26.7185 11.661 27.334 14.0014 27.3333L19.7739 17.3349L19.7702 17.3312C19.1869 18.346 18.3463 19.1892 17.3332 19.7756C16.3201 20.362 15.1703 20.671 13.9997 20.6714C12.8292 20.6718 11.6792 20.3636 10.6657 19.7779C9.65219 19.1921 8.81104 18.3495 8.227 17.3351Z"
          fill="url(#paint2_linear_32247_476756)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_32247_476756" x1="2.45404" y1="8.99998" x2="25.5451" y2="8.99998"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#D93025"/>
          <stop offset="1" stopColor="#EA4335"/>
        </linearGradient>
        <linearGradient id="paint1_linear_32247_476756" x1="12.1789" y1="27.1551" x2="23.7244" y2="7.15761"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCC934"/>
          <stop offset="1" stopColor="#FBBC04"/>
        </linearGradient>
        <linearGradient id="paint2_linear_32247_476756" x1="15.4434" y1="26.5008" x2="3.89784" y2="6.50334"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#1E8E3E"/>
          <stop offset="1" stopColor="#34A853"/>
        </linearGradient>
        <clipPath id="clip0_32247_476756">
          <rect width="26.6667" height="26.6667" fill="white" transform="translate(0.666687 0.666656)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChromeIcon;
