import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import Icon from "../../../Icon";
import {Carousel} from "antd";
import acc from "accounting";
import './OverviewAdvertiser.less';

export const OverviewAdvertiserWrapper = (
  {
    data,
    pageSize=4,
    carouselRef,
    afterChange,
  }) => {

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pagesCount = Math.ceil(data?.length / pageSize);
    let result = [];
    for (let i = 0; i < pagesCount; i++) {
      let page = [];
      for (let j = 0; j < pageSize; j++) {
        if (i === 0) {
          page.push(data[j])
        } else {
          page.push(data[i*pageSize + j])
        }
      }
      result.push(page.filter(el => Boolean(el)));
      setPages(result);
    }
  }, [JSON.stringify(data)])

  return (
    <Carousel ref={carouselRef}
              infinite={false}
              afterChange={index => afterChange(index)}
              dots={false}
    >
      {
        pages.map(page => (
          <div className={'fadspot-page-overview-advertisers-wrapper'}
               key={page}
          >
            {
              [...page].map((el) => (
                <OverviewAdvertiser data={el}
                                    pageLength={page?.length}
                                    key={el?.id}
                />
              ))
            }
          </div>
        ))
      }
    </Carousel>
  )
}

const OverviewAdvertiser = (
  {
    data,
    pageLength
  }) => {

  const {search} = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }

  return (
    <div key={data?.id}
         className={'overview-advertiser-wrapper'}
         data-text={data?.name}
         // onClick={() => window.open(data?.url, '_blank')}
         onClick={() => navigate(`/ad-spot/${id}/all_ads/${search.split('&').filter(s => !s.includes('same')).join('&')}&same=${data?.id}`)}
         style={{width: `${(100 - pageLength)/pageLength}%`}}
    >
      <div className="overview-advertiser-logo">
        {
          data?.icon ?
            <img src={utils.changeImageSize(data?.icon, 496)}
                 alt=""
                 onError={utils.addDefaultSrc}
            />
            :
            <Image src={Images.defaultImg} small={false}/>
      }
    </div>
  <div className="overview-advertiser-content">
    <div className="overview-advertiser-title">
      <span onMouseOver={mouseOver}
            onMouseLeave={mouseLeave}
      >
        {
          data?.name
        }
      </span>
      {
        data?.is_verified ?
          <Icon role={'icon'} type={'ad_spot_checkmark'}/>
          :
          null
      }
    </div>
    <div className="overview-advertiser-value">
      {
        acc.formatNumber(data?.total_ads, 0, ',', ',')
      } {data?.total_ads === 1 ? 'ad' : 'ads'}
    </div>
  </div>
    </div>
  );
};
