import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Col } from 'antd';
import ButtonComponent from "../Button";
import './styles.less';

const SubUnSubComponent = ({
  categoryName,
  categoryText,
  className,
  active,
  subscribeFunc,
}) => (
  <Col span={24}>
    <div className={cls(className, 'panel', `panel--${categoryName}`)}>
      <div className="category-portfolio">
        {active && (
          <div className="category-portfolio_sub-text">
            {categoryText ||
              'To access product drops from this category,\n' +
                'you must subscribe to a plan'}
          </div>
        )}
        <div
          className={cls('category-portfolio_button', {
            'category-portfolio_button--disabled': !active,
          })}
        >
          <ButtonComponent disabled={!active} type="button" onClick={subscribeFunc}>
            Subscribe
          </ButtonComponent>
        </div>
      </div>
    </div>
  </Col>
);

SubUnSubComponent.defaultProps = {};

SubUnSubComponent.propTypes = {
  categoryName: PropTypes.string,
  categoryText: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  imageName: PropTypes.string,
  subscribeFunc: PropTypes.func,
};

export default SubUnSubComponent;
