import utils from "../../../Utils/utils";
import Icon from "../../../Icon";
import React from 'react';
import {columnTitles} from "../../../Components/titleSortFilters";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import ButtonComponent from "../../../Components/Button";
import './StoreProductsModal.less';

dayjs.extend(utc)

export const getColumns = (
  {
    t,
    sortOrder
  }
) => {
  return ([
    {
      title: () => t('Product'),
      dataIndex: 'title',
      key: 'name',
      width: 400,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className={'product-database-table-image'}
          >
            <img src={utils.changeImageSize(record?.main_image, 124)}
                 alt=""
                 onError={utils.addDefaultSrc}
                 width="62" height="62"
            />
          </div>
          <div className="product-image-quantity">
            <Icon type={'database_product_images'}
                  role={'icon'}
                  color={'#707ba0'}
                  width={16}
                  height={16}
            />
            <span>{record.images}</span>
          </div>
          <div className={'product-info-wrapper'} style={{flex: 1}}>
            <p className={'product-name'}>{record.title}</p>
            <p>
                    <span className={'product-url'} style={{color: '#707ba0' }}>
                      {`$${record?.usd_price ? record?.usd_price : 0}`}

                      <span style={{paddingLeft: 4, color: '#707ba0'}}>
                        {record?.store?.currency !== 'USD' && `(${record?.original_price ? record?.original_price : 0} ${record?.store?.currency})`}
                      </span>
                    </span>
              <span className={'product-variants'}>{record.variants} {t(record.variants === 1 ? 'Variant' : 'Variants')}</span>
            </p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = t('Product Creation Date')
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 216,
      sorter: true,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-creation-date">
            {
              record.created_at ?  dayjs(record.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 162,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell cell-actions cell-actions_tracker'}>
          <div className="product-actions" style={{height: 71}}>
                <ButtonComponent text={t('View Product')}
                                 className={'store-products-modal-action'}
                                 onClick={() => window.open(`https://${record?.store?.original_domain}/products/${record?.handle}`,
                                     '_blank')}
                />
          </div>
        </div>
      ),
    },
  ])
}
