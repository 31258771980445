/* eslint-disable indent */
import React from 'react';
import { Row, Col, Input } from 'antd';
import InputComponent from "../../../../Components/Input";
import './styles.less';
import cls from 'classname';

export default function Description({ product, onChange, disabled }) {
  const { TextArea } = Input;

  const emptyArr = new Array(20);
  const emptyLinks = emptyArr.fill('').map((_, i) => ({ id: i + 1, url: null }));
  const allVideosList = product?.videos ? [...product?.videos?.tiktok?.filter(el=> !!el.url), ...product?.videos?.facebook?.filter(el=> !!el.url),] : []

  function fillData(value) {
    if (allVideosList?.length) {
      if (value) {
        const content = allVideosList
          ?.filter((el) => el.platform === value)
          ?.map((el) => ({ ...el, id: `${value}-${el?.id}`, uid: el?.id }));
        if (content.length) {
          return emptyLinks.map((link, i) => (content[i] ? content[i] : link));
        }
        return emptyLinks;
      }
      return emptyLinks.map((link, i) =>
        allVideosList[i] ? allVideosList[i] : link,
      );
    }
    return emptyLinks;
  }

  const customLinks = fillData();
  const facebookLinks = fillData('facebook');
  const tiktokLinks = fillData('tiktok');

  function handleChangeLinks(inputLink) {
    const itemInclude = customLinks?.some((link) => inputLink?.id === link?.id && inputLink?.platform === link?.platform);
    const notEmpty = customLinks?.filter((el) => el.url !== null);

    if (itemInclude) {
      const changedLinks = notEmpty?.map((link, i) =>
        (inputLink?.id === link?.id && inputLink?.platform === link?.platform) ? inputLink : link,
      );

      if(inputLink.platform === 'tiktok'){
        onChange({ videos: {
            tiktok: [...changedLinks.filter(el => el.platform === 'tiktok')],
            facebook: [...customLinks.filter(el => el.platform === 'facebook')],
          }
        });
      }else{
        onChange({ videos: {
            tiktok: [...customLinks.filter(el => el.platform === 'tiktok')],
            facebook: [...changedLinks.filter(el => el.platform === 'facebook')],
          }
        });
      }
      return;
    }

    if(inputLink.platform === 'tiktok'){
      onChange({
        videos: {
          tiktok: [...notEmpty.filter(el => el.platform === 'tiktok'), inputLink],
          facebook: [...notEmpty.filter(el => el.platform === 'facebook')],
        }
      });
    } else {
      onChange({
        videos: {
          tiktok: [...notEmpty.filter(el => el.platform === 'tiktok')],
          facebook: [...notEmpty.filter(el => el.platform === 'facebook'), inputLink],
        }
      });
    }
  }

  return (
    <div className="description-block">
      <Row className="description-block_wrapper" gutter={[24, 24]}>
        <Col span={24}>
          <div className="block-with-label">
            <div className="block-with-label_label space-between">
              <div>
                Description <span className="important">*</span>{' '}
              </div>
              <div>
                {
                  disabled ?
                    <span style={{
                      color: '#D71313',
                      marginRight: 5
                    }}>
                      Must be less than 2500 symbols!
                    </span>
                    :
                    null
                }
                {product?.description?.length || 0} Characters
              </div>
            </div>
            <div className={cls('description-subblock', {
              'description-subblock-error': disabled
            })}>
              <TextArea
                value={product?.description}
                className="description-textarea"
                onChange={(e) => onChange({ description: e?.target?.value })}
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="block-with-label">
            <div className="block-with-label_label">Facebook Content</div>
            <div className="common-links-block">
              <div className="common-links-block_wrapper">
                {facebookLinks?.length
                  ? facebookLinks?.map((link, i) => i < 10 && (
                      <React.Fragment
                        key={`facebook-${link?.id}` || `facebook-${i + 1}`}
                      >
                        <div className="block-with-label">Link {i + 1}</div>
                        <InputComponent
                          placeholder="Enter link here..."
                          value={link?.url || ''}
                          className="block-with-label_input"
                          disabled={i > product?.videos?.facebook?.filter(el => !!el.url && el.platform === 'facebook').length && !link?.url}
                          onChange={(e) =>
                            handleChangeLinks({
                              id: link?.uid || `facebook-${i + 1}`,
                              url: e.target.value,
                              platform: 'facebook',
                            })
                          }
                        />
                      </React.Fragment>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="block-with-label">
            <div className="block-with-label_label">TikTok Content</div>
            <div className="common-links-block">
              <div className="common-links-block_wrapper">
                {tiktokLinks?.length
                  ? tiktokLinks?.map((link, i) => i < 10 && (
                      <React.Fragment
                        key={`tiktok-${link?.id}` || `tiktok-${i + 1}`}
                      >
                        <div className="block-with-label">Link {i + 1}</div>
                        <InputComponent
                          placeholder="Enter link here..."
                          value={link?.url || ''}
                          className="block-with-label_input"
                          disabled={i > product?.videos?.tiktok?.filter(el => !!el.url).length}
                          onChange={(e) =>
                            handleChangeLinks({
                              id: link?.uid || `tiktok-${i + 1}`,
                              url: e.target.value,
                              platform: 'tiktok',
                            })
                          }
                        />
                      </React.Fragment>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
