import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import Icon from '../../../../Icon';

const EmptyImage = ({ className, text }) => (
  <div className={cls('empty-image', className)}>
    <div className="empty-image_icon">
      <Icon width={64} height={64} role="icon" type="empty_warning" />
    </div>
    <div className="empty-image_wrapper">
      <div className="empty-image_header">{text}</div>
    </div>
  </div>
);

EmptyImage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default EmptyImage;
