import React from 'react';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from "../../../Components/Image";
import {columnTitles} from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";

//product database columns
export const getColumns = (
  {
    sortOrder,
    goStoreDetailsPage,
  }) => (
  [
    {
      title: () => 'Store',
      dataIndex: 'title',
      key: 'name',
      width: 372,
      fixed: 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <a href={`https://${record?.custom_domain}`}
             target={'_blank'}
             rel="noopener noreferrer"
          >
            <div className={'product-database-table-image sales-store-table-image'}
            >
              {record?.logo || record?.favicon ?
                <img src={record?.logo || record?.favicon}
                     alt="" onError={utils.addDefaultSrc}
                     width="62" height="62"
                />
                :
                <Image src={Images.defaultImg} small={true}/>
              }
            </div>
            <div className={'product-info-wrapper'}>
              <p className={'product-name'}>{record?.title}</p>
              <p>
              <span className={'product-url'}>
                {record?.all_domains?.length > 0 ?
                  <>
                    {record?.custom_domain}
                    <TooltipColumnIcon record={record} isST={true}/>
                  </>
                  :
                  record?.custom_domain
                }
              </span>
              </p>
            </div>
          </a>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales Today'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'day_revenue',
        })
      },
      dataIndex: 'day_revenue',
      key: 'day_revenue',
      width: 207,
      sorter: (a, b) => (a['day_revenue'] || 0) - (b['day_revenue'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="product-store-info">
            {
              record?.day_revenue ?
                <p>${acc.format(record?.day_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.day_sales ?
                <p>{acc.format(record?.day_sales, 0, ',', '.')} {record?.day_sales === 1 ? 'Sale' : 'Sales'}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales Yesterday'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'yesterday_revenue',
        })
      },
      dataIndex: 'yesterday_revenue',
      key: 'yesterday_revenue',
      width: 207,
      sorter: (a, b) => (a['yesterday_revenue'] || 0) - (b['yesterday_revenue'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="product-store-info">
            {
              record?.yesterday_revenue ?
                <p>${acc.format(record?.yesterday_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.yesterday_sales ?
                <p>{acc.format(record?.yesterday_sales, 0, ',', '.')} {record?.yesterday_sales === 1 ? 'Sale' : 'Sales'}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales 7 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'week_revenue',
        })
      },
      dataIndex: 'week_revenue',
      key: 'week_revenue',
      width: 207,
      sorter: (a, b) => (a['week_revenue'] || 0) - (b['week_revenue'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="product-store-info">
            {
              record?.week_revenue ?
                <p>${acc.format(record?.week_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.week_sales ?
                <p>{acc.format(record?.week_sales, 0, ',', '.')} {record?.week_sales === 1 ? 'Sale' : 'Sales'}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales 30 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'month_revenue',
        })
      },
      dataIndex: 'month_revenue',
      key: 'month_revenue',
      width: 207,
      sorter: (a, b) => (a['month_revenue'] || 0) - (b['month_revenue'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="product-store-info">
            {
              record?.month_revenue ?
                <p>${acc.format(record?.month_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.month_sales ?
                <p>{acc.format(record?.month_sales, 0, ',', '.')} {record?.month_sales === 1 ? 'Sale' : 'Sales'}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Store Info'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'products_count',
        })
      },
      dataIndex: 'products_count',
      key: 'products_count',
      width: 216,
      sorter: (a, b) => (a['products_count'] || 0) - (b['products_count'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="product-store-info">
            <p>{acc.format(record?.products_count, 0, ',', '.')} Products</p>
            <p style={{textTransform: 'capitalize'}}>{record?.language}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Store Creation Date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 216,
      sorter: (a, b) => dayjs(a['created_at']).valueOf() - dayjs(b['created_at']).valueOf(),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={(e)=> goStoreDetailsPage(record, e)}
        >
          <div className="store-creation-date">
            {
              record?.created_at ? dayjs(record?.created_at).format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 48,
      sorter: false,
      fixed: 'right',
      render: (text, record) => (
        <div className={'product-database-table-cell sales-tracker-store-table-cell cell-actions'}>
          <div className="product-actions">
            <span onClick={(e)=> goStoreDetailsPage(record, e)}>
              <Icon type="chart_icon" role="icon" width={16} height={16} color={'#707ba0'}/>
            </span>
          </div>
        </div>
      ),
    },
  ]
);
