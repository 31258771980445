import React from 'react';
import { Row, Col, Card } from 'antd';
import utils from '../../Utils/utils';
import './styles.less';

export default function BillingSkeleton() {
  return (
    <div className="billing-subpage billing-skeletons">
      <Row
        gutter={[
          { xs: 0, md: 16 },
          { xs: 48, sm: 48, md: 16 },
        ]}
      >
        <Col xs={24} sm={24} xl={12}>
          <Card className="plan-details-block">
            <div className="plan-details-header">
              <div className="billing-title-text" />
              <div className="edit-btn" />
            </div>
            <ul className="plan-details-list-info">
              {[[33,67], [111,82], [148,55], [130,53]].map(el => (
                <li key={el[0]} className="plan-details-list-info-item">
                  <span style={{width: el[0]}} className={'plan-details-list-info-item_title'}/>
                  <span style={{width: el[1]}} className={'plan-details-list-info-item_value'}/>
                </li>
              ))}
            </ul>
            <div className="edit-btn-mobile_plan-details" />
          </Card>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Card className="payment-method">
            <div className="billing-title-text" />
            <div className="payment-card-block">
              <div className="payment-card" />
              <div className="replace-card" />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Card className="billing-address-block">
            <div className="billing-address-header">
              <div className="billing-title-text" />
              <div className="icon-btn" />
              <div className="edit-btn" />
            </div>

            <Row
              gutter={[
                { xs: 21, sm: 21, md: 15 },
                { xs: 16, sm: 16 },
              ]}
            >
              {[1, 2, 3, 4, 5].map(el => (
                <Col xs={12} sm={12} md={8} key={el}>
                  <div className="billing-subtitle-text left-text" />
                  <div className="billing-dark-text botom-text" />
                </Col>
              ))}
              <Col xs={12} sm={24} md={24}>
                <div className="billing-subtitle-text left-text" />
                <div className="billing-dark-text botom-text" />
              </Col>
            </Row>
            <div className="edit-btn-mobile blue-btn" />
          </Card>
        </Col>
        <Col xs={24} sm={24} xl={12}>
          <Card className="billing-address-block company-details-block">
            <div className="billing-address-header">
              <div className="billing-title-text" />
              <div className="icon-btn" />
              <div className="edit-btn" />
            </div>

            <Row
              gutter={[
                { xs: 21, sm: 21, md: 15 },
                { xs: 16, sm: 16 },
              ]}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(el => (
                <Col xs={12} sm={12} md={8} key={el}>
                  <div className="billing-subtitle-text left-text" />
                  <div className="billing-dark-text botom-text" />
                </Col>
              ))}
            </Row>
            <div className="edit-btn-mobile blue-btn" />
          </Card>
        </Col>
        <Col xs={24} sm={24}>
          <Card className="payment-history">
            <div className="billing-title-text" />
            <div className="payment-list">
              <div className="payment-list-table">
                <Row
                  gutter={[
                    { xs: 0, sm: 0, md: 40 },
                    { xs: 32, sm: 32, md: 30 },
                  ]}
                >
                  {[1, 2, 3, 4, 5, 6].map(el => (
                    <React.Fragment key={el}>
                      <Col xs={2} sm={2} md={3}>
                        <div style={{width: 55}}/>
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        <div style={{height: 32, width: utils.randomNum(49, 65)}}/>
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        <div style={{width: utils.randomNum(42, 68)}}/>
                      </Col>
                      <Col xs={3} sm={3} md={4}>
                        <div style={{width: 86}}/>
                      </Col>
                      <Col xs={5} sm={5} md={5}>
                        <div style={{width: 141}}/>
                      </Col>
                      <Col xs={4} sm={4} md={6}>
                        <div style={{marginRight: 23}}/>
                        <div style={{width: 24, minWidth: 24}}/>
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
