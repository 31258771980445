/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import acc from 'accounting';
import { Badge } from 'antd';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import './SuppliersBlock.less';

export const SuppliersBlock = ({ skeleton, isMobile, product, onOpenUpgradePlan }) => {
  /* Alibaba, AliExpress */
  const [selected, setSelected] = useState('AliExpress');
  const { t } = useTranslation();

  const suppliersData = product?.suppliers;

  const isEmptyAliexpress = !suppliersData?.aliexpress?.length
  const isEmptyAlibaba = !suppliersData?.alibaba?.length
  const isLocked = product?.is_locked

  useEffect(() => {
    if(isEmptyAliexpress && selected === 'AliExpress') {
      setSelected('Alibaba');
    }else{
      if(isEmptyAlibaba && selected === 'Alibaba') setSelected('AliExpress');
      if(!isEmptyAliexpress && selected === 'Alibaba') setSelected('AliExpress');
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isEmptyAliexpress, isEmptyAlibaba, product?.product_id]);

  const handleChangeSelected = (platform) => {
    if (platform === selected) return;
    setSelected(platform);
  };

  const title = (
    <div className="suppliers-card-value-wrap">
      <span>
        {skeleton ? (
          <span className="text skeleton" style={{ minWidth: '21%' }} />
        ) : (
          t('Cost')
        )}
      </span>
      <span>
        {skeleton ? (
          <span className="text skeleton" style={{ minWidth: '59%' }} />
        ) : (
          t(selected === 'AliExpress' ? 'Shipping' : 'Min. Order')
        )}
      </span>
    </div>
  );

  const createTitle = () => {
    if (selected === 'AliExpress' && !isEmptyAliexpress) {
      return 'Suppliers';
    } else if (selected === 'Alibaba' && !isEmptyAlibaba) {
      return 'Suppliers';
    }
    return 'No Suppliers Found';
  };

  const renderHeader = (
    <>
      <span className={cls('title', { skeleton })}>
        {skeleton ? null : t(createTitle())}
      </span>

      {isMobile ? null : createTitle() === 'Suppliers' ? title : null}

      <div className="suppliers-card-badges-block">
        {
          isEmptyAlibaba
            ? null
            : <Badge className={cls('custom-badge', { active: selected === 'Alibaba' && !skeleton, skeleton })}
                     status={selected === 'Alibaba' && !skeleton ? 'processing' : 'default'}
                     text="Alibaba"
                     onClick={() => handleChangeSelected('Alibaba')}
            />
        }

        {
          isEmptyAliexpress
            ? null
            : <Badge className={cls('custom-badge', { active: selected === 'AliExpress' && !skeleton, skeleton })}
                     status={selected === 'AliExpress' && !skeleton ? 'processing' : 'default'}
                     text="AliExpress"
                     onClick={() => handleChangeSelected('AliExpress')}
            />
        }
      </div>
    </>
  );

  const renderCard = (el, index) => {
    if (skeleton) {
      return (
        <div key={`${el}_skeleton`} className="suppliers-card">
          <div className="suppliers-card-info-wrap">
            <span className="icon-wrap skeleton" />
            <span
              className="suppliers-card-link skeleton"
              style={{ width: index === 2 ? '76%' : '63%' }}
            />
          </div>

          <div className="suppliers-card-value-wrap">
            <span style={{ height: isMobile ? 16 : 24 }}>
              <span
                className="value skeleton"
                style={{ minWidth: index === 2 ? (isMobile ? 60 : 80) : 68 }}
              />
            </span>
            <span style={{ height: isMobile ? 16 : 24 }}>
              <span
                className="value skeleton"
                style={{ minWidth: index === 2 ? 44 : 34 }}
              />
            </span>
          </div>

          <div className="suppliers-card-tags-wrap">
            <span className="suppliers-tag skeleton" style={{ width: '54%' }} />
            <span className="suppliers-tag skeleton" style={{ width: '32%' }} />
          </div>
        </div>
      );
    }
    return (
      <div key={el.name + el.id}
           className={cls('suppliers-card',{locked: el.is_locked})}
      >
        <div className="suppliers-card-info-wrap">
          <Icon role="icon" type={`supplier_${selected.toLowerCase()}`} />
          <a
            href={el?.link || '#'}
            target="_blank"
            rel="noopener noreferrer"
            className="suppliers-card-link"
          >
            {el?.name}
          </a>
        </div>

        <div className="suppliers-card-value-wrap">
          <span className="value">
            {`$${acc.formatNumber(el?.cost_min, 2, '', ',')}${
              el?.cost_max
                ? ` - $${acc.formatNumber(el?.cost_max, 2, '', ',')}`
                : ''
            }`}
          </span>
          <span className="value">
            {selected === 'AliExpress'
              ? `$${
                  el?.shipping ? acc.formatNumber(el?.shipping, 2, '', ',') : 0
                }`
              : `${el?.min_orders} ${t('Units')}`}
          </span>
        </div>

        <div className="suppliers-card-tags-wrap">
          <span className="suppliers-tag">
            {t(
              selected === 'AliExpress'
                ? '_value_% Feedback Score'
                : '_value_ Stars',
              {
                value: el?.rating,
              },
            )}
          </span>
          <span className="suppliers-tag">
            {t('Open _count_ Years', { count: el?.years_open })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <ProductDetailsCard
      header={renderHeader}
      headerClassName="suppliers-header"
      withLine={!isMobile}
      isLocked={!skeleton && isLocked && suppliersData?.[selected.toLowerCase()]?.length > 1}
      onOpenUpgradePlan={onOpenUpgradePlan}
    >
      {isMobile ? (
        <div className="suppliers-card header-line">
          {createTitle() === 'Suppliers' ? title : null}
        </div>
      ) : null}

      {skeleton
      ?
        selected && (createTitle() === 'Suppliers') &&
        (suppliersData?.alibaba?.length ||
          suppliersData?.aliexpress?.length)
          ?
          { alibaba: [1, 2, 3], aliexpress: [1, 2, 3] }[selected.toLowerCase()]?.map(renderCard)
         :
            <div className={'videos-card empty'}>
            </div>
      : selected && (createTitle() === 'Suppliers') &&
          (suppliersData?.alibaba?.length || suppliersData?.aliexpress?.length)
          ?
          suppliersData?.[selected.toLowerCase()]?.map(renderCard)
          :
          <div className={'videos-card empty'}>
            <Icon role="icon" type="competitors_empty"/>
          </div>
      }
    </ProductDetailsCard>
  );
};

export default SuppliersBlock;
