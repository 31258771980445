import React from 'react';

const AdSpotShares = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.5321 18.2287C12.4771 18.2281 12.4162 18.2199 12.3622 18.2125C11.9024 18.1499 11.5146 17.8616 11.3268 17.4202L9.94485 14.2221C9.84993 14.0024 9.81717 13.7688 9.84086 13.543C9.87894 13.1799 10.0629 12.8368 10.369 12.604L14.5441 9.42914C14.8563 9.19171 14.9169 8.74613 14.6795 8.43392C14.4421 8.1217 13.9965 8.06107 13.6843 8.29849L9.51782 11.4669C9.51496 11.469 9.51209 11.4712 9.50922 11.4733C9.20242 11.7029 8.82352 11.7858 8.46494 11.7238C8.23919 11.6847 8.0215 11.5883 7.83491 11.4349L5.16054 9.23585C4.75906 8.90681 4.51136 8.21687 4.67644 7.72239C4.83828 7.23648 5.2406 6.89139 5.75144 6.82162L17.0612 5.13834C17.5462 5.06576 18.029 5.26311 18.3198 5.6561C18.6107 6.04908 18.6707 6.57003 18.4657 7.01335L13.762 17.4398C13.537 17.9289 13.0688 18.2324 12.5321 18.2287ZM20.2858 7.84241L15.5821 18.2689L15.579 18.2755C15.0326 19.4635 13.8576 20.238 12.5182 20.2287L12.5118 20.2286C12.3221 20.2267 12.1445 20.2016 12.1 20.1953C12.0968 20.1948 12.0925 20.1942 12.0925 20.1942C10.9439 20.038 9.95683 19.3025 9.48916 18.2097L8.10891 15.0154C7.91533 14.5674 7.82624 14.0959 7.83374 13.6311C7.37965 13.5105 6.94591 13.2932 6.56466 12.9797L3.89278 10.7827L3.89124 10.7814C3.34492 10.3331 2.99442 9.73292 2.80798 9.17155C2.6237 8.61672 2.52209 7.86091 2.77893 7.09038M20.2858 7.84241C20.801 6.71811 20.6379 5.42614 19.9274 4.46628C19.1915 3.472 17.9738 2.98009 16.7667 3.16013L5.46634 4.84202C4.18265 5.02293 3.17279 5.90944 2.77936 7.08907"
            fill="#707BA0"/>
    </svg>
  );
};

export default AdSpotShares;
