import React from 'react';
import { Checkbox } from 'antd';
import acc from 'accounting';
import { titleSortFilters } from '../../../../Components/titleSortFilters';

export const interestTargetingColumns = ({onChangeInterest}) => (
  [
    {
      title: 'Add',
      dataIndex: 'add',
      key: 'id',
      width: 30,
      sorter: false,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className="title-column column-interest">
        <Checkbox value={record?.id}
                  onChange={onChangeInterest}
        >
        </Checkbox>
      </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Rank',
          stateData: state,
          key: 'id',
        }),
      dataIndex: 'id',
      key: 'rank',
      width: 40,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className="title-column column-interest">#{record?.id}</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Interests',
          stateData: state,
          key: 'interests',
        }),
      dataIndex: 'interests',
      key: 'interests',
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className="title-column column-interest">{record?.name}</span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Audience',
          stateData: state,
          key: 'audience',
        }),
      dataIndex: 'audience',
      key: 'audience',
      width: 80,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => {
        const audienceFormatter = (value) => {
          if (value >= 1000000)
            return `${acc.formatNumber(value / 1000000, 1, ' ', '.')}M`;
          if (value >= 1000)
            return `${acc.formatNumber(value / 1000, 1, ' ', '.')}K`;
        };
        return (
          <span className="title-column column-interest">
          {audienceFormatter(record.audience)}
        </span>
        );
      },
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Path',
          stateData: state,
          key: 'path',
        }),
      dataIndex: 'path',
      key: 'path',
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className="title-column column-interest"
              style={{lineHeight: '32px', textAlign: 'left', whiteSpace: 'initial'}}
        >{record?.path}
        </span>
      ),
    },
  ]
)
