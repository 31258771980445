import React from 'react';
import { InputNumber } from 'antd';
import Images from '../../../../Images';
import cls from 'classname';
import Icon from '../../../../Icon';
import InputComponent from "../../../../Components/Input";

export default function SupplierItem({ type, supplier, onDelete, onChange }) {
  return (
    <div className="supplier_item">
      <img src={Images[`${type}Icon`]} alt="" />
      <InputComponent
        className="block-with-label_input store--name"
        value={supplier?.name || '-'}
        suffix={
          <a href={supplier?.link || '#'} target="_blank" rel="noopener noreferrer">
            {supplier?.link ? (
              <Icon type="external_link" role="icon" color="#225aea" />
            ) : null}
          </a>
        }
        onChange={(e) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'name',
            value: e?.target?.value,
          })
        }
      />
      <InputNumber
        placeholder="Min Cost"
        className="block-with-label_input without-arrows"
        value={supplier?.cost_min || 0}
        onChange={(cost_min) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'cost_min',
            value: cost_min,
          })
        }
      />
      <span className="cost-divider">-</span>
      <InputNumber
        placeholder="Max Cost"
        value={supplier?.cost_max || 0}
        className="block-with-label_input without-arrows"
        onChange={(cost_max) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'cost_max',
            value: cost_max,
          })
        }
      />
      <InputNumber
        placeholder="Min Orders"
        value={type === 'alibaba' ? supplier?.['min_orders'] : supplier?.shipping || 0}
        className={cls('block-with-label_input without-arrows', {
          [type]: type,
        })}
        onChange={(min_orders) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'min_orders',
            value: min_orders,
          })
        }
      />
      <InputNumber
        placeholder="Rating"
        value={supplier?.rating || 0}
        className="block-with-label_input without-arrows rating-star"
        onChange={(rating) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'rating',
            value: rating,
          })
        }
      />
      <InputNumber
        placeholder="Years Open"
        value={supplier?.years_open || 0}
        className="block-with-label_input years_open without-arrows"
        onChange={(years_open) =>
          onChange({
            type,
            id: supplier?.id,
            field: 'years_open',
            value: years_open,
          })
        }
      />
      <Icon
        type="delete_basket"
        role="button"
        width={24}
        height={24}
        onClick={() => {
          onDelete({ id: supplier?.id, type });
        }}
      />
    </div>
  );
}
