import React from 'react';
import {Spin} from "antd";
import cls from "classname";
import ButtonComponent from "../Button";
import {useTranslation} from "react-i18next";
import './CancelSubModal.less'

const CancelSubModal = ({setVisible, theme, callBack, billingDate, fetching}) => {

  const {t} = useTranslation();

  return (
      <Spin spinning={fetching}>
        <div className='cancel-sub-wrapper'>
          <div className="cancel-sub-title">
            {t('Cancel Plan')}
          </div>
          <div className="cancel-sub-text">
            <p>{
              t('Your plan cancelation will be scheduled until the end of your current billing period (_billingDate_)', {
                billingDate: billingDate
              })
            }</p>
          </div>
          <div className={cls("cancel-sub-image", {
            "cancel-sub-image--dark": theme === 'dark'
          })} />
          <div className="cancel-sub-btn-wrapper">
            <ButtonComponent text={t('Keep My Plan')}
                             onClick={() => setVisible(false)}
                             className='cancel-sub-button'
            />
            <ButtonComponent text={t('Cancel Plan')}
                             onClick={() => callBack()}
                             className='cancel-sub-button cancel-sub-button--red'
            />
          </div>
        </div>
      </Spin>
  );
};

export default CancelSubModal;
