import Creators, {AdSpotTypes as constants} from './reducer';
import openNotification, {openNotificationWithIcon} from "../../Components/Notification/index";
import {DefaultMsg, DefaultMsgSaga} from "../../Components/Notification/notification-message";
import React from "react";
import * as services from "./services";
import {call, delay, put, takeLatest} from "redux-saga/effects";
import {push} from 'connected-react-router';
import FileDownload from "js-file-download";

const actions = {
  getProductInfoAdSpotActions: {
    request: Creators.getProductInfoAdSpotRequest,
    success: Creators.getProductInfoAdSpotSuccess,
    errors: Creators.getProductInfoAdSpotFailure,
  },
  getProductChartAdSpotActions: {
    request: Creators.getProductChartAdSpotRequest,
    success: Creators.getProductChartAdSpotSuccess,
    errors: Creators.getProductChartAdSpotFailure,
  },
  getProductReportAdSpotActions: {
    request: Creators.getProductReportAdSpotRequest,
    success: Creators.getProductReportAdSpotSuccess,
    errors: Creators.getProductReportAdSpotFailure,
  },
  getProductAdsAdSpotActions: {
    request: Creators.getProductAdsAdSpotRequest,
    success: Creators.getProductAdsAdSpotSuccess,
    errors: Creators.getProductAdsAdSpotFailure,
  },
  getProductsReportAdSpotActions: {
    request: Creators.getProductsReportAdSpotRequest,
    success: Creators.getProductsReportAdSpotSuccess,
    errors: Creators.getProductsReportAdSpotFailure,
  },
  getProductsFiltersByStoreIdAdSpotActions: {
    request: Creators.getProductsFiltersByStoreIdAdSpotRequest,
    success: Creators.getProductsFiltersByStoreIdAdSpotSuccess,
    errors: Creators.getProductsFiltersByStoreIdAdSpotFailure,
  },
  getProductsCountByStoreIdAdSpotActions: {
    request: Creators.getProductsCountByStoreIdAdSpotRequest,
    success: Creators.getProductsCountByStoreIdAdSpotSuccess,
    errors: Creators.getProductsCountByStoreIdAdSpotFailure,
  },
  getProductsByStoreIdAdSpotActions: {
    request: Creators.getProductsByStoreIdAdSpotRequest,
    success: Creators.getProductsByStoreIdAdSpotSuccess,
    errors: Creators.getProductsByStoreIdAdSpotFailure,
  },
  getOverviewSimilarActions: {
    request: Creators.getOverviewSimilarRequest,
    success: Creators.getOverviewSimilarSuccess,
    errors: Creators.getOverviewSimilarFailure,
  },
  getOverviewReportActions: {
    request: Creators.getOverviewReportRequest,
    success: Creators.getOverviewReportSuccess,
    errors: Creators.getOverviewReportFailure,
  },
  getOverviewChartInfoActions: {
    request: Creators.getOverviewChartInfoRequest,
    success: Creators.getOverviewChartInfoSuccess,
    errors: Creators.getOverviewChartInfoFailure,
  },
  getOverviewCardInfoActions: {
    request: Creators.getOverviewCardInfoRequest,
    success: Creators.getOverviewCardInfoSuccess,
    errors: Creators.getOverviewCardInfoFailure,
  },
  getOverviewMainInfoActions: {
    request: Creators.getOverviewMainInfoRequest,
    success: Creators.getOverviewMainInfoSuccess,
    errors: Creators.getOverviewMainInfoFailure,
  },
  getOverviewStoreProductsModalActions: {
    request: Creators.getOverviewStoreProductsModalRequest,
    success: Creators.getOverviewStoreProductsModalSuccess,
    errors: Creators.getOverviewStoreProductsModalFailure
  },
  getOverviewStoreProductsModalNextActions: {
    request: Creators.getOverviewStoreProductsModalNextRequest,
    success: Creators.getOverviewStoreProductsModalNextSuccess,
    errors: Creators.getOverviewStoreProductsModalNextFailure
  },
  getSuggestionsActions: {
    request: Creators.getSuggestionsRequest,
    success: Creators.getSuggestionsSuccess,
    errors: Creators.getSuggestionsFailure,
  },
  getAdSpotFiltersActions: {
    request: Creators.getAdSpotFiltersRequest,
    success: Creators.getAdSpotFiltersSuccess,
    errors: Creators.getAdSpotFiltersFailure,
  },
  getAdSpotPresetsActions: {
    request: Creators.getAdSpotPresetsRequest,
    success: Creators.getAdSpotPresetsSuccess,
    errors: Creators.getAdSpotPresetsFailure,
  },
  createAdSpotPresetActions: {
    request: Creators.createAdSpotPresetRequest,
    success: Creators.createAdSpotPresetSuccess,
    errors: Creators.createAdSpotPresetFailure,
  },
  deleteAdSpotPresetActions: {
    request: Creators.deleteAdSpotPresetRequest,
    success: Creators.deleteAdSpotPresetSuccess,
    errors: Creators.deleteAdSpotPresetFailure,
  },
  cancelDeleteAdSpotPresetActions: {
    request: Creators.cancelDeleteAdSpotPresetRequest,
    success: Creators.cancelDeleteAdSpotPresetSuccess,
    errors: Creators.cancelDeleteAdSpotPresetFailure,
  },
  updateAdSpotPresetActions: {
    request: Creators.updateAdSpotPresetRequest,
    success: Creators.updateAdSpotPresetSuccess,
    errors: Creators.updateAdSpotPresetFailure,
  },
  getAdsActions: {
    success: Creators.getAdsSuccess,
    errors: Creators.getAdsFailure,
    request: Creators.getAdsRequest,
  },
  getAdsByIDActions: {
    success: Creators.getAdsByIDSuccess,
    errors: Creators.getAdsByIDFailure,
    request: Creators.getAdsByIDRequest,
  },
}

const notification = (error) => openNotificationWithIcon({
  style: { minWidth: '716px' },
  className: 'notification notification--save notification-rename-error',
  message: (
    <DefaultMsg
      text={
        <span className="preset-notification-block">
                {error}
              </span>
      }
      icon="alert_triangle_error"
      iconWidth={48}
      iconHeight={48}
    />
  ),
});

const presetNotification = (name, type) => openNotificationWithIcon({
  style: { minWidth: '716px' },
  className: 'notification notification--save',
  message: (
    <DefaultMsgSaga
      text={
        <span className="preset-notification-block">
                {type}
              </span>
      }
      icon="bookmark_preset"
      iconOutline={true}
      withTranslate={true}
      preset={name}
    />
  ),
});

const eventsOptions = {
  [constants.GET_PRODUCT_INFO_AD_SPOT_REQUEST]: {
    api: services.getProductInfoAdSpot,
    actions: actions.getProductInfoAdSpotActions,
  },
  [constants.GET_PRODUCT_CHART_AD_SPOT_REQUEST]: {
    api: services.getProductChartAdSpot,
    actions: actions.getProductChartAdSpotActions,
  },
  [constants.GET_PRODUCT_REPORT_AD_SPOT_REQUEST]: {
    api: services.getProductReportAdSpot,
    actions: actions.getProductReportAdSpotActions,
  },
  [constants.GET_PRODUCT_ADS_AD_SPOT_REQUEST]: {
    api: services.getProductAdsAdSpot,
    actions: actions.getProductAdsAdSpotActions,
  },
  [constants.GET_PRODUCTS_REPORT_AD_SPOT_REQUEST]: {
    api: services.getProductsReportAdSpot,
    actions: actions.getProductsReportAdSpotActions,
  },
  [constants.GET_PRODUCTS_FILTERS_BY_STORE_ID_AD_SPOT_REQUEST]: {
    api: services.getProductsFiltersByStoreIdAdSpot,
    actions: actions.getProductsFiltersByStoreIdAdSpotActions,
  },
  [constants.GET_PRODUCTS_COUNT_BY_STORE_ID_AD_SPOT_REQUEST]: {
    api: services.getProductsCountByStoreIdAdSpot,
    actions: actions.getProductsCountByStoreIdAdSpotActions,
  },
  [constants.GET_PRODUCTS_BY_STORE_ID_AD_SPOT_REQUEST]: {
    api: services.getProductsByStoreIdAdSpot,
    actions: actions.getProductsByStoreIdAdSpotActions,
  },
  [constants.GET_OVERVIEW_SIMILAR_REQUEST]: {
    api: services.getOverviewSimilar,
    actions: actions.getOverviewSimilarActions,
  },
  [constants.GET_OVERVIEW_REPORT_REQUEST]: {
    api: services.getOverviewReport,
    actions: actions.getOverviewReportActions,
  },
  [constants.GET_OVERVIEW_CHART_INFO_REQUEST]: {
    api: services.getOverviewChartInfo,
    actions: actions.getOverviewChartInfoActions,
  },
  [constants.GET_OVERVIEW_CARD_INFO_REQUEST]: {
    api: services.getOverviewCardInfo,
    actions: actions.getOverviewCardInfoActions,
  },
  [constants.GET_OVERVIEW_MAIN_INFO_REQUEST]: {
    api: services.getOverviewMainInfo,
    actions: actions.getOverviewMainInfoActions,
  },
  [constants.GET_OVERVIEW_STORE_PRODUCTS_MODAL_REQUEST]: {
    api: services.getOverviewStoreProductsModal,
    actions: actions.getOverviewStoreProductsModalActions
  },
  [constants.GET_OVERVIEW_STORE_PRODUCTS_MODAL_NEXT_REQUEST]: {
    api: services.getOverviewStoreProductsModal,
    actions: actions.getOverviewStoreProductsModalNextActions
  },
  [constants.GET_SUGGESTIONS_REQUEST]: {
    api: services.getSuggestions,
    actions: actions.getSuggestionsActions,
  },
  [constants.GET_AD_SPOT_FILTERS_REQUEST]: {
    api: services.getAdSpotFilters,
    actions: actions.getAdSpotFiltersActions,
  },
  [constants.GET_AD_SPOT_PRESETS_REQUEST]: {
    api: services.getAdSpotPresets,
    actions: actions.getAdSpotPresetsActions,
  },
  [constants.CREATE_AD_SPOT_PRESET_REQUEST]: {
    api: services.createAdSpotPreset,
    actions: actions.createAdSpotPresetActions,
    openNotification: (name) => {
      presetNotification(name, 'preset_create_name_')
    },
  },
  [constants.DELETE_AD_SPOT_PRESET_REQUEST]: {
    api: services.deleteAdSpotPreset,
    actions: actions.deleteAdSpotPresetActions,
  },
  [constants.CANCEL_DELETE_AD_SPOT_PRESET_REQUEST]: {
    api: services.cancelDeleteAdSpotPreset,
    actions: actions.cancelDeleteAdSpotPresetActions,
    openNotification: (name) => {
      presetNotification(name, 'preset_restore_name_')
    },
  },
  [constants.UPDATE_AD_SPOT_PRESET_REQUEST]: {
    api: services.updateAdSpotPreset,
    actions: actions.updateAdSpotPresetActions,
    openNotification: (name) => {
      openNotificationWithIcon({
        name,
        style: { minWidth: '716px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                preset_update_name_
              </span>
            }
            icon="rename_preset_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    },
    openErrorNotification: (error) => {
      notification(error)
    }
  },
  [constants.GET_ADS_REQUEST]: {
    api: services.getAds,
    actions: actions.getAdsActions,
  },
  [constants.GET_ADS_BY_ID_REQUEST]: {
    api: services.getAdsByID,
    actions: actions.getAdsByIDActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      if (action.type === 'GET_ADS_BY_ID_REQUEST') {
        yield put(provider.actions.success({...response.data, pageNumber: params?.page_number}));
      } else if(action.type === 'GET_ADS_REQUEST') {
        yield put(provider.actions.success({...response.data, isDefault: !Boolean(params?.creative_filters), pageNumber: params?.page_number}));
      } else if(action.type === 'DELETE_AD_SPOT_PRESET_REQUEST') {
        yield put(provider.actions.success(params.id));
      } else yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

function* apiGeneratorWithNotification(action) {
  const provider = eventsOptions[action.type];
  const params = action.payload;

  try {
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      yield delay(500);

      if (action.type === 'CANCEL_DELETE_AD_SPOT_PRESET_REQUEST') {
        yield put(provider.actions.success(params || true));
        yield call(provider.openNotification, params.name);
      }
      else if (action.type === 'UPDATE_AD_SPOT_PRESET_REQUEST') {
        yield put(provider.actions.success(response?.data || true));
        yield call(provider.openNotification, response?.data?.name);
      }
      else {
        yield put(provider.actions.success(response?.data || true));
        yield call(provider.openNotification, response?.data?.name);
      }
    } else {
      const error = Object.values(response.data).length
        ? Object.values(response.data)[0].detail
        : null;
      yield put(provider.actions.errors({ errors: error }));
      if (action.type === 'UPDATE_AD_SPOT_PRESET_REQUEST') {
        yield call(provider.openErrorNotification, error);
      }
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

function* apiGeneratorWithRedirect(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      if(action.type === 'GET_PRODUCT_ADS_AD_SPOT_REQUEST'
        || action.type === 'GET_OVERVIEW_SIMILAR_REQUEST'
        || action.type === 'GET_PRODUCTS_BY_STORE_ID_AD_SPOT_REQUEST'
        || action.type === 'GET_ADS_BY_ID_REQUEST'
      ) {
        yield put(provider.actions.success({...response.data, pageNumber: params?.page_number}));
      } else yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
      yield put(push('/ad-spot'));
      openNotification({
        type: 'warning',
        message: response?.data?.message?.detail || 'Data is being collected and will be displayed within 24 hours',
        style: { minWidth: '716px' },
      })
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

function* exportFileGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.data && response.ok) {
      let result = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
      FileDownload(result, `${params.name}.xlsx`);
      yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
      openNotification({
        type: 'warning',
        message: response?.data?.message?.detail || 'Something went wrong',
        style: { minWidth: '716px' },
      })
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_PRODUCT_INFO_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_PRODUCT_CHART_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_PRODUCT_REPORT_AD_SPOT_REQUEST, exportFileGenerator);
  yield takeLatest(constants.GET_PRODUCT_ADS_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_PRODUCTS_REPORT_AD_SPOT_REQUEST, exportFileGenerator);
  yield takeLatest(constants.GET_PRODUCTS_FILTERS_BY_STORE_ID_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_PRODUCTS_COUNT_BY_STORE_ID_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_PRODUCTS_BY_STORE_ID_AD_SPOT_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_OVERVIEW_SIMILAR_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_OVERVIEW_REPORT_REQUEST, exportFileGenerator);
  yield takeLatest(constants.GET_OVERVIEW_CHART_INFO_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_OVERVIEW_MAIN_INFO_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_OVERVIEW_CARD_INFO_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.GET_OVERVIEW_STORE_PRODUCTS_MODAL_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_OVERVIEW_STORE_PRODUCTS_MODAL_NEXT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_SUGGESTIONS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AD_SPOT_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AD_SPOT_PRESETS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_ADS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_ADS_BY_ID_REQUEST, apiGeneratorWithRedirect);
  yield takeLatest(constants.CREATE_AD_SPOT_PRESET_REQUEST, apiGeneratorWithNotification);
  yield takeLatest(constants.DELETE_AD_SPOT_PRESET_REQUEST, apiGenerator);
  yield takeLatest(constants.CANCEL_DELETE_AD_SPOT_PRESET_REQUEST, apiGeneratorWithNotification);
  yield takeLatest(constants.UPDATE_AD_SPOT_PRESET_REQUEST, apiGeneratorWithNotification);
};
