import React, { useEffect, useRef } from 'react';

import { TweenMax, TweenLite, Expo } from 'gsap';
import { message } from 'antd';

import cls from 'classname';
import dayjs from 'dayjs';

import utils, {categoryClassName} from '../../Utils/utils';

import './styles.less';
import utc from "dayjs/plugin/utc";

TweenLite.defaultEase = Expo.easeOut;

dayjs.extend(utc)

const TimerCountdownAnimation = ({ nextDrop, categoryName }) => {
  const timerEl = useRef(null);

  let timeoutId = null;

  useEffect(() => {
    if (nextDrop && !!timerEl) {
      const deadline = dayjs.utc(nextDrop);

      const days = deadline.diff(dayjs(), 'days');
      const deadlineHours = dayjs.utc(nextDrop).subtract(days, 'days');

      const hours = deadlineHours.diff(dayjs(), 'hours');
      const deadlineMin = dayjs.utc(deadlineHours).subtract(hours, 'hours');

      const minutes = deadlineMin.diff(dayjs(), 'minute');
      const deadlineSec = dayjs.utc(deadlineMin).subtract(minutes, 'minute');

      const second = deadlineSec.diff(dayjs(), 'second');

      initTimer(`${days}:${hours}:${minutes}:${second}`);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [nextDrop]);

  function initTimer(t) {
    const daysGroupEl = timerEl?.current.querySelector('.days-group');
    const hoursGroupEl = timerEl?.current.querySelector('.hours-group');
    const minutesGroupEl = timerEl?.current.querySelector('.min-group');
    const secondsGroupEl = timerEl?.current.querySelector('.sec-group');
    const daysGroup = {
      firstNum: daysGroupEl?.querySelector('.first'),
      secondNum: daysGroupEl?.querySelector('.second'),
    };
    const hoursGroup = {
      firstNum: hoursGroupEl?.querySelector('.first'),
      secondNum: hoursGroupEl?.querySelector('.second'),
    };
    const minutesGroup = {
      firstNum: minutesGroupEl?.querySelector('.first'),
      secondNum: minutesGroupEl?.querySelector('.second'),
    };
    const secondsGroup = {
      firstNum: secondsGroupEl?.querySelector('.first'),
      secondNum: secondsGroupEl?.querySelector('.second'),
    };

    const time = {
      days: t.split(':')[0],
      hours: t.split(':')[1],
      min: t.split(':')[2],
      sec: t.split(':')[3],
    };

    let timeNumbers;

    function updateTimer() {
      let timestr;
      const date = new Date();

      date.setHours(time.hours);
      date.setMinutes(time.min);
      date.setSeconds(time.sec);

      const newDate = new Date(date.valueOf() - 1000);

      const days = dayjs(nextDrop).diff(dayjs(), 'days');
      const temp = newDate.toTimeString().split(' ');
      const tempsplit = temp[0].split(':');

      if (days < 0){
        time.days = '00';
        time.hours = '00';
        time.min = '00';
        time.sec = '00';
      }else{
        time.days = days < 10 ? `0${days}` : days;
        time.hours = tempsplit[0];
        time.min = tempsplit[1];
        time.sec = tempsplit[2];
      }

      timestr = `${time.days}${time.hours}${time.min}${time.sec}`;
      timeNumbers = timestr.split('');
      updateTimerDisplay(timeNumbers);

      if (timestr === '00000000') {
        countdownFinished();
      } else {
        timeoutId = setTimeout(updateTimer, 950);
      }
    }

    function updateTimerDisplay(arr) {
      animateNum(daysGroup.firstNum, arr[0]);
      animateNum(daysGroup.secondNum, arr[1]);
      animateNum(hoursGroup.firstNum, arr[2]);
      animateNum(hoursGroup.secondNum, arr[3]);
      animateNum(minutesGroup.firstNum, arr[4]);
      animateNum(minutesGroup.secondNum, arr[5]);
      animateNum(secondsGroup.firstNum, arr[6]);
      animateNum(secondsGroup.secondNum, arr[7]);
    }

    function animateNum(group, arrayValue) {
      TweenMax.killTweensOf(group.querySelector('.number-grp-wrp'));
      TweenMax.to(group.querySelector('.number-grp-wrp'), 0.5, {
        y: -group.querySelector(`.num-${arrayValue}`)?.offsetTop,
      });
    }

    timeoutId = setTimeout(updateTimer, 1000);
  }

  function countdownFinished() {
    message.info('countdownFinished :)');
  }

  const numberGroup = (
    <div className="number-grp-wrp">
      <div className="num num-0">
        <p>0</p>
      </div>
      <div className="num num-1">
        <p>1</p>
      </div>
      <div className="num num-2">
        <p>2</p>
      </div>
      <div className="num num-3">
        <p>3</p>
      </div>
      <div className="num num-4">
        <p>4</p>
      </div>
      <div className="num num-5">
        <p>5</p>
      </div>
      <div className="num num-6">
        <p>6</p>
      </div>
      <div className="num num-7">
        <p>7</p>
      </div>
      <div className="num num-8">
        <p>8</p>
      </div>
      <div className="num num-9">
        <p>9</p>
      </div>
    </div>
  );

  const renderGroup = (groupType) => (
    <div key={groupType} className="clock-display-grp-wrap">
      <span className="clock-display-grp-title">
        {utils.toUpperLatter(groupType)}
      </span>

      <div className={`${groupType}-group clock-display-grp`}>
        <div className="first number-grp">{numberGroup}</div>
        <div className="second number-grp">{numberGroup}</div>
      </div>
    </div>
  );

  return (
    <div className="timer-animation" ref={timerEl}>
      <div className={cls('timer-animation--clock', categoryClassName[categoryName])}>
        {['days', 'hours', 'min', 'sec'].map(renderGroup)}
      </div>
    </div>
  );
};

export default TimerCountdownAnimation;
