import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import cls from "classname";
import NavHeader from "../components/NavHeader";
import {connect} from "react-redux";
import Creators from '../reducer';
import {OverlayScrollbar} from "../../../Components/ScrollBar";
import AdItemOverview from "./AdItemOverview";
import AdItemProducts from "./AdItemProducts";
import AdItemAllAdsPage from "./AdItemAllAdsPage";
import './AdItemPage.less';


const AdItemPage = (
  {
    isMobile,
    loading,
    selectedAdv,
    getAds,
    userInfo,
    getOverviewMainInfo,
    overviewInfo,
    creditsLeft
  }) => {

  const {id, mode} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Ad Spot - Dropship`;
  }, [])

  const innerContent = {
    'all_ads': <AdItemAllAdsPage id={id}
                                 isMobile={isMobile}
                                 loading={loading}
                                 selectedAdv={selectedAdv}
                                 userInfo={userInfo}
                                 getAds={getAds}
                                 getOverviewMainInfo={getOverviewMainInfo}
                                 mainInfo={overviewInfo?.mainInfo}
                                 creditsLeft={creditsLeft}
    />,
    'overview': <AdItemOverview />,
    'products': <AdItemProducts />,
    'detailed': <AdItemProducts />
  }

  return (
    <OverlayScrollbar visibility="hidden">
      <div className={cls("product-database-page sales-tracker-page fadspot-page-inner")}>
        <NavHeader navigate={navigate}
                   isMobile={isMobile}
                   mainInfo={overviewInfo?.mainInfo}
        />

        {
          innerContent[mode]
        }

      </div>
    </OverlayScrollbar>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  loading: state.adSpot.selectedAdvLoading,
  selectedAdv: state.adSpot.selectedAdv,
  view: state.adSpot.view,
  userInfo: state.auth.userInfo,
  overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  creditsLeft: state.adSpot.creditsLeft,
});

const mapDispatchToProps = (dispatch) => ({
  getAds: (data) => dispatch(Creators.getAdsByIDRequest(data)),
  getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdItemPage);
