import React from 'react';
import {useSelector} from "react-redux";
import { Input, Divider, Select } from 'antd';
import cls from 'classname';

import CompetitorsShopifyTable from './CompetitorsShopifyTable';
import Icon from '../../../../Icon';

import ArrowSelectDown from '../../../../Icon/img/ArrowSelectDown';
import './styles.less';

const { Option } = Select;

export default function CompetitorsShopify({
  products,
  count,
  onSearch,
  setSearchTextShopify,
  searchTextShopify,
  getShopifyTargetNext,
  next,
  fetching,
  changeApprovedShopify,
  setSelectValueShopify,
}) {
  const { Search } = Input;
  const sortOrder = null;
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  function handleChange(type, data) {
    changeApprovedShopify(type, data);
  }

  const optionsSelect = [
    { label: 'Title', value: 'title', id: 'title', isDisabled: false },
    {
      label: 'Description',
      value: 'description',
      id: 'description',
      isDisabled: false,
    },
    { label: 'Domain', value: 'domain', id: 'domain', isDisabled: false },
    { label: 'All', value: 'all', id: 'all', isDisabled: false },
  ];

  return (
    <div className="competitors-shopify-block">
      <div className="block-with-label">
        <div className="block-with-label_label space-between bold-text">
          <div style={{ color: '#151E3A' }}>
            Competitors Shopify <span className="blue-block">{count}</span>
          </div>
        </div>
      </div>
      <div className="container container--table-view competitors--shopify">
        <div className="search">
          <div className="search_wrapper">
            <Search
              value={searchTextShopify}
              placeholder="Search for products..."
              className={cls('search-input', 'interests-search shopify-search', {
                'disabled-transition': isDisabledTransition
              })}
              onChange={(e) => setSearchTextShopify(e.target.value)}
              enterButton="Search"
              onSearch={(e) => {
                onSearch(e, sortOrder);
              }}
              size="large"
              prefix={
                <>
                  <Icon type="search_icon" role="icon" />
                  <Divider type="vertical" className="search-divider" />
                </>
              }
            />
            <Select
              className="custom-select category-admin-select"
              popupClassName="custom-dropdown category-admin-dropdown competitors-shopify"
              showArrow
              suffixIcon={<ArrowSelectDown />}
              optionFilterProp="options"
              defaultValue="title"
              onSelect={(value) => setSelectValueShopify(value)}
              listHeight={176}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {optionsSelect.map((el) => (
                <Option value={el.id} key={el.id} disabled={el.isDisabled}>
                  <Icon
                    role="icon"
                    type="select_dropdown_check_mark_v2"
                    className="check-icon"
                  />
                  <span onClick={(e) => e.preventDefault()}>{el.label}</span>
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="shopify-list">
          <CompetitorsShopifyTable
            products={products}
            searchText={searchTextShopify}
            getShopifyTargetNext={getShopifyTargetNext}
            next={next}
            fetching={fetching}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
