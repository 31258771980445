import React from 'react';
import {Tag} from "antd";
import Icon from "../../../../Icon";
import acc from "accounting";
import './FilterTag.less';

const FilterTag = ({data, setValue, languages}) => {

  const handleTagClose = (id) => {
    if (id !== 'languages') {
      setValue(prev => ({...prev, [id]: {min: data?.min, max: data?.max}}))
    } else {
      setValue(prev => ({...prev, [id]: null}))
    }
  }

  return (
    <Tag className='dropdown-filter-tag'
         closable
         onClose={() => handleTagClose(data?.id)}
         closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
    >
      {data?.title}:
      {
        data?.id === 'languages' ?
          ` ${data?.value?.length ?
            data?.value?.length === 1 ?
              languages?.filter(el => el?.code === data?.value?.[0])?.[0]?.value
              :
              'Mixed Values'
            :
            data?.value?.join(',')
        }`
          :
          ` ${(['price', 'revenue'].includes(data?.id) ? '$' : '') + (data?.id === 'created_at' ? data?.value?.min : acc.formatNumber( Math.floor(data?.value?.min), 0, ',', '.'))} - ${(['price', 'revenue'].includes(data?.id) ? '$' : '') + (data?.id === 'created_at' ? data?.value?.max : data?.value?.max === null ? acc.formatNumber(Math.floor(data?.max), 0, ',', '.') : acc.formatNumber(Math.floor(data?.value?.max), 0, ',', '.'))}`
      }
    </Tag>
  );
};

export default FilterTag;
