import React, {useEffect, useRef, useState} from 'react';
import cls from "classname";
import {Dropdown, Table} from "antd";
import Icon from "../../../Icon";
import IconWithText from "../../../Components/Text";
import {useTranslation} from "react-i18next";
import './FilterPresetTable.less';

const FilterPresetTable = (props) => {
  const {filters, theme, isDropship, updatePreset, setSelected, selected, editable, setEditable, setRecordToDelete, setModal, category} = props
  //menu dropdown toggle show/hide
  const [visibleMenu, setVisibleMenu] = useState(null);
  //rename state
  const [name, setName] = useState(null);
  const [caret, setCaret] = useState(null);
  const tableRef = useRef();
  const editableRef = useRef(null);
  const { t } = useTranslation();

  const handleVisibleChange = value => {
    if (value === visibleMenu)
      setVisibleMenu(null)
    else setVisibleMenu(value)
    setSelected(null)
  }

  //hide menu dropdown on table scroll
  const scrollListener = (e) => {
    if (visibleMenu)
      setVisibleMenu(null)
  }

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', scrollListener)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [visibleMenu])

  useEffect(() => {
    const input = editableRef.current;
    if (input) input.setSelectionRange(caret, caret);
  }, [editableRef, caret, name]);

  const columns = [{
    title: '',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => (
      //if dropship preset table
      !isDropship ?
        //if editable -> render input with btns
        editable === record.id ?
          <>
            <input
              ref={editableRef}
              id={`editable-preset-filter-input-${record.id}`}
              autoFocus={true}
              type="text"
              value={typeof name === 'object' ? text : name}
              onChange={(e) => {
                setCaret(e.target.selectionStart);
                setName(e.target.value);
              }}
              className="editable-text_input editable-text_input-preset"
            />
            <span
              className="editable-text_icon editable-text_icon--success"
              onClick={() => {
                updatePreset({record, name: name.trim()})
                setEditable(null)
                setName('')
              }}
            >
            <Icon role="icon" type="check_mark" />
          </span>
            <span
              className="editable-text_icon editable-text_icon--cancel"
              onClick={() => {
                setEditable(null)
                setName(text)
              }}
            >
            <Icon role="icon" type="close" width={24} height={24} />
          </span>
          </>
        :
        <span id={`filter-preset-cell-text-${index}`}
              className={cls('filter-preset-cell-text', {
                'filter-preset-cell-text-highlighted': visibleMenu === record.id
              })}
        >
          {text}
          <Dropdown dropdownRender={() => (
            <ul className="filter-preset-action-dropdown">
              <li className="action-dropdown_item"
                  onClick={() => {
                    setVisibleMenu(null)
                    setSelected(null)
                    setEditable(record.id)
                    setName(record.name)
                  }}
              >
                <IconWithText
                  text={t('rename_preset')}
                  size={24}
                  icon={() => <Icon type="rename_preset" role="icon"/>}
                />
              </li>
              <li className="action-dropdown_item action-dropdown_item--red"
                  onClick={() => {
                    setVisibleMenu(null)
                    setSelected(null)
                    setRecordToDelete(record)
                    setModal('deleteFilterPreset')
                  }}
              >
                <IconWithText
                  text={t('Delete')}
                  size={24}
                  icon={() => <Icon type="remove_preset" role="icon"/>}
                />
              </li>
            </ul>
                    )}
                    destroyPopupOnHide={true}
                    placement="bottomRight"
                    getPopupContainer={() => document.getElementsByClassName('preset-table')[0]}
                    overlayClassName={'filter-preset-cell-menu-wrapper'}
                    onOpenChange={(a ) => handleVisibleChange(record.id)}
                    trigger={['click']}
                    open={visibleMenu === record.id}
          >
            <Icon type={'three_dots'} role={'button'} className={'preset-filters-more-icon'} />
          </Dropdown>
        </span>
        :
        <span className={'filter-preset-cell-text'}>{text}</span>
    )
  }]

  const dataSource = filters?.length
    ? filters
    : [];

  let result = `${isDropship ? 'Dropship' : 'Your'} Presets`
  return (
    <div
      className={cls('filters-preset-table', {
        'top': !isDropship,
        'bottom': isDropship,
        'top-competitors': category === 'competitors'
      })}
    >
      <div className="filters-preset-table-header">
        {t(result)}
      </div>
      {dataSource.length ? (
        <>
          <Table
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table
                  ref={tableRef}
                  {...other}
                  className={cls(className, 'presets-table-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            columns={columns}
            className="preset-table"
            rowClassName={record => cls("preset-filter-row", {
              'preset-filter-row-selected': selected && selected?.id === record.id,
              'preset-filter-row-highlighted': visibleMenu === record.id
            })}
            dataSource={dataSource}
            pagination={false}
            style={{height: 190}}
            rowKey={record => record.id}
            scroll={{ scrollToFirstRowOnChange: true, y: 208 }}
            sticky={true}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => {
                  e.persist()
                  //selected row -> clicked and @bluePrimary bg
                  if (editable !== record.id) {
                    if (e.target.classList.contains('filter-preset-cell-text')) {
                      e.target.parentNode.classList.toggle('ant-table-cell-selected')
                      if (setSelected) {
                        if (selected?.id === record.id) {
                          setSelected(null)
                        } else {
                          setSelected({...record})
                          setEditable(null)
                          setName(record.name)
                        }
                      }
                    } else if (e.target.classList.contains('ant-table-cell')) {
                      e.target.classList.toggle('ant-table-cell-selected')
                      if (setSelected) {
                        if (selected?.id === record.id) {
                          setSelected(null)
                        } else {
                          setSelected({...record})
                          setEditable(null)
                          setName(record.name)
                        }
                      }
                    }
                  }
                },
              };
            }}
          />
        </>
      ) : (
        //empty table
        <div className="empty-state empty-state-filters">
          <div className="empty-state_icon">
            <Icon width={64} height={64}
                  role="icon"
                  type="empty_warning"
                  fill={theme === 'dark' ? '#2B3450' : '#C5CCE3'}
            />
          </div>
          <div className="empty-state_wrapper">
            <div className={'empty-state-header-filters'}>
              {
                isDropship
                ? ''
                : ( category === 'competitors' ? t('empty_preset_competitors_table') : t('empty_preset_table') )
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterPresetTable;
