import React from 'react';
import cls from 'classname';
import { Divider } from 'antd';
import './FreeBonuses.less';

const content = [
  {
    title: 'Done for you logo design',
    text: 'Our team of eCommerce Experts will Give you a Logo that you can use on your eCommerce store',
    value: 97,
    svg: 'design'
  },
  {
    title: 'Full dropshipping course',
    text: 'Access to a full A-Z guide on how to start a profitable dropshipping store in 2024',
    value: 497,
    svg: 'shopify'
  },
  {
    title: 'Mystery gift',
    text: 'Order Today and get a Mystery Gift with your purchase that will blow your mind',
    value: 197,
    svg: 'mystery'
  }
]

const FreeBonuses = ({ isMobile }) => {
  return (
    <div className={'free-bonuses-wrapper'}>
      <h3>
        + 3 FREE Bonuses For Today
      </h3>

      <div className="free-bonuses-content">
        {
          content.map((el, index) => (
            <div className="free-bonuses-content-item"
                 key={el?.title}
            >
              <div className={cls(`free-bonuses-content-item-image ${el?.svg}`)}>

              </div>
              <div className="free-bonuses-content-item-content">
                <h5>
                  Bonus #{index + 1}
                </h5>
                <h4 className={'free-bonuses-content-item-title'}>
                  {
                    el?.title
                  }
                </h4>
                <p className={'free-bonuses-content-item-text'}>
                  {
                    el?.text
                  }
                </p>
                <p className={'free-bonuses-content-item-value'}>
                  ${el?.value} value
                </p>
              </div>

            </div>
          ))
        }
      </div>

      <Divider type={'horizontal'} style={{margin: isMobile ? '32px auto' : '40px auto 32px'}} />

      <div className={'free-bonuses-total'}>
        {
          isMobile ?
            <p>
              <span>
                Total Value:
              </span>
              <span>
                $17,422.00
              </span>
            </p>
            :
            <p>
              Total Value: $17,422.00
            </p>
        }
        <span>
          Usual cost: $5,000+
        </span>
      </div>

      <div className={'free-bonuses-btn'}
           onClick={() => document.getElementById('pick-your-stores-form').scrollIntoView({
             behavior: 'smooth',
           })}
      >
        <p>
          Only $49!
        </p>
        <span>
          (One time payment)
        </span>
      </div>

    </div>
  );
};

export default FreeBonuses;
