import React from 'react';
import Images from '../../../../Images';
import utils from '../../../../Utils/utils';

export const competitorsShopifyColumns = () => [
  {
    title: 'Product',
    dataIndex: 'title',
    key: 'title',
    width: 180,
    sorter: false,
    render: (_, record) => (
      <span className="title-column-shopify">
        <a href={record.link || '#'} target="_blank" rel="noopener noreferrer">
          <div className='imag-wrap'
               style={{backgroundImage: `url(${record.image})`}}
          >
          <img
            src={record.image || Images.defaultImg}
            alt="images"
            onError={utils.addDefaultSrc}
          />
          </div>
        </a>
        <a
          className="title-column-text"
          href={record?.link || '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.title}
        </a>
      </span>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: 100,
    sorter: false,
    showSorterTooltip: false,
    render: (_, record) => (
      <span className="title-column column-shopify">{`${record?.currency || ''} ${record?.price}`}</span>
    ),
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    width: 150,
    sorter: false,
    showSorterTooltip: false,
    render: (_, record) => {
      return (
        <span className="title-column column-shopify">
          {record.domain}
        </span>
      );
    },
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    width: 100,
    sorter: false,
    showSorterTooltip: false,
    render: (_, record) => (
      <span className="title-column column-shopify">{record?.score}</span>
    ),
  },
]
