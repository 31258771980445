import Icon from '../../Icon';
import cls from 'classname';
import React from 'react';
import './styles.less';

export const NavigateBlock = ({ skeleton, handleChangeMoreProducts, previous, next, ...props }) => {

  if (skeleton) {
    return (
      <div className={'navigate-block'}>
      <div className='navigate-block-btn'>
        <span className='nav-btn prev skeleton'/>
        <span className='text skeleton'>{previous?.text || ''}</span>
      </div>

      <div className='navigate-block-btn'>
        <span className='text skeleton'>{next?.text || ''}</span>
        <span className='nav-btn next skeleton'/>
      </div>
    </div>
    );
  } else {
    return (
      <div className={'navigate-block'}>
        <button className={cls('navigate-block-btn', { disabled: previous.disabled })}
                onClick={() => handleChangeMoreProducts('previous')}
                disabled={previous.disabled}
        >
          <Icon role={'icon'}
                type={'arrow_slider_prev'}
                className={cls('nav-btn prev', {
                  disabled: previous.disabled,
                })}
          />
          <span>{previous?.text || ''}</span>
        </button>

        <button className={cls('navigate-block-btn', { disabled: next.disabled })}
                onClick={() => handleChangeMoreProducts('next')}
                disabled={next.disabled}
        >
          <span>{next?.text || ''}</span>
          <Icon role={'icon'}
                type={'arrow_slider_next'}
                className={cls('nav-btn next', {
                  disabled: next.disabled,
                })}
          />
        </button>
      </div>
    );
  }
};

export default NavigateBlock;
