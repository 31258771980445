import React from 'react';

const AdSpotAngry = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.2858 11.9996C22.2858 14.7275 21.2021 17.3438 19.2732 19.2727C17.3443 21.2016 14.728 22.2853 12.0001 22.2853C9.27213 22.2853 6.65592 21.2016 4.72698 19.2727C2.79803 17.3438 1.71436 14.7275 1.71436 11.9996C1.71436 9.27164 2.79803 6.65543 4.72698 4.72649C6.65592 2.79754 9.27213 1.71387 12.0001 1.71387C14.728 1.71387 17.3443 2.79754 19.2732 4.72649C21.2021 6.65543 22.2858 9.27164 22.2858 11.9996Z"
        fill={`url(#paint0_linear_36566_241416_${id})`}/>
      <g filter={`url(#filter0_d_36566_241416_${id})`}>
        <path
          d="M8.40088 19.1374C8.40088 19.8163 10.0119 19.7083 12.0009 19.7083C13.9886 19.7083 15.6009 19.8163 15.6009 19.1374C15.6009 18.3197 13.9886 17.7861 12.0009 17.7861C10.0119 17.7861 8.40088 18.3197 8.40088 19.1374Z"
          fill="black"/>
      </g>
      <path
        d="M8.40088 19.1374C8.40088 19.8163 10.0119 19.7083 12.0009 19.7083C13.9886 19.7083 15.6009 19.8163 15.6009 19.1374C15.6009 18.3197 13.9886 17.7861 12.0009 17.7861C10.0119 17.7861 8.40088 18.3197 8.40088 19.1374Z"
        fill={`url(#paint1_linear_36566_241416_${id})`}/>
      <path
        d="M6.34141 14.3545C6.34141 13.3375 7.03313 12.5146 7.88427 12.5146C8.7367 12.5146 9.42713 13.3375 9.42713 14.3545C9.42713 14.7775 9.30756 15.1684 9.10313 15.4782C9.0076 15.6209 8.86147 15.722 8.69426 15.7611C8.50141 15.8074 8.2147 15.8575 7.88427 15.8575C7.55385 15.8575 7.26585 15.8074 7.07428 15.7611C6.9069 15.7224 6.76065 15.6212 6.66541 15.4782C6.45015 15.1433 6.33754 14.7527 6.34141 14.3545ZM14.57 14.3545C14.57 13.3375 15.2604 12.5146 16.1129 12.5146C16.964 12.5146 17.6557 13.3375 17.6557 14.3545C17.6557 14.7775 17.5349 15.1684 17.3317 15.4782C17.2364 15.6212 17.0903 15.7224 16.9229 15.7611C16.6575 15.8246 16.3857 15.8569 16.1129 15.8575C15.7824 15.8575 15.4957 15.8074 15.3029 15.7611C15.1353 15.722 14.9888 15.6209 14.8927 15.4782C14.6784 15.1429 14.5663 14.7524 14.57 14.3545Z"
        fill={`url(#paint2_linear_36566_241416_${id})`}/>
      <g filter={`url(#filter1_i_36566_241416_${id})`}>
        <path
          d="M6.34141 14.3545C6.34141 13.3375 7.03313 12.5146 7.88427 12.5146C8.7367 12.5146 9.42713 13.3375 9.42713 14.3545C9.42713 14.7775 9.30756 15.1684 9.10313 15.4782C9.0076 15.6209 8.86147 15.722 8.69426 15.7611C8.50141 15.8074 8.2147 15.8575 7.88427 15.8575C7.55385 15.8575 7.26585 15.8074 7.07428 15.7611C6.9069 15.7224 6.76065 15.6212 6.66541 15.4782C6.45015 15.1433 6.33754 14.7527 6.34141 14.3545ZM14.57 14.3545C14.57 13.3375 15.2604 12.5146 16.1129 12.5146C16.964 12.5146 17.6557 13.3375 17.6557 14.3545C17.6557 14.7775 17.5349 15.1684 17.3317 15.4782C17.2364 15.6212 17.0903 15.7224 16.9229 15.7611C16.6575 15.8246 16.3857 15.8569 16.1129 15.8575C15.7824 15.8575 15.4957 15.8074 15.3029 15.7611C15.1353 15.722 14.9888 15.6209 14.8927 15.4782C14.6784 15.1429 14.5663 14.7524 14.57 14.3545Z"
          fill="black"/>
      </g>
      <path
        d="M8.10296 13.7134C8.10926 13.7434 8.11226 13.774 8.11196 13.8047C8.11196 14.0631 7.88567 14.2752 7.60538 14.2752C7.32638 14.2752 7.1001 14.0631 7.1001 13.8047C7.1001 13.6979 7.13867 13.5989 7.20295 13.5205C7.49095 13.5887 7.7931 13.6542 8.10296 13.7134ZM15.7221 14.2752C15.4508 14.2752 15.2297 14.0785 15.2168 13.8278C15.5498 13.7789 15.8803 13.7185 16.2017 13.6529C16.2194 13.7019 16.2282 13.7538 16.2274 13.8059C16.2274 14.0631 16.0024 14.2752 15.7221 14.2752Z"
        fill="#4F4F67"/>
      <g filter={`url(#filter2_d_36566_241416_${id})`}>
        <path
          d="M13.2853 11.4853C13.2853 10.9119 13.4949 10.7139 13.8575 10.7139C14.2175 10.7139 14.3897 11.0687 14.508 12.0844C15.9583 12.0844 18.414 11.3979 18.6506 11.3979C18.8795 11.3979 19.0068 11.5072 19.0582 11.7412C19.1032 11.9443 19.0286 12.1372 18.774 12.2554C17.9756 12.6244 15.633 13.4576 13.6517 13.4576C13.4357 13.4576 13.2853 13.347 13.2853 13.0706V11.4853ZM9.49118 12.0844C9.60946 11.0687 9.78175 10.7139 10.1417 10.7139C10.5043 10.7139 10.7139 10.9119 10.7139 11.4853V13.0706C10.7139 13.347 10.5635 13.4576 10.3475 13.4576C8.36618 13.4576 6.02361 12.6244 5.22518 12.2554C4.97061 12.1372 4.89604 11.9443 4.94104 11.7412C4.99375 11.5072 5.12104 11.3979 5.34861 11.3979C5.58518 11.3979 8.04089 12.0844 9.49118 12.0844Z"
          fill="black"/>
      </g>
      <path
        d="M13.2853 11.4853C13.2853 10.9119 13.4949 10.7139 13.8575 10.7139C14.2175 10.7139 14.3897 11.0687 14.508 12.0844C15.9583 12.0844 18.414 11.3979 18.6506 11.3979C18.8795 11.3979 19.0068 11.5072 19.0582 11.7412C19.1032 11.9443 19.0286 12.1372 18.774 12.2554C17.9756 12.6244 15.633 13.4576 13.6517 13.4576C13.4357 13.4576 13.2853 13.347 13.2853 13.0706V11.4853ZM9.49118 12.0844C9.60946 11.0687 9.78175 10.7139 10.1417 10.7139C10.5043 10.7139 10.7139 10.9119 10.7139 11.4853V13.0706C10.7139 13.347 10.5635 13.4576 10.3475 13.4576C8.36618 13.4576 6.02361 12.6244 5.22518 12.2554C4.97061 12.1372 4.89604 11.9443 4.94104 11.7412C4.99375 11.5072 5.12104 11.3979 5.34861 11.3979C5.58518 11.3979 8.04089 12.0844 9.49118 12.0844Z"
        fill={`url(#paint3_linear_36566_241416_${id})`}/>
      <defs>
        <filter id={`filter0_d_36566_241416_${id}`} x="7.40088" y="17.7861" width="9.2002" height="3.92871"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.509681 0 0 0 0 0 0 0 0 0.371207 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result={`effect1_dropShadow_36566_241416_${id}`}/>
          <feBlend mode="normal" in="SourceGraphic" in2={`effect1_dropShadow_36566_241416_${id}`} result="shape"/>
        </filter>
        <filter id={`filter1_i_36566_241416_${id}`} x="6.34131" y="12.5146" width="11.3145" height="3.34277"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0387428 0 0 0 0 0.0406183 0 0 0 0 0.0875053 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result={`effect1_innerShadow_36566_241416_${id}`}/>
        </filter>
        <filter id={`filter2_d_36566_241416_${id}`} x="4.82871" y="10.7139" width="14.3416" height="3.44414"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="0.6"/>
          <feGaussianBlur stdDeviation="0.05"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.565875 0 0 0 0 0.151272 0 0 0 0 0 0 0 0 0.15024 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result={`effect1_dropShadow_36566_241416_${id}`}/>
          <feBlend mode="normal" in="SourceGraphic" in2={`effect1_dropShadow_36566_241416_${id}`} result="shape"/>
        </filter>
        <linearGradient id={`paint0_linear_36566_241416_${id}`} x1="12.0001" y1="1.71387" x2="12.0001" y2="15.5366"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#E04300"/>
          <stop offset="1" stopColor="#FFA320"/>
        </linearGradient>
        <linearGradient id={`paint1_linear_36566_241416_${id}`} x1="12.0006" y1="18.0471" x2="12.0006" y2="19.7147"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#3D0D00"/>
          <stop offset="1" stopColor="#661C04"/>
        </linearGradient>
        <linearGradient id={`paint2_linear_36566_241416_${id}`} x1="11.999" y1="12.5141" x2="11.999" y2="15.857"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#191A33"/>
          <stop offset="0.872" stopColor="#3B426A"/>
        </linearGradient>
        <linearGradient id={`paint3_linear_36566_241416_${id}`} x1="16.648" y1="13.7134" x2="16.648" y2="10.7139"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#9A2F00"/>
          <stop offset="1" stopColor="#D44800"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotAngry;
