import React from 'react';
import {useSelector} from "react-redux";
import cls from "classname";

const AttentionOutline = (props) => {

  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  return (
    <svg width={props.width ? props.width : 44}
         height={props.height ? props.height : 44}
         fill={props.color ? props.color : '#C5CCE3'}
         viewBox="0 0 16 16"
         xmlns="http://www.w3.org/2000/svg"
         className={cls('attention-outline-icon', {
           'disabled-transition': isDisabledTransition
         })}
    >
      <path
        d="M7.99992 1.33331C4.31826 1.33331 1.33325 4.31832 1.33325 7.99998C1.33325 11.6816 4.31826 14.6666 7.99992 14.6666C11.6816 14.6666 14.6666 11.6816 14.6666 7.99998C14.6666 4.31832 11.6816 1.33331 7.99992 1.33331ZM9.38881 11.6662C9.04489 11.8007 8.77152 11.9043 8.5665 11.9748C8.36147 12.0454 8.12558 12.0807 7.85442 12.0807C7.43995 12.0807 7.11588 11.9793 6.8844 11.7764C6.65512 11.5758 6.54048 11.3179 6.54048 11.0048C6.54048 10.8836 6.5493 10.7601 6.56694 10.6345C6.58237 10.5066 6.61103 10.3655 6.64851 10.2046L7.0784 8.68781C7.11588 8.54231 7.14895 8.40562 7.1754 8.27555C7.20186 8.14548 7.21288 8.02643 7.21288 7.91841C7.21288 7.72661 7.1732 7.58993 7.09383 7.51497C7.01226 7.43781 6.86015 7.40033 6.63528 7.40033C6.52505 7.40033 6.41041 7.41797 6.29357 7.45104C6.17673 7.48631 6.07752 7.51938 5.99375 7.55024L6.10838 7.08287C6.38837 6.96823 6.65733 6.87123 6.91526 6.78966C7.17099 6.70809 7.4157 6.66621 7.64278 6.66621C8.05503 6.66621 8.3747 6.76762 8.59736 6.96603C8.82002 7.16444 8.93246 7.42458 8.93246 7.74204C8.93246 7.80818 8.92584 7.92502 8.91041 8.09037C8.89498 8.25571 8.86632 8.40783 8.82443 8.54892L8.39674 10.0613C8.36147 10.1825 8.33061 10.3214 8.30195 10.4757C8.27549 10.6301 8.26226 10.7491 8.26226 10.8285C8.26226 11.0291 8.30636 11.168 8.39674 11.2407C8.48493 11.3135 8.64145 11.3487 8.86412 11.3487C8.96773 11.3487 9.08457 11.3311 9.21685 11.2958C9.34692 11.2584 9.44172 11.2275 9.50124 11.1988L9.38881 11.6662ZM9.31165 5.52644C9.11323 5.71162 8.87293 5.80421 8.59295 5.80421C8.31297 5.80421 8.07047 5.71162 7.86985 5.52644C7.66923 5.34125 7.57002 5.11638 7.57002 4.85404C7.57002 4.59169 7.67144 4.36462 7.86985 4.17723C8.07047 3.98984 8.31297 3.89725 8.59295 3.89725C8.87293 3.89725 9.11323 3.98984 9.31165 4.17723C9.51226 4.36462 9.61147 4.59169 9.61147 4.85404C9.61147 5.11638 9.51226 5.34125 9.31165 5.52644Z"
      />
    </svg>
  )
};

export default AttentionOutline;
