import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const Panel = ({ children, className, key }) => (
  <div
    className={cls(className, 'panel')}
    key={`panel-item-${key}`}
  >
    <div className="panel_wrap">{children}</div>
  </div>
);

Panel.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  key: PropTypes.string,
  isActive: PropTypes.bool,
};

export default Panel;
