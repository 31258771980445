import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import BreadcrumbsActions from '../Breadcrumbs/actions';

// COMPONENTS
import { Container } from '../../Components/Blocks';
import Search from '../../Components/Search';
import Icon from '../../Icon';
import {ButtonComponent} from '../../Components/Button';
import Collections from './components/collections';
import Products from './components/productList';
import { debounce } from '../../Utils/utils';
import CollectionsSkeletons from './components/skeleton/CollectionsSkeletons';
import Creators from './reducer';
import { EventHandler } from '../../Utils/event-handler';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import ModalComponent from '../../Components/Modal';
import {useNavigate} from "react-router-dom";

export const CollectionsPage = ({
  loading,
  fetching,
  products,
  collections,
  collection,
  theme,
  isMobile,
  skeletonCollections,
  collectionID,
  dispatch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchText, setSearch] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const collectionsList = collections.collections?.results || [];

  // BREADCRUMBS
  useEffect(() => {
    document.title = 'Collections - Dropship';
    dispatch(BreadcrumbsActions.clearBreadCrumbs());
    dispatch(
      BreadcrumbsActions.addBreadCrumb({
        name: 'Collections',
        link: '/collections',
      }),
    );
    dispatch(Creators.clearProductData());
    dispatch(Creators.getCollectionsRequest({ page_size: 40 }));
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  // CREATE COLLECTION
  const onCreateCollections = () => {
    EventHandler.emit('open-create-modal');
  };

  // GET NEXT PRODUCTS ACTION HANDLER
  const getNextProducts = () => {
    if (products?.next) {
      dispatch(
        Creators.getProductsNextRequest({
          link: products.next,
        }),
      );
    }
  };

  // GET NEXT COLLECTIONS ACTION HANDLER
  const getNextCollections = () => {
    if (collections?.next) {
      dispatch(
        Creators.getNextCollectionsRequest({
          link: collections.next,
        }),
      );
    }
  };

  // SEARCH HANDLER
  const handleSearch = debounce((e) => {
    const search = e.target.value.trim();

    // set text to local state
    setSearch(search);

    // get products after search
    dispatch(
      Creators.getProductsRequest({
        ...(search && { q: search, saved: true }),
        page_size: 40,
      }),
    );
  }, 500);

  // ADD/REMOVE ACTION HANDLER
  const addToCollectionFunc = ({ id, isSave, name }) => {
    if (!isSave) {
      dispatch(
        Creators.addToCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    } else {
      dispatch(
        Creators.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  // CLOSE DELETE MODAL
  function handleCancel() {
    setDeleteModalVisible(false);
  }

  // OPEN DELETE MODAL AND REMOVE COLLECTION
  function handleOk() {
    setDeleteModalVisible(false);
    dispatch(
      Creators.removeCollectionRequest({
        collectionID: collection.collectionID,
      }),
    );
  }

  return (
    <>
      {fetching || collectionsList.length ? (
        <CollectionsSkeletons />
      ) : (
        <Container className={cls('container collections-container')}>
          <div className="sales-tracker-top-page-header">
            <div style={{maxWidth: 1600, width: '100%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className="title-wrapper">
                <div className="title-wrapper-icon">
                  <Icon type={'collections_dashboard'} role={'icon'} />
                </div>
                <div className="sales-tracker-top-page-subheader">
                  <h3 className="sales-tracker-top-page-title">
                    {t(`Collections`)}
                  </h3>
                  <h4 className="sales-tracker-top-page-subtitle">
                    {t(`Your saved products from Portfolio`)}
                  </h4>
                </div>
              </div>
              <div className={'sales-tracker-top-page-limits-wrapper'}>
                <div style={{width: 172}} className={'sales-tracker-top-page-watch-tutorial'}
                     onClick={() => navigate('/portfolio')}
                >
                  <Icon type={'portfolio_button_icon'} role={'icon'} />
                  <span>{t('Go To Portfolio')}</span>
                </div>
              </div>
            </div>
          </div>
          <Search
            handleSearch={handleSearch}
            button={
              <ButtonComponent
                key="submit"
                type="primary"
                className="btn-primary"
                onClick={onCreateCollections}
                text={
                  <>
                    <Icon type="plus" role="icon" />
                    <span className="btn_text">
                      {t('common.create_collection')}
                    </span>
                  </>
                }
              />
            }
          />
          {searchText ? (
            <Products
              products={(products.results && products.results) || []}
              loading={loading}
              theme={theme}
              getNextList={getNextProducts}
              next={products?.next}
              searchText={searchText}
              addToCollectionFunc={(id, isSave, name) =>
                addToCollectionFunc({ id, isSave, name })
              }
            />
          ) : (
            <Collections
              collections={collections.results}
              loading={loading}
              next={collections?.next}
              skeletonCollections={skeletonCollections}
              getNextCollections={getNextCollections}
              dispatch={dispatch}
              collectionID={collectionID}
              setDeleteModalVisible={setDeleteModalVisible}
            />
          )}
        </Container>
      )}
      <ModalComponent
        width={411}
        title={isMobile ? t('Are you sure?') : t('common.remove_collection')}
        isMobile={isMobile}
        handleClose={handleCancel}
        className="collections collections--delete-collection"
        open={isDeleteModalVisible}
        onCancel={handleCancel}
        destroyOnClose
        footer={footerModal({
          isMobile,
          handleCancel,
          textBtnCancel: t('Cancel'),
          handleOk,
          textBtnOk: t('Delete'),
          divider: true,
          deleteType: true,
        })}
      >
        <div>{t('common.are_sure')}</div>
      </ModalComponent>
   </>
  );
};

CollectionsPage.propTypes = {
  skeletonProducts: PropTypes.bool,
  skeletonCollections: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
  ]),
  collections: PropTypes.object,
  collection: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  products: PropTypes.object,
  theme: PropTypes.string,
  isOpenCollectionModal: PropTypes.bool,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  skeletonProducts: state.collections.skeletonProducts,
  skeletonCollections: state.collections.skeletonCollections,
  collectionId: state.collections.collectionId,
  loading: state.collections.loading,
  fetching: state.collections.fetching,
  errors: state.collections.errors,
  collections: state.collections.collections,
  collection: state.collections.collection,
  products: state.collections.products,
  isOpenCollectionModal: state.collections.isOpenCollectionModal,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
  collectionID: state.collections.collectionID,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage);
