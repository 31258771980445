import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider } from 'antd';
import Images from '../../../Images';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import Modal from '../../../Components/Modal';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import utils from '../../../Utils/utils';
import './CompetitorsBlock.less';

const acc = require('accounting');

export const CompetitorsBlock = ({ skeleton, isMobile, product, onOpenUpgradePlan }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const isEmptyState = !product?.competitors?.length;

  const competitorsList = !isEmptyState ? [...product?.competitors] : [];
  const competitorsShortList =
    competitorsList?.length > 5
      ? competitorsList?.slice(0, 5)
      : competitorsList;
  const skeletonList = [1, 2, 3, 4, 5];
  const isLocked = product?.is_locked && competitorsList.length > 3

  const handleClose = () => {
    setVisible(false);
  };

  const renderHeader = () => {
    let result = null;

    if (isEmptyState) {
      result = <span className="title">{t('No Competitors Found')}</span>;
    } else {
      result = (
        <>
          <span className="title">
            {t('Competitors')}
            <span className="header-tag">{product?.competitors_count}</span>
          </span>
          {competitorsList?.length > 5 ? (
            <span className={cls('link', { disabled: isLocked })}
                  onClick={() => setVisible(!isLocked)}
            >
              {isLocked ? <Icon role="icon" type="lock"/> : ''}
              {t('View All')}
            </span>
          ) : null}
        </>
      );
    }

    return result;
  };

  const renderHeaderSkeleton = (
    <>
      <span className="title skeleton" style={{ width: 133 }}>
        <span className="header-tag skeleton" />
      </span>

      <span className="link skeleton" style={{ width: 62 }} />
    </>
  );

  const renderCompetitorsList = (arr, className) => (
    <Row gutter={[0, 18]} className={cls('competitors-card-wrap', className)}>
      {arr?.map((el, index) => (
        <Col key={(el?.domain || el) + index} span={24}>
          <div className={cls('competitors-card', {locked: !skeleton && el.is_locked})}>
            {skeleton ? (
              <div className="competitors-card-info-wrap">
                <span className="competitors-card-img skeleton" />
                <span
                  className="competitors-card-link skeleton"
                  style={{
                    width: isMobile
                      ? utils.randomNum(55, 90)
                      : index
                      ? 140
                      : 108,
                  }}
                />
              </div>
            ) : (
              <div className="competitors-card-info-wrap">
                <img
                  alt=""
                  src={el.favicon || Images.defaultImg}
                  onError={utils.addDefaultSrc}
                  className="competitors-card-img"
                />
                <a
                  href={el.link || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="competitors-card-link"
                >
                  {el.domain}
                </a>
              </div>
            )}

            <span
              className={cls('competitors-card-tag', { skeleton })}
              style={
                skeleton
                  ? { width: isMobile ? 48 : utils.randomNum(53, 113) }
                  : {}
              }
            >
              {skeleton ? null : el.platform}
            </span>

            <span
              className={cls('competitors-card-price', { skeleton })}
              style={
                skeleton
                  ? {
                      width: isMobile
                        ? utils.randomNum(66, 90)
                        : utils.randomNum(88, 94),
                    }
                  : {}
              }
            >
              {skeleton
                ? null
                : `${acc.formatNumber(el?.price, 2, '', ',')} ${el?.currency}`}
            </span>
          </div>
          {isMobile ? <Divider /> : null}
        </Col>
      ))}
    </Row>
  );

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
        {renderCompetitorsList(skeletonList)}
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard header={renderHeader()}
                        withLine={!isMobile}
                        isLocked={isLocked}
                        onOpenUpgradePlan={onOpenUpgradePlan}
    >
      {isEmptyState ? (
        <div className="competitors-card empty">
          <Icon role="icon" type="competitors_empty" />
        </div>
      ) : (
        renderCompetitorsList(competitorsShortList)
      )}

      <Modal
        className="modal-competitors small"
        title={
          <span>
            {t('Competitors')}{' '}
            <span className="header-tag">{product?.competitors_count}</span>
          </span>
        }
        open={visible}
        width={649}
        handleClose={handleClose}
        isMobile={isMobile}
        destroyOnClose
      >
        <OverlayScrollbar>
          {renderCompetitorsList(competitorsList, 'withScroll')}
        </OverlayScrollbar>
      </Modal>
    </ProductDetailsCard>
  );
};

export default CompetitorsBlock;
