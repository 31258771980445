import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ButtonComponent from "../../../Components/Button";
import './CanNotRemoveTracking.less';
import {Divider} from "antd";

const CanNotRemoveTracking = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'can-not-remove-tracking-wrapper'}>
      <h4 className={'can-not-remove-tracking-title'}>
        {t('Can Not Remove Tracking')}
      </h4>
      <p className="can-not-remove-tracking-text">
        {t('Free trial users can not remove a store/product from tracking. To access this feature, you need to activate your membership.')}
      </p>
      <Divider type={'horizontal'} style={{margin: '24px auto'}} />
      <ButtonComponent className={'can-not-remove-tracking-button'}
                       text={t('Go To Billing')}
                       onClick={() => navigate('/setting/plan')}
      />
    </div>
  );
};

export default CanNotRemoveTracking;
