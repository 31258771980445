import React from 'react';
import {Divider} from "antd";

const values = {
  first: {
    1: {
      first: 56,
      second: 82
    },
    2: {
      first: 115,
      second: 100
    },
    3: {
      first: 115,
      second: 100
    }
  },
  second: {
    1: {
      first: 175,
      second: 154
    },
    2: {
      first: 216,
      second: 195
    },
    3: {
      first: 230,
      second: 195
    }
  }
}

const PlanCardOnboardingSkeleton = ({ index }) => {
  return (
    <div className={'plan-card-onboarding'} style={{position: "relative"}}>

      <span  className={'link skeleton'} style={{width: 174, height: 174, position: "absolute", left: 0, top: -69}}/>

      {index === 2 && (
        <span  className={'link skeleton'} style={{width: 120, height: 32, position: "absolute", right: 16, top: 16}}/>
      )}

      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%'}}>
        <span className="link skeleton" style={{width: values.first[index].first, height: 32}} />
        <span className="link skeleton" style={{width: values.first[index].second, height: 32}} />
      </div>

      <div style={{display: "flex", flexDirection: "column", marginTop: 14}}>
        <span className="link skeleton" style={{width: values.second[index].first, height: 16}} />
        <span className="link skeleton" style={{width: values.second[index].second, height: 16, marginTop: 4}} />
      </div>

      <Divider type={'horizontal'} style={{margin: '16px auto 0'}} />

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 119, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 115, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 119, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 115, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 140, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 115, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 90, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 58, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 106, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 75, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 106, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 75, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 96, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 171, height: 16}} />
      </div>

      <div className={'skeleton-plan-card-info'}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className={'link skeleton'} style={{width: 96, height: 16, marginRight: 4}} />
          <span className={'link skeleton'} style={{width: 16, height: 16}} />
        </div>
        <span className={'link skeleton'} style={{width: 171, height: 16}} />
      </div>

      <span className="link skeleton" style={{width: '100%', height: 48, marginTop: 28}} />

    </div>
  );
};

export default PlanCardOnboardingSkeleton;
