import React, {useEffect, useState} from 'react';
import './SaveFilterPresetBlock.less';
import InputComponent from "../../../Components/Input";
import ButtonComponent from "../../../Components/Button";
import {useDispatch, useSelector} from "react-redux";
import Creators from "../reducer";
import cls from "classname";
import {useTranslation} from "react-i18next";

const SaveFilterPresetBlock = (props) => {
  const {setVisible, saveFilterPreset, filters, category} = props;
  const { t } = useTranslation();
  //input value
  const [inputValue, setInputValue] = useState('');
  //if create error
  const saveError = useSelector(store => store[category].createError);
  //show correct name in popup
  const saveResult = useSelector(store => store[category].createResult);
  const dispatch = useDispatch()

  const changeHandler = e => {
    setInputValue(e.target.value)
    dispatch(Creators.resetCreateError())
  }

  //reset create popup, errors on load
  useEffect(() => {
    if (saveResult) {
      setVisible(false)
      dispatch(Creators.resetCreateResult())
    }
    dispatch(Creators.resetCreateError())
  }, [dispatch, setVisible, saveResult])

  return (
    <div className={'load-filter-preset-wrapper save-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Save Filter')}
      </div>
      <p className="load-filter-preset-subtitle">
        {t('name_new_preset')}
      </p>
      <div className="save-filter-preset-input-wrapper" style={{position: 'relative'}}>
        {/*red error text above input*/}
        {saveError && <p
          style={{color: '#D71313', top: -24, fontSize: 12, lineHeight: '16px', position: 'absolute'}}>
          {saveError}
        </p>}
        <InputComponent onChange={changeHandler}
                        className={cls('save-filter-preset-input', {
                          'save-filter-preset-input-error': saveError
                        })}
                        value={inputValue}
                        placeholder={t('common.create_col_placeholder')}
        />
        <ButtonComponent className={'save-filter-preset-submit'}
                         text={t('Save Preset')}
                         onClick={() => {
                           saveFilterPreset({name: inputValue.trim(), value: {...filters}})
                         }}
                         disabled={!inputValue}
        />
      </div>
    </div>
  );
};

export default SaveFilterPresetBlock;
