import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Input, Divider, Select, message } from 'antd';
import cls from 'classname';
import BdCreators from './reducer';
import Icon from '../../Icon';
import { Container } from '../../Components/Blocks';
import { debounce } from '../../Utils/utils';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import BDlist from './components/BDlist';
import ModalComponent from '../../Components/Modal';
import DeleteModal from './components/DeleteModal';
import ConfigureModal from './components/ConfigureModal';
import ChooseIconModal from './components/ChooseIconModal';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import openNotification from '../../Components/Notification';
import ButtonComponent from "../../Components/Button";
import './styles.less';

const optionsSelect = [
  { label: 'All', value: 'all', id: '1' },
  { label: 'Benefits', value: 'benefits', id: '2' },
  { label: 'Drawbacks', value: 'drawbacks', id: '3' },
];

const modalTitle = {
  configure: 'Configure',
  choose_icon: 'Choose Icon',
  delete: 'Delete Benefit/Drawback',
};

function BDPageAdmin({
  fetching,
  fetchingIcons,
  getBdList,
  getBdListNext,
  bdList,
  bdIcons,
  getBdIcons,
  getBdIconsNext,
  isMobile,
  createBdCard,
  changeBdCard,
  deleteBdCard,
  saveResultReset,
  resetError,
  errors,
  saveResult,
}) {
  const [searchText, setSearchText] = useState(null);
  const [filterOrder, setFilterOrder] = useState(null);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);
  const [iconsBtn, setIconsBtn] = useState('benefits');
  const [formData, setFormData] = useState(null);
  const [searchIcon, setSearchIcon] = useState(null);
  const [startValue, setStartValue] = useState(null);
  const [showArrow, setShowArrow] = useState(true);

  const { Search } = Input;
  const { Option } = Select;

  useEffect(() => {
    document.title = 'Benefits & Drawbacks - Dropship'
  }, []);

  useEffect(() => {
    if (errors?.error) {
      openNotification({
        type: 'warning',
        message: 'Something went wrong, kindly try again',
        style: { minWidth: '716px' },
      });
      resetError();
      handleCancel();
    } else if (errors && !errors?.error) {
      message.error('Server response error', 1);
      message.destroy();

      handleCancel();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!errors]);

  useEffect(() => {
    if (!!saveResult) {
      openNotification({
        type: 'success',
        message: `${
          iconsBtn === 'benefits' ? 'Benefit' : 'Drawback'
        } has been successfully ${saveResult?.value}`,
        style: { minWidth: '716px' },
      });
      handleCancel();
      getBdList({
        ...(searchText && { q: searchText }),
        ...(filterOrder && { _type: filterOrder }),
        page_size: 33,
      });
    }
    saveResultReset();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!saveResult]);

  useEffect(() => {
    getBdList({
      ...(searchText && { q: searchText }),
      ...(filterOrder && { _type: filterOrder }),
      page_size: 33,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleSearch = debounce((e) => {
    const search = e.target?.value ? e.target?.value.trim() : null;
    if (!modal) {
      getBdList({
        ...(search && { q: search }),
        ...(filterOrder && { _type: filterOrder }),
        page_size: 33,
      });
      setSearchText(search);
    } else {
      setSearchIcon(search);
      setFormData({ ...formData, icon: null });
      getBdIcons({
        ...(search && { q: search }),
        ...(iconsBtn && { _type: iconsBtn }),
        page_size: 18,
      });
    }
  }, 500);

  const modalBlock = {
    configure: (
      <ConfigureModal
        iconsBtn={iconsBtn}
        setIconsBtn={setIconsBtn}
        setModal={setModal}
        formData={formData}
        setFormData={setFormData}
        getBdIcons={getBdIcons}
        icons={bdIcons?.results}
        onSubmit={handleOk}
        onSelectIcon={handleSelectIcon}
        onDelete={handleDelete}
        fetching={fetchingIcons}
      />
    ),
    choose_icon: (
      <ChooseIconModal
        handleSearch={handleSearch}
        onSelectIcon={handleSelectIcon}
        icons={bdIcons?.results || []}
        next={bdIcons?.next}
        fetching={fetchingIcons}
        getBdIconsNext={getBdIconsNext}
        searchIcon={searchIcon}
        iconsBtn={iconsBtn}
        selectedId={formData?.icon?.id}
      />
    ),
    delete: <DeleteModal />,
  };
  function emptyFormData() {
    if (formData) {
      if (startValue) {
        return (
          startValue?.description === formData?.description &&
          startValue?.icon?.id === formData?.icon?.id &&
          startValue?.title === formData?.title &&
          startValue?._type === formData?._type
        );
      } else {
        return Object.values(formData).some((el) => !el);
      }
    }
    return false;
  }

  const btnOK = {
    configure: emptyFormData() ? 'Add' : 'Submit',
    choose_icon: 'Select',
    delete: 'Delete',
  };

  const handleFilter = (filter) => {
    let filtered = null;
    if (filter === 'benefits') {
      filtered = 1;
    } else if (filter === 'drawbacks') {
      filtered = 2;
    }

    setFilterOrder(filtered);
    getBdList({
      ...(searchText && { q: searchText }),
      ...(filtered && { _type: filtered }),
      page_size: 33,
    });
  };

  function getNextList() {
    if (bdList?.next) getBdListNext(bdList?.next);
  }

  function handleChange(value) {
    setIconsBtn(value?._type === 'Benefits' ? 'benefits' : 'drawbacks');
    setVisible(true);
    setModal('configure');
    setFormData(value);
    setStartValue(value);
  }

  function handleAdd() {
    setVisible(true);
    setFormData({ _type: '1', icon: null, title: null, description: null });
    setModal('configure');
    setIconsBtn('benefits');
  }
  function handleOk() {
    if (modal === 'choose_icon') {
      setModal('configure');
    } else if (modal === 'configure') {
      !formData?.id
        ? createBdCard({
            ...formData,
            icon: { id: formData?.icon?.id },
          })
        : changeBdCard({
            ...formData,
            _type: formData?._type === 'Benefits' || formData?._type === '1' ? '1' : '2',
          });
    } else if (modal === 'delete') {
      deleteBdCard({ id: formData?.id });
    }
  }
  function handleCancel() {
    setModal(null);
    setVisible(false);
    setSearchIcon(null);
    setFormData(null);
    setStartValue(null);
  }

  function handleSelectIcon(icon) {
    setFormData(formData => ({ ...formData, icon }));
  }
  function disabledButton() {
    if (modal === 'configure' && formData) {
      return emptyFormData();
    }
    if (modal === 'choose_icon' && formData?.icon?.id) {
      return false;
    }
    if (modal === 'delete' && formData?.id) {
      return false;
    }

    return true;
  }

  function handleDelete() {
    const clearData = {
      _type: iconsBtn === 'benefits' ? '1' : '2',
      title: null,
      description: null,
      icon: null,
    };
    formData?.id ? setModal('delete') : setFormData(clearData);
  }

  return (
      <Container className="bd-admin-page" id="bd-admin-page">
        <div className="bd-search">
          <div className="search_wrapper">
            <Search
              placeholder="Search keywords"
              className="search-input"
              onChange={(e) => {
                e.persist();
                handleSearch(e);
              }}
              prefix={
                <>
                  <Icon type="search_icon" role="icon" />
                  <Divider type="vertical" className="search-divider" />
                </>
              }
            />

            <Select
              // labelInValue
              className="custom-select bd-admin-select"
              popupClassName="custom-dropdown bd-admin-dropdown"
              showArrow
              optionFilterProp="options"
              placeholder="All"
              onSelect={(value) => handleFilter(value)}
              listHeight={136}
              getPopupContainer={(trigger) => trigger.parentNode}
              suffixIcon={
                <div
                  className={cls('arrow', {
                    arrow_selected: !showArrow,
                  })}
                >
                  <ArrowSelectDown />
                </div>
              }
              onDropdownVisibleChange={() => {
                setShowArrow(!showArrow);
              }}
            >
              {optionsSelect.map((el) => (
                <Option value={el.value} key={el.id}>
                  <span onClick={(e) => e.preventDefault()}>{el.label}</span>
                </Option>
              ))}
            </Select>
            <ButtonComponent type="primary" onClick={handleAdd} className="add-new-btn">
              Add New
            </ButtonComponent>
          </div>
        </div>
        <BDlist
          fetching={fetching}
          list={bdList?.results || []}
          next={bdList?.next}
          getNextList={getNextList}
          searchText={searchText}
          filterOrder={filterOrder}
          onChange={handleChange}
        />
        <ModalComponent
          width={512}
          title={modalTitle[modal]}
          isMobile={isMobile}
          handleClose={handleCancel}
          className={cls('bd-modal', {
            [modal]: modal,
          })}
          open={visible}
          onCancel={handleCancel}
          destroyOnClose
          confirmLoading={fetching}
          footer={footerModal({
            isMobile,
            handleCancel,
            textBtnCancel: 'Cancel',
            handleOk,
            textBtnOk: btnOK[modal],
            isDisabledBtnOk: disabledButton(),
            deleteType: modal === 'delete',
            width: modal !== 'delete' ? '358px' : null,
            divider: true,
          })}
        >
          {modalBlock[modal]}
        </ModalComponent>
      </Container>
  );
}

const mapStateToProps = (state) => ({
  fetching: state.benefitsDrawbacks.fetching,
  fetchingIcons: state.benefitsDrawbacks.fetchingIcons,
  bdList: state.benefitsDrawbacks.bdList,
  bdIcons: state.benefitsDrawbacks.bdIcons,
  isMobile: state.nav.isMobile,
  saveResult: state.benefitsDrawbacks.saveResult,
  errors: state.benefitsDrawbacks.errors,
});
const mapDispatchToProps = (dispatch) => ({
  getBdList: (search) => dispatch(BdCreators.getBdListRequest(search)),
  getBdListNext: (link) => dispatch(BdCreators.getBdListNextRequest(link)),
  getBdIcons: (search) => dispatch(BdCreators.getBdIconsRequest(search)),
  getBdIconsNext: (link) => dispatch(BdCreators.getBdIconsNextRequest(link)),
  createBdCard: (data) => dispatch(BdCreators.createBdCardRequest(data)),
  changeBdCard: (data) => dispatch(BdCreators.changeBdCardRequest(data)),
  deleteBdCard: (data) => dispatch(BdCreators.deleteBdCardRequest(data)),
  saveResultReset: () => dispatch(BdCreators.bdSaveResultReset()),
  resetError: () => dispatch(BdCreators.bdErrorsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BDPageAdmin);
