import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import acc from 'accounting';
import { Col, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptySearch from '../../../../Components/EmptyState';
import SaveToCollection from '../../../../Components/SaveToCollections/save-to-collections';
import { ImageCellComponent as Image } from '../../../../Components/Image';

export const ProductsComponent = ({
  products,
  loading,
  searchText,
  getNextList,
  next,
  addToCollectionFunc,
}) => {
  const productsRef = useRef();
  const { t } = useTranslation();

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const productsContent = productsRef.current;

    if (productsContent) productsContent.addEventListener('scroll', onScrollHandlerFunc);

    return ()=>{
      if (productsContent) productsContent.removeEventListener('scroll', onScrollHandlerFunc);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SCROLL TO TOP WHEN INPUT TEXT
  useEffect(() => {
    scrollToTop();
  }, [searchText]);

  // SCROLL TO TOP
  const scrollToTop = () => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={cls('products-overview', {
        'products-overview--empty': products.length === 0,
      })}
    >
      <Spin spinning={loading}>
      <div className="products-overview_wrapper"
        ref={productsRef}
      >
        <Row
          className="products-overview_wrapper_internal-block"
          gutter={{ xs: 0, sm: 16 }}
        >
          {products.length > 0 ? (
            products.map((value) => (
              <Col xs={24} md={12} lg={8} key={`col-${value.product_id}`}>
                <div className="product" key={`product-${value.product_id}`}>
                  <Link
                    to={`/collections/product/${value.product_id}`}
                    className="product_image"
                    state={value}
                  >
                    {value.image && <Image src={value?.image?.url} />}
                  </Link>
                  <div className="product_wrap">
                  <Link
                    to={`collections/product/${value.product_id}`}
                    className="product_name"
                  >
                    {value.title}
                  </Link>
                    <div className="product_favorite">
                      <SaveToCollection
                        saveFunc={(isSaved) =>
                          addToCollectionFunc(
                            value.product_id,
                            isSaved,
                            value.title,
                          )
                        }
                        isSave={value.saved}
                      />
                    </div>
                  </div>
                  <div className="product_details">
                  <div className="product_row">
                      <div className="product-detail">
                        <div className="product-detail_title">{t('Cost')}</div>
                        <div className="product-detail_value">{value.cost ? value?.cost?.replaceAll('.',',') : 0}</div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">{t('Price')}</div>
                        <div className="product-detail_value">
                          ${acc.formatNumber(value.price, 2, ' ', ',')}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">{t('P/C Ratio')}</div>
                        <div className="product-detail_value">
                          {acc.formatNumber(value.p_c_ratio, 2, ' ', '.')}X
                        </div>
                      </div>
                    </div>
                    <div className="product_row">
                      <div className="product-detail">
                        <div className="product-detail_title">{t('BECPA')}</div>
                        <div className="product-detail_value">
                          ${acc.formatNumber(value.BECPA, 2, ' ', ',')}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">{t('BEROAS')}</div>
                        <div className="product-detail_value">
                          {acc.formatNumber(value.BEROAS, 2, ' ', '.')}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">{t('POT Profit')}</div>
                        <div className="product-detail_value">
                          ≈${acc.formatNumber(value.pot_profit, 0, ',', ',')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={`/collections/product/${value.product_id}`} className="product_view-details">
                    {t('View Details')}
                  </Link>
                </div>
              </Col>
            ))
          ) : (
            <EmptySearch />
          )}
        </Row>
        </div>
      </Spin>
    </div>
  );
};

ProductsComponent.defaultProps = {
  products: [],
  loading: false,
};

ProductsComponent.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  getNextList: PropTypes.func.isRequired,
  addToCollectionFunc: PropTypes.func.isRequired,
  next: PropTypes.string,
};

export default ProductsComponent;
