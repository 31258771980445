import React, {useEffect} from 'react';
import cls from "classname";
import Images from "../../Images";
import ButtonComponent from "../../Components/Button";
import Modal from "../../Components/Modal";
import Creators from "./reducer";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Spin} from "antd";
import openNotification from "../../Components/Notification";

const text = 'Are you sure you want to disconnect _store_ from your Dropship account?';

const ModalAutoDsDisconnect = (
  {
    isVisibleModal,
    theme,
    changeVisibleModalAutoDS,
    isMobile,
    autoDS,
    deleteResult,
    deleteErrors,
    resetErrors,
    deleteStore
  }) => {

  const {t} = useTranslation();

  const closeModalAutoDS = () => changeVisibleModalAutoDS({isVisibleModal: null, importProductId: null})

  const handleClose = () => closeModalAutoDS();

  useEffect(() => {
    if(deleteResult || deleteErrors){
      if(deleteResult){
        openNotification({
          message: t('Your AutoDS account has been successfully disconnected'),
          style: { minWidth: '716px' },
        });
        closeModalAutoDS();
      }
      if(deleteErrors){
        openNotification({
          message: t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
          type: 'error'
        });
      }
      resetErrors();
    }
  }, [deleteResult, deleteErrors])

  return (
    <Modal
      className={cls('shopify-connect autods-connect autods-disconnect')}
      title={'Disconnect Store'}
      open={isVisibleModal === 'disconnect'}
      width={592}
      handleOk={null}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
      <Spin spinning={autoDS?.loading}>
        <img src={Images[theme === 'dark' ? 'autoConnectDark' : 'autoConnect']}
             alt=""
        />
        <p>{t(text, {store: autoDS?.email || 'account'})}</p>

        <ButtonComponent
          type="primary"
          className={cls('btn-store')}
          disabled={false}
          onClick={() => {
            deleteStore();
          }}
          style={{width: '100%', height: 48}}
        >
          {t('Disconnect')}
        </ButtonComponent>
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  autoDS: state.autoDS,
  deleteResult: state.autoDS?.deleteResult,
  deleteErrors: state.autoDS?.deleteErrors,
  isVisibleModal: state.autoDS.isVisibleModal,
  theme: state?.nav?.theme,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  resetErrors: () => dispatch(Creators.resetState()),
  deleteStore: () => dispatch(Creators.deleteAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDsDisconnect);
