import React, { useState, useEffect } from 'react';
import {connect, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Divider, Dropdown, Spin } from 'antd';
import Creators from './reducer';
import openNotification from '../../Components/Notification';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Modal from '../../Components/Modal';
import Icon from '../../Icon';
import ButtonComponent from "../../Components/Button";
import InputComponent from "../../Components/Input";
import './styles.less';

const text = 'You can import the product to your existing Shopify store or connect a new store to your Dropship account.';

const ModalShopifyImport = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    closeModalShopify,
    ...props
  }) => {

  const { loading, isVisibleModal, importErrors, importResult, importResultData, results, importProductId, isTrial } = shopifyStore;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkOauth = shopifyStore?.checkOauth;
  const [visible, setVisible] = useState(false);
  const [visibleNewStoreInput, setVisibleNewStoreInput] = useState(false);
  const [selectStore, setSelectStore] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const count = results.length || 0;
  const REDIRECT_URL_TO_OUR_SITE = document?.location?.origin + '/connect-shopify/verify' //href

  useEffect(() => {
    if (count && !selectStore && isVisibleModal === 'import') {
      setSelectStore(results?.[0]);
    }
    dispatch(Creators.resetOauth());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [count, isVisibleModal]);

  useEffect(() => {
    if (checkOauth && isVisibleModal === 'import' && storeName !== null) {
      window.open(`https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_STORE_KEY}&scope=write_products&redirect_uri=${REDIRECT_URL_TO_OUR_SITE}&state=${userInfo?.id}&grant_options[]=value`, '_self')
    }
    else if (!checkOauth && typeof checkOauth !== 'object' && isVisibleModal === 'import') openNotification({
      type: 'warning',
      message: t('This store is already connected.'),
      style: { minWidth: '716px' },
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [checkOauth, isVisibleModal])

  useEffect(() => {
    if (importResult || importErrors) {
      if (importResult) {
        openNotification({
          message: isTrial ?
            (
              <div className={'trial-shopify-note'}>
                <p>The product has been successfully imported to Shopify.</p>
                <p>Note: Images cannot be imported into Shopify accounts on a trial</p>
              </div>
            )
            :
            <p style={{margin: 0}}>
              {t(importResultData?.message?.detail)}
              <a href={importResultData?.message?.link?.url || '#'}
                 rel="noopener noreferrer"
                 target='_blank'
                 style={{marginLeft: 4}}>
                {importResultData?.message?.link?.text}
              </a>
            </p>,
          style: { minWidth: '716px' },
        });
        handleClose();
      }
      if (importErrors) {
        openNotification({
          message: importErrors?.link ? (
            <p style={{margin: 0}}>
              {importErrors?.detail}
              <a href={importErrors?.link?.url || '#'}
                 rel="noopener noreferrer"
                 target='_blank'
                 style={{marginLeft: 4}}
              >
                {importErrors?.link?.text}
              </a>
            </p>
          ) : (importErrors?.detail || importErrors),
          style: { minWidth: '716px' },
          type: 'error',
        });
      }
      props.importProductShopifyStoreReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [importResult, importErrors, isTrial]);

  const handleClose = () => {
    if (selectStore) setSelectStore(null)
    if (isVisibleModal === 'import') closeModalShopify();
  }

  const handleVisibleChange = (value) => {
    setVisible(value);
    if(!value && visibleNewStoreInput) setVisibleNewStoreInput(false)
    if(!value && storeName) setStoreName(null)
  };

  const handleSelectStore = (store) => {
    if (selectStore?.id !== store?.id) setSelectStore(store);
    if(visibleNewStoreInput) setVisibleNewStoreInput(false)
    if(storeName) setStoreName(null)
    setVisible(false);
  };

  const handleVisibleNewStoreInput = () => {
    window.open('https://apps.shopify.com/dropship-10?show_store_picker=1', '_self')
  }

  function handleChange(e) {
    const { value } = e.target;
    setStoreName( value );
  }

  const handleImport = () => {
    if (loading) return;
    if (selectStore?.id && importProductId) {
      props.importProductShopifyStore({ shop_id: selectStore?.id, product_id: importProductId });
    }
  };

  return (
    <Modal
      className={cls('shopify-import')}
      title={t('Import Product')}
      open={isVisibleModal === 'import'}
      width={596}
      handleOk={() => {
      }}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
      <Spin spinning={loading}>
        <p>{t(text)}</p>

        <div className={'import-dropdown-wrap'}>
          <Dropdown
            overlayClassName={'dropdown-store-wrapper'}
            destroyPopupOnHide={true}
            getPopupContainer={trigger => isMobile ? document.getElementById('global-wrap') : trigger.parentNode}
            onOpenChange={handleVisibleChange}
            dropdownRender={() => (
              <>
                <ul className="user_store-list" style={{ maxHeight: 168 }}>
                  {
                    results?.map(({ id, name, domain, ...other }) => (
                      <li key={id}
                          className="user_store-item user_store-item_clickable"
                          onClick={() => handleSelectStore({ id, name, domain })}
                      >
                        <span className="user_store-name">{name}</span>
                        <span className="user_store-link">{domain}</span>
                      </li>
                    ))
                  }
                </ul>
                <Divider style={{ margin: '16px 0' }}/>

                {
                  visibleNewStoreInput
                  ? (
                      <div className='input-store-wrapper input-store-wrapper_small'>
                        <ButtonComponent
                          type="primary"
                          className={cls('btn-store', {
                            'btn-store-loading': loading
                          })}
                          onClick={() => window.open('https://apps.shopify.com/dropship-10?show_store_picker=1', '_self')}
                        >
                          {t('Connect')}
                        </ButtonComponent>
                      </div>)
                  :
                    <span className="text-link" onClick={handleVisibleNewStoreInput}>
                      <Icon type="plus" role="icon" width={16} height={16}/>
                      {t('Connect New Store')}
                    </span>
                }
              </>
            )}
            trigger={['click']}
            open={visible}
          >
            <div className="dropdown-store">
            <span className="dropdown-store_name">
              {selectStore?.domain}
            </span>
              <ArrowSelectDown/>
            </div>
          </Dropdown>

          <ButtonComponent
            type="primary"
            className="btn-primary"
            onClick={handleImport}
          >
            {t('Import')}
          </ButtonComponent>
        </div>
      </Spin>
    </Modal>
  );
};

ModalShopifyImport.defaultProps = {
  shopifyStore: {
    loading: false,
    results: [],
    importProductId: null,
  },
};

ModalShopifyImport.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  userInfo: PropTypes.object,
  shopifyStore: PropTypes.shape({
    loading: PropTypes.bool,
    importResult: PropTypes.bool,
    results: PropTypes.array,
    isVisibleModal: PropTypes.string,
    importProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  selectStore: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    domain: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  importProductShopifyStore: (data) => dispatch(Creators.importProductShopifyStoreRequest(data)),
  importProductShopifyStoreReset: () => dispatch(Creators.importProductShopifyStoreReset()),
  closeModalShopify: () => dispatch(Creators.changeVisibleModalShopify({isVisibleModal: null, initialUrl: null, importProductId: null})),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyImport);
