import React from 'react';

const CompetitorsDashboard = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width ? width : 40}
         height={height ? height : 40}
         viewBox="0 0 40 40"
         fill="none">
      <path
        d="M3.33337 17.4999C3.33337 9.66697 9.61561 3.31714 17.3651 3.31714C21.0866 3.31714 24.6556 4.81139 27.2871 7.47117C29.9186 10.1309 31.3969 13.7384 31.3969 17.4999C31.3969 25.3328 25.1147 31.6826 17.3651 31.6826C9.61561 31.6826 3.33337 25.3328 3.33337 17.4999Z"
        fill="#F6B60E"/>
      <path
        d="M31.7472 29.2485C31.0527 28.554 29.9267 28.554 29.2322 29.2485C28.5377 29.943 28.5377 31.069 29.2322 31.7635L33.6308 36.1622C34.3253 36.8567 35.4513 36.8567 36.1458 36.1622C36.8403 35.4677 36.8403 34.3417 36.1458 33.6472L31.7472 29.2485Z"
        fill="#F6B60E"/>
    </svg>
  );
};

export default CompetitorsDashboard;
