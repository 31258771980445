import React from 'react';
import {Divider} from "antd";

const MainBlockNewSkeleton = ({isMobile=false, autoDS=false}) => {

  const statBlocks = [
    {title: 115, value: 87},
    {title: 132, value: 95},
    {title: 86, value: 44},
    {title: 100, value: 21},
    {title: 75, value: 70},
  ];

  return (
    <div className="report-main-block report-main-block-new report-main-block-skeleton"
         style={{display: 'flex', flexDirection: 'column'}}
    >
      <div style={{display: 'flex', justifyContent: isMobile ? 'flex-start' : 'space-between', alignItems: 'center', width: '100%'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className="link skeleton" style={{width: 64, height: 64, marginRight: 12}}/>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <span className="link skeleton" style={{width: 163, height: 32}}/>
            <span className="link skeleton" style={{width: 150 , height: 16, marginTop: 4}}/>
          </div>
        </div>
        {
          autoDS ?
            <div style={{display: "flex", alignItems: 'center'}}>
              <span className="link skeleton" style={{ display: isMobile ? 'none' : 'inline-block', width: 40, height: 40, marginRight: 8}}/>
              <span className="link skeleton" style={{ display: isMobile ? 'none' : 'inline-block', width: 178, height: 40}}/>
            </div>
            :
            <span className="link skeleton" style={{ display: isMobile ? 'none' : 'inline-block', width: 178, height: 40}}/>
        }
      </div>

      <Divider style={{margin: '16px auto'}} type={'horizontal'} />

      <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: isMobile ? 'flex-start' : 'center', width: '100%'}}>
        <div style={{width: isMobile ? '100%' : 'calc(100% - 320px)', display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row'}}>
          {statBlocks.map(el => (
            <div key={el.title} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: isMobile ? 12 : 0}}>
              <span className="link skeleton" style={{width: el.title, height: 20}}/>
              <span className="link skeleton" style={{width: el.value , height: 24, marginTop: 4}}/>
            </div>
          ))}
        </div>
        <div style={{width: 210}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <span className="link skeleton" style={{width: 107, height: 20, marginBottom: 4}}/>
            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', gap: 8}}>
              {[1,2,3,4,5,6].map(el => (
                <span key={el} className="link skeleton" style={{width: 24, height: 24}}/>
              ))}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainBlockNewSkeleton;
