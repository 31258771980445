import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import {expandedRowRender} from '../../../Utils/utils';
import {Modal, Spin, Table } from 'antd';
import NumbersBreakdownDatabaseBlock from './NumbersBreakdownDatabaseBlock';
import DatabaseProductsPagination from './DatabaseProductsPagination';
import DatabaseProductsSkeleton from './DatabaseProductsSkeleton';
import { getColumns } from './DatabaseProductsColumns';
import Icon from '../../../Icon';
import Creators from '../../ShopifyStore/reducer';
import AutoDSCreators from '../../AutoDS/reducer';
import Creator from '../../ProductDatabasePage/reducer';
import CanNotRemoveTracking from "./CanNotRemoveTracking";
import PriceHistory from './PriceHistory';
import './DatabaseProducts.less';

const CustomTable = (
  {
    tableRef,
    onSort,
    data,
    chartLoading,
    columns,
    expandedRow,
    handleExpandRow,
    isMobile,
    getChart,
    showRawData,
    setShowRawData
  }) => {
  const { t } = useTranslation();

  const chartData = useSelector(store => store.productDatabase.chartData) || [];
  const userInfo = useSelector(store => store.auth.userInfo);

  function handleExpandToggle(record, state) {
    setShowRawData(state);
    getChart({
      store_id: record?.store?.id,
      product_id: record?.id,
      raw_data: userInfo?.raw_data_access && state
    });
  }

  return (
    <Table
      components={{
        // eslint-disable-next-line react/prop-types
        table: ({ className, children, ...other }) => (
          <table
            {...other}
            ref={tableRef}
            className={cls(className, 'other-wrapper')}
          >
            {children}
          </table>
        ),
      }}
      className="list-table"
      rowClassName={'product-database-table-row'}
      header={!!data.length}
      rowKey={(record) => record.id}
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{
        x: 2100,
        y: 'calc(100vh - 58px - 48px)',
      }}
      sticky={{
        offsetHeader: 0,
        offsetScroll: 0,
        getContainer: () => document.querySelector('.product-database-table-body')
      }}
      sortDirections={['descend', 'ascend', null]}
      onChange={(pagination, filters, sorter) => {
       onSort(sorter);
      }}
      expandable={{
        expandedRowKeys: [expandedRow],
        expandedRowRender: record => expandedRowRender(record, chartLoading, chartData, t, isMobile, userInfo, handleExpandToggle, showRawData),
        onExpand: handleExpandRow,
        expandIcon: ({ expanded, onExpand, record }) => (
          <Icon type="min_max_input_divider"
                width={7}
                height={10}
                role="button"
                btnType={expanded ?
                  record?.monthly_sales ?
                    'icon-btn-expand-row active'
                    :
                    'icon-btn-expand-row active empty'
                :
                  record?.monthly_sales ?
                    'icon-btn-expand-row'
                    :
                    'icon-btn-expand-row empty'
                }
                onClick={e => onExpand(record, e)}
          />
        ),
      }}
    />
  );
};

const MemoTable = React.memo(CustomTable);

const DatabaseProducts = (props) => {
  const {
    fetching,
    loading,
    onSort,
    sortOrder,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    data,
    isChanged,
    setCompetitor,
    enabled,
    getChart,
    chartLoading,
    currentScroll,
    daysFromLaunch,
    isTrial,
    isMobile,
  } = props;
  //modal show/hide state
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);
  const [visibleDropdown, setVisibleDropdown] = useState({});
  //record for Numbers breakdown
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);
  //tableRef to handle scroll
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const shopifyStores = useSelector(store => store['shopifyStore'].results) || [];
  const autoDS = useSelector(store => store.autoDS);
  const userInfo = useSelector(store => store.auth.userInfo);
  const totalProducts = useSelector(store => store['productDatabase'].products.total);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const [expandedRow, setExpandedRow] = useState(null);
  const [showRawData, setShowRawData] = useState(false);

  //scroll  table to top on page change
  useEffect(() => {
    const tableContent = tableRef?.current?.parentNode;
    if (tableContent && !currentScroll.current) {
      tableContent.scrollTop = 0;
    } else {
      tableContent.scrollTop = currentScroll.current;
    }
    setExpandedRow(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, data]);

  useEffect(() => {
    currentScroll.current = null;
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder]);

  const scrollListener = useCallback((e) => {
    currentScroll.current = e.target.scrollTop;
    setVisibleDropdown({});
  }, [])

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', scrollListener);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId }));
    } else {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null }));
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  const handleExpandRow = useCallback((expanded, record) => {
    if (!expanded || (expanded && expandedRow === record.id)) return setExpandedRow([]);
    if (expanded) {
      getChart({
        store_id: record?.store?.id,
        product_id: record?.id,
        raw_data: userInfo?.raw_data_access && showRawData
      });
      setExpandedRow(record.id);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [expandedRow, showRawData]);

  const connectStore = useCallback(data => dispatch(Creator.createTrackingStoreDatabaseRequest(data)), [dispatch]);
  const connectProduct = useCallback(data => dispatch(Creator.createTrackingProductDatabaseRequest(data)), [dispatch]);
  const disconnectStore = useCallback(id => dispatch(Creator.deleteTrackingStoreDatabaseByIDRequest(id)), [dispatch]);
  const disconnectProduct = useCallback(id => dispatch(Creator.deleteTrackingProductDatabaseByIDRequest(id)), [dispatch]);

  const toggleConnectStore = useCallback((record) => {
    if (record?.store?.is_tracked) {
      if (isTrial) {
        setVisible(true);
        handleSetModal('canNotRemoveTracking');
      } else disconnectStore(record);
    } else {
      connectStore({
        "custom_domain": record?.store?.custom_domain,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const toggleConnectProduct = useCallback((record) => {
    if (record?.is_tracked) {
      if (isTrial) {
        setVisible(true);
        handleSetModal('canNotRemoveTracking');
      } else disconnectProduct(record);
    } else {
      connectProduct({
        "custom_domain": record?.store?.custom_domain,
        "handle": record?.handle,
        "product_id": record?.id,
        "title": record?.title,
        "images": record?.images,
        "main_image": record?.main_image,
        "variants": record?.variants,
        "created_at": record?.created_at,
        "original_price": record?.original_price,
        "original_price_min": record?.original_price_min,
        "original_price_max": record?.original_price_max,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleSetRecordToCalc = useCallback((value) => setRecordToCalc(value), []);
  const handleSetRecordToPriceHistory = useCallback((value) => setRecordToPriceHistory(value), []);
  const handleSetVisible = useCallback((value) => setVisible(value), []);
  const handleSetModal = useCallback((value) => setModal(value), []);

  const modalBlocks = {
    numbersBreakdown: <Spin size="large" spinning={fetching}>
                        <NumbersBreakdownDatabaseBlock product={recordToCalc}/>
                      </Spin>,
    canNotRemoveTracking: <CanNotRemoveTracking />,
    priceHistory: <PriceHistory product={recordToPriceHistory} />
  }

  const modalBlocksWidth = {
    numbersBreakdown: 1040,
    priceHistory: 1040,
    canNotRemoveTracking: 512
  }

  const columns = useMemo(() => {
    return getColumns({
      sortOrder,
      handleSetRecordToCalc,
      handleSetRecordToPriceHistory,
      setVisible: handleSetVisible,
      setModal: handleSetModal,
      handleOpenModalShopifyConnect,
      handleOpenModalAutoDSConnect,
      setCompetitor,
      handleExpandRow,
      toggleConnectStore,
      toggleConnectProduct,
      daysFromLaunch,
      t,
      isMobile,
      visibleDropdown,
      setVisibleDropdown,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [sortOrder, daysFromLaunch, handleExpandRow, JSON.stringify(visibleDropdown), autoDS?.email, autoDS?.stores?.length]);

  return (
    <>
      {fetching ? <DatabaseProductsSkeleton isMobile={isMobile} /> : null}

      <div className={cls('product-database-table-wrapper', {
        'product-database-table-wrapper-empty': !data?.length && !fetching,
        'product-database-table-wrapper-hidden': fetching,
      })}>

        <DatabaseProductsPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalProducts={totalProducts}
          disabled={!isChanged || !enabled}
          isTrial={isTrial}
        />

        <div id={'product-database-table-body'} className={cls('product-database-table-body', {
          'table-column-title-disabled': !isChanged,
        })}>
          <Spin size="large" spinning={loading}>
            <MemoTable
              tableRef={tableRef}
              columns={columns}
              onSort={onSort}
              data={data}
              chartLoading={chartLoading}
              expandedRow={expandedRow}
              handleExpandRow={handleExpandRow}
              isMobile={isMobile}
              getChart={getChart}
              showRawData={showRawData}
              setShowRawData={setShowRawData}
            />
          </Spin>
        </div>
      </div>

      <Modal
        className="change-modal custom-modal"
        {...(isMobile ? { transitionName: '' } : null)}
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => handleSetVisible(false)}
        destroyOnClose
      >
        {modalBlocks[modal]}
      </Modal>
    </>
  );
};

export default React.memo(DatabaseProducts);
