import React, {useEffect, useMemo, useCallback, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import cls from 'classname';
import { Modal, Spin, Table } from 'antd';
import DatabaseProductsSkeleton from './DatabaseProductsSkeleton';
import { getColumns } from './DatabaseProductsColumns';
import Icon from '../../../Icon';
import Creators from '../../ShopifyStore/reducer';
import AutoDSCreators from "../../AutoDS/reducer";
import Creator from '../../ShopifySearch/reducer';
import NumbersBreakdownDatabaseBlock from "../../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock";
import DatabaseProductsPagination from "../../ProductDatabasePage/components/DatabaseProductsPagination";
import CanNotRemoveTracking from "../../ProductDatabasePage/components/CanNotRemoveTracking";

const CustomTable = (
  {
    tableRef,
    onSort,
    data,
    columns,
  }) => {
  return (
    <Table
      components={{
        // eslint-disable-next-line react/prop-types
        table: ({ className, children, ...other }) => (
          <table
            {...other}
            ref={tableRef}
            className={cls(className, 'other-wrapper')}
          >
            {children}
          </table>
        ),
      }}
      className="list-table"
      rowClassName={'product-database-table-row'}
      rowKey={(record) => record.title + record.price + record['created_at']}
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{
        x: 1600, //'max-content',
        y: 'calc(100vh - 58px - 48px)',
      }}
      sticky={{
        offsetHeader: 0,
        offsetScroll: 0,
        getContainer: () => document.querySelector('.product-database-table-body')
      }}
      sortDirections={['descend', 'ascend', null]}
      onChange={(pagination, filters, sorter) => {
        onSort(sorter);
      }}
    />
  )
}

const MemoTable = React.memo(CustomTable);

const DatabaseProducts = (props) => {
  const {
    fetching,
    loading,
    onSort,
    sortOrder,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    data,
    isChanged,
    enabled,
    isTrial,
    isMobile,
    isAdmin,
    currentScroll
  } = props;
  const {t} = useTranslation();
  //show/hide modal
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);
  const [visibleDropdown, setVisibleDropdown] = useState({});
  //record to show numbers breakdown
  const [recordToCalc, setRecordToCalc] = useState(null);
  //table ref for scroll handler
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const shopifyStores = useSelector(store => store['shopifyStore'].results) || [];
  const autoDS = useSelector(store => store.autoDS);
  const totalProducts = useSelector(store => store.competitors.products.total);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId }));
    } else {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null }));
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  useEffect(() => {
    const tableContent = tableRef?.current?.parentNode;
    if (tableContent && !currentScroll.current) {
      tableContent.scrollTop = 0;
    } else {
      tableContent.scrollTop = currentScroll.current;
    }
  }, [pageNumber, data]);

  useEffect(() => {
    currentScroll.current = null;
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder]);

  const scrollListener = useCallback((e) => {
    currentScroll.current = e.target.scrollTop;
    setVisibleDropdown({});
  }, [])

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', scrollListener);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleSetRecordToCalc = useCallback((value) => setRecordToCalc(value), []);
  const handleSetVisible = useCallback((value) => setVisible(value), []);
  const handleSetModal = useCallback((value) => setModal(value), []);
  const connectStore = useCallback(data => dispatch(Creator.createTrackingStoreCompetitorsRequest(data)), [dispatch]);
  const connectProduct = useCallback(data => dispatch(Creator.createTrackingProductCompetitorsRequest(data)), [dispatch]);
  const disconnectStore = useCallback(id => dispatch(Creator.deleteTrackingStoreCompetitorsByIDRequest(id)), [dispatch]);
  const disconnectProduct = useCallback(id => dispatch(Creator.deleteTrackingProductCompetitorsByIDRequest(id)), [dispatch]);

  const toggleConnectProduct = useCallback((record) => {
    if (record?.is_tracked) {
      if (isTrial) {
        setVisible(true);
        handleSetModal('canNotRemoveTracking');
      } else disconnectProduct(record);
    } else {
      connectProduct({
        "custom_domain": record?.store?.custom_domain,
        "handle": record?.handle,
        "product_id": record?.id,
        "title": record?.title,
        "images": record?.images,
        "main_image": record?.main_image,
        "variants": record?.variants,
        "created_at": record?.created_at,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain,
        "original_price": record?.original_price,
        "original_price_min": record?.original_price_min,
        "original_price_max": record?.original_price_max,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const toggleConnectStore = useCallback((record) => {
    if (record?.store?.is_tracked) {
      if (isTrial) {
        setVisible(true);
        handleSetModal('canNotRemoveTracking');
      } else disconnectStore(record);
    } else {
      connectStore({
        "custom_domain": record?.store?.custom_domain,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const modalBlocks = {
    numbersBreakdown: <Spin size="large" spinning={fetching}>
      <NumbersBreakdownDatabaseBlock product={recordToCalc}/>
    </Spin>,
    canNotRemoveTracking: <CanNotRemoveTracking />
  }

  const modalBlocksWidth = {
    numbersBreakdown: 1040,
    canNotRemoveTracking: 512
  }

  const columns = useMemo(() => {
    return getColumns({
      sortOrder,
      setRecordToCalc: handleSetRecordToCalc,
      setVisible: handleSetVisible,
      setModal: handleSetModal,
      handleOpenModalShopifyConnect,
      handleOpenModalAutoDSConnect,
      disabled: !isChanged,
      toggleConnectStore,
      toggleConnectProduct,
      t,
      isAdmin,
      isMobile,
      visibleDropdown,
      setVisibleDropdown
    })
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [sortOrder, JSON.stringify(visibleDropdown), autoDS?.email, autoDS?.stores?.length])

  return (
    <>
      {fetching ? <DatabaseProductsSkeleton isMobile={isMobile} /> : null}

      <div className={cls('product-database-table-wrapper', {
        'product-database-table-wrapper-empty': !data?.length && !fetching,
        'product-database-table-wrapper-hidden': fetching,
      })}>

        <DatabaseProductsPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalProducts={totalProducts}
          disabled={!isChanged || !enabled}
          isTrial={isTrial}
        />

        <div id={'product-database-table-body'} className={cls("product-database-table-body competitors-table-body", {
          'table-column-title-disabled': !isChanged
        })}>
          <Spin size="large" spinning={loading}>
            <MemoTable
              tableRef={tableRef}
              onSort={onSort}
              data={data}
              columns={columns}
            />
          </Spin>
        </div>
      </div>

      <Modal
        className="change-modal custom-modal"
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => handleSetVisible(false)}
        destroyOnClose
      >
        {modalBlocks[modal]}
      </Modal>
    </>
  );
};

export default React.memo(DatabaseProducts);
