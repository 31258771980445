import React from 'react';
import cls from 'classname';
import { Divider } from 'antd';
import SwipeableButton from '../../Components/ButtonSwipeable';
import ButtonComponent from "../../Components/Button";

export const footerModal = ({
  isMobile,
  handleOk,
  handleCancel,
  textBtnOk,
  isDisabledBtnOk,
  textBtnCancel,
  deleteType,
  swipeBtn,
  textSwipeBtn,
  bigBtn,
  textBigBtn,
  textBtnCancelNew,
  upgradeConfirm,
  handleClose,
  ...props
}) => {
  if (bigBtn) {
    return (
      <div className="modal-footer-sub-btn-wrapper">
        {
          !upgradeConfirm && <ButtonComponent text={textBtnCancelNew}
                                              onClick={handleClose}
                                              className='modal-footer-btn btn-primary border-only'
          />
        }
        <ButtonComponent
          text={textBigBtn}
          onClick={handleOk}
          className={cls('modal-footer-btn btn-primary', {
            'red': deleteType,
            'wide': upgradeConfirm
          })}
        />
      </div>
    )
  }
  if (swipeBtn) {
    return [
      <SwipeableButton
        key="submit"
        text={textSwipeBtn}
        text_unlocked="Confirmed"
        onSuccess={handleOk}
        deleteType={deleteType}
        onFailure={() => {}}
        isMobile={isMobile}
      />,
    ];
  }
  if (isMobile) {
    return (
      <div className="double-btn-wrp">
        <ButtonComponent
          type="primary"
          className={cls('btn-primary border-only', {
            red: deleteType,
            blue: !deleteType,
          })}
          onClick={handleCancel}
          disabled={isDisabledBtnOk}
        >
          {textBtnCancel}
        </ButtonComponent>

        <ButtonComponent
          type="primary"
          className={cls('btn-primary', { red: deleteType })}
          onClick={handleOk}
          disabled={isDisabledBtnOk}
        >
          {textBtnOk}
        </ButtonComponent>
      </div>
    );
  }
  return (
    <div className="double-btn-wrapper">
      {props.divider ? <Divider /> : null}
      <div className="double-btn-block">
        <ButtonComponent
          key="back"
          type="primary"
          className="btn-primary border-only"
          style={{ minWidth: 81, letterSpacing: 0.07 }}
          onClick={handleCancel}
        >
          {textBtnCancel}
        </ButtonComponent>
        <ButtonComponent
          key="submit"
          type="primary"
          className={cls('btn-primary', { red: deleteType })}
          style={{
            minWidth: 91,
            letterSpacing: 0.07,
            ...(props.width && { width: props.width }),
          }}
          onClick={handleOk}
          disabled={isDisabledBtnOk}
        >
          {textBtnOk}
        </ButtonComponent>
      </div>
    </div>
  );
};
