import React from 'react';

import { Typography } from 'antd';
import SaveToCollection from '../../../Components/SaveToCollections/save-to-collections';

import './NameProductBlock.less';

const { Text } = Typography;

export const NameProductBlock = ({ skeleton, isMobile, title, textLink, link, addToCollectionFunc, product }) => {

  if (skeleton) {
    return (
      <div className={'product-details-page-name-block'}>
        <span className='icon-wrap skeleton'/>
        <div className='product-details-page-name-block-title skeleton'/>
        <span className='link skeleton'/>
      </div>);
  } else {
    return (
      <div className={'product-details-page-name-block'}>
        <SaveToCollection
          saveFunc={(isSaved) =>
            addToCollectionFunc(
              product?.product_id,
              isSaved,
              product?.title,
            )
          }
          isSave={product.saved}
        />
        <Text
          className={'product-details-page-name-block-title'}
          style={{width: 100}}
          ellipsis={{ tooltip: title }}
        >
          {title}
        </Text>

        {isMobile ? null : <div className="divider"/>}

        <a className={'link'}
           href={link || '#'}
           target="_blank"
           rel="noopener noreferrer"
        >
          {textLink}
        </a>

      </div>
    );
  }
};

export default NameProductBlock;
