/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Select } from 'antd';
import _ from 'lodash';
import cls from 'classname';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
const { Option } = Select;

export default function ChangeBillingAddressForm({
  billingInfo,
  firstName,
  lastName,
  onCancel,
  onSubmit,
  getCountries,
  countries,
  isMobile,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectCountry, setSelectCountry] = useState('United States');
  const [data, setData] = useState({
    country: { id: 1450 },
  });
  const [disable, setDisable] = useState(true);
  const [showArrow, setShowArrow] = useState(true);
  const [showArr, setShowArr] = useState(true);

  const isPreset = !!billingInfo;

  useEffect(() => {
    if (!countries?.length) getCountries();
    form.getFieldsValue();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (isPreset) {
      setSelectCountry(billingInfo.country.name);
      setData({
        ...billingInfo,
        first_name: billingInfo?.first_name || firstName,
        last_name: billingInfo?.last_name || lastName,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isPreset]);

  const countriesArr = _.cloneDeep(countries);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  const optionsCountry = countriesArr
    ? countriesArr
    .sort(byField('name'))
    .map((el) => ({ label: el.name, value: el.name, id: el.id }))
    : [];
  const optionsState = countriesArr
    ? countriesArr
    .filter((country) => country.name === 'United States')[0]
    .state?.sort((a, b)=> a.localeCompare(b))
    .map((el) => ({
        label: el,
        value: el,
      }))
    : [];

  const onFinish = (value) => {
    const result = {};
    for (const key in value) {
      value[key] ? (result[key] = value[key]) : (result[key] = null);
    }
    result?.country?.id === 'United States'
      ? onSubmit({
          ...result,
          country: { id: 1450 },
        })
      : onSubmit(result);
  };

  function handleChange(changedValues, allValues) {
    const { address, city, country, postal_code, first_name, last_name } =
      allValues;

    if (address && city && country && postal_code && first_name && last_name) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    setData({
      ...data,
      ...allValues,
    });
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleChange}
      validateTrigger="onSubmit"
      className="account-form payment-form on-boarding"
      autoComplete="off"
      requiredMark={false}
    >
      <Row
        className=""
        gutter={[
          { xs: 12, sm: 12, md: 16 },
          { xs: 12, sm: 12, md: 16 },
        ]}
      >
        <Col xs={12} sm={12}>
          <Form.Item
            label={t('First Name')}
            name="first_name"
            className={data?.first_name ? 'active' : ''}
            initialValue={isPreset ? billingInfo?.first_name || firstName : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'first_name')} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12}>
          <Form.Item
            label={t('Last Name')}
            name="last_name"
            className={data?.last_name ? 'active' : ''}
            initialValue={isPreset ? billingInfo?.last_name || lastName : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'last_name')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            label={t('Street and House Number')}
            className={data?.address ? 'active' : ''}
            name="address"
            initialValue={isPreset ? billingInfo?.address : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'address')} />
          </Form.Item>
        </Col>

        <Col xs={12} sm={12}>
          <Form.Item
            label={t('setting_billing.City/Town')}
            className={data?.city ? 'active' : ''}
            name="city"
            initialValue={isPreset ? billingInfo.city : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'city')} />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12}>
          <Form.Item
            label={t('setting_billing.Postal Code')}
            className={data?.postal_code ? 'active' : ''}
            name="postal_code"
            initialValue={isPreset ? billingInfo.postal_code : ''}
            validateTrigger="onBlur"
          >
            <InputComponent onFocus={(e) => handleClearError(e, 'postal_code')} />
          </Form.Item>
        </Col>

        {selectCountry === 'United States' || selectCountry === 1450 ? (
          <Col xs={24} sm={24}>
            <Form.Item
              label={t('State')}
              name="state"
              initialValue={isPreset ? billingInfo.state : null}
              className={data?.state ? 'active' : ''}
            >
              <Select
                className="custom-select"
                popupClassName="custom-dropdown"
                showSearch
                showArrow="true"
                open={!showArrow}
                listHeight={176}
                notFoundContent={
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="empty-state_icon-select">
                      <Icon width={24} height={24}
                            role="icon"
                            type="empty_warning"
                            fill={'#707ba0'}
                      />
                    </div>
                    <div style={{color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px'}} className="empty-state_wrapper-select">
                      No results found
                    </div>
                  </div>
                }
                suffixIcon={
                  <div
                    className={cls('arrow', {
                      arrow_selected: !showArrow,
                    })}
                  >
                    <ArrowSelectDown />
                  </div>
                }
                onFocus={(e) => {
                  handleClearError(e, 'state');
                }}
                onDropdownVisibleChange={() => {
                  setShowArrow(!showArrow);
                }}
                onSelect={() => setShowArrow(!showArrow)}
                optionFilterProp="options"
                getPopupContainer={(trigger) => trigger.parentNode}
                onScroll={(e) => e.stopPropagation()}
                filterOption={(input, { value }) =>
                  utils.includeSearch(value, input)
                }
              >
                {optionsState.map((el) => (
                  <Option value={el.value} key={el.value}>
                    <span onClick={(e) => e.preventDefault()}>{el.label}</span>
                    {form.getFieldValue('state') === el.label ? (
                      <Icon
                        role="icon"
                        type="select_dropdown_check_mark"
                        className="check-icon"
                      />
                    ) : null}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}

        <Col xs={24} sm={24}>
          <Form.Item
            label={t('Country')}
            name={['country', 'id']}
            initialValue={isPreset ? billingInfo?.country?.id : 'United States'}
            className={data?.country?.id ? 'active' : ''}
          >
            <Select
              className="custom-select"
              popupClassName="custom-dropdown"
              showSearch
              showArrow="true"
              open={!showArr}
              listHeight={176}
              notFoundContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className="empty-state_icon-select">
                    <Icon width={24} height={24}
                          role="icon"
                          type="empty_warning"
                          fill={'#707ba0'}
                    />
                  </div>
                  <div style={{color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px'}} className="empty-state_wrapper-select">
                    No results found
                  </div>
                </div>
              }
              suffixIcon={
                <div
                  className={cls('arrow', {
                    arrow_selected: !showArr,
                  })}
                  onClick={() => setShowArr(!showArr)}
                >
                  <ArrowSelectDown color={!showArr && '#225AEA'} />
                </div>
              }
              value={selectCountry}
              onChange={(value) => {
                setSelectCountry(value);
              }}
              onFocus={(e) => {
                handleClearError(e, ['country', 'id']);
              }}
              optionFilterProp="options"
              getPopupContainer={(trigger) => trigger.parentNode}
              onScroll={(e) => e.stopPropagation()}
              filterOption={(input, { label }) =>
                utils.includeSearch(label, input)
              }
              onDropdownVisibleChange={() => {
                setShowArr(!showArr);
              }}
              onSelect={() => setShowArr(!showArr)}
            >
              {optionsCountry.map((el) => (
                <Option value={el.id} key={el.value} label={el.value}>
                  <span onClick={(e) => e.preventDefault()}>{el.label}</span>
                  {selectCountry === el.label ? (
                    <Icon
                      role="icon"
                      type="select_dropdown_check_mark"
                      className="check-icon"
                    />
                  ) : null}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <div className={isMobile ? 'double-btn-wrp' : 'change-btn-block'}>
            <ButtonComponent
              type="primary"
              htmlType="button"
              className="btn-primary border-only"
              onClick={onCancel}
            >
              {t('Cancel')}
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="btn-primary"
              disabled={disable}
            >
              {t('Submit')}
            </ButtonComponent>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
