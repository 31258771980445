import React from 'react';
import cls from 'classname';
import { Spin } from 'antd';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import InputComponent from "../../../Components/Input";
import './style-modal.less';

export default function ConfigureModal({
  setIconsBtn,
  iconsBtn,
  setModal,
  setFormData,
  formData,
  getBdIcons,
  onSelectIcon,
  onDelete,
  fetching,
}) {
  function handleChange(name, e) {
    const value = e.target.value;
    setFormData(formData => ({ ...formData, [name]: value }));
  }

  function changeIcon() {
    setModal('choose_icon');
    onSelectIcon(null);
    getBdIcons({ page_size: 18, ...(iconsBtn && { _type: iconsBtn }) });
  }
  function changeButton(name) {
    setIconsBtn(name);
    setFormData(formData => ({
      ...formData,
      _type: name === 'benefits' ? '1' : '2',
      icon: null,
    }));
  }

  return (
    <div className="configure-modal">
      {formData?.icon ? (
        <div className="configure-delete" onClick={onDelete}>
          <Icon type="delete_basket" role="icon" />
          <span className="configure-delete-text">Delete</span>
        </div>
      ) : null}
      <Spin size="large" spinning={fetching}>
        <div className={`configure-buttons-wrapper ${iconsBtn}`}>
          <div className={`configure-buttons-slider  ${iconsBtn}`}/>
          <div
            className={cls('bd-btn', {
              active: iconsBtn === 'benefits',
            })}
            onClick={() => changeButton('benefits')}
          >
            Benefits
          </div>
          <div
            className={cls('bd-btn', {
              active: iconsBtn === 'drawbacks',
            })}
            onClick={() => changeButton('drawbacks')}
          >
            Drawbacks
          </div>
        </div>
        <div className="db-form">
          <div className="change-form-item">
            <div className="choose-icon-wrapper">
              <div
                className={`choose-icon ${iconsBtn}`}
                dangerouslySetInnerHTML={{ __html: formData?.icon?.content }}
              />
              <div className="choose-icon-block">
                {formData?.icon?.title ? (
                  <div className="choose-icon-title">
                    {utils.toUpperLatter(formData?.icon?.title || '')}
                  </div>
                ) : null}

                <div className="choose-icon-text" onClick={changeIcon}>
                  Choose Icon
                </div>
              </div>
            </div>
          </div>
          <div className="change-form-item">
            <InputComponent
              placeholder="Title (30 Letters)"
              name="title"
              value={formData?.title ? formData?.title : ''}
              onChange={(e) => handleChange('title', e)}
              maxLength={30}
            />
          </div>
          <div className="change-form-item">
            <InputComponent
              placeholder="Description (45 Letters)"
              name="description"
              value={formData?.description ? formData?.description : ''}
              onChange={(e) => handleChange('description', e)}
              maxLength={45}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
