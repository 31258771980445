import React from 'react';

const AdSpotWow = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22.2858 11.9996C22.2858 14.7275 21.2021 17.3438 19.2732 19.2727C17.3443 21.2016 14.728 22.2853 12.0001 22.2853C9.27213 22.2853 6.65592 21.2016 4.72698 19.2727C2.79803 17.3438 1.71436 14.7275 1.71436 11.9996C1.71436 9.27164 2.79803 6.65543 4.72698 4.72649C6.65592 2.79754 9.27213 1.71387 12.0001 1.71387C14.728 1.71387 17.3443 2.79754 19.2732 4.72649C21.2021 6.65543 22.2858 9.27164 22.2858 11.9996Z"
        fill={`url(#paint0_linear_36566_241393_${id})`}/>
      <path
        d="M8.96956 15.7131C8.76642 18.0853 9.90299 19.7143 12 19.7143C14.0957 19.7143 15.2336 18.0853 15.0304 15.7131C14.8286 13.3397 13.5968 12 12 12C10.4031 12 9.17142 13.3397 8.96956 15.7131Z"
        fill={`url(#paint1_linear_36566_241393_${id})`}/>
      <path
        d="M6.21436 8.78599C6.21436 7.72142 6.93307 6.85742 7.8215 6.85742C8.70993 6.85742 9.42864 7.72142 9.42864 8.78599C9.42864 9.85185 8.70993 10.7146 7.8215 10.7146C6.93307 10.7146 6.21436 9.85185 6.21436 8.78599ZM14.5715 8.78599C14.5715 7.72142 15.2915 6.85742 16.1786 6.85742C17.0671 6.85742 17.7858 7.72142 17.7858 8.78599C17.7858 9.85185 17.0671 10.7146 16.1786 10.7146C15.2915 10.7146 14.5715 9.85185 14.5715 8.78599Z"
        fill={`url(#paint2_linear_36566_241393_${id})`}/>
      <g filter={`url(#filter0_i_36566_241393_${id})`}>
        <path
          d="M6.21436 8.78599C6.21436 7.72142 6.93307 6.85742 7.8215 6.85742C8.70993 6.85742 9.42864 7.72142 9.42864 8.78599C9.42864 9.85185 8.70993 10.7146 7.8215 10.7146C6.93307 10.7146 6.21436 9.85185 6.21436 8.78599ZM14.5715 8.78599C14.5715 7.72142 15.2915 6.85742 16.1786 6.85742C17.0671 6.85742 17.7858 7.72142 17.7858 8.78599C17.7858 9.85185 17.0671 10.7146 16.1786 10.7146C15.2915 10.7146 14.5715 9.85185 14.5715 8.78599Z"
          fill="black"/>
      </g>
      <path
        d="M7.477 7.58692C7.71614 7.64092 7.84985 7.91092 7.77528 8.18992C7.702 8.47021 7.44871 8.65278 7.20957 8.59878C6.97042 8.54478 6.83671 8.27478 6.91128 7.99449C6.98457 7.71678 7.23785 7.53163 7.477 7.58692ZM16.0373 7.66792C16.3022 7.72835 16.4513 8.02792 16.369 8.33649C16.2868 8.64892 16.0065 8.85078 15.7403 8.79163C15.4755 8.73249 15.3263 8.43163 15.4086 8.12178C15.4896 7.81063 15.7712 7.60749 16.0386 7.66792H16.0373Z"
        fill="#4E506A"/>
      <g filter={`url(#filter1_d_36566_241393_${id})`}>
        <path
          d="M15.9446 3.89519C16.0115 3.88876 16.0783 3.88619 16.1465 3.88619C16.7726 3.88619 17.4193 4.14848 17.9105 4.60876C17.9991 4.69318 18.0515 4.80865 18.0568 4.93091C18.0621 5.05318 18.0198 5.17274 17.9388 5.26448C17.8997 5.30947 17.8517 5.34608 17.7981 5.37211C17.7445 5.39813 17.6861 5.41303 17.6265 5.41591C17.5669 5.41879 17.5074 5.4096 17.4515 5.38887C17.3956 5.36816 17.3444 5.33634 17.3011 5.29534C16.9475 4.96234 16.4576 4.78105 16.0256 4.82091C15.8758 4.83212 15.7302 4.87604 15.5992 4.94956C15.4681 5.02309 15.3547 5.12444 15.2671 5.24648C15.2318 5.29431 15.1873 5.3346 15.1363 5.36498C15.0853 5.39536 15.0287 5.41522 14.9699 5.42337C14.911 5.43151 14.8511 5.42779 14.7938 5.41243C14.7364 5.39706 14.6827 5.37037 14.6358 5.33391C14.5397 5.25761 14.477 5.14699 14.461 5.02539C14.4449 4.90377 14.4767 4.78068 14.5496 4.68205C14.7116 4.458 14.9202 4.27166 15.161 4.13584C15.4018 4.00002 15.6691 3.91792 15.9446 3.89519ZM6.08964 4.60876C6.56634 4.15541 7.19588 3.89753 7.85364 3.88619C8.16438 3.87946 8.47212 3.94837 8.75029 4.08702C9.02848 4.22566 9.26878 4.42987 9.45049 4.68205C9.52327 4.78063 9.55507 4.90354 9.53926 5.02505C9.52343 5.14657 9.46122 5.25724 9.36564 5.33391C9.31863 5.3704 9.26481 5.39713 9.20733 5.4125C9.14986 5.42788 9.08988 5.43161 9.03093 5.42346C8.97199 5.41532 8.91526 5.39547 8.86412 5.36506C8.81296 5.33467 8.7684 5.29435 8.73307 5.24648C8.64533 5.12449 8.53195 5.02319 8.40091 4.94967C8.26987 4.87615 8.12432 4.83221 7.97449 4.82091C7.54378 4.78105 7.05264 4.96234 6.69907 5.29534C6.65575 5.33634 6.60459 5.36816 6.54866 5.38887C6.49273 5.4096 6.43319 5.41879 6.37363 5.41591C6.31406 5.41303 6.25567 5.39813 6.20201 5.37211C6.14834 5.34608 6.1005 5.30947 6.06135 5.26448C5.98035 5.17274 5.93804 5.05318 5.94331 4.93091C5.94859 4.80865 6.00104 4.69318 6.08964 4.60876Z"
          fill="black"/>
      </g>
      <path
        d="M15.9446 3.89519C16.0115 3.88876 16.0783 3.88619 16.1465 3.88619C16.7726 3.88619 17.4193 4.14848 17.9105 4.60876C17.9991 4.69318 18.0515 4.80865 18.0568 4.93091C18.0621 5.05318 18.0198 5.17274 17.9388 5.26448C17.8997 5.30947 17.8517 5.34608 17.7981 5.37211C17.7445 5.39813 17.6861 5.41303 17.6265 5.41591C17.5669 5.41879 17.5074 5.4096 17.4515 5.38887C17.3956 5.36816 17.3444 5.33634 17.3011 5.29534C16.9475 4.96234 16.4576 4.78105 16.0256 4.82091C15.8758 4.83212 15.7302 4.87604 15.5992 4.94956C15.4681 5.02309 15.3547 5.12444 15.2671 5.24648C15.2318 5.29431 15.1873 5.3346 15.1363 5.36498C15.0853 5.39536 15.0287 5.41522 14.9699 5.42337C14.911 5.43151 14.8511 5.42779 14.7938 5.41243C14.7364 5.39706 14.6827 5.37037 14.6358 5.33391C14.5397 5.25761 14.477 5.14699 14.461 5.02539C14.4449 4.90377 14.4767 4.78068 14.5496 4.68205C14.7116 4.458 14.9202 4.27166 15.161 4.13584C15.4018 4.00002 15.6691 3.91792 15.9446 3.89519ZM6.08964 4.60876C6.56634 4.15541 7.19588 3.89753 7.85364 3.88619C8.16438 3.87946 8.47212 3.94837 8.75029 4.08702C9.02848 4.22566 9.26878 4.42987 9.45049 4.68205C9.52327 4.78063 9.55507 4.90354 9.53926 5.02505C9.52343 5.14657 9.46122 5.25724 9.36564 5.33391C9.31863 5.3704 9.26481 5.39713 9.20733 5.4125C9.14986 5.42788 9.08988 5.43161 9.03093 5.42346C8.97199 5.41532 8.91526 5.39547 8.86412 5.36506C8.81296 5.33467 8.7684 5.29435 8.73307 5.24648C8.64533 5.12449 8.53195 5.02319 8.40091 4.94967C8.26987 4.87615 8.12432 4.83221 7.97449 4.82091C7.54378 4.78105 7.05264 4.96234 6.69907 5.29534C6.65575 5.33634 6.60459 5.36816 6.54866 5.38887C6.49273 5.4096 6.43319 5.41879 6.37363 5.41591C6.31406 5.41303 6.25567 5.39813 6.20201 5.37211C6.14834 5.34608 6.1005 5.30947 6.06135 5.26448C5.98035 5.17274 5.93804 5.05318 5.94331 4.93091C5.94859 4.80865 6.00104 4.69318 6.08964 4.60876Z"
        fill={`url(#paint3_linear_36566_241393_${id})`}/>
      <defs>
        <filter id={`filter0_i_36566_241393_${id}`} x="6.21436" y="6.85742" width="11.5713" height="3.85742"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="0.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.101961 0 0 0 0 0.2 0 0 0 0.819684 0"/>
          <feBlend mode="normal" in2="shape" result={`effect1_innerShadow_36566_241393_${id}`}/>
        </filter>
        <filter id={`filter1_d_36566_241393_${id}`} x="2.94287" y="1.88574" width="18.1143" height="7.54199"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.803922 0 0 0 0 0.388235 0 0 0 0 0.00392157 0 0 0 0.145679 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result={`effect1_dropShadow_36566_241393_${id}`}/>
          <feBlend mode="normal" in="SourceGraphic" in2={`effect1_dropShadow_36566_241393_${id}`} result="shape"/>
        </filter>
        <linearGradient id={`paint0_linear_36566_241393_${id}`} x1="12.0001" y1="3.82244" x2="12.0001" y2="22.2853"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEEA70"/>
          <stop offset="1" stopColor="#F69B30"/>
        </linearGradient>
        <linearGradient id={`paint1_linear_36566_241393_${id}`} x1="11.9999" y1="12" x2="11.9999" y2="19.7143"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#472315"/>
          <stop offset="1" stopColor="#8B3A0E"/>
        </linearGradient>
        <linearGradient id={`paint2_linear_36566_241393_${id}`} x1="12.0001" y1="6.85742" x2="12.0001" y2="10.7146"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#191A33"/>
          <stop offset="0.872" stopColor="#3B426A"/>
        </linearGradient>
        <linearGradient id={`paint3_linear_36566_241393_${id}`} x1="11.9998" y1="3.88513" x2="11.9998" y2="5.42798"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#E78E0D"/>
          <stop offset="1" stopColor="#CB6000"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdSpotWow;
