import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { Menu } from './components';
import Icon from '../../Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import actions from './actions';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import Creators from '../AutoDS/reducer';
import Creator from './reducer';
import {useCountdown} from "../../hooks/useCountdown";
import './styles.less';

export const UserComponent = ({ user, nav, shopifyStore, autoDS, isAdmin, ...props }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const {
    first_name: firstName,
    last_name: lastName,
    subscriptions,
  } = user;

  const { theme, isMobile } = nav;
  const { logout, changeTheme, setDisabledTransition } = props;
  const planName = subscriptions[0] ? subscriptions[0]?.['payment_status']?.id : false;
  const deadline = (subscriptions[0]?.['trial_ends_at'] + 15) * 1000 || null;
  const [days, hours, minutes, seconds] = useCountdown(deadline);

  let trialDays = subscriptions[0] ? handleTrial() : 'Choose Plan'

  function handleTrial() {
    if (planName === 6 && deadline) {
      if (seconds === -1) return
      else if (days < 1 && hours >= 1) {
        return t('_value_-Hours Trial Left', {value: (minutes >= 30 ? hours + 1 : hours) || 0})
      } else if (hours < 1 && days < 1) {
        if ((minutes === 0 && seconds === 0) || (minutes < 0)) {
          props.getUserInfo();
        }
        return t('_value_-Minutes Trial Left', {value: minutes < 1 ? '< 1' : minutes})
      } else {
        return t('_value_-Day Trial Left', {value: (hours >= 12 ? days + 1 : days) || 0})
      }
    }
  }

  const disabledConnect = false;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const handleVisibleChange = (value) => {
    if (value && !isAdmin) {
      props.getShopifyStore();
      props.getAutoStore();
    }
    setVisible(value);
  };

  const handleOpenModalShopifyConnect = () => {
    props.openModalShopifyConnect(initialUrl);
    setVisible(false);
  };

  const handleOpenModalAutoConnect = () => {
    props.openModalAutoConnect();
    setVisible(false);
  }

  return (
    <div className="user-dropdown-wrap" id='user-dropdown-wrap'>
      <Dropdown
        destroyPopupOnHide={true}
        getPopupContainer={() => document.getElementById('user-dropdown-wrap')}
        onOpenChange={handleVisibleChange}
        dropdownRender={() =>
          <Menu
            theme={theme}
            changeTheme={changeTheme}
            setDisabledTransition={setDisabledTransition}
            logout={logout}
            isAdmin={isAdmin}
            isMobile={isMobile}
            shopifyStore={shopifyStore}
            autoDS={autoDS}
            openModalShopifyConnect={handleOpenModalShopifyConnect}
            openModalAutoDSConnect={handleOpenModalAutoConnect}
            disabledConnect={disabledConnect}
          />
        }
        trigger={['click']}
        open={visible}
      >
        <span className={cls({close: !visible})} style={{ cursor: 'pointer' }}>
          <Icon className="avatar-image" type="logo_simple" role="icon"/>
          <div className="user-details">
            <span className="user-details_name">
              {`${firstName} ${lastName}`}
              <ArrowSelectDown/>
            </span>
            { !user?.need_block_pages ?
              <span className="user-details_plan">
              {
                isAdmin ?
                  'Plan'
                  :
                  planName ?
                    (planName === 6 ?
                      trialDays : t('_planName_ plan', {
                        planName: planName === 7 ?
                          'Paused'
                          :
                          planName === 2 ?
                            `Pending ${subscriptions[0]?.plan?.plan?.name}`
                            :
                            subscriptions[0]?.plan?.plan?.name,
                      }))
                    :
                    t(trialDays)
              }
            </span>
              :
              null
            }
          </div>

        </span>
      </Dropdown>
    </div>
  );
};

UserComponent.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  nav: PropTypes.shape({
    theme: PropTypes.string,
    isMobile: PropTypes.bool,
  }),
  avatar: PropTypes.string,
  changeTheme: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  shopifyStore: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  isAdmin: state.auth.isAdmin,
  nav: state.nav,
  shopifyStore: state.shopifyStore,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
  getUserInfo: () => dispatch(Creator.userRequest()),
  changeTheme: theme => dispatch(actions.changeTheme('theme', theme)),
  setDisabledTransition: disabledTransition => dispatch(actions.setDisabledTransition('disabledTransition', disabledTransition)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  openModalShopifyConnect: initialUrl => dispatch(ShopifyStoreCreators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl })),
  openModalAutoConnect: () => dispatch(Creators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId: null })),
  getAutoStore: () => dispatch(Creators.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
