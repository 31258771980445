import React from 'react';

const TopStoresIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16.0729 13.3333H15.9292C15.9527 13.3336 15.9764 13.3337 16.0002 13.3337C16.0245 13.3337 16.0488 13.3336 16.0729 13.3333Z"
        fill="#D71313"/>
      <path
        d="M24.0502 13.3333C24.0335 13.3336 24.0168 13.3337 24.0002 13.3337C23.9809 13.3337 23.9617 13.3336 23.9426 13.3333H24.0502Z"
        fill="#D71313"/>
      <path
        d="M25.678 15.7553C26.1494 15.6166 26.6668 15.9431 26.6668 16.4344V24C26.6668 26.2091 24.876 28 22.6668 28H20.1862C19.3471 28 18.6668 27.3197 18.6668 26.4806V22.6666C18.6668 21.1939 17.4729 20 16.0002 20C14.5274 20 13.3335 21.1939 13.3335 22.6666V26.4806C13.3335 27.3197 12.6532 28 11.8141 28H9.3335C7.12436 28 5.3335 26.2091 5.3335 24V16.434C5.3335 15.9427 5.85094 15.6162 6.32229 15.7549C6.85369 15.9112 7.41601 16 8.00016 16C9.398 16 10.553 15.5689 11.4585 15.0153C11.6993 14.8681 12.004 14.8691 12.2456 15.0149C13.219 15.6028 14.4614 16.0002 15.9998 16.0004C17.5385 16.0006 18.7812 15.6033 19.7548 15.0153C19.9964 14.8694 20.3011 14.8685 20.5419 15.0157C21.4474 15.5693 22.6024 16.0004 24.0002 16.0004C24.5843 16.0004 25.1466 15.9117 25.678 15.7553Z"
        fill="#D71313"/>
      <path
        d="M9.33333 4.00042C10.6667 3.99884 21.3333 4.00228 22.6667 4.00166C25.3333 4.00042 28 6.00042 28 9.33375C28 11.3338 26 13.3338 24 13.3338C21.3333 13.3338 20 10.6671 20 10.6671C20 10.6671 19.3333 13.3342 16 13.3338C12.6667 13.3333 12 10.6671 12 10.6671C12 10.6671 10.6667 13.3333 8 13.3333C6 13.3333 4 11.3338 4 9.33375C4 6.00042 6.66667 4.00357 9.33333 4.00042Z"
        fill="#D71313"/>
    </svg>
  );
};

export default TopStoresIcon;
