import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import Icon from '../../Icon';
import { useTranslation } from 'react-i18next';
import './styles.less';

export const EmptyState = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={cls('empty-state', className)}>
      <div className="empty-state_icon">
        <Icon width={164} height={164} role="icon" type="empty_warning" />
      </div>
      <div className="empty-state_wrapper">
        <div className="empty-state_header">{t('common.no_products')}</div>
        <div className="empty-state_text">{t('common.try_another')}</div>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  className: PropTypes.string,
};

export default EmptyState;
