import React from 'react';

const SearchMicrosoftMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none">
      <g clipPath="url(#clip0_30980_445239)">
        <path
          d="M24.0002 13.0959C24.0015 12.9023 23.9045 12.722 23.7446 12.6207H23.7418L23.7317 12.615L15.4154 7.53465C15.3795 7.50961 15.3423 7.48673 15.3038 7.46611C14.9827 7.29515 14.6013 7.29515 14.2802 7.46611C14.2417 7.48674 14.2045 7.50961 14.1685 7.53465L5.8523 12.615L5.84226 12.6207C5.58809 12.7838 5.51017 13.1287 5.66822 13.391C5.71479 13.4683 5.77894 13.5327 5.85509 13.5786L14.1714 18.6589C14.2074 18.6838 14.2447 18.7066 14.283 18.7275C14.6041 18.8984 14.9855 18.8984 15.3066 18.7275C15.345 18.7066 15.3822 18.6838 15.4183 18.6589L23.7345 13.5786C23.9007 13.4787 24.0021 13.2944 24.0002 13.0959Z"
          fill="#0A2767"/>
        <path
          d="M6.82144 9.70393H12.2789V14.8666H6.82144V9.70393ZM22.8836 4.456V2.09439C22.8967 1.50394 22.4438 1.01415 21.8717 1H7.70945C7.13731 1.01415 6.68444 1.50394 6.69754 2.09439V4.456L15.0696 6.75999L22.8836 4.456Z"
          fill="#0364B8"/>
        <path d="M6.69775 4.45605H12.2791V9.64005H6.69775V4.45605Z" fill="#0078D4"/>
        <path d="M17.8607 4.45605H12.2793V9.64005L17.8607 14.824H22.8839V9.64005L17.8607 4.45605Z" fill="#28A8EA"/>
        <path d="M12.2793 9.63989H17.8607V14.8239H12.2793V9.63989Z" fill="#0078D4"/>
        <path d="M12.2793 14.824H17.8607V20.008H12.2793V14.824Z" fill="#0364B8"/>
        <path d="M6.82178 14.8667H12.2793V19.5599H6.82178V14.8667Z" fill="#14447D"/>
        <path d="M17.8604 14.824H22.8836V20.008H17.8604V14.824Z" fill="#0078D4"/>
        <path
          d="M23.7445 13.547L23.7339 13.5527L15.4176 18.3796C15.3813 18.4026 15.3445 18.4245 15.306 18.4441C15.1647 18.5135 15.0113 18.5527 14.855 18.5593L14.4007 18.2851C14.3623 18.2652 14.325 18.2431 14.2891 18.2189L5.86117 13.2549H5.85726L5.58154 13.0959V22.8672C5.58584 23.5191 6.10127 24.0441 6.73298 24.0399H22.8665C22.876 24.0399 22.8844 24.0353 22.8945 24.0353C23.0279 24.0265 23.1594 23.9982 23.2851 23.9512C23.3395 23.9275 23.3919 23.8994 23.442 23.8671C23.4794 23.8453 23.5436 23.7975 23.5436 23.7975C23.8295 23.5791 23.9987 23.2343 24.0001 22.8672V13.0959C23.9999 13.2828 23.9023 13.4552 23.7445 13.547Z"
          fill="url(#paint0_linear_30980_445239)"/>
        <path opacity="0.5"
              d="M23.5537 13.0573V13.6563L14.8579 19.8351L5.85506 13.2589C5.85506 13.2557 5.85256 13.2531 5.84948 13.2531L5.02344 12.7405V12.3085L5.3639 12.3027L6.0839 12.729L6.10063 12.7347L6.16203 12.7751C6.16203 12.7751 14.6234 17.7575 14.6457 17.769L14.9695 17.9648C14.9974 17.9533 15.0253 17.9418 15.0588 17.9302C15.0755 17.9187 23.4587 13.0515 23.4587 13.0515L23.5537 13.0573Z"
              fill="#0A2767"/>
        <path
          d="M23.7445 13.547L23.7339 13.5533L15.4176 18.3802C15.3813 18.4032 15.3445 18.4251 15.306 18.4447C14.983 18.6075 14.6053 18.6075 14.2824 18.4447C14.2441 18.4251 14.2069 18.4036 14.1707 18.3802L5.85447 13.5533L5.84443 13.547C5.68387 13.4571 5.58321 13.2844 5.58154 13.0959V22.8672C5.58554 23.519 6.10079 24.0441 6.73238 24.0399C6.73239 24.0399 6.7324 24.0399 6.73243 24.0399H22.8492C23.4808 24.0441 23.9961 23.5191 24.0001 22.8673C24.0001 22.8672 24.0001 22.8672 24.0001 22.8672V13.0959C23.9999 13.2828 23.9022 13.4552 23.7445 13.547Z"
          fill="#1490DF"/>
        <path opacity="0.1"
              d="M15.5386 18.3088L15.4141 18.3808C15.378 18.4045 15.3408 18.4263 15.3025 18.4459C15.1653 18.5154 15.0162 18.5561 14.8638 18.5657L18.0279 22.4272L23.5473 23.7998C23.6985 23.6819 23.8188 23.5269 23.8973 23.3488L15.5386 18.3088Z"
              fill="black"/>
        <path opacity="0.05"
              d="M16.1023 17.9817L15.4141 18.3809C15.378 18.4046 15.3408 18.4263 15.3025 18.4459C15.1653 18.5154 15.0162 18.5561 14.8638 18.5657L16.3462 22.7838L23.549 23.7981C23.8327 23.5783 23.9998 23.2335 23.9999 22.8673V22.7412L16.1023 17.9817Z"
              fill="black"/>
        <path
          d="M6.74805 24.0399H22.8476C23.0953 24.0412 23.3369 23.9605 23.5369 23.8095L14.4001 18.2863C14.3617 18.2664 14.3245 18.2443 14.2885 18.22L5.8606 13.2561H5.8567L5.58154 13.0959V22.8338C5.58091 23.4993 6.10318 24.0393 6.74805 24.0399C6.74804 24.0399 6.74804 24.0399 6.74805 24.0399Z"
          fill="#28A8EA"/>
        <path opacity="0.1"
              d="M13.3955 6.66371V18.9498C13.3945 19.3806 13.1407 19.7677 12.7536 19.929C12.6337 19.9822 12.5046 20.0096 12.3741 20.0096H5.58154V6.18391H6.69783V5.60791H12.3741C12.938 5.61011 13.3943 6.08181 13.3955 6.66371Z"
              fill="black"/>
        <path opacity="0.2"
              d="M12.8374 7.23969V19.5258C12.8388 19.6649 12.8102 19.8026 12.7536 19.9289C12.5986 20.3232 12.2283 20.5819 11.816 20.5839H5.58154V6.18389H11.816C11.9779 6.18221 12.1375 6.2239 12.2792 6.30485C12.6214 6.48274 12.8372 6.84433 12.8374 7.23969Z"
              fill="black"/>
        <path opacity="0.2"
              d="M12.8374 7.23969V18.3738C12.8346 18.9554 12.3796 19.4268 11.816 19.4319H5.58154V6.18389H11.816C11.9779 6.18221 12.1375 6.2239 12.2792 6.30485C12.6214 6.48274 12.8372 6.84433 12.8374 7.23969Z"
              fill="black"/>
        <path opacity="0.2"
              d="M12.2792 7.23989V18.374C12.2786 18.9565 11.8223 19.4292 11.2578 19.4321H5.58154V6.18408H11.2578C11.8222 6.18439 12.2795 6.65684 12.2792 7.23932C12.2792 7.23951 12.2792 7.2397 12.2792 7.23989Z"
              fill="black"/>
        <path
          d="M1.02307 6.18408H11.256C11.821 6.18408 12.2791 6.65679 12.2791 7.23989V17.8003C12.2791 18.3834 11.821 18.8561 11.256 18.8561H1.02307C0.458037 18.8561 0 18.3834 0 17.8003V7.23989C0 6.65679 0.45805 6.18408 1.02307 6.18408Z"
          fill="url(#paint1_linear_30980_445239)"/>
        <path
          d="M3.19779 10.6123C3.44994 10.0579 3.85694 9.59388 4.36654 9.27999C4.93089 8.94655 5.57345 8.78033 6.22347 8.7996C6.82594 8.78611 7.42042 8.94372 7.94198 9.25521C8.43237 9.55701 8.82732 9.99922 9.07891 10.5282C9.3529 11.111 9.48945 11.7525 9.47743 12.4002C9.4907 13.0771 9.35021 13.7478 9.06719 14.3586C8.80961 14.9064 8.40213 15.3644 7.8951 15.6759C7.35343 15.9969 6.73687 16.1587 6.11241 16.1436C5.49707 16.1589 4.88948 15.9995 4.35594 15.6828C3.86131 15.3806 3.4614 14.9379 3.20394 14.4075C2.92833 13.8331 2.79015 13.1989 2.80097 12.558C2.78948 11.8868 2.92514 11.2217 3.19779 10.6123ZM4.44356 13.7399C4.578 14.0904 4.80598 14.3944 5.10104 14.6166C5.40159 14.8334 5.76147 14.9452 6.12802 14.9357C6.51838 14.9517 6.90316 14.836 7.22421 14.6062C7.51553 14.3847 7.73753 14.0799 7.86271 13.7296C8.00266 13.3383 8.07172 12.9238 8.06643 12.5067C8.07076 12.0857 8.00584 11.667 7.87443 11.2683C7.75837 10.9084 7.54353 10.5911 7.25602 10.3548C6.94302 10.1142 6.55927 9.99212 6.16932 10.0092C5.79482 9.99919 5.42688 10.1119 5.11835 10.3312C4.81829 10.5543 4.58598 10.861 4.44858 11.2153C4.14378 12.0276 4.14219 12.9277 4.44411 13.7411L4.44356 13.7399Z"
          fill="white"/>
        <path d="M17.8604 4.45605H22.8836V9.64005H17.8604V4.45605Z" fill="#50D9FF"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_30980_445239" x1="14.7908" y1="13.0959" x2="14.7908" y2="24.04"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#35B8F1"/>
          <stop offset="1" stopColor="#28A8EA"/>
        </linearGradient>
        <linearGradient id="paint1_linear_30980_445239" x1="2.13312" y1="5.3591" x2="10.5304" y2="19.4519"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#1784D9"/>
          <stop offset="0.5" stopColor="#107AD5"/>
          <stop offset="1" stopColor="#0A63C9"/>
        </linearGradient>
        <clipPath id="clip0_30980_445239">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchMicrosoftMail;
