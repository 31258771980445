import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Typography } from 'antd';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import utils from '../../../Utils/utils';
import ButtonComponent from "../../../Components/Button";
import './InterestBlock.less';

const { Paragraph } = Typography;

const skeletonList = [1, 2, 3, 4, 5]

export const InterestBlock = ({ skeleton, isMobile, product, onDownloadFile, onOpenUpgradePlan }) => {

  /* field = path or audience */
  /* sort_type = descend or ascend */
  const [sort, setSort] = useState({ field: null, sort_type: null });
  const [isVisibleAll, setIsVisibleAll] = useState(false);

  const { t } = useTranslation();

  const interestsList = product?.interests?.length ? [...product?.interests] : [];
  const isLocked = product?.is_locked && interestsList.length > 3

  const renderHeader = (<>
    <span className="title">{t('Interest Targeting')}</span>

    <span className={cls('link', {disabled: isLocked})}
          onClick={isLocked ? ()=>{} : onDownloadFile}
    >
       {isLocked ? <Icon role="icon" type="lock"/> : ''}
        {t('Export To CSV')}
      </span>
  </>);

  const renderHeaderSkeleton = (<>
    <span className="title skeleton" style={{ width: isMobile ? 190 : 222 }}/>
    <span className="link skeleton" style={{ width: isMobile ? 97 : 112 }}/>
  </>);

  const handleChangeSort = (field) => {
    if(isLocked) return
    if (sort.field !== field) {
      setSort({ field: field, sort_type: 'descend' });
    } else {
      if (sort.field === field) {
        if (sort.sort_type === 'descend') {
          setSort({ field: field, sort_type: 'ascend' });
        } else {
          setSort({ field: null, sort_type: null });
        }
      }
    }
  };

  function sortList(a, b) {
    if (sort['sort_type'] === 'descend') {
      return (a[sort.field] > b[sort.field] ? -1 : 1);
    } else if (sort['sort_type'] === 'ascend') {
      return (a[sort.field] > b[sort.field] ? 1 : -1);
    } else {
      return 0;
    }
  }

  function sortedList() {
    let result = interestsList.sort(sortList);
    if ((isMobile && !isVisibleAll) || isLocked) {
      return result.slice(0, 5);
    } else {
      return result;
    }
  }

  const handleChangeShow = () => {
    setIsVisibleAll(isVisibleAll => !isVisibleAll);
  };

  const memoizedList = useMemo(() => sortedList(),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [sort.field, sort.sort_type, isVisibleAll, interestsList.length, product?.product_id]);

  if (skeleton) {
    return (<ProductDetailsCard
      header={renderHeaderSkeleton}
      withLine={!isMobile}
    >
      <div className="interest-card header-line">
        <div className="header-line-sort-item">
          <span className="icon-wrap skeleton"/>
          <span className="text skeleton" style={{width: 67}}/>
        </div>

        <div className="header-line-sort-item">
          <span className="icon-wrap skeleton"/>
          <span className="text skeleton" style={{width: 73}}/>
        </div>
      </div>

      <div className='interest-card-wrap'>
        {
          skeletonList.map(el => (
            <div key={el} className='interest-card'>
              <div className='interest-card-copy-text'>
                <span className="text skeleton" style={{width: utils.randomNum(42,72)}}/>
                <span className="icon-wrap skeleton"/>
              </div>
              <span className="text skeleton" style={{width: utils.randomNum(43,52)}}/>
            </div>
          ))
        }
      </div>

      {isMobile ? <div className={'interest-card-show-interest-btn skeleton'}/> : null}

    </ProductDetailsCard>);
  } else {
    return (
      <ProductDetailsCard
        header={renderHeader}
        withLine={!isMobile}
        isLocked={isLocked}
        onOpenUpgradePlan={onOpenUpgradePlan}
      >
        <div className="interest-card header-line">

          <div className="header-line-sort-item"
               onClick={() => handleChangeSort('name')}>
            <Icon type="arrow_downward" role="icon"
                  className={cls({
                    'icon-descend': sort['field'] === 'name' && sort['sort_type'] === 'descend',
                    'icon-ascend': sort['field'] === 'name' && sort['sort_type'] === 'ascend',
                  })}
            />
            <span>{t('Interests')}</span>
          </div>

          <div className="header-line-sort-item"
               onClick={() => handleChangeSort('audience')}>
            <Icon type="arrow_downward" role="icon"
                  className={cls({
                    'icon-descend': sort['field'] === 'audience' && sort['sort_type'] === 'descend',
                    'icon-ascend': sort['field'] === 'audience' && sort['sort_type'] === 'ascend',
                  })}
            />
            <span>{t('Audience')}</span>
          </div>

        </div>

        <OverlayScrollbar>
          <div className={cls('interest-card-wrap', { 'show-all': isVisibleAll })}>
            {
              memoizedList.map((el, index) => (

                <div key={el.id}
                     className={cls('interest-card', {locked: el.is_locked})}
                >

                  <Paragraph
                    className="interest-card-copy-text"
                    copyable={{
                      icon: [
                        <Icon type="copy"
                              role="button"
                              key="copy-icon"
                              titleText={t('Copy')}
                              tooltipProps={{
                                placement: 'top',
                                trigger: isMobile ? 'click' : 'hover',
                                getPopupContainer: () => document.getElementById('global-wrap'),
                              }}
                        />,
                        <Icon type="copy"
                              role="button"
                              key="copied-icon"
                              titleText={t('Copied')}
                              tooltipProps={{
                                placement: 'top',
                                trigger: isMobile ? 'click' : 'hover',
                                getPopupContainer: () => document.getElementById('global-wrap'),
                                visible: true,
                              }}
                              className="active"
                        />,
                      ],
                      tooltips: false,
                    }}
                  >
                    {el.name}
                  </Paragraph>

                  <span className="interest-card-value">{utils.convertNumber(el?.audience, 2)}</span>
                </div>
              ))}
          </div>
        </OverlayScrollbar>
        {
          isMobile && !isLocked
            ? (
              <ButtonComponent type="primary"
                      className={'btn-primary interest-card-show-interest-btn'}
                      onClick={handleChangeShow}
              >
                {t(isVisibleAll ? 'Show Less' : 'Show More')}
              </ButtonComponent>)
            : null
        }
      </ProductDetailsCard>
    );
  }
};

export default InterestBlock;
