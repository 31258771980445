import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbar } from '../ScrollBar';
import { titleSortFilters } from '../titleSortFilters';
import './styles-sort.less';

function handleSort({ order }) {
  switch (order) {
    case '':
      return 'descend';
    case 'descend':
      return 'ascend';
    case 'ascend':
      return '';
    default:
      return '';
  }
}

const sortProps = ({ tooltip }) => [
  { title: 'Cost', sortKey: 'cost' },
  { title: 'Price', sortKey: 'price' },
  { title: 'P/C Ratio', sortKey: 'p_c_ratio', tooltip: 'tooltips.p_c_ratio' },
  { title: 'BEROAS', sortKey: 'BEROAS', tooltip: 'tooltips.BEROAS' },
  { title: 'BECPA', sortKey: 'BECPA', tooltip: 'tooltips.BECPA' },
  { title: 'POT Profit', sortKey: 'pot_profit', tooltip: 'tooltips.pot_profit' },
];

const SortItemComponent = (
  {
    onClickHandler,
    className,
    title,
    tooltip,
    sortKey,
    sortData,
    isMobile,
}) => {
  return (
    <div
      className={cls(className, 'sort_item')}
      onClick={() => onClickHandler({ dataIndex: sortKey })}
    >
      {tooltip ? (
        <Tooltip
          overlayClassName="details-tooltip p_c_ratio"
          title={tooltip}
          trigger={isMobile ? 'click' : 'hover'}
          destroyTooltipOnHide={true}
          getPopupContainer={() => document.getElementById('global-wrap')}
        >
          {titleSortFilters({
            title,
            stateData: sortData,
            key: sortKey,
          })}
        </Tooltip>
      ) : (
        titleSortFilters({ title, stateData: sortData, key: sortKey })
      )}
    </div>
  );
};

SortItemComponent.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  sortKey: PropTypes.string.isRequired,
  sortData: PropTypes.shape({
    sortColumn: PropTypes.object,
    sortOrder: PropTypes.string,
  }).isRequired,
};

export const SortComponent = ({ tooltipsText, onSort }) => {
  const [sortData, setSortData] = useState({
    sortColumn: {
      dataIndex: '',
    },
    sortOrder: '',
  });

  const { t } = useTranslation();
  const onSortHandler = ({ dataIndex }) => {
    const sortOrder = handleSort({
      order:
        // если индексы совпадают то продолжаем использовать state иначе обнвляем значение
        sortData.sortColumn.dataIndex === dataIndex ? sortData.sortOrder : '',
    });

    setSortData({
      sortColumn: {
        dataIndex,
      },
      sortOrder,
    });
    // call request
    onSort({ order: sortOrder, field: dataIndex });
  };

  return (
    <div className="sort">
      <span className="sort_text">{t('common.sort_by')}</span>
      <OverlayScrollbar visibility="hidden">
        <div className="sort_wrapper">
          {sortProps({ tooltip: tooltipsText }).map((value) => (
            <SortItemComponent
              title={t(value.title)}
              tooltip={t(value.tooltip)}
              className={`sort_item--${value.sortKey}`}
              key={`sort-item-key${value.sortKey}`}
              sortKey={value.sortKey}
              sortData={sortData}
              onClickHandler={onSortHandler}
            />
          ))}
        </div>
      </OverlayScrollbar>
    </div>
  );
};

SortComponent.propTypes = {
  onSort: PropTypes.func.isRequired,
};

export default SortComponent;
