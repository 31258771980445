import React, { useState } from 'react';
import { Input, Divider } from 'antd';
import cls from 'classname';
import './styles.less';

import InterestTargetingTable from './InterestTargetingTable';
import Icon from '../../../../Icon';

export default function InterestTargeting({
  interests,
  onSearch,
  getInterestTarget,
  changeInterestTarget,
  getInterestTargetNext,
  next,
  fetching,
  drop_id,
  product_id,
  approved,
  approvedIds,
  changeApprovedInterests,
}) {
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [showSavedInterests, setShowSavedInterests] = useState(false);
  const { Search } = Input;

  const selectedLength = approved;

  function handleSort({ order, field }) {
    if (order) {
      const ordering = order === 'ascend' ? field : `-${field}`;
      setSortOrder(ordering);
      getInterestTarget({
        product_id,
        drop_id,
        ordering,
        ...(searchText && { q: searchText }),
        ...(showSavedInterests && {saved: true}),
        page_size: 20,
      });
    } else {
      setSortOrder(null);
      getInterestTarget({
        product_id,
        drop_id,
        ...(searchText && { q: searchText }),
        ...(showSavedInterests && {saved: true}),
        page_size: 20,
      });
    }
  }

  function handleChange(e) {
    const {checked, value} = e.target
    changeApprovedInterests(checked ? 'add' : 'delete', value);
  }

  return (
    <div className="interest-targeting-block">
      <div className="block-with-label">
        <div className="block-with-label_label space-between bold-text">
          <div>
            Interest Targeting <span className="important">*</span>
          </div>
          <div
            className={cls('interests-approved', {
              empty_interests: selectedLength < 5,
            })}
          >
            <span>{selectedLength || 0}</span>
            <span> Approved</span>
          </div>
        </div>
      </div>
      <div className="container container--table-view interest--targeting">
        <div className="search">
          <div className="search_wrapper">
            <Search
              value={searchText}
              placeholder="Search for interests..."
              className={cls('search-input', 'interests-search', {'interests-search_active': showSavedInterests})}
              onChange={(e) => {
                e.persist();
                onSearch(e, sortOrder);
                setSearchText(e.target.value);
              }}
              onSearch={() => {
                changeInterestTarget(!showSavedInterests);
                setShowSavedInterests(showSavedInterests => !showSavedInterests);
                setSearchText('');
              }}
              enterButton="Saved"
              size="large"
              prefix={
                <>
                  <Icon type="search_icon" role="icon" />
                  <Divider type="vertical" className="search-divider" />
                </>
              }
            />
          </div>
        </div>
        <div className="interest-list">
          <InterestTargetingTable
            interests={interests}
            onSort={handleSort}
            searchText={searchText}
            getInterestTargetNext={getInterestTargetNext}
            next={next}
            fetching={fetching}
            onChange={handleChange}
            approvedIds={approvedIds}
          />
        </div>
      </div>
    </div>
  );
}
