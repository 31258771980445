import {API, encryptedData} from '../../Services/Api';

const request = new API();

export const getAutoStore = () => request.api.get('/auto-ds/get_stores/');

export const connectAutoStore = (code) => request.api.get(`/auto-ds/auth_callback/?code=${code}`);

export const importProductToAutoStore = (data) => request.api.post('/auto-ds/import_product/', encryptedData({...data}));

export const deleteAutoStore = () => request.api.delete('/auto-ds/disconnect/');
