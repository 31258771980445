import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cls from 'classname';
import { Row, Col, Card } from 'antd';
import Icon from '../../../Icon';
import Images from '../../../Images';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import ButtonComponent from "../../../Components/Button";
import './MoreProductsBlock.less';

const { Meta } = Card;

const productsListSkeleton = [1,2,3,4,5,6]

export const MoreProductsBlock = ({ skeleton, isMobile, product, scrollToTop, breadCrumbs }) => {

  const { t } = useTranslation();
  const [part, setPart] = useState(0);
  const [partSize, setPartSize] = useState(4);

  const productsList = product?.more_products ? [...product?.more_products] : [];
  const maxPart = Math.ceil(productsList.length / partSize) - 1;

  const path = breadCrumbs?.filter((el) => !!el.link)[0]?.link || '/dashboard';

  useEffect(() => {
    if (isMobile) {
      setPartSize(productsList.length);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isMobile, productsList.length]);

  function handleChangeMoreProducts(step) {
    if (step === 'next') {
      if (part === maxPart) return;
      setPart(part => part + 1);
    } else {
      if (part === 0) return;
      setPart(part => part - 1);
    }
  }

  const renderCard = (data, index) => (
    <Link
      key={skeleton ? data : data?.product_id}
      to={skeleton ? '#' :`${path}/product/${data.product_id}`}
      className="more-products_link"
    >
    <Card
      hoverable={false}
      className={cls('more-products-block-card', { _skeleton: skeleton })}
      cover={
        skeleton ? (
          <div className="image skeleton" />
        ) : (
          <img
            alt={`products ${index}`}
            src={data?.image || Images.defaultImg}
          />
        )
      }
    >
      <Meta
        title={
          skeleton ? (
            <span
              className="text skeleton"
              style={{ width: 86, height: 16, display: 'inline-block' }}
            />
          ) : (
            data?.title
          )
        }
        description={
          <div className="more-products-block-card-tag-wrap">
            <span
              className={cls('more-products-block-card-tag', {
                skeleton: skeleton,
              })}
              style={skeleton ? { width: 86, height: 16 } : {}}
            >
              {skeleton
                ? null
                : t('Cost _value_', { value: data?.cost.replaceAll('.', ',') })}
            </span>
            <span
              className={cls('more-products-block-card-tag', {
                skeleton: skeleton,
              })}
              style={skeleton ? { width: 62, height: 16 } : {}}
            >
              {skeleton
                ? null
                : t('_value_/10 Score', { value: data?.score || 0 })}
            </span>
          </div>
        }
      />
    </Card>
    </Link>
  );

  if (skeleton) {
    return (
      <div className='more-products-block'>
        <div className="more-products-block-header">
          <span className="more-products-block-header-title skeleton"
                style={{width: 233}}
          />
          {
            isMobile
              ? null
              : (<>
                <div className="scroll-to-top">
                  <div className='icon-wrap skeleton'/>
                  <span className='text skeleton' style={{width: 86}}/>
                  <div className='icon-wrap skeleton'/>
                </div>

                <div className={'btn-wrapper'}>
                  <span className='nav-btn prev skeleton'/>
                  <span className='nav-btn skeleton'/>
                </div>
              </>)
          }

        </div>

        <div className="more-products-block-body more-products-block-body_skeleton">
          {
            productsListSkeleton.map((el, index) => renderCard(el, index))
          }
        </div>
      </div>
    );
  } else {
    return (
      <div className="more-products-block">
        <div className="more-products-block-header">
          <span className="more-products-block-header-title">
            {t('More Products From This Drop')}
          </span>

          {
            isMobile
              ? null
              : (<>
                <ButtonComponent type="primary"
                        className="scroll-to-top"
                        onClick={scrollToTop}
                >
                  <Icon role="icon" type="arrow_up"/>
                  <span>
                    {t('Scroll To Top')}
                  </span>
                  <Icon role="icon" type="arrow_up"/>
                </ButtonComponent>

                <div className={'btn-wrapper'}>
                  <span onClick={() => handleChangeMoreProducts('previous')}>
                    <Icon role={'icon'}
                          type={'arrow_slider_prev'}
                          className={cls('nav-btn prev', {
                            disabled: part === 0,
                          })}
                    />
                  </span>

                  <span onClick={() => handleChangeMoreProducts('next')}>
                    <Icon role={'icon'}
                          type={'arrow_slider_next'}
                          className={cls('nav-btn', {
                            disabled: productsList.length <= (partSize * part + partSize),
                          })}
                    />
                  </span>
                </div>
              </>)
          }

        </div>

        <OverlayScrollbar visibility={'hidden'} className='more-products-block-body-wrap'>
          <Row gutter={16} className="more-products-block-body" style={{margin: 0}}>
            {
              productsList
                .slice(part * partSize, part * partSize + partSize)
                .map((el, index) => {
                  if (isMobile) {
                    return renderCard(el, index);
                  } else {
                    return (
                      <Col span={6} key={skeleton ? el : el?.product_id}>
                        {renderCard(el, index)}
                      </Col>
                    );
                  }
                })
            }
          </Row>
        </OverlayScrollbar>
      </div>
    );
  }
};

export default MoreProductsBlock;
