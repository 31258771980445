import React from 'react';
import Icon from '../../../Icon';
import { useNavigate } from 'react-router';

const SkipOffer = ({ setVisible, isMobile, isOnboarding=false }) => {

  const navigate = useNavigate();

  return (
    <div className={'skip-offer'}>
      <p className={'skip-offer-text'}
         style={{display: isOnboarding ? 'block': 'none'}}
      >
        <Icon type={'skip_offer_info'} role={'icon'} />
        <span>
          Pay attention! It’s one time offer only.
        </span>
      </p>
      <div className={'skip-offer-btn'}
           onClick={() => {
             if (isOnboarding) setVisible(true);
             else navigate('/dashboard');
           }}
      >
        <span>
          {
            isMobile ?
              'Skip'
              :
              'Skip offer'
          }
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M10.0027 7.98695C10.0032 7.89963 9.98642 7.81307 9.95324 7.73223C9.92007 7.65139 9.87118 7.57786 9.80939 7.51586L7.14273 4.86184C7.08057 4.79998 7.00678 4.7509 6.92556 4.71742C6.84435 4.68394 6.7573 4.66671 6.66939 4.66671C6.58149 4.66671 6.49444 4.68394 6.41323 4.71742C6.33201 4.7509 6.25822 4.79998 6.19606 4.86184C6.1339 4.9237 6.08459 4.99715 6.05095 5.07798C6.01731 5.15881 6 5.24544 6 5.33293C6 5.42042 6.01731 5.50705 6.05095 5.58788C6.08459 5.66871 6.1339 5.74215 6.19606 5.80402L8.40273 7.98695L6.28273 10.1765C6.21453 10.2376 6.15973 10.3119 6.12174 10.395C6.08375 10.4781 6.06339 10.5681 6.06194 10.6594C6.06048 10.7506 6.07797 10.8412 6.11329 10.9254C6.14862 11.0097 6.20103 11.0858 6.26725 11.1489C6.33347 11.2121 6.41206 11.2609 6.49813 11.2925C6.58419 11.324 6.67586 11.3376 6.76742 11.3322C6.85898 11.3269 6.94845 11.3028 7.03023 11.2615C7.11202 11.2202 7.18436 11.1626 7.24273 11.0922L9.81606 8.43814C9.9336 8.3168 10.0003 8.15549 10.0027 7.98695Z"
            fill="#225AEA" />
        </svg>
      </div>
    </div>
  );
};

export default SkipOffer;
