import React from 'react';

const UniversityDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M22.4401 20.8533C23.3334 20.2666 24.5067 20.9066 24.5067 21.9733V23.6933C24.5067 25.3866 23.1867 27.2 21.6001 27.7333L17.3467 29.1466C16.6001 29.4 15.3867 29.4 14.6534 29.1466L10.4001 27.7333C8.80008 27.2 7.49341 25.3866 7.49341 23.6933V21.96C7.49341 20.9066 8.66674 20.2666 9.54674 20.84L12.2934 22.6266C13.3467 23.3333 14.6801 23.68 16.0134 23.68C17.3467 23.68 18.6801 23.3333 19.7334 22.6266L22.4401 20.8533Z"
        fill="#225AEA"/>
      <path
        d="M26.64 8.61333L18.6534 3.37333C17.2134 2.42666 14.84 2.42666 13.4 3.37333L5.37336 8.61333C2.80003 10.28 2.80003 14.0533 5.37336 15.7333L7.50669 17.12L13.4 20.96C14.84 21.9067 17.2134 21.9067 18.6534 20.96L24.5067 17.12L26.3334 15.92V20C26.3334 20.5467 26.7867 21 27.3334 21C27.88 21 28.3334 20.5467 28.3334 20V13.44C28.8667 11.72 28.32 9.72 26.64 8.61333Z"
        fill="#225AEA"/>
    </svg>
  );
};

export default UniversityDashboard;
