import React from 'react';
import cls from 'classname';
import PropTypes from 'prop-types';

// STYLES
import './styles.less';

const Skeleton = ({ width, height, borderRadius, className }) => (
  <div
    style={{ width, height, borderRadius }}
    className={cls('skeleton-item', className)}
  />
);

Skeleton.defaultProps = {
  borderRadius: 6,
};

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

export default Skeleton;
