import React from 'react';

const AdSpotChartShares = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_37799_211209)">
        <path d="M5.5 11L11.25 8.75L14.5 5L17 6.5L20 10V11.5L15 15L14 14.5L13 13L8.5 14L4 17L5.5 11Z" fill="#225AEA"/>
        <path
          d="M3.7791 20.9996C4.00754 21.0062 4.23136 20.9345 4.41331 20.7962C4.59525 20.6579 4.72435 20.4615 4.7791 20.2396C5.22936 18.4556 6.22088 16.8547 7.6175 15.6568C9.01412 14.459 10.7473 13.7229 12.5791 13.5496V14.6696C12.5793 15.0199 12.6828 15.3623 12.8767 15.654C13.0706 15.9458 13.3462 16.1738 13.6691 16.3096C14.0192 16.4705 14.4084 16.5269 14.7899 16.4719C15.1713 16.4169 15.5287 16.2529 15.8191 15.9996L20.8791 11.5896C21.0731 11.4244 21.229 11.219 21.3358 10.9876C21.4427 10.7562 21.498 10.5044 21.498 10.2496C21.498 9.99474 21.4427 9.74293 21.3358 9.51157C21.229 9.2802 21.0731 9.07481 20.8791 8.90959L15.8191 4.48959C15.5249 4.2319 15.1615 4.06614 14.7741 4.01282C14.3866 3.95949 13.992 4.02095 13.6391 4.18959C13.3162 4.32537 13.0406 4.5534 12.8467 4.84513C12.6528 5.13686 12.5493 5.4793 12.5491 5.82959V6.99959C9.83288 7.20959 7.29403 8.43006 5.43353 10.4201C3.57303 12.4102 2.52602 15.0254 2.4991 17.7496C2.50047 18.5891 2.60458 19.4253 2.8091 20.2396C2.86385 20.4615 2.99295 20.6579 3.17489 20.7962C3.35684 20.9345 3.58066 21.0062 3.8091 20.9996H3.7791ZM13.5491 11.4996C11.8523 11.487 10.1754 11.8646 8.64781 12.6032C7.12022 13.3419 5.78288 14.4218 4.7391 15.7596C5.24002 13.8098 6.38015 12.0843 7.97723 10.8589C9.57432 9.63341 11.5361 8.97876 13.5491 8.99959C13.8143 8.99959 14.0687 8.89423 14.2562 8.70669C14.4437 8.51916 14.5491 8.2648 14.5491 7.99959V6.07959L19.3691 10.2496L14.5491 14.4596V12.4596C14.5388 12.2014 14.4289 11.9572 14.2424 11.7782C14.056 11.5992 13.8075 11.4994 13.5491 11.4996Z"
          fill="#225AEA"/>
      </g>
      <defs>
        <clipPath id="clip0_37799_211209">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdSpotChartShares;
