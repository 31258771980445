import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Divider } from 'antd';
import ProductDetailsCard from './ProductDetailsCard';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import Icon from '../../../Icon';
import ButtonComponent from "../../../Components/Button";
import './DescriptionBlock.less';

const platformsList = [
  { id: 1, name: 'aliexpress' },
  { id: 2, name: 'amazon' },
  { id: 3, name: 'alibaba' },
  { id: 4, name: 'facebook' },
  { id: 5, name: 'google' },
];

export const DescriptionBlock = ({ skeleton, isMobile, theme, product, isShopifyConnected, autoDS={}, openModalShopify, openModalAutoDS=null, disabledImport }) => {
  const { t } = useTranslation();
  const description = product?.description;
  const shopifyRedirectUrl = product?.shopify_redirect_url?.latest || null;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '')

  const renderHeader = (
    <div className="description-card-header">
      <div className="description-card-header-item">
        <span className="label">{t('Cost')}</span>
        <span className="value">
          {product?.cost?.replaceAll('.', ',') || '-'}
        </span>
      </div>

      <div className="description-card-header-item">
        <span className="label">
          {t('Shipping')}
          {!isMobile ? (
            <Icon
              type="attention_outline"
              role="button"
              titleText={t('tooltips.shipping')}
              tooltipProps={{
                placement: 'top',
                overlayClassName: cls('details-tooltip', 'shipping'),
                getPopupContainer: () =>
                  document.getElementById('product-details-block-header'),
              }}
            />
          ) : null}
        </span>

        <span className="value">
          {product.shipping
            ? `$${product.shipping.replaceAll('.', ',')}`
            : t('free')}
        </span>
      </div>
    </div>
  );

  const renderHeaderSkeleton = (
    <div className="description-card-header">
      <div className="description-card-header-item">
        <span className="label skeleton" style={{ width: 36 }} />
        <span className="value skeleton" style={{ width: 76 }} />
      </div>

      <div className="description-card-header-item">
        <span className="label skeleton" style={{ width: 66 }} />
        {isMobile ? null : <span className="icon-wrap skeleton" />}
        <span className="value skeleton" style={{ width: 59 }} />
      </div>
    </div>
  );

  const handleOpenModal = () => {
    if(isShopifyConnected){
      openModalShopify('import', initialUrl, product?.product_id)
    }else{
      openModalShopify('connect', initialUrl, null)
    }
  }

  const handleOpenAutoDS = () => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) openModalAutoDS('import', {'source': 'portfolio', 'product_id': product?.product_id,})
      else openModalAutoDS('import_unavailable', null)
    } else openModalAutoDS('connect', null)
  }

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
        <div className="description-card">
          <div className="description-card-description">
            <p className="text skeleton" />
            <p className="text skeleton" />
            <p className="text skeleton" style={{ width: '19.2%' }} />
            <p className="text skeleton" />
            <p className="text skeleton" />
            <p className="text skeleton" style={{ width: '92.3%' }} />
          </div>
          <Divider />
          <div className="description-card-footer">
            <div className="description-card-footer-item">
              <span className="label skeleton" style={{ width: 102 }} />
              <div className="list">
                {[1, 2, 3, 4, 5].map((el) => (
                  <span key={el} className="link with-img skeleton" />
                ))}
              </div>
            </div>
            {isMobile ? null : <div className="btn-import skeleton" />}
          </div>
        </div>
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard header={renderHeader} withLine={!isMobile}>
      <div className="description-card">
        <OverlayScrollbar visibility="hidden">
          <div className="description-card-description">
            <p>{description}</p>
          </div>
        </OverlayScrollbar>

        <Divider />

        <div className="description-card-footer">
          <div className="description-card-footer-item">
            <span className="label">{t('Quick Search')}</span>
            <div className="list">
              {platformsList.map((el, index) => {
                return (
                <a
                  key={el.id}
                  href={product?.quick_search?.[el.id] || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link with-img"
                >
                  <Icon role="button"
                        type={`search_${el.name}`}
                        theme={theme}
                        className={`${el.name}-icon`}
                        titleText={() => <>Search on <span style={{textTransform: 'capitalize'}}>{el.name}</span></>}
                        tooltipProps={{
                          placement: 'bottom',
                          destroyTooltipOnHide: true,
                          trigger: 'hover',
                          overlayClassName: cls('details-tooltip', `${el.name}-tooltip`),
                          getPopupContainer: () =>
                            document.querySelector('.description-card-footer-item'),
                        }}
                  />
                </a>
              )})}
            </div>
          </div>

          {isMobile ? null : (
            <div className='btn-wrap'>
              {
                shopifyRedirectUrl
                  ? <ButtonComponent type='primary'
                            className='btn-primary neutral border-only view-shopify-btn'
                            href={shopifyRedirectUrl}
                            target='_blank'
                  >
                    <Icon role="icon" type="check_mark_circle"/>
                    {t('View On Shopify')}
                </ButtonComponent>
                  : null
              }
              <ButtonComponent type="primary"
                               className="btn-primary auto-ds-import"
                               onClick={handleOpenAutoDS}
              >
                {t('Import')}
                <Icon role="icon" type="auto_ds" isLogo={true} />
              </ButtonComponent>
              <ButtonComponent type="primary"
                      className="btn-primary"
                      disabled={disabledImport}
                      onClick={handleOpenModal}
              >
                {t('Import')}
                <Icon role="icon" type="shopify_btn_icon" theme={theme} className={cls({disabled: disabledImport})}/>
              </ButtonComponent>
            </div>
          )}
        </div>
      </div>
    </ProductDetailsCard>
  );
};

export default DescriptionBlock;
