import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

// COMPONENTS
import {Spin, Row, Modal} from 'antd';
import Drops from './components/drops';
import SubUnSubComponent from '../../Components/Subscribed';
import Panel from '../../Components/Tabs/panel';
import Skeleton from './components';
import CollectionsActions from '../CollectionsPage/reducer';
import BreadcrumbsActions from '../Breadcrumbs/actions';

// STYLES
import './styles.less';
import './styles-subscribed.less';
import {
  imageBig
} from './tabs-data';

import Creators from './reducer';
import NavActions from '../../Redux/NavRedux';
import Icon from "../../Icon";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import dayjs from "dayjs";

export const getClassName = (other, category) => {
  let className = '';

  switch (true) {
    case category === 'random':
      className = 'theme-default';
      break;
    case category === 'household':
      className = 'theme-red';
      break;
    case ['fashion', 'fashion_men'].includes(category):
      className = 'theme-yellow';
      break;
    case ['baby', 'fashion_women'].includes(category):
      className = 'theme-pink';
      break;
    case category === 'electronics':
      className = 'theme-purple';
      break;
    case category === 'beauty':
      className = 'theme-green';
      break;
    case category === 'pets':
      className = 'theme-brown';
      break;
    default:
      className = 'theme-default';
      break;
  }

  return cls(className, other);
};

export const Subscribed = (
  {
    loading,
    skeletonLoading,
    userCategoryDataLoading,
    dropsLoading,
    categories,
    subscriptions,
    getStatus,
    getUserCategoryData,
    dispatch,
    isMobile,
    tabPosition,
    activeTab,
    userInfo,
    lastLanguage,
    setLastLanguage
}) => {
  const scrollRef = useRef();
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.title = 'Portfolio - Dropship';
    setLastLanguage(userInfo?.language);
    dispatch(CollectionsActions.removeCollection());
    dispatch(BreadcrumbsActions.clearBreadCrumbs());
    dispatch(
      BreadcrumbsActions.addBreadCrumb({
        name: 'Portfolio',
        link: '/portfolio',
      }),
    );

    // GET ALL CATEGORY
    if (!categories?.length) getStatus();
    getUserCategoryData({ id: categories[activeTab]?.id || 2 });

    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  // SCROLL TABS
  useEffect(() => {
    if (scrollRef.current && isMobile && activeTab) {
      scrollRef.current.osInstance().scroll({ x: tabPosition?.x || 0 });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [skeletonLoading]);

  // SUBSCRIBE TO CATEGORY - ROUTE TO UPCOMING DROPS PAGE
  const onSubscribeHandler = () => {
    dispatch(push('/setting/plan'));
  };

  // GET NEXT DROP ACTION HANDLER
  const getNextList = () => {
    if (subscriptions?.next) {
      dispatch(
        Creators.getNextDropsRequest({
          link: subscriptions.next,
        }),
      );
    }
  };

  // SHOW SKELETON
  if (skeletonLoading || userCategoryDataLoading) {
    return (
      <div className="portfolio-page tabs">
        <div style={{maxWidth: 1648, width: '100%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className="sales-tracker-top-page-header" style={{minWidth: '100%'}}>
          <div className="title-wrapper">
            <div className="title-wrapper-icon">
              <span className={'link skeleton'} style={{width: 45, height: 48}}/>
            </div>
            <div className="sales-tracker-top-page-subheader">
              <span className={'link skeleton'} style={{width: 242, height: 32}}/>
              <span className={'link skeleton'} style={{width: 335, height: 24}}/>
            </div>
          </div>
          <div className={'sales-tracker-top-page-limits-wrapper'}>
            <span className={'link skeleton'} style={{width: 167, height: 40}}/>
          </div>
        </div>
        </div>
        <Skeleton />
      </div>
    );
  }

  return (
      <div className="portfolio-page tabs">
        <div className="sales-tracker-top-page-header">
          <div style={{maxWidth: 1600, width: '100%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div className="title-wrapper">
              <div className="title-wrapper-icon">
                <Icon type={`portfolio_dashboard`} role={'icon'} width={32} height={32} />
              </div>
              <div className="sales-tracker-top-page-subheader">
                <h3 className="sales-tracker-top-page-title">
                  {t(`Portfolio`)}
                </h3>
                <h4 className="sales-tracker-top-page-subtitle">
                  {t(`Receive winning products on a weekly basis`)}
                </h4>
              </div>
            </div>
            <div className={'sales-tracker-top-page-limits-wrapper'}>
              <div className={'sales-tracker-top-page-watch-tutorial'}
                   onClick={() => setVisible(true)}
              >
                <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                <span>{t('Watch Tutorial')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio_wrap tabs_wrapper">
          <Spin
            spinning={
              // пока нет этих данных отображать лоадер
              loading || !categories.length
            }
          >
            <Panel
              className={getClassName(
                `panel--${categories[activeTab]?.name || 'random'}`,
                categories[activeTab]?.name || 'random',
              )}
            >
              <Row gutter={{ xs: 8, sm: 0 }}>
                {!categories[activeTab]?.subs_status && !subscriptions?.count ? (
                  <SubUnSubComponent
                    imageName={
                      imageBig[categories[activeTab]?.name] || 'random'
                    }
                    categoryText={subscriptions?.message || ''}
                    categoryName={categories[activeTab]?.name || 'random'}
                    categoryTitle={categories[activeTab]?.title || 'random'}
                    active={categories[activeTab]?.status || false}
                    subscribeFunc={onSubscribeHandler}
                  />
                ) : (
                  <Drops
                    {...subscriptions}
                    getNextList={getNextList}
                    dropsLoading={dropsLoading}
                    drops={subscriptions?.results || []}
                    isMobile={isMobile}
                    category={categories[activeTab]}
                  />
                )}
              </Row>
            </Panel>
          </Spin>
        </div>
        <div
          className={cls('portfolio_empty-footer', {
            'portfolio_empty-footer--with-btn':
              !categories[activeTab]?.subs_status,
          })}
        />
        <Modal
          className="change-modal custom-modal video-modal"
          {...(isMobile ? {transitionName: ''} : null)}
          getContainer={() => document.getElementById('global-wrap')}
          centered={!isMobile}
          open={visible}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
          }
          destroyOnClose
          closable="true"
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <ReactPlayer controls={true}
                       url={'https://www.youtube.com/watch?v=SaL0W9Lq1To'}
                       height={'100%'}
                       playing={true}
                       width={'100%'}
          />
        </Modal>
      </div>
  );
};

Subscribed.propTypes = {
  loading: PropTypes.bool,
  skeletonLoading: PropTypes.bool,
  dropsLoading: PropTypes.bool,
  categories: PropTypes.array,
  subscriptions: PropTypes.object,
  getStatus: PropTypes.func,
  getUserCategoryData: PropTypes.func,
  setTabPosition: PropTypes.func,
  tabPosition: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  dispatch: PropTypes.func,
  isMobile: PropTypes.bool,
  setActiveTabIndex: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: state.portfolio.loading,
  skeletonLoading: state.portfolio.skeletonLoading,
  userCategoryDataLoading: state.portfolio.userCategoryDataLoading,
  dropsLoading: state.portfolio.dropsLoading,
  errors: state.portfolio.errors,
  categories: state.portfolio.categories,
  subscriptions: state.portfolio.subscriptions,
  isMobile: state.nav.isMobile,
  tabPosition: state.nav.tabPosition,
  activeTab: state.portfolio.activeTab,
  lastLanguage: state.portfolio.lastLanguage,
  userInfo: state.auth.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  getStatus: () => dispatch(Creators.getCategoryStatusRequest()),
  setLastLanguage: (data) => dispatch(Creators.setLastLanguage(data)),
  setTabPosition: (value) =>
    dispatch(NavActions.navSetProp('tabPosition', value)),
  getUserCategoryData: ({ id }) =>
    dispatch(Creators.getUserCategoryDataRequest({ id })),
  setActiveTabIndex: ({ activeTab }) =>
    dispatch(Creators.setActiveTabIndex({ activeTab })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribed);
