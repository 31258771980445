import React from 'react';

const TopProductsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20.0002 16C18.5274 16 17.3335 17.1939 17.3335 18.6667V24C17.3335 25.4728 18.5274 26.6667 20.0002 26.6667H25.3335C26.8063 26.6667 28.0002 25.4728 28.0002 24V18.6667C28.0002 17.1939 26.8063 16 25.3335 16H24.6668C24.2986 16 24.0002 16.2985 24.0002 16.6667V18.6667C24.0002 19.403 23.4032 20 22.6668 20C21.9304 20 21.3335 19.403 21.3335 18.6667V16.6667C21.3335 16.2985 21.035 16 20.6668 16H20.0002Z"
            fill="#D71313"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.66667 16C5.19391 16 4 17.1939 4 18.6667V24C4 25.4728 5.19391 26.6667 6.66667 26.6667H12C13.4728 26.6667 14.6667 25.4728 14.6667 24V18.6667C14.6667 17.1939 13.4728 16 12 16H11.3333C10.9651 16 10.6667 16.2985 10.6667 16.6667V18.6667C10.6667 19.403 10.0697 20 9.33333 20C8.59695 20 8 19.403 8 18.6667V16.6667C8 16.2985 7.70152 16 7.33333 16H6.66667Z"
            fill="#D71313"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.3332 2.66669C11.8604 2.66669 10.6665 3.86059 10.6665 5.33335V10.6667C10.6665 12.1394 11.8604 13.3334 13.3332 13.3334H18.6665C20.1393 13.3334 21.3332 12.1394 21.3332 10.6667V5.33335C21.3332 3.86059 20.1393 2.66669 18.6665 2.66669H17.9998C17.6316 2.66669 17.3332 2.96516 17.3332 3.33335V5.33335C17.3332 6.06973 16.7362 6.66669 15.9998 6.66669C15.2635 6.66669 14.6665 6.06973 14.6665 5.33335V3.33335C14.6665 2.96516 14.368 2.66669 13.9998 2.66669H13.3332Z"
            fill="#D71313"/>
    </svg>
  );
};

export default TopProductsIcon;
