import React from 'react';
import {Divider} from "antd";

const ChartInfoSkeleton = () => {
  return (
    <div className={'chart-block'}>
      <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
        <div style={{display: "flex", alignItems: "center", gap: '8px'}}>
          <span className="link skeleton" style={{width: 122, height: 24}}/>
          <span className="link skeleton" style={{width: 186, height: 20}}/>
        </div>
        <span className="link skeleton" style={{width: 107, height: 16}}/>
      </div>
      <span className="link skeleton" style={{width: 141, height: 40, margin: '8px 0 16px'}}/>
      <span className="link skeleton" style={{width: '100%', height: 235}}/>
      <Divider type={'horizontal'} style={{margin: '16px auto'}} />
      <div className="fadspot-page-overview-chart-stats">
        {
          [1,2,3].map(el => (
            <div key={el} className="fadspot-page-overview-chart-stat">
              <div className="fadspot-page-overview-chart-stat-icon"/>
              <div className="fadspot-page-overview-chart-stat-info">
                <span className="link skeleton" style={{width: 65, height: 20}}/>
                <span className="link skeleton" style={{width: 79, height: 24}}/>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ChartInfoSkeleton;
