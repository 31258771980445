import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Layout, message, Spin } from 'antd';
import AuthActions from './LoginPage/reducer';
import Images from '../Images';
import ButtonComponent from "../Components/Button";

function ExpiredPage(props) {
  const { error, fetching } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      if (error.message) {
        for (const i in error.message) {
          message.error(`${i}: ${error.message[i]}`, 5);
        }
      }
      props.resetError();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [!!error]);

  function handleResend() {
    if (state) {
      props.resendEmail(state);
    } else {
      navigate('/login');
    }
  }

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div
          className="login-row"
          style={{
            background: `center / cover url(${Images.loginBg})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="login-col">
            <div className="login-logo">
              <img src={Images.logoLogin} alt="" />
            </div>
            <div className="page-content">
              <img src={Images.stopWatch} style={{ marginBottom: 56 }} alt="" />
              <div className="page-title" style={{ color: '#EB5757' }}>
                {t('Uh-oh!')}
              </div>
              <div className="page-text">
                <Trans
                  i18nKey="It looks like the link sent to _email_ has already Expired. Kindly click on the button below to resend."
                  values={{ email: state }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonComponent
                  type="primary login"
                  className="btn-primary"
                  style={{ width: '165px' }}
                  onClick={handleResend}
                >
                  {t('Resend')}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  error: state.auth.error,
  fetching: state.auth.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
  resendEmail: (email) => dispatch(AuthActions.resendEmailRequest(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredPage);
