import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../Images'
import './CategoryCardCheckout.less'

const CategoryCardCheckout = (
  {
    plan,
  }) => {
  const {t} = useTranslation()
  return (
    <div className={'category-card-checkout'}>

      <div className={'category-info-wrapper'}>

        <div className='img-wrp'>
          <img src={Images[`plan_${plan?.name}_small`]} alt=""/>
        </div>

        <div className={'info-wrp'}>
          <span className={'category-name'}>{`${t(plan?.name)} ${t('Plan')}`}</span>
          <p className={'category-sub-name'}>7-Day Free Trial</p>
        </div>

      </div>
    </div>
  )
}

export default CategoryCardCheckout
