import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import utils from '../../Utils/utils';
import ModalComponent from '../Modal';
import ChangeCompanyDetailsForm from './ChangeCompanyDetailsForm';
import openNotification from '../Notification';
import './styles-billing-address.less';

function CompanyDetails({
  user,
  isMobile,
  getCountries,
  countries,
  addCompanyDetails,
  changeCompanyDetails,
  saveResult,
  errors,
  saveFetching,
  resetErrors,
  resetResult,
  defaultUserCountry,
}) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errors?.add_company_details || errors?.change_company_details) {
      openNotification({
        type: 'error',
        message: t('Something went wrong, kindly try again'),
        style: { minWidth: '716px' },
      });
      resetErrors();
    }
    if (saveResult?.add_company_details || saveResult?.change_company_details) {
      openNotification({
        type: 'success',
        message: t(
          'setting_billing.Company Details has been successfully _app_',
          {
            app: saveResult?.add_company_details
              ? t('setting_billing.added') + '!'
              : t('setting_billing.updated') + '!',
          },
        ),

        style: { minWidth: '716px' },
      });
      resetResult();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  const { company } = user;

  function replaceValue(value) {
    const defaultValue = value ? value : '-';
    return hidden ? defaultValue : utils.starsReplace(value, true);
  }

  const handleClose = () => {
    setVisible(false);
  };
  const handleSubmit = (data) => {
    if (saveFetching) return
    company ? changeCompanyDetails(data) : addCompanyDetails(data);
    setTimeout(() => {
      if (!saveFetching) {
        setVisible(false);
      }
    }, 500);
  };
  return (
    <Card className="billing-address-block">
      <div className="billing-address-header">
        <div className="billing-title-text">
          {t('setting_billing.Company Details')}
        </div>
        <Icon
          type={hidden ? 'eye' : 'eye_invisible'}
          width={24}
          height={24}
          role="button"
          onClick={() => setHidden(!hidden)}
          color="#707BA0"
        />
        <div className="edit-btn" onClick={() => setVisible(true)}>
          {company
            ? t('setting_billing.Edit Details')
            : t('setting_billing.Add Details')}
        </div>
      </div>

      <Row
        gutter={[
          { xs: 21, sm: 21, md: 15 },
          { xs: 16, sm: 16 },
        ]}
      >
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Company Name')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.name)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Country')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.country?.name)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.City')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.city)}
          </div>
        </Col>
        {company?.country?.name === 'United States' ? (
          <Col xs={12} sm={12} md={8}>
            <div className="billing-subtitle-text left-text">
              {t('setting_billing.State')}
            </div>
            <div className="billing-dark-text botom-text">
              {replaceValue(company?.state)}
            </div>
          </Col>
        ) : null}
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Postal Code')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.postal_code)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Address Line 1')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.address_line_1)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.Address Line 2')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.address_line_2)}
          </div>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <div className="billing-subtitle-text left-text">
            {t('setting_billing.VAT')}
          </div>
          <div className="billing-dark-text botom-text">
            {replaceValue(company?.VAT)}
          </div>
        </Col>

        <ModalComponent
          width={438}
          className="company-detail-modal"
          title={
            company
              ? t('setting_billing.Edit Company Details')
              : t('setting_billing.Add Company Details')
          }
          isMobile={isMobile}
          handleClose={handleClose}
          open={visible}
          onCancel={handleClose}
          destroyOnClose
          footer={false}
        >
          <Spin size="large" spinning={saveFetching}>
            <ChangeCompanyDetailsForm
              company={company || {country: defaultUserCountry}}
              onCancel={handleClose}
              onSubmit={handleSubmit}
              getCountries={getCountries}
              countries={countries}
              isMobile={isMobile}
            />
          </Spin>
        </ModalComponent>
      </Row>
      <div
        className="edit-btn-mobile blue-btn"
        onClick={() => setVisible(true)}
      >
        {company ? t('setting_billing.Edit') : t('setting_billing.Add')}
      </div>
    </Card>
  );
}

export default React.memo(CompanyDetails)
