import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cls from 'classname';
import PaymentTypeCheckbox from '../PaymentTypeCheckbox';
import Icon from '../../Icon';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import API from '../../Services/Api';
import CardForm from "./CardForm";
import './CreditCardForm.less';

const tooltipTitle = {
  card: 'All transactions are secure and encrypted.',
  cvv: '3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.',
};

const style = {
  light: {
    base: {
      color: '#151E3A',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',

      ':focus': {
        color: '#151E3A',
      },

      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: '#E2E6E3',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
  dark: {
    base: {
      color: '#E2E6F3',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',

      ':focus': {
        color: '#E2E6F3',
      },

      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: '#151E3A',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
};

const options = {
  // Custom classes - applied on container elements based on field's state
  classes: {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  },
  // locale
  locale: 'en',
  // Custom placeholders
  placeholder: {
    number: '4111 1111 1111 1111',
    expiry: 'MM / YY',
    cvv: ' ',
  },
  // Custom fonts
  fonts: [
    'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap',
  ],
};

function CreditCardForm(
  {
    fetching,
    onBack,
    isMobile,
    isSettings,
    btnText,
    theme,
    amount,
    intent,
    billingInfo,
    disable,
    setChargebeeCardFetching,
    errorSave,
    onBoarding= false,
    ...props
  }) {

  const {classes, locale, placeholder, fonts} = options;
  const cardRef = useRef();
  const {t} = useTranslation();
  const { pathname } = useLocation();
  const discountInfo = useSelector(store => store?.onBoarding?.discountResult);

  const [unlocked, setUnlocked] = useState(false);

  const [chargebeeInstance, setChargebeeInstance] = useState(null);
  const [state, setState] = useState({
    token: '',
    errors: {},
    errorMessage: '',
    loading: false,
    fullName: '',
  });

  const [isValidCard, setIsValidCard] = useState(false);
  const [cardData, setCardData] = useState({
    'number': null,
    'expiry': null,
    'cvv': null
  })

  const [lockIconColor, setLockIconColor] = useState('#707BA0');

  const isDisabledIntent = false;
  const isDisabledBtn = ( unlocked ?
      false
      :
      !state?.fullName || disable || state?.loading || fetching || !isValidCard
  )
  useEffect(() => {
    let instance = window.Chargebee?.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_APPLE_CHARGEBEE_PUBLISHABLE_KEY,
      enableRefersionTracking: true,
    });
    setChargebeeInstance(instance);
  }, []);

  useEffect(() => {
    setUnlocked(discountInfo?.type === 'percent'
      && +discountInfo?.value === 100
      && discountInfo?.duration_type === 'forever')
  }, [discountInfo])

    useEffect(() => {
      if (intent?.error) {
        setState(state => ({...state, loading: false}));
        setChargebeeCardFetching(false)
        intent.clearIntent()
      }
    },
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [intent?.error]);

  useEffect(() => {
    if (cardRef?.current && intent?.result) {

      const additionalData = {
        billingAddress: {
          firstName: state?.fullName?.split(' ')[0] || '',
          lastName: state?.fullName?.split(' ')[1] || '',
          addressLine1: billingInfo?.address,
          city: billingInfo?.city,
          state: billingInfo?.state,
          countryCode: billingInfo?.country?.country_code,
          zip: billingInfo?.postal_code,
        }
      }

      cardRef.current.authorizeWith3ds(intent?.result, additionalData)
        .then(authorizedPaymentIntent => {
          props.onContinue({
            intent_id: authorizedPaymentIntent.id
          });
        }).catch(error => {
        API.saveFrontLogs({ pathname, action: 'authorizeWith3ds',  error })
          .then()
          .finally(() => {
            setState(state => ({...state, loading: false, token: ''}));
            setChargebeeCardFetching(false)
            errorSave('3D Secure authentication failed');
          })
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [intent?.result]);

  useEffect(() => {
    for (let key in cardData) {
      if (!cardData[key]?.complete || cardData[key]?.empty || Boolean(cardData[key]?.error)) return setIsValidCard(false)
      else setIsValidCard(true)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [cardData])

  function tokenize(skip=false) {
    if((isDisabledIntent || skip) && (onBoarding && Object.values(cardData).every(el => el === null || (el?.complete === false && el?.error === undefined)))) {
       props.onContinue({});
    }else{
      setState(state => ({...state, loading: true}));
      setChargebeeCardFetching(true);
      // Call tokenize methods through  card component's ref

      const additionalData = {
        addressLine1: billingInfo?.['address_1'],
        city: billingInfo?.city,
        state: billingInfo?.country?.id === 1450 ? billingInfo?.state : null,
        country: billingInfo?.country?.name,
        countryCode: billingInfo?.country?.country_code,
        zip: billingInfo?.postal_code,
      }

      cardRef.current.tokenize({
        firstName: state?.fullName?.split(' ')[0] || '',
        lastName: state?.fullName?.split(' ')[1] || '',
        ...(isSettings ? additionalData : {})
      }).then((data) => {
        setState(state => ({...state, token: data['vaultToken'], error: '', loading: false}));
        intent.createIntent({
          'amount': onBoarding ? 100 : amount ? amount : 100,
          'gateway_account_id': 'gw_BTcXhMTspTHPA8UH',
          'payment_method_type': 'card',
          'card_token': data['vaultToken'],
        });
      }).catch((error) => {
        API.saveFrontLogs({ pathname, action: 'tokenize', error })
          .then()
          .finally(() => {
          setChargebeeCardFetching(false)
            let message = error?.message.split('-')
          setState(state => ({
            ...state,
            loading: false,
            token: '',
            errorMessage: message[message?.length - 1].trim().length ?
              message[message?.length - 1].trim()
              :
              message[message?.length - 2].trim()
          }));
        })

      });
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  const onChange = (status) => {
    setCardData((prev) => ({...prev, [status?.field]: {...status}}));
    if (status.field === 'number') {
      if (status.error) setLockIconColor('#D71313')
      else if (status.type === 'change' || status.type === 'focus') setLockIconColor('#225AEA')
      else setLockIconColor('#707BA0')
    }

    let errors = {
      ...state.errors,
      [status.field]: status?.error?.message,
    };

    let errMessages = Object.values(errors).filter(message => !!message);
    setState(state => ({
      ...state,
      errors: errors,
      errorMessage: errMessages.shift() || '',
    }));
  };

  return (
    <>
      {
        isSettings
          ? null
          : (
            <>
              <span className="title-block" style={{width: '100%'}}>
                Card Information
              </span>
              <span className="text"
                    style={{fontSize: 14, lineHeight: '16px', color: '#707BA0', paddingTop: 8, width: '100%'}}
              >
                All transactions are secure and encrypted
              </span>
            </>)
      }

      <div className={cls('credit-card-form', {'credit-card-form_settings': isSettings})}>
        <PaymentTypeCheckbox type={isSettings ? null : 'chargebee'}
                             selectedMethod={'chargebee'}
                             isMobile={isMobile}
                             setMethod={() => {
                             }}
        >
          <div className="ex1 container">
            <div className="ex1-wrap">
              <div className="ex1-fieldset">
                <div className="ex1-field">
                  <InputComponent name="fullName"
                         className={cls('ex1-input', {'val': !!state?.fullName, disable: isDisabledIntent})}
                         type="text"
                         value={state?.fullName}
                         onChange={handleChange}
                  />
                  <label className="ex1-label">{t('Name on Card')}</label>
                </div>

                {!!chargebeeInstance
                  ? (
                    <CardForm cardRef={cardRef}
                              classes={classes}
                              styles={style[theme]}
                              fonts={fonts}
                              locale={locale}
                              placeholder={placeholder}
                              isDisabledIntent={isDisabledIntent}
                              onChange={onChange}
                              setLockIconColor={setLockIconColor}
                              t={t}
                              isMobile={isMobile}
                              lockIconColor={lockIconColor}
                              tooltipTitle={tooltipTitle}
                    />
                    )
                  : null
                }

              </div>
              <div id="errors" className="error" role="alert">{state?.errorMessage}</div>
            </div>
          </div>
        </PaymentTypeCheckbox>

        {
          isMobile
            ? (
              <div className={'double-btn-wrp step-billing-form-btn-wrap'}>
                <ButtonComponent type={'primary'}
                        className={cls('btn-primary', {
                          'step-billing-form-prev': window.location.pathname.includes('onboarding')
                        })}
                        onClick={() => onBack(null)}
                >
                  {
                    isSettings
                      ? null
                      : (
                        <>
                          <Icon role="icon" type="arrow_forward_onboarding" />
                        </>)
                  }
                  {btnText ? btnText?.cancel : 'Return To Billing'}
                </ButtonComponent>

                <span>You won't be billed anything now</span>

                <ButtonComponent type={'primary'}
                        className={cls('btn-primary', {
                          'step-billing-form-next': window.location.pathname.includes('onboarding')
                        })}
                        disabled={isDisabledBtn}
                        htmlType="submit"
                        onClick={() => tokenize(unlocked)}
                >
                  {btnText ? btnText?.submit :
                      <>
                        Start Free Trial
                        <Icon role='icon' type='arrow_forward_onboarding' />
                      </>
                  }
                </ButtonComponent>

              </div>)
            : (
              <div className="dialog-footer"
                   style={{justifyContent: isSettings ? 'flex-end' : 'space-between'}}
              >
                <ButtonComponent className={cls('btn-primary', {
                  'step-billing-form-prev': window.location.pathname.includes('onboarding')
                })}
                                 onClick={() => onBack(null)}>
                  {
                    isSettings
                      ? btnText?.cancel
                      : <>
                        <Icon role='icon' type='arrow_forward_onboarding' />
                        Return To Billing
                      </>
                  }
                </ButtonComponent>

                <ButtonComponent
                  type="primary"
                  className={cls('btn-primary', {
                    'btn-primary_animation small': !isSettings,
                    'step-billing-form-next': window.location.pathname.includes('onboarding')
                  })}
                  style={{minWidth: isSettings ? 183 : 217}}
                  disabled={isDisabledBtn}
                  htmlType="submit"
                  onClick={() => tokenize(unlocked)}
                >
                  {
                    btnText
                      ? btnText?.submit
                      : 'Start Free Trial'
                  }

                  {
                    isSettings
                      ? null
                      : <Icon role="icon"
                              type="arrow_forward_onboarding"
                              color={'#FFFFFF'}
                              borderless
                              style={{marginLeft: 16}}/>
                  }
                </ButtonComponent>
              </div>)
        }
      </div>
    </>
  );
}

export default CreditCardForm;
