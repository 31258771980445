import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Creators from '../../Redux/OnboardingRedux';

import { Switch } from 'antd';

import './styles.less';

const SwitchSubscriptionPeriod = ({onlyText}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isYearlySubscription = useSelector(store => store.onBoarding.isYearlySubscription);

  function onChangeSwitch(state) {
    if(state === isYearlySubscription) return
    dispatch(Creators.setYearlySubscription(state))
  }

  return (
    <div className={cls('switch-block', {
      'switch-block_only-text': onlyText,
    })}>
      <div className={cls('switch-block-wrap', {active: isYearlySubscription})}>
        <span onClick={()=> onChangeSwitch(false)}>
          {t('Pay Monthly')}
        </span>
        {
          onlyText
            ? null
            : (
              <Switch name={'period'}
                      checked={isYearlySubscription}
                      onChange={(state) => onChangeSwitch(state)}
              />)
        }
        <span onClick={()=> onChangeSwitch(true)}>
          {t('Pay Yearly')}
        </span>
      </div>
      <span className="description">{t('Get up to 6 months free with yearly billing')}</span>
    </div>
  );
};

export default SwitchSubscriptionPeriod;
