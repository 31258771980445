import React from 'react';

const UniversityWatched = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992ZM10.4713 7.13799C10.7317 6.87764 10.7317 6.45553 10.4713 6.19518C10.211 5.93483 9.78886 5.93483 9.52851 6.19518L7.33325 8.39044L6.47132 7.52851C6.21097 7.26816 5.78886 7.26816 5.52851 7.52851C5.26816 7.78886 5.26816 8.21097 5.52851 8.47132L6.86185 9.80466C7.1222 10.065 7.54431 10.065 7.80466 9.80466L10.4713 7.13799Z"
            fill={color ? color : "#225AEA"}/>
    </svg>
  );
};

export default UniversityWatched;
