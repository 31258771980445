import React from 'react';

const AdItemProductsGeneralSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <div className='fadspot-page-products-general'>
      <span className="link skeleton" style={{width: '100%', height: 52}}/>
      <span className="link skeleton" style={{width: '100%', height: 88, marginTop: 20}}/>
      <span className="link skeleton" style={{width: '100%', height: 72, marginTop: 16}}/>
      {
        isMobile ?
          <div style={{marginTop: 32, display: "flex", width: '100%', alignItems: 'center', justifyContent: "space-between"}}>
            <span className="link skeleton" style={{width: '100%', height: 508}}/>
          </div>
          :
          <div style={{marginTop: 32, display: "flex", width: '100%', alignItems: 'center', justifyContent: "center", gap: 14, flexWrap: 'wrap'}}>
            {
              [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(el => <span className="link skeleton" key={el} style={{width: 268, height: 508}}/>)
            }
          </div>
      }
    </div>
  );
};

export default AdItemProductsGeneralSkeleton;
