import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import ButtonComponent from '../../../Components/Button';
import { Tooltip } from 'antd';

const QuickSearch = (
  {
    loadPreset,
    setLoadedPreset,
    loadedPreset,
    skeleton,
    isMobile,
    data = [],
    reset = null,
    disabledReset = false,
  }) => {

  const { t } = useTranslation();

  if (skeleton) return (
    <div className={'fadspot-page-quick-search-wrapper'}>
      <span className={'fadspot-page-quick-search-title'}>
        <span className={'link skeleton'} style={{ width: 92, height: 20 }} />
      </span>
      <div className="fadspot-page-quick-search-items">
        {
          [1, 2, 3].map(el => (
            <span key={el} className={'link skeleton'}
                  style={{ width: isMobile ? '100%' : 101 + el * 10, height: 32 }} />
          ))
        }
      </div>
    </div>
  );

  return (
    <div className={'fadspot-page-quick-search-wrapper'}>
      <span className={'fadspot-page-quick-search-title'}>
        {t('Quick Search')}:
      </span>
      <div className="fadspot-page-quick-search-items">
        {
          data.map(el =>
            el?.tooltip ?
              (
                <Tooltip placement="top"
                         title={el?.tooltip}
                         destroyTooltipOnHide={true}
                         key={el?.id + 1}
                >
                  <div className={cls('fadspot-page-quick-search-item', {
                         'active': loadedPreset?.id === el?.id,
                       })}
                       id={el?.id}
                       onClick={() => {
                         if (loadedPreset?.id !== el?.id) {
                           setLoadedPreset(el);
                           loadPreset(el);
                         }
                       }}
                  >
                    {el?.name}
                  </div>
                </Tooltip>
              )
                :
              (
                <div key={el?.id}
                     className={cls('fadspot-page-quick-search-item', {
                       'active': loadedPreset?.id === el?.id,
                     })}
                     id={el?.id}
                     onClick={() => {
                       if (loadedPreset?.id !== el?.id) {
                         setLoadedPreset(el);
                         loadPreset(el);
                       }
                     }}
                >
                {el?.name}
              </div>
            ),
          )
        }
      </div>
      {
        reset ?
          <ButtonComponent className={'fadspot-page-filters-button-reset'}
                           text={t('Reset Filter')}
                           onClick={() => reset()}
                           disabled={disabledReset}
          />
          :
          null
      }
    </div>
  );
};

export default QuickSearch;
