import React from 'react';
import { Collapse } from 'antd';
import './FAQ.less';

const {Panel} = Collapse;

const data = [
  {
    title: 'Do I Need To Have A Shopify Account Before Using The Websites?',
    text: 'No! All our Shopify Dropshipping Stores come as brand new stores that you\'ll get a 3 day free trial with. After the 3 Day Free Trial, Shopify will be $1 for 1 Month and after 1 Month, Shopify will be $39/Month. Shopify gives you an entire 30 Days to give your website a shot and only after 30 days you can choose to pay the $39 which is nothing if you are making sales on your store every day. If you don\'t want to pay the $39 you can cancel your $1 month trial. The risk is very low.'
  },
  {
    title: 'What If I Already Have A Shopify Store?',
    text: 'Most of the time it is a lot better if you have an expert setup the store from scratch for you since the design you made is most likely not setup for success. So, even if you have already have a shopify store, don\'t worry. We\'ll still be able to create a new one for you that is setup correctly.'
  },
  {
    title: 'How Many Products Will I Get On My Store?',
    text: 'All our stores come pre-implemented with 30 Products.'
  },
  {
    title: 'What Other Fees Are Associated With This?',
    text: 'The only other thing that you have to pay for is hosting for your Shopify store. You will get a free 3 day trial with no credit card required, if you choose to keep it you will need to pay $1 your first month to keep hosting your website through Shopify, you can cancel at anytime and there are no contracts or other hidden fees.'
  },
  {
    title: 'Do You Advertise The Store For Me?',
    text: 'We do NOT do any advertising for your store. That will be for you to do when the store is done.'
  },
  {
    title: 'What If I Have My Own Products? Can I Add Them To My Store?',
    text: 'Yes! You\'ll easily be able to add as many products as you like to your store after deliver it. The process is extremely simple and you can do it within a couple of clicks.'
  },
  {
    title: 'How Will I Be Able To Use The Shopify Store As My Own?',
    text: '24 - 48 Hours after you place your order, we\'ll send you your login information for your new Shopify Dropshipping Store. All you\'ll have to do is login, change the password, and the store is all yours!'
  },
  {
    title: 'Can I Get A Refund If I Don\'t Like My Shopify Store?',
    text: 'We have a 365 Day Guarantee, if you use your store for two months and don\'t make your money back we\'ll fully refund you.'
  },
  {
    title: 'Will You Help Me Make Changes And Customizations On My Shopify Store?',
    text: 'We will provide you support to help you access the Shopify store and use it. We\'ll also give you a step by step video guide on how to access and make changes to your store. However, we will not be able to go into your account and do the changes FOR YOU as that will not allow us to provide our stores at this good of a price. Rest assured, you will love your website and you\'ll easily be able to make the changes you would like.'
  },

]

const Faq = () => {

  return (
    <div className={'faq-wrapper'}>
      <div className="faq-title-wrapper">
        <h4>
          Frequently Asked Questions
        </h4>
        <div className={'faq-btn'}
             onClick={() => document.getElementById('pick-your-stores-form').scrollIntoView({
               behavior: 'smooth',
             })}
        >
          <span>
            Rush me my store!
          </span>
        </div>
      </div>
      <div className="faq-content">
        <Collapse collapsible={'header'}
                  expandIconPosition={'end'}
                  expandIcon={({ isActive }) => isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                            fill="#707BA0" />
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z"
                            fill="#707BA0" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
                            fill="#225AEA" />
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M10.3572 3H13.6428C14.7266 2.99999 15.6007 2.99998 16.3086 3.05782C17.0375 3.11737 17.6777 3.24318 18.27 3.54497C19.2108 4.02433 19.9757 4.78924 20.455 5.73005C20.7568 6.32234 20.8826 6.96253 20.9422 7.69138C21 8.39925 21 9.27339 21 10.3572V13.6428C21 14.7266 21 15.6008 20.9422 16.3086C20.8826 17.0375 20.7568 17.6777 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.6777 20.7568 17.0375 20.8826 16.3086 20.9422C15.6008 21 14.7266 21 13.6428 21H10.3572C9.27339 21 8.39925 21 7.69138 20.9422C6.96253 20.8826 6.32234 20.7568 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3.24318 17.6777 3.11737 17.0375 3.05782 16.3086C2.99998 15.6007 2.99999 14.7266 3 13.6428V10.3572C2.99999 9.27341 2.99998 8.39926 3.05782 7.69138C3.11737 6.96253 3.24318 6.32234 3.54497 5.73005C4.02433 4.78924 4.78924 4.02433 5.73005 3.54497C6.32234 3.24318 6.96253 3.11737 7.69138 3.05782C8.39926 2.99998 9.27341 2.99999 10.3572 3ZM7.85424 5.05118C7.24907 5.10062 6.90138 5.19279 6.63803 5.32698C6.07354 5.6146 5.6146 6.07354 5.32698 6.63803C5.19279 6.90138 5.10062 7.24907 5.05118 7.85424C5.00078 8.47108 5 9.26339 5 10.4V13.6C5 14.7366 5.00078 15.5289 5.05118 16.1458C5.10062 16.7509 5.19279 17.0986 5.32698 17.362C5.6146 17.9265 6.07354 18.3854 6.63803 18.673C6.90138 18.8072 7.24907 18.8994 7.85424 18.9488C8.47108 18.9992 9.26339 19 10.4 19H13.6C14.7366 19 15.5289 18.9992 16.1458 18.9488C16.7509 18.8994 17.0986 18.8072 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C18.8072 17.0986 18.8994 16.7509 18.9488 16.1458C18.9992 15.5289 19 14.7366 19 13.6V10.4C19 9.26339 18.9992 8.47108 18.9488 7.85424C18.8994 7.24907 18.8072 6.90138 18.673 6.63803C18.3854 6.07354 17.9265 5.6146 17.362 5.32698C17.0986 5.19279 16.7509 5.10062 16.1458 5.05118C15.5289 5.00078 14.7366 5 13.6 5H10.4C9.26339 5 8.47108 5.00078 7.85424 5.05118Z"
                            fill="#225AEA" />
                    </svg>}
        >
          {
            data.map(el => (
              <Panel key={el?.title}
                     showArrow={true}
                     header={
                       <div className={'faq-item-header'}>
                         {el?.title}
                       </div>
                     }
              >
                <div className={'faq-item-text'}>
                  {el?.text}
                </div>
              </Panel>
            ))
          }
        </Collapse>
      </div>

    </div>
  );
};

export default Faq;
