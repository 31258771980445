import React from 'react';
import {Divider} from "antd";

const UniversityVideoSkeleton = ({isMobile}) => {
  return (
    <div className={'product-database-page university-page university-video-page'}>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
        <span className='link skeleton' style={{width: 91, height: 16, marginRight: 8}}/>
        <span className='link skeleton' style={{width: 24, height: 24, marginRight: 8}}/>
        <span className='link skeleton' style={{width: 74, height: 16, marginRight: 8}}/>
        <span className='link skeleton' style={{ display: isMobile ? 'none' : 'block', width: 24, height: 24, marginRight: 8}}/>
        <span className='link skeleton' style={{ display: isMobile ? 'none' : 'block', width: 74, height: 16, marginRight: 8}}/>
        <span className='link skeleton' style={{ display: isMobile ? 'none' : 'block', width: 24, height: 24, marginRight: 8}}/>
        <span className='link skeleton' style={{ display: isMobile ? 'none' : 'block', width: 74, height: 16}}/>
      </div>
      <span className='link skeleton' style={{width: '100%', height: isMobile ? '430px' : '54vh', maxHeight: 900}}/>
      <div className="university-video-page-content-wrapper">
        <span className='link skeleton' style={{width: 248, height: 32, marginTop: 16}}/>
        <Divider type={'horizontal'} className='university-video-page-divider' />
        <span className='link skeleton' style={{width: '100%', height: 96}}/>
      </div>
    </div>
  );
};

export default UniversityVideoSkeleton;
