import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getStoreSuggestions = (data) =>
  request.api.post('/sales_tracker/stores/suggestion/domain_name/', encryptedData({...data}))

export const getTrackingStores = (data) => request.api.get('/sales_tracker/stores/', {...data});
export const getTrackingStoresNext = (url) => request.api.get(url, null);
export const getTrackingStoreByID = (id) => request.api.get(`/sales_tracker/stores/${id}/`, null);
export const deleteTrackingStoreByID = (record) => request.api.delete(`/sales_tracker/stores/${record.id}/`, null);
export const createTrackingStore = (url) => request.api.post(`/sales_tracker/stores/`, encryptedData({url: url}));
export const createTrackingStoreNew = (data) => {
  return data?.fullData ?
    request.api.post(`/sales_tracker/stores/start_tracking/?full_data=true`, encryptedData({...data}))
    :
    request.api.post(`/sales_tracker/stores/start_tracking/`, encryptedData({...data}));
}

export const getTrackingProducts = (data) => request.api.get('/sales_tracker/products/', {...data});
export const getTrackingProductsNext = (url) => request.api.get(url, null);
export const getTrackingProductByID = (id) => request.api.get(`/sales_tracker/products/${id}/`, null);
export const deleteTrackingProductByID = (record) => request.api.delete(`/sales_tracker/products/${record.id}/`, null);
export const createTrackingProduct = (url) => request.api.post(`/sales_tracker/products/`, encryptedData({url: url}));
export const createTrackingProductNew = (data) => request.api.post(`/sales_tracker/products/start_tracking/`, encryptedData({...data}));

export const getDetailedStoreInfo =  (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/stores/${data.id}/detailed_charts/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}))
    :
    request.api.post(`/sales_tracker/stores/${data.id}/detailed_charts/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));
}
export const getDetailedStoreInfoNew =  (data) => request.api.get(`/sales_tracker/stores/${data.id}/detailed_charts/info/`);
export const getDetailedProductInfo =  (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/${data?.store_id}_${data.id}/detailed_charts/raw_data/`,
      encryptedData({page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}))
    :
    request.api.post(`/sales_tracker/products/${data?.store_id}_${data.id}/detailed_charts/`,
      encryptedData({page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));
}
export const getDetailedProductInfoNew =  (data) => request.api.get(`/sales_tracker/products/${data?.store_id}_${data.id}/detailed_charts/info/`);

export const getDetailedStoreInfoCheck =  (data) => request.api.post(`/sales_tracker/stores/${data.id}/statistic_exists/`,
  encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));
export const getDetailedProductInfoCheck =  (data) => request.api.post(`/sales_tracker/products/${data?.store_id}_${data.id}/statistic_exists/`,
  encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));

export const getProductListByStore =  (data) => request.api.post(`/sales_tracker/products/store/${data.store_id}/`,
  encryptedData({page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));

export const getStoreProductsModal =  (data) => request.api.post(`/sales_tracker/products/store/${data.store_id}/`,
  encryptedData({popup: true, page: data.page, page_size: data.page_size, ordering: data.ordering, date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));

export const getExpandableChart = (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/chart/${data.store_id}/${data.product_id}/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}))
    :
    request.api.post(`/sales_tracker/products/chart/${data.store_id}/${data.product_id}/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));
}

export const getSalesReport = (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/stores/${data.id}/store_report/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'})
    :
    request.api.post(`/sales_tracker/stores/${data.id}/store_report/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'});
}
export const getProductReport = (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/${data.id}/download_report/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'})
    :
    request.api.post(`/sales_tracker/products/${data.id}/download_report/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'});
}
export const getRevenueReport = (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/stores/${data.id}/download_report/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'})
    :
    request.api.post(`/sales_tracker/stores/${data.id}/download_report/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}), {responseType: 'blob'});
}

export const getTopStoresFilters = () => request.api.get(`/sales_tracker/top_stores/filters/`, null);
export const getTopProductsFilters = () => request.api.get(`/sales_tracker/top_products/filters/`, null);
export const getTopStoresPresets = () => request.api.get(`/sales_tracker/top_stores/filter_presets/`, null);
export const getTopProductsPresets = () => request.api.get(`/sales_tracker/top_products/filter_presets/`, null);
export const getTopStores = (data) => request.api.post(`/sales_tracker/top_stores/`, encryptedData({...data}));
export const getTopProducts = (data) => request.api.post(`/sales_tracker/top_products/`, encryptedData({...data}));
export const getTopStoresCount = (data) => request.api.post(`/sales_tracker/top_stores/count/`, encryptedData({...data}));
export const getTopProductsCount = (data) => request.api.post(`/sales_tracker/top_products/count/`, encryptedData({...data}));

export const getCardsFilters =  (data) => {
  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/store/${data.store_id}/filters/raw_data/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}))
    :
    request.api.post(`/sales_tracker/products/store/${data.store_id}/filters/`,
      encryptedData({date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] }}));
}

export const getCardsList =  (data) => {
  let body = {
    page: data.page,
    domain: data?.domain,
    ordering: data.ordering,
    page_size: data.page_size,
    date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] },
    price: {min: data?.price?.min, max: data?.price?.max},
    revenue: {min: data?.revenue?.min, max: data?.revenue?.max},
    sales: {min: data?.sales?.min, max: data?.sales?.max},
    created_at: {min: data?.created_at?.min?.split('T')?.[0], max: data?.created_at?.max?.split('T')?.[0]},
  };

  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/store/${data.store_id}/cards/raw_data/`, encryptedData(body))
    :
    request.api.post(`/sales_tracker/products/store/${data.store_id}/cards/`, encryptedData(body));
}

export const getCardsCount = (data) => {
  let body = {
    page: data.page,
    domain: data?.domain,
    ordering: data.ordering,
    page_size: data.page_size,
    date_range: { min: data?.date_range?.min?.split('T')?.[0], max: data?.date_range?.max?.split('T')?.[0] },
    price: {min: data?.price?.min, max: data?.price?.max},
    revenue: {min: data?.revenue?.min, max: data?.revenue?.max},
    sales: {min: data?.sales?.min, max: data?.sales?.max},
    created_at: {min: data?.created_at?.min?.split('T')?.[0], max: data?.created_at?.max?.split('T')?.[0]},
  };

  return data?.raw_data ?
    request.api.post(`/sales_tracker/products/store/${data.store_id}/count/raw_data/`, encryptedData(body))
    :
    request.api.post(`/sales_tracker/products/store/${data.store_id}/count/`, encryptedData(body));
}
