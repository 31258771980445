import React from 'react';
import {Row, Col, Divider} from 'antd';
import MainBlockSkeleton from "./MainBlockSkeleton";
import SalesTrackerNoteSkeleton from "./SalesTrackerNoteSkeleton";
import MainBlockNewSkeleton from "./MainBlockNewSkeleton";
import '../styles.less'

export const StoreTrackerPage = ({isMobile=false}) => {

  return (
    <div style={{padding: 0}} className="product-database-page sales-tracker-page store-tracker-skeleton ">
      <span className="link skeleton" style={{width: '100vw', height: 64, marginLeft: -24}}/>

      {
        isMobile ?
          null
          :
          <SalesTrackerNoteSkeleton />
      }

      <MainBlockNewSkeleton isMobile={isMobile}/>

      <MainBlockSkeleton isMobile={isMobile}/>

      <Row gutter={[16, 16]} style={{marginLeft: 'unset', marginRight: 'unset'}}>
        <Col span={24} className='chart-block' style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 16}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <span className="link skeleton" style={{width: 108, height: 24, marginRight: 16}}/>
              <span className="link skeleton" style={{display: isMobile ? 'none' : 'inline-block', width: 186, height: 20}}/>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <span className="link skeleton" style={{width: 83, height: 16, marginRight: 8}}/>
              <span className="link skeleton" style={{width: 16, height: 16}}/>
            </div>
          </div>
          <div style={{marginTop: 8}}>
            <span className="link skeleton" style={{width: 142, height: 40}}/>
          </div>
          <div style={{marginTop: 16}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8}}>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              </div>
              <div style={{width: 'calc(100% - 39px)', height: 227}}>
                <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
              </div>
            </div>
            <div style={{width: 'calc(100% - 39px)', marginLeft: 39, display: "flex", justifyContent: "space-between"}}>
              {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
                <span key={el * index} className="link skeleton" style={{width: el, height: 16}}/>
              ))}
            </div>
          </div>
        </Col>

        <Col span={isMobile ? 24 : null} md={isMobile ? null : 24} xl={isMobile ? null : 12} className='chart-block'
             style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 16, maxWidth: isMobile ? '100%' : 'calc(50% - 12px)', marginRight: isMobile ? 0 : 24}}
        >
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              <span className="link skeleton" style={{width: 84, height: 24}}/>
            </div>
            <div style={{display: "flex", alignItems:"center"}}>
              <span className="link skeleton" style={{width: 83, height: 16, marginRight: 8}}/>
              <span className="link skeleton" style={{width: 16, height: 16}}/>
            </div>
          </div>
          <div style={{marginTop: 16}}>
            <span className="link skeleton" style={{width: 46, height: 24}}/>
          </div>
          <Divider type={'horizontal'} style={{margin: '24px 0 16px'}} />
          <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8}}>
                <span className="link skeleton" style={{width: 16, height: 16}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
              </div>
              <div style={{width: isMobile ? 'calc(100% - 39px)' : 'calc(100% - 24px)', height: isMobile ? 227 : 99}}>
                <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
              </div>
            </div>
            <div style={{width: isMobile ? 'calc(100% - 39px)' : 'calc(100% - 24px)', marginLeft: isMobile ? 39 : 24, display: "flex", justifyContent: "space-between"}}>
              {[38, 39, 39, 41, 40, 40, 32, 32, 32, 38, 37].map((el, index) => (
                <span key={(index + 1) * el*2} className="link skeleton" style={{width: el, height: 16}}/>
              ))}
            </div>
          </div>
        </Col>

        <Col span={isMobile ? 24 : null} md={isMobile ? null : 24} xl={isMobile ? null : 12} className='chart-block'
             style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 16, maxWidth: isMobile ? '100%' : 'calc(50% - 12px)'}}
        >
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              <span className="link skeleton" style={{width: 208, height: 24}}/>
            </div>
            <div style={{display: "flex", alignItems:"center"}}>
              <span className="link skeleton" style={{width: 83, height: 16, marginRight: 8}}/>
              <span className="link skeleton" style={{width: 16, height: 16}}/>
            </div>
          </div>
          <div style={{marginTop: 16}}>
            <span className="link skeleton" style={{width: 74, height: 24}}/>
          </div>
          <Divider type={'horizontal'} style={{margin: '24px 0 16px'}} />
          <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8}}>
                <span className="link skeleton" style={{width: 16, height: 16}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 16, height: 16, marginTop: 12}}/>
              </div>
              <div style={{width: isMobile ? 'calc(100% - 39px)' : 'calc(100% - 24px)', height: isMobile ? 227 : 99}}>
                <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
              </div>
            </div>
            <div style={{width: isMobile ? 'calc(100% - 39px)' : 'calc(100% - 24px)', marginLeft: isMobile ? 39 : 24, display: "flex", justifyContent: "space-between"}}>
              {[38, 39, 39, 41, 40, 40, 32, 32, 32, 38, 37].map((el, index) => (
                <span key={index * el} className="link skeleton" style={{width: el, height: 16}}/>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StoreTrackerPage;
