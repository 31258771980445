import React from 'react';

const UniversityPageCourseWrapperSkeleton = ({isMobile}) => {
  return (
    <div className="university-page-course-wrapper">
      <span className='link skeleton' style={{width: '35%', maxWidth: 394, borderRadius: '6px 0 0 6px'}}/>
      <div className="university-page-course-info">
        <span className='link skeleton' style={{width: 84, height: 16}}/>
        <span className='link skeleton' style={{width: 312, height: 32, marginTop: 4}}/>
        <span className='link skeleton' style={{width: '100%', height: 40, marginTop: 4}}/>
        <span className='link skeleton' style={{width: isMobile ? '100%' : 148, height: 40, marginTop: 56}}/>
      </div>
    </div>
  );
};

export default UniversityPageCourseWrapperSkeleton;
