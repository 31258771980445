import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cls from 'classname';
import dayjs from 'dayjs';
import Numbers from './numbers';
import { getSuffix, parseTime } from '../../../../Utils/utils';
import {useSelector} from "react-redux";
import ButtonComponent from "../../../../Components/Button";
import './index.less';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

const progressStyle = (value, max) =>
  value >= 1
    ? {
        display: 'block',
        width: `${Math.abs((100 * value) / max)}%`,
      }
    : {};

export const DropComponent = (
  {
    timerEnd,
    drop,
    totalProducts,
    totalDrops,
    max,
    isCanceled,
    category,
}) => {
  const date = dayjs.utc(timerEnd) - dayjs();
  const end = date <= 0 ? 1 : date;
  const theme = useSelector(store => store.nav.theme)

  const [counter, setCounter] = useState(end);
  const [clock, setClock] = useState(parseTime(counter));
  const { t } = useTranslation();

  useEffect(() => {
    let timer;

    setClock(parseTime(counter));

    if (counter) {
      timer = setTimeout(
        () => setCounter(end - 1000 > 0 ? end - 1000 : 1),
        1000,
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [counter, timerEnd]);

  return (
    <div className="drop">
      {
        isCanceled
          ? (
            <div className="drop_info">
              <div className="drop_week_wrap">
                <p className="drop_text">{t('portfolio.status')}</p>
                <p className="drop_week">
                  <span className="drop_week_suffix"
                        style={{margin: 0}}
                  >{t('portfolio.canceled')}</span>
                </p>
              </div>
              <div className="drop_tag">
                <ButtonComponent className="tag-drop"
                        href={'/setting/plan'}
                >
                  {t('portfolio.subscribe')}
                </ButtonComponent>
              </div>
            </div>
          )
          : (
            <div className="drop_info">
              <div className="drop_week_wrap">
                <p className="drop_text">{t('portfolio.up_next')}</p>
                <p className="drop_week">
                  <span className="drop_week_num">{drop.week}</span>
                  <span className="drop_week_suffix">{getSuffix(drop.week)}</span>
                  <span className="drop_week_text">{t('portfolio.drop')}</span>
                </p>
              </div>
              <div className="drop_tag">
                <div className={theme === 'dark' ? "tag-drop tag-drop-dark" : "tag-drop"}>
                  <span className="tag-drop_month">{drop.month}</span>
                  <span className="tag-drop_year">{drop.year}</span>
                </div>
              </div>
            </div>
          )
      }
      <div className="drop_divider" />
      <div className="drop_timer">
        <div className="timer">
          <div className="timer_days">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.days.toString()}/>}
            </div>
            <div className="timer_text">{t('portfolio.days')}</div>
            <div className="timer_progress">
              <div
                className={cls('timer_progress-inner', {
                  'timer_progress-inner--animated active': isCanceled
                })}
                style={progressStyle(clock.days, max)}
              />
            </div>
          </div>
          <div className="timer_hours">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.hours.toString()}/>}
            </div>
            <div className="timer_text">{t('portfolio.hours')}</div>
            <div className="timer_progress">
              <div
                className={cls('timer_progress-inner', {
                  'timer_progress-inner--animated active': isCanceled
                })}
                style={progressStyle(clock.hours, 24)}
              />
            </div>
          </div>
          <div className="timer_min">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.min.toString()}/>}
            </div>
            <div className="timer_text">{t('portfolio.min')}</div>
            <div className="timer_progress">
              <div
                className={cls('timer_progress-inner', {
                  'timer_progress-inner--animated active': isCanceled
                })}
                style={progressStyle(clock.min, 60)}
              />
            </div>
          </div>
          <div className="timer_sec">
            <div className="timer_num">
              {isCanceled ? '-' : <Numbers str={clock.sec.toString()}/>}
            </div>
            <div className="timer_text">{t('portfolio.sec')}</div>
            <div className="timer_progress">
              <div
                className={cls('timer_progress-inner', {
                  'timer_progress-inner--animated': clock.sec === '00',
                  active: isCanceled
                })}
                style={progressStyle(clock.sec, 60)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="drop_divider drop_divider--last-child" />
      <div className="drop_data">
        <div className="drop_row">
          <div className="drop_strong">{t('portfolio.summary')}</div>
          <Link
            to={{
              pathname: `/portfolio/product-list/?category=${category?.id}`
            }}
            state={{
            name: `All Products`,
            link: `/portfolio/product-list/?category=${category?.id}`,
          }}
            className="drop_link"
          >
            {t('View All')}
          </Link>
        </div>
        <div className="drop_row">
          <div className="">{t('portfolio.total_products')}</div>
          <div className="">{totalProducts}</div>
        </div>
        <div className="drop_row">
          <div className="">{t('portfolio.total_drops')}</div>
          <div className="">{totalDrops}</div>
        </div>
      </div>
    </div>
  );
};

DropComponent.propTypes = {
  timerEnd: PropTypes.string.isRequired,
  drop: PropTypes.object.isRequired,
  totalProducts: PropTypes.number.isRequired,
  totalDrops: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default DropComponent;
