import React from 'react';

const AdSpotCare = ({id=1}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint0_linear_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint1_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint2_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint3_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint4_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1854 9.30003C14.0568 8.91431 9.94251 8.91431 9.81394 9.30003C9.68537 9.68574 10.5854 10.2 11.9997 10.2C13.4139 10.2 14.3139 9.68574 14.1854 9.30003Z"
            fill={`url(#paint5_linear_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.42803 6.98652C9.42803 7.88652 8.91374 8.14367 8.14231 8.27224C7.37088 8.40081 6.72803 8.01509 6.72803 6.98652C6.72803 6.21509 7.11374 5.18652 8.14231 5.18652C9.04231 5.18652 9.42803 6.21509 9.42803 6.98652Z"
            fill={`url(#paint6_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M8.01516 5.69994C8.14374 5.82852 8.14374 6.21423 7.88659 6.3428C7.75802 6.47137 7.50088 6.59994 7.37231 6.3428C7.24374 6.08566 7.24374 5.95709 7.37231 5.69994C7.62945 5.57137 7.88659 5.57137 8.01516 5.69994Z"
            fill="#4E506A"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.5713 6.98652C14.5713 7.88652 15.0856 8.14367 15.9856 8.27224C16.757 8.40081 17.3999 8.01509 17.3999 6.98652C17.3999 6.21509 17.0141 5.18652 15.9856 5.18652C14.957 5.18652 14.5713 6.21509 14.5713 6.98652Z"
            fill={`url(#paint7_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.8568 5.70067C15.9853 5.82924 15.8568 6.08638 15.7282 6.34353C15.5996 6.4721 15.3425 6.4721 15.2139 6.34353C15.0853 6.21496 15.2139 5.95781 15.3425 5.70067C15.5996 5.44353 15.7282 5.44353 15.8568 5.70067Z"
            fill="#4E506A"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.08495 4.4152C5.82781 4.67234 6.08495 5.05805 6.47067 4.92948C7.2421 4.54377 8.78495 4.15805 10.0707 4.28663C10.4564 4.28663 10.585 4.28663 10.4564 3.77234C10.4564 3.38663 9.9421 3.12948 8.91352 3.25805C7.37067 3.38663 6.3421 4.15805 6.08495 4.4152Z"
            fill={`url(#paint8_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.0862 3.25805C14.0576 3.12948 13.5433 3.38663 13.5433 3.77234C13.4148 4.28663 13.5433 4.28663 13.929 4.28663C15.3433 4.15805 16.8862 4.54377 17.529 4.92948C18.0433 5.18663 18.1719 4.67234 17.9148 4.4152C17.6576 4.15805 16.629 3.38663 15.0862 3.25805Z"
            fill={`url(#paint9_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint10_linear_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint11_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint12_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint13_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint14_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint15_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.1859 12.6432C11.4859 11.8718 10.5859 13.6718 10.5859 13.6718C10.5859 13.6718 10.8431 11.6146 8.14309 10.7146C5.57166 9.94318 4.0288 12.386 3.90023 13.8003C3.64309 17.0146 6.47166 20.6146 7.50023 21.9003C7.6288 22.286 8.01452 22.286 8.40023 22.286C9.94309 21.9003 14.3145 20.486 15.9859 17.6575C16.6288 16.2432 16.7574 13.4146 14.1859 12.6432Z"
            fill={`url(#paint16_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.3856 11.614C2.74274 10.9711 1.5856 10.5854 1.45702 12.1283C1.32845 13.2854 1.84274 15.3426 3.25702 16.4997C6.85702 19.3283 10.0713 17.7854 10.1999 15.7283C10.3285 14.1854 8.39988 14.314 7.8856 14.314C7.8856 14.314 7.8856 14.314 7.8856 14.1854C8.01417 14.0569 8.27131 13.9283 8.39988 13.7997C8.91417 13.414 8.65702 12.7711 8.01417 12.8997C7.8856 12.8997 6.34274 13.414 5.31417 13.0283C4.2856 12.6426 4.15702 12.2569 3.3856 11.614Z"
            fill={`url(#paint17_radial_36566_241356_${id})`}/>
      <path
        d="M20.0999 11.7431C20.4856 10.9716 21.1285 11.2288 21.5142 11.3574C22.0285 11.4859 22.4142 11.8716 22.4142 12.6431C22.4142 14.5716 22.1571 16.3716 20.4856 18.1716C17.5285 21.5145 12.5142 21.0002 11.9999 18.9431C11.6142 17.4002 13.4142 17.1431 14.057 17.1431V17.0145C13.7999 16.8859 13.6713 16.7574 13.4142 16.6288C12.8999 16.2431 13.0285 15.4716 13.6713 15.6002C14.4428 15.7288 15.4713 15.9859 16.2428 15.8574C18.6856 15.6002 19.0713 13.6716 20.0999 11.7431Z"
        fill={`url(#paint18_radial_36566_241356_${id})`}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M22.2858 11.9996C22.2858 17.6567 17.6572 22.2853 12.0001 22.2853C6.34293 22.2853 1.71436 17.6567 1.71436 11.9996C1.71436 6.34244 6.34293 1.71387 12.0001 1.71387C17.6572 1.71387 22.2858 6.34244 22.2858 11.9996Z"
            fill={`url(#paint19_radial_36566_241356_${id})`}/>
      <defs>
        <linearGradient id={`paint0_linear_36566_241356_${id}`} x1="11.9898" y1="21.267" x2="11.9898" y2="3.24644"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#F28A2D"/>
          <stop offset="1" stopColor="#FDE86F"/>
        </linearGradient>
        <radialGradient id={`paint1_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(11.9898 11.9893) scale(10.2857)">
          <stop stopColor="#F28A2D" stopOpacity="0"/>
          <stop offset="1" stopColor="#F08423" stopOpacity="0.34"/>
        </radialGradient>
        <radialGradient id={`paint2_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(3.93952 12.4118) rotate(24.1484) scale(2.07771 1.60709)">
          <stop stopColor="#F28A2D" stopOpacity="0.5"/>
          <stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint3_radial_36566_241356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(16.9729 16.8514) rotate(-38.243) scale(5.82173 2.118)">
          <stop stopColor="#F28A2D" stopOpacity="0.5"/>
          <stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint4_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(8.26341 4.32356) rotate(-10.3559) scale(2.20115 0.437716)">
          <stop stopColor="#D45F00" stopOpacity="0.15"/>
          <stop offset="1" stopColor="#F28A2D" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id={`paint5_linear_36566_241356_${id}`} x1="11.9993" y1="8.54058" x2="11.9993" y2="10.3713"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#482314"/>
          <stop offset="1" stopColor="#9A4111"/>
        </linearGradient>
        <radialGradient id={`paint6_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(8.13304 7.20656) rotate(-90) scale(2.39095 2.39106)">
          <stop stopColor="#3B446B"/>
          <stop offset="0.688" stopColor="#202340"/>
        </radialGradient>
        <radialGradient id={`paint7_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(15.8657 7.20509) rotate(-90) scale(2.39095 2.39106)">
          <stop stopColor="#3B446B"/>
          <stop offset="0.688" stopColor="#202340"/>
        </radialGradient>
        <radialGradient id={`paint8_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(8.14403 3.9146) rotate(-104.427) scale(0.521307 2.41647)">
          <stop stopColor="#E38200"/>
          <stop offset="1" stopColor="#CD6700"/>
        </radialGradient>
        <radialGradient id={`paint9_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(15.7738 3.68394) rotate(-79.0815) scale(0.549265 3.25957)">
          <stop stopColor="#E38200"/>
          <stop offset="1" stopColor="#CD6700"/>
        </radialGradient>
        <linearGradient id={`paint10_linear_36566_241356_${id}`} x1="10.3974" y1="14.2474" x2="7.78652" y2="22.6586"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#F34462"/>
          <stop offset="1" stopColor="#CC0820"/>
        </linearGradient>
        <radialGradient id={`paint11_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(13.0809 15.7739) rotate(20.3927) scale(3.38517 3.38519)">
          <stop stopColor="#FF7091" stopOpacity="0.7"/>
          <stop offset="1" stopColor="#FE6D8E" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint12_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(7.20226 14.0688) rotate(20.3927) scale(3.38517 3.38519)">
          <stop stopColor="#FF7091" stopOpacity="0.7"/>
          <stop offset="1" stopColor="#FE6D8E" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint13_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(6.8373 16.8377) rotate(101.61) scale(2.90656 4.00235)">
          <stop stopColor="#9C0600"/>
          <stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint14_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(16.3898 17.1338) rotate(30.6331) scale(1.75095 2.19749)">
          <stop stopColor="#9C0600" stopOpacity="0.5"/>
          <stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint15_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(4.35889 13.4319) rotate(-20.1593) scale(1.51749 1.95553)">
          <stop stopColor="#9C0600" stopOpacity="0.5"/>
          <stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint16_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(13.2024 19.6048) rotate(34.0715) scale(2.04278 2.21244)">
          <stop stopColor="#9C0600" stopOpacity="0.999"/>
          <stop offset="1" stopColor="#9C0600" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id={`paint17_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(1.61017 11.3172) rotate(36.5187) scale(8.54807 8.54798)">
          <stop stopColor="#EDA83A"/>
          <stop offset="1" stopColor="#FFDC5E"/>
        </radialGradient>
        <radialGradient id={`paint18_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(21.8408 11.6621) scale(11.3734 11.3734)">
          <stop stopColor="#EDA83A"/>
          <stop offset="1" stopColor="#FFDC5E"/>
        </radialGradient>
        <radialGradient id={`paint19_radial_36566_241356_${id}`} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(15.7176 4.47435) rotate(11.5352) scale(2.18057 0.433644)">
          <stop stopColor="#D45F00" stopOpacity="0.15"/>
          <stop offset="1" stopColor="#D45F00" stopOpacity="0"/>
        </radialGradient>
      </defs>
    </svg>
  );
};

export default AdSpotCare;
