import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin, message } from 'antd';
import cls from 'classname';
import TimerCreators from './reducer';
import { Container } from '../../Components/Blocks';
import DropCard from './components/DropCard';
import ModalComponent from '../../Components/Modal';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import openNotification from '../../Components/Notification';
import DropModal from './components/DropModal';
import './styles.less';

function DropTimesPageAdmin({
  getTimerList,
  timerList,
  isMobile,
  fetching,
  saveFetching,
  timerUpdatePartial,
  resultReset,
  resetError,
  saveResult,
  errors,
}) {
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);
  const [timerData, setTimerData] = useState(null);
  const [timer, setTimer] = useState({
    hour: 0,
    date: 0,
    month: 0,
    year: 0,
  });
  const [startValue, setStartValue] = useState(null);

  useEffect(() => {
    document.title = 'Drop Times - Dropship'
    getTimerList();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (errors?.error) {
      openNotification({
        type: 'warning',
        message: 'Something went wrong, kindly try again',
        style: { minWidth: '716px' },
      });
      resetError();
      handleCancel();
    } else if (errors && !errors?.error) {
      message.error('Server response error', 1);
      message.destroy();
      resetError();
      handleCancel();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [errors]);

  useEffect(() => {
    if (saveResult) {
      openNotification({
        type: 'success',
        message: 'Drop time has been successfully changed',
        style: { minWidth: '716px' },
      });
      handleCancel();
      getTimerList();
      resultReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  const dropTimerList = timerList?.results || [];

  const modalBlock = {
    timer: (
      <DropModal
        timerData={timerData}
        onChange={setTimerData}
        timer={timer}
        setTimer={setTimer}
      />
    ),
    confirm: (
      <div className="modal-text-confirm">
        Are you sure you want this change to be applied?
      </div>
    ),
  };

  function handleChange(data) {
    setVisible(true);
    setModal('timer');
    setTimerData(data);
    setStartValue(data);
  }

  function handleCancel() {
    setModal(null);
    setVisible(false);
  }

  function handleOk() {
    if (modal === 'timer') {
      setModal('confirm');
    } else {
      const newTimer = `${timer.year}-${timer.month}-${timer.day} ${timer.hour}:00:00`;
      timerUpdatePartial({
        id: timerData?.category_timer?.id,
        end: newTimer,
      });
    }
  }
  const btnOK = {
    timer: 'Submit',
    confirm: 'Slide To Confirm',
  };

  function disabledButton() {
    return !(modal === 'timer' && !isDataChanged());

  }
  function isDataChanged() {
    const newTimer = `${timer.year}-${timer.month}-${timer.day} ${timer.hour}:00:00`;
    if (startValue) {
      return startValue?.category_timer?.end === newTimer;
    }
    return false;
  }

  return (
      <Container className="droptimes-admin-page">
        <Spin size="large" spinning={fetching}>
          <div className="drop-timer-list">
            <Row
              gutter={[
                { xs: 16, sm: 16, md: 16 },
                { xs: 16, sm: 16, md: 16 },
              ]}
            >
              {dropTimerList.map((timer) => (
                <Col span={12} key={timer?.id}>
                  <DropCard
                    {...timer}
                    onChange={handleChange}
                    setTimer={setTimer}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Spin>

        <ModalComponent
          width={512}
          title="Change Drop Time"
          isMobile={isMobile}
          handleClose={handleCancel}
          className={cls('drop-time-modal', {
            [modal]: modal,
          })}
          open={visible}
          onCancel={handleCancel}
          destroyOnClose
          confirmLoading={saveFetching}
          footer={footerModal({
            isMobile,
            handleCancel,
            textBtnCancel: 'Cancel',
            handleOk,
            textBtnOk: btnOK[modal],
            isDisabledBtnOk: disabledButton(),
            width: modal === 'timer' ? '354px' : null,
            swipeBtn: modal === 'confirm',
            textSwipeBtn: 'Slide To Confirm',
          })}
        >
          <Spin size="small" spinning={saveFetching}>
            {modalBlock[modal]}
          </Spin>
        </ModalComponent>
      </Container>
  );
}

const mapStateToProps = (state) => ({
  timerList: state.dropTimes.timerList,
  fetching: state.dropTimes.fetching,
  saveFetching: state.dropTimes.saveFetching,
  isMobile: state.nav.isMobile,
  saveResult: state.dropTimes.saveResult,
  errors: state.dropTimes.errors,
});
const mapDispatchToProps = (dispatch) => ({
  getTimerList: () => dispatch(TimerCreators.getTimerListRequest()),
  timerUpdate: (id) => dispatch(TimerCreators.timerUpdateRequest(id)),
  timerUpdatePartial: (data) =>
    dispatch(TimerCreators.timerUpdatePartialRequest(data)),

  resultReset: () => dispatch(TimerCreators.timerSaveResultReset()),
  resetError: () => dispatch(TimerCreators.timerErrorsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropTimesPageAdmin);
