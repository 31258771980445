import React from 'react';
import {useTranslation} from "react-i18next";
import {Spin} from "antd";
import ButtonComponent from "../Button";
import './CancelTrialModal.less';
import Images from "../../Images";
import cls from "classname";
import acc from "accounting";
import utils from "../../Utils/utils";
import DiscountCard from "../DiscountCard";

const CancelTrialModal = (
  {
    isMobile,
    setSubModal,
    user,
    callBack,
    fetching,
    currentSubscription,
    priceType,
    isCurrentPlan,
    discount,
    error,
    discountFetching,
    handleCheckDiscount,
    handleClearError,
    handleClearDiscount,
  }
) => {

  const {t} = useTranslation();
  let discountCash = 0;

  let price = currentSubscription?.plan?.price * utils.multiplyPriceType?.[currentSubscription?.plan?.price_type?.id];
  let billing = isCurrentPlan ? currentSubscription?.plan?.price_type?.value : t(utils.toUpperLatter(priceType));

  if (discount) {
    discountCash = discount.type === 'value' ? discount.value : (price / 100) * discount.value;
  }

  return (
      <Spin spinning={fetching}>
        <div className='cancel-sub-wrapper cancel-trial-wrapper'>
          <div className="cancel-sub-title">
            {t('Confirmation')}
          </div>
          <div className="cancel-sub-text" style={{marginBottom: 16}}>
            <p>
              {t('Skip the trial and pay now to remove all trial limitations')}
            </p>
          </div>

          <div className={cls('plan-card-upcoming')}>
            <div className="plan-info-wrapper">
              <div className="img-wrp">
                <img
                  src={Images[`plan_${currentSubscription?.plan?.plan?.name === 'basic' ? 'free' : currentSubscription?.plan?.plan?.name || currentSubscription?.plan?.name}`]}
                  alt="" className={currentSubscription?.plan?.plan?.name || currentSubscription?.plan?.name}/>
              </div>

              <div className="info-wrp">
                <span
                  className="info-title">{`${currentSubscription?.plan?.plan?.name || currentSubscription?.plan?.name} ${t('Plan')}`}</span>
              </div>
            </div>
            <div className='plan-info-wrapper_only-text'>
              <div className="info-wrp">
                <span className="info-title">{billing}</span>
                <span className="info-text info-text_plan">{t('Billing')}</span>
              </div>

              <div className="info-wrp">
        <span className="info-title">
          {`$${acc.formatNumber(discount ? (price - discountCash) : currentSubscription?.next_payment_amount / 100, discount ? 2 : (currentSubscription?.next_payment_amount / 100) === Math.floor(currentSubscription?.next_payment_amount / 100) ? 0 : 2, ',', ',')}`}
        </span>
                <span className="info-text">{t('Amount')}</span>
              </div>
            </div>
          </div>

          <DiscountCard
            key={'discount'}
            onSubmit={handleCheckDiscount}
            error={error}
            clearError={handleClearError}
            discount={discount}
            clearDiscount={handleClearDiscount}
            fetching={discountFetching}
            style={{padding: 0, marginTop: 16}}
            isMobile={isMobile}
            priceType={priceType}
          />

            <ButtonComponent text={t('Pay Now')}
                             onClick={() => {
                               if (discount) {
                                 if ((price - discountCash) > 0) {
                                   if (user?.card || (
                                     discount?.duration_type === "forever" &&
                                     discount?.type === "percent" &&
                                     +discount?.value === 100
                                   )) {
                                     callBack({discount_code: discount?.id})
                                   } else setSubModal('addCard')
                                 } else callBack({discount_code: discount?.id})
                               } else {
                                 if (currentSubscription?.next_payment_amount > 0) {
                                   if (user?.card || (
                                     discount?.duration_type === "forever" &&
                                     discount?.type === "percent" &&
                                     +discount?.value === 100
                                   )) {
                                     callBack({discount_code: discount?.id})
                                   } else setSubModal('addCard')
                                 } else callBack({discount_code: discount?.id})
                               }
                             }}
                             className='cancel-trial-button'
            />
        </div>
      </Spin>
  );
};

export default CancelTrialModal;
