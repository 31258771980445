import React from 'react'

const EmptyChartLikes = (props) => (
    <svg width="84" height="76" viewBox="0 0 84 76" fill={props.color ? props.color : '#1D79FB'}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M82.308 28.076C83.2425 27.1415 83.2425 25.6265 82.308 24.692C81.3735 23.7575 79.8585 23.7575 78.924 24.692L74.8284 28.7876C73.2663 30.3497 70.7337 30.3497 69.1716 28.7876L65.076 24.692C64.1415 23.7575 62.6265 23.7575 61.692 24.692C60.7575 25.6265 60.7575 27.1415 61.692 28.076L65.7876 32.1716C67.3497 33.7337 67.3497 36.2663 65.7876 37.8284L61.692 41.924C60.7575 42.8585 60.7575 44.3735 61.692 45.308C62.6265 46.2425 64.1415 46.2425 65.076 45.308L69.1716 41.2124C70.7337 39.6503 73.2663 39.6503 74.8284 41.2124L78.924 45.308C79.8585 46.2425 81.3735 46.2425 82.308 45.308C83.2425 44.3735 83.2425 42.8585 82.308 41.924L78.2124 37.8284C76.6503 36.2663 76.6503 33.7337 78.2124 32.1716L82.308 28.076Z"
            fillOpacity="0.5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 63.7537C0 69.9673 5.03709 75.0044 11.2507 75.0044H59.2469L59.9132 74.9848C64.9885 74.6864 69.2711 70.9999 70.2791 65.9601L73.1535 51.5883C73.5247 49.732 72.1048 48 70.2117 48H68.6816C67.2515 48 66.0203 49.0094 65.7398 50.4117L62.9243 64.4892L62.8124 64.9167C62.3155 66.4437 60.8856 67.5039 59.2469 67.5039H25.5013C23.8445 67.5039 22.5013 66.1608 22.5013 64.5039V35.5226L22.5266 35.0881C22.5769 34.6566 22.702 34.236 22.8972 33.8454L35.0332 9.57351L35.2688 9.16779C35.9585 8.13455 37.1248 7.50044 38.3875 7.50044H39.3773L39.7143 7.53065C40.5891 7.68942 41.2524 8.45502 41.2524 9.37555V30.0017L41.273 30.5615C41.5593 34.4425 44.7987 37.5022 48.7528 37.5022H58C59.6569 37.5022 61 36.159 61 34.5022V33.0017C61 31.3449 59.6569 30.0017 58 30.0017H51.7528C50.096 30.0017 48.7528 28.6586 48.7528 27.0017V9.37555L48.7329 8.7591C48.4155 3.86848 44.3481 0 39.3773 0H38.3875L37.6396 0.0247888C33.6746 0.288113 30.1183 2.63187 28.3246 6.21921L17.259 28.3437C16.7507 29.3599 15.712 30.0017 14.5759 30.0017H11.2507C5.03709 30.0017 0 35.0388 0 41.2524V63.7537ZM11.2507 37.5022H12.0009C13.6577 37.5022 15.0009 38.8453 15.0009 40.5022V64.5039C15.0009 66.1608 13.6577 67.5039 12.0009 67.5039H11.2507C9.17947 67.5039 7.50044 65.8249 7.50044 63.7537V41.2524C7.50044 39.1812 9.17947 37.5022 11.2507 37.5022Z"
              fillOpacity="0.5"/>
    </svg>
)

export default EmptyChartLikes
