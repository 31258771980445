import React from 'react';
import {useTranslation} from "react-i18next";
import ChangeCreditCardForm from "../BillingSubPage/ChangeCreditCardForm";
import {Spin} from "antd";
import './AddCardModal.less';

const AddCardModal = (
  {
    fetching,
    paymentMethod,
    billingInfo,
    onCancel,
    onSubmit,
    getCountries,
    countries,
    isMobile,
    theme,
    setChargebeeCardFetching,
    errorSave,
    intent,
  }
) => {

  const {t} = useTranslation();

  return (
    <Spin spinning={fetching}>
      <div className='cancel-sub-wrapper add-card-wrapper'>
        <div className="cancel-sub-title">
          {t('Add Credit Card')}
        </div>
        <ChangeCreditCardForm customClass={'add-card'}
                              paymentMethod={paymentMethod}
                              billingInfo={billingInfo}
                              onCancel={onCancel}
                              onSubmit={onSubmit}
                              getCountries={getCountries}
                              countries={countries}
                              isMobile={isMobile}
                              theme={theme}
                              setChargebeeCardFetching={setChargebeeCardFetching}
                              errorSave={errorSave}
                              intent={intent}
        />
      </div>
    </Spin>
  );
};

export default AddCardModal;
