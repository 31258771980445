import React, {useEffect, useState} from 'react';
import {OverlayScrollbar} from "../../Components/ScrollBar";
import SearchComponent from "../../Components/Search";
import ButtonComponent from "../../Components/Button";
import Icon from "../../Icon";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Creators from "./reducer";
import DataTable from "./components/DataTable";
import {Modal, Spin, Switch} from "antd";
import cls from "classname";
import DeleteModal from "./components/DeleteModal";
import './styles.less';

const AdminStoreManager = (
  {
    searchErrors,
    adminStoreManager,
    resetResults,
    resetSearchErrors,
    getStores,
    deleteStore,
    fetching,
    data,
  }
) => {

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  //state to set modal type
  const [modal, setModal] = useState('');
  //state to show modal
  const [visible, setVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [flag, setFlag] = useState('all');
  const [searchOption, setSearchOption] = useState(false)

  useEffect(() => {
    document.title = `Store Management - Dropship`;
    resetResults();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const handleSearch = e => {
    setSearchValue(e.target.value.trim());
  }

  const searchSubmit = () => getStores({searchValue: searchValue, only_product_db: searchOption});

  const onKeyUp = e => e.keyCode === 13 && searchSubmit();

  const modalBlocksWidth = {
    deleteTracking: 512,
  };

  const modalBlocks = {
    deleteTracking: <DeleteModal setModal={setModal}
                                 record={recordToDelete}
                                 setVisible={setVisible}
                                 deleteStore={deleteStore}
                                 flag={flag}
    />
  }

  return (
    <OverlayScrollbar visibility="hidden" className={cls('',{
      'opened_modal': false
    })}>
      <div className={cls("product-database-page sales-tracker-page store-admin-page", {
        'sales-tracker-page-no_scroll': false
      })}>
        <div className={cls("sales-tracker-search-wrapper", {
          'sales-tracker-search-wrapper-error': searchErrors
        })}
             style={{width: '100%', display: 'flex', alignItems: 'center'}}
        >
          <SearchComponent handleSearch={handleSearch}
                           wrapperStyle={{width: '100%'}}
                           onKeyUp={onKeyUp}
                           value={!adminStoreManager?.lastSuccessfulSearch ? searchValue : ''}
                           onFocusCallback={() => resetSearchErrors()}
                           placeholder={t('Insert Store URL...')}
                           button={<ButtonComponent onClick={searchSubmit}
                                                    className={'sales-tracker-search'}
                                                    text={t('Search')}
                                                    disabled={fetching}
                                                    style={{marginRight: 10}}
                           />}
          />
          {(searchErrors && typeof searchErrors !== 'object') && <p className='sales-tracker-search-error'>
            <Icon type={'alert_triangle_error'} role={'icon'} />
            <span>{searchErrors}</span>
          </p>}
          <Switch checked={searchOption}
                  name={'search_option'}
                  key={'search_option'}
                  onChange={(state) => setSearchOption(state)}
                  checkedChildren={'PD Stores'}
                  unCheckedChildren={'All Stores'}
          />
        </div>

        { fetching && !data?.length ?
          <div style={{height: 'calc(100vh - 96px - 54px)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin spinning={true} />
          </div>
          :
          null
        }

        {data?.length ? <DataTable loading={fetching}
                                   data={data}
                                   setModal={setModal}
                                   setVisible={setVisible}
                                   setRecordToDelete={setRecordToDelete}
                                   setFlag={setFlag}
          />
          :
          null}

      </div>

      <Modal
        className="change-modal custom-modal"
        getContainer={() => document.getElementById('global-wrap')}
        open={visible}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <Spin size="large" spinning={fetching}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>

    </OverlayScrollbar>
  );
};

const mapStateToProps = (state) => ({
  adminStoreManager: state.adminStoreManager,
  searchErrors: state.adminStoreManager.searchErrors,
  fetching: state.adminStoreManager.loading,
  data: state.adminStoreManager.results.results,
});

const mapDispatchToProps = (dispatch) => ({
  resetSearchErrors: () => dispatch(Creators.resetSearchErrors()),
  resetResults: () => dispatch(Creators.resetResults()),
  resetLastSuccessfulSearch: () => dispatch(Creators.resetLastSuccessfulSearch()),
  getStores: (data) => dispatch(Creators.getSearchStoreRequest(data)),
  deleteStore: (data) => dispatch(Creators.deleteStoreRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoreManager);
