import React from 'react';
import { Row, Col, InputNumber } from 'antd';
import Icon from '../../../../Icon';
import InputComponent from "../../../../Components/Input";
import './styles.less';

export default function ProductMainInfo({ product, onChange }) {
  return (
    <div className="product-main-block">
      <Row className="product-main-block_wrapper" gutter={[24, 24]}>
        <Col span={12}>
          <div className="block-with-label">
            <div className="block-with-label_label">
              Title <span className="important">*</span>
            </div>
            <InputComponent
              placeholder="Product Name"
              value={product?.title || ''}
              className="block-with-label_input with-suffix-num"
              suffix={<div>{product?.title?.length}</div>}
              onChange={(e) => onChange({ title: e?.target?.value })}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="block-with-label">
            <div className="block-with-label_label">Source Link</div>
            <InputComponent
              placeholder="Source Link"
              className="block-with-label_input"
              value={product?.initial_url || '-'}
              suffix={
                <a href={product?.initial_url || '#'} target="_blank" rel="noopener noreferrer">
                  {product?.initial_url ? (
                    <Icon type="external_link" role="icon" color="#225aea" />
                  ) : null}
                </a>
              }
              onChange={(e) =>
                onChange({
                  initial_url: e?.target?.value,
                })
              }
              disabled
            />
          </div>
        </Col>
      </Row>
      <Row className="product-main-block_wrapper" gutter={[24, 24]}>
        <Col span={9}>
          <div className="block-with-label">
            <div className="block-with-label_label">
              Cost <span className="important">*</span>
            </div>
            <div className="product-cost-wrapper">
              <InputNumber
                placeholder="Min Cost"
                className="block-with-label_input product-cost without-arrows"
                value={product?.cost_min}
                onChange={(cost_min) => onChange({ cost_min })}
              />
              <span className="cost-divider">—</span>
              <InputNumber
                placeholder="Max Cost"
                value={product?.cost_max}
                className="block-with-label_input product-cost without-arrows"
                onChange={(cost_max) => onChange({ cost_max })}
              />
            </div>
          </div>
        </Col>
        <Col span={5}>
          <div className="block-with-label">
            <div className="block-with-label_label">Shipping</div>
            <InputNumber
              placeholder="Shipping"
              className="block-with-label_input without-arrows"
              value={product?.shipping}
              onChange={(shipping) => onChange({ shipping })}
            />
          </div>
        </Col>
        <Col span={5}>
          <div className="block-with-label">
            <div className="block-with-label_label">
              Selling Price <span className="important">*</span>
            </div>
            <InputNumber
              placeholder="Selling Price"
              value={product?.price}
              className="block-with-label_input without-arrows"
              onChange={(price) => onChange({ price })}
            />
          </div>
        </Col>
        <Col span={5}>
          <div className="block-with-label">
            <div className="block-with-label_label">
              Score <span className="important">*</span>
            </div>
            <InputNumber
              placeholder="Product Score"
              className="block-with-label_input-number"
              value={product?.score}
              min={0}
              disabled
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
