import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cls from "classname";
import Creators from "./reducer";
import BreadcrumbsActions from '../Breadcrumbs/actions';
import ShopifyCreators from "../ShopifyStore/reducer";
import OnboardingActions from '../../Redux/OnboardingRedux';
import AutoDSCreators from "../AutoDS/reducer";
import PlanCreators from "../../Redux/PlanRedux";
import UserCreators from "../User/reducer";
import {Modal, Spin} from "antd";
import openNotification from '../../Components/Notification';
import {OverlayScrollbar} from '../../Components/ScrollBar';
import SearchComponent from "../../Components/Search";
import ButtonComponent from "../../Components/Button";
import SalesSettingsSkeleton from "./components/SalesSettingsSkeleton";
import SalesStorePage from "./SalesStorePage";
import SalesProductsPage from "./SalesProductsPage";
import Icon from "../../Icon";
import DeleteTrackingModal from "./components/DeleteTrackingModal";
import dayjs from 'dayjs';
import FreePlanErrorModal from "./components/FreePlanErrorModal";
import ReactPlayer from "react-player";
import CanNotRemoveTracking from "../ProductDatabasePage/components/CanNotRemoveTracking";
import LimitModal from "./components/LimitModal";
import UntrackableDeleteModal from "./components/UntrackableDeleteModal";
import GatheringDataModal from "./components/GatheringDataModal";
import './styles.less';

export const SalesTrackerPage = (
  {
    isAdmin,
    setView,
    setActiveView,
    fetching,
    errors,
    searchErrors,
    userInfo,
    isMobile,
    theme,
    stores,
    products,
    getStores,
    connectStore,
    connectStoreNew,
    deleteStore,
    getProducts,
    salesTracker,
    connectProduct,
    deleteProduct,
    getStoresNext,
    getProductsNext,
    getExpandableChartProductTable,
    getPlans,
    plansLoading,
    getUserInfo,
    userInfoLoading,
    shopifyStore,
    changeVisibleModalShopify,
    searchFetching,
    resetSearchResults,
    getShopifyStore,
    setChargebeeCardFetching,
    ...props
  }) => {
  const isVisibleModal = shopifyStore?.isVisibleModal;
  let storesNewTab = useRef(false);
  let productsNewTab = useRef(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestionValue, setSearchSuggestionValue] = useState(null);
  const [visibleSearchDropdown, setVisibleSearchDropdown] = useState(false);
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);
  //state to set modal type
  const [modal, setModal] = useState('');
  //state to show modal
  const [visible, setVisible] = useState(false);
  //video modal
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [visibleVideoStores, setVisibleVideoStores] = useState(false);
  const [visibleVideoProducts, setVisibleVideoProducts] = useState(false);
  //state for record to stop tracking
  const [recordToDelete, setRecordToDelete] = useState(null);
  const {t} = useTranslation();
  //current view
  const {view} = useParams();
  const navigate = useNavigate();
  const isCurrentPrem = userInfo?.subscriptions?.[0]?.plan?.plan?.id === 3;

  const showFreePlanError = false;

  let max = new Date(dayjs()).toISOString();
  let min = new Date(dayjs().subtract(30, 'days')).toISOString();

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    getShopifyStore();
    props.getAutoStore();
    setActiveView('overview');
    if (isVisibleModal) changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    if (!['stores', 'products'].includes(view)) navigate('/sales-tracker/stores', {replace: true});
    if(props?.filters?.report_period.min !== min || props?.filters?.report_period.max !== max) props.setFilters({report_period: {min, max}})
    if(props?.period === null || props?.period?.[0]?.id !== 4) props.setPeriod([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}])
    getPlans();
    getUserInfo();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    props.clearBreadCrumbs();
    props.addBreadCrumb({
      name: view === 'stores' ? t('Store Tracker') : t('Product Tracker'),
      link: `/sales-tracker/${view}`,
    });
    if(firsLoading) setFirsLoading(false);

    //reset input errors
    props.resetSearchErrors();
    //reset input default value
    props.resetLastSuccessfulSearch();
    //reset input local value(useRef)
    setSearchValue('');
    setSearchSuggestionValue(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [view])

  useEffect(() => {
    if(isMobile) {
      setVisibleVideo(false)
      setVisibleVideoStores(false)
      setVisibleVideoProducts(false)
    }
  }, [isMobile])

  //reset input value onSuccess
  useEffect(() => {
    props.resetLastSuccessfulSearch();
    setSearchValue('')
    setSearchSuggestionValue(null)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [products?.results?.length, stores?.results?.length])

  useEffect(() => {
    if (showFreePlanError) {
      setModal('freePlanError');
      setVisible(true);
    }
  }, [showFreePlanError])

  const modalBlocks = {
    deleteTracking: <DeleteTrackingModal setModal={setModal}
                                         record={recordToDelete}
                                         setVisible={setVisible}
                                         deleteStore={deleteStore}
                                         deleteProduct={deleteProduct}
                                         view={view}
    />,
    untrackableDelete: <UntrackableDeleteModal setModal={setModal}
                                               record={recordToDelete}
                                               setVisible={setVisible}
                                               deleteStore={deleteStore}
                                               deleteProduct={deleteProduct}
                                               view={view}
    />,
    gatheringData: <GatheringDataModal setModal={setModal}
                                       setVisible={setVisible}
    />,
    freePlanError: <FreePlanErrorModal  setModal={setModal}
                                        setVisible={setVisible}
                                        reset={props.resetFreePlanError}
    />,
    canNotRemoveTracking: <CanNotRemoveTracking />,
    limitModal: <LimitModal data={{
      storeLimit: stores?.limits?.stores?.allowed < 50 ? 50: stores?.limits?.stores?.allowed,
      productLimit: stores?.limits?.products?.allowed < 100 ? 100: stores?.limits?.products?.allowed,
      subscription: userInfo?.subscriptions?.[0]
    }}
                            setVisible={setVisible}
    />
  }

  const modalBlocksWidth = {
    deleteTracking: 512,
    freePlanError: 512,
    canNotRemoveTracking: 512,
    limitModal: 512,
    untrackableDelete: 512,
    gatheringData: 512,
  };

  const handleSearch = e => {
    setSearchValue(e.target.value.trim());
  }

  const searchSubmit = () => {
    setVisibleSearchDropdown(false);
    if (view === 'stores') {
      searchSuggestionValue === null ?
        connectStore(searchValue)
        :
        connectStoreNew({ ...searchSuggestionValue, fullData: true })
    } else {
      connectProduct(searchValue);
    }
    setSearchSuggestionValue(null);
  }

  const onKeyUp = e => isValidUrl() && e.keyCode === 13 && searchSubmit();

  //set current page
  const viewHandler = value => {
    navigate(`/sales-tracker/${value}`);
    setFirsLoading(true);
    setView(value);
  }

  function handleOpenNotAvailable(){
    openNotification({
      type: 'warning',
      message: t('Data is being collected and will be displayed within 24 hours'),
      style: { minWidth: '716px' },
    });
  }

  const handleLimits = key => {
    let tempKey = Object.keys(stores).length > 0 ? stores : products
    return (<span>{{...tempKey}?.limits?.[key]?.used ? {...tempKey}?.limits?.[key]?.used : 0}/{{...tempKey}?.limits?.[key]?.allowed ? {...tempKey}?.limits?.[key]?.allowed : <span>&infin;</span>}</span>)
  }

  const isValidUrl = () => {
    // eslint-disable-next-line
    if (view === 'products') return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(searchValue) && searchValue.includes('/products/')
    // eslint-disable-next-line
    else return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(searchValue)
  }

  return (
    //custom scrollbars
    <OverlayScrollbar visibility="hidden" className={cls('',{
      'opened_modal': visibleVideo || visibleVideoStores || visibleVideoProducts
    })}>
      <div className={cls("product-database-page sales-tracker-page", {
        'sales-tracker-page-no_scroll': visibleVideo || visibleVideoStores || visibleVideoProducts
      })}>
        {
          firsLoading || (fetching && !productsNewTab.current && !storesNewTab.current) || plansLoading || userInfoLoading ?
            <>
              <div className="sales-tracker-top-page-header">
                <div className="title-wrapper">
                  <div className="title-wrapper-icon">
                    <span className={'link skeleton'} style={{width: 48, height: 48}}/>
                  </div>
                  <div className="sales-tracker-top-page-subheader">
                    <span className={'link skeleton'} style={{width: 240, height: 32}}/>
                    <span className={'link skeleton'} style={{width: 338, height: 24}}/>
                  </div>
                </div>
                <div className={'sales-tracker-top-page-limits-wrapper'}>
                  <span className={'link skeleton'} style={{width: 138, height: 40}}/>
                  <span className={'link skeleton'} style={{width: 169, height: 40}}/>
                  <span className={'link skeleton'} style={{width: 235, height: 40}}/>
                </div>
              </div>
              <SalesSettingsSkeleton view={view} isMobile={isMobile}/>
            </>
            :
            <>
              <div className="sales-tracker-top-page-header">
                <div className="title-wrapper">
                  <div className="title-wrapper-icon">
                    <Icon type={`sales_tracker_dashboard`} role={'icon'} width={32} height={32}/>
                  </div>
                  <div className="sales-tracker-top-page-subheader">
                    <h3 className="sales-tracker-top-page-title">
                      {t(`Sales Tracker`)}
                    </h3>
                    <h4 className="sales-tracker-top-page-subtitle">
                      {t(`Track sales of stores and products`)}
                    </h4>
                  </div>
                </div>
                <div className={'sales-tracker-top-page-limits-wrapper'}>
                  <div className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => window.Intercom('startTour', 433088)}
                       style={{width: 138}}
                  >
                    <Icon type={'take_tour'} role={'icon'}/>
                    <span>{t('Take Tour')}</span>
                  </div>

                  <div className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => setVisibleVideo(true)}
                  >
                    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                    <span>{t('Watch Tutorial')}</span>
                  </div>
                  <div style={{width: 235}} className={cls("sales-tracker-top-page-watch-tutorial", {
                    'disabled': isTrial
                  })}
                       onClick={() => {
                         if (!isTrial) {
                           setVisible(true);
                           setModal('limitModal');
                         }
                       }}
                  >
                    <Icon type={'increase_limit'} role={'icon'}/>
                    <span>
                    {
                      t(isCurrentPrem &&
                      (stores?.limits?.stores?.allowed !== 50 || stores?.limits?.products?.allowed !== 100) ?
                        'Change Tracking Limit'
                        :
                        'Increase Tracking Limit')
                    }
                  </span>
                  </div>
                </div>
              </div>
              <div className="sales-tracker-settings-wrapper">
                <div className={cls("sales-tracker-search-wrapper", {
                  'sales-tracker-search-wrapper-error': searchErrors
                })}>
                  <SearchComponent handleSearch={handleSearch}
                                   visibleDropdown={visibleSearchDropdown}
                                   setVisibleDropdown={setVisibleSearchDropdown}
                                   onKeyUp={onKeyUp}
                                   gps={true}
                                   withSuggestions={view === 'stores'}
                                   disabled={props.fetchingSpin}
                                   value={!salesTracker?.lastSuccessfulSearch ? searchValue : ''}
                                   setValue={setSearchValue}
                                   setSearchSuggestionValue={setSearchSuggestionValue}
                                   onFocusCallback={() => props.resetSearchErrors()}
                                   placeholder={view === 'stores' ? t('Insert Shopify store URL...') : t('Insert Shopify product URL...')}
                                   button={<ButtonComponent onClick={searchSubmit}
                                                            className={'sales-tracker-search'}
                                                            text={t('Start Tracking')}
                                                            disabled={fetching || props.fetchingSpin || !isValidUrl() || !Boolean(userInfo?.subscriptions?.[0])}
                                   />}
                  />
                  {(searchErrors && typeof searchErrors !== 'object') && <p className='sales-tracker-search-error'>
                    <Icon type={'alert_triangle_error'} role={'icon'} />
                    {searchErrors?.includes('Free trial') ?
                      (<span>Free trial users can track up to 5 Stores and 5 Products. To track more, activate your membership <a href="/setting/plan">here.</a></span>)
                      :
                      (<span>{searchErrors}</span>)
                    }
                  </p>}
                </div>
                <div className="sales-tracker-page-view-wrapper">
                  <div className="sales-tracker-view-button--selected" style={{left: view === 'stores' ? '0' : '50%'}}/>
                  <div className={cls("sales-tracker-view-button", {
                    'sales-tracker-view-button-active': view === 'stores'
                  })}
                       onClick={() => {
                         if (view === 'products') viewHandler('stores')
                       }}
                  >
              <span>
                {t('Stores')}
              </span>
                    <div className="sales-tracker-view-button-info">
                      {handleLimits('stores')}
                    </div>
                  </div>
                  <div className={cls("sales-tracker-view-button", {
                    'sales-tracker-view-button-active': view === 'products'
                  })}
                       onClick={() => {
                         if (view === 'stores') viewHandler('products')
                       }}
                  >
              <span>
                {t('Products')}
              </span>
                    <div className="sales-tracker-view-button-info">
                      {handleLimits('products')}
                    </div>
                  </div>
                </div>
              </div>
            </>
        }

        {
            view === 'stores' ?
              <SalesStorePage isMobile={isMobile}
                              theme={theme}
                              fetching={fetching || plansLoading || userInfoLoading}
                              spinner={props.fetchingSpin}
                              skeleton={firsLoading}
                              setRecordToDelete={setRecordToDelete}
                              setVisible={setVisible}
                              setModal={setModal}
                              getStores={getStores}
                              getStoresNext={getStoresNext}
                              handleOpenNotAvailable={handleOpenNotAvailable}
                              setView={setView}
                              visibleVideo={visibleVideoStores}
                              setVisibleVideo={setVisibleVideoStores}
                              isTrial={isTrial}
                              storesNewTab={storesNewTab}
                              searchFetching={searchFetching}
                              resetSearchResults={resetSearchResults}
              />
              :
              <SalesProductsPage isMobile={isMobile}
                                 theme={theme}
                                 fetching={fetching || plansLoading || userInfoLoading}
                                 spinner={props.fetchingSpin}
                                 skeleton={firsLoading}
                                 setRecordToDelete={setRecordToDelete}
                                 setVisible={setVisible}
                                 setModal={setModal}
                                 getProducts={getProducts}
                                 handleOpenNotAvailable={handleOpenNotAvailable}
                                 getProductsNext={getProductsNext}
                                 visibleVideo={visibleVideoProducts}
                                 setVisibleVideo={setVisibleVideoProducts}
                                 getExpandableChartProductTable={getExpandableChartProductTable}
                                 isTrial={isTrial}
                                 productsNewTab={productsNewTab}
                                 searchFetching={searchFetching}
                                 resetSearchResults={resetSearchResults}
                                 getShopifyStore={getShopifyStore}
                                 getAutoStore={props.getAutoStore}
              />
        }
        <Modal
          className="change-modal custom-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => {
            setVisible(false);
            props.resetFreePlanError();
            setChargebeeCardFetching(false);
          }}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>
        <Modal
          className="change-modal custom-modal video-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visibleVideo}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
          }
          footer={null}
          closable="true"
          onCancel={() => setVisibleVideo(false)}
          destroyOnClose
        >
          <ReactPlayer controls={true}
                       width={'100%'}
                       height={'100%'}
                       playing={true}
                       url={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
          />
        </Modal>
      </div>
    </OverlayScrollbar>
  );
};

SalesTrackerPage.propTypes = {
  isAdmin: PropTypes.bool,
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  fetching: state.salesTracker.loading,
  searchFetching: state.salesTracker.searchLoading,
  fetchingSpin: state.salesTracker.fetchingSpin,
  errors: state.salesTracker.errors,
  salesTracker: state.salesTracker,
  searchErrors: state.salesTracker.searchErrors,
  products: state.salesTracker.products,
  stores: state.salesTracker.stores,
  filters: state.salesTracker.filters,
  period: state.salesTracker.checkedList,
  plansLoading: state?.plans?.plansFetching,
  userInfoLoading: state?.users?.fetching,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  setView: (data) => dispatch(Creators.setView(data)),
  setActiveView: (data) => dispatch(Creators.setActiveView(data)),
  setFilters: (data) => dispatch(Creators.setFilters(data)),
  setPeriod: (data) => dispatch(Creators.setCheckedList(data)),
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  resetSearchErrors: () => dispatch(Creators.resetSearchErrors()),
  resetFreePlanError: () => dispatch(Creators.resetFreePlanError()),
  resetLastSuccessfulSearch: () => dispatch(Creators.resetLastSuccessfulSearch()),
  getStores: (data) => dispatch(Creators.getTrackingStoresRequest(data)),
  resetSearchResults: () => dispatch(Creators.resetSearchResults()),
  getStoresNext: (data) => dispatch(Creators.getTrackingStoresNextRequest(data)),
  connectStore: (data) => dispatch(Creators.createTrackingStoreRequest(data)),
  connectStoreNew: (data) => dispatch(Creators.createTrackingStoreNewRequest(data)),
  deleteStore: (data) => dispatch(Creators.deleteTrackingStoreByIDRequest(data)),
  getProducts: (data) => dispatch(Creators.getTrackingProductsRequest(data)),
  getProductsNext: (data) => dispatch(Creators.getTrackingProductsNextRequest(data)),
  connectProduct: (data) => dispatch(Creators.createTrackingProductRequest(data)),
  deleteProduct: (data) => dispatch(Creators.deleteTrackingProductByIDRequest(data)),
  getExpandableChartProductTable: (data) => dispatch(Creators.getExpandableChartRequest(data)),
  getPlans: () => dispatch(PlanCreators.plansRequest()),
  getUserInfo: () => dispatch(UserCreators.userRequest()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
  getShopifyStore: () => dispatch(ShopifyCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
  setChargebeeCardFetching: (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value))

});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerPage);
