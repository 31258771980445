import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {Button} from "antd";
import './BannerBlock.less';
import SearchComponent from "../../../Components/Search";

export const ImagesBlock = ({ skeleton, isMobile, setVisible, searchPlaceholder }) => {
  const { t } = useTranslation();

  const theme = useSelector(state => state.nav.theme);

  const renderSkeleton =
        <>
          <div className='info-wrp' style={{display: 'flex', flexDirection: 'column'}}>
            {[
              {width: 269, height: 28, mt: 0},
              {width: 271, height: 16, mt: 8},
              {width: 283, height: 16, mt: 4},
              {width: 253, height: 16, mt: 4},
              {width: 237, height: 16, mt: 4},
              {width: 303, height: 40, mt: 24},
            ]
              .map(el => (
                <span key={el.width * Math.random() * (5 - 1) + 1} className="link skeleton" style={{width: el.width, height: el.height, marginTop: el.mt, marginLeft: 'auto', marginRight: 'auto'}} />
              ))}
          </div>
        </>

  const banner = (
    <>
      <div className='info-wrp'>

        <span className='title'>{t('sales_tracker_banner_title')}</span>
        <p className={'text'}>{t('sales_tracker_banner_text')}</p>

        <Button type='primary'
                className='btn-primary'
                onClick={() => setVisible(true)}
                style={{minWidth: 'auto', padding: '0 24px', maxWidth: 146, maxHeight: 40}}
        >
          {t('Watch Tutorial')}
        </Button>
      </div>
    </>
  )

  return (
    <div className={'sales-tracker-empty-block-wrapper'}>
      {
        isMobile ?
          null
          :
          <SearchComponent handleSearch={() => null}
                           onKeyUp={null}
                           value={null}
                           onFocusCallback={null}
                           placeholder={t(searchPlaceholder)}
                           button={null}
                           disabled={true}
          />
      }
      <div className={cls('sales-tracker-empty-block', {
        dark: theme === 'dark',
        'skeleton-banner': skeleton
      })}>
        {
          isMobile && skeleton ? renderSkeleton : banner
        }
      </div>
    </div>
  );
};

export default ImagesBlock;
