import React from 'react';

const AdSpotChartLike = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M23.2875 10.6875C23.25 10.5375 23.25 10.3875 23.1749 10.2375C22.95 9.45002 22.425 8.77502 21.75 8.43752C21.1125 8.13752 20.4375 8.13752 19.9125 8.13752L15.9375 8.17502L16.5 5.58752C16.6125 5.13752 16.725 4.53752 16.5375 3.93752C16.2375 2.85002 15.075 2.17502 13.9875 2.43752C13.3125 2.62502 12.825 3.07502 12.4875 3.41252C12.3 3.60002 12 3.90002 11.625 4.23752C8.09995 7.68752 7.42495 8.43752 7.23745 8.85002C7.04995 9.33752 7.01245 9.82502 7.01245 10.35V18.2625C7.01245 18.9 7.01245 20.025 7.76245 20.775C8.51245 21.525 9.59995 21.5625 10.275 21.5625C10.3125 21.5625 10.3125 21.5625 10.35 21.5625H17.5125C17.9625 21.5625 18.5625 21.5625 19.125 21.2625C19.9125 20.85 20.25 20.025 20.475 19.5C23.025 13.5375 23.175 12.5625 23.2125 12.225C23.3625 11.6625 23.3624 11.175 23.2875 10.6875ZM21.6 12C21.5625 12.3375 21.1875 13.65 18.975 18.8625C18.7875 19.275 18.6 19.6875 18.375 19.8C18.1875 19.9125 17.85 19.9125 17.55 19.9125H10.3875C9.86245 19.9125 9.26245 19.875 8.99995 19.65C8.77495 19.3875 8.73745 18.8625 8.73745 18.3375V10.425C8.73745 10.0875 8.73745 9.82502 8.81245 9.60002C9.18745 9.07502 11.7 6.60002 12.825 5.55002C13.2 5.17502 13.5 4.87502 13.6875 4.68752C13.9125 4.46252 14.175 4.20002 14.4 4.12502C14.5875 4.08752 14.8875 4.23752 14.925 4.42502C15 4.65002 14.925 4.95002 14.85 5.25002L14.0625 8.85002C14.025 9.11252 14.0625 9.37502 14.25 9.56252C14.4 9.75002 14.6625 9.86252 14.925 9.86252L19.9875 9.82502C20.4 9.82502 20.775 9.82502 21.075 9.97502C21.3375 10.0875 21.525 10.3875 21.6375 10.725C21.675 10.8 21.675 10.875 21.675 10.95C21.675 11.25 21.675 11.5875 21.6 12Z"
        fill="#225AEA"/>
      <path
        d="M3.90005 9.0752H2.77505C1.61255 9.0752 0.675049 10.0127 0.675049 11.1752V19.5002C0.675049 20.6627 1.61255 21.6002 2.77505 21.6002H3.90005C5.06255 21.6002 6.00005 20.6627 6.00005 19.5002V11.1752C5.96255 10.0127 5.02505 9.0752 3.90005 9.0752ZM4.27505 19.5002C4.27505 19.7252 4.08755 19.9127 3.86255 19.9127H2.73755C2.51255 19.9127 2.32505 19.7252 2.32505 19.5002V11.1752C2.32505 10.9502 2.51255 10.7627 2.73755 10.7627H3.86255C4.08755 10.7627 4.27505 10.9502 4.27505 11.1752V19.5002Z"
        fill="#225AEA"/>
      <path d="M2 10H5L5.5 15.5L5 21H2L1.5 15.5L2 10Z" fill="#225AEA"/>
      <path d="M8.5 8L14.5 3.5L15.5 4L15 9H22L21.5 16L19.5 20.5H8L8.5 8Z" fill="#225AEA"/>
    </svg>
  );
};

export default AdSpotChartLike;
