import React from 'react';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from "../../../Components/Image";
import {columnTitles} from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";
import cls from 'classname';


const CellWrapper = (
  {
    setModal,
    setVisible,
    children,
    style={},
    record,
    setRecordToDelete
  }
) => {
  return (
    record?.scraping_status === 'untrackable' &&
    (!record?.month_revenue && !record?.week_revenue && !record?.yesterday_revenue && !record?.day_revenue) ?
      <div onClick={() => {
        setRecordToDelete({type: 'store', data: {...record}})
        setModal('untrackableDelete');
        setVisible(true);
      }}
           style={{cursor: 'pointer', ...style}}
      >
        {
          children
        }
      </div>
      :
      record?.scraping_status === 'gathering' ?
        <div onClick={() => {
          setModal('gatheringData');
          setVisible(true);
        }}
             style={{cursor: 'pointer', ...style}}
        >
          {
            children
          }
        </div>
        :
        <a href={`/sales-tracker/stores/store/${record?.id}`} style={{...style}}>
          {
            children
          }
        </a>
  )
}

//product database columns
export const getColumns = (
  {
    sortOrder,
    setRecordToDelete,
    setVisible,
    setModal,
    t,
    isTrial,
    isMobile = false,
  }) => (
  [
    {
      title: () => 'Store',
      dataIndex: 'title',
      key: 'name',
      width: isMobile ? 'calc(100vw - 48px)' : 372,
      fixed: isMobile ? false : 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <CellWrapper style={{flex: 'unset'}}
                       setModal={setModal}
                       setVisible={setVisible}
                       record={record}
                       setRecordToDelete={setRecordToDelete}
          >
            <div className={'product-database-table-image sales-store-table-image'}
            >
              {record?.logo || record?.favicon ?
                <img src={record?.logo || record?.favicon}
                     alt="" onError={utils.addDefaultSrc}
                     width="62" height="62"
                />
                :
                <Image src={Images.defaultImg} small={true}/>
              }
            </div>
          </CellWrapper>
            <div className={'product-info-wrapper'}>
              <CellWrapper setModal={setModal}
                           setVisible={setVisible}
                           record={record}
                           setRecordToDelete={setRecordToDelete}
              >
              <p className={'product-name'}>
                {record?.title}
                {record?.is_demo &&
                  <span className="product-name-tag">
                  {t('example')}
                </span>}
                {
                  record?.scraping_status === 'untrackable' ?
                  <span className="product-name-tag untrackable"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setVisible(true);
                          setRecordToDelete({type: 'store', data: {...record}})
                          setModal('untrackableDelete');
                        }}
                  >
                    {
                      t('untrackable')
                    }
                  </span>
                    :
                    record?.scraping_status === 'gathering' ?
                      <span className="product-name-tag gathering">
                        {
                          t('gathering data')
                        }
                      </span>
                      :
                      null
                }
                <span className="product-is-deleted">
                    {record?.is_deleted_from_st ?
                      <Icon role="button"
                            type={`unavailable_store_triangle`}
                            className={`unavailable-store-triangle`}
                            width={18}
                            height={18}
                            titleText={t('This store has been closed or paused.')}
                            tooltipProps={{
                              placement: 'right',
                              trigger: 'hover',
                              destroyTooltipOnHide: true,
                              overlayClassName: cls('details-tooltip', `unavailable-store-triangle-tooltip`),
                              getPopupContainer: () => document.getElementById('product-database-table-body'),
                            }}
                      />
                      :
                      null}
                  </span>
              </p>
              </CellWrapper>
              <p>
                <a href={`https://${record?.custom_domain}`}
                   target={'_blank'}
                   rel="noopener noreferrer"
                >
                  <span className={'product-url'}>
                    {
                      record?.all_domains?.length > 0 && !isMobile ?
                      <>
                        {record?.user_domain ? record?.user_domain : record?.custom_domain}
                        <TooltipColumnIcon record={record} isST={true} />
                      </>
                      :
                      record?.user_domain ? record?.user_domain : record?.custom_domain
                    }
                  </span>
                </a>
              </p>
            </div>
        </div>
      ),
    },
    {
      title: '',
      fixed: isMobile ? false : 'left',
      dataIndex: 'quick_search',
      key: 'suppliers',
      width: 136,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-suppliers">
            {/*{record?.quick_search?.map(el => (*/}
            <a
              key={'facebook'}
              href={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&q=${record?.custom_domain?.includes('www') ? record?.custom_domain?.split('.')?.filter(el => el !== 'www').join('.') : record?.custom_domain}&search_type=keyword_unordered&media_type=all`}
              target="_blank"
              rel="noopener noreferrer"
              className="link with-img"
            >
              <Icon role="button"
                    type={`search_facebook`}
                    className={`facebook-icon`}
                    width={20}
                    height={20}
                    titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>Facebook</span></>}
                    tooltipProps={{
                      placement: 'bottom',
                      trigger: 'hover',
                      destroyTooltipOnHide: true,
                      overlayClassName: cls('details-tooltip', `facebook-tooltip`),
                      getPopupContainer: () => document.getElementById('product-database-table-body'),
                    }}
              />
            </a>
            {/*))}*/}
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales Today'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'day_revenue',
        })
      },
      dataIndex: 'day_revenue',
      key: 'day_revenue',
      width: 207,
      sorter: (a, b) => (a['day_revenue'] || 0) - (b['day_revenue'] || 0),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
          <div className={'product-database-table-cell product-database-table-cell_clickable'}>
            <div className="product-store-info">
              {
                record?.day_revenue ?
                  <p>${acc.format(record?.day_revenue, 2, ',', '.')}</p>
                  :
                  <p>-</p>
              }
              {
                record?.day_sales ?
                  <p>{acc.format(record?.day_sales, 0, ',', '.')} {record?.day_sales === 1 ? t('Sale') : t('Sales')}</p>
                  :
                  <p>-</p>
              }
            </div>
          </div>
        </CellWrapper>
      ),
    },
    {
      title: () => {
        let result = 'Sales Yesterday'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'yesterday_revenue',
        })
      },
      dataIndex: 'yesterday_revenue',
      key: 'yesterday_revenue',
      width: 207,
      sorter: (a, b) => (a['yesterday_revenue'] || 0) - (b['yesterday_revenue'] || 0),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
        <div className={'product-database-table-cell product-database-table-cell_clickable'}>
          <div className="product-store-info">
            {
              record?.yesterday_revenue ?
                <p>${acc.format(record?.yesterday_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.yesterday_sales ?
                <p>{acc.format(record?.yesterday_sales, 0, ',', '.')} {record?.yesterday_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </CellWrapper>
      ),
    },
    {
      title: () => {
        let result = 'Sales 7 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'week_revenue',
        })
      },
      dataIndex: 'week_revenue',
      key: 'week_revenue',
      width: 207,
      sorter: (a, b) => (a['week_revenue'] || 0) - (b['week_revenue'] || 0),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
        <div className={'product-database-table-cell product-database-table-cell_clickable'}>
          <div className="product-store-info">
            {
              record?.week_revenue ?
                <p>${acc.format(record?.week_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.week_sales ?
                <p>{acc.format(record?.week_sales, 0, ',', '.')} {record?.week_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </CellWrapper>
      ),
    },
    {
      title: () => {
        let result = 'Sales 30 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'month_revenue',
        })
      },
      dataIndex: 'month_revenue',
      key: 'month_revenue',
      width: 207,
      sorter: (a, b) => (a['month_revenue'] || 0) - (b['month_revenue'] || 0),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
        <div className={'product-database-table-cell product-database-table-cell_clickable'}>
          <div className="product-store-info">
            {
              record?.month_revenue ?
                <p>${acc.format(record?.month_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.month_sales ?
                <p>{acc.format(record?.month_sales, 0, ',', '.')} {record?.month_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </CellWrapper>
      ),
    },
    {
      title: () => {
        let result = 'Store Info'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'products_count',
        })
      },
      dataIndex: 'products_count',
      key: 'products_count',
      width: 216,
      sorter: (a, b) => (a['products_count'] || 0) - (b['products_count'] || 0),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
        <div className={'product-database-table-cell product-database-table-cell_clickable'}>
          <div className="product-store-info">
            <p>{acc.format(record?.products_count, 0, ',', '.')} Products</p>
            <p style={{textTransform: 'capitalize'}}>{record?.language}</p>
          </div>
        </div>
        </CellWrapper>
      ),
    },
    {
      title: () => {
        let result = 'Store Creation Date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 216,
      sorter: (a, b) => dayjs(a['created_at']).valueOf() - dayjs(b['created_at']).valueOf(),
      render: (text, record) => (
        <CellWrapper style={{flex: 'unset'}}
                     setModal={setModal}
                     setVisible={setVisible}
                     record={record}
                     setRecordToDelete={setRecordToDelete}
        >
        <div className={'product-database-table-cell product-database-table-cell_clickable'}>
          <div className="store-creation-date">
            {
              record?.created_at ? dayjs(record?.created_at).format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
        </CellWrapper>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: isMobile ? 117 : 167,
      sorter: false,
      fixed: 'right',
      render: (text, record) => (
        <div className={'product-database-table-cell sales-tracker-store-table-cell cell-actions'}>
          <div className="product-actions">
            <span className={'product-actions-options'}
                  onClick={() => {
                    setVisible(true);
                    if (isTrial && record?.scraping_status !== 'untrackable') {
                      setModal('canNotRemoveTracking');
                    } else {
                      setRecordToDelete({type: 'store', data: {...record}})
                      setModal('deleteTracking');
                    }
                  }}>
              <Icon type="remove_preset" role="icon" width={24} height={24} color={'#707ba0'}/>
            </span>
            {
              record?.scraping_status === 'untrackable' &&
              (!record?.month_revenue && !record?.week_revenue && !record?.yesterday_revenue && !record?.day_revenue) ?
                <span className={'product-actions-options-btn'} onClick={() => {
                  setVisible(true);
                  setRecordToDelete({type: 'store', data: {...record}})
                  setModal('untrackableDelete');
                }}>
                  {isMobile ? null : t('Insights')}
                  <Icon type="arrow_forward" role="icon"/>
                </span>
                :
                record?.scraping_status === 'gathering' ?
                  <span className={'product-actions-options-btn'} onClick={() => {
                    setVisible(true);
                    setModal('gatheringData');
                  }}>
                    {isMobile ? null : t('Insights')}
                    <Icon type="arrow_forward" role="icon"/>
                  </span>
                  :
                  <a href={`/sales-tracker/stores/store/${record?.id}`} className={'product-actions-options-btn'}>
                    {isMobile ? null : t('Insights')}
                    <Icon type="arrow_forward" role="icon"/>
                  </a>
            }
          </div>
        </div>
      ),
    },
  ]
);
