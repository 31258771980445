import React from 'react';

const ShopifyConnectNewHave = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M25.6298 6.16049C25.6064 5.99902 25.4655 5.91048 25.3482 5.90006C25.2308 5.88964 22.9515 5.85579 22.9515 5.85579C22.9515 5.85579 21.0425 4.00676 20.8547 3.81665C20.667 3.62914 20.2993 3.68643 20.1558 3.7281C20.1532 3.7281 19.7985 3.84009 19.1961 4.02499C19.097 3.69946 18.9484 3.301 18.7371 2.89995C18.0591 1.60563 17.0629 0.920706 15.8606 0.918102C15.858 0.918102 15.858 0.918102 15.8554 0.918102C15.7719 0.918102 15.6885 0.925914 15.605 0.933727C15.5685 0.892059 15.5346 0.850391 15.4955 0.808722C14.9713 0.248805 14.3011 -0.022038 13.4952 0.00140041C11.9435 0.0456729 10.397 1.16551 9.14264 3.15516C8.26117 4.55366 7.58833 6.31153 7.39795 7.67356C5.61675 8.22567 4.37017 8.6111 4.34149 8.61891C3.44176 8.90017 3.41307 8.92882 3.29572 9.77521C3.21487 10.4132 0.859932 28.5936 0.859932 28.5936L20.5887 32L29.1401 29.8775C29.1401 29.8775 25.6507 6.32195 25.6298 6.16049ZM18.2077 4.32969C17.7539 4.47032 17.2376 4.62918 16.6769 4.80366C16.6665 4.01978 16.5726 2.92599 16.2049 1.98325C17.3836 2.20461 17.9626 3.53539 18.2077 4.32969ZM15.6442 5.12399C14.6114 5.44431 13.4822 5.79328 12.3504 6.14226C12.6685 4.92606 13.2736 3.71248 14.0142 2.91818C14.2906 2.62129 14.6766 2.29315 15.133 2.10565C15.5633 2.99891 15.6546 4.26719 15.6442 5.12399ZM13.5291 1.03269C13.8942 1.02488 14.1994 1.10561 14.4628 1.27749C14.0429 1.49364 13.6387 1.80616 13.2579 2.21242C12.2721 3.26975 11.5158 4.90784 11.2159 6.49123C10.2771 6.7803 9.35649 7.06677 8.51153 7.3272C9.04354 4.83752 11.1351 1.1004 13.5291 1.03269Z"
            fill="#95BF47" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M25.3482 5.90015C25.2308 5.88973 22.9515 5.85588 22.9515 5.85588C22.9515 5.85588 21.0425 4.00685 20.8548 3.81674C20.7844 3.74642 20.6905 3.70996 20.5888 3.69434V31.9975L29.1375 29.875C29.1375 29.875 25.6481 6.32204 25.6272 6.16057C25.609 5.99911 25.4655 5.91057 25.3482 5.90015Z"
            fill="#5E8E3E" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.8502 10.2906L14.8566 14.0017C14.8566 14.0017 13.7482 13.499 12.4364 13.5798C10.5118 13.7022 10.4909 14.9132 10.5092 15.2179C10.6135 16.8768 14.9843 17.2388 15.2321 21.1269C15.4251 24.1843 13.6074 26.2756 10.989 26.4422C7.8465 26.6402 6.11485 24.7885 6.11485 24.7885L6.77987 21.9603C6.77987 21.9603 8.52195 23.2728 9.91457 23.1843C10.8247 23.127 11.1507 22.3874 11.1168 21.8639C10.9812 19.6998 7.41881 19.8274 7.19453 16.27C7.00415 13.2777 8.97312 10.2437 13.3179 9.97026C14.9948 9.86349 15.8502 10.2906 15.8502 10.2906Z"
            fill="white" />
    </svg>
  );
};

export default ShopifyConnectNewHave;
