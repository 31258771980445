import React from 'react';
import { useNavigate } from 'react-router';
import {Divider} from "antd";
import ButtonComponent from "../../../Components/Button";
import './SkipModal.less';

const SkipModal = ({ setVisible, isMobile }) => {

  const navigate = useNavigate();

  return (
    <div className={'skip-promo-wrapper'}>
      <div className="skip-promo-title">
        Are you sure?
      </div>
      <p className="skip-promo-subtitle">
        Are you sure you want to skip your special offer? This action is irreversible. You will lose this offer forever.
      </p>

      <Divider className={'skip-promo-divider'}
               style={{margin: isMobile ? '16px auto' : '24px auto'}}
      />

      <div className="skip-promo-button-wrapper">
        <ButtonComponent className={'skip-promo-button-cancel'}
                         text={'Cancel'}
                         onClick={() => {
                           setVisible(false);
                         }}
        />
        <ButtonComponent className={'skip-promo-button-apply skip-promo-button-cancel'}
                         text={'Skip offer'}
                         onClick={() => {
                           setVisible(false);
                           navigate('/onboarding/checkout/success')
                         }}
        />
      </div>
    </div>
  );
};

export default SkipModal;
