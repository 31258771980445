import React from 'react';

const AdSpotCalendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9 4C9 3.44772 8.55229 3 8 3C7.44772 3 7 3.44772 7 4V5.00003C6.54097 5.00031 6.14075 5.00314 5.80498 5.03057C5.40963 5.06287 5.01641 5.13419 4.63803 5.32698C4.07355 5.6146 3.6146 6.07354 3.32698 6.63803C3.13419 7.01641 3.06287 7.40963 3.03057 7.80497C2.99997 8.17953 2.99998 8.6343 3 9.16142V9.16146V16.8386V16.8386C2.99998 17.3657 2.99997 17.8205 3.03057 18.195C3.06287 18.5904 3.13419 18.9836 3.32698 19.362C3.6146 19.9265 4.07355 20.3854 4.63803 20.673C5.01641 20.8658 5.40963 20.9371 5.80498 20.9694C6.17951 21 6.63438 21 7.16146 21H7.16148H16.8387H16.8387C17.3658 21 17.8205 21 18.195 20.9694C18.5904 20.9371 18.9836 20.8658 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C20.8658 18.9836 20.9371 18.5904 20.9694 18.195C21 17.8205 21 17.3657 21 16.8386V9.16144C21 8.6343 21 8.17954 20.9694 7.80497C20.9371 7.40963 20.8658 7.01641 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.9836 5.13419 18.5904 5.06287 18.195 5.03057C17.8593 5.00314 17.459 5.00031 17 5.00003V4C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4V5H9V4ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12ZM16 12C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16ZM9 15C9 15.5523 8.55229 16 8 16C7.44772 16 7 15.5523 7 15C7 14.4477 7.44772 14 8 14C8.55229 14 9 14.4477 9 15Z"
            fill="#225AEA"/>
    </svg>
  );
};

export default AdSpotCalendar;
