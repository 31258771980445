import React from 'react';
import cls from 'classnames';
import { Tooltip } from 'antd';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DragableUploadList';

export const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={cls('ant-upload-draggable-list-item', {
        [dropClassName]: isOver,
      })}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

export const fakeImageList = [
  {
    uid: '-1',
    id: '-1',
    name: 'image1.png',
    status: 'done',
    url: require('../../../../Images/icon-beauty-full.png'),
  },
  {
    uid: '-2',
    id: '-2',
    name: 'image2.png',
    status: 'done',
    url: require('../../../../Images/icon-baby-full.png'),
  },
  {
    uid: '-3',
    id: '-3',
    name: 'image3.png',
    status: 'done',
    url: require('../../../../Images/icon-electronics-full.png'),
  },
  {
    uid: '-4',
    id: '-4',
    name: 'image4.png',
    status: 'done',
    url: require('../../../../Images/icon-household-full.png'),
  },
  {
    uid: '-5',
    id: '-5',
    name: 'image4.png',
    status: 'done',
    url: require('../../../../Images/icon-random-full.png'),
  },
  {
    uid: '-6',
    id: '-6',
    name: 'image4.png',
    status: 'done',
    url: require('../../../../Images/icon-pets-full.png'),
  },
];
