import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Creators from "./reducer";
import SuppliersPageSkeleton from "./components/SuppliersPageSkeleton";
import SupplierCard from "./components/SupplierCard";
import Icon from "../../Icon";
import ReactPlayer from "react-player";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import AutoDSBanner from "./components/AutoDSBanner";
import AutoDSCreators from "../AutoDS/reducer";
import './styles.less';

const SuppliersPage = (
  {
    isMobile,
    getSuppliers,
    getAutoStore,
    loading,
    suppliers,
    autoDS
  }
) => {

  const [visible, setVisible] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    document.title = 'Suppliers - Dropship';
    getSuppliers();
    getAutoStore();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  return (
    <div className={'product-database-page suppliers-page'}>
      {
          loading ?
            <SuppliersPageSkeleton />
            :
            <>
              <div className="sales-tracker-top-page-header">
                <div className="title-wrapper">
                  <div className="title-wrapper-icon">
                    <Icon type={`suppliers_dashboard`} role={'icon'} />
                  </div>
                  <div className="sales-tracker-top-page-subheader">
                    <h3 className="sales-tracker-top-page-title">
                      {t(`Suppliers`)}
                    </h3>
                    <h4 className="sales-tracker-top-page-subtitle">
                      {t(`Contact industry-leading suppliers`)}
                    </h4>
                  </div>
                </div>
                <div className={'sales-tracker-top-page-limits-wrapper'}>
                  <div className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => setVisible(true)}
                  >
                    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                    <span>{t('Watch Tutorial')}</span>
                  </div>
                </div>
              </div>

              <AutoDSBanner isConnected={autoDS?.email}/>

              <div className="suppliers-page-cards-wrapper">
                {
                  suppliers.map(el => <SupplierCard info={el} key={el?.id} />)
                }
              </div>
            </>
      }
      <Modal
        className="change-modal custom-modal video-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? {transitionName: ''} : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
        }
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <ReactPlayer controls={true}
                     width={'100%'}
                     height={'100%'}
                     playing={true}
                     url={'https://www.youtube.com/watch?v=_Bk0HiXAFMw'}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  loading: state.suppliersPage.loading,
  suppliers: state.suppliersPage.suppliers,
  autoDS: state.autoDS
})

const mapDispatchToProps = (dispatch) => ({
  getSuppliers: () => dispatch(Creators.getSuppliersRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersPage);
