import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import cls from 'classname';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Card,
  Divider,
  Upload,
  Avatar,
  Select,
  message,
  Modal,
  Spin,
  Statistic,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import openNotification from '../Notification';
import Icon from '../../Icon/Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import ChangePasswordForm from './ChangePasswordForm';
import CreateNewPassword from './CreateNewPassword';
import ChangeEmailForm from './ChangeEmailForm';
import CheckEmail from './CheckEmail';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import './styles-main.less';
import './styles-change-password.less';

dayjs.extend(utc)
dayjs.extend(timezone)

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const { Option } = Select;

const ResendTimer = (
  {
    verifyEmail,
    email,
    setVerifyLastReqTime,
    verifyLastReqTime,
    setVisible,
    handleChangeModal,
    setVerify
  }) => {

  const { Countdown } = Statistic;
  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    if (new Date().getTime() < verifyLastReqTime) handleResetTime();
  }, [verifyLastReqTime]);

  function handleResetTime() {
    setDeadline(verifyLastReqTime);
    setResendFetching(true);
  }
  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  return (
    resendFetching ?
      <span className={'personal-subtitle-tag-text'}>
      <Countdown
        title=""
        value={deadline}
        onFinish={onFinish}
        format="ss"
        prefix={<span>Resend In </span>}
        suffix={<span>Sec</span>}
      />
      </span>
      :
      <span className={'personal-subtitle-tag-text'}
            onClick={() => {
              verifyEmail({new_email: email});
              setVerifyLastReqTime(new Date().getTime() + 30000);
              setVisible(true);
              localStorage.setItem('dropshipVerifyEmail', 'true')
              handleChangeModal('checkEmail');
              setVerify(true)
            }}>
        Verify
      </span>
  );
}

export default function MainInfo({
  saveFetching,
  userInfo,
  updateUser,
  updateAvatar,
  saveResult,
  languages,
  changeLang,
  saveAvatarFetching,
  userSaveResultReset,
  isMobile,
  resetError,
  error,
  changePassword,
  userError,
  userErrorsReset,
  sendCodePassword,
  confirmCodePassword,
  createNewPassword,
  changeEmails,
  verifyEmail,
  setVerifyLastReqTime,
  verifyLastReqTime,
  setPassword,
}) {
  const {
    email,
    first_name,
    last_name,
    created,
    language,
    avatar,
    billing_info,
    password_created,
    email_confirmed,
  } = userInfo;

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [verify, setVerify] = useState(false);

  const { t, i18n } = useTranslation();
  const timeZone = dayjs.tz.guess();
  const data = useRef({
    first_name,
    last_name,
    language,
    billing_info,
  });
  const [field, setField] = useState('');
  const [clearForm, setClearForm] = useState(false);
  const [modal, setModal] = useState('');
  const [saveConfirm, setSaveConfirm] = useState({});
  const [modalWidth, setModalWidth] = useState(438);
  const [showArr, setShowArr] = useState(true);
  const [forgotPasswordModal,setForgotModalPassword] = useState(false);

  const isDisabledTransition = useSelector(state => state.nav.disabledTransition)

  useEffect(() => {
    if (error?.message) {
      openNotification({
        type: 'warning',
        message: error?.message?.detail,
        style: { minWidth: '716px' },
      });
    }
    if(error) resetError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  useEffect(() => {
    if (saveResult && !saveResult?.message) {
      if (field) {
        openNotification({
          type: 'success',
          message: localStorage.getItem('dropshipVerifyEmail') === 'true' && field === 'Email' ?
            'Your Email has been successfully verified'
            :
            t('Your _field_ has been successfully changed', { field }),
          style: { minWidth: '716px' },
        });
        setField('');
        userSaveResultReset();
      }
    }
    if (userError) {
      if (userError?.confirmError?.detail) {
        openNotification({
          type: 'warning',
          message: userError?.confirmError?.detail,
          style: { minWidth: '716px' },
        });
        userErrorsReset();
      } else if (userError?.userPersonalError) {
        openNotification({
          type: 'warning',
          message: t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
        });
        userErrorsReset();
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, userError, field]);

  useEffect(() => {
    if (saveResult?.confirmEmail) {
      changePersonalData('email');
    }
    if (saveResult?.setPassword) {
      openNotification({
        type: 'success',
        message: t('A password has been successfully set!'),
        style: { minWidth: '716px' },
      });
      setVisible(false);
      userSaveResultReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  useEffect(() => {
    if (language !== data.current.language) {
      i18n.changeLanguage(language);
      dayjs.locale(language === 'zh-hans' ? 'zh-cn' : language);
      openNotification({
        type: 'success',
        message: t('Language has been changed'),
        style: { minWidth: '716px' },
      });
      userSaveResultReset();

    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [language]);

  function handleSubmit(current, new_data) {
    if (saveFetching) return
    if (modal === 'changePassword') {
      changePassword({ current, password: new_data });
      changePersonalData('password');
    } else if (modal === 'changeEmail') {
      changeEmails({ password: current, new_email: new_data });
    }
  }

  function handleCreatePasswordSubmit(password) {
    if (password_created) {
      createNewPassword({ password });
      changePersonalData('password');
    } else {
      setPassword({ password });
    }
  }

  function handleClose() {
    setVisible(false);
  }
  function handleChangeModal(name) {
    userErrorsReset();
    userSaveResultReset();
    setModal(name);

    if (name === 'forgotPassword') {
      setForgotModalPassword(true)
      setModalWidth(564);
    } else if (name === 'checkEmail') {
      setModalWidth(564);
    } else {
      setModalWidth(438);
    }
  }
  function handleSaveConfirm(data) {
    setSaveConfirm(data);
  }
  function handleChangeLang(lang) {
    changeLang(lang);
    data.current = { ...data.current, language: '' };
  }

  const fakeRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 500);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('You can upload file only in JPG/ PNG formats'));
      setLoading(false);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('Image must be less than 2 MB'));
      setLoading(false);
      return false;
    }
    return true;
  };

  const optionsLanguages = languages
    ? languages.map((el) => ({
        label: el.name,
        value: el.value,
      }))
    : [];

  const handleChangeImage = (info= null) => {
    if (info === null) {
      setLoading(false);
      updateAvatar({ avatar: { media: '' } });
      return
    }
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        updateAvatar({ avatar: { media: info.file.originFileObj } });
      });
    }
  };
  function handleUpdateUserInfo(params, value) {
    if (
      params === 'phone' &&
      billing_info?.phone !== +data.current.billing_info?.phone &&
      data.current.billing_info?.phone
    ) {
      updateUser({
        billing_info: {
          [params]: data.current.billing_info[params],
         phone_code: { id: data.current.billing_info?.phone_code?.id },
        },
      });
    } else if(
      params === 'phone_code' &&
      billing_info?.phone_code?.id !== +value
    ){
      updateUser({
        billing_info: {
          phone_code: { id: value },
        },
      });
    } else if (
      params === 'first_name' &&
      first_name !== data.current.first_name &&
      data.current.first_name
    ) {
      updateUser({ [params]: data.current[params] });
    } else if (
      params === 'last_name' &&
      last_name !== data.current.last_name &&
      data.current.last_name
    ) {
      updateUser({ [params]: data.current[params] });
    } else {
      return;
    }

    changePersonalData(params);
  }

  function changePersonalData(params) {
    const changedField = {
      first_name: t('First Name'),
      last_name: t('Last Name'),
      phone_code: t('Phone Number'),
      phone: t('Phone Number'),
      password: t('Password'),
      email: t('Email'),
    };
    setField(changedField[params]);
  }

  function handleSelect(value, fieldName) {
    if (fieldName === 'phone_code') {
      data.current = {
        ...data.current,
        billing_info: {
          ...data.current.billing_info,
          [fieldName]: { id: +value.value },
        },
      };
      if (billing_info?.phone) handleUpdateUserInfo('phone_code', +value.value)
    } else if (fieldName === 'phone') {
    data.current ={
        ...data.current,
        billing_info: {
          ...data.current.billing_info,
          [fieldName]: +value.trim().replace(/[^.\d]+/g, ''),
        },
      };
    } else {
      data.current = { ...data.current, [fieldName]: value.trim() };
    }
  }

  function handleSendCodePassword() {
    sendCodePassword(email);
  }
  const defaultAvatar = (
    <Icon type="user_default" isMobile={isMobile} role="icon" width={64} height={64} />
  );

  const changePasswordBlock = (
    <div className="change-result-block">
      <ChangePasswordForm
        onSubmit={handleSubmit}
        onClose={handleClose}
        userError={userError}
        userErrorsReset={userErrorsReset}
        saveResult={saveResult}
        userSaveResultReset={userSaveResultReset}
        clearForm={clearForm}
        onChangeModal={handleChangeModal}
        sendCodePassword={handleSendCodePassword}
        isMobile={isMobile}
        setField={setField}
        handleSendCodePassword={handleSendCodePassword}
        email={email}
      />
    </div>
  );

  const createNewPasswordBlock = (
    <div className="change-result-block create-new">
      <div className="change-password-subtitle subtitle-grey">
        {password_created
          ? t(
              'Your new password must be different from previously used passwords',
            )
          : t('Password')}
      </div>
      <CreateNewPassword
        password_created={password_created}
        onSubmit={handleCreatePasswordSubmit}
        onClose={handleClose}
        userError={userError}
        userErrorsReset={userErrorsReset}
        userSaveResultReset={userSaveResultReset}
        saveResult={saveResult}
        clearForm={clearForm}
        isMobile={isMobile}
      />
    </div>
  );


  const changeEmailBlock = (
    <div className="change-result-block">
      <ChangeEmailForm
        onSubmit={handleSubmit}
        onClose={handleClose}
        userError={userError}
        userErrorsReset={userErrorsReset}
        saveResult={saveResult}
        userSaveResultReset={userSaveResultReset}
        clearForm={clearForm}
        isMobile={isMobile}
        onChangeModal={handleChangeModal}
        onSaveConfirm={handleSaveConfirm}
      />
    </div>
  );

  const checkEmailBlock = (
    <div className="change-result-block confirm-new-email">
      <CheckEmail
        data={saveConfirm}
        isMobile={isMobile}
        changeEmails={changeEmails}
        email={email}
        forgotPasswordModal={forgotPasswordModal}
        handleSendCodePassword={handleSendCodePassword}
        verify={verify}
        verifyEmail={verifyEmail}
      />
    </div>
  );

  const modalBlocks = {
    changePassword: {
      component: changePasswordBlock,
      title: t('Change Password')
    },
    forgotPassword: {
      component: checkEmailBlock,
      title: t('You’ve Got Mail!')
    },
    createNewPassword: {
      component: createNewPasswordBlock,
      title: password_created ? t('Create New Password') : t('Create Password')
    },
    changeEmail: {
      component: changeEmailBlock,
      title: t('Change Email')
    },
    checkEmail: {
      component: checkEmailBlock,
      title: t('You’ve Got Mail!')
    },
  };

  return (
    <Card className="account-top">
      <div className="account-top_header">
        <div className="top-block-header">
          <div className="user-avatar-wrapper">
            {avatar && (
              <div className="edit-block"
                   style={{left: -5, top: 0, zIndex: 3}}
                   onClick={() => handleChangeImage()}
              >
                <Icon role="icon" type="close" special={true} />
              </div>
            )}
            <div style={{width: isMobile ? 48 : 64, height: isMobile ? 48 : 64, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {!saveAvatarFetching && !loading ? (
                <Avatar
                  className="user_avatar"
                  size={isMobile ? 48 : 64}
                  {...(avatar ? { src: avatar } : { icon: defaultAvatar })}
                />
              ) : (
                <LoadingOutlined />
              )}
          </div>
            <Upload
              name="media"
              listType="picture-card"
              className="upload-avatar"
              showUploadList={false}
              customRequest={fakeRequest}
              beforeUpload={beforeUpload}
              onChange={handleChangeImage}
            >
              <div className="edit-block">
                <Icon role="icon" type="edit" />
              </div>
            </Upload>
          </div>
          <div className="user-detailed">
            <div className="user-detailed_fullname">
              {first_name || ''} {last_name || ''}
            </div>
            <div className="user-detailed_member">
              {t('Member since')}{' '}
              {created ? dayjs.utc(created).tz(timeZone).format('DD MMM, YYYY') : ''}
            </div>
          </div>
        </div>
      </div>
      {!isMobile ? <Divider /> : null}
      <Row
        gutter={[{ xs: 0, md: 48 }, { xs: 0 }]}
        className="personal-block"
        id="personal-block"
      >
        <Col
          xs={24}
          md={12}
          className="personal-block_info"
          id="personal-block_info"
        >
          <div className="account-title">{t('Personal Information')}</div>
          <Row
            gutter={[0, 24]}
            className="personal-information personal-full-name"
          >
            <Col span={24} className="personal-information-block">
              <div className="personal-subtitle">{t('First Name')}</div>
              <InputComponent
                defaultValue={data.current.first_name || ''}
                onBlur={() => {
                  handleUpdateUserInfo('first_name');
                }}
                onChange={(e) => handleSelect(e.target.value, 'first_name')}
              />
            </Col>
            <Col span={24} className="personal-information-block">
              <div className="personal-subtitle">{t('Last Name')}</div>
              <InputComponent
                defaultValue={data.current.last_name || ''}
                onBlur={() => {
                  handleUpdateUserInfo('last_name');
                }}
                onChange={(e) => handleSelect(e.target.value, 'last_name')}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} className="personal-block_settings">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="account-title">{t('Account Settings')}</div>
            <Select
              defaultValue={language}
              onChange={(lang) => {
                handleChangeLang(lang);
              }}
              onScroll={(e) => e.stopPropagation()}
              className={cls("account-custom-select select-lang", {
                'disabled-transition': isDisabledTransition
              })}
              popupClassName="account-custom-dropdown lang-dropdown custom-dropdown"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow="true"
              listHeight={144}
              suffixIcon={
                <div
                  className={cls('arrow', {
                    arrow_selected: !showArr,
                  })}
                >
                  <ArrowSelectDown color={!showArr && '#225AEA'} />
                </div>
              }
              onDropdownVisibleChange={() => {
                setShowArr(!showArr);
              }}
            >
              {optionsLanguages.map((el) => (
                <Option value={el.value} key={el.value}>
                  <Icon type="globe_outline" role="icon" />
                  <span className="shot-name">{el.value}</span>
                  <span className="full-name">{el.label}</span>
                </Option>
              ))}
            </Select>
          </div>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <div className="personal-subtitle">
                {t('Email')}
                {
                  email_confirmed ?
                    <span className={'personal-subtitle-tag'}>
                      Verified
                    </span>
                    :
                    <div>
                      <span className={'personal-subtitle-tag personal-subtitle-tag-error'}>
                      Not Verified
                    </span>
                      <ResendTimer verifyEmail={verifyEmail}
                                   email={email}
                                   setVerifyLastReqTime={setVerifyLastReqTime}
                                   verifyLastReqTime={verifyLastReqTime}
                                   setVisible={setVisible}
                                   handleChangeModal={handleChangeModal}
                                   setVerify={setVerify}
                      />
                    </div>
                }
              </div>
              <div className="personal-email">
                <InputComponent placeholder={email} disabled />
                <ButtonComponent
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    localStorage.setItem('dropshipVerifyEmail', 'false')
                    handleChangeModal('changeEmail');
                    setVerify(false)
                  }}
                  className="change-btn"
                >
                  {t('Change')}
                </ButtonComponent>
              </div>
            </Col>
            <Col span={24}>
              <div className="personal-subtitle">{t('Password')}</div>
              <div className="personal-email">
                <InputComponent
                  placeholder={
                    password_created ? '******************' : 'Not Set'
                  }
                  disabled
                />
                <ButtonComponent
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                    handleChangeModal(
                      password_created ? 'changePassword' : 'createNewPassword',
                    );
                    setVerify(false)
                  }}
                  className="change-btn"
                >
                  {password_created ? t('Change') : t('Create')}
                </ButtonComponent>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className="change-modal custom-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? { transitionName: '' } : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalWidth}
        title={modalBlocks[modal]?.title}
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
        afterClose={() => setClearForm(Math.random())}
      >
        <Spin size="large" spinning={saveFetching}>
          {modalBlocks[modal]?.component}
        </Spin>
      </Modal>
    </Card>
  );
}
