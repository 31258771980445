import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute = (
  {
    redirectPath,
    auth,
    children,
    ...props
  }) => {
  let location = useLocation();

  return (
    auth
      ? children
      : <Navigate to={redirectPath}
                  state={{from: location}}
                  replace
      />
  )
};

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  auth: PropTypes.bool,
};

export default PrivateRoute;
