import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import Icon from '../../../Icon';
import InputComponent from '../../../Components/Input';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import utils from '../../../Utils/utils';
import _ from 'lodash';
import './DropdownBlock.less';

const DropdownBlockTest = (
  {
    label,
    iconType,
    id,
    dropdownFilters,
    setDropdownFilters,
    searchPlaceholder,
    data
  }) => {
  const { t } = useTranslation();
  //state toggle show/hide dropdown
  const [visible, setVisible] = useState(false);
  //checked checkboxes
  const [checkedList, setCheckedList] = useState([]);
  //check all checkbox state
  const [checkAll, setCheckAll] = useState(false);
  //search query in dropdown
  const [search, setSearch] = useState('');

  const localData = useRef([]);
  const isDisabledTransition = useSelector(state => state.nav.disabledTransition);

  //check all if no initial value and nothing selected on close
  useEffect(() => {
    if (!visible && !checkedList.length && (!dropdownFilters[id] || !dropdownFilters[id].length)) {
      setCheckedList(data);
      setDropdownFilters(state => ({ ...state, [id]: 'All' }));
      setCheckAll(true);
      localData.current = [...data];
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  //set initial value on load
  useEffect(() => {
    if (dropdownFilters[id] === 'All') {
      setCheckedList(data);
      setCheckAll(true);
    } else if (!dropdownFilters[id]) {
      setCheckedList([]);
      setCheckAll(false);
    } else if (dropdownFilters[id].length) {
      let newList = [];
      for (let i = 0; i < dropdownFilters[id].length; i++) {
        data.forEach(el => {
          if (el.id === dropdownFilters[id][i])
            newList.push(el);
        });
      }
      setCheckedList(newList);
      setCheckAll(dropdownFilters[id].length === data.length);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dropdownFilters]);

  useEffect(() => {
    localData.current = [...data];
  }, [data])

  useEffect(() => {
    if (checkedList.length === data.length) {
      setDropdownFilters(state => ({...state, [id]: 'All'}));
      localData.current = [...data];
    }
    //use only checkbox id in API
    else setDropdownFilters(state => ({ ...state, [id]: checkedList.map(el => el.id) }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkedList])

  const handleVisibleChange = (value) => {
    setVisible(value);
    setSearch('');
  };

  //checkbox click handle
  const clickHandler = (val, e) => {
    e.target.classList.toggle('dropdown-database-list-item--active')
    if (checkedList.some(el => el.id === val.id)) {
      let res = checkedList.filter(el => el.id !== val.id)
      setCheckedList(res)
      setCheckAll(checkedList.length === data.length);
      let newData = _.differenceBy([...data], res, 'id');
      localData.current = [...res, ...newData];
    } else {
      let res = [...checkedList, val]
      setCheckedList(res)
      setCheckAll(checkedList.length === data.length);
      let newData = [...localData.current].filter(el => el.id !== val.id);
      localData.current = [val, ...newData];
    }
  }

  //main checkbox handler
  const allClickHandler = e => {
    let checked;
    if (e.target.classList.contains('dropdown-database-list-item-checkbox')) {
      e.target.classList.toggle('dropdown-database-list-item-checkbox--active');
      checked = e.target.classList.contains('dropdown-database-list-item-checkbox--active')
    } else {
      e.target.classList.toggle('dropdown-database-list-item--active')
      checked = e.target.classList.contains('dropdown-database-list-item--active')
    }
    setCheckedList(checked ? data : []);
    if (checked) setDropdownFilters(state => ({ ...state, [id]: 'All' }));
    else setDropdownFilters(state => ({ ...state, [id]: null }));
    setCheckAll(checked);
    localData.current = [...data];
  }

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  //text to display after closed dropdown
  function getSelectValue() {
    let result = null;

    switch (true) {
      case checkedList.length === 1 && checkedList.length !== data.length:
        result = checkedList[0]['value'] ? checkedList[0]['value'] : checkedList[0]['name'];
        break;
      case checkedList.length === data.length:
        result = t('All');
        break;
      case checkedList.length > 1:
        result = t('Mixed Values');
        break;
      default:
        result = t('Select Option');
        break;
    }

    return result;
  }

  return (
    <div className={cls("select-block", {
      'disabled-transition': isDisabledTransition
    })}>
      <span className="select-block_label">{t(label)}</span>

      <Dropdown
        overlayClassName={'dropdown-database-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottom'}
        dropdownRender={() => (
          <div className="dropdown-database-menu" style={{ maxHeight: 304 }}>
            <InputComponent
              className={'dropdown-search'}
              prefix={<Icon type="database_search" role="icon"/>}
              placeholder={t(searchPlaceholder)}
              value={search}
              onChange={handleChangeSearch}
            />

            <ul className="dropdown-database-list" style={{ maxHeight: 'calc(304px - 64px)' }}>
              {
                //hide main checkbox on search
                search
                  ? null
                  : (
                    <div className={cls("dropdown-database-list-item  test-item", {
                      'dropdown-database-list-item--active': checkAll
                    })}
                         onClick={allClickHandler}
                    >
                      <div className={cls('dropdown-database-list-item-checkbox', {
                        'dropdown-database-list-item-checkbox--active': checkAll
                      })} />
                      {t('All')}
                    </div>)
              }

              {
                !search || data.filter(el => [el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                  ?
                  localData.current.map(el => (
                    <div key={el.id}
                         onClick={(e) => clickHandler(el, e)}
                         className={cls('dropdown-database-list-item  test-item', {
                           'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                           'dropdown-database-list-item--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                         })}>

                      <div className={cls('dropdown-database-list-item-checkbox', {
                        'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el.id === elem.id) || checkAll,
                      })} />

                      {
                        el?.flag
                          ? (
                            <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 30px)' }}>
                              <img src={el?.flag}
                                   className="country-flag"
                                   onError={utils.addDefaultSrc}
                                   alt="country flag"
                              />
                              <div className={'info-wrap'}>
                                <span>{el.code}</span>
                                <span>{el.name}</span>
                              </div>
                            </div>)
                          : <span className={'value'} >{el.value}</span>
                      }
                    </div>
                  ))
                  :
                  <div className="dropdown-database-list-item" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="empty-state_icon-select">
                      <Icon width={24} height={24}
                            role="icon"
                            type="empty_warning"
                            fill={'#707ba0'}
                      />
                    </div>
                    {search &&
                      <div style={{ color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px' }}
                           className="empty-state_wrapper-select">
                        {t('No results found')}
                      </div>}
                  </div>
              }

            </ul>
          </div>
        )}
        trigger={['click']}
        open={visible}
      >
        <div className={cls('dropdown-database', { 'ant-dropdown-open': visible }, id)}>
          <Icon role="icon" type={iconType}/>
          <span className="dropdown-database_value">
            {
              getSelectValue()
            }
          </span>
          <ArrowSelectDown/>
        </div>
      </Dropdown>
    </div>
  );
}

const areEqual = (prev, next) => {
  if (prev?.dropdownFilters?.[prev.id] === 'All') return next?.dropdownFilters?.[next.id] === 'All'
  else return JSON.stringify(prev?.dropdownFilters?.[prev.id]) === JSON.stringify(next?.dropdownFilters?.[next.id])
}

export default React.memo(DropdownBlockTest, areEqual);
