import React from 'react';

const PortfolioButtonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.15985 6.13534C3.82477 6.36346 2 8.33225 2 10.7272V17.7798C2 20.3281 4.06574 22.3938 6.61396 22.3938H17.389C19.9372 22.3938 22.0029 20.3281 22.0029 17.7798V10.7272C22.0029 8.33144 20.1769 6.36213 17.8407 6.13512C17.8267 6.13376 17.8127 6.13246 17.7987 6.13122C17.7823 5.9734 17.7553 5.81871 17.7186 5.66786C17.508 4.80333 16.9749 4.06511 16.2539 3.5879C15.7033 3.22349 15.0431 3.01129 14.3334 3.01129H9.66719C8.95748 3.01129 8.29733 3.2235 7.74673 3.58793C7.02575 4.06513 6.49261 4.80333 6.28203 5.66785C6.24527 5.81877 6.21833 5.97353 6.20195 6.13142C6.18789 6.13267 6.17386 6.13397 6.15985 6.13534ZM17.5079 8.09069H15.7912H8.20944H6.49431L6.37625 8.10114C5.03251 8.22005 3.97741 9.35152 3.97741 10.7272V17.7798C3.97741 19.236 5.15783 20.4164 6.61396 20.4164H17.389C18.8451 20.4164 20.0255 19.236 20.0255 17.7798V10.7272C20.0255 9.35106 18.9697 8.21931 17.6253 8.10102L17.5079 8.09069ZM15.3287 5.36424C15.0633 5.13049 14.7149 4.9887 14.3334 4.9887H9.66719C9.2857 4.9887 8.93732 5.13049 8.67189 5.36425C8.45022 5.55946 8.2864 5.81881 8.20944 6.11328H9.66719H14.3334H15.7912C15.7142 5.81881 15.5504 5.55945 15.3287 5.36424Z"
            fill="#151E3A"/>
      <rect x="11" y="13" width="2" height="3" rx="1" fill="#151E3A"/>
      <path
        d="M3 11L8.01475 12.2537C8.63395 12.4085 9.28986 12.2585 9.78019 11.8498L10.7196 11.067C11.4613 10.4489 12.5387 10.4489 13.2804 11.067L14.2198 11.8498C14.7101 12.2585 15.366 12.4085 15.9853 12.2537L21 11"
        stroke="#151E3A" strokeWidth="2"/>
    </svg>
  );
};

export default PortfolioButtonIcon;
