import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Spin } from 'antd';

import { Container } from '../../Components/Blocks';
import Search from '../../Components/Search';
import {ButtonComponent} from '../../Components/Button';
import Drops from './components/drops';

import CollectionsActions from '../CollectionsPage/reducer';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import PlanCreators from '../../Redux/PlanRedux';

import './styles.less';

function PortfolioPageAdmin({ loading, dropsLoading, result, dispatch, maxProducts, ...props }) {

  let navigate = useNavigate();;

  function handleNavigate(path, value) {
    navigate(path, { ...value });
  }

  useEffect(() => {
    document.title = 'Portfolio - Dropship';
    props.getCategoryDrops();
    dispatch(PlanCreators.plansRequest());
    dispatch(CollectionsActions.removeCollection());
    dispatch(BreadcrumbsActions.clearBreadCrumbs());
    dispatch(
      BreadcrumbsActions.addBreadCrumb({
        name: 'Portfolio',
        link: '/admin/portfolio',
      }),
    );
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const getNextList = () => {
    if (result?.next) {
      dispatch(
        Creators.getNextDropsRequest({
          link: result.next,
        }),
      );
    }
  };

  return (
    <Container className="portfolio-admin-page">
      <Search
        placeholder="Search by Drop ID.. (Disabled)"
        handleSearch={() => {
        }}
        disabled={true}
        button={
          <ButtonComponent
            key="submit"
            type="primary"
            className="btn-primary"
            onClick={() =>
              handleNavigate('/admin/portfolio/product-list/', {
                state: {
                  name: 'All Products',
                  link: '/admin/portfolio/product-list/',
                }
              })
            }
            style={{ minWidth: 224 }}
            text={
              <span className="btn_text">
                  View All Products
                </span>
            }
          />
        }
      />
      <Spin spinning={loading || dropsLoading} size="large">
        <Drops
          drops={result?.results || []}
          next={result?.next || null}
          dropsLoading={dropsLoading}
          maxProducts={maxProducts}
          getNextList={getNextList}
        />
      </Spin>

    </Container>
  );
}

PortfolioPageAdmin.propTypes = {
  loading: PropTypes.bool,
  dropsLoading: PropTypes.bool,
  result: PropTypes.object,
  maxProducts: PropTypes.number,
};

const mapStateToProps = (state) => ({
  loading: state.portfolioAdmin.loading,
  dropsLoading: state.portfolioAdmin.dropsLoading,
  result: state.portfolioAdmin.result,
  maxProducts: state.plan.maxProducts,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCategoryDrops: () => dispatch(Creators.getCategoryDropsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPageAdmin);
