import React, {useState} from 'react';
import {CardComponent, CardCVV, CardExpiry, CardNumber} from "@chargebee/chargebee-js-react-wrapper";
import cls from "classname";
import {Tooltip} from "antd";
import Icon from "../../Icon";

const CardForm = (props) => {

  const {
    cardRef,
    classes,
    styles,
    fonts,
    locale,
    placeholder,
    isDisabledIntent,
    onChange,
    setLockIconColor,
    t,
    isMobile,
    lockIconColor,
    tooltipTitle,
  } = props;

  const [reload, setReload] = useState(false);

  const onReady = el => {
    for (let field of el?.fields) {
      if (field?.mountStatus !== 2) return setReload(true)
    }
  }

  return (
    <CardComponent ref={cardRef}
                   className="fieldset field"
                   styles={styles}
                   classes={classes}
                   locale={locale}
                   placeholder={placeholder}
                   fonts={fonts}
                   icon={false}
                   disabled={true}
                   onReady={onReady}
                   reload={reload}
    >
      <div className="ex1-field">
        <CardNumber className={cls('ex1-input', {disable: isDisabledIntent})}
                    onChange={onChange}
                    onFocus={(e) => {
                      setLockIconColor('#225AEA')
                      onChange(e)
                    }}
                    onBlur={(e) => {
                      setLockIconColor('#707BA0')
                      onChange(e)
                    }}
        />
        <label className="ex1-label">{t('Card Number')}</label>

        <Tooltip placement="topRight"
                 title={t(tooltipTitle['card'])}
                 arrow={true}
                 trigger={isMobile ? 'click' : 'hover'}
                 destroyTooltipOnHide={true}
        >
          <div className={'ex1-field-icon'}>
            <Icon type={'lock'} role={'icon'} color={lockIconColor} inverted={true}/>
          </div>
        </Tooltip>
      </div>

      <div className="ex1-fields">
        <div className="ex1-field">
          <CardExpiry className={cls('ex1-input', {disable: isDisabledIntent})}
                      onChange={onChange}
          />
          <label className="ex1-label">{t('Expiration Date (MM / YY)')}</label>
        </div>

        <div className="ex1-field">
          <CardCVV className={cls('ex1-input', {disable: isDisabledIntent})}
                   onChange={onChange}
          />
          <label className="ex1-label">{t('CVC')}</label>

          <Tooltip placement="topRight"
                   title={t(tooltipTitle['cvv'])}
                   arrow={true}
                   overlayClassName="details-tooltip score"
          >
            <div className={'ex1-field-icon'}>
              <Icon type={'attention_outline'} role={'icon'} width={24} height={24} color={'#707ba0'}/>
            </div>
          </Tooltip>
        </div>

      </div>
    </CardComponent>
  );
};

export default CardForm;
