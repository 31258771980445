import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classname';

import { Col, Row } from 'antd';

import WeekCard from '../weekCard';
import Images from '../../../../Images';

import './styles.less';

const emptyListWeeks = [
  {
    drop_id: null,
    week: 1,
    product_count: '-',
    drop_status: 'Pending',
    drop_date: null,
  },
  {
    drop_id: null,
    week: 2,
    product_count: '-',
    drop_status: 'Pending',
    drop_date: null,
  },
  {
    drop_id: null,
    week: 3,
    product_count: '-',
    drop_status: 'Pending',
    drop_date: null,
  },
  {
    drop_id: null,
    week: 4,
    product_count: '-',
    drop_status: 'Pending',
    drop_date: null,
  },
]

const Drops = ({ drops, maxProducts, next, getNextList, dropsLoading }) => {
  const dropsRef = useRef();

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= (maxTopScroll*0.95) && !dropsLoading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const dropsContent = dropsRef.current;

    if (dropsContent) {
      dropsContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (dropsContent) {
        dropsContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  return (
    <div ref={dropsRef} className="admin-drops_wrapper">
      <div className="admin-drops_wrapper_internal-block">
      {drops.map((value) => (
          <div key={`${value.month}_${value.year}`}
               className="admin-drops-calendar"
          >
            <div className="admin-drops-calendar_header">
              <div className="admin-drops-calendar_date">
                <div className="admin-drops-calendar_month">{value.month}</div>
                <div className="admin-drops-calendar_year">{value.year}</div>
              </div>
              <Link
                to={{
                  pathname: `/admin/portfolio/product-list/`,
                  search: `?month=${value.month}&year=${value.year}`,
                }}
                state={{
                name: `${value.month} ${value.year}`,
                link: `/admin/portfolio/product-list/?month=${value.month}&year=${value.year}`,
              }}
                className={cls('admin-drops-calendar_link', {
                  'calendar_link--disabled': false,
                })}
              >
                View All
              </Link>
            </div>
            <div>
              {
                value.categories.filter(({status})=> status).map(category => (
                  <div key={category.name} style={{ minWidth: 245 }}>
                    <div className={cls('admin-drops-calendar_category', { disabled: !category.status })}>
                      <div className={cls('admin-drops-calendar_category_header')}>
                        <img src={Images[`${category.name}Full${!category.status ? 'Disabled' : ''}`]}
                             className="img"
                             alt=""
                        />
                        <span className="title">{category?.title}</span>
                      </div>
                      <div className={'admin-drops-calendar_category-content-wrapper'}>
                        {
                          (category.status ? category.drops : emptyListWeeks).map((drop) => (
                          <WeekCard
                            key={drop['drop_id'] || drop['week']}
                            drop={drop}
                            link={
                              category.status
                                ? (
                                  { pathname: `/admin/portfolio/product-list/`,
                                    search: `?drop_id=${drop['drop_id']}`,
                                    state: {
                                    name: drop['drop_id'],
                                      link: `/admin/portfolio/product-list/?drop_id=${drop['drop_id']}`,
                                    }
                                  })
                                : null
                            }
                            isEmptyTimer={!category.status}
                            maxProducts={maxProducts}
                          />
                        ))
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ),
      )}
      </div>
    </div>
  );
};

Drops.propTypes = {
  drops: PropTypes.array.isRequired,
  category_timer: PropTypes.object,
  summary: PropTypes.object,
  maxProducts: PropTypes.number,
};

export default Drops;
