import React from 'react';

const DayFreeTrial = ({width, height, color, className, style, ...props}) => (
  <svg width="25"
       height="24"
       viewBox="0 0 25 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18.5 23C21.2614 23 23.5 20.7614 23.5 18C23.5 15.2386 21.2614 13 18.5 13C15.7386 13 13.5 15.2386 13.5 18C13.5 20.7614 15.7386 23 18.5 23ZM19.5 16C19.5 15.4477 19.0523 15 18.5 15C17.9477 15 17.5 15.4477 17.5 16V18C17.5 18.2652 17.6054 18.5196 17.7929 18.7071L18.7929 19.7071C19.1834 20.0976 19.8166 20.0976 20.2071 19.7071C20.5976 19.3166 20.5976 18.6834 20.2071 18.2929L19.5 17.5858V16Z"
          fill="#707BA0"/>
    <path
      d="M15.7413 5H9.2587C8.45374 4.99999 7.78937 4.99998 7.24818 5.04419C6.68608 5.09012 6.16937 5.18868 5.68404 5.43598C4.93139 5.81947 4.31947 6.43139 3.93598 7.18404C3.68868 7.66937 3.59012 8.18608 3.54419 8.74818C3.49998 9.28937 3.49999 9.95372 3.5 10.7587V15.2413C3.49999 16.0463 3.49998 16.7106 3.54419 17.2518C3.59012 17.8139 3.68868 18.3306 3.93598 18.816C4.31947 19.5686 4.93139 20.1805 5.68404 20.564C6.16937 20.8113 6.68608 20.9099 7.24818 20.9558C7.78937 21 8.45373 21 9.2587 21H10.5C11.0523 21 11.5 20.5523 11.5 20C11.5 19.4477 11.0523 19 10.5 19H9.3C8.44342 19 7.86113 18.9992 7.41104 18.9624C6.97262 18.9266 6.74842 18.8617 6.59202 18.782C6.2157 18.5903 5.90973 18.2843 5.71799 17.908C5.6383 17.7516 5.57337 17.5274 5.53755 17.089C5.50078 16.6389 5.5 16.0566 5.5 15.2V10.8C5.5 9.94342 5.50078 9.36113 5.53755 8.91104C5.57337 8.47262 5.6383 8.24842 5.71799 8.09202C5.90973 7.7157 6.2157 7.40973 6.59202 7.21799C6.74842 7.1383 6.97262 7.07337 7.41104 7.03755C7.86113 7.00078 8.44342 7 9.3 7H15.7C16.5566 7 17.1389 7.00078 17.589 7.03755C18.0274 7.07337 18.2516 7.1383 18.408 7.21799C18.7843 7.40973 19.0903 7.7157 19.282 8.09202C19.3617 8.24842 19.4266 8.47262 19.4624 8.91104C19.4992 9.36113 19.5 10 19.5 10C19.5 10.5523 19.9477 11 20.5 11C21.0523 11 21.5 10.5523 21.5 10C21.5 10 21.5 9.28937 21.4558 8.74818C21.4099 8.18608 21.3113 7.66937 21.064 7.18404C20.6805 6.43139 20.0686 5.81947 19.316 5.43598C18.8306 5.18868 18.3139 5.09012 17.7518 5.04419C17.2106 4.99998 16.5463 4.99999 15.7413 5Z"
      fill="#707BA0"/>
    <path
      d="M9.5 4C9.5 3.44772 9.05228 3 8.5 3C7.94772 3 7.5 3.44772 7.5 4V6C7.5 6.55228 7.94772 7 8.5 7C9.05228 7 9.5 6.55228 9.5 6V4Z"
      fill="#707BA0"/>
    <path
      d="M17.5 4C17.5 3.44772 17.0523 3 16.5 3C15.9477 3 15.5 3.44772 15.5 4V6C15.5 6.55228 15.9477 7 16.5 7C17.0523 7 17.5 6.55228 17.5 6V4Z"
      fill="#707BA0"/>
  </svg>
);

export default DayFreeTrial;
