import React from 'react';

const AdSpotPlay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
      <path
        d="M26.1646 7.6406C20.2 3.46122 12 7.72813 12 15.0113V56.9896C12 64.2728 20.2001 68.5397 26.1647 64.3602L56.1891 43.3217C61.2688 39.7622 61.2688 32.238 56.189 28.6786L26.1646 7.6406Z"
        fill="white" fillOpacity="0.9"/>
    </svg>
  );
};

export default AdSpotPlay;
