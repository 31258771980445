import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider } from 'antd';
import ProductDetailsCard from './ProductDetailsCard';
import utils from '../../../Utils/utils'
import './BenefitsBlock.less';

const skeletonList = [1,2,3,4,5,6]

export const BenefitsBlock = ({ skeleton, isMobile, product }) => {

  const { t } = useTranslation();

  const benefitsList = skeleton ? skeletonList : product?.benefits || [];
  const drawbacksList = skeleton ? skeletonList : product?.drawbacks || [];

  const renderHeader = (
    <span className={cls('title', { skeleton: skeleton })}
          style={skeleton ? {width: 259} :{}}
    >
      {skeleton ? null : t('Benefits and Drawbacks')}
    </span>
  )

  const renderCard = (data, className) => (
    <Col xs={24} sm={12} lg={8} key={data?.id || data}>
      <div className={cls('benefit-drawback-card')}>
        <div className={cls('benefit-drawback-card-img-wrap', className, { skeleton: skeleton })}
             dangerouslySetInnerHTML={{ __html: skeleton ? null : data?.icon }}
        />
        <div style={{flex: '1 1'}}>
          <span className={cls('benefit-drawback-card-label', { skeleton: skeleton })}
                style={skeleton ? {width: utils.randomNum(10, 100) + '%'} : {}}
          >
            {skeleton ? null : data?.title}
          </span>
          <span className={cls('benefit-drawback-card-text', { skeleton: skeleton })}
                style={skeleton ? {width: utils.randomNum(10, 100) + '%'} : {}}
          >
            {skeleton ? null : data?.description}
          </span>
        </div>
      </div>
    </Col>
  );

  return (
    <ProductDetailsCard
      header={renderHeader}
      withLine={!isMobile}
    >
      <Row gutter={[{ xs: 0, sm: 0, md: 16 }, 24]}>
        {
          benefitsList.map(el => renderCard(el))
        }
      </Row>

      { drawbacksList.length ? <Divider/> : null }

      <Row gutter={[16, 24]}>
        {
          drawbacksList.map(el => renderCard(el, 'drawback'))
        }
      </Row>
    </ProductDetailsCard>
  );
};

export default BenefitsBlock;
