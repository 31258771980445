import React from 'react';

const CardInfoSkeleton = () => {
  return (
    <div className="fadspot-page-overview-post-wrapper">
      <div className="fadspot-page-overview-post">
        <span className="fadspot-page-item-wrapper link skeleton"/>
      </div>
      <div className="fadspot-page-overview-info-wrapper">
        <div className="fadspot-page-overview-landing fadspot-page-overview-info-block">
          <span className="link skeleton" style={{width: 130, height: 24}}/>
          <span className="link skeleton" style={{width: 197, height: 20}}/>
        </div>
        <div className="fadspot-page-overview-status fadspot-page-overview-info-block">
          <span className="link skeleton" style={{width: 75, height: 24}}/>
          <span className="link skeleton" style={{width: 130, height: 20}} />
          {/*<div>*/}
            {/*<div className="status circle grey" />*/}
            {/*<span className="link skeleton" style={{width: 42, height: 20}}/>*/}
            {/*<div className="circle" />*/}
            {/*<span className="link skeleton" style={{width: 53, height: 20}}/>*/}
          {/*</div>*/}
        </div>
        <div className="fadspot-page-overview-timeline fadspot-page-overview-info-block">
          <span className="link skeleton" style={{width: 91, height: 24}}/>
          <div>
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 93, height: 20}}/>
                <span className="link skeleton" style={{width: 188, height: 20}}/>
              </div>
            </div>
            <div className="badge" style={{width: 56, height: 24}} />
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 76, height: 20}}/>
                <span className="link skeleton" style={{width: 89, height: 20}}/>
              </div>
            </div>
            <div className="badge" style={{width: 56, height: 24}} />
            <div className="item-wrapper">
              <div className="item-image"/>
              <div className={'item-info'}>
                <span className="link skeleton" style={{width: 43, height: 20}}/>
                <span className="link skeleton" style={{width: 89, height: 20}}/>
              </div>
            </div>
          </div>
        </div>
        <div className="fadspot-page-overview-landing-same fadspot-page-overview-info-block">
          <span className="link skeleton" style={{width: 225, height: 24}}/>
          <span className="link skeleton" style={{width: 17, height: 40}}/>
        </div>
      </div>
    </div>
  );
};

export default CardInfoSkeleton;
