import React from 'react';

const AdSpotDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M12 24.4206V22.2224C12 21.7315 11.602 21.3335 11.1111 21.3335H8.02472C7.3349 21.3335 6.82874 21.9818 6.99605 22.651L7.57465 24.9654C7.82463 25.9653 8.72308 26.6668 9.75379 26.6668C10.9943 26.6668 12 25.6612 12 24.4206Z"
        fill="#DF7009"/>
      <path
        d="M24 14.3704C24 13.0613 25.0613 12 26.3704 12H26.6667C28.1394 12 29.3333 13.1939 29.3333 14.6667C29.3333 16.1394 28.1394 17.3333 26.6667 17.3333H26.3704C25.0613 17.3333 24 16.2721 24 14.963V14.3704Z"
        fill="#DF7009"/>
      <path
        d="M8 10.6669C5.79086 10.6669 4 12.4577 4 14.6669C4 16.876 5.79086 18.6669 8 18.6669H11.3333C11.7015 18.6669 12 18.3684 12 18.0002V11.3335C12 10.9653 11.7015 10.6669 11.3333 10.6669H8Z"
        fill="#DF7009"/>
      <path
        d="M14.9967 10.4743C14.7923 10.5939 14.6667 10.8129 14.6667 11.0497V18.2848C14.6667 18.5215 14.7921 18.7404 14.9963 18.86L22.9813 23.5395C25.2034 24.8418 28 23.2393 28 20.6637V8.6787C28 6.10416 25.2056 4.5015 22.9834 5.8016L14.9967 10.4743Z"
        fill="#DF7009"/>
    </svg>
  );
};

export default AdSpotDashboard;
