import React from 'react';

const AdSpotCarousel = (
  {
    color='#151E3A'
  }
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17 9.16146V14.8385C17 15.3657 17 15.8205 16.9694 16.195C16.9371 16.5904 16.8658 16.9836 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.9836 18.8658 14.5904 18.9371 14.195 18.9694C13.8205 19 13.3657 19 12.8385 19H11.1615C10.6343 19 10.1796 19 9.80497 18.9694C9.40963 18.9371 9.01641 18.8658 8.63803 18.673C8.07354 18.3854 7.6146 17.9265 7.32698 17.362C7.13419 16.9836 7.06287 16.5904 7.03057 16.195C6.99997 15.8205 6.99998 15.3657 7 14.8385L7 9.16148C6.99998 8.63432 6.99997 8.17955 7.03057 7.80497C7.06287 7.40963 7.13419 7.01641 7.32698 6.63803C7.6146 6.07354 8.07354 5.6146 8.63803 5.32698C9.01641 5.13419 9.40963 5.06287 9.80497 5.03057C10.1795 4.99997 10.6343 4.99998 11.1615 5L12.8385 5C13.3657 4.99998 13.8205 4.99997 14.195 5.03057C14.5904 5.06287 14.9836 5.13419 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C16.8658 7.01641 16.9371 7.40963 16.9694 7.80497C17 8.17955 17 8.63431 17 9.16146ZM14.9761 7.96784C14.9539 7.69617 14.9162 7.59546 14.891 7.54601C14.7951 7.35785 14.6422 7.20487 14.454 7.109C14.4045 7.0838 14.3038 7.04613 14.0322 7.02393C13.7488 7.00078 13.3766 7 12.8 7H11.2C10.6234 7 10.2512 7.00078 9.96784 7.02393C9.69617 7.04612 9.59545 7.0838 9.54601 7.109C9.35785 7.20487 9.20487 7.35785 9.109 7.54601C9.0838 7.59546 9.04612 7.69617 9.02393 7.96784C9.00078 8.25117 9 8.62345 9 9.2L9 14.8C9 15.3766 9.00078 15.7488 9.02393 16.0322C9.04612 16.3038 9.0838 16.4045 9.10899 16.454C9.20487 16.6422 9.35785 16.7951 9.54601 16.891C9.59545 16.9162 9.69617 16.9539 9.96784 16.9761C10.2512 16.9992 10.6234 17 11.2 17H12.8C13.3766 17 13.7488 16.9992 14.0322 16.9761C14.3038 16.9539 14.4045 16.9162 14.454 16.891C14.6422 16.7951 14.7951 16.6422 14.891 16.454C14.9162 16.4045 14.9539 16.3038 14.9761 16.0322C14.9992 15.7488 15 15.3766 15 14.8V9.2C15 8.62345 14.9992 8.25118 14.9761 7.96784Z"
            fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21C4.55228 21 5 20.5523 5 20V4ZM21 4C21 3.44772 20.5523 3 20 3C19.4477 3 19 3.44772 19 4V20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20V4Z"
            fill={color}/>
    </svg>
  );
};

export default AdSpotCarousel;
