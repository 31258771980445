import apisauce, { CancelToken } from 'apisauce';
import fernet  from 'fernet';

const secret = new fernet.Secret(process.env.REACT_APP_FERNET_SECRET_KEY)

const generateToken = () => new fernet.Token({secret: secret, time: Date.parse(new Date())})

export const encryptedData = (data) => {
  let encrypted = generateToken().encode(JSON.stringify(data))
  return ({encrypted})
}

export class API {
  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_API_ROUTE;

    if (!API.instance) {
      this.create();
      this.createInterceptor();

      API.instance = this;
    }

    return API.instance;
  }

  create() {
    this.api = apisauce.create({
      // base URL is read from the 'constructor'
      baseURL: this.baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      cancelToken: new CancelToken((cancel) => {
        this.source = cancel;
      }),
      // 60 second timeout...
      timeout: 60000,
    });
  }

  cancelAllRequest() {
    API.instance.source();
  }

  createInterceptor() {
    this.interceptors = this.api.axiosInstance.interceptors;
  }
}

const request = new API();

const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const accessData = { accessToken: null };

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
    // 60 second timeout...
    timeout: 60000,
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;

    if (accessToken) {
      api.setHeader('Authorization', `JWT ${accessToken}`);
    } else {
      delete api.headers['Authorization']
    }
  };

  const getCategories = () => request.api.get(`/onboarding/category/`);

  const getCategory = (id) => request.api.get(`/onboarding/category/${id}/`);

  const getPlans = () => request.api.get(`/onboarding/plan/`);

  const getPlan = (id) => request.api.get(`/onboarding/plan/${id}/`);

  const getCountries = () => request.api.get(`/user/country-list/`);

  const getClickFunnelProducts = () => request.api.get(`/payments/onboarding_charges/`);

  const createClickFunnelSession = (data) => request.api.post(`/payments/stores-upsell-checkout/`, encryptedData(data));

  const createStripeSession = (data) => api.post(`/payments/stripe-checkout-session/`, encryptedData(data));

  const changeStripeCard = () => api.post(`/payments/replace_card/`);

  const checkDiscountCode = (code) => api.get(`/discount/check`, { code });

  const saveSubscription = (data) => api.post(`/subscription/`, encryptedData(data));

  const updateSubscription = (data) => api.post(`/subscription/update/`, encryptedData(data));

  const deleteSubscription = (subscriptionId) =>
    api.delete(`/subscription/${subscriptionId}/`);

  const undoDeleteSubscription = ({deletedSubscriptionId, oldSchedule }) =>
    api.post(`/subscription/${deletedSubscriptionId}/`, encryptedData({old_schedule: oldSchedule}));

  const cancelSub = (subscriptionId) =>
    api.delete(`/subscription/manage/${subscriptionId}/`);

  const cancelTrial = (data) =>
    api.post(`/subscription/trial-management/force_activate/`, encryptedData(data));

  const undoCancelSub = (subscriptionId) =>
    api.post(`/subscription/manage/${subscriptionId}/`);

  const undoPauseSub = (subscriptionId) =>
    api.post(`/subscription/pause/${subscriptionId}/`);

  const getQuestionsData = () => api.get('user/question-form/');

  const createIntent = (data) => api.post('/payments/create_intent/', encryptedData(data))

  const saveFrontLogs = (data) => api.post('/frontend_logs/', encryptedData(data))

  const serialize = (obj, prefix) => {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix : p;
        let v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
      }
    }
    return str.join('&');
  };

  const signIn = (email, password, otp_pass=null) => {
    return new Promise((resolve, reject) => {
      api
        .post('auth/token/', encryptedData(otp_pass ?
          {email, password, otp_pass}
        :
            {email, password}
        ))
        .then((response) => {
          if (
            response &&
            response.ok &&
            response.data &&
            response.data.access
          ) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh,
            });
          } else if (!response.ok && response.data) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const checkEmail = (data) => {
    return api.post(`auth/confirm-email/`, encryptedData(data));
  };

  const createAccount = (data) => {
    return api.post(`auth/sign-up/`, encryptedData(data));
  };

  const changeEmail = (data) => {
    return api.post(`auth/change-email/`, encryptedData(data));
  };

  const confirmEmail = (hash) => {
    const query = {
      code: hash,
      auth: true
    };
    return new Promise((resolve, reject) => {
      api
        .get('auth/confirm-email/?' + serialize(query))
        .then((response) => {
          if (
            response &&
            response.ok &&
            response.data &&
            response.data.access
          ) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh,
            });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const confirmEmailInit = (hash) => {
    const query = {
      code: hash,
    };
    return new Promise((resolve, reject) => {
      api
        .get('auth/confirm-email/?' + serialize(query))
        .then((response) => {
          if (
            response &&
            response.ok &&
            response.data &&
            response.data?.code_valid
          ) {
            resolve({
              codeValid: response?.data?.code_valid
            });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const checkPassword = (email) => {
    return api.post(`auth/forgot-password/`, encryptedData({ email }));
  };

  const confirmPassword = (code) => {
    const query = {
      code,
    };
    return new Promise((resolve, reject) => {
      api
        .get('auth/forgot-password/?' + serialize(query))
        .then((response) => {
          if (
            response &&
            response.ok &&
            response.data &&
            response.data.access
          ) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh,
            });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const tokenRefresh = () => {
    return new Promise((resolve, reject) => {
      api
        .post('auth/token/refresh/', encryptedData({
          refresh: accessData.refreshToken,
        }))
        .then((response) => {
          if (
            response &&
            response.ok &&
            response.data &&
            response.data.access
          ) {
            updateAccessToken(response.data.access, response.data.refresh);
          }
          resolve({
            access: response.data.access,
            refresh: response.data.refresh,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //create user password
  const createUserPassword = (new_password ,confirm_code) => {
    return api.post(`/user/user/set_password/`, encryptedData({ new_password, confirm_code }));
  };
  
  const socialAuth = (data) => {
    const { access_token, socialType, user, promo_offer_id = null } = data;
    return new Promise((resolve, reject) => {
      api
        .post(`auth/social/${socialType}/`, encryptedData({
          access_token,
          ...(user && { user: user }),
          ...(promo_offer_id && { promo_offer_id: promo_offer_id }),
        }))
        .then((response) => {
          if (response?.ok && response?.data?.access) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh,
              ...(response?.data?.checkout_url && {checkout_url: response?.data?.checkout_url})
            });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const socialAuthAdd = (access_token, socialType, user) => {
    return new Promise((resolve, reject) => {
      api
        .post(`user/social/${socialType}/`, encryptedData({
          access_token,
          ...(user && { user: user })
        }))
        .then((response) => {
          if (response?.ok && response?.data?.access) {
            updateAccessToken(response.data.access, response.data.refresh);
            resolve({
              userInfo: response.data.user,
              access: response.data.access,
              refresh: response.data.refresh,
            });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const socialAuthDelete = (socialType) =>
    api.delete(`user/social/${socialType}/`);

  const logout = () => {
    const {accessToken, refreshToken } = accessData
    return api.post('auth/logout/', encryptedData({ refresh_token: refreshToken}), {
      headers: { "Authorization": 'JWT ' + accessToken }});
  };

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    api,
    // a list of the API functions from step 2
    updateAccessToken,

    getCategories,
    getCategory,

    getPlans,
    getPlan,

    getCountries,
    getClickFunnelProducts,
    createClickFunnelSession,
    createStripeSession,
    changeStripeCard,
    checkDiscountCode,
    saveSubscription,
    updateSubscription,
    deleteSubscription,
    undoDeleteSubscription,
    getQuestionsData,
    createIntent,
    cancelSub,
    cancelTrial,
    undoCancelSub,
    undoPauseSub,
    saveFrontLogs,
    signIn,
    logout,
    tokenRefresh,
    checkEmail,
    createAccount,
    changeEmail,
    confirmEmail,
    confirmEmailInit,
    checkPassword,
    confirmPassword,
    createUserPassword,
    socialAuth,
    socialAuthAdd,
    socialAuthDelete,
  };
};

// let's return back our create method as the default.
export default create();
