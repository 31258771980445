import React, {useState} from 'react';
import {useSelector} from "react-redux";

const AutoDs = ({width = 25, height = 24, isLogo = false, isTooltip = false}) => {
  const theme = useSelector(state => state.nav.theme);
  const [reversedIcon, setReversedIcon] = useState(false)
  return (
    isLogo ?
      <svg xmlns="http://www.w3.org/2000/svg" width="87" height="22" viewBox="0 0 87 22" fill="none">
        <path
          d="M37.9281 5.55249C37.0333 6.47267 36.5504 7.66277 36.4777 9.12367C36.454 9.68366 36.4418 10.1709 36.4418 10.5846C36.4418 11.1323 36.454 11.7361 36.4777 12.3925C36.5504 13.8543 37.0333 15.0409 37.9281 15.954C38.8228 16.869 40.1164 17.3247 41.8086 17.3247C43.5009 17.3247 44.7944 16.869 45.6891 15.954C46.583 15.0409 47.0668 13.8543 47.1395 12.3925C47.1632 11.7361 47.1755 11.1323 47.1755 10.5846C47.1755 10.1709 47.1632 9.68366 47.1395 9.12367C47.0668 7.64963 46.583 6.4569 45.6891 5.54373C44.7944 4.62968 43.5009 4.1731 41.8086 4.1731C40.1164 4.1731 38.8228 4.63319 37.9281 5.55249ZM39.7693 14.3196C39.2549 13.8394 38.9736 13.1663 38.9263 12.3014C38.9008 11.6932 38.8886 11.1761 38.8886 10.7493C38.8886 10.3357 38.9008 9.823 38.9263 9.21393C38.9736 8.35072 39.2549 7.67329 39.7693 7.18691C40.2829 6.7014 40.962 6.4569 41.8086 6.4569C42.6543 6.4569 43.3352 6.7014 43.8479 7.18691C44.3623 7.67329 44.6428 8.35072 44.6918 9.21393C44.7155 9.51978 44.7287 10.0307 44.7287 10.7493C44.7287 11.4802 44.7155 11.9964 44.6918 12.3014C44.6428 13.1663 44.3623 13.8394 43.8479 14.3196C43.3352 14.8007 42.6543 15.0409 41.8086 15.0409C40.962 15.0409 40.2829 14.8007 39.7693 14.3196Z"
          fill="white"/>
        <path
          d="M57.563 4.39136C57.4298 4.39136 57.3141 4.43956 57.2177 4.53683C57.1222 4.63499 57.074 4.75067 57.074 4.88475V16.6832C57.074 16.8173 57.1222 16.933 57.2177 17.0294C57.3141 17.1284 57.4298 17.1766 57.563 17.1766H61.7345C63.4267 17.1766 64.715 16.7358 65.5966 15.8533C66.4782 14.9699 66.969 13.7308 67.0654 12.1358C67.089 11.8317 67.1013 11.3803 67.1013 10.7835C67.1013 10.1876 67.089 9.73629 67.0654 9.43307C66.969 7.83721 66.473 6.59891 65.5782 5.71554C64.6834 4.83304 63.3724 4.39136 61.6433 4.39136H57.563ZM59.5208 6.67428H61.5539C62.5442 6.67428 63.2909 6.92492 63.793 7.42357C64.2934 7.9231 64.5686 8.59264 64.6168 9.43307C64.6414 9.73629 64.6536 10.1876 64.6536 10.7835C64.6536 11.3803 64.6414 11.8317 64.6168 12.1358C64.5686 12.9753 64.2996 13.6457 63.8114 14.1453C63.3216 14.6448 62.5977 14.8928 61.6433 14.8928H59.5208V6.67428Z"
          fill="white"/>
        <path
          d="M5.15038 4.35718C4.9681 4.35718 4.82613 4.40538 4.72272 4.50265C4.62106 4.59993 4.53255 4.75855 4.45981 4.97764L0.0184073 16.5211C0.00613822 16.557 0 16.6008 0 16.649C0 16.7831 0.0482037 16.8979 0.144604 16.9961C0.241003 17.0925 0.356682 17.1407 0.490766 17.1407H1.79479C2.15761 17.1407 2.39335 16.9891 2.50289 16.6841L6.14681 7.16942L9.7916 16.6841C9.90114 16.9891 10.1351 17.1407 10.4997 17.1407H11.8037C11.9361 17.1407 12.0526 17.0925 12.149 16.9961C12.2445 16.8979 12.2936 16.7831 12.2936 16.649C12.2936 16.6008 12.2875 16.557 12.2761 16.5211L7.83293 4.97764C7.76019 4.75855 7.67343 4.59993 7.5709 4.50265C7.46749 4.40538 7.32552 4.35718 7.14411 4.35718H5.15038Z"
          fill="white"/>
        <path
          d="M21.4384 4.35645C21.3052 4.35645 21.1913 4.40552 21.094 4.5028C20.9967 4.60095 20.9494 4.71575 20.9494 4.85071V12.2472C20.9494 13.2095 20.7005 13.9158 20.2053 14.3654C19.7102 14.8167 19.0337 15.0419 18.1748 15.0419C17.316 15.0419 16.6447 14.8167 16.1627 14.3654C15.6789 13.9158 15.4379 13.2095 15.4379 12.2472V4.85071C15.4379 4.71575 15.3889 4.60095 15.2925 4.5028C15.1943 4.40552 15.0813 4.35645 14.948 4.35645H13.4784C13.3461 4.35645 13.2304 4.40552 13.134 4.5028C13.0376 4.60095 12.9894 4.71575 12.9894 4.85071V12.3384C12.9894 13.8843 13.4302 15.1024 14.3127 15.9919C15.1943 16.8797 16.4817 17.3249 18.1748 17.3249C19.8548 17.3249 21.1413 16.877 22.0369 15.9814C22.9308 15.0875 23.3787 13.8729 23.3787 12.3384V4.85071C23.3787 4.71575 23.3296 4.60095 23.2332 4.5028C23.1368 4.40552 23.0211 4.35645 22.8888 4.35645H21.4384Z"
          fill="white"/>
        <path
          d="M26.0945 4.35645C25.9604 4.35645 25.8456 4.40552 25.7492 4.5028C25.652 4.60095 25.6038 4.71575 25.6038 4.85071V6.14685C25.6038 6.28094 25.652 6.39574 25.7492 6.49302C25.8456 6.59117 25.9604 6.64025 26.0945 6.64025H29.3572V16.6492C29.3572 16.7833 29.4063 16.8981 29.5018 16.9962C29.5982 17.0935 29.7139 17.1417 29.848 17.1417H31.315C31.4482 17.1417 31.563 17.0935 31.6603 16.9962C31.7567 16.8981 31.8049 16.7833 31.8049 16.6492V6.64025H35.0685C35.2017 6.64025 35.3165 6.59117 35.4138 6.49302C35.5102 6.39574 35.5584 6.28094 35.5584 6.14685V4.85071C35.5584 4.71575 35.5102 4.60095 35.4138 4.5028C35.3165 4.40552 35.2017 4.35645 35.0685 4.35645H26.0945Z"
          fill="white"/>
        <path
          d="M71.2492 4.69186C70.5236 5.01524 69.9531 5.46306 69.5359 6.03532C69.1188 6.60671 68.9102 7.25347 68.9102 7.97121C68.9102 9.03073 69.1906 9.83435 69.7532 10.383C70.315 10.9298 71.2317 11.3619 72.5015 11.6791C72.9835 11.8 73.2692 11.8921 73.5707 11.9534C74.3331 12.1348 74.795 12.2855 75.1692 12.3995C75.5442 12.516 75.8334 12.6624 76.0394 12.8394C76.2453 13.0155 76.3479 13.2504 76.3479 13.5422C76.3479 14.0532 76.0823 14.4361 75.5504 14.6938C75.0193 14.9479 74.4611 15.0759 73.6618 15.0759C72.8407 15.0759 72.4472 14.9567 72.0537 14.7192C71.662 14.4817 71.3622 14.1636 71.1572 13.7613C71.0844 13.615 70.9898 13.5046 70.8767 13.4318C70.7602 13.3591 70.6068 13.3223 70.4131 13.3223H69.1442C68.9996 13.3223 68.8786 13.3678 68.7822 13.4599C68.685 13.5501 68.6429 13.6693 68.6543 13.8157C68.6911 14.5097 68.9514 15.1276 69.4342 15.6692C69.9171 16.2116 70.5525 16.6288 71.3395 16.9206C72.1238 17.2124 72.7557 17.3597 73.6618 17.3597C74.7266 17.3597 75.5469 17.1949 76.3391 16.8654C77.1305 16.5376 77.7378 16.0837 78.1619 15.5044C78.5835 14.9278 78.7964 14.2723 78.7964 13.5422C78.7964 12.5432 78.4695 11.758 77.8175 11.1857C77.1646 10.6143 76.106 10.1691 74.6433 9.85188C73.6285 9.63279 73.1097 9.45577 72.6636 9.32256C72.2167 9.18848 71.8872 9.02197 71.6751 8.81953C71.4639 8.61972 71.3587 8.33665 71.3587 7.97121C71.3587 7.52076 71.5822 7.16145 72.0292 6.89328C72.4761 6.62512 72.9195 6.49103 73.5707 6.49103C74.2367 6.49103 74.5487 6.59532 74.9352 6.80302C75.3225 7.00896 75.6179 7.28326 75.8238 7.62417C75.9088 7.77052 76.0052 7.87919 76.1139 7.95193C76.2234 8.02642 76.3733 8.06147 76.567 8.06147H77.8359C77.9814 8.06147 78.1023 8.0159 78.1987 7.92564C78.2951 7.83362 78.3381 7.71531 78.3258 7.56983C78.289 7.07031 78.0778 6.55851 77.6905 6.03532C77.3049 5.51126 76.7387 5.07658 75.9964 4.72954C75.2533 4.38163 74.5873 4.20898 73.5707 4.20898C72.6768 4.20898 71.9748 4.36936 71.2492 4.69186Z"
          fill="white"/>
        <path
          d="M49.3585 9.71533C49.2262 9.71533 49.1097 9.76441 49.0133 9.86081C48.9169 9.95896 48.8687 10.0746 48.8687 10.2087V11.6871C48.8687 11.8212 48.9169 11.9378 49.0133 12.0351C49.1097 12.1315 49.2262 12.1805 49.3585 12.1805H54.8884C55.0216 12.1805 55.1364 12.1315 55.2328 12.0351C55.3301 11.9378 55.3783 11.8212 55.3783 11.6871V10.2087C55.3783 10.0746 55.3301 9.95896 55.2328 9.86081C55.1364 9.76441 55.0216 9.71533 54.8884 9.71533H49.3585Z"
          fill="#D59955"/>
        <path
          d="M44.5849 0.000876477L44.6513 0.0058821C44.7522 0.0215585 44.8415 0.0685315 44.918 0.146352L44.9704 0.206927C45.0326 0.290637 45.0634 0.386125 45.0634 0.493392V1.97182L45.0576 2.05015C45.0422 2.15175 44.9958 2.24191 44.918 2.31973L44.8582 2.37231C44.7755 2.43467 44.6801 2.46608 44.5735 2.46608H39.0437L38.9663 2.4602C38.8658 2.44453 38.7762 2.39755 38.6984 2.31973L38.6464 2.25907C38.585 2.17513 38.5547 2.07908 38.5547 1.97182V0.493392L38.5604 0.415151C38.5756 0.313773 38.6213 0.224173 38.6984 0.146352L38.759 0.0937779C38.8426 0.031409 38.9378 0 39.0437 0L39.0323 0.000876477H44.5849Z"
          fill="#B2D8D0"/>
        <path
          d="M80.9812 9.88354C80.848 9.88354 80.7323 9.93262 80.6359 10.0308C80.5395 10.1272 80.4922 10.2437 80.4922 10.3778V11.8562C80.4922 11.9903 80.5395 12.1069 80.6359 12.2033C80.7323 12.3014 80.848 12.3505 80.9812 12.3505H86.511C86.6434 12.3505 86.7591 12.3014 86.8555 12.2033C86.9527 12.1069 87.0001 11.9903 87.0001 11.8562V10.3778C87.0001 10.2437 86.9527 10.1272 86.8555 10.0308C86.7591 9.93262 86.6434 9.88354 86.511 9.88354H80.9812Z"
          fill="#D9D4AB"/>
        <path
          d="M70.959 19.0662C70.8267 19.0662 70.7119 19.1161 70.6146 19.2134C70.5173 19.3107 70.4691 19.4263 70.4691 19.5604V21.0397C70.4691 21.1738 70.5173 21.2886 70.6146 21.3868C70.7119 21.4849 70.8267 21.5331 70.959 21.5331H76.4888C76.6221 21.5331 76.7377 21.4849 76.8341 21.3868C76.9305 21.2886 76.9787 21.1738 76.9787 21.0397V19.5604C76.9787 19.4263 76.9305 19.3107 76.8341 19.2134C76.7377 19.1161 76.6221 19.0662 76.4888 19.0662H70.959Z"
          fill="#E5BBB8"/>
        <path
          d="M15.4196 19.0662C15.2864 19.0662 15.1698 19.1161 15.0743 19.2134C14.9779 19.3107 14.9288 19.4263 14.9288 19.5604V21.0397C14.9288 21.1738 14.9779 21.2886 15.0743 21.3868C15.1698 21.4849 15.2864 21.5331 15.4196 21.5331H20.9494C21.0818 21.5331 21.1966 21.4849 21.293 21.3868C21.3911 21.2886 21.4385 21.1738 21.4385 21.0397V19.5604C21.4385 19.4263 21.3911 19.3107 21.293 19.2134C21.1966 19.1161 21.0818 19.0662 20.9494 19.0662H15.4196Z"
          fill="#B2D8D0"/>
        <path
          d="M19.4307 8.59009C19.2984 8.59009 19.1827 8.63916 19.0854 8.73644C18.989 8.83284 18.9408 8.95027 18.9408 9.08436V10.5619C18.9408 10.696 18.989 10.8125 19.0854 10.9098C19.1827 11.0071 19.2984 11.0562 19.4307 11.0562H24.9605C25.0937 11.0562 25.2094 11.0071 25.3049 10.9098C25.4022 10.8125 25.4504 10.696 25.4504 10.5619V9.08436C25.4504 8.95027 25.4022 8.83284 25.3049 8.73644C25.2094 8.63916 25.0937 8.59009 24.9605 8.59009H19.4307Z"
          fill="#E5BBB8"/>
        <path
          d="M29.3582 11.1167V16.6877C29.3582 16.8218 29.4064 16.9384 29.5019 17.0348C29.5983 17.1321 29.714 17.1811 29.8472 17.1811H31.3151C31.4492 17.1811 31.5631 17.1321 31.6604 17.0348C31.7568 16.9384 31.805 16.8218 31.805 16.6877V11.1167H29.3582Z"
          fill="#D9D4AB"/>
        <path
          d="M59.0309 4.39136H58.2299H57.563C57.4298 4.39136 57.315 4.43956 57.2186 4.53683C57.1222 4.63499 57.074 4.74979 57.074 4.88387V10.4558H59.5208V5.79617V4.88387V4.39136H59.0309Z"
          fill="#B2D8D0"/>
        <path
          d="M3.38183 11.0386C3.24863 11.0386 3.1347 11.0877 3.03655 11.1841C2.94015 11.2822 2.89282 11.3979 2.89282 11.532V12.8307C2.89282 12.9648 2.94015 13.0796 3.03655 13.1769C3.1347 13.2742 3.24863 13.3233 3.38183 13.3233H8.91168C9.04489 13.3233 9.15969 13.2742 9.25609 13.1769C9.35337 13.0796 9.40244 12.9648 9.40244 12.8307V11.532C9.40244 11.3979 9.35337 11.2822 9.25609 11.1841C9.15969 11.0877 9.04489 11.0386 8.91168 11.0386H3.38183Z"
          fill="#D59955"/>
      </svg>
      :
      <svg xmlns="http://www.w3.org/2000/svg"
           width={width}
           height={height}
           viewBox="0 0 25 24"
           onMouseEnter={() => setReversedIcon(true)}
           onMouseLeave={() => setReversedIcon(false)}
           fill="none">
        <g clipPath="url(#clip0_36231_525624)">
          <path
            d="M2.20468 5.51379C2.07238 5.51379 1.95748 5.56167 1.86174 5.65828C1.76687 5.75577 1.71899 5.87066 1.71899 6.00383V17.722C1.71899 17.8552 1.76687 17.9701 1.86174 18.0658C1.95748 18.1642 2.07238 18.2121 2.20468 18.2121H6.34778C8.02852 18.2121 9.30801 17.7743 10.1836 16.8978C11.0593 16.0204 11.5467 14.7896 11.6424 13.2055C11.6659 12.9035 11.6781 12.4552 11.6781 11.8625C11.6781 11.2706 11.6659 10.8224 11.6424 10.5212C11.5467 8.93621 11.054 7.70633 10.1654 6.82897C9.27668 5.95248 7.97456 5.51379 6.25726 5.51379H2.20468ZM4.14915 7.78119H6.16848C7.15203 7.78119 7.89361 8.03012 8.39235 8.52538C8.88935 9.02151 9.16265 9.68649 9.21053 10.5212C9.2349 10.8224 9.24708 11.2706 9.24708 11.8625C9.24708 12.4552 9.2349 12.9035 9.21053 13.2055C9.16265 14.0394 8.89544 14.7052 8.41063 15.2013C7.92407 15.6975 7.20512 15.9438 6.25726 15.9438H4.14915V7.78119Z"
            fill={isTooltip && reversedIcon ? '#225aea' : theme === 'light' ? '#191A35' : '#FFFFFF'}/>
          <path
            d="M15.7976 5.81234C15.0769 6.13352 14.5102 6.57829 14.0959 7.14666C13.6816 7.71416 13.4745 8.35652 13.4745 9.06938C13.4745 10.1217 13.753 10.9198 14.3118 11.4647C14.8697 12.0078 15.7802 12.437 17.0414 12.752C17.5201 12.8722 17.8038 12.9635 18.1033 13.0245C18.8605 13.2046 19.3192 13.3544 19.6909 13.4675C20.0634 13.5833 20.3506 13.7286 20.5552 13.9044C20.7597 14.0794 20.8616 14.3127 20.8616 14.6025C20.8616 15.11 20.5978 15.4903 20.0695 15.7462C19.542 15.9986 18.9876 16.1257 18.1938 16.1257C17.3782 16.1257 16.9874 16.0073 16.5966 15.7715C16.2075 15.5356 15.9099 15.2196 15.7062 14.8201C15.6339 14.6748 15.5399 14.5651 15.4276 14.4928C15.3119 14.4206 15.1596 14.384 14.9672 14.384H13.7069C13.5633 14.384 13.4431 14.4293 13.3474 14.5207C13.2508 14.6103 13.209 14.7287 13.2203 14.8741C13.2569 15.5634 13.5154 16.1771 13.995 16.715C14.4746 17.2537 15.1056 17.6681 15.8872 17.9579C16.6662 18.2477 17.2938 18.394 18.1938 18.394C19.2513 18.394 20.066 18.2303 20.8529 17.9031C21.6388 17.5775 22.242 17.1267 22.6633 16.5513C23.0819 15.9786 23.2935 15.3276 23.2935 14.6025C23.2935 13.6103 22.9688 12.8304 22.3212 12.262C21.6728 11.6945 20.6213 11.2523 19.1686 10.9373C18.1607 10.7197 17.6454 10.5438 17.2024 10.4115C16.7585 10.2784 16.4312 10.113 16.2206 9.91192C16.0108 9.71347 15.9064 9.43233 15.9064 9.06938C15.9064 8.62199 16.1283 8.26513 16.5722 7.99878C17.0161 7.73244 17.4566 7.59927 18.1033 7.59927C18.7648 7.59927 19.0746 7.70285 19.4585 7.90913C19.8432 8.11368 20.1365 8.38611 20.3411 8.7247C20.4255 8.87005 20.5212 8.97798 20.6292 9.05023C20.738 9.12421 20.8868 9.15903 21.0792 9.15903H22.3395C22.484 9.15903 22.6041 9.11377 22.6998 9.02412C22.7956 8.93272 22.8382 8.81522 22.8261 8.67073C22.7895 8.1746 22.5797 7.66629 22.195 7.14666C21.812 6.62616 21.2498 6.19444 20.5125 5.84977C19.7744 5.50422 19.1129 5.33275 18.1033 5.33275C17.2155 5.33275 16.5183 5.49203 15.7976 5.81234Z"
            fill={isTooltip && reversedIcon ? '#225aea' : theme === 'light' ? '#191A35' : '#FFFFFF'}/>
          <path
            d="M-5.94411 10.8015C-6.07554 10.8015 -6.1913 10.8502 -6.28705 10.946C-6.38279 11.0435 -6.43066 11.1583 -6.43066 11.2915V12.7599C-6.43066 12.8931 -6.38279 13.0088 -6.28705 13.1054C-6.1913 13.2012 -6.07554 13.2499 -5.94411 13.2499H-0.451886C-0.319585 13.2499 -0.205562 13.2012 -0.109818 13.1054C-0.0132036 13.0088 0.0346684 12.8931 0.0346684 12.7599V11.2915C0.0346684 11.1583 -0.0132036 11.0435 -0.109818 10.946C-0.205562 10.8502 -0.319585 10.8015 -0.451886 10.8015H-5.94411Z"
            fill={isTooltip && reversedIcon ? '#225aea' : '#D59955'}/>
          <path
            d="M25.4635 10.9686C25.3312 10.9686 25.2163 11.0173 25.1205 11.1148C25.0248 11.2106 24.9778 11.3263 24.9778 11.4595V12.9279C24.9778 13.061 25.0248 13.1768 25.1205 13.2725C25.2163 13.37 25.3312 13.4188 25.4635 13.4188H30.9557C31.0871 13.4188 31.202 13.37 31.2978 13.2725C31.3944 13.1768 31.4414 13.061 31.4414 12.9279V11.4595C31.4414 11.3263 31.3944 11.2106 31.2978 11.1148C31.202 11.0173 31.0871 10.9686 30.9557 10.9686H25.4635Z"
            fill={isTooltip && reversedIcon ? '#225aea' : '#D9D4AB'}/>
          <path
            d="M15.51 20.0887C15.3786 20.0887 15.2645 20.1383 15.1679 20.2349C15.0713 20.3315 15.0234 20.4464 15.0234 20.5796V22.0488C15.0234 22.182 15.0713 22.296 15.1679 22.3935C15.2645 22.491 15.3786 22.5388 15.51 22.5388H21.0022C21.1345 22.5388 21.2494 22.491 21.3452 22.3935C21.4409 22.296 21.4888 22.182 21.4888 22.0488V20.5796C21.4888 20.4464 21.4409 20.3315 21.3452 20.2349C21.2494 20.1383 21.1345 20.0887 21.0022 20.0887H15.51Z"
            fill={isTooltip && reversedIcon ? '#225aea' : '#E5BBB8'}/>
          <path
            d="M3.6626 5.51379H2.86705H2.20468C2.07238 5.51379 1.95835 5.56167 1.86261 5.65828C1.76687 5.75577 1.71899 5.86979 1.71899 6.00296V11.537H4.14915V6.90905V6.00296V5.51379H3.6626Z"
            fill={isTooltip && reversedIcon ? '#225aea' : '#B2D8D0'}/>
        </g>
        <defs>
          <clipPath id="clip0_36231_525624">
            <rect width="25" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
  );
};

export default AutoDs;
