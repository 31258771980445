import React from 'react';
import './StoreProductsModalTableSkeleton.less';

const StoreProductsModalTableSkeleton = ({isMobile=false}) => {

  return (
    <div className={'store-products-modal-table-skeleton-wrapper'}>
      <div className="store-products-modal-table-skeleton-header">
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '60%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
        <div style={{display: isMobile ? "none" : "flex", justifyContent: "flex-start", alignItems: "center", width: '40%'}}>
          <span className="link skeleton" style={{ width: 24, height: 24, marginRight: 8  }}/>
          <span className="link skeleton" style={{ width: 170, height: 24  }}/>
        </div>
      </div>

      {[1,2,3,4,5,6,7,8].map(el => (
        <div key={el} className={'store-products-modal-table-skeleton-row'} style={{display: "flex", alignItems: "center", padding: '12px 0'}}>
          <div style={{width: isMobile ? '100%' : '60%', display: "flex", alignItems: "center", paddingLeft: 24}}>
            <span className="link skeleton" style={{ width: 64, height: 64, marginRight: 16  }}/>
            <div style={{display: "flex", flexDirection: "column"}}>
              <span className="link skeleton" style={{ width: 246, height: 20, marginBottom: 4 }}/>
              <div style={{display: "flex", alignItems: "center"}}>
                <span className="link skeleton" style={{ width: 47, height: 16, marginRight: 20 }}/>
                <span className="link skeleton" style={{ width: 58, height: 16 }}/>
              </div>
            </div>
          </div>

          <div style={{display: isMobile ? 'none' : 'flex', width: '20%'}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>

          <div style={{display: isMobile ? 'none' : 'flex'}}>
            <span className="link skeleton" style={{ width: 130, height: 40, marginLeft: 16 }}/>
          </div>
        </div>
      ))}

    </div>
  );
};

export default StoreProductsModalTableSkeleton;
