import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
import openNotification from '../Notification';

export default function EmailNotifications(props) {
  const {
    resetErrors,
    resetResult,
    changeNotifications,
    notifications,
    saveResult,
    notificationText,
    errors,
  } = props;
  const { t } = useTranslation();
  const [checkBox, setCheckBox] = useState(null);
  useEffect(() => {
    if (saveResult && checkBox) {
      const note = Object.entries(checkBox);
      const turned = note[0][1] ? t('turned on') : t('turned off');
      const text = note[0][0];

      openNotification({
        type: 'success',
        message: t('_notification_ emails have been successfully _turn_', {
          notification: notificationText[text],
          turn: turned,
        }),
        style: { minWidth: '716px' },
      });
      setCheckBox(null);
      resetResult();
    }
    if (errors) {
      resetErrors();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, checkBox]);

  const handleChange = (name) => {
    changeNotifications({ email: { [name]: !notifications?.email?.[name] } });
    setCheckBox({ [name]: !notifications?.email?.[name] });
  };

  return (
    <Card className="notifications-card">
      <div className="notifications-title">{t('Email')}</div>
      <Row
        gutter={[
          { xs: 0, md: 48 },
          { xs: 24, sm: 24, md: 32 },
        ]}
      >
        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.upcoming_drops}
            onChange={() => handleChange('upcoming_drops')}
          >
            <div className="notifications-checkbox-title">
              {t('Upcoming Drops')}
            </div>
            <div className="notifications-checkbox-subtitle">
              {t('Get notified when there is a new drop coming your way')}
            </div>
          </Checkbox>
        </Col>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 3 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.support}
            onChange={() => handleChange('support')}
          >
            <div className="notifications-checkbox-title">{t('Support')}</div>
            <div className="notifications-checkbox-subtitle">
              {t(
                'Get notified when you receive an update to your support tickets',
              )}
            </div>
          </Checkbox>
        </Col>
        <Col xs={{ span: 24, order: 3 }} md={{ span: 12, order: 5 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.messages}
            onChange={() => handleChange('messages')}
          >
            <div className="notifications-checkbox-title">{t('Messages')}</div>
            <div className="notifications-checkbox-subtitle">
              {t('Get notified when someone messages you')}
            </div>
          </Checkbox>
        </Col>
        <Col xs={{ span: 24, order: 4 }} md={{ span: 12, order: 2 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.newsletter}
            onChange={() => handleChange('newsletter')}
          >
            <div className="notifications-checkbox-title">
              {t('Newsletter')}
            </div>
            <div className="notifications-checkbox-subtitle">
              {t('We keep you up to date with Dropship')}
            </div>
          </Checkbox>
        </Col>

        <Col xs={{ span: 24, order: 5 }} md={{ span: 12, order: 4 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.new_features}
            onChange={() => handleChange('new_features')}
          >
            <div className="notifications-checkbox-title">
              {t('New Features')}
            </div>
            <div className="notifications-checkbox-subtitle">
              {t('Get notified when a new feature is released')}
            </div>
          </Checkbox>
        </Col>

        <Col xs={{ span: 24, order: 6 }} md={{ span: 12, order: 6 }}>
          <Checkbox
            type="bounce"
            checked={notifications?.email?.billing}
            onChange={() => handleChange('billing')}
          >
            <div className="notifications-checkbox-title">{t('Billing')}</div>
            <div className="notifications-checkbox-subtitle">
              {t(
                'Get notified when there is an action happening related to your payments',
              )}
            </div>
          </Checkbox>
        </Col>
      </Row>
    </Card>
  );
}
