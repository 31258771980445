import React from 'react';
import cls from 'classname';
import EmptyData from '../EmptyData';
import CompetitorsList from './CompetitorsList';
import ButtonComponent from "../../../../Components/Button";
import './styles.less';

export default function Competitors({
  product,
  onChangeValue,
  openModal,
  saveInfo,
}) {
  function openDeleteModal() {
    openModal('competitors_delete');
  }
  function openAddModal() {
    openModal('competitors_add');
  }
  function handleChangeInput(value) {
    const changeCompetitor = product?.competitors?.map((competitor) =>
      competitor?.id === value?.id ? value : competitor,
    );
    onChangeValue({ competitors: changeCompetitor });
  }

  return (
    <div className="competitors-block">
      <div className="block-with-label ">
        <div className="block-with-label_label space-between bold-text">
          <div>
            Competitors Manual{' '}
            <span className="blue-block">{product?.competitors?.length}</span>
          </div>
          <ButtonComponent type="primary" className="btn-primary" onClick={openAddModal}>
            Add
          </ButtonComponent>
        </div>
      </div>
      <div
        className={cls('block-with-list', {
          empty_data: !product?.competitors?.length,
        })}
      >
        {product?.competitors?.length ? (
          <CompetitorsList
            openDeleteModal={openDeleteModal}
            competitors={product?.competitors}
            onChangeInput={handleChangeInput}
            saveInfo={saveInfo}
          />
        ) : (
          <EmptyData text="No Competitors Added" />
        )}
      </div>
    </div>
  );
}
