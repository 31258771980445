import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from "../../../Components/Image";
import {columnTitles} from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";

//product database columns
export const getColumns = (
  {
    sortOrder,
    setRecordToDelete,
    setVisible,
    setModal,
    setLocalModal,
    setShowLocalModal,
    setRecordToPriceHistory,
    setRecordToCalc,
    t,
    theme,
    isTrial,
    isMobile=false
  }) => (
  [
    {
      title: () => 'Product',
      dataIndex: 'title',
      key: 'name',
      width: isMobile ? 'calc(100vw - 48px)' : 372,
      fixed: isMobile ? false : 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <a href={`/sales-tracker/products/product/${record?.id}`} style={{flex: 'unset'}}>
            <div className={'product-database-table-image'}>
              {
                record?.main_image
                  ? <img src={utils.changeImageSize(record?.main_image, 124)}
                         alt=""
                         onError={utils.addDefaultSrc}
                         width="62" height="62"
                  />
                  : <Image src={Images.defaultImg} small={true}/>
              }
            </div>
          </a>
            <div className="product-image-quantity">
              <Icon type={'database_product_images'}
                    role={'icon'}
                    color={'#707ba0'}
                    width={16}
                    height={16}
              />
              <span>{record?.images}</span>
            </div>
            <div className={'product-info-wrapper'}>
              <a href={`/sales-tracker/products/product/${record?.id}`}>
              <p className={'product-name'}>
                {record?.title}
                {record?.is_demo &&
                  <span className="product-name-tag">
                  {t('example')}
                </span>}
              </p>
              </a>
              <p>
                <a href={`https://${record?.store?.custom_domain}/products/${record?.handle}`}
                   target={'_blank'}
                   rel="noopener noreferrer"
                >
                  <span className={'product-url'}>
                    {
                      record?.store?.all_domains?.length > 0 && !isMobile ?
                        <>
                          {record?.store?.custom_domain}
                          <TooltipColumnIcon record={record}  />
                        </>
                        :
                        record?.store?.custom_domain
                    }
                  </span>
                </a>
                <span
                  className={'product-variants'}>{record?.variants} {record?.variants === 1 ? 'Variant' : 'Variants'}</span>
              </p>
            </div>
        </div>
      ),
    },
    {
      title: '',
      fixed: isMobile ? false : 'left',
      dataIndex: 'quick_search',
      key: 'suppliers',
      width: 136,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <div className="product-suppliers">
            {record?.quick_search?.map(el => (
              <a
                key={record?.title + el?.platform}
                href={el?.link ? el?.link : '#'}
                target="_blank"
                rel="noopener noreferrer"
                className="link with-img"
              >
                <Icon role="button"
                      type={`search_${el?.platform}`}
                      theme={theme}
                      className={`${el?.platform}-icon`}
                      width={el?.platform === 'alibaba' ? 24 : 16}
                      height={el?.platform === 'alibaba' ? 24 : 16}
                      titleText={() => <>Search on <span style={{textTransform: 'capitalize'}}>{el?.platform}</span></>}
                      tooltipProps={{
                        placement: 'bottom',
                        trigger: 'hover',
                        destroyTooltipOnHide: true,
                        overlayClassName: cls('details-tooltip', `${el?.platform}-tooltip`),
                        getPopupContainer: () =>
                          document.getElementById('product-database-table-body'),
                      }}
                />
              </a>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: '',
      key: 'price_history',
      width: 64,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={e => {
               setLocalModal('priceHistory');
               setShowLocalModal(true);
               setRecordToPriceHistory(record);
             }}
        >
          <div className="product-price-history">
            <Icon role={'icon'} type={'chart_icon'} />
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Price'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'usd_price',
        })
      },
      dataIndex: 'usd_price',
      key: 'usd_price',
      width: 194,
      sorter: (a, b) => (a['usd_price'] || 0) - (b['usd_price'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={() => {
               setShowLocalModal(true);
               setLocalModal('breakdown');
               setRecordToCalc(record);
             }}
        >
          <div className="product-price">
            <p>{`$${acc.format(record.usd_price, 2, ',', '.')}`} {record?.price_max &&
              <span>- ${acc.format(record?.price_max, 2, ',', '.')}</span>}</p>
            {record?.store?.currency !== 'USD' &&
              <p style={{marginTop: 8, fontSize: 12, lineHeight: '16px', color: '#707ba0'}}>
                {(record.original_price > 0 || record.original_price_max > 0) && acc.format(record.original_price, 2, ',', '.')} {record.original_price_max > 0 &&
                <span>- {acc.format(record.original_price_max, 2, ',', '.')}</span>} {(record.original_price > 0 || record.original_price_max > 0) && record.store.currency}
              </p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales Today'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'day_revenue',
        })
      },
      dataIndex: 'day_revenue',
      key: 'day_revenue',
      width: 197,
      sorter: (a, b) => (a['day_revenue'] || 0) - (b['day_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
          <div className="product-database-table-cell product-database-table-cell_clickable">
            <div className="product-store-info">
              {
                record?.day_revenue ?
                  <p>${acc.format(record?.day_revenue, 2, ',', '.')}</p>
                  :
                  <p>-</p>
              }
              {
                record?.day_sales ?
                  <p>{acc.format(record?.day_sales, 0, ',', '.')} {record?.day_sales === 1 ? t('Sale') : t('Sales')}</p>
                  :
                  <p>-</p>
              }
            </div>
          </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales Yesterday'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'yesterday_revenue',
        })
      },
      dataIndex: 'yesterday_revenue',
      key: 'yesterday_revenue',
      width: 197,
      sorter: (a, b) => (a['yesterday_revenue'] || 0) - (b['yesterday_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.yesterday_revenue ?
                <p>${acc.format(record?.yesterday_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.yesterday_sales ?
                <p>{acc.format(record?.yesterday_sales, 0, ',', '.')} {record?.yesterday_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales 7 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'week_revenue',
        })
      },
      dataIndex: 'week_revenue',
      key: 'week_revenue',
      width: 207,
      sorter: (a, b) => (a['week_revenue'] || 0) - (b['week_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.week_revenue ?
                <p>${acc.format(record?.week_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.week_sales ?
                <p>{acc.format(record?.week_sales, 0, ',', '.')} {record?.week_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales 30 Days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'month_revenue',
        })
      },
      dataIndex: 'month_revenue',
      key: 'month_revenue',
      width: 207,
      sorter: (a, b) => (a['month_revenue'] || 0) - (b['month_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.month_revenue ?
                <p>${acc.format(record?.month_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.month_sales ?
                <p>{acc.format(record?.month_sales, 0, ',', '.')} {record?.month_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Store Info'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'store_products_count',
        })
      },
      dataIndex: 'store_products_count',
      key: 'store_products_count',
      width: 216,
      sorter: (a, b) => (a?.store['products_count'] || 0) - (b?.store['products_count'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            <p>{
              record?.store?.products_count
                ?
                `${acc.format(record?.store?.products_count, 0, ',', '.')} Products`
                :
                '-'
            }</p>
            <p style={{textTransform: 'capitalize'}}>{record?.store?.language}</p>
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Product Creation Date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 216,
      sorter: (a, b) => dayjs(a['created_at']).valueOf() - dayjs(b['created_at']).valueOf(),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="store-creation-date">
            {
              record?.created_at ? dayjs(record?.created_at).format('MMM DD, YYYY') : '-'
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: isMobile ? 117 : 167,
      sorter: false,
      fixed: 'right',
      render: (text, record) => (
        <div className={'product-database-table-cell sales-tracker-store-table-cell cell-actions'}>
          <div className="product-actions">
            <span className={'product-actions-options'}
                  onClick={() => {
                    setVisible(true);
                    if (isTrial) {
                      setModal('canNotRemoveTracking');
                    } else {
                      setRecordToDelete({type: 'product', data: {...record}});
                      setModal('deleteTracking');
                    }
                  }}>
              <Icon type="remove_preset" role="icon" width={24} height={24} color={'#707ba0'}/>
            </span>
            <a href={`/sales-tracker/products/product/${record?.id}`} className={'product-actions-options-btn'}>
              {isMobile ? null : t('Insights')}
              <Icon type="arrow_forward" role="icon"/>
            </a>
          </div>
        </div>
      ),
    },
  ]
);
