import React from 'react';

const SkipOfferInfo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.0003 3.33329C6.31843 3.33329 3.33366 6.31806 3.33366 9.99996C3.33366 13.6819 6.31843 16.6666 10.0003 16.6666C13.6822 16.6666 16.667 13.6819 16.667 9.99996C16.667 6.31806 13.6822 3.33329 10.0003 3.33329ZM1.66699 9.99996C1.66699 5.39759 5.39795 1.66663 10.0003 1.66663C14.6027 1.66663 18.3337 5.39759 18.3337 9.99996C18.3337 14.6023 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6023 1.66699 9.99996Z"
            fill="#225AEA" />
      <path
        d="M10.0003 5.83337C9.54009 5.83337 9.16699 6.20647 9.16699 6.66671C9.16699 7.12694 9.54009 7.50004 10.0003 7.50004C10.4606 7.50004 10.8337 7.12694 10.8337 6.66671C10.8337 6.20647 10.4606 5.83337 10.0003 5.83337Z"
        fill="#225AEA" />
      <path
        d="M10.8337 10C10.8337 9.5398 10.4606 9.16671 10.0003 9.16671C9.54009 9.16671 9.16699 9.5398 9.16699 10L9.16699 13.3334C9.16699 13.7936 9.54009 14.1667 10.0003 14.1667C10.4606 14.1667 10.8337 13.7936 10.8337 13.3334L10.8337 10Z"
        fill="#225AEA" />
    </svg>
  );
};

export default SkipOfferInfo;
