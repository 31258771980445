import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Divider, notification, Slider, Spin} from "antd";
import OnboardingActions from "../../../Redux/OnboardingRedux";
import Creators from "../reducer";
import ErrorsActions from "../../../Redux/ErrorsRedux";
import ChargebeeActions from "../../../Components/PlanCardUpcomingNew/reducer";
import openNotification from "../../../Components/Notification";
import cls from "classname";
import acc from "accounting";
import ButtonComponent from "../../../Components/Button";
import Images from "../../../Images";
import Icon from "../../../Icon";
import PaymentOptionCard from "../../../Components/PaymentOptionCard";
import DiscountCard from "../../../Components/DiscountCard";
import ChangeCreditCardForm from "../../../Components/BillingSubPage/ChangeCreditCardForm";
import './LimitModal.less';

const PlanInfo = (
  {
    planName,
    t,
    price,
    priceType,
    recalculate=false,
    now=false,
    discount=null
  }) => {

  const diffToPay = useSelector(state => state?.chargebee?.result?.amount_due) || 0;

  return (
    <div className="switch-plan-wrapper">
      <div className="switch-plan-img-wrp">
        <img src={Images[`plan_${planName === 'basic' ? 'free' : planName || planName}`]} alt="" className={planName}/>
      </div>
      <div className="switch-plan-title">
        {`${planName} ${t('Plan')}`}
      </div>
      <Divider style={{margin: '12px 0 8px'}} type={'horizontal'} />
      <div className="switch-plan-info">
          <div className="switch-plan-info-price">
            <span>{priceType}</span>
            <span>{t(now ? 'Billing now' : 'Billing')}</span>
          </div>
        <div className="switch-plan-info-period">
          <span>${recalculate ? price : price * (priceType === 'monthly' ? 1 : 12)}</span>
          <span>
            {diffToPay >= 0 && (now || discount) ?
              `$${acc.formatNumber(diffToPay/100, 2, ',', ',')}`
              :
              t('Amount')}
          </span>
        </div>
      </div>

    </div>
  )
}

const LimitModal = (props) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {view} = useParams();

  const {data, setVisible} = props;

  const {storeLimit, productLimit, subscription} = data;

  const currentPlan = subscription?.plan;
  const defaultUserCountry = useSelector(state => state.setting.userCountry);
  const userInfo = useSelector(state => state?.auth?.userInfo);
  const isMobile = useSelector(state => state?.nav?.isMobile);
  const theme = useSelector(state => state?.nav?.theme);
  const onBoarding = useSelector(state => state?.onBoarding);
  const fetching = onBoarding?.updateSubscriptionFetching || onBoarding?.chargebeeCardFetching;
  const updateSubscriptionResult = onBoarding?.updateSubscriptionResult
  const premiumPlan = useSelector(state => state?.plan?.plans?.[2]);
  const discountId =  onBoarding?.discountResult?.id || null;
  const spinner = useSelector(state => state.chargebee.loading);

  const hasCard = userInfo?.card || (
    onBoarding?.discountResult?.duration_type === "forever" &&
    onBoarding?.discountResult?.type === "percent" &&
    +onBoarding?.discountResult?.value === 100
  );
  let discountCash = 0;

  if (onBoarding.discountResult) {
    discountCash = onBoarding.discountResult.type === 'value' ? onBoarding.discountResult.value : (premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * 100 / 100) * onBoarding.discountResult.value;
  }

  const isCurrentPrem = currentPlan?.plan?.id === 3;

  const checkDiscountCode = code => dispatch(OnboardingActions.checkDiscountCodeRequest(code));
  const checkDiscountCodeReset = () => dispatch(OnboardingActions.checkDiscountCodeReset());
  const getCountries = () => dispatch(OnboardingActions.countriesRequest());
  const changeSubscriptionReset = () => dispatch(OnboardingActions.changeSubscriptionReset());
  const updateSubscription = (plan) => dispatch(OnboardingActions.updateSubscriptionRequest(plan));
  const createIntent = data => dispatch(OnboardingActions.createIntentRequest(data));
  const clearIntent = () => dispatch(OnboardingActions.createIntentReset());
  const getStores = (data) => dispatch(Creators.getTrackingStoresRequest(data));
  const getProducts = (data) => dispatch(Creators.getTrackingProductsRequest(data));
  const setChargebeeCardFetching = (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value));
  const errorSave = (error) => dispatch(ErrorsActions.errorSave(error));
  const getCharge = (data) => dispatch(ChargebeeActions.getChargeRequest(data))

  const [store, setStore] = useState(storeLimit < 50 ? 50 : storeLimit);
  const [product, setProduct] = useState(productLimit < 100 ? 100 : productLimit);
  const [step, setStep] = useState(1);

  const [paymentType, setPaymentType] = useState('now');

  const submit = () => {
    let data = {
      plan: {2: premiumPlan?.prices?.[currentPlan?.price_type?.id]},
      'discount_code': discountId || null,
      force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
      new_limits: {
        stores_limit: store,
        products_limit: product
      }
    }
    updateSubscription(data)
  }

  const submitWithCard = (value) => {
    let fullData = {
      plan: {2: premiumPlan?.prices?.[currentPlan?.price_type?.id]},
      force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
      'discount_code': discountId || null,
      payment: {
        intent_id: value?.intent_id,
        type: 'card',
      },
      new_limits: {
        stores_limit: store,
        products_limit: product
      }
    }
    updateSubscription(fullData);
  }

  const okHandler  = (value) => {
    if (step === 1) {
      setStep(prev => prev + (isCurrentPrem ? 2 : 1));
    } else if(step === 2) {
      setStep(prev => prev + 1);
    } else if(step === 3) {
      if (hasCard) {
        submit()
      } else {
        setStep(prev => prev + 1);
      }
    } else {
      submitWithCard(value);
    }
  }

  const cancelHandler = () => {
    if (step === 1) {
      setVisible(false);
    } else if(step === 2) {
      setStep(prev => prev - 1);
    } else if(step === 3) {
      setStep(prev => prev - (isCurrentPrem ? 2 : 1));
    } else if(step === 4) {
      setStep(prev => prev - 1);
    }
  }

  function handleClearDiscount() {
    if (onBoarding.discountResult) checkDiscountCodeReset();
  }

  function handleCheckDiscount(code) {
    checkDiscountCode(code);
  }

  function handleClearError() {
    if (onBoarding.discountError) checkDiscountCodeReset();
  }

  function calculateCost() {
    const storeLimitDiff = (store - 50) * (currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25;
    const productLimitDiff = (product - 100) * (currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50;
    return premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * (currentPlan?.price_type?.id === 'monthly' ? 1 : 12) + storeLimitDiff + productLimitDiff;
  }

  function calculateCurrentCost() {
    const storeLimitDiff = (storeLimit - 50) * (currentPlan?.price_type?.id === 'monthly' ? 20 : 120) / 25;
    const productLimitDiff = (productLimit - 100) * (currentPlan?.price_type?.id === 'monthly' ? 15 : 90) / 50;
    return premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * (currentPlan?.price_type?.id === 'monthly' ? 1 : 12) + storeLimitDiff + productLimitDiff;
  }

  const content = {
    1: (
      <>
        <div className="load-filter-preset-title">
          {t('Increase Tracking Limit')}
        </div>
        {
          !isCurrentPrem ?
            <div className="load-filter-preset-subtitle">
              {t('Tracking limits can only be increased with a Premium plan.')}
              <p>{t('To increase, you will have to upgrade to Premium plan, which you can do here.')}</p>
            </div>
            :
            null
        }
        <div className="sales-tracker-limit-modal-content">
          <div className="sales-tracker-limit-modal-sliders-wrapper" style={{marginTop: 16}}>
            <div className="sales-tracker-limit-modal-slider-wrapper">
              <p className="sales-tracker-limit-modal-slider-title">
                {t('Store Tracking Limit')}
              </p>
              <div className="sales-tracker-limit-modal-slider">
                <span>{store}</span>
                <Slider defaultValue={store}
                        min={50}
                        max={500}
                        step={25}
                        tooltip={{ open: false }}
                        onChange={(value) => setStore(value)}
                />
                <span>500</span>
              </div>
            </div>
            <div className="sales-tracker-limit-modal-slider-wrapper">
              <p className="sales-tracker-limit-modal-slider-title">
                {t('Product Tracking Limit')}
              </p>
              <div className="sales-tracker-limit-modal-slider" style={{marginTop: 8}}>
                <span>{product}</span>
                <Slider defaultValue={product}
                        min={100}
                        max={1000}
                        step={50}
                        tooltip={{ open: false }}
                        onChange={(value) => setProduct(value)}
                />
                <span>1000</span>
              </div>
            </div>
          </div>
          <div className="sales-tracker-limit-modal-cost">
            {`Subscription Cost: $${calculateCost()}/${currentPlan?.price_type?.id}`}
          </div>
        </div>
        <Divider type={"horizontal"} style={{margin: '24px auto'}} />
        <div className="sales-tracker-limit-modal-btn-wrapper">
          <ButtonComponent text={t('Cancel')}
                           className={'sales-tracker-limit-modal-btn-cancel'}
                           onClick={cancelHandler}
          />
          <ButtonComponent text={t('Next Step')}
                           className={'sales-tracker-limit-modal-btn-next'}
                           onClick={okHandler}
                           disabled={isCurrentPrem && (store === storeLimit && product === productLimit)}
          />
        </div>
      </>
    ),
    2: (
        <>
          <div className="load-filter-preset-title">
            {t('Upgrade Plan')}
          </div>
          <div className="load-filter-preset-subtitle" style={{marginBottom: 0}}>
            {t('Are you sure you want to upgrade your plan?')}
          </div>
          <div className="sales-tracker-limit-modal-content">
            <div className="plan-switch-wrapper">
              <PlanInfo t={t} planName={currentPlan?.plan?.name} price={currentPlan?.price} priceType={currentPlan?.price_type?.id} />
              <Icon role={'icon'} type={'double_arrow_st'} />
              <PlanInfo t={t} planName={premiumPlan?.name} recalculate={true} price={calculateCost()} priceType={currentPlan?.price_type?.id} />
            </div>
            <div className="status">
              <Icon type={'check_mark_circle_border'} role={'icon'} color={'#16895A'} />
              <span>
                {
                  'All features in Premium Plan' +
                  (
                    store > storeLimit ?
                      ` + ${store - storeLimit} more Store Trackings`
                      :
                      store === storeLimit ? '' : ` - ${storeLimit - store} less Store Trackings`
                  ) + (
                    product > productLimit ?
                      ` and + ${product - productLimit} more Product Trackings`
                      :
                      product === productLimit ? '' : ` and - ${productLimit - product} less Product Trackings`
                  )
                }
              </span>
            </div>
            <div className="payment-options">
              <div className="payment-options-title">
                {t('Payment Options')}
              </div>
              {
                [
                  {
                    type: 'now',
                    title: t('Pay Now'),
                    text: t('You will be billed immediately after checkout'),
                    onCheck: setPaymentType,
                    checkedPaymentType: paymentType,
                  },
                  {
                    type: 'schedule',
                    title: t('Schedule Payment'),
                    text: t('You will be billed at the end of the billing period'),
                    onCheck: setPaymentType,
                    checkedPaymentType: paymentType,
                  },
                ].map((payment) => (
                  <PaymentOptionCard key={payment.type} {...payment} />
                ))
              }
            </div>

          </div>
          <div className="sales-tracker-limit-modal-btn-wrapper" style={{marginTop: 24}}>
            <ButtonComponent text={t('Back')}
                             className={'sales-tracker-limit-modal-btn-cancel'}
                             onClick={cancelHandler}
            />
            <ButtonComponent text={t('Next Step')}
                             className={'sales-tracker-limit-modal-btn-next'}
                             onClick={okHandler}
            />
          </div>
        </>
    ),
    3: (
      <>
        <div className="load-filter-preset-title">
          {t('Confirmation')}
        </div>
        <div className="load-filter-preset-subtitle" style={{marginBottom: 0}}>
          {
            calculateCurrentCost() > calculateCost() ?
              t('Are you sure you want to downgrade your plan? Your extra tracked stores will be removed. This action can not be reversed.')
              :
              t('Are you sure you want to upgrade your plan?')
          }
        </div>
        <div className="sales-tracker-limit-modal-content">
          <div className="final-confirmation-plan switch-plan-wrapper">
            <Spin spinning={spinner}>
              <PlanInfo t={t}
                        planName={premiumPlan?.name}
                        recalculate={true}
                        price={calculateCost()}
                        priceType={currentPlan?.price_type?.id}
                        now={paymentType === 'now'}
                        discount={onBoarding.discountResult}
              />
            </Spin>
          </div>
          <div className="discount-wrapper">
            <DiscountCard
              key={'discount'}
              onSubmit={handleCheckDiscount}
              error={onBoarding.discountError}
              clearError={handleClearError}
              discount={onBoarding.discountResult}
              clearDiscount={handleClearDiscount}
              fetching={onBoarding.discountFetching}
              style={{padding: 0, marginTop: 16}}
              isMobile={isMobile}
              priceType={currentPlan?.price_type?.id}
              isSt={true}
            />
          </div>
        </div>
        <div className="sales-tracker-limit-modal-btn-wrapper" style={{marginTop: 24}}>
          <ButtonComponent text={t(calculateCurrentCost() > calculateCost() ? 'Downgrade Plan' : 'Upgrade Plan')}
                           className={cls('sales-tracker-limit-modal-btn-upgrade', {
                             'downgrade': calculateCurrentCost() > calculateCost()
                           })}
                           onClick={okHandler}
          />
        </div>
      </>
    ),
    4: (
      <>
        <div className="load-filter-preset-title">
          {t('Add Credit Card')}
        </div>
        <ChangeCreditCardForm customClass={'add-card'}
                              paymentMethod={hasCard}
                              billingInfo={{country: defaultUserCountry}}
                              onCancel={cancelHandler}
                              onSubmit={okHandler}
                              getCountries={getCountries}
                              countries={onBoarding?.countries}
                              isMobile={isMobile}
                              theme={theme}
                              setChargebeeCardFetching={setChargebeeCardFetching}
                              errorSave={errorSave}
                              intent={{
                                result: onBoarding?.intentResult,
                                error: onBoarding?.intentError,
                                createIntent: createIntent,
                                clearIntent: clearIntent,
                                amount: onBoarding.discountResult ?
                                  (premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price * 100 - discountCash)
                                  :
                                  premiumPlan?.prices?.[currentPlan?.price_type?.id]?.price,
                              }}
                              isSt={true}
        />
      </>
    )
  }

  useEffect(() => {
    if (updateSubscriptionResult) {
      notification.destroy();
      openNotification({
        style: { minWidth: '716px' },
        message: t(paymentType === 'now' && calculateCurrentCost() <= calculateCost() ? 'Your plan has been successfully upgraded' : 'Your plan change has been successfully scheduled'),
        type: paymentType === 'now' && calculateCurrentCost() <= calculateCost() ? 'success' : 'addSchedule',
      });
      setVisible(false);
      setChargebeeCardFetching(false);
      changeSubscriptionReset();
      view === 'stores' ? getStores({page: 1,page_size: 50}) : getProducts({page: 1,page_size: 50})
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [updateSubscriptionResult])

  useEffect(() => {
      if (step === 3 && (paymentType === 'now' || onBoarding.discountResult)) {
        let data = {
          plan: {2: premiumPlan?.prices?.[currentPlan?.price_type?.id]},
          'discount_code': discountId || null,
          force: paymentType === 'now' && calculateCurrentCost() <= calculateCost(),
          new_limits: {
            stores_limit: store,
            products_limit: product
          }
        };
        getCharge(data);
      }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [discountId, step])

  return (
    <div className={'load-filter-preset-wrapper sales-tracker-limit-modal-wrapper'}>
      <Spin spinning={fetching}>
        {content[step]}
      </Spin>
    </div>
  );
};

export default LimitModal;
