import React from 'react';

const DayMonneyBack = ({width, height, color, className, style, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6 15C6 14.4477 6.44772 14 7 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H7C6.44772 16 6 15.5523 6 15Z"
      fill="#707BA0"/>
    <path d="M16 9C15.4477 9 15 9.44772 15 10C15 10.5523 15.4477 11 16 11H22V9H16Z" fill="#707BA0"/>
    <path d="M9 9.00024L11 11.0002H2V9.00024H9Z" fill="#707BA0"/>
    <path
      d="M16.2 6C17.0566 6 17.6389 6.00078 18.089 6.03755C18.5274 6.07337 18.7516 6.1383 18.908 6.21799C19.2843 6.40974 19.5903 6.7157 19.782 7.09202C19.8617 7.24842 19.9266 7.47262 19.9624 7.91104C19.9992 8.36113 20 8.94342 20 9.8V15C20 15.5523 20.4477 16 21 16C21.5523 16 22 15.5523 22 15V9.75873C22 8.95374 22 8.28938 21.9558 7.74818C21.9099 7.18608 21.8113 6.66937 21.564 6.18404C21.1805 5.43139 20.5686 4.81947 19.816 4.43598C19.3306 4.18868 18.8139 4.09012 18.2518 4.04419C17.7106 3.99998 17.0463 3.99999 16.2413 4H11C10.4477 4 10 4.44772 10 5C10 5.55229 10.4477 6 11 6H16.2Z"
      fill="#707BA0"/>
    <path
      d="M4.18404 4.43614C4.24069 4.40728 4.29777 4.38044 4.3553 4.35547L6.02892 6.02909C5.98861 6.03172 5.94935 6.03459 5.91104 6.03772C5.47262 6.07354 5.24842 6.13847 5.09202 6.21816C4.7157 6.4099 4.40974 6.71586 4.21799 7.09219C4.1383 7.24859 4.07337 7.47279 4.03755 7.91121C4.00078 8.3613 4 8.94359 4 9.80017V14.2002C4 15.0567 4.00078 15.639 4.03755 16.0891C4.07337 16.5275 4.1383 16.7517 4.21799 16.9081C4.40974 17.2845 4.7157 17.5904 5.09202 17.7822C5.24842 17.8619 5.47262 17.9268 5.91104 17.9626C6.36113 17.9994 6.94342 18.0002 7.8 18.0002H16.2C16.9837 18.0002 17.5378 17.9995 17.9711 17.9712L19.6447 19.6449C19.2094 19.8338 18.7483 19.9154 18.2518 19.956C17.7106 20.0002 17.0463 20.0002 16.2413 20.0002H7.75873C6.95376 20.0002 6.28937 20.0002 5.74818 19.956C5.18608 19.91 4.66938 19.8115 4.18404 19.5642C3.43139 19.1807 2.81947 18.5688 2.43598 17.8161C2.18868 17.3308 2.09012 16.8141 2.0442 16.252C1.99998 15.7108 1.99999 15.0464 2 14.2415V9.75886C1.99999 8.9539 1.99998 8.28953 2.0442 7.74834C2.09012 7.18624 2.18868 6.66954 2.43598 6.18421C2.81947 5.43156 3.43139 4.81964 4.18404 4.43614Z"
      fill="#707BA0"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
          fill="#707BA0"/>
  </svg>

);

export default DayMonneyBack;
