import React, { useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import BDCard from './BDCard';

export default function BDlist({
  fetching,
  list,
  next,
  getNextList,
  searchText,
  filterOrder,
  onChange,
}) {
  const bdRef = useRef();

  useEffect(() => {
    const bdContent = bdRef?.current;
    if (bdContent) {
      bdContent.addEventListener('scroll', onScrollHandlerFunc);
    }
    return () => {
      bdContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  useEffect(() => {
    scrollToTop();
  }, [searchText, filterOrder]);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !fetching && !!next) {
      getNextList();
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  function scrollToTop() {
    const bdContent = bdRef?.current;
    if (bdContent) {
      bdContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  return (
    <Spin spinning={fetching} size="large">
      <div className="bd-list" ref={bdRef}>
        <Row
          gutter={[
            { xs: 16, sm: 16, md: 16 },
            { xs: 16, sm: 16, md: 16 },
          ]}
          className="bd-list-wrapper"
        >
          {list?.map((card) => (
            <Col key={card?.id} xs={24} sm={12} xl={8}>
              <BDCard {...card} onChange={onChange} />
            </Col>
          ))}
        </Row>
      </div>
    </Spin>
  );
}
