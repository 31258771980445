import React from 'react'
import {Radio} from 'antd'
import './styles.less'

const PaymentOptionCard = ({type, title, text, checkedPaymentType, onCheck, ...props}) => {

  return (
    <div className={'payment-option-card'}>

      <div className='info-wrp'>
        <span className='info-title'>{title}</span>
        <span className='info-text'>{text}</span>
      </div>

      <Radio className={'custom-radio'}
             checked={type === checkedPaymentType}
             onClick={() => onCheck(type)}
      />

    </div>
  )
}

export default PaymentOptionCard
