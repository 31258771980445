import React, { useEffect, useState } from 'react';
import cls from 'classname';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OnboardingActions from '../../Redux/OnboardingRedux';
import AuthActions from '../LoginPage/reducer';
import LoginHeader from '../../Components/LoginHeader';
import { Layout, Modal, Spin } from 'antd';
import SkipOffer from './components/SkipOffer';
import EcommerceExperts from './components/EcommerceExperts';
import OrderNow from './components/OrderNow';
import FreeBonuses from './components/FreeBonuses';
import MoneyBack from './components/MoneyBack';
import PickYourStores from './components/PickYourStores';
import HowItWorks from './components/HowItWorks';
import ExampleOfStores from './components/ExampleOfStores';
import FAQ from './components/FAQ';
import Icon from '../../Icon';
import SkipModal from './components/SkipModal';
import './styles.less';

const ClickFunnelsPage = () => {

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMobile = useSelector(store => store?.nav?.isMobile);
  const loading = useSelector(store => store?.onBoarding?.clickFunnelLoading);
  const clickFunnelSessionLoading = useSelector(store => store?.onBoarding?.clickFunnelSessionLoading);
  const clickFunnelProducts = useSelector(store => store?.onBoarding?.clickFunnelProducts);

  const [checkedStores, setCheckedStores] = useState([]);
  const [checkedOffer, setCheckedOffer] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(OnboardingActions.getClickFunnelProductsRequest());
    dispatch(AuthActions.setOfferId(null));
  }, []);

  const createSessionHandler = (data) => dispatch(OnboardingActions.createClickFunnelSessionRequest(data));

  return (
    <Layout>
        <div className="login-row"
             style={{height: 'unset'}}
        >
          <LoginHeader withLogout={false} />
          <SkipOffer setVisible={setVisible} isMobile={isMobile} isOnboarding={pathname?.includes('onboarding')} />

            <div className={cls("clickfunnels-page")}>
            <div className={'clickfunnels-page-main-wrapper'}>
             <div className={'clickfunnels-page-main-wrapper-left'}>
               <EcommerceExperts isMobile={isMobile} />
               <OrderNow />
               <FreeBonuses isMobile={isMobile} />
             </div>
             <div className={'clickfunnels-page-main-wrapper-right'}>
               <MoneyBack />
               <Spin spinning={clickFunnelSessionLoading}>
                 <PickYourStores checkedStores={checkedStores}
                                 setCheckedStores={setCheckedStores}
                                 checkedOffer={checkedOffer}
                                 setCheckedOffer={setCheckedOffer}
                                 loading={loading}
                                 clickFunnelProducts={clickFunnelProducts || []}
                                 createSession={createSessionHandler}
                 />
               </Spin>
             </div>
           </div>
            <HowItWorks isMobile={isMobile} />
            <ExampleOfStores isMobile={isMobile} />
            <FAQ />
          </div>
          <Modal
            className={cls("change-modal custom-modal")}
            getContainer={() => document.getElementById('global-wrap')}
            open={visible}
            {...(isMobile ? {transitionName: ''} : null)}
            centered={!isMobile}
            closeIcon={
              <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
            }
            width={500}
            footer={null}
            closable="true"
            onCancel={() => {
              setVisible(false);
            }}
            destroyOnClose
          >
            <SkipModal setVisible={setVisible} isMobile={isMobile} />
          </Modal>
        </div>
    </Layout>
  );
};

export default ClickFunnelsPage;
