import React, {useState} from 'react';
import {Divider} from 'antd';
import Icon from '../../../Icon';
import acc from 'accounting';
import {useTranslation} from "react-i18next";
import InputComponent from "../../../Components/Input";
import './MinMaxInput.less'

const areEqual = (prev, next) => JSON.stringify(next?.data[next?.field]) === JSON.stringify(prev?.data[prev?.field]);

export default React.memo(function MinMaxInput({ iconType, data, field, text, tooltip, onChange, isMobile }) {
  //state to change icon colors
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="min-max-input-wrapper">
      <Icon
        color={(focused || data?.[field]?.max || data?.[field]?.min) ? '#225aea' : '#707ba0'}
        role="button"
        type={iconType}
        titleText={t(tooltip)}
        tooltipProps={{
          placement: 'right',
          destroyTooltipOnHide: true,
          trigger: isMobile ? 'click' : 'hover',
        }}
      />

      <InputComponent
        placeholder={t(text)}
        name={field + '_min'}
        prefix={<span>Min<Divider type='vertical' className={'min-max-divider'} /></span>}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={
          data?.[field].min
            ? acc.formatNumber(data?.[field].min, 0, ',', '')
            : ''
        }
        onChange={e => onChange(e, field, 'min')}
      />

      <div className="cost-divider" style={{width: 58, height: 24, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Icon type="min_max_input_divider"
              role={'icon'}
              width={7}
              height={10}
        />
      </div>

      <InputComponent
        placeholder={t(text)}
        name={field + '_max'}
        prefix={<span>Max<Divider type='vertical' className={'min-max-divider'}/></span>}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={
          data?.[field].max
            ? acc.formatNumber(data?.[field].max, 0, ',', '')
            : ''
        }
        onChange={e => onChange(e, field, 'max')}
      />
    </div>
  );
}, areEqual)
