import React from 'react';
import './MainBlock.less';

export const MainBlockSkeleton = ({isMobile=false, withMarginTop=false}) => {

  return (
    <div className="report-main-block report-main-block-skeleton"
         style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: withMarginTop ? 24 : 0}}
    >
      {
        isMobile ?
          null
          :
          <span className="link skeleton" style={{width: 306, height: 24}}/>
      }
      <div style={{width: isMobile ? '100%' : "initial"}}>
        <span className="link skeleton" style={{width: isMobile ? '100%' : 156, height: 40, marginRight: isMobile ? 0 : 16}}/>
        <span className="link skeleton" style={{width: isMobile ? '100%' : 202, height: 40, marginTop: isMobile ? 16 : 0}}/>
      </div>

    </div>
  );
};

export default MainBlockSkeleton;
