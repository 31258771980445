import React, {useEffect, useState} from 'react';
import cls from "classname";
import {useTranslation} from "react-i18next";
import Creators from "./reducer";
import {Dropdown, Spin} from "antd";
import ButtonComponent from "../../Components/Button";
import ArrowSelectDown from "../../Icon/img/ArrowSelectDown";
import Modal from "../../Components/Modal";
import {connect} from "react-redux";

// const text = 'You can import the product to your existing AutoDS store.';

const ModalAutoDSImport = ({isMobile, autoDS, changeVisibleModalAutoDS, importProductToAutoStore, resetState}) => {

  const { loading, isVisibleModal, stores, importProductResult } = autoDS;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [selectStore, setSelectStore] = useState(null);
  const [storeName, setStoreName] = useState(null);


  const closeModalAutoDS = () => changeVisibleModalAutoDS({isVisibleModal: null, importProductId: null})

  const handleClose = () => {
    if (selectStore) setSelectStore(null)
    if (isVisibleModal === 'import') closeModalAutoDS();
  }

  const handleVisibleChange = (value) => {
    setVisible(value);
    if(!value && storeName) setStoreName(null)
  };

  const handleSelectStore = (store) => {
    if (selectStore?.id !== store?.id) setSelectStore(store);
    if(storeName) setStoreName(null)
    setVisible(false);
  };

  const handleImport = () => {
    let data = {
      ...autoDS?.importProductId,
      'store_id': selectStore?.id
    }
    importProductToAutoStore(data);
  }

  useEffect(() => {
    if (isVisibleModal === 'import' && stores?.length) setSelectStore(stores?.[0]?.store);
  }, [isVisibleModal])

  useEffect(() => {
    if (importProductResult) resetState()
  }, [importProductResult])

  return (
    <Modal
      className={cls('shopify-import autods-import')}
      title={t('Import Product to AutoDS')}
      open={isVisibleModal === 'import'}
      width={596}
      handleOk={() => {
      }}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
      <Spin spinning={loading}>
        <p>
          {t('To prevent complications when importing products into various regions, we recommend selecting the "WorldWide" as the "Ship to Region" option in your')} <a href='https://platform.autods.com/settings/supplierSettings' target='_blank'>{t('AutoDS Supplier Settings')}</a>
        </p>

        <div className={'import-dropdown-wrap'}>
          <Dropdown
            overlayClassName={'dropdown-store-wrapper'}
            destroyPopupOnHide={true}
            getPopupContainer={trigger => isMobile ? document.getElementById('global-wrap') : trigger.parentNode}
            onOpenChange={handleVisibleChange}
            dropdownRender={() => (
              <>
                <ul className="user_store-list" style={{ maxHeight: 168 }}>
                  {
                    stores?.map(({ store }) => (
                      <li key={store?.id}
                          className="user_store-item user_store-item_clickable"
                          onClick={() => handleSelectStore(store )}
                          style={{flexDirection: 'column', alignItems: 'flex-start'}}
                      >
                        <span className="user_store-name">{store?.name}</span>
                        <span className="user_store-link">{store?.store_url}</span>
                      </li>
                    ))
                  }
                </ul>
              </>
            )}
            trigger={['click']}
            open={visible}
          >
            <div className="dropdown-store">
            <span className="dropdown-store_name">
              {selectStore?.store_url}
            </span>
              <ArrowSelectDown/>
            </div>
          </Dropdown>

          <ButtonComponent
            type="primary"
            disabled={!Object.keys(selectStore ? selectStore : {})?.length}
            className="btn-primary"
            onClick={handleImport}
            style={{height: 48}}
          >
            {t('Import')}
          </ButtonComponent>
        </div>
      </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  importProductToAutoStore: (data) => dispatch(Creators.importProductToAutoStoreRequest(data)),
  resetState: () => dispatch(Creators.resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDSImport);
