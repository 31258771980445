import React, { useCallback, useEffect, useState } from 'react';
import {connect, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Row, Col, Spin, Modal} from 'antd';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import AdminMainBlock from './components/AdminMainBlock';
import Chart from '../../Components/Charts/Chart';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import { OverlayScrollbar } from '../../Components/ScrollBar';
import AdminTrackerTable from './components/AdminTrackerTable';
import Icon from "../../Icon";
import NumbersBreakdownDatabaseBlock from "../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock";
import {dateOptions} from "../../Utils/utils";
import ChartBlock from "../SalesTrackerPage/components/ChartBlock";
import './styles.less';

const reportTitle = {
  revenue: 'Total Revenue',
  sales: 'Total Sales',
  unique_products_sold: 'Total Unique Products Sold',
}

const reportNames = {
  sales: 'Sales Report',
  revenue: 'Revenue Report',
  unique_products_sold: 'Unique Products Report'
}

export const AdminTrackerReportDetailsPage = (
  {
    fetching,
    error,
    userInfo,
    breadcrumbs,
    dispatch,
    getProductListByStore,
    selectedStoreTableDataLoading,
    getExpandableChart,
    downloadSalesReport,
    downloadRevenueReport,
    salesTracker,
    filterDates,
    setFilters,
    setPeriod,
    ...props
  }) => {
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, report } = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;

  //selected store chart + main info
  const selectedStore = useSelector(store => store?.adminStoreTable?.selectedStore);
  const stores = useSelector(store => store?.adminStoreTable?.stores?.results) || [];
  const selectedStoreNew = stores?.filter(el => +el.id === +id)?.[0] || {};
  //selected store table data (sales report)
  const storeProducts = useSelector(store => store?.adminStoreTable?.selectedStoreTableData) || [];

  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const timeZone = selectedStoreNew?.store_timezone ? selectedStoreNew?.store_timezone.split('UTC')[1] : null;

  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  // modal show/hide
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [recordToCalc, setRecordToCalc] = useState(null);

  const [data, setData] = useState([]);

  const showFreePlanError = false;

  useEffect(() => {
    document.title = `Store Table - Dropship`;
    if(!stores.length || !Object.keys(reportNames).includes(report)) navigate('/sales-tracker/stores', {replace: true});
    if (firsLoading) setTimeout(() => setFirsLoading(false), 0);
    if (
      selectedStoreNew?.first_sale_date
      && dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') < 30
      && dayjs(minDate) < dayjs(selectedStoreNew?.first_sale_date)
    ) {
      const newData = dateOptions.filter(el => dayjs().utcOffset(timeZone ? timeZone : '+0:00').diff(dayjs(selectedStoreNew?.first_sale_date), 'days') >= el.days);
      let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1]
      let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2]
      let period = newData?.[newData?.length - 1]?.id
      let min = dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') < 7 ?
        dayjs(selectedStoreNew?.first_sale_date).format('YYYY-MM-DD')
        :
        dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
      let max = dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') < 7 ?
        dayjs().format('YYYY-MM-DD')
        :
        [1,2].includes(period) ? min : dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min, max}});
      setPeriod(dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedStoreNew?.first_sale_date), 'days') < 7 ?
        [...newData]
        :
        [newData[newData?.length - 1]]);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (breadcrumbs?.length <= 2) {
      props.addBreadCrumb({
        name: t(reportTitle[report]),
      });
    }
    if (breadcrumbs?.length === 3) {
      props.removeBreadCrumb();
      props.addBreadCrumb({
        name: t(reportTitle[report]),
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [report]);

  useEffect(() => {
    if (report !== 'sales') {
      setData(selectedStore?.table ? [...selectedStore?.table.slice(0, 49)] : [])
      setSortOrder(null)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [selectedStore])

  useEffect(() => {
    if (report === 'sales') {
      !firsLoading && getProductListByStore({page: pageNumber, store_id: id, page_size: pageSize, ordering: sortOrder, date_range: {min: minDate, max: maxDate}});
    } else {
      if (sortOrder) {
        let newData;
        if (sortOrder === 'monthly_sales') newData = [...selectedStore?.table].sort((a, b) => a?.sales - b?.sales);
        else if (sortOrder === '-monthly_sales') newData = [...selectedStore?.table].sort((a, b) => b?.sales - a?.sales);
        else if (sortOrder === 'revenue') newData = [...selectedStore?.table].sort((a, b) => a?.revenue - b?.revenue);
        else if (sortOrder === '-revenue') newData = [...selectedStore?.table].sort((a, b) => b?.revenue - a?.revenue);
        else if (sortOrder === 'unique') newData = [...selectedStore?.table].sort((a, b) => a?.unique - b?.unique);
        else if (sortOrder === '-unique') newData = [...selectedStore?.table].sort((a, b) => b?.unique - a?.unique);
        setData([...newData.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)]);
      } else {
        setData(selectedStore?.table ? [...selectedStore?.table.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)] : []);
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder, report]);

  useEffect(() => {
      getProductListByStore({
        page: pageNumber,
        store_id: id,
        page_size: pageSize,
        ordering: sortOrder,
        date_range: {min: minDate, max: maxDate}
      });
      props.getDetailedStoreInfo({id: id, date_range: {min: minDate, max: maxDate}})
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [minDate, maxDate])


  useEffect(() => {
    if (showFreePlanError) {
      setModal('freePlanError');
      setVisible(true);
    }
  }, [showFreePlanError])

  const modalBlocks = {
    breakdown: <NumbersBreakdownDatabaseBlock product={recordToCalc}/>,
  }

  const modalBlocksWidth = {
    breakdown: 1040,
  }

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  return (
    //custom scrollbars
    <OverlayScrollbar visibility="hidden">
      <div className="product-database-page sales-tracker-page ">
            <BreadcrumbsComponent
              breadCrumbs={breadCrumbs}
              isMobile={false}
              isAdmin={false}
              timeInfo={selectedStoreNew?.last_updated}
            />

            <AdminMainBlock data={{
              title: selectedStoreNew?.title,
              link: selectedStoreNew?.custom_domain,
              logo: selectedStoreNew?.logo || selectedStoreNew?.favicon,
              report: report,
              id: id,
              reportName: reportNames[report],
              dates: {min: minDate, max: maxDate},
              // timeInfo - 1st sale date
              timeInfo: selectedStoreNew?.first_sale_date,
              timeZone: timeZone,
            }}
                            downloadSalesReport={downloadSalesReport}
                            downloadRevenueReport={downloadRevenueReport}
                            dateOptions={dateOptions}
            />

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ChartBlock type={report}
                            subTitle={minDate && maxDate ?
                              `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                              :
                              `${dayjs(selectedStore?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(selectedStore?.charts?.revenue[selectedStore?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`}
                            totalCount={selectedStore?.total ? report === 'unique_products_sold' ? selectedStore?.total?.unique : selectedStore?.total?.[report] : 0}
                            link={false}
                >
                  <Chart data={selectedStore?.charts ? report === 'unique_products_sold' ? [...selectedStore?.charts?.unique?.chart] : [...selectedStore?.charts?.[report]?.chart] : [] || []}
                         type={report}
                         animation={false}
                  />
                </ChartBlock>
              </Col>
            </Row>

        <AdminTrackerTable fetching={firsLoading || fetching}
                           minDate={minDate}
                           maxDate={maxDate}
                           loading={selectedStoreTableDataLoading}
                           onSort={handleSort}
                           pageSize={pageSize}
                           setPageSize={handleChangePageSize}
                           pageNumber={pageNumber}
                           setPageNumber={handleChangePageNumber}
                           totalProducts={report === 'sales' ? storeProducts?.count : selectedStore?.table?.length}
                           data={report === 'sales' ? storeProducts?.results : selectedStore?.table || []}
                           localData={data || []}
                           sortOrder={sortOrder}
                           isChanged={true}
                           report={report}
                           withSummary={true}
                           setVisible={setVisible}
                           setModal={setModal}
                           setRecordToCalc={setRecordToCalc}
                           getExpandableChart={getExpandableChart}
                           selectedStoreStats={selectedStore?.total}
        />

        <Modal
          className="change-modal custom-modal"
          getContainer={() => document.getElementById('global-wrap')}
          open={visible}
          centered={true}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => {
            setVisible(false);
            props.resetFreePlanError();
          }}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>

      </div>
    </OverlayScrollbar>
  );
};

AdminTrackerReportDetailsPage.propTypes = {
  isAdmin: PropTypes.bool,
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  fetching: state.adminStoreTable.loading,
  salesTracker: state.adminStoreTable,
  selectedStoreTableDataLoading: state.adminStoreTable.selectedStoreTableDataLoading,
  error: state.adminStoreTable.error,
  view: state.adminStoreTable.view,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  filterDates: state.adminStoreTable.filters.report_period,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getProductListByStore: (data) => dispatch(Creators.getAdminProductListByStoreRequest(data)),
  getExpandableChart: (data) => dispatch(Creators.getAdminExpandableChartRequest(data)),
  downloadSalesReport: (data) => dispatch(Creators.getAdminSalesReportRequest(data)),
  downloadRevenueReport: (data) => dispatch(Creators.getAdminRevenueReportRequest(data)),
  setFilters: (data) => dispatch(Creators.setAdminFilters(data)),
  setPeriod: (data) => dispatch(Creators.setAdminCheckedList(data)),
  getDetailedStoreInfo: (data) => dispatch(Creators.getAdminDetailedStoreInfoRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTrackerReportDetailsPage);
