import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Dropdown } from 'antd';
import cls from 'classname';
import Icon from '../../Icon/Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import ModalShopifyDelete from '../../Containers/ShopifyStore/ModalShopifyDelete'
import {useDispatch} from "react-redux";
import openNotification from "../Notification";
import Creators from "../../Containers/ShopifyStore/reducer";
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import './styles-shopify.less';

const apiKey = process?.env?.REACT_APP_SHOPIFY_STORE_KEY;

export default function ShopifyStore({ theme, userInfo, shopifyStore, getShopifyStore }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkOauth = shopifyStore?.checkOauth;
  const isVisibleModal = shopifyStore?.isVisibleModal;
  const [visible, setVisible] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [deleteStore, setDeleteStore] = useState(null)
  const [storeName, setStoreName] = useState(null);
  const disabledImport = !userInfo?.subscriptions?.[0]?.plan?.price
    && userInfo?.subscriptions?.[0]?.plan?.plan?.name !== 'free'
    && userInfo?.role?.id !== 4 ;
  const REDIRECT_URL_TO_OUR_SITE = document?.location?.origin + '/setting/account';

  let countStore = shopifyStore?.results?.length || 0
  const shopifyStatus = countStore ? 'connected' : 'not_connected';
  const shopifyTags = {
    coming: t('COMING SOON'),
    not_connected: t('Not Connected'),
    connected: t('Connected'),
  };

  useEffect(() => {
    getShopifyStore();
    dispatch(Creators.resetOauth());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (checkOauth && isVisibleModal === null && storeName !== null) {
      window.open(`https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=write_products&redirect_uri=${REDIRECT_URL_TO_OUR_SITE}&state=${userInfo?.id}&grant_options[]=value`, '_self')
    }
    else if (!checkOauth && typeof checkOauth !== 'object' && isVisibleModal === null) openNotification({
      type: 'warning',
      message: t('This store is already connected.'),
      style: { minWidth: '716px' },
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [checkOauth])

  const shopifyIcons = {
    coming: (
      <Icon role="icon" type="shopify_coming_icon" outline={theme !== 'dark'} />
    ),
    not_connected: (
      <Icon role="icon" type="shopify_connected_icon" outline={theme !== 'dark'} />
    ),
    connected: (
      <Icon role="icon" type="shopify_connected_icon" outline={theme !== 'dark'} />
    ),
  };

  function handleChange(e) {
    const { value } = e.target;
    setStoreName(value);
  }

  const handleVisibleChange = (value) => {
    setVisible(value)
  }

  const handleDisconnect = (store) => {
    setVisible(false)
    setDeleteStore(store)
    setVisibleModal(true)
  }

  const handleCloseModal = () => {
    setVisibleModal(false)
    setDeleteStore(null)
  }

  const shopifyComponent = {
    coming: (
      <div className="input-store-wrapper">
        <InputComponent
          suffix=".myshopify.com"
          placeholder={t('store-name')}
          className='input-store coming'
          value={storeName}
          onChange={handleChange}
        />

        <ButtonComponent
          type="primary"
          className='btn-store disabled'
        >
          {t('Connect')}
        </ButtonComponent>

      </div>),
    not_connected: (
      <div className="input-store-wrapper">
        <ButtonComponent
          type="primary"
          className={cls('btn-store', {
            disabled: disabledImport
          })}
          disabled={disabledImport}
          onClick={() => {
            dispatch(Creators.setNichesStep(1));
            dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl: document?.location?.pathname + (document?.location?.search || '')}));
          }}
        >
          {t('Connect')}
        </ButtonComponent>

      </div>),
    connected: (
      <Dropdown
        overlayClassName={'dropdown-store-wrapper'}
        destroyPopupOnHide={true}
        getPopupContainer={() => document.getElementById('global-wrap')}
        onOpenChange={handleVisibleChange}
        dropdownRender={() => (
          <ul className="user_store-list">
            {
              shopifyStore?.results?.map(({ id, name, domain }) => (
                <li key={id} className="user_store-item user_store-item_with-btn">
                  <div>
                    <span className="user_store-name">{name}</span>
                    <span className="user_store-link">{domain}</span>
                  </div>
                  <ButtonComponent
                    type="primary"
                    className="btn-primary red btn-cancel border-only"
                    onClick={()=> handleDisconnect({id, name, domain})}
                  >{t('Disconnect')}</ButtonComponent>
                </li>
              ))
            }
          </ul>
        )}
        trigger={['click']}
        open={visible}
      >
          <div className="dropdown-store">
            <span className="dropdown-store_name">
              {`${shopifyStore?.results?.[0]?.domain} ${countStore > 1 ? t('and _count_ more..', {count: countStore-1}) : ''}`}
            </span>
            <ArrowSelectDown/>
          </div>
      </Dropdown>
    ),
  }

  return (
    <Card className="account-shopify">
      <div className="account-shopify-wrap">
        <div className="account-shopify-info-block">
          <div className="shopify-header">
            <span className="account-title">
              {t('Shopify Store')}

              <span
                className={cls('shopify-tag', {
                  coming: shopifyStatus === 'coming',
                  not_connected: shopifyStatus === 'not_connected',
                  connected: shopifyStatus === 'connected',
                })}
              >
              {shopifyTags[shopifyStatus]}
            </span>
            </span>
          </div>
          <div
            className={cls('shopify-subtitle', {
              coming: shopifyStatus === 'coming',
            })}
          >
            {
              shopifyStatus === 'connected'
                ? t('Your Shopify store (_name_) is currently connected to Dropship.', {name: shopifyStore?.results?.[0]?.domain})
                : t('Connect your Shopify store to start importing products in just a few clicks.')
            }
          </div>
        </div>
        <div className="shopify-icons">
          {shopifyIcons[disabledImport ? 'coming' : shopifyStatus]}
        </div>
      </div>
      {
        shopifyComponent[shopifyStatus]
      }

      <ModalShopifyDelete
        isVisible={visibleModal && !!deleteStore}
        closeModal={handleCloseModal}
        deleteStore={deleteStore}
      />
    </Card>
  );
}
