import React from 'react';
import ButtonComponent from "../../../Components/Button";
import {useTranslation} from "react-i18next";
import {ArrowSelectDown} from "../../../Icon/img";
import Icon from "../../../Icon";
import AutoDSCreators from "../../AutoDS/reducer";
import {useDispatch} from "react-redux";
import './AutoDSBanner.less';

const AutoDsBanner = ({isConnected}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={'auto-ds-banner'}>
      <div className="auto-ds-banner-info-wrapper">
        <div className="auto-ds-banner-info-logo">
          <div>
            <Icon className="avatar-image" type="logo_simple" role="icon" width={40} height={40}/>
          </div>
          <Icon type="suppliers_plug" role="icon" />
          <div>
            <Icon type="auto_ds" role="icon" width={40} height={40}/>
          </div>

        </div>
        <div className="auto-ds-banner-info-text">
          <h3>
            {t('AutoDs Integration')}
            <span>
              {t('NEW')}
            </span>
          </h3>
          <p>
            {t('You can now import products from Dropship to AutoDS and fulfill your orders!')}
          </p>
        </div>
      </div>
      {
        isConnected ?
          <ButtonComponent className={'auto-ds-banner-btn disabled'}
                           text={null}
                           onClick={() => null}
          >
            <span>
              {t('Connected')}
            </span>
          </ButtonComponent>
          :
          <ButtonComponent className={'auto-ds-banner-btn'}
                           text={null}
                           onClick={() => {
                             dispatch(AutoDSCreators.changeVisibleModalAuto({
                               isVisibleModal: 'connect',
                               importProductId: null
                             }));
                           }}
          >
            <span>
              {t('Connect to AutoDS')}
            </span>
            <ArrowSelectDown/>
          </ButtonComponent>
      }
    </div>
  );
};

export default AutoDsBanner;
