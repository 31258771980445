import React from 'react'

import Images from '../Images'
import Icon from '../Icon'

function PaymentTypeCheckbox({type, text, isMobile, selectedMethod, setMethod, ...props}) {
  if (type === 'stripe' || type === 'chargebee') {
    return (
      <div className={'choice-payment-block'}>
        <div className='header-line'>
          <span>Credit / Debit Card</span>

          <div className='icon-card-list'>
            <div className='icon-card-list-item'> <img src={Images.visa} alt='' /></div>
            {isMobile ? null : <div className='icon-card-list-item'> <img src={Images.maestro} alt='' /></div>}
            <div className='icon-card-list-item'> <img src={Images.mastercard} alt='' /></div>
            <div className='icon-card-list-item'> <img src={Images.americanExpress} alt='' /></div>

            <span>more...</span>
          </div>
        </div>

        {
          (selectedMethod === 'stripe'|| type === 'chargebee')
          ? <div className='form-block'>{props.children}</div>
            : null
        }
      </div>
    )
  } else if (type === 'mollie') {
    return (
      <div className={'choice-payment-block'}>
        <div className='header-line'>
          <span>Credit Card (mollie)</span>

          <div className='icon-card-list'>
            <img src={Images.visa} alt='' />
            <img src={Images.mastercard} alt='' />
            <img src={Images.maestro} alt='' />
            <img src={Images.americanExpress} alt='' />

            <button className={`radio-btn${selectedMethod === 'mollie' ? ' active' : ''}`} onClick={() => setMethod('mollie')} />
          </div>
        </div>

        {selectedMethod === 'mollie' ? (
          <div className='info-block'>
            <Icon type='card' role='icon' color={'#C1C8DD'} opacity={1} />
            <p>{text}</p>
          </div>
        ) : null}
      </div>
    )
  } else if (type === 'payPal') {
    return (
      <div className={'choice-payment-block'}>
        <div className='header-line'>
          <img src={Images.payPal} alt='' />

          <div className='icon-card-list'>
            <img src={Images.visa} alt='' />
            <img src={Images.mastercard} alt='' />
            <img src={Images.maestro} alt='' />
            <img src={Images.americanExpress} alt='' />

            <button className={`radio-btn${selectedMethod === 'payPal' ? ' active' : ''}`} onClick={() => setMethod('payPal')} />
          </div>
        </div>
      </div>
    )
  } else if (type === 'bitPay') {
    return (
      <div className={'choice-payment-block'}>
        <div className='header-line'>
          <img src={Images.bitPay} alt='' />

          <div className='icon-card-list'>
            <img src={Images.bitcoinBtc} alt='' />
            <img src={Images.bitcoinCash} alt='' />
            <img src={Images.litecoinCash} alt='' />
            <img src={Images.ethereum} alt='' />
            <img src={Images.xrp} alt='' />
            <img src={Images.chainlink} alt='' />
            <img src={Images.cardano} alt='' />

            <button className={`radio-btn${selectedMethod === 'bitPay' ? ' active' : ''}`} onClick={() => setMethod('bitPay')} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={'choice-payment-block'}>
        <div className='form-block'>{props.children}</div>
      </div>
    )
  }
}

export default PaymentTypeCheckbox
