import React from 'react';
import Icon from '../../../Icon';

export default function EmptyData({ text }) {
  return (
    <div className="empty-data-block">
      <div className="empty-data-block_icon">
        <Icon
          width={64}
          height={64}
          role="icon"
          type="empty_warning"
          color="#C5CCE3"
        />
      </div>
      <div className="empty-data-block_text">{text}</div>
    </div>
  );
}
