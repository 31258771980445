import React from 'react';
import { Row, Col } from 'antd';
import cls from 'classname';
import EmptyData from '../EmptyData';
import BDListDetails from './BDListDetails';
import ButtonComponent from "../../../../Components/Button";
import './styles.less';

export default function BenefitsDrawbacks({
  b_and_d,
  openModal,
  saveInfo,
  getBdList,
}) {
  function openDeleteModal() {
    openModal('bd_delete');
  }
  function openAddModal() {
    getBdList();
    openModal('bd_import');
  }

  const benefitsList = b_and_d?.filter((el) => el._type === 'Benefits');
  const drawbacksList = b_and_d?.filter((el) => el._type === 'Drawbacks');
  return (
    <div className="benefits-drawbacks-block">
      <div className="block-with-label ">
        <div className="block-with-label_label bold-text">
          <div>
            Benefits{' '}
            <span className="blue-block">{benefitsList?.length || 0}/6</span>
          </div>
          <div>
            Drawbacks{' '}
            <span className="blue-block">{drawbacksList?.length || 0}/6</span>
            <ButtonComponent
              type="primary"
              className="btn-primary"
              onClick={openAddModal}
            >
              Import
            </ButtonComponent>
          </div>
        </div>
      </div>
      <div
        className={cls('block-with-list', {
          empty_data: !b_and_d?.length,
        })}
      >
        {b_and_d?.length ? (
          <Row className="bd-list-wrapper" gutter={[32, 0]}>
            <Col span={12} className="bd-list-benefits">
              <BDListDetails
                list={benefitsList}
                onDelete={openDeleteModal}
                saveInfo={saveInfo}
              />
            </Col>
            <Col span={12} className="bd-list-drawbacks">
              <BDListDetails
                list={drawbacksList}
                onDelete={openDeleteModal}
                saveInfo={saveInfo}
              />
            </Col>
          </Row>
        ) : (
          <EmptyData text="No Benefits & Drawbacks imported" />
        )}
      </div>
    </div>
  );
}
