import React from 'react';
import {Col, Row} from "antd";
import MainBlockSkeleton from "./MainBlockSkeleton";
import SalesTrackerNoteSkeleton from "./SalesTrackerNoteSkeleton";
import MainBlockNewSkeleton from "./MainBlockNewSkeleton";


const values = {
  sales: {
    first: 84,
    second: 186,
    third: 46,
  },
  revenue: {
    first: 108,
    second: 186,
    third: 142,
  },
  unique_products_sold: {
    first: 207,
    second: 186,
    third: 74,
  },
  product: {
    first: 207,
    second: 186,
    third: 74,
  }
}

const TrackerReportDetailsPageSkeleton = ({report, autoDS=false, isMobile=false, withMarginTop=false}) => {

  return (
    <div style={{padding: isMobile ? 0 : '24px 24px 0'}} className="product-database-page sales-tracker-page store-tracker-skeleton">

      <div style={{display: 'flex', alignItems: 'center'}}>
        <span className="link skeleton" style={{width: 91, height: 16}}/>
        <span className="link skeleton" style={{width: 24, height: 24, margin: '0 8px'}}/>
        <span className="link skeleton" style={{width: 74, height: 16}}/>
      </div>

      {
        report === 'product' && (
          <>
            {isMobile ? null : <SalesTrackerNoteSkeleton />}
          <MainBlockNewSkeleton isMobile={isMobile} autoDS={autoDS}/>
          </>
        )
      }

      <MainBlockSkeleton isMobile={isMobile} withMarginTop={withMarginTop}/>

      <Row gutter={[16, 16]} style={{marginLeft: 'unset', marginRight: 'unset', marginTop: isMobile ? 16 : 0}}>
        <Col span={24} className='chart-block' style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 16}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: isMobile ? "flex-start" : "center", flexDirection: isMobile ? 'column' : 'row'}}>
              <span className="link skeleton" style={{width: values?.[report]?.first, height: 24, marginRight: 16}}/>
              <span className="link skeleton" style={{width: values?.[report]?.second, height: 20}}/>
            </div>
          </div>
          <div style={{marginTop: 8}}>
            <span className="link skeleton" style={{width: values?.[report]?.third, height: 40}}/>
          </div>
          <div style={{marginTop: 16}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8}}>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
                <span className="link skeleton" style={{width: 33, height: 16, marginTop: 12}}/>
              </div>
              <div style={{width: 'calc(100% - 39px)', height: 227}}>
                <span className="link skeleton" style={{width: '100%', height: '100%'}}/>
              </div>
            </div>
            <div style={{width: 'calc(100% - 39px)', marginLeft: 39, display: "flex", justifyContent: "space-between"}}>
              {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
                <span key={el * index} className="link skeleton" style={{width: el, height: 16}}/>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TrackerReportDetailsPageSkeleton;
