import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Gps = ({width, height, className, color = '#6e7dae', outline}) =>
  outline ? (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         viewBox="0 0 24 24"
         className={cls(className, 'icon icon-speaker icon-speaker-outline')}
         fill="none"
    >
      <path
        d="M19 11.5C19 10.6716 19.6716 10 20.5 10C21.3284 10 22 10.6716 22 11.5C22 12.3284 21.3284 13 20.5 13C19.6716 13 19 12.3284 19 11.5Z"
        fill="#707BA0"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M5.98069 13.8037L6.71565 17.4785C6.77628 17.7816 7.04244 17.9998 7.35158 17.9998C7.70976 17.9998 8.00011 17.7095 8.00011 17.3513V13.9998H10.0001V17.3513C10.0001 18.814 8.81432 19.9998 7.35158 19.9998C6.08908 19.9998 5.00208 19.1087 4.75449 17.8707L4.01953 14.1959L5.98069 13.8037Z"
            fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M21 7.5087C21 4.80548 18.0659 3.12268 15.7327 4.4877L11.8344 6.76827C10.4556 7.57485 8.88709 7.99996 7.28976 7.99996H5.5C3.567 7.99996 2 9.56697 2 11.5C2 13.433 3.567 15 5.5 15H7.28569C8.88534 15 10.4561 15.4263 11.8362 16.2351L15.7304 18.5172C18.0636 19.8846 21 18.202 21 15.4976V7.5087ZM16.7426 6.21399C17.7425 5.62898 19 6.35018 19 7.5087V15.4976C19 16.6566 17.7415 17.3777 16.7416 16.7917L12.8474 14.5096C12.2573 14.1638 11.6387 13.8751 11 13.646V9.35536C11.6375 9.12695 12.2551 8.83925 12.8443 8.49456L16.7426 6.21399ZM9 9.8662C8.43632 9.95492 7.86457 9.99996 7.28976 9.99996H5.5C4.67157 9.99996 4 10.6715 4 11.5C4 12.3284 4.67157 13 5.5 13H7.28569C7.86188 13 8.435 13.0452 9 13.1344V9.8662Z"
            fill={color}/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         viewBox="0 0 24 24"
         className={cls(className, 'icon icon-speaker icon-speaker-bold')}
         fill="none">
      <path
        d="M8.99976 18.3153V16.6667C8.99976 16.2985 8.70128 16 8.33309 16H6.0183C5.50093 16 5.12131 16.4862 5.24679 16.9881L5.68074 18.7239C5.86823 19.4739 6.54207 20 7.3151 20C8.24551 20 8.99976 19.2458 8.99976 18.3153Z"
        fill="#225AEA"/>
      <path
        d="M18 10.7778C18 9.79594 18.7959 9 19.7778 9H20C21.1046 9 22 9.89543 22 11C22 12.1046 21.1046 13 20 13H19.7778C18.7959 13 18 12.2041 18 11.2222V10.7778Z"
        fill="#225AEA"/>
      <path
        d="M6 7.99977C4.34315 7.99977 3 9.34292 3 10.9998C3 12.6566 4.34315 13.9998 6 13.9998H8.5C8.77614 13.9998 9 13.7759 9 13.4998V8.49977C9 8.22363 8.77614 7.99977 8.5 7.99977H6Z"
        fill="#225AEA"/>
      <path
        d="M11.2475 7.85536C11.0942 7.94504 11 8.10932 11 8.28692V13.7133C11 13.8907 11.0941 14.0549 11.2472 14.1446L17.236 17.6543C18.9026 18.631 21 17.4291 21 15.4974V6.50866C21 4.57775 18.9042 3.37576 17.2375 4.35083L11.2475 7.85536Z"
        fill="#225AEA"/>
    </svg>
  );

Gps.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

Gps.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Gps;
