import React from 'react';

const SearchAppleMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none">
      <g clipPath="url(#clip0_30980_445262)">
        <path
          d="M15.6267 0C18.0267 0 19.2267 0 20.5333 0.4C21.9467 0.906667 23.0667 2.02667 23.5733 3.44C24 4.74667 24 5.97333 24 8.37333V15.6267C24 18.0267 24 19.2267 23.6 20.5333C23.0933 21.9467 21.9733 23.0667 20.56 23.5733C19.2533 24 18.0267 24 15.6267 24H8.37333C5.97333 24 4.77333 24 3.46667 23.6C2.05333 23.0667 0.933333 21.9733 0.426667 20.5333C-3.17891e-08 19.2533 0 18.0533 0 15.6267V8.37333C0 5.97333 0 4.77333 0.4 3.46667C0.933333 2.05333 2.05333 0.933333 3.46667 0.426667C4.74667 -3.17891e-08 5.97333 0 8.37333 0H15.6267Z"
          fill="url(#paint0_linear_30980_445262)"/>
        <path
          d="M19.7067 17.1999C19.68 17.1999 19.6267 17.2265 19.6 17.2265H4.4C4.37333 17.2265 4.32 17.2265 4.29333 17.1999L9.04 12.4532L10.0533 13.4932C11.1467 14.6132 12.88 14.6132 13.9733 13.4932L14.9867 12.4532L19.7067 17.1999ZM20 16.6932V7.27988C20 7.22655 20 7.17322 19.9733 7.14655C19.9467 7.19988 15.2533 12.0532 15.2533 12.0532L20 16.7999V16.6932ZM4 16.7199V7.33322C4 7.27988 4 7.22655 4.02667 7.19988C4.05333 7.25322 8.74667 12.1065 8.74667 12.1065L4.02667 16.8265C4 16.7999 4 16.7465 4 16.7199ZM19.7333 6.82655L13.68 13.0132C12.7467 13.9465 11.2533 13.9465 10.3467 13.0132L4.29333 6.82655C4.26667 6.79988 19.7333 6.82655 19.7333 6.82655Z"
          fill="white"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_30980_445262" x1="11.9947" y1="0.0106667" x2="11.9947" y2="23.9947"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#1E51EE"/>
          <stop offset="1" stopColor="#19E6FF"/>
        </linearGradient>
        <clipPath id="clip0_30980_445262">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchAppleMail;
