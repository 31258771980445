import React from 'react';

const SuppliersDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M5.33317 5.33331C3.86041 5.33331 2.6665 6.52722 2.6665 7.99998V21.3333C2.6665 22.8061 3.86041 24 5.33317 24H18.6665V7.99998C18.6665 6.52722 17.4726 5.33331 15.9998 5.33331H5.33317Z"
        fill="#225AEA"/>
      <path
        d="M22.1499 9.33331H18.6665V24H26.6665C28.1393 24 29.3332 22.8061 29.3332 21.3333V16.1181C29.3332 15.3681 29.0174 14.6528 28.4632 14.1475L23.9466 10.0294C23.4554 9.58157 22.8146 9.33331 22.1499 9.33331Z"
        fill="#225AEA"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.2188 25.8856C11.7189 25.3855 11.9998 24.7072 11.9998 24H9.33317H6.6665C6.6665 24.7072 6.94746 25.3855 7.44755 25.8856C7.94765 26.3857 8.62593 26.6667 9.33317 26.6667C10.0404 26.6667 10.7187 26.3857 11.2188 25.8856ZM24.5521 25.8856C25.0522 25.3855 25.3332 24.7072 25.3332 24H22.6665H19.9998C19.9998 24.7072 20.2808 25.3855 20.7809 25.8856C21.281 26.3857 21.9593 26.6667 22.6665 26.6667C23.3737 26.6667 24.052 26.3857 24.5521 25.8856Z"
            fill="#225AEA"/>
    </svg>
  );
};

export default SuppliersDashboard;
