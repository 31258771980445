import React from 'react';

const ProductDatabaseDashboard = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width ? width : 40}
         height={height ? height : 40}
         viewBox="0 0 40 40"
         fill="none">
      <path
        d="M18.3333 8.33478C18.3333 7.4143 17.5849 6.65854 16.6701 6.7606C14.2918 7.02595 12.0029 7.85763 9.99977 9.19607C7.53303 10.8443 5.61044 13.187 4.47513 15.9279C3.33981 18.6688 3.04276 21.6848 3.62154 24.5945C4.20032 27.5042 5.62893 30.1769 7.72672 32.2747C9.82451 34.3725 12.4972 35.8011 15.407 36.3799C18.3167 36.9587 21.3327 36.6616 24.0736 35.5263C26.8145 34.391 29.1571 32.4684 30.8054 30.0017C32.1438 27.9985 32.9755 25.7097 33.2408 23.3314C33.3429 22.4166 32.5871 21.6681 31.6667 21.6681H20C19.0795 21.6681 18.3333 20.9219 18.3333 20.0014V8.33478Z"
        fill="#16895A"/>
      <path
        d="M35 18.3347C35.9205 18.3347 36.6763 17.5863 36.5742 16.6715C36.4181 15.2727 36.0656 13.9 35.5249 12.5945C34.7711 10.7746 33.6662 9.12101 32.2733 7.72813C30.8804 6.33525 29.2268 5.23036 27.4069 4.47654C26.1015 3.93579 24.7288 3.5833 23.3299 3.42723C22.4151 3.32516 21.6667 4.08092 21.6667 5.0014V16.6681C21.6667 17.5885 22.4129 18.3347 23.3334 18.3347H35Z"
        fill="#16895A"/>
    </svg>
  );
};

export default ProductDatabaseDashboard;
