import React from 'react';
import cls from 'classname';
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';

const creditCardType = {
  'Visa': 'visa',
  'visa': 'visa',
  'American Express': 'american_express',
  'american_express': 'american_express',
  'mastercard': 'master_card',
  'MasterCard': 'master_card',
  'Bancontact': 'bancontact',
  'Belfius': 'belfius',
  'Ideal': 'ideal',
  'Ing': 'ing',
  'Kbc': 'kbc',
  'Maestro': 'maestro',
  'Paypal': 'paypal',
  'SEPA': 'sepa',
  'Sofort': 'sofort',
}

function PaymentCard({ payment }) {
  const { t } = useTranslation();
  const theme = useSelector(store => store.nav.theme)

  function renderLastFour(num) {
    const numberString = String(num);
    if (numberString.length === 4) return num;
    if (numberString.length === 0) return '0000';
    if (numberString.length === 1) return `000${num}`;
    if (numberString.length === 2) return `00${num}`;
    if (numberString.length === 3) return `0${num}`;
  }

  return (
    <div>
      {payment ? (
        <div className={cls('payment-card', {
          'payment-card--light': theme === 'light',
          'payment-card--dark': theme === 'dark',
        })}
        >
          <div className="body-card">
            <div className="payment-card-number-wrap">
              <div className="card-text">
                {t('setting_billing.CARD NUMBER')}
              </div>
              <div className="card-line-block">
                <span className="card-line">****</span>
                <span className="card-line">****</span>
                <span className="card-line">****</span>
                <span className="card-line">
                  {renderLastFour(payment?.last_4)}
                </span>
              </div>
            </div>
            <div className="payment-card-exp">
              <div className="card-text">{t('setting_billing.EXP DATE')}</div>
              <div className="card-line">
                {`${payment?.expire_month}/${payment?.expire_year?.slice(2)}`}
              </div>
            </div>
          </div>
          <div className="footer-card">
            <div className="payment-card-name">
              <div className="card-text">
                {t('setting_billing.CARD HOLDER')}
              </div>
              <div className="card-line">
                {`${payment?.card_holder_first_name || ''} ${payment?.card_holder_last_name || ''}`}
              </div>
            </div>
            <div className="payment-card-logo-wrap">
              {
                creditCardType?.[payment?.card_type]
                ? <Icon role="icon"
                        type={creditCardType?.[payment?.card_type]}/>
                : null
              }
            </div>
          </div>
        </div>
      ) : (
        <div className={cls('payment-card empty', {
               'payment-card--light': theme === 'light',
               'payment-card--dark': theme === 'dark',
             })}
        >
          <div className="body-card">
            <div className="payment-card-number-wrap">
              <div className="card-text">
                {t('setting_billing.CARD NUMBER')}
              </div>
              <div className="card-line-block">
                <span className="card-line">----</span>
                <span className="card-line">----</span>
                <span className="card-line">----</span>
                <span className="card-line">----</span>
              </div>
            </div>
            <div className="payment-card-exp">
              <div className="card-text">{t('setting_billing.EXP DATE')}</div>
              <div className="card-line">--/--</div>
            </div>
          </div>
          <div className="footer-card">
            <div className="payment-card-name">
              <div className="card-text">
                {t('setting_billing.CARD HOLDER')}
              </div>
              <div className="card-line">-----</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default PaymentCard;
