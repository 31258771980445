import React, { useState } from 'react';
import { Input } from 'antd';
import cls from 'classname';
import EmptyData from '../EmptyData';
import SupplierItem from './SupplierItem';

export default function SupplierBlock({
  type,
  title,
  length,
  suppliersList,
  onImportSupplier,
  onChange,
  onDelete,
}) {
  const { Search } = Input;
  const [state, setState] = useState('');

  return (
    <div className="suppliers-block_wrapper">
      <div className="block-with-label_label light-text">
        {title}
        <span className="blue-block">{length || 0}/3</span>
      </div>
      <Search
        placeholder="Enter link here..."
        value={state}
        className="suppliers-search"
        onChange={(e) => {
          e.persist();
          setState(e.target.value);
        }}
        onSearch={(url) => {
          onImportSupplier({ type, url });
          setState('');
        }}
        enterButton="Import"
        size="large"
        disabled={length >= 3}
      />
      <div
        className={cls('block-with-list', {
          empty_data: !length,
        })}
      >
        {length ? (
          suppliersList.map((supplier) => (
            <SupplierItem
              key={supplier?.id}
              supplier={supplier}
              onChange={onChange}
              type={type}
              onDelete={onDelete}
            />
          ))
        ) : (
          <EmptyData text={`No ${title} Suppliers Added`} />
        )}
      </div>
    </div>
  );
}
