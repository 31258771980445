import React, {useState} from "react";
import FilterPresetTable from "./FilterPresetTable";
import ButtonComponent from "../../../Components/Button";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import './LoadFilterPresetBlock.less'
import './FilterPresetTable.less'

const LoadFilterPresetBlock = (
  {
    fetching,
    theme,
    setVisible,
    setRecordToDelete,
    setModal,
    updatePreset,
    setPageSize,
    setPageNumber,
    loadPreset,
    setLoadedPreset,
    setSortOrder,
    category
  }) => {
  //initial table value
  const presetsOptions = useSelector(store => store[category].presets);

  const {t} = useTranslation();

  //selected - clicked and @bluePrimary bg
  const [selected, setSelected] = useState(null);
  //editable name row
  const [editable, setEditable] = useState(null);

  return (
    <div className={'load-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Load Filter Preset')}
      </div>
      <p className="load-filter-preset-subtitle">
        {
          category === 'adSpot' ?
            'Save your favorite Ad Spot filters for quicker searching. We also have a list of curated Dropship filters that you can apply.'
            :
            category === 'competitors' ?
              t('load_preset_competitors_text')
              :
              t('load_preset_text')
        }
      </p>
      {/*user-presets table*/}
      <FilterPresetTable filters={presetsOptions.users}
                         fetching={fetching}
                         theme={theme}
                         isDropship={false}
                         setRecordToDelete={setRecordToDelete}
                         setModal={setModal}
                         selected={selected}
                         setSelected={setSelected}
                         editable={editable}
                         setEditable={setEditable}
                         updatePreset={updatePreset}
                         category={category}
      />
      {/*dropship-presets table*/}
      {
        category !== 'competitors' &&
        <FilterPresetTable filters={presetsOptions.dropship}
                           fetching={fetching}
                           theme={theme}
                           isDropship={true}
                           setSelected={setSelected}
                           selected={selected}
                           editable={editable}
                           setEditable={setEditable}
                           category={category}
        />
      }
      <div className="load-filter-preset-button-wrapper">
        <ButtonComponent className={'load-filter-preset-button'}
                         text={t('Cancel')}
                         onClick={() => {
                           setSelected(null)
                           setVisible(false)
                         }}
        />
        <ButtonComponent className={'load-filter-preset-button apply'}
                         text={t('load_preset')}
                         onClick={() => {
                           loadPreset(selected)
                           setLoadedPreset(selected)
                           //reset pagination on preset load
                           setPageSize(50)
                           setPageNumber(1)
                           setSelected(null)
                           setVisible(false)
                           if (category !== 'adSpot') setSortOrder(null)
                           else setSortOrder('most_recent')
                         }}
                         disabled={!selected}
        />
      </div>
    </div>
  );
};

export default LoadFilterPresetBlock;
