import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';

export const ProductViewComponent = ({ view, setView, isMobile }) => {
  const { t } = useTranslation();

  return (
    <div className="product-view">
      {isMobile ? (
        <div
          onClick={() => setView(view === 'overview' ? 'details' : 'overview')}
        >
          {view === 'details' ? (
            <Icon type="view_port" role="icon" />
          ) : (
            <Icon type="view_port_table" role="icon" />
          )}
        </div>
      ) : (
        <>
          <div
            className={cls('product-view_btn', 'product-view_btn--overview', {
              'product-view_btn--active': view === 'overview',
            })}
            onClick={() => setView('overview')}
          >
            {t('common.switch_button.overview')}
          </div>
          <div
            className={cls('product-view_btn', 'product-view_btn--details', {
              'product-view_btn--active': view === 'details',
            })}
            onClick={() => setView('details')}
          >
            {t('common.switch_button.detailed')}
          </div>
        </>
      )}
    </div>
  );
};

ProductViewComponent.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default ProductViewComponent;
