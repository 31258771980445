import React, {useRef, useState} from 'react';
import InputComponent from "../../../../Components/Input";
import {useTranslation} from "react-i18next";
import Icon from "../../../../Icon";
import {Divider, Tag} from "antd";
import ButtonComponent from "../../../../Components/Button";

const SearchWithTags = (
  {
    searchValue,
    search,
    setSearch,
    setSearchValue,
    searchSubmit,
    disabled,
    disabledBtn,
    placeholder = 'Search for a product...'
  }) => {
  const {t} = useTranslation();
  const inputRef = useRef(null);

  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  const tagData = searchValue?.split(',').filter(el => el.length && el !== 'null') || [];

  const doesContain = val => {
    let trimmedVal = val.trim();
    let valArray = trimmedVal.split(',')[0];
    return tagData.includes(trimmedVal) || tagData.includes(valArray.trim());
  };

  const onChangeInputFilter = (e) => {
    e.persist();
    setSearchValue(state => (state === '' ? e.target.value.split(',')[0].trim() + ',' : state + ',' + e.target.value.split(',')[0].trim()));
  };

  const onPasteInputFilter = (e) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setSearchValue(state => (state === '' ? val?.[i].trim() + ',' : [...new Set([...state.split(','), val?.[i].trim()])].join(',')));
    }
  };

  const handleTagClose = tag => {
    const newValue = tagData.filter(el => el !== tag).join(',');
    setSearchValue(newValue.length ? newValue : '');
    if (!newValue.length && search) {
      searchSubmit(true);
      setSearch(false);
    }
  };

  const focus = () => inputRef.current && inputRef.current.focus();

  const handleChange = e => {
    setValue(e.target.value)
  };

  const onKeyUp = e => {
    if (e.key === ',' || e.keyCode === 13) {
      if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
        setValue('');
        return
      }
      if(e.target.value) onChangeInputFilter(e);
      setValue('')
    }
  };

  const onBlur = (e) => {
    if(e.target.value) {
      if (doesContain(e.target.value.toLowerCase()) || tagData.filter(tag => tag.toLowerCase() === e.target.value.trim().toLowerCase()).length ) {
        setValue('');
        setFocused(false);
        return
      }
      onChangeInputFilter(e);
    }
    setValue('');
    setFocused(false);
  };

  const onKeyDown = e => {
    if (e.keyCode === 8 && !value) {
      if (tagData.length) {
        let newValue = [...tagData];
        newValue.pop();
        setSearchValue(newValue.length ? newValue.join(',') : '');
        if (!newValue.length && search) {
          searchSubmit(true);
          setSearch(false);
        }
      }
    }
  };

  const onPaste = e => {
    e.persist();
    setValue('');
    setFocused(false);
    onPasteInputFilter(e);
  };


  return (
    <div className={'top-products-search-wrapper'}>
      <ul className={'top-products-search-list'} onClick={() => focus()}>

        <div className={'top-products-search-prefix'}>
          <Icon type={"search_icon"} role="icon" color={focused ? '#225AEA' : '#707BA0'}/>
          <Divider type="vertical" className="search-divider"/>
        </div>

        <div className={'top-products-search-list-items'} style={{marginTop: tagData.length ? 6 : 0}}>
          {tagData && tagData.map(item =>
            <li key={item}>
              <Tag closable
                   onClose={() => handleTagClose(item)}
                   closeIcon={<Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />}
              >
                {item}
              </Tag>
            </li>
          )}

          <li style={{width: focused ? value.length + 1 + 'ch' : tagData.length ? 0 : '100%', height: !focused && tagData.length ? 0 : 30, marginTop: tagData.length ? -3 : 0}}>
            <InputComponent name={''}
                            placeholder={tagData.length || focused ? '' : t(placeholder)}
                            onChange={handleChange}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                            onPaste={onPaste}
                            onBlur={onBlur}
                            onFocus={() => setFocused(true)}
                            value={value}
                            ref={inputRef}
                            autoComplete='off'
                            disabled={disabled}
            />
          </li>
        </div>
      </ul>
      <ButtonComponent onClick={() => {
        setSearch(true);
        searchSubmit();
      }}
                       className={'sales-tracker-search'}
                       text={t('Search')}
                       disabled={disabledBtn || !searchValue.length ? !value.length : false}
      />
    </div>
  );
};

export default SearchWithTags;
