import React from 'react';
import {useNavigate} from "react-router-dom";
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from "../../../Icon";
import ButtonComponent from "../../../Components/Button";
import UniversityPageCourseWrapperSkeleton from "./skeletons/UniversityPageCourseWrapperSkeleton";
import './UniversityPageCourseWrapper.less';

const UniversityPageCourseWrapper = (
  {
    skeleton,
    isMobile,
    disabled,
    info,
    dashboard,
    isComingSoon,
  }
) => {

  const {t} = useTranslation();

  const navigate = useNavigate();

  const {id, title, description, thumbnail, lessons_count} = info;

  const clickHandler = () => {
    if(isComingSoon) return

    if(!disabled && (isMobile && dashboard)){
      navigate(`/dropship-university`)
    }else{
      navigate(`/dropship-university/${id}`, {
        state: {
          name: title,
        }
      })
    }
  }

  if (skeleton) return (<UniversityPageCourseWrapperSkeleton />)

  return (
    <div className={cls('university-page-course-wrapper', {
      'no-clickable': isComingSoon
    })}
         onClick={clickHandler}
    >
      <div className="university-page-course-image"
           style={{backgroundImage: `url("${thumbnail}")`}}
      />
      <div className="university-page-course-info">
        <p className='university-page-course-info-lessons'>
          <Icon type={'watch_tutorial'} role={'icon'} color={'#707ba0'}/>
          <span>
            {
              lessons_count === 1 ?
                `${lessons_count} ${t('lesson')}`
                :
                `${lessons_count} ${t('lessons')}`
            }
          </span>
        </p>
        <h3 className="university-page-course-info-title">
          {title}
        </h3>
        <p className="university-page-course-info-text">
          {description}
        </p>
        <ButtonComponent className='university-page-course-info-watch'
                         text={isComingSoon ? t('Coming Soon') : t('Watch Course')}
                         disabled={disabled || isComingSoon}
        />
      </div>
    </div>
  );
};

UniversityPageCourseWrapper.defaultProps = {
  info: {
    id: 1,
    title: 'Title',
    description: 'Description',
    thumbnail: null,
    lessons_count: 1
  }
}

export default UniversityPageCourseWrapper;
