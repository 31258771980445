import React, {useState} from 'react';

const SearchTwitter = () => {

  const [reversedIcon, setReversedIcon] = useState(false);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 19 18"
         onMouseEnter={() => setReversedIcon(true)}
         onMouseLeave={() => setReversedIcon(false)}
         fill="none">
      <path
        d="M13.7247 2.25H16.0149L11.0116 7.96846L16.8976 15.75H12.2889L8.67917 11.0305L4.54886 15.75H2.25733L7.60886 9.63346L1.9624 2.25H6.6881L9.95094 6.56377L13.7247 2.25ZM12.9209 14.3792H14.1899L5.99856 3.54877H4.63679L12.9209 14.3792Z"
        fill={reversedIcon ? "#225aea" : "#000000"}
      />
    </svg>
  );
};

export default SearchTwitter;
