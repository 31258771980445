import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Spin, Divider } from 'antd';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import openNotification from '../../Components/Notification';
import Modal from '../../Components/Modal';
import Creators from './reducer';
import './styles.less';

const text = 'Are you sure you want to disconnect _store_ from your Dropship account?';

const ModalShopifyDelete = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    isVisible,
    closeModal,
    deleteStore,
    ...props
  }) => {

  const { loading, deleteErrors, deleteResult } = shopifyStore;
  const { t } = useTranslation();

  useEffect(() => {
    if(deleteResult || deleteErrors){
      if(deleteResult){
        openNotification({
          message: t('Your Shopify store has been successfully disconnected'),
          style: { minWidth: '716px' },
        });
        closeModal()
      }
      if(deleteErrors){
        openNotification({
          message: t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
          type: 'error'
        });
      }
      props.deleteShopifyStoreReset()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[deleteResult, deleteErrors])

  const handleOk = () => {
    if(loading) return
    props.deleteShopifyStore(deleteStore?.id)
  };

  return (
    <Modal
      className={cls('shopify-delete')}
      title={t('Are you sure?')}
      open={isVisible}
      width={512}
      handleOk={handleOk}
      handleClose={closeModal}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={footerModal({
        isMobile: isMobile,
        handleCancel: closeModal,
        textBtnCancel: t('Cancel'),
        handleOk: handleOk,
        textBtnOk: t('Disconnect'),
        isDisabledBtnOk: false,
        deleteType: true,

      })}
    >
      <Spin spinning={loading}>
        <p>{t(text, {store: deleteStore?.name || 'store'})}</p>
        <Divider />
      </Spin>
    </Modal>
  );
};

ModalShopifyDelete.defaultProps = {
  shopifyStore: {
    loading: false,
    results: [],
  },
};

ModalShopifyDelete.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  userInfo: PropTypes.object,
  shopifyStore: PropTypes.shape({
    loading: PropTypes.bool,
    deleteResult: PropTypes.bool,
    results: PropTypes.array,
  }).isRequired,
  deleteStore: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    domain: PropTypes.string,
  })
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  deleteShopifyStore: (store_id) => dispatch(Creators.deleteShopifyStoreRequest({store_id})),
  deleteShopifyStoreReset: () => dispatch(Creators.deleteShopifyStoreReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyDelete);
