import React from 'react';

const ArrowDatepickerNext = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       fill={color ? color : '#225aea'}
       className={className}
       style={style}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0041 11.9804C15.0049 11.8494 14.9796 11.7195 14.9299 11.5983C14.8801 11.477 14.8068 11.3667 14.7141 11.2737L10.7141 7.2927C10.6209 7.1999 10.5102 7.12629 10.3883 7.07607C10.2665 7.02585 10.136 7 10.0041 7C9.87223 7 9.74166 7.02585 9.61984 7.07607C9.49802 7.12629 9.38733 7.1999 9.29409 7.2927C9.20085 7.38549 9.12689 7.49566 9.07643 7.6169C9.02597 7.73815 9 7.8681 9 7.99933C9 8.13057 9.02597 8.26052 9.07643 8.38176C9.12689 8.50301 9.20085 8.61317 9.29409 8.70597L12.6041 11.9804L9.42409 15.2647C9.3218 15.3563 9.23959 15.4679 9.18261 15.5925C9.12562 15.7171 9.09508 15.8521 9.0929 15.989C9.09072 16.1259 9.11695 16.2618 9.16994 16.3881C9.22293 16.5145 9.30155 16.6286 9.40087 16.7233C9.5002 16.818 9.61809 16.8914 9.74719 16.9387C9.87628 16.986 10.0138 17.0063 10.1511 16.9983C10.2885 16.9903 10.4227 16.9542 10.5453 16.8922C10.668 16.8303 10.7765 16.7438 10.8641 16.6382L14.7241 12.6571C14.9004 12.4751 15.0005 12.2332 15.0041 11.9804Z"
    />
  </svg>
);

export default ArrowDatepickerNext;
