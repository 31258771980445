import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import acc from 'accounting';
import cls from 'classname';
import { Row, Col, Divider, InputNumber, Slider } from 'antd';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import InputComponent from "../../../Components/Input";
import './NumbersBreakdownBlock.less';

const calculator = {
  getFees: (price) => (acc.unformat(price, ',') / 100) * 2.9 + 0.3,
  getCPA: (price) => acc.unformat(price, ',') / 3,
  getRatio: ({ price, cost, shipping }) => cost === 0 && shipping === 0 ? 0 : acc.unformat(price, ',') / (cost + shipping),
  getBECPA: ({ price, cost, shipping, fees }) => acc.unformat(price, ',') - cost - shipping - fees,
  getBEROAS: ({ price, BECPA }) => acc.unformat(price, ',') / BECPA,
  getMargin: ({ price, CPA, BECPA }) => ((BECPA - CPA) / acc.unformat(price, ',')) * 100,
  getProfit: ({ orders, BECPA, CPA }) => orders * (BECPA - CPA),
};

const fieldList = [
  {
    id: 1,
    name: 'cost',
    label: 'Est. Cost of Good Sold',
  },
  {
    id: 2,
    name: 'shipping',
    label: 'Est. Shipping Cost',
  },
  {
    id: 3,
    name: 'fees',
    label: 'Est. Fees Per Order',
  },
  {
    id: 4,
    name: 'CPA',
    label: 'Est. CPA',
  },
];

export const NumbersBreakdownBlock = ({
  skeleton,
  isMobile,
  product,
  productDatabase
}) => {
  const [state, setState] = useState({
    price: null,
    orders: null,
    cost: null,
    shipping: null,
    fees: null,
    CPA: null,
  });
  const [isChanged, setIsChanged] = useState(true);
  const theme = useSelector(state => state.nav.theme)

  const { t } = useTranslation();

  const defaultValue = {
    price: productDatabase ?  acc.formatNumber(product?.usd_price, 2, '', ',') : product?.price ? acc.formatNumber(product?.price, 2, '', ',') : 0,
    orders: 25000,
    cost: product?.cost_min || 0,
    shipping: +product?.shipping || 0,
    fees: product?.price ? calculator.getFees(product?.price) : 0,
    CPA: product?.price ? calculator.getCPA(product?.price) : 0,
  };

  useEffect(() => {
    if (product?.product_id || productDatabase) saveDefaultValue();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [product?.product_id]);

  useEffect(() => {
    let fees = 0.3;
    let CPA = 0;
    if (state.price) {
      const parsedPrice = acc.unformat(state.price, ',');
      fees = calculator.getFees(parsedPrice);
      CPA = calculator.getCPA(parsedPrice);
    }
    setState((state) => ({ ...state, fees, CPA }));
  }, [state.price]);

  useEffect(() => {
    checkIsChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    state.price,
    state.orders,
    state.cost,
    state.shipping,
    state.fees,
    state.CPA,
  ]);

  function saveDefaultValue() {
    setState((state) => ({ ...state, ...defaultValue }));
  }

  const memoRatio = useMemo(() => {
    if (!acc.unformat(state.price, ',')) return 0;
    return calculator.getRatio({
      price: state.price,
      cost: state.cost,
      shipping: state.shipping,
    });
  }, [state.price, state.cost, state.shipping]);

  const memoBECPA = useMemo(
    () =>
      calculator.getBECPA({
        price: state.price,
        cost: state.cost,
        shipping: state.shipping,
        fees: state.fees,
      }),
    [state.price, state.cost, state.shipping, state.fees],
  );

  const memoBEROAS = useMemo(
    () =>
      calculator.getBEROAS({
        price: state.price,
        BECPA: memoBECPA,
      }),
    [state.price, memoBECPA],
  );

  const memoMargin = useMemo(() => {
    if (!acc.unformat(state.price, ',')) return 0;
    return calculator.getMargin({
      price: state.price,
      CPA: state.CPA,
      BECPA: memoBECPA,
    });
  }, [state.price, state.CPA, memoBECPA]);

  const memoProfit = useMemo(
    () => {
      if (state.orders === null || memoBECPA === null ||
        state.CPA === null || state.shipping === null || state.cost === null) {
        return 'N/A'
      } else {
        return calculator.getProfit({
          orders: state.orders,
          BECPA: memoBECPA,
          CPA: state.CPA,
        })
      }
    },
    [state.orders, state.CPA, memoBECPA, state.cost, state.shipping],
  );

  function handleChange(event) {
    const { name, value } = event.target;
    const parsedValue = value.replace(/\$\s?|(,*)/g, '');

    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      if (name === 'CPA' && Number(parsedValue) > 100) return;
      setState((state) => ({ ...state, [name]: parsedValue }));
    }
  }

  function handleChangeNumber(value, name) {
    if (acc.formatNumber(value,2, '', ',') === acc.formatNumber(state[name],2, '', ',')) return
    setState((state) => ({ ...state, [name]: +value }));
  }

  function checkIsChanged() {
    if (
      Object.keys(defaultValue).some((key) => defaultValue[key] !== state[key])
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(false);
  }

  function handleResetValue() {
    saveDefaultValue();
  }

  const renderHeader = !productDatabase ?
    <>
      <span className="title">{t('Numbers Breakdown')}</span>

      <span
        className={cls('link', { disabled: !isChanged })}
        onClick={handleResetValue}
      >
        {t('Reset')}
      </span>
    </>
    :
    <></>

  const renderHeaderSkeleton = (
    <>
      <span className="title skeleton" style={{ width: 222 }} />
      <span className="link skeleton" style={{ width: 44 }} />
    </>
  );

  const renderTooltipIcon = (text, className) => (
    <Icon
      type="attention_outline"
      role="button"
      titleText={text}
      tooltipProps={{
        placement: 'top',
        destroyTooltipOnHide: true,
        trigger: isMobile ? 'click' : 'hover',
        overlayClassName: cls('details-tooltip', className),
        getPopupContainer: () => productDatabase ?
          document.querySelector('.product-database-breakdown-card')
          :
          document.getElementById('product-details-block-numbers-id'),
      }}
    />
  );

  const marksPrice = {
    0: '$0',
    500: '$500+',
  };

  const marksOrders = {
    0: '0',
    100000: '100,000+',
  };

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeaderSkeleton} withLine={!isMobile}>
        <Row gutter={[{ xs: 12, sm: 32, xl: 16 }, 22]}>
          {[1, 2].map((el) => (
            <Col key={el} xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <div className="numbers-breakdown-card">
                <span
                  className="numbers-breakdown-card-label skeleton"
                  style={{ width: 115 }}
                />
                <div className="numbers-breakdown-card-input skeleton" />
                <div className="numbers-breakdown-card-slider numbers-breakdown-card-slider_skeleton">
                  <div className="rail skeleton" />
                  <span className="text skeleton" />
                  <span className="text skeleton" style={{ width: 56 }} />
                </div>
              </div>
            </Col>
          ))}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 8 }}
            className="numbers-breakdown-card-wrap pot_profit"
          >
            <div className="numbers-breakdown-card">
              <span
                className="numbers-breakdown-card-label skeleton"
                style={{ width: 102 }}
              />
              <span className="numbers-breakdown-card-result skeleton" />
            </div>
          </Col>
        </Row>
        <Divider style={{ margin: '16px 0' }} />
        <Row
          gutter={[
            { xs: 12, sm: 12, lg: 16 },
            { xs: 16, sm: 16, lg: 32 },
          ]}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8].map((el) => (
            <Col key={el} xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
              <div className="numbers-breakdown-card">
                <span
                  className="numbers-breakdown-card-label small skeleton"
                  style={{ width: 104 }}
                />
                <div
                  className="numbers-breakdown-card-input skeleton"
                  style={{ maxWidth: 164 }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard wrapperClassName={productDatabase ? 'product-database-breakdown-card' : null} header={renderHeader} withLine={!isMobile}>
      <Row gutter={[{ xs: 12, sm: 32, lg: 16, xl: 120 }, 22]} className={productDatabase ? 'product-database-card-block-upper' : null}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('Selling Price')}
              {renderTooltipIcon(t('tooltips.price'), 'price')}
            </span>

            <InputNumber
              className={cls('numbers-breakdown-card-input selling-price', {
                dark: theme === 'dark',
                'numbers-breakdown-card-input-product-database': productDatabase
              })}
              name="price"
              value={state.price}
              step={0.01}
              decimalSeparator={','}
              precision={2}
              onChange={(value) => handleChangeNumber(value, 'price')}
              stringMode
            />
            <Slider
              className="numbers-breakdown-card-slider"
              value={acc.unformat(state['price'], ',')}
              max={500}
              marks={marksPrice}
              open={false}
              onChange={(value) => handleChangeNumber(value, 'price')}
              onAfterChange={(value) =>
                setState((state) => ({
                  ...state,
                  price: acc.formatNumber(value, 2, '', ','),
                }))
              }
            />
          </div>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('Example Orders')}
              {renderTooltipIcon(t('tooltips.orders'), 'orders')}
            </span>
            <InputComponent
              className={cls("numbers-breakdown-card-input", {
                'numbers-breakdown-card-input-product-database': productDatabase
              })}
              prefix={
                <>
                  {
                    productDatabase ?
                      <Icon role="icon" type="orders_product_database" />
                      :
                      <Icon role="icon" type="orders" />
                  }
                  <div className="divider" />
                </>
              }
              name="orders"
              value={acc.formatNumber(state.orders, 0, ',', '')}
              onChange={handleChange}
            />

            <Slider
              className="numbers-breakdown-card-slider"
              defaultValue={25000}
              value={acc.unformat(state.orders)}
              max={100000}
              marks={marksOrders}
              open={false}
              onChange={(value) => handleChangeNumber(value, 'orders')}
            />
          </div>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 8 }}
          className="numbers-breakdown-card-wrap pot_profit"
        >
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('POT. Profit')}
              {renderTooltipIcon(t('tooltips.pot_profit'), 'pot_profit')}
            </span>
            <span className="numbers-breakdown-card-result">
              {typeof memoProfit === 'number' ?
                `≈ $${acc.formatNumber(memoProfit, 0, ',')}`
              :
                `≈ $${memoProfit}`
              }

            </span>
          </div>
        </Col>
      </Row>

      <Divider style={{ margin: '16px 0' }} />

      <Row
        gutter={[
          { xs: 12, sm: 12, lg: 16, xl: 100 },
          { xs: 16, sm: 16, lg: 32 },
        ]}
        className={productDatabase ? 'product-database-card-block' : null}
      >
        {fieldList.map((el) => (
          <Col key={el.id} xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
            <div className="numbers-breakdown-card">
              <span className="numbers-breakdown-card-label small">
                {t(el.label)}
                {
                  renderTooltipIcon(t(`tooltips.${
                    productDatabase && el.name === 'shipping'
                      ? el.name+'_productDatabase'
                      : el.name
                  }`), el.name)
                }
              </span>
              <InputNumber
                className={cls('numbers-breakdown-card-input', {
                  dark: theme === 'dark',
                  'numbers-breakdown-card-input-product-database': productDatabase
                })}
                name={el.name}
                value={state[el.name]}
                step={0.01}
                decimalSeparator={','}
                precision={2}
                onChange={(value) => handleChangeNumber(value, el.name)}
                stringMode
              />
            </div>
          </Col>
        ))}

        {/* result fields */}

        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('P/C Ratio')}
              {renderTooltipIcon(t('tooltips.p_c_ratio'), 'p_c_ratio')}
            </span>
            <InputComponent
              className="numbers-breakdown-card-input"
              name="p_c_ratio"
              value={`${acc.formatNumber(memoRatio, 2, ',')}X`}
              disabled
            />
          </div>
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('BECPA')}
              {renderTooltipIcon(t('tooltips.BECPA'), 'BECPA')}
            </span>
            <InputComponent
              className="numbers-breakdown-card-input"
              name="BECPA"
              value={`$${acc.formatNumber(memoBECPA, 2, '', ',')}`}
              disabled
            />
          </div>
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('BEROAS')}
              {renderTooltipIcon(t('tooltips.BEROAS'), 'BEROAS')}
            </span>
            <InputComponent
              className="numbers-breakdown-card-input"
              name="BEROAS"
              value={acc.formatNumber(memoBEROAS, 2, ',')}
              disabled
            />
          </div>
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('Profit Margin')}
              {renderTooltipIcon(t('tooltips.profit_margin'), 'profit_margin')}
            </span>
            <InputComponent
              className="numbers-breakdown-card-input"
              name="profit_margin"
              value={`${acc.formatNumber(memoMargin, 2, ',')}%`}
              disabled
            />
          </div>
        </Col>
      </Row>
    </ProductDetailsCard>
  );
};

export default NumbersBreakdownBlock;
