import React from 'react';

const AdSpotReactions = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill="#707BA0"/>
      <path
        d="M7 12.6809C7 12.3048 7.30483 12 7.68085 12H16.3191C16.6952 12 17 12.3048 17 12.6809V13C17 15.7614 14.7614 18 12 18C9.23858 18 7 15.7614 7 13V12.6809Z"
        fill="#707BA0"/>
    </svg>
  );
};

export default AdSpotReactions;
