import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../reducer';
import DropdownDateBlockST from "../../SalesTrackerPage/components/DropdownDateBlockST";
import ButtonComponent from "../../../Components/Button";
import '../../SalesTrackerPage/components/MainBlock.less';
import dayjs from "dayjs";

export const MainBlockAdSpot = (
  {
    data,
    downloadReport,
    dateOptions,
    fileLoading,
    sort=null,
    isProduct=false
  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownFilters = useSelector(store => store.adSpot?.innerPagesInfo?.dateFilters) || {report_period: {min: dayjs().format('YYYY-MMM-DD'), max: dayjs().subtract(30, 'days').format('YYYY-MMM-DD')}};
  const checkedList = useSelector(store => store.adSpot?.innerPagesInfo?.checkedDateFilters) || [{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}];

  const setDropdownFilters = (prop) => {
    let result = prop();
    dispatch(Creators.setInnerPagesDateFilters(result))
  }

  const setMemoCheckedList = (data) => {
    dispatch(Creators.setInnerPagesDateCheckedList(data))
  }

  const handleDownload = () => {
    !isProduct ?
    downloadReport({id: data.id, name: `${data.ad_headline}`, date_range: data?.dates, ...(sort && {sort: sort})})
      :
    downloadReport({internal_shop_id: data.internal_shop_id, shopify_product_id: data?.shopify_product_id, name: `${data.ad_headline}`, date_range: data?.dates})
  }

  return (
    <div className="report-main-block" style={{marginTop: 0}}>

      <span className={'main-block-calendar'}>
        <span>{t('Engagement Calendar')}</span>
      </span>


      {/*<ButtonComponent type={'primary'}*/}
      {/*                 loading={fileLoading}*/}
      {/*                 className={'btn-primary white'}*/}
      {/*                 onClick={handleDownload}*/}
      {/*>*/}
      {/*  {t('Download Report')}*/}
      {/*</ButtonComponent>*/}
      {/*<div style={{marginLeft: "auto"}}>*/}
        <DropdownDateBlockST
          limit={data?.timeInfo}
          timeZone={data?.timeZone}
          label={null}
          customOverlayClassName={'dropdown-sales_tracker-wrapper'}
          customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
          id={'report_period'}
          iconType={'database_calendar'}
          data={dateOptions}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          memoCheckedList={checkedList}
          setMemoCheckedList={setMemoCheckedList}
          hideTimeZoneText={true}
        />
      {/*</div>*/}
    </div>
  );
};

export default MainBlockAdSpot;
