import React, { useEffect, useRef, useState } from 'react';
import {Col, Divider, Row, Slider, Tooltip} from "antd";
import Icon from "../../../Icon";
import InputFilter from "./InputFilter";
import cls from "classname";
import DropdownBlockTest from "../../ProductDatabasePage/components/DropdownBlockTest";
import DropdownDateBlock from "../../ProductDatabasePage/components/DropdownDateBlock";
import ButtonComponent from "../../../Components/Button";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import acc from "accounting";

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const inputsData = [
  {
    key: 'ad_copy',
    title: 'Ad Copy',
    tooltip: 'Search for text that is included in the caption, headline, or description of the ad.'
  },
  {
    key: 'page_name',
    title: 'Page Name',
    tooltip: 'Search for a specific Facebook page or pages you are looking for.'
  },
  {
    key: 'domain_name',
    title: 'Domain Name',
    tooltip: 'Search for specific domain names you are looking to see ads for.'
  },
];

const creativeData = [
  {
    key: 'all',
    title: 'All',
    icon: null
  },
  {
    key: 'video',
    title: 'Video',
    icon: 'ad_spot_video'
  },
  {
    key: 'image',
    title: 'Photo',
    icon: 'ad_spot_photo'
  },
  {
    key: 'carousel',
    title: 'Carousel',
    icon: 'ad_spot_carousel'
  },
]

const engagementData = [
  {
    key: 'reactions',
    title: 'Reactions',
  },
  {
    key: 'shares',
    title: 'Shares',
  },
  {
    key: 'comments',
    title: 'Comments',
  },
  {
    key: 'video_length',
    title: 'Video Length',
  }
]

const FiltersBlock = (
  {
    isMobile,
    sortOrder,
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    setSortOrder,
    inputFilters,
    setInputFilters,
    creativeFilters,
    setCreativeFilters,
    engagementFilters,
    setEngagementFilters,
    dropdownFilters,
    setDropdownFilters,
    getAds,
    defaultSearch,
    setDefaultSearch,
    disabledSearch,
    loadedPreset,
    setLoadedPreset,
    tableRef,
    setFilters,
    setVisible,
    setModal,
    skeleton,
    changedFilters,
    adsLoading,
    suggestions,
    suggestionsLoading,
    getSuggestions,
    resetSuggestions,
    inputFiltersSuggestions,
    setInputFiltersSuggestions
  }
) => {

  const {t} = useTranslation();
  const [firstLoading, setFirstLoading] = useState(true);
  const rerenderKey = useRef(new Date().getTime());

  const languageOptions = useSelector(store => store.adSpot.filters.languages) || [];
  const actionOptions = useSelector(store => store.adSpot.filters.call_to_action) || [];
  const websiteOptions = useSelector(store => store.adSpot.filters.website_providers) || [];
  const domainOptions = useSelector(store => store.adSpot.filters.domain_tld) || [];

  const sliderTooltipFormatter = (key, value) => {
    if (key === 'video_length') return `${Math.floor(value/60) < 10 ? '0'+Math.floor(value/60) : Math.floor(value/60)}:${(value - Math.floor(value/60)*60) < 10 ? '0'+(value - Math.floor(value/60)*60) : value - Math.floor(value/60)*60}`
    else return `${acc.formatNumber(value, 0, ',', '')}`
  }

  const videoLengthFormat = (min, max) => {
    const info = num => ({
      minutes: Math.floor(num/60),
      seconds: num - Math.floor(num/60)*60,
    });
    const minInfo = info(min);
    const maxInfo = info(max);
    return `${minInfo?.minutes < 10 ? '0'+minInfo?.minutes : minInfo?.minutes}:${minInfo?.seconds < 10 ? '0'+minInfo?.seconds : minInfo?.seconds} - ${maxInfo?.minutes < 10 ? '0'+maxInfo?.minutes : maxInfo?.minutes}:${maxInfo?.seconds < 10 ? '0'+maxInfo?.seconds : maxInfo?.seconds}`
  }

  const onChangeInputFilter = (e, key, skip=false) => {
    e.persist();
    if (!skip) setInputFilters(state => ({ ...state, [key]: state[key] === null ? e.target.value.split(',')[0].trim() + ',' : state[key] + ',' + e.target.value.split(',')[0].trim() }));
  };

  const onPasteInputFilter = (e, key) => {
    e.persist();
    let val = e.clipboardData.getData('text').replaceAll(',','');
    setInputFilters(state => ({ ...state, [key]: state[key] === null ? val.trim() + ',' : [...new Set([...state[key].split(','), val?.trim()])].join(',') }));
  };

  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return {min: min, max: max}
  }

  const resetFilters = () => {
    rerenderKey.current = new Date().getTime();
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({ ...state, [key]: null })));
    Object.keys(inputFiltersSuggestions).forEach((key) => setInputFiltersSuggestions(state => ({ ...state, [key]: [] })));
    Object.keys(engagementFilters).forEach((key) => setEngagementFilters(state => ({ ...state, [key]: {min: 0, max: key === 'video_length' ? 600 : 10000} })));
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'ad_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({ ...state, [key]: 'All' }));
    });
    setCreativeFilters('all');
    setPageSize(50);
    setPageNumber(1);
    setSortOrder('most_recent');
    setLoadedPreset(null);
  }

  const applyFilters =(new_search=false, dataToSend=null) => {
    if (disabledSearch && new_search) return
    setFilters();
    if (tableRef?.current && pageNumber === 1) tableRef.current.scrollTop = 0;
    if (new_search) setDefaultSearch(false);
    let data = {
      ...inputFilters,
      creative_filters: creativeFilters,
      ...engagementFilters,
      page_name_suggestions: [
        ...inputFiltersSuggestions?.page_name
      ],
      domain_name_suggestions: [
        ...inputFiltersSuggestions?.domain_name
      ],
      call_to_action: dropdownFilters?.call_to_action.length ? dropdownFilters?.call_to_action : 'All',
      website_provider: dropdownFilters?.website_provider.length ? dropdownFilters?.website_provider : 'All',
      ad_copy_language: dropdownFilters?.ad_copy_language.length ? dropdownFilters?.ad_copy_language : 'All',
      domain_tld: dropdownFilters?.domain_tld.length ? dropdownFilters?.domain_tld : 'All',
      ad_created_at: dropdownFilters?.ad_created_at?.id ? parseDates('ad_created_at') : {
        min: dropdownFilters?.ad_created_at?.min,
        max: dropdownFilters?.ad_created_at?.max
      },
      new_search: new_search,
      sort: sortOrder,
      page_size: pageSize,
      page_number: pageNumber
    }
    getAds(dataToSend ? dataToSend : data);
  }

  const handleSearch =(new_search=false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
      setDefaultSearch(false);
    }
  }

  useEffect(() => {
    if (firstLoading) return setFirstLoading(false)
    if (defaultSearch) applyFilters(
      false, {
        sort: sortOrder,
        page_size: pageSize,
        page_number: pageNumber
      })
    else applyFilters();
  }, [pageNumber, pageSize, sortOrder, firstLoading])

  useEffect(() => {
    if (loadedPreset !== null) {
      applyFilters(true);
      if (!loadedPreset?.key?.includes('defined')) setLoadedPreset(null);
    }
  }, [loadedPreset]);

  if (skeleton) return (
    <div className="fadspot-page-filters-wrapper">
      <div className="fadspot-page-filters-title-wrapper">
        <span className="link skeleton" style={{width: 161, height: 32, marginBottom: isMobile ? 16 : 0}}/>
        {
          isMobile ?
            null
            :
            <div className="fadspot-page-filters-preset-wrapper">
              <span className="link skeleton" style={{width: 118, height: 24}}/>
              <span className="link skeleton" style={{width: 118, height: 24}}/>
            </div>
        }
      </div>

      {
        isMobile ?
          null
          :
          <Divider style={{margin: '16px auto'}} type={'horizontal'}/>
      }

      <Row className="fadspot-page-filters-main-block-wrapper" gutter={[48, 0]}>

        <Col xs={24} md={12} style={{paddingRight: isMobile ? 24 : 12}}>
          <div className="fadspot-page-filters-inputs input-filter-wrapper">
            {[150, 196, 171]
              .map(el => (
                <div key={el} className="input-filter-skeleton-wrapper">
                  <span className="link skeleton" style={{width: el, height: 20}}/>
                  <div>
                    <span className="link skeleton" style={{width: 184, height: 20}}/>
                  </div>
                </div>
              ))}
          </div>
        </Col>

        <Col xs={24} md={12} style={{paddingLeft: isMobile ? 24 : 12, marginTop: isMobile ? 12 : 0}}>
          <div className="fadspot-page-filters-options">
            <span className="link skeleton" style={{width: 92, height: 20}}/>
            <div className="fadspot-page-filters-options-creative-wrapper" style={{marginTop: 8}}>
              {
                [1,2,3,4].map(el => <span key={el} className="link skeleton" style={{width: isMobile ? '100%' : 116, height: 40}}/>)
              }
            </div>
            <span className="link skeleton" style={{width: 106, height: 20}}/>
            <div className="fadspot-page-filters-options-engagement-wrapper" style={{marginTop: 12}}>
              {
                [1,2,3,4].map(el => (
                  <div key={el*el} style={{display: "flex", flexDirection: 'column', width: isMobile ? '100%' : 249}}>
                    <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between", width: isMobile ? '100%' : 249}}>
                      <span className="link skeleton" style={{width: 34, height: 20}}/>
                      <span className="link skeleton" style={{width: 86, height: 20}}/>
                    </div>
                    <span className="link skeleton" style={{width: isMobile ? '100%' : 249, height: 4, marginTop: 14}}/>
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>

      <Divider style={{margin: '24px auto'}} type={'horizontal'} />

      <Col span={24} className="selects-wrapper fadspot-page-filters-dropdowns">
        {
          [1,2,3,4,5].map(el => (
            <div key={el/2} className={'select-block'}>
              <span className="link skeleton" style={{width: 114, height: 20}}/>
              <span className="link skeleton" style={{width: '100%', height: 40, marginTop: 8}}/>
            </div>
          ))
        }
      </Col>

      <Divider style={{margin: '30px auto 24px'}} type={'horizontal'} />

      <div className="fadspot-page-filters-button-wrapper">
        <span className="link skeleton" style={{width: 116, height: 40}}/>
        <span className="link skeleton" style={{width: 87, height: 40, marginLeft: 12}}/>
      </div>
    </div>
  )

  return (
    <div className="fadspot-page-filters-wrapper">
      <div className="fadspot-page-filters-title-wrapper">
        <h4 className="fadspot-page-filters-title">
          {t('Search For Ads')}
        </h4>
        {
          isMobile ?
            null
            :
            <div className="fadspot-page-filters-preset-wrapper">
                <span className="fadspot-page-filters-preset-btn"
                      onClick={() => {
                        setVisible(true);
                        setModal('loadFilterPreset');
                      }}
                >
                  {t('Load Filter Preset')}
                </span>
              <span className={cls("fadspot-page-filters-preset-btn", {
                'disabled': !changedFilters
              })}
                    onClick={() => {
                      if (changedFilters) {
                        setVisible(true);
                        setModal('saveFilterPreset');
                      }
                    }}
              >
                  {t('Save Filter Preset')}
                </span>
            </div>
        }
      </div>
      {
        isMobile ?
          null
          :
          <Divider style={{margin: '16px auto 24px'}} type={'horizontal'}/>
      }
      <div className='fadspot-page-filters'>
        <Row className="fadspot-page-filters-main-block-wrapper" gutter={[48, 0]}>
          <Col xs={24} md={12} style={{paddingRight: isMobile ? 24 : 12}}>
            <div className="fadspot-page-filters-inputs input-filter-wrapper">
              {
                inputsData.map(el => (
                  <InputFilter key={rerenderKey.current + el?.key}
                               el={el}
                               onChangeInputFilter={onChangeInputFilter}
                               onPasteInputFilter={onPasteInputFilter}
                               inputFilters={inputFilters}
                               setInputFilters={setInputFilters}
                               isMobile={isMobile}
                               type={el?.key === 'ad_copy' ? 'static' : 'dynamic'}
                               suggestions={suggestions}
                               suggestionsLoading={suggestionsLoading}
                               getSuggestions={getSuggestions}
                               resetSuggestions={resetSuggestions}
                               setInputFiltersSuggestions={setInputFiltersSuggestions}
                  />
                ))
              }
            </div>
          </Col>
          <Col xs={24} md={12} style={{paddingLeft: isMobile ? 24 : 12, marginTop: isMobile ? 12 : 0}}>
            <div className="fadspot-page-filters-options">
              <h5 className="fadspot-page-filters-options-title">
                {t('Creative Type')}
              </h5>
              <div className="fadspot-page-filters-options-creative-wrapper">
                {
                  creativeData.map(el => (
                    <div key={el?.key}
                         className={cls("fadspot-page-filters-options-creative-button", {
                           'active': creativeFilters.includes(el?.key)
                         })}
                         onClick={() => {
                           if (el?.key === 'all') {
                             setCreativeFilters(el?.key)
                           } else {
                             if (creativeFilters.includes(el?.key)) {
                               if (creativeFilters.length === 1) setCreativeFilters('all')
                               else {
                                 if (el?.key === 'video') {
                                   setEngagementFilters(prev => ({...prev, video_length: {min: 0, max: 600}}))
                                 }
                                 setCreativeFilters(state => ([...state.filter(elem => elem !== el?.key)]));
                               }
                             } else {
                               if (creativeFilters.length === 2) {
                                 setCreativeFilters('all');
                               } else {
                                 if (el?.key !== 'video' && !creativeFilters.includes('video')) {
                                   setEngagementFilters(prev => ({...prev, video_length: {min: 0, max: 600}}))
                                 }
                                 setCreativeFilters(state => (typeof state === 'string' ? [el?.key] : [...state, el?.key]));
                               }
                             }
                           }
                         }}
                    >
                      {
                        el?.icon && <Icon role={'icon'} type={el?.icon}/>
                      }
                      {
                        t(el?.title)
                      }
                    </div>
                  ))
                }
              </div>
              <h5 className="fadspot-page-filters-options-title">
                {t('Ad Engagement')}
              </h5>
              <div className="fadspot-page-filters-options-engagement-wrapper">
                {
                  engagementData.map(el => (
                    <div key={el?.key}
                         className={cls('fadspot-page-filters-options-engagement-filter', {
                           'disabled': el?.key === 'video_length' && !(creativeFilters.includes('video') || creativeFilters.includes('all'))
                         })}>
                      <div className="fadspot-page-filters-options-engagement-filter-title-wrapper">
                      <span className="fadspot-page-filters-options-engagement-filter-title">
                        {t(el?.title)}
                      </span>
                        {
                          el?.key === 'video_length' ?
                            <span className="fadspot-page-filters-options-engagement-filter-range">
                              {
                                videoLengthFormat(engagementFilters?.[el?.key]?.min, engagementFilters?.[el?.key]?.max)
                              }
                            </span>
                            :
                            <span className="fadspot-page-filters-options-engagement-filter-range">
                              {
                                acc.formatNumber(engagementFilters?.[el?.key]?.min, 0, ',', '') || 0} - {acc.formatNumber(engagementFilters?.[el?.key]?.max, 0, ',', '') || '10,000'}{engagementFilters?.[el?.key]?.max === 10000 ? '+' : null
                              }
                            </span>
                        }
                      </div>
                      <Slider defaultValue={el?.key === 'video_length' ? [0, 600] : [0, 10000]}
                              value={[engagementFilters?.[el?.key]?.min, engagementFilters?.[el?.key]?.max]}
                              range={{draggableTrack: true}}
                              min={0}
                              max={el?.key === 'video_length' ? 600 : 10000}
                              tooltip={{
                                formatter: (value) => sliderTooltipFormatter(el?.key, value),
                                ...(isMobile && { open: false })
                              }}
                              onChange={(value) => {
                                if (!(el?.key === 'video_length' && !(creativeFilters.includes('video') || creativeFilters.includes('all')))) setEngagementFilters(prev => ({
                                  ...prev, [el?.key]: {min: value[0], max: value[1]}
                                }));
                              }}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </Col>
        </Row>
        <Divider style={{margin: isMobile ? '16px auto' : '24px auto'}} type={'horizontal'}/>
        <Col span={24} className="selects-wrapper fadspot-page-filters-dropdowns">

          <DropdownDateBlock
            label={'Ad Creation Date'}
            id={'ad_created_at'}
            iconType={'database_calendar'}
            data={dateOptions}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            isMobile={isMobile}
            hideTimeZone={true}
          />

          <DropdownBlockTest
            label={'Call To Action'}
            id={'call_to_action'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_action'}
            searchPlaceholder={'Search for call to action...'}
            data={actionOptions}
          />

          <DropdownBlockTest
            label={'Website Provider'}
            id={'website_provider'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_provider'}
            searchPlaceholder={'Search for provider...'}
            data={websiteOptions}
          />

          <DropdownBlockTest
            label={'Ad Copy Language'}
            id={'ad_copy_language'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_globe'}
            searchPlaceholder={'Search for language...'}
            data={languageOptions}
          />

          <DropdownBlockTest
            label={'Domain TLD'}
            id={'domain_tld'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_domain'}
            searchPlaceholder={'Search for TLD...'}
            data={domainOptions}
          />
        </Col>
        <Divider style={{margin: isMobile ? '16px auto' : '30px auto 24px'}} type={'horizontal'}/>
        <div className="fadspot-page-filters-button-wrapper">
          <ButtonComponent className={'fadspot-page-filters-button-reset'}
                           text={t('Reset Filter')}
                           onClick={resetFilters}
          />
          {
            disabledSearch ?
              <Tooltip placement="top"
                       title={
                         <span>
                       {t('You have ran out of credits. To continue using Ad Spot')},
                           <a href="/setting/plan">{t('get more credits')}</a>
                     </span>}
                       destroyTooltipOnHide={true}
                       getPopupContainer={triggerNode => triggerNode?.parentNode}
              >
                <div className={cls('fadspot-page-filters-button-apply fadspot-page-filters-button-reset', {
                  disabled: true
                })}
                >
                  {t('Search')}
                </div>
              </Tooltip>
              :
              <div className={cls('fadspot-page-filters-button-apply fadspot-page-filters-button-reset', {
                disabled: adsLoading
              })}
                   onClick={() => handleSearch(true)}
              >
                {t('Search')}
              </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FiltersBlock;
