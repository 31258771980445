import logo from './logo.svg';

import signIn from './sign-in.png';
import signInMobile from './sign-in_mobile.png';
import signUp from './sign-up.png';
import signUpMobile from './sign-up_mobile.png';
import verify from './verify.png';
import verifyEmail from './verify-email.png';
import verifyEmailMobile from './verify-email_mobile.png';
import verifyCode from './verify-code.png';
import verifyCodeMobile from './verify-code_mobile.png';
import security from './security.png';
import forgotPassword from './forgot-password.png';
import forgotPasswordMobile from './forgot-password_mobile.png';

import createPassword from './create-password.png';
import createPasswordMobile from './create-password_mobile.png';
import successful from './successful.png';
import successfulMobile from './successful_mobile.png';

import successful1 from './successful1.png';
import successful1Mobile from './successful1_mobile.png';

import createPassword1 from './create-password1.png';
import createPassword1Mobile from './create-password1_mobile.png';

import welcome from './welcome-bg.svg';
import welcomeMobile from './welcome_mobile.svg';

import checkoutSuccess from './checkout-success.png';
import checkoutSuccessMobile from './checkout-success_mobile.png';

import menu from './menu.svg';
import emailConfirmed from './emailConfirmed.svg';
import gmail from './to-gmail.svg';
import outlook from './toOutlook.svg';
import yahoo from './toYahoo.svg';
import protonmail from './toProtonmail.svg';
import aol from './toAol.svg';
import zoho from './toZoho.svg';
import icloud from './toICloud.svg';
import gmx from './toGMX.svg';
import hey from './toHey.svg';
import yandex from './toYandex.svg';
import google from './google.svg';
import facebook from './facebook.svg';
import apple from './apple.svg';
import email from './email.svg';
import emailError from './email-error.svg';
import lock from './lock.svg';
import lockError from './lock-error.svg';
import person from './person.svg';

import edit from './edit.svg';
import check from './check.svg';

import defaultImg from './EmptyImageProductDatabase.svg';
import ResendEmailLoader from './resend-email-loader.svg';
import speedometer from './speedometer.png';
import speedometerDark from './speedometer_dark.png';
import random from './icon-random.png';
import babyFull from './icon-baby-full.png';
import beautyFull from './icon-beauty-full.png';
import electronicsFull from './icon-electronics-full.png';
import fashionFull from './icon-fashion-full.png';
import householdFull from './icon-household-full.png';
import fashion_menFull from './icon-fashion_men-full.png';
import fashion_womenFull from './icon-fashion_women-full.png';
import petsFull from './icon-pets-full.png';
import randomFull from './icon-random-full.png';
import babyFullDisabled from './icon-baby-full-disabled.png';
import beautyFullDisabled from './icon-beauty-full-disabled.png';
import electronicsFullDisabled from './icon-electronics-full-disabled.png';
import fashionFullDisabled from './icon-fashion-full-disabled.png';
import fashion_menFullDisabled from './icon-fashion_men-full-disabled.png';
import fashion_womenFullDisabled from './icon-fashion_women-full-disabled.png';
import householdFullDisabled from './icon-household-full-disabled.png';
import petsFullDisabled from './icon-pets-full-disabled.png';
import randomFullDisabled from './icon-random-full-disabled.png';

import randomBig from './category/png/random-big.png';
import random300 from './category/png/random-300.png';
import randomSmall from './category/png/random-active.png';

import amazon from './amazon.svg';
import shopify from './shopify.svg';
import ebay from './ebay.svg';
import aliexpress from './aliexpress.svg';

import mastercard from './_Mastercard.svg';
import maestro from './_Maestro.svg';
import americanExpress from './_AmericanExpress.svg';
import visa from './_VISA.svg';
import payPal from './_PayPal.svg';
import bitPay from './_BitPay.svg';
import bitcoinBtc from './_bitcoin-btc.svg';
import bitcoinCash from './_bitcoin-cash.svg';
import litecoinCash from './_litecoin-cash.svg';
import ethereum from './_ethereum.svg';
import xrp from './_xrp.svg';
import chainlink from './_chainlink.svg';
import cardano from './_cardano.svg';

import videoSignUpSuccess from './video_sign-up-success.mp4';

import logoDropdhip from './logo-dropship.svg';
import heartRed from './heart-red.png';
import heartBlue from './heart-blue.png';

import aliexpressIcon from './aliexpress.png';
import alibabaIcon from './alibaba.png';

import logoInvoice from './logo-invoice.svg';
import logoInvoiceDark from './logo-invoice-dark.svg';
import visa3 from './Visa3.png';
import searchProducts_light from './search-products.png';
import searchProducts_dark from './search-products_dark.png';
import emptyChartBg from './_empty-chart-bg.svg'
import emptyChartBgDark from './_empty-chart-bg_dark.svg'


/*Plans*/
import plan_0 from './drop_size_0.png';
import plan_1 from './drop_size_1.png';
import plan_2 from './drop_size_3.png';
import plan_3 from './drop_size_3.png';

import plan_free from './drop_size_0.png';
import plan_basic from './drop_size_0.png';
import plan_standard from './drop_size_1.png';
import plan_premium from './drop_size_3.png';

import plan_free_small from './plan_free_small.png';
import plan_basic_small from './plan_free_small.png';
import plan_standard_small from './plan_standard_small.png';
import plan_premium_small from './plan_premium_small.png';

import shopifyConnect from './shopify-connect.png'
import shopifyConnectDark from './shopify-connect_dark.png'
import autoConnect from './auto-connect.svg'
import autoConnectDark from './auto-connect_dark.svg'

const Images = {
  logo,
  emailConfirmed,
  signIn,
  signInMobile,
  signUp,
  signUpMobile,
  verify,
  verifyEmail,
  verifyEmailMobile,
  verifyCode,
  verifyCodeMobile,
  security,
  forgotPassword,
  forgotPasswordMobile,
  createPassword,
  createPasswordMobile,
  successful,
  successfulMobile,
  successful1,
  successful1Mobile,
  createPassword1,
  createPassword1Mobile,
  welcome,
  welcomeMobile,
  checkoutSuccess,
  checkoutSuccessMobile,

  menu,
  gmail,
  outlook,
  yahoo,
  protonmail,
  aol,
  zoho,
  icloud,
  gmx,
  hey,
  yandex,
  google,
  facebook,
  email,
  emailError,
  lock,
  lockError,
  person,
  apple,

  edit,
  check,

  random,
  randomBig,
  random300,
  randomSmall,

  babyFull,
  beautyFull,
  electronicsFull,
  fashionFull,
  fashion_menFull,
  fashion_womenFull,
  householdFull,
  petsFull,
  randomFull,

  babyFullDisabled,
  beautyFullDisabled,
  electronicsFullDisabled,
  fashionFullDisabled,
  householdFullDisabled,
  petsFullDisabled,
  randomFullDisabled,
  fashion_menFullDisabled,
  fashion_womenFullDisabled,

  amazon,
  shopify,
  ebay,
  aliexpress,

  mastercard,
  maestro,
  americanExpress,
  visa,
  payPal,
  bitPay,
  bitcoinBtc,
  bitcoinCash,
  litecoinCash,
  ethereum,
  xrp,
  chainlink,
  cardano,

  videoSignUpSuccess,

  defaultImg,
  ResendEmailLoader,
  logoDropdhip,
  heartRed,
  heartBlue,

  speedometer,
  speedometerDark,

  aliexpressIcon,
  alibabaIcon,

  logoInvoice,
  logoInvoiceDark,
  visa3,
  searchProducts_light,
  searchProducts_dark,

  plan_0,
  plan_1,
  plan_2,
  plan_3,

  plan_free,
  plan_basic,
  plan_standard,
  plan_premium,

  plan_free_small,
  plan_basic_small,
  plan_standard_small,
  plan_premium_small,

  autoConnect,
  autoConnectDark,
  shopifyConnect,
  shopifyConnectDark,
  emptyChartBg,
  emptyChartBgDark,
};

export default Images;
