import React from 'react';
import { Spin } from 'antd';
import SupplierBlock from './SupplierBlock';
import './styles.less';

export default function Suppliers({
  product,
  openModal,
  onChange,
  onImportSupplier,
  saveInfo,
  fetching,
}) {
  function handleDelete(value) {
    saveInfo(value);
    openModal('suppliers_delete');
  }
  function handleChange(params) {
    const { type, id, field, value } = params;
    if (product?.suppliers[type].length) {
      const newData = product?.suppliers[type].map((el) =>
        el.id === id ? { ...el, [field]: value } : el,
      );
      onChange({
        suppliers: {
          ...product?.suppliers,
          [type]: newData,
        },
      });
    }
  }

  return (
    <Spin spinning={fetching}>
      <div className="suppliers-block">
        <div className="block-with-label">
          <div className="block-with-label_label bold-text">
            Suppliers <span className="important">*</span>
          </div>
          <SupplierBlock
            title="AliExpress"
            type="aliexpress"
            suppliersList={product?.suppliers?.aliexpress}
            length={product?.suppliers?.aliexpress?.length}
            onChange={handleChange}
            onDelete={handleDelete}
            onImportSupplier={onImportSupplier}
          />
          <SupplierBlock
            title="Alibaba"
            type="alibaba"
            suppliersList={product?.suppliers?.alibaba}
            length={product?.suppliers?.alibaba?.length}
            onChange={handleChange}
            onDelete={handleDelete}
            onImportSupplier={onImportSupplier}
          />
        </div>
      </div>
    </Spin>
  );
}
