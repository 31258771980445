import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import cls from "classname";
import {Table} from "antd";
import {getColumns} from "./components/SalesStoreTableColumns";
import Creators from "./reducer";
import DatabaseProductsPagination from "../ProductDatabasePage/components/DatabaseProductsPagination";
import DatabaseProductsSkeleton from "../ShopifySearch/components/DatabaseProductsSkeleton";

const AdminSalesStorePage = (props) => {
  const {
    fetching,
    handleOpenNotAvailable,
    onSort,
    sortOrder,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    total,
    isChanged
  } = props;

  let navigate = useNavigate();;
  const tableRef = useRef(null);
  //tracked stores
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const isAvailable = useSelector(state => state.adminStoreTable.isAvailable);
  const filterDates = useSelector(state => state.adminStoreTable.filters.report_period);
  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const stores = useSelector(state => state.adminStoreTable.stores) || {results: [], next: null};
  const { results } = stores;

  const [newTab, setNewTab] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) {
      setNewTab(false);
      dispatch(Creators.resetIsAvailable());
      setFirstLoading(false)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (isAvailable && !firstLoading && selected) {
      if (newTab) {
        window.open(`/admin/store-table/store/${selected?.id}`, '_blank');
        setSelected(null);
      } else {
        navigate(`/admin/store-table/store/${selected?.id}`, {
          state: {
            name: selected?.title,
            link: `/admin/store-table/store/${selected?.id}`,
          }
        });
      }
    } else if (!isAvailable && typeof isAvailable !== 'object' && selected) {
      handleOpenNotAvailable();
      setSelected(null);
    }
    dispatch(Creators.resetIsAvailable());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [isAvailable, selected?.id, newTab])

  //no link if not available
  function goStoreDetailsPage (record, e){
    if(record?.not_available){
      handleOpenNotAvailable()
    }else{
      if (e.metaKey || e.ctrlKey) setNewTab(true)
      else setNewTab(false)
      setSelected(record);
      dispatch(Creators.getAdminDetailedStoreInfoCheckRequest({id: record.id, date_range: {min: minDate, max: maxDate}}));
    }
  }

  return (
    <>
      {fetching ? <DatabaseProductsSkeleton/> : null}
      <div className={cls('products-database-table-wrapper sales-tracker-stores-table-wrapper', {
        'product-database-table-wrapper-hidden': (fetching && !newTab),
      })}>
        <DatabaseProductsPagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalProducts={total}
          disabled={!isChanged}
        />
        <div id={'product-database-table-body'} className="product-database-table-body sales-tracker-stores-table-body">
          <Table
            components={{
              // eslint-disable-next-line react/prop-types
              table: ({className, children, ...other}) => (
                <table
                  {...other}
                  ref={tableRef}
                  className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
            className="list-table"
            loading={fetching}
            rowClassName={'product-database-table-row'}
            rowKey={(record) => record.id}
            dataSource={results?.length ? [...results] : []}
            columns={getColumns({
              sortOrder,
              goStoreDetailsPage,
            })}
            pagination={false}
            locale={{emptyText: '-'}}
            scroll={{
              x: 1600, //'max-content',
              y: 'calc(100vh - 96px - 54px)',
            }}
            sticky={true}
            sortDirections={['descend', 'ascend', null]}
            onChange={(pagination, filters, sorter) => {
              onSort(sorter);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AdminSalesStorePage, (prev, next) => prev.fetching === next.fetching);
