import React from 'react';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Icon from '../../../Icon';
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";
import ButtonComponent from "../../../Components/Button";
import {useTranslation} from "react-i18next";
import {Divider} from "antd";
import dayjs from "dayjs";
import MainBlockStat from "../../SalesTrackerPage/components/MainBlockStat";
import acc from "accounting";
import {useLocation} from "react-router-dom";
import {useParams, useNavigate} from "react-router";
import '../../SalesTrackerPage/components/MainBlockNew.less';


const MainBlockNewAdSpot = (
  {
    data,
    isMobile=false,
    isShopify,
    setVisible,
    setModal,
    hideIcon=false
  }) => {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const {search} = useLocation();

  const statBlocks = [
    // {title: 'Page Tracked By', value: acc.formatNumber(data?.tracked_by, 0, ',')},
    {title: 'Store Creation Date', value: data?.store_created_at ? dayjs(data?.store_created_at).format('MMM DD, YYYY') : 'No Data'},
    {title: `Advertiser ${data?.ads_count === 1 ? 'Ad' : 'Ads'} Found`, value: acc.formatNumber(data?.ads_count, 0, ','), onClick: () => {
        navigate(`/ad-spot/${id}/all_ads/${search}`)
      }},
    {title: 'Provider', value: data?.provider},
    {title: `Store ${data?.store_products_count === 1 ? 'Product' : 'Products'}`, value: acc.formatNumber(data?.store_products_count, 0, ','),
      ...(data?.store_products_count && {
        onClick: () => {
          setVisible(true);
          setModal('storeProducts');
        }
      }
    )},
      (data?.store_apps?.length && {
        title: `Store ${data?.store_apps?.length === 1 ? 'App' : 'Apps'}`,
        value: 'View List', onClick: () => {
          setVisible(true);
          setModal('storeApps');
        }}),
  ];

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }

  return (
    <div className="report-main-block report-main-block-new fadspot">
      <div className="main-info-wrapper">
        <div className="main-info fad-spot">
          <div className={'report-main-block-logo'}>
            {
              data?.advertiser?.icon
                ? <img src={data?.advertiser?.icon}
                       alt=""
                       onError={utils.addDefaultSrc}
                       width="62" height="62"
                />
                : <Image src={Images.defaultImg} small={true}/>
            }
          </div>

          <div className="main-title">
            <span className="report-main-block_title fad-spot">
              <span onMouseOver={mouseOver}
                    onMouseLeave={mouseLeave}
                    data-text={data?.advertiser?.name}
              >
                {data?.advertiser?.name}
              </span>
              {
                data?.advertiser?.is_verified ?
                  <Icon role={'icon'} type={'ad_spot_checkmark'}/>
                  :
                  null
              }
            </span>

            {
              data?.domain ?
                <div className="main-link">
                  <a className={'report-main-block_link chart-block_link'}
                     target={'_blank'}
                     href={data?.domain ? `https://${data?.domain}` : '#'}
                     rel="noopener noreferrer">
                    <span>{data?.domain}</span>
                  </a>
                  {
                    hideIcon || !data?.all_domains?.length ?
                      null
                      :
                      <TooltipColumnIcon record={data} isST={true}/>
                  }
                </div>
                :
                null
            }
          </div>
          {
            isShopify ?
              null
              :
              isMobile ?
                null
                :
                <Divider type={'vertical'} style={{margin: '6px 24px', height: 48}} />
          }
          {/*{*/}
          {/*  isShopify ?*/}
          {/*    null*/}
          {/*    :*/}
          {/*    <div className={'main-title-stat'}>*/}
          {/*      <h3>*/}
          {/*        {t('Brand Tracked By')}*/}
          {/*      </h3>*/}
          {/*      <span>*/}
          {/*        {acc.formatNumber(data?.tracked_by, 0, ',')} {t('Others')}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*}*/}
        </div>
        {
          isMobile ?
            null
            :
            <div className={'report-main-block-new-button-wrapper'}>
              <ButtonComponent className={'report-main-block-new-button'}
                               text={''}
                               onClick={() => window.open(data?.advertiser?.url, '_blank')}
              >
                <Icon role={'icon'} type={'search_facebook'} isDark={true}/>
                {t('View Page')}
              </ButtonComponent>
              {/*<ButtonComponent className={'report-main-block-new-button track'}*/}
              {/*                 text={''}*/}
              {/*                 onClick={() => console.log('click')}*/}
              {/*>*/}
              {/*  <Icon role={'icon'} type={'ad_spot_views'} color={'white'}/>*/}
              {/*  {t('Track Website')}*/}
              {/*</ButtonComponent>*/}
            </div>
        }
      </div>
      {
        isShopify ?
          <Divider style={{margin: '16px auto'}} type={'horizontal'} />
          :
          null
      }
      {
        isShopify ?
          <div className="main-stats">
            <div className="stat-blocks-wrapper">
              {statBlocks.map(el => (
                <MainBlockStat key={el?.title} {...el} t={t}/>
              ))}
            </div>
            {data?.social_channels?.length > 0 &&
              <div className="channels-wrapper">
                <h4 className="main-block-stat-title">
                  {
                    data?.social_channels?.length === 1 ?
                    t('Social Channel')
                      :
                    t('Social Channels')
                  }
                </h4>
                <div className="main-block-stat-value">
                  {
                    data?.social_channels?.length > 0 ?
                      data?.social_channels?.map(el => (
                        <a key={el?.platform}
                           href={el?.link ? el?.link : '#'}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="link with-img"
                        >
                          <Icon role={'button'} type={`search_${el?.platform}`} />
                        </a>
                      ))
                      :
                      null
                  }
                </div>
              </div>
            }
          </div>
          :
          null
      }
    </div>
  );
};

export default MainBlockNewAdSpot;
