import React from 'react';

const AdSpotRedirect = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.2929 11.2929C19.4804 11.1054 19.7348 11 20 11C20.2652 11 20.5196 11.1054 20.7071 11.2929C20.8946 11.4804 21 11.7348 21 12V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H12C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4C13 4.26522 12.8946 4.51957 12.7071 4.70711C12.5196 4.89464 12.2652 5 12 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V12C19 11.7348 19.1054 11.4804 19.2929 11.2929ZM17.5799 5H15.9999C15.7347 5 15.4804 4.89464 15.2928 4.70711C15.1053 4.51957 14.9999 4.26522 14.9999 4C14.9999 3.73478 15.1053 3.48043 15.2928 3.29289C15.4804 3.10536 15.7347 3 15.9999 3H19.9999C20.2652 3 20.5195 3.10536 20.707 3.29289C20.8946 3.48043 20.9999 3.73478 20.9999 4V8C20.9999 8.26522 20.8946 8.51957 20.707 8.70711C20.5195 8.89464 20.2652 9 19.9999 9C19.7347 9 19.4804 8.89464 19.2928 8.70711C19.1053 8.51957 18.9999 8.26522 18.9999 8V6.42L12.7099 12.7C12.617 12.7937 12.5064 12.8681 12.3845 12.9189C12.2627 12.9697 12.132 12.9958 11.9999 12.9958C11.8679 12.9958 11.7372 12.9697 11.6154 12.9189C11.4935 12.8681 11.3829 12.7937 11.2899 12.7C11.1962 12.607 11.1218 12.4964 11.071 12.3746C11.0203 12.2527 10.9941 12.122 10.9941 11.99C10.9941 11.858 11.0203 11.7273 11.071 11.6054C11.1218 11.4836 11.1962 11.373 11.2899 11.28L17.5799 5Z"
            fill="#707BA0"/>
    </svg>
  );
};

export default AdSpotRedirect;
