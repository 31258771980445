import React from 'react';
import UniversityCourseChapterCardSkeleton from "./UniversityCourseChapterCardSkeleton";
import {Divider} from "antd";

const UniversityCourseChapterSkeleton = ({isMobile}) => {
  return (
    <div className={'product-database-page university-page course-chapter-page'}>

      <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
        <span className='link skeleton' style={{width: 91, height: 16, marginRight: 8}}/>
        <span className='link skeleton' style={{width: 24, height: 24, marginRight: 8}}/>
        <span className='link skeleton' style={{width: 74, height: 16}}/>
      </div>

      <span className='link skeleton' style={{width: 181, height: 32}}/>

      <div className="course-chapter-page-content">
        <div className="course-chapter-page-chapter-wrapper">
          {
            [1,2,3,4,5].map(el => (
              <UniversityCourseChapterCardSkeleton key={el} isMobile={isMobile} />
            ))
          }
        </div>
        {
          isMobile ?
            null
            :
            <div className="course-chapter-page-progress-wrapper">
              <div className="course-chapter-page-progress">
                <span className='link skeleton' style={{width: 322, height: 180}}/>
                <div className="course-chapter-page-progress-info">
                  <span className='link skeleton' style={{width: 197, height: 20}}/>
                  <span className='link skeleton' style={{width: 204, height: 24, marginTop: 4}}/>
                  <span className='link skeleton' style={{width: 292, height: 8, marginTop: 16}}/>
                </div>
              </div>
              <div className="course-chapter-page-instructor">
                <span className='link skeleton' style={{width: 66, height: 20, marginBottom: 12 }}/>
                <div className="course-chapter-page-instructor-info">
                  <span className='link skeleton' style={{width: 64, height: 64, borderRadius: '50%'}}/>
                  <div className="course-chapter-page-instructor-bio">
                    <span className='link skeleton' style={{width: 112, height: 24}}/>
                    <span className='link skeleton' style={{width: 129, height: 20, marginTop: 4}}/>
                  </div>
                </div>
                <Divider type={'horizontal'} className={'course-chapter-page-instructor-divider'} />
                <span className='link skeleton' style={{width: 292, height: 140}}/>
              </div>
            </div>
        }
      </div>
    </div>
  );
};

export default UniversityCourseChapterSkeleton;
