import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Card } from 'antd';
import Icon from '../../Icon';
import dayjs from 'dayjs';
import acc from 'accounting';
import './styles-plan-details.less';

export default function PlanDetails({ subscriptions, setYearlySubscription, isMobile }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subscription = subscriptions[0];

  let nextPaymentDate = subscription?.next_payment_date;
  let nextPaymentAmount = subscription?.next_payment_amount || 0;
  let planPriceType = subscription?.plan?.price_type?.id;
  let isFreePlan = subscription?.plan?.is_trial;
  const isCancelledPlan = subscription?.expire_date && subscription?.next_payment_date === null;
  const isPaused = subscription?.payment_status?.id === 7;

  function handleNavigate() {
    if (planPriceType === 'monthly' && !isFreePlan) setYearlySubscription(true);
    navigate('/setting/plan');
  }

  return (
    <Card className="plan-details-block">
      <div className="plan-details-header">
        <div className="billing-title-text">
          {t('Plan Details')}

          {
            subscription?.scheduled_plan || isCancelledPlan || isPaused
              ? (
                <Icon
                  type="warning_slim"
                  role="button"
                  color={'#F6B60E'}
                  btnType="scheduled-tooltip-icon"
                  tooltipProps={{
                    trigger: isMobile ? 'click' : 'hover',
                    placement: isMobile ? 'bottom' : 'bottomRight',
                    getPopupContainer: (trigger) => trigger.parentNode,
                  }}
                  titleText={
                    isPaused ?
                      t('Your _currentPlan_ plan is set to be paused until _date_',
                        {
                          currentPlan: subscription?.plan?.plan?.label,
                          date: dayjs(subscription?.next_payment_date).format('MMM DD, YYYY'),
                        },
                      )
                      :
                    isCancelledPlan ?
                      t('Your _currentPlan_ plan is set to be cancelled on _date_',
                        {
                          currentPlan: subscription?.plan?.plan?.label,
                          date: dayjs(subscription?.expire_date).format('MMM DD, YYYY'),
                        },
                      )
                      :
                      t('Your _currentPlan_ plan is set to be changed on _date_ to _scheduledPlan_',
                    {
                      currentPlan: subscription?.plan?.plan?.label,
                      date: dayjs(subscription?.expire_date || subscription?.next_payment_date).format('MMM DD, YYYY'),
                      scheduledPlan: subscription?.scheduled_plan?.name,
                    },
                  )}
                />)
              : null
          }

        </div>
      </div>

      <ul className={'plan-details-list-info'}>
        <li className={'plan-details-list-info-item'}>
          <span className={'plan-details-list-info-item_title'}>{t('Plan')}</span>
          <span className={'plan-details-list-info-item_value'}>{subscription?.plan?.plan?.label || '-'}</span>
        </li>

        <li className={'plan-details-list-info-item'}>
          <span className={'plan-details-list-info-item_title'}>{t('Next Billing On')}</span>
          <span className={'plan-details-list-info-item_value'}>{(!nextPaymentDate || isFreePlan) ? '-' : dayjs(nextPaymentDate).format('M/DD/YYYY')}</span>
        </li>

        <li className={'plan-details-list-info-item'}>
          <span className={'plan-details-list-info-item_title'}>{t('Next Billing Amount')}</span>
          <span className={'plan-details-list-info-item_value'}>{isCancelledPlan ? '$0,00' : isFreePlan ? '-' : `$${acc.formatNumber(nextPaymentAmount/100, 2, ' ', ',')}`}</span>
        </li>

        <li className={'plan-details-list-info-item'}>
          <span className={'plan-details-list-info-item_title'}>{t('Billing Frequency')}</span>
          <span className={'plan-details-list-info-item_value'}>{isFreePlan ? '-' : subscription?.plan?.price_type?.value || '-'}</span>
        </li>
      </ul>

      <div
        className={'edit-btn-mobile_plan-details'}
        onClick={handleNavigate}
      >
        {
          (planPriceType === 'monthly' && !isFreePlan)
            ? <span>
              {t('Get Annual Plan')}
              <span className="tags">{t('SAVE UP TO _value_')}</span>
            </span>
            : <span>{('Manage Plan')}</span>
        }
      </div>
    </Card>
  );
}
