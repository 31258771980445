import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ButtonComponent from "../../../Components/Button";
import Icon from "../../../Icon";
import cls from "classname";
import './DashboardPageBlock.less';

const DashboardPageBlock = (
  {
    title,
    text,
    video,
    icon,
    link,
    setVideo,
    setVisible,
    iconColor,
    skeleton,
    tag=null,
    disabled=false
  }
) => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  if (skeleton) return (
    <div className='dashboard-page-block'>
      <div className="dashboard-page-block-content">
        <div className={`dashboard-page-block-icon dashboard-page-block-icon-${iconColor}`}>
          <span className='link skeleton' style={{width: '100%', height: 96}} />
        </div>
        <div className='dashboard-page-block-text'>
          <h4 className="dashboard-page-block-title">
            <span className='link skeleton' style={{width: 155, height: 24}} />
          </h4>
          <h5 className="dashboard-page-block-subtitle">
            <span className='link skeleton' style={{width: 190, height: 70}} />
          </h5>
        </div>
      </div>
      <div className="dashboard-page-block-buttons">
        <span className='link skeleton skeleton-button' style={{width: '100%', height: 40}} />
        <span className='link skeleton skeleton-button' style={{width: '100%', height: 40}} />
      </div>
    </div>
  )

  return (
    <div className='dashboard-page-block'>
      <div className="dashboard-page-block-content">
        <div className={`dashboard-page-block-icon dashboard-page-block-icon-${iconColor}`}>
          <Icon type={icon} role={'icon'} />
        </div>
        <div className='dashboard-page-block-text'>
          <h4 className="dashboard-page-block-title">
            {t(title === 'Competitor Research' ? 'sidebar.competitor_research' : title)}
            {
              tag ?
              <span className={'dashboard-page-block-title-tag'}>{tag}</span>
              :
              null
            }
          </h4>
          <h5 className="dashboard-page-block-subtitle">
            {t(text)}
          </h5>
        </div>
      </div>
      <div className="dashboard-page-block-buttons">
        <ButtonComponent className={'dashboard-page-block-button'}
                         text={title}
                         onClick={() => navigate(link)}
        />
        <ButtonComponent className={cls('dashboard-page-block-button dashboard-page-block-button-transparent', {
          disabled: disabled
        })}
                         onClick={() => {
                           if (!disabled) {
                             setVideo(video);
                             setVisible(true);
                           }
                         }}
        >
          <span>
          <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
            {t('Watch Tutorial')}
          </span>
        </ButtonComponent>
      </div>
    </div>
  );
};

export default DashboardPageBlock;
