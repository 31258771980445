import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router'
import PasswordValidator from '../PasswordValidator'
import Icon from '../../Icon'
import ButtonComponent from "../Button";
import openNotification from '../Notification';
import NotificationError from '../../Icon/img/NotificationError';


function NewPasswordForm({ error, message, ...props }) {

  const [value, setValue] = useState(null)
  const [valueConfirm, setValueConfirm] = useState(null)
  const [newPassIconColor, setNewPassIconColor] = useState('#707BA0');
  const [repeatPassIconColor, setRepeatPassIconColor] = useState('#707BA0');
  const [errorText, setErrorText] = useState(null)

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        if (error.length > 100) {
          setErrorText('Server response error')
        } else {
          setErrorText(error)
        }
      } else {
        setErrorText(error.message[0].detail)
        setNewPassIconColor('#D71313')
      }
    }
  }, [error])

  useEffect(() => {
    if (errorText) {
      openNotification({
        type: 'error',
        icon: <NotificationError />,
        message: errorText,
        style: { minWidth: '716px' },
        getContainer: document.body,
        duration: 3,
      });
      setTimeout(() => {
        navigate('/setting')
      }, 3000)
      props.resetError();
    }
    if (!errorText && !error && message) {
      openNotification({
        type: 'success',
        message: t(message.detail ?? 'A password has been successfully set!'),
        style: { minWidth: '716px' },
      });
      setTimeout(() => {
        navigate('/setting')
      }, 3000)
      props.resetMessage();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [errorText, error, message]);

  const onFinish = values => {
    const data = { ...values }
    props.onSubmit(data.password)
  }

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0]['errors'])
      setNewPassIconColor('#D71313')
      setRepeatPassIconColor('#D71313')
    }
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ])
    props.resetError();
    setErrorText(null)
    setNewPassIconColor('#707BA0')
    setRepeatPassIconColor('#707BA0')
  }

  function onFocusHandler(e) {
    const target = e.target

    handleClearError(e, target.name)
  }

  function handleChange(e) {
    const { name, value } = e.target
    if (name === 'password') setValue(value)
    if (name === 'confirm') setValueConfirm(value)
  }

  return (
    <Form onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger='onSubmit'
      className="login-form login-create-new-pass-form"
      layout="vertical"
      form={form}
      requiredMark={false}
    >
      <PasswordValidator value={value} error={errorText} isLogin={true} />

      <Form.Item label="New Password"
        name="password"
        validateStatus={errorText ? "error" : null}
        rules={[{ required: true, message: "Required field" }]}
      >
        <Input.Password
          size="large"
          name="password"
          placeholder={t('New Password')}
          autoComplete="new-password"
          visibilityToggle
          iconRender={visible => (
            visible
              ? <Icon type='eye' color={'#C5CCE3'} />
              : <Icon type='eye_invisible' color={'#C5CCE3'} />
          )
          }
          prefix={<Icon type={'lock'} color={newPassIconColor} />}
          onChange={(e) => {
            handleChange(e)
            setNewPassIconColor('#225AEA')
          }}
          onFocus={(e) => {
            onFocusHandler(e)
            setNewPassIconColor('#225AEA')
          }}
          onBlur={() => setNewPassIconColor('#707BA0')}
        />
      </Form.Item>

      <Form.Item label="Confirm Password"
        name="confirm"
        style={{ marginBottom: 32 }}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || !getFieldValue('password') || getFieldValue('password') === value) {
                if (value?.length < 6) {
                  return Promise.reject('Must be at least 6 characters')
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.reject('Passwords do not match')
              }
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          name="confirm"
          placeholder={t('Confirm Password')}
          autoComplete='dontshow'
          visibilityToggle
          iconRender={visible => (
            visible
              ? <Icon type='eye' color={'#C1C8DD'} />
              : <Icon type='eye_invisible' color={'#C1C8DD'} />
          )
          }
          prefix={<Icon type={'lock'} color={repeatPassIconColor} />}
          onChange={(e) => {
            handleChange(e)
            setRepeatPassIconColor('#225AEA')
          }}
          onFocus={(e) => {
            onFocusHandler(e)
            setRepeatPassIconColor('#225AEA')
          }}
          onBlur={() => setRepeatPassIconColor('#707BA0')}
        />
      </Form.Item>


      <ButtonComponent type="primary"
        htmlType="submit"
        className="btn-primary"
        style={{ width: '100%' }}
        disabled={!valueConfirm || (value?.length < 6 || valueConfirm?.length < 6)}
      >
        {t('Submit')}
      </ButtonComponent>


    </Form>
  )
}

export default NewPasswordForm