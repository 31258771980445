import React from 'react';
import Skeleton from '../../../Components/Skeleton';
import './skeleton-overview.less';

export const ProductOverviewSkeletons = () => {

  const dropsCount = new Array(3).fill(0);

  return (
    <div className="skeleton skeleton--product-overview-page">
      <div className="skeleton_block skeleton_block--breadcrumbs">
        <div className="skeleton_row">
          <Skeleton width={64} height={24} borderRadius={6} />
          <Skeleton width={24} height={24} borderRadius={6} />
          <Skeleton width={64} height={24} borderRadius={6} />
        </div>
      </div>
      <div className="skeleton_block skeleton_block--search">
        <div className="skeleton_row">
          <Skeleton width={800} height={48} borderRadius={6} />
          <Skeleton width={223} height={48} borderRadius={6} />
        </div>
      </div>
      <div className="skeleton_block skeleton_block--filter">
        <div className="skeleton_row">
          <Skeleton width={678} height={24} borderRadius={6} />
        </div>
      </div>
      <div className="skeleton_block skeleton_block--product-overview">
        <div className="skeleton_row">
          {dropsCount.map((value, key) => (
            <div
              className="skeleton_block skeleton_block--product"
              key={`skeleton-overview-${key}`}
            >
              <Skeleton
                width={'100%'}
                height={'auto'}
                borderRadius={0}
                className="skeleton-item--no-radius-bottom responsive-square"
              />
              <div className="skeleton_block skeleton_block--product-info">
                <div className="skeleton_row">
                  <Skeleton width={165} height={32} borderRadius={6} />
                  <Skeleton width={32} height={32} borderRadius={6} />
                </div>
                <div className="skeleton_block skeleton_block--info">
                  <div className="skeleton_row">
                    <div className="skeleton_cell">
                      <Skeleton width={36} height={24} borderRadius={6} />
                      <Skeleton width={50} height={20} borderRadius={6} />
                    </div>
                    <div className="skeleton_cell">
                      <Skeleton width={39} height={24} borderRadius={6} />
                      <Skeleton width={27} height={20} borderRadius={6} />
                    </div>
                    <div className="skeleton_cell">
                      <Skeleton width={72} height={24} borderRadius={6} />
                      <Skeleton width={40} height={20} borderRadius={6} />
                    </div>
                  </div>
                  <div className="skeleton_row">
                    <div className="skeleton_cell">
                      <Skeleton width={53} height={24} borderRadius={6} />
                      <Skeleton width={38} height={20} borderRadius={6} />
                    </div>
                    <div className="skeleton_cell">
                      <Skeleton width={64} height={24} borderRadius={6} />
                      <Skeleton width={28} height={20} borderRadius={6} />
                    </div>
                    <div className="skeleton_cell">
                      <Skeleton width={79} height={24} borderRadius={6} />
                      <Skeleton width={80} height={20} borderRadius={6} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="skeleton_block skeleton_block--button">
                <Skeleton
                  width={334}
                  height={48}
                  borderRadius={0}
                  className="skeleton-item--no-radius-top"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductOverviewSkeletons;
