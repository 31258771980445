import React from 'react'
import {useTranslation} from 'react-i18next'

import  './StepTitle.less'

const StepTitle = ({stepInfo, style, ...props}) => {

  const {step, title, text} = stepInfo

  const {t} = useTranslation()

  return (
    <div className='step-title'
         style={style || {}}
    >
      <div>
        <span>{step}</span>
        <span>{t(title)}</span>
      </div>
      <span className='step-text'>{t(text)}</span>

    </div>
  )
}

export default StepTitle
