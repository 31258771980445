/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';

import PasswordValidator from '../PasswordValidator';
import Icon from '../../Icon';
import ButtonComponent from "../Button";
import './styles-change-password.less';

function CreateNewPassword({
  userError,
  saveResult,
  onClose,
  onSubmit,
  userErrorsReset,
  userSaveResultReset,
  clearForm,
  isMobile,
  password_created,
}) {
  const [errorText, setErrorText] = useState(null);
  const [passIconColor, setPassIconColor] = useState('#707BA0');
  const [repeatIconColor, setRepeatIconColor] = useState('#707BA0');

  const [values, setValues] = useState({
    password: null,
    confirm: null,
  });

  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (userError) {
      if (typeof userError === 'string') {
        if (userError.length > 100) {
          setErrorText('Server response error');
        } else {
          setErrorText(userError);
          setRepeatIconColor('#D71313');
          setPassIconColor('#D71313');
        }
      } else {
        setErrorText(Object.values(userError)[0]);
        setRepeatIconColor('#D71313');
        setPassIconColor('#D71313');
      }
    }
  }, [userError]);

  useEffect(() => {
    if (saveResult?.create) {
      clearFormData();
      onClose();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, userError]);

  useEffect(() => {
    clearFormData();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [clearForm]);

  function clearFormData() {
    form.resetFields();
    setValues({ password: null, confirm: null });
    setErrorText(null);
    setRepeatIconColor('#707BA0');
    setPassIconColor('#707BA0');
    userSaveResultReset();
    userErrorsReset();
  }

  const onFinish = (value) => {
    const data = { ...value };
    userErrorsReset();
    onSubmit(data.password);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0].errors);
      setRepeatIconColor('#D71313');
      setPassIconColor('#D71313');
    }
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setRepeatIconColor('#707BA0');
    setPassIconColor('#707BA0');
  }

  function onFocusHandler(e) {
    handleClearError(e, e.target.name);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  const doubleBts = (
    <>
      <ButtonComponent
        type="primary"
        htmlType="button"
        className="btn-primary btn-cancel"
        onClick={onClose}
      >
        {t('Cancel')}
      </ButtonComponent>
      <ButtonComponent
        type="primary"
        htmlType="submit"
        className="btn-primary btn-submit"
        disabled={!values.confirm}
      >
        {t('Continue')}
      </ButtonComponent>
    </>
  );

  return (
    <Form
      className="change-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger="onSubmit"
      layout="vertical"
      form={form}
    >
      <Form.Item
        label=""
        className="change-form-item new-p"
        rules={[
          {
            required: true,
            message: 'Please enter your password!',
          },
          ({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || !getFieldValue('confirm') || getFieldValue('confirm') === value) {
                if(value?.length < 6){
                  return Promise.reject('Must be at least 6 characters')
                }else{
                  return Promise.resolve()
                }
              } else {
                return Promise.reject('Passwords do not match')
              }
            },
          }),
        ]}
        validateStatus={errorText ? 'error' : null}
      >
        <PasswordValidator value={values.password} error={errorText} />
        <Form.Item name="password" noStyle>
          <Input.Password
            size="large"
            name="password"
            placeholder={t('New Password')}
            autoComplete="new-password"
            visibilityToggle
            iconRender={(visible) =>
              visible ? (
                <Icon type="eye" color="#C5CCE3" />
              ) : (
                <Icon type="eye_invisible" color="#C5CCE3" />
              )
            }
            prefix={<Icon type={'lock'} color={errorText ? '#D71313' : passIconColor} />}
            onChange={(e) => {
              handleChange(e)
              setPassIconColor('#225AEA')
            }}
            onFocus={(e) => {
              onFocusHandler(e)
              setPassIconColor('#225AEA')
            }}
            onBlur={() => setPassIconColor('#707BA0')}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label=""
        name="confirm"
        className="change-form-item"
        validateStatus={errorText ? 'error' : null}
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: t('Please confirm your password!'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                !value ||
                !getFieldValue('password') ||
                getFieldValue('password') === value
              ) {
                return value?.length < 6
                  ? Promise.reject(t('Must be at least 6 characters'))
                  : Promise.resolve();
              }
              return Promise.reject(t('Passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          name="confirm"
          placeholder={
            password_created ? t('Confirm Password') : t('New Password')
          }
          autoComplete="dontshow"
          visibilityToggle
          iconRender={(visible) =>
            visible ? (
              <Icon type="eye" color="#C5CCE3" />
            ) : (
              <Icon type="eye_invisible" color="#C5CCE3" />
            )
          }
          prefix={<Icon type={'lock'} color={errorText ? '#D71313' : repeatIconColor} />}
          onChange={(e) => {
            handleChange(e)
            setRepeatIconColor('#225AEA')
          }}
          onFocus={(e) => {
            onFocusHandler(e)
            setRepeatIconColor('#225AEA')
          }}
          onBlur={() => setRepeatIconColor('#707BA0')}
        />
      </Form.Item>
      {isMobile ? (
        <div className="double-btn-wrp" style={{ marginTop: '24px' }}>
          {doubleBts}
        </div>
      ) : password_created ? (
        <ButtonComponent
          type="primary"
          htmlType="submit"
          className="btn-primary confirm-btn"
          style={{ width: '187px', margin: '32px 0px 0px auto' }}
          disabled={!values.confirm}
        >
          {t('Confirm')}
        </ButtonComponent>
      ) : (
        <div className="double-btn-block">{doubleBts}</div>
      )}
    </Form>
  );
}

export default CreateNewPassword;
