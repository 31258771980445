/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';

import { Row, Col } from 'antd';

import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';

import utils, { checkSaturationIndex } from '../../../Utils/utils';
import './OverviewBlock.less';

const acc = require('accounting');

const listData = [
  {
    id: 1,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'cost_min',
    label: 'Cost',
    prefix: '$',
    suffix: '',
    precision: 2,
    delimiter: ',',
  },
  {
    id: 2,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'price',
    label: 'Price',
    prefix: '$',
    suffix: '',
    precision: 2,
    delimiter: ',',
  },
  {
    id: 3,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'competitors_count',
    label: 'Competitors',
    prefix: '',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
  {
    id: 4,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'saturation',
    label: 'Saturation',
    prefix: '',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
  {
    id: 5,
    span: { xs: 24, sm: 16, xl: 8 },
    name: 'score',
    label: 'Score',
    prefix: '',
    suffix: '/10',
    precision: 0,
    delimiter: '.',
  },
  {
    id: 6,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'p_c_ratio',
    label: 'P/C Ratio',
    prefix: '',
    suffix: 'X',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 7,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'BECPA',
    label: 'BECPA',
    prefix: '$',
    suffix: '',
    precision: 2,
    delimiter: ',',
  },
  {
    id: 8,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'BEROAS',
    label: 'BEROAS',
    prefix: '',
    suffix: '',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 9,
    span: { xs: 12, sm: 8, xl: 4 },
    name: 'profit_margin',
    label: 'Profit Margin',
    prefix: '',
    suffix: '%',
    precision: 2,
    delimiter: '.',
  },
  {
    id: 10,
    span: { xs: 24, sm: 16, xl: 8 },
    name: 'pot_profit',
    label: 'POT. Profit',
    prefix: '≈ $',
    suffix: '',
    precision: 0,
    delimiter: '.',
  },
];

export const OverviewBlock = ({ skeleton, theme, isMobile, product }) => {
  const { t } = useTranslation();

  function checkSaturation(value) {
    if (value) {
      let result = '';
      switch (true) {
        case value > 65:
          result = 'high';
          break;

        case value < 36:
          result = 'low';
          break;

        default:
          result = 'medium';
          break;
      }
      return result;
    }
    return value ?? '-';
  }

  const renderHeader = (
    <>
      <span
        className={cls('title', { skeleton })}
        style={skeleton ? { width: 102 } : {}}
      >
        {skeleton ? null : t('Overview')}
      </span>
      {isMobile ? null : (
        <span
          className={cls('text', { skeleton })}
          style={skeleton ? { width: 159 } : {}}
        >
          {skeleton
            ? null
            : t('product id _productId_', {
                productId: product?.id || '-',
              })}
        </span>
      )}
    </>
  );

  function renderBlockWithBg(type, value) {
    let content = null;

    if (type === 'pot_profit') {
      content = <span className="overview-card-value">{value}</span>;
    } else if (type === 'score') {
      const values = value ? value.split('/') : 0;
      const maxIndicators = values[1] ? +values[1] : 10;
      const array = new Array(maxIndicators).fill(1);

      content = (
        <div className="score-indicator">
          {array.map((el, index) => (
            <div
              key={index}
              className={cls('score-indicator-item', {
                active: index < values[0],
              })}
            />
          ))}
        </div>
      );
    }

    if (content) {
      return <div className="overview-card-block-bg">{content}</div>;
    }
    return null;
  }

  if (skeleton) {
    return (
      <ProductDetailsCard header={renderHeader}>
        <Row
          gutter={[
            { xs: 12, sm: 12, md: 16 },
            { xs: 12, sm: 12, md: 16 },
          ]}
        >
          {listData.map((el) => (
            <Col
              key={el.id}
              {...el.span}
              className={cls('overview-card-wrap', {
                'order-last':
                  isMobile && (el.name === 'score' || el.name === 'pot_profit'),
              })}
            >
              <div
                className={cls('overview-card', el.name, {
                  withBg: el.name === 'score' || el.name === 'pot_profit',
                })}
              >
                <div className="overview-card-info-wrap">
                  <span
                    className="overview-card-label skeleton"
                    style={{ width: `${utils.randomNum(20, 70)}%` }}
                  />
                  <span
                    className="overview-card-value skeleton"
                    style={{ width: `${utils.randomNum(10, 90)}%` }}
                  />
                </div>

                {el.name === 'score' || el.name === 'pot_profit' ? (
                  <div className="overview-card-block-bg skeleton" />
                ) : null}
              </div>
            </Col>
          ))}
        </Row>
      </ProductDetailsCard>
    );
  }
  return (
    <ProductDetailsCard header={renderHeader}>
      <Row
        gutter={[
          { xs: 12, sm: 12, md: 16 },
          { xs: 12, sm: 12, md: 16 },
        ]}
      >
        {listData.map((el) => {
          const value = product[el.name] ?? '';

          const integerValue = product[el.name]
            ? `${el.prefix}${acc.formatNumber(value, el.precision, ',', el.delimiter)}${
                el.suffix
              }`
            : product[el.name] ?? '-';
          return (
            <Col
              key={el.id}
              {...el.span}
              className={cls('overview-card-wrap', {
                'order-last':
                  isMobile && (el.name === 'score' || el.name === 'pot_profit'),
              })}
            >
              <div
                className={cls('overview-card', el.name, {
                  withBg: el.name === 'score' || el.name === 'pot_profit',
                })}
              >
                <div className="overview-card-info-wrap">
                  <span className="overview-card-label">
                    {t(el.label)}
                    {el.name === 'score' ? (
                      <Icon
                        type="attention_outline"
                        role="button"
                        titleText={t('tooltips.score')}
                        tooltipProps={{
                          placement: 'top',
                          trigger: isMobile ? 'click' : 'hover',
                          overlayClassName: cls('details-tooltip', 'score'),
                          getPopupContainer: () =>
                            document.getElementById(
                              'product-details-block-overview-id',
                            ),
                        }}
                      />
                    ) : null}
                  </span>
                  <span className="overview-card-value">
                    {el.name === 'saturation' ? (
                      <>
                        <span className={checkSaturation(value)}>
                          {checkSaturation(value)}
                        </span>
                        <Icon
                          type="ellipse"
                          role="icon"
                          className={cls('saturation-icon', {
                            disabled: !value,
                          })}
                          outline={checkSaturationIndex(value)}
                          theme={theme}
                        />
                      </>
                    ) : el.name === 'cost' ? (
                      value || '-'
                    ) : (
                      integerValue
                    )}
                  </span>
                </div>
                {renderBlockWithBg(el.name, integerValue)}
              </div>
            </Col>
          );
        })}
      </Row>
    </ProductDetailsCard>
  );
};

export default OverviewBlock;
