import React, {useCallback, useEffect, useRef, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import cls from 'classname';
import Creators from './reducer';
import ProductDatabaseCreators from '../ProductDatabasePage/reducer';
import Icon from '../../Icon';
import BannerBlock from './components/BannerBlock';
import BannerLimited from '../../Components/Banners/BannerLimited';
import FiltersBlock from './components/FiltersBlock';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import DatabaseProducts from './components/DatabaseProducts';
import openNotification, { openNotificationWithIcon } from '../../Components/Notification/index';
import { DefaultMsgSaga } from '../../Components/Notification/notification-message';
import ReactPlayer from "react-player";
import {Modal} from "antd";
import './styles.less';

export const CompetitorResearchPage = (
  {
    isAdmin,
    error,
    userInfo,
    isMobile,
    theme,
    productDatabase,
    activeSubscriptions,
    setCompetitor,
    competitor,
    ...props
  }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentScroll = useRef(null);
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);
  //check if filters were changed
  const [isChanged, setIsChanged] = useState(true);
  const [enabled, setEnabled] = useState(true);
  //toggle table/banner
  const [showTable, setShowTable] = useState(false);
  //video modal
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);

  //deleted preset to call undo
  const deleteResult = useSelector(store => store['competitors'].deleteResult);
  const tableData = useSelector(store => store['competitors'].products.results) || [];

  const { fetching, loading, filtersLoading, attemptsLeft, productsLoading } = productDatabase;
  const isVisibleLimitRequest = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  //show notification after delete to undo
  useEffect(() => {
    if (deleteResult) {
      openNotificationWithIcon({
        deleteResult,
        style: { minWidth: '716px' },
        className: 'notification preset-delete-notification',
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                delete preset _name_ notification
              </span>
            }
            icon="remove_preset"
            iconOutline={true}
            undo={
              <span style={{ color: '#225aea', cursor: 'pointer', display: 'block' }}
                    onClick={() => dispatch(Creators.cancelDeleteCompetitorsPresetRequest(deleteResult))}
              >
              {t('Undo Action')}
            </span>}
            withTranslate={true}
            preset={deleteResult.name}
          />
        ),
      });
      setTimeout(() => dispatch(Creators.resetDeleteResult()), 1);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [dispatch, deleteResult]);

  useEffect(() => {
    document.title = `Competitor Research - Dropship`;
    if (firsLoading) setTimeout(() => setFirsLoading(false), 300);
    if (isAdmin) dispatch(Creators.getShopifyCountRequest());
    props.getFilters();
    props.getPresets();
    if(!isAdmin) {
      props.getShopifyStore();
      props.getAutoStore();
    }
    dispatch(Creators.resetCreateError());
    dispatch(Creators.resetDeleteResult());

    return () => {
      if (competitor) setCompetitor(false);
    }

    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if(isMobile) setVisible(false)
  }, [isMobile])

  useEffect(() => {
    if (showTable && !isChanged) setEnabled(false);
  }, [showTable, isChanged]);

  useEffect(() => {
    if(attemptsLeft === 0 && (!firsLoading && !loading && !filtersLoading)){
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error'
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [attemptsLeft]);

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({ order, columnKey }) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const header = () => (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <Icon type={`competitors_dashboard`} role={'icon'} width={27} height={27} />
        </div>
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            {t(`Competitor Research`)}
          </h3>
          <h4 className="sales-tracker-top-page-subtitle">
            {t(`Search and find all your competitors`)}
          </h4>
        </div>
      </div>
      <div className={'sales-tracker-top-page-limits-wrapper'}>
        <div className={'sales-tracker-top-page-watch-tutorial'}
             onClick={() => window.Intercom('startTour',433090)}
             style={{width: 138}}
        >
          <Icon type={'take_tour'} role={'icon'}/>
          <span>{t('Take Tour')}</span>
        </div>

        <div className={'sales-tracker-top-page-watch-tutorial'}
             onClick={() => setVisible(true)}
        >
          <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
          <span>{t('Watch Tutorial')}</span>
        </div>
      </div>
    </div>
  )

  const headerSkeleton = () => (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <span className={'link skeleton'} style={{width: 48, height: 48}}/>
        </div>
        <div className="sales-tracker-top-page-subheader">
          <span className={'link skeleton'} style={{width: 240, height: 32}}/>
          <span className={'link skeleton'} style={{width: 338, height: 24}}/>
        </div>
      </div>
      <div className={'sales-tracker-top-page-limits-wrapper'}>
        <span className={'link skeleton'} style={{width: 138, height: 40}}/>
        <span className={'link skeleton'} style={{width: 169, height: 40}}/>
      </div>
    </div>
  )

  return (
    //custom scrollbars
    <div className={cls("product-database-page competitors-page", {
      'competitors-page-no_scroll': visible
    })}>
      {
        isVisibleLimitRequest || isTrial
          ? (
            <>
              {
                firsLoading || filtersLoading ?
                  headerSkeleton()
                  :
                  header()
              }
            <BannerLimited
              skeleton={firsLoading || filtersLoading}
              btnText={t('Upgrade For Unlimited')}
              onClick={() => navigate('/setting/plan')}
              className={cls({ 'limited-banner_error': attemptsLeft === 0 })}
              text={`${t('Daily Searches Remaining')}: ${attemptsLeft}`}
              isTrial={isTrial}
            />
            </>
            )
          : null
      }

      {
          <>
            {
              !isAdmin && !(isTrial || isVisibleLimitRequest) ?
                firsLoading || filtersLoading ?
                  headerSkeleton()
                  :
                  header()
                :
                null
            }
            <FiltersBlock
              isAdmin={isAdmin}
              productsLoading={productsLoading}
              skeleton={firsLoading || filtersLoading}
              isMobile={isMobile}
              savePreset={props.savePreset}
              deletePreset={props.deletePreset}
              theme={theme}
              fetching={loading}
              updatePreset={props.updatePreset}
              getProducts={props.getProducts}
              setShowTable={setShowTable}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              showTable={showTable}
              pageSize={pageSize}
              setPageSize={handleChangePageSize}
              pageNumber={pageNumber}
              setPageNumber={handleChangePageNumber}
              isChanged={(isTrial || isVisibleLimitRequest) ? (attemptsLeft >= 0 && isChanged) : isChanged}
              disabledSearch={((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0])}
              setIsChanged={setIsChanged}
              isVisibleLimitRequest={(isTrial || isVisibleLimitRequest)}
              setEnabled={setEnabled}
              setVisibleTutorial={setVisible}
              currentScroll={currentScroll}
            />

            {showTable ?
              //condition to toggle table/empty table
              !tableData.length && !fetching && !productsLoading ?
                <div className={cls('product-database-table-wrapper', {
                  'product-database-table-wrapper-empty': !tableData?.length,
                })}>
                  <div className="empty-state empty-state-product-database">
                    <div className="empty-state-product-database-title">
                      {t('common.no_products')}
                    </div>
                    <div className="empty-state-product-database-subtitle">
                      {t('kindly_change_search')}
                    </div>
                    <div className="empty-state_icon">
                      <Icon width={96} height={96}
                            role="icon"
                            type="empty_warning"
                            fill={theme === 'dark' ? '#707ba0' : '#BDC4DB'}
                      />
                    </div>
                  </div>
                </div>
                :
                <DatabaseProducts fetching={fetching || productsLoading}
                                  loading={loading}
                                  onSort={handleSort}
                                  pageSize={pageSize}
                                  setPageSize={handleChangePageSize}
                                  pageNumber={pageNumber}
                                  setPageNumber={handleChangePageNumber}
                                  data={tableData}
                                  sortOrder={sortOrder}
                                  isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                                  enabled={enabled}
                                  isTrial={isTrial}
                                  isMobile={isMobile}
                                  isAdmin={isAdmin}
                                  currentScroll={currentScroll}
                />
              :
              null
            }
          </>
      }

      {!showTable && <BannerBlock
        skeleton={firsLoading || filtersLoading}
        isMobile={isMobile}
        theme={theme}
        setVisible={setVisible}
      />}

      <Modal
        className="change-modal custom-modal video-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? {transitionName: ''} : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
        }
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <ReactPlayer controls={true}
                     width={'100%'}
                     height={'100%'}
                     playing={true}
                     url={'https://www.youtube.com/watch?v=h-rOBAPwptI'}
        />
      </Modal>
    </div>
  );
};

CompetitorResearchPage.propTypes = {
  isAdmin: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  productDatabase: state.competitors,
  competitor: state.productDatabase.competitor
});

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => dispatch(Creators.getCompetitorsFiltersRequest()),
  getPresets: () => dispatch(Creators.getCompetitorsPresetsRequest()),
  savePreset: (data) => dispatch(Creators.createCompetitorsPresetRequest(data)),
  deletePreset: (id) => dispatch(Creators.deleteCompetitorsPresetRequest(id)),
  updatePreset: (data) => dispatch(Creators.updateCompetitorsPresetRequest(data)),
  getProducts: (data) => dispatch(Creators.getCompetitorsRequest(data)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
  setCompetitor: (value) => dispatch(ProductDatabaseCreators.setCompetitorResearch(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorResearchPage);
