import React from 'react';
import {Divider} from "antd";

const SupplierCardSkeleton = ({id}) => {

  let res = id !== 3 ? [1,2,3] : [1];

  return (
    <div className='suppliers-page-card-wrapper'>
      <div className="suppliers-page-card-bio">
        <span className='link skeleton' style={{width: 64, height: 64, borderRadius: '50%', marginRight: 12}} />
        <div className="suppliers-page-card-name-wrapper">
          <h3 className="suppliers-page-card-name" style={{display: 'flex', alignItems: 'center'}}>
            <span className='link skeleton' style={{width: 54, height: 24, marginRight: 4}} />
            <span className='link skeleton' style={{width: 16, height: 16}} />
          </h3>
          <p className="suppliers-page-card-job">
            <span className='link skeleton' style={{width: 255, height: 20}} />
          </p>
        </div>
      </div>

      <Divider type={'horizontal'} className={'suppliers-page-card-divider'} />

      <p className="suppliers-page-card-description">
        <span className='link skeleton' style={{width: '100%', height: 80}} />
      </p>
      <span className='link skeleton' style={{width: '100%', height: 40, margin: "36px auto 32px"}} />

      <div className="suppliers-page-card-info-wrapper">
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>

          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>

          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>

          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>

          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>
        </div>
        <div className="suppliers-page-card-info-line">
          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>

          <div className="suppliers-page-card-info">
            <span className='link skeleton' style={{width: 160, height: 18}} />
            <span className='link skeleton' style={{width: 97, height: 18, marginTop: 4}} />
          </div>
        </div>

      </div>

      <Divider type={'horizontal'} className={'suppliers-page-card-divider'} />

      <div className="suppliers-page-card-country-wrapper">
        <p className="suppliers-page-card-info-title">
          <span className='link skeleton' style={{width: 74, height: 16}} />
        </p>
        <div className="suppliers-page-card-country-info-wrapper">
          {
            res.map(el => (
              <div className="suppliers-page-card-country-info" key={el}>
                <div className="suppliers-page-card-country">
                  <span className='link skeleton' style={{width: 22, height: 18}} />
                  <span className='link skeleton' style={{width: 20, height: 16, marginLeft: 4}} />
                </div>
                <p className="suppliers-page-card-city">
                  <span className='link skeleton' style={{width: 150, height: 14, marginTop: 4}} />
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default SupplierCardSkeleton;
