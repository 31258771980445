import React from 'react';
import cls from 'classname';

export const ShopifyBtnIcon = ({width, height, className, theme, isLogo = false}) =>
  isLogo ?
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M19.1412 5.14575C19.1263 5.03702 19.0313 4.9767 18.9524 4.97014C18.8742 4.96357 17.3415 4.94028 17.3415 4.94028C17.3415 4.94028 16.0596 3.69547 15.933 3.56884C15.8064 3.44221 15.5591 3.48043 15.4629 3.50911C15.4618 3.5097 15.2222 3.58377 14.819 3.70861C14.7515 3.48999 14.6524 3.2212 14.5108 2.95121C14.0545 2.08033 13.3861 1.6198 12.5785 1.6186C12.5773 1.6186 12.5767 1.6186 12.5755 1.6186C12.5194 1.6186 12.4638 1.62398 12.4077 1.62876C12.3838 1.60008 12.3599 1.57201 12.3348 1.54453C11.983 1.16823 11.532 0.984851 10.9914 1.00098C9.94852 1.03084 8.90981 1.78406 8.06759 3.12205C7.47505 4.06341 7.02407 5.24612 6.89625 6.16179C5.69863 6.53271 4.86119 6.79193 4.84267 6.79793C4.23819 6.98786 4.21908 7.00637 4.14023 7.57624C4.08169 8.00687 2.5 20.2375 2.5 20.2375L15.755 22.53L21.5 21.1018C21.5 21.1018 19.1561 5.25448 19.1412 5.14575ZM14.1554 3.91408C13.8502 4.00846 13.5031 4.11598 13.1268 4.23246C13.1191 3.70443 13.0563 2.96973 12.8103 2.33478C13.6017 2.48471 13.9911 3.38009 14.1554 3.91408ZM12.4333 4.44749C11.7387 4.66251 10.9807 4.89726 10.2203 5.13262C10.4341 4.31369 10.8397 3.49835 11.3379 2.96376C11.5231 2.76485 11.7823 2.54325 12.0893 2.41661C12.3778 3.01871 12.4405 3.87108 12.4333 4.44749ZM11.0123 1.69506C11.2572 1.68968 11.4633 1.74344 11.6395 1.85932C11.3576 2.00566 11.0852 2.21592 10.8296 2.49009C10.1671 3.20089 9.65941 4.30413 9.45692 5.36855C8.82556 5.56386 8.20793 5.75562 7.63929 5.93124C7.9983 4.25575 9.40256 1.74165 11.0123 1.69506Z"
        fill="#95BF47"/>
      <path
        d="M18.9523 4.97141C18.8741 4.96485 17.3414 4.94156 17.3414 4.94156C17.3414 4.94156 16.0595 3.69674 15.9329 3.57011C15.8857 3.52292 15.8218 3.49843 15.7549 3.48828L15.7555 22.5301L21.4999 21.1025C21.4999 21.1025 19.156 5.25575 19.1411 5.14703C19.1261 5.0383 19.0306 4.97798 18.9523 4.97141Z"
        fill="#5E8E3E"/>
      <path
        d="M12.5703 7.92534L11.9031 10.4215C11.9031 10.4215 11.1588 10.0828 10.2766 10.1384C8.9828 10.2202 8.96905 11.0361 8.98222 11.241C9.0527 12.3574 11.9897 12.6011 12.1546 15.2162C12.2842 17.2733 11.0632 18.6806 9.30417 18.7917C7.19265 18.9249 6.03027 17.6795 6.03027 17.6795L6.47767 15.7764C6.47767 15.7764 7.64779 16.6593 8.58438 16.6001C9.19605 16.5613 9.41465 16.0638 9.39255 15.7119C9.30058 14.2557 6.90892 14.3417 6.75781 11.9488C6.63057 9.93531 7.95305 7.89487 10.8709 7.7109C11.9951 7.63863 12.5703 7.92534 12.5703 7.92534Z"
        fill="white"/>
    </svg>
    :
    <svg width={width ? width : '24'}
         height={height ? height : '24'}
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         className={cls('icon icon-shopify', className)}
    >
      <path
        d="M17.9 5.9C17.9 5.8 17.8 5.7 17.7 5.7C17.6 5.7 16.3 5.7 16.3 5.7C16.3 5.7 15.1 4.5 15 4.4C14.9 4.3 14.7 4.3 14.6 4.3C14.6 4.3 14.4 4.4 14 4.5C13.9 4.3 13.9 4 13.7 3.8C13.3 3 12.7 2.6 12 2.6C11.9 2.6 11.9 2.6 11.8 2.6C11.8 2.6 11.8 2.5 11.7 2.5C11.5 2.2 11.1 2 10.6 2C9.7 2 8.7 2.7 8 4C7.5 4.9 7.1 6 7 6.8C5.9 7.1 5.1 7.4 5.1 7.4C4.6 7.6 4.5 7.6 4.5 8.1C4.4 8.5 3 19.9 3 19.9L14.9 22L20 20.7C20 20.7 17.9 6 17.9 5.9ZM13.4 4.7C13.2 4.8 12.8 4.9 12.5 5C12.5 4.5 12.4 3.8 12.2 3.2C12.9 3.4 13.3 4.2 13.4 4.7ZM11.9 5.2C11.3 5.4 10.6 5.6 9.9 5.8C10.1 5 10.5 4.3 10.9 3.8C11.1 3.6 11.3 3.4 11.6 3.3C11.8 3.9 11.9 4.7 11.9 5.2ZM10.6 2.6C10.8 2.6 11 2.6 11.2 2.8C10.9 2.9 10.7 3.1 10.5 3.4C9.9 4 9.4 5.1 9.2 6.1C8.7 6.2 8.1 6.4 7.6 6.6C7.9 5 9.2 2.7 10.6 2.6Z"
        fill={theme === 'dark' ? '#E2E6F3' : '#F2F6FF'}/>
      <path
        d="M17.3004 5.70005C17.2004 5.70005 16.2004 5.70005 16.2004 5.70005C16.2004 5.70005 15.8004 5.10005 15.6004 5.00005C15.6004 5.00005 15.0004 4.30005 14.9004 4.30005V22L20.0004 20.7001C20.0004 20.7001 17.9004 5.90005 17.9004 5.80005C17.8004 5.60005 17.4004 5.70005 17.3004 5.70005Z"
        fill={theme === 'dark' ? '#F2F6FF' : '#FFFFFF'}/>
      <path
        d="M12 8.1999L11.4 10.4999C11.4 10.4999 10.7 10.1999 9.9 10.1999C8.7 10.2999 8.7 10.9999 8.7 11.1999C8.8 12.1999 11.5 12.3999 11.6 14.7999C11.7 16.6999 10.6 17.8999 9 17.9999C7.1 18.0999 6 16.9999 6 16.9999L6.4 15.2999C6.4 15.2999 7.5 16.0999 8.3 15.9999C8.9 15.9999 9.1 15.4999 9 15.1999C9 13.8999 6.8 13.9999 6.7 11.7999C6.6 9.9999 7.8 8.1999 10.4 7.9999C11.5 7.8999 12 8.1999 12 8.1999Z"
        fill={theme === 'dark' ? '#225AEA' : '#225AEA'}/>
    </svg>
;

export default ShopifyBtnIcon;
