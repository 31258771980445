import React, {useEffect} from 'react';
import cls from "classname";
import Images from "../../Images";
import ButtonComponent from "../../Components/Button";
import Modal from "../../Components/Modal";
import Creators from "./reducer";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Spin} from "antd";

const text = 'Click button below, and you will be redirected to AutoDS to connect your account to Dropship.';

const ModalAutoDsConnect = ({isVisibleModal, theme, changeVisibleModalAutoDS, isMobile, autoDS, recheckAutoStores}) => {

  const {t} = useTranslation();

  const closeModalAutoDS = () => changeVisibleModalAutoDS({isVisibleModal: null, importProductId: null})

  const handleClose = () => closeModalAutoDS();

  useEffect(() => {
    if (isVisibleModal === 'connect' && !autoDS?.email) {
      recheckAutoStores()
    }
  }, [isVisibleModal])

  return (
    <Modal
      className={cls('shopify-connect autods-connect')}
      title={'Connect Store'}
      open={isVisibleModal === 'connect'}
      width={592}
      handleOk={null}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
        <Spin spinning={autoDS?.loading}>
          <img src={Images[theme === 'dark' ? 'autoConnectDark' : 'autoConnect']}
               alt=""
          />
          <p>{t(text)}</p>

          <ButtonComponent
            type="primary"
            className={cls('btn-store')}
            disabled={false}
            onClick={() => {
              closeModalAutoDS();
              setTimeout(() => window.open(
                `https://www.autods.com/integrate/?response_type=code&ref=Mjc0NzQ5&client_id=72buojhlk54m0aj1i9hn1071qc&redirect_uri=${process.env.REACT_APP_BASE_URI}/dashboard`,
                '_blank'
              ), 300);
            }}
            style={{width: '100%', height: 48}}
          >
            {t('Connect')}
          </ButtonComponent>
        </Spin>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  autoDS: state.autoDS,
  isVisibleModal: state.autoDS.isVisibleModal,
  theme: state?.nav?.theme,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  recheckAutoStores: () => dispatch(Creators.recheckAutoStoresRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDsConnect);
