import React from 'react';
import cls from 'classname';
import { Carousel } from 'antd';
import './ExampleOfStores.less';

const ExampleOfStores = ({ isMobile }) => {
  return (
    <div className={'example-of-stores-wrapper'}>

      <div className="example-of-stores-content">
        <h4>
          Example of stores
        </h4>
        {
          isMobile ?
            <div className="example-of-stores-cards">
              <Carousel autoplay={true}>
                {
                  [0, 1, 2, 3, 4, 5].map(el => (
                    <div className={cls(`example-of-stores-card example-of-stores-card--${el}`)}
                         key={el}
                    />
                  ))
                }
              </Carousel>
            </div>
            :
            <div className="example-of-stores-cards">
              {
                [0, 1, 2, 3, 4, 5].map(el => (
                  <div className={cls(`example-of-stores-card example-of-stores-card--${el}`)}
                       key={el}
                  />
                ))
              }
            </div>
        }
      </div>

    </div>
  );
};

export default ExampleOfStores;
