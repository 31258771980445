import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../Icon';
import './styles.less';

export const EditableTextComponent = ({
  text,
  onResend,
  collection,
  setChange,
  isChange,
}) => {
  const [t, setText] = useState(text);

  return (
    <div className="editable-text">
      {!isChange ? (
        <div
          className="editable-text_text"
          onClick={() => onResend(collection)}
        >
          {t}
        </div>
      ) : (
        <>
          <input
            type="text"
            value={t}
            onChange={(e) => setText(e.target.value)}
            className="editable-text_input "
          />
          <span
            className="editable-text_icon editable-text_icon--success"
            onClick={() => {
              setText(t);
              setChange({ result: true, value: t });
            }}
          >
            <Icon role="icon" type="check_mark" />
          </span>
          <span
            className="editable-text_icon editable-text_icon--cancel"
            onClick={() => {
              setText(text);
              setChange({ result: false });
            }}
          >
            <Icon role="icon" type="close" width={24} height={24} />
          </span>
        </>
      )}
    </div>
  );
};

EditableTextComponent.defaultProps = {
  isChange: false,
};

EditableTextComponent.propTypes = {
  text: PropTypes.string.isRequired,
  onResend: PropTypes.func,
  setChange: PropTypes.func,
  isChange: PropTypes.bool,
  collection: PropTypes.shape({
    collectionID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    collectionName: PropTypes.string,
  }),
};

export default EditableTextComponent;
