import React from 'react';

const AdSpotPhoto = (
  {
    color='#151E3A'
  }
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M3.70423 17.3127C3.63146 16.9958 3.58689 16.6627 3.55797 16.3086C3.52832 15.9458 3.51388 15.5393 3.50684 15.0833L5.84998 13.6345L5.92863 13.5857C6.25832 13.381 6.58991 13.1751 6.96348 13.079C7.28957 12.9951 7.62925 12.9779 7.96213 13.0286C8.34347 13.0866 8.69411 13.258 9.04274 13.4285L9.1259 13.4691L9.65426 13.7262C10.1836 13.9838 10.2468 14.001 10.289 14.0065C10.3602 14.0159 10.4325 14.0099 10.5012 13.9888C10.5418 13.9763 10.6012 13.9489 11.0804 13.6068L13.586 11.8177L13.6791 11.751C14.0634 11.4754 14.449 11.1988 14.894 11.08C15.2815 10.9766 15.6883 10.9679 16.0799 11.0548C16.5295 11.1545 16.9265 11.4145 17.3222 11.6736L17.418 11.7363L21.4993 14.3959C21.4968 15.1414 21.4861 15.7727 21.4423 16.3086C21.4309 16.4484 21.4171 16.5849 21.4 16.7184L16.3261 13.4119C15.7635 13.0452 15.6928 13.0175 15.6468 13.0073C15.5685 12.99 15.4872 12.9917 15.4096 13.0124C15.3642 13.0245 15.2947 13.0552 14.7482 13.4454L12.2426 15.2344L12.1613 15.2926C11.8226 15.5355 11.4822 15.7796 11.0887 15.9005C10.7455 16.006 10.3836 16.0363 10.0277 15.9894C9.61958 15.9356 9.24325 15.7515 8.86883 15.5684L8.77907 15.5246L8.25072 15.2674C7.75911 15.0282 7.70109 15.0119 7.66146 15.0058C7.59488 14.9957 7.52695 14.9991 7.46173 15.0159C7.42291 15.0259 7.36682 15.048 6.90181 15.3355L3.70423 17.3127Z"
        fill={color}/>
      <path
        d="M11.5001 9C11.5001 10.1046 10.6047 11 9.50015 11C8.39558 11 7.50015 10.1046 7.50015 9C7.50015 7.89543 8.39558 7 9.50015 7C10.6047 7 11.5001 7.89543 11.5001 9Z"
        fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.8572 3H14.1428C15.2266 2.99999 16.1007 2.99998 16.8086 3.05782C17.5375 3.11737 18.1777 3.24318 18.77 3.54497C19.7108 4.02433 20.4757 4.78924 20.955 5.73005C21.2568 6.32234 21.3826 6.96253 21.4422 7.69138C21.5 8.39925 21.5 9.27339 21.5 10.3572V13.6428C21.5 14.7266 21.5 15.6008 21.4422 16.3086C21.3826 17.0375 21.2568 17.6777 20.955 18.27C20.4757 19.2108 19.7108 19.9757 18.77 20.455C18.1777 20.7568 17.5375 20.8826 16.8086 20.9422C16.1008 21 15.2266 21 14.1428 21H10.8572C9.77339 21 8.89925 21 8.19138 20.9422C7.46253 20.8826 6.82234 20.7568 6.23005 20.455C5.28924 19.9757 4.52433 19.2108 4.04497 18.27C3.74318 17.6777 3.61737 17.0375 3.55782 16.3086C3.49998 15.6007 3.49999 14.7266 3.5 13.6428V10.3572C3.49999 9.27341 3.49998 8.39926 3.55782 7.69138C3.61737 6.96253 3.74318 6.32234 4.04497 5.73005C4.52433 4.78924 5.28924 4.02433 6.23005 3.54497C6.82234 3.24318 7.46253 3.11737 8.19138 3.05782C8.89926 2.99998 9.77341 2.99999 10.8572 3ZM8.35424 5.05118C7.74907 5.10062 7.40138 5.19279 7.13803 5.32698C6.57354 5.6146 6.1146 6.07354 5.82698 6.63803C5.69279 6.90138 5.60062 7.24907 5.55118 7.85424C5.50078 8.47108 5.5 9.26339 5.5 10.4V13.6C5.5 14.7366 5.50078 15.5289 5.55118 16.1458C5.60062 16.7509 5.69279 17.0986 5.82698 17.362C6.1146 17.9265 6.57354 18.3854 7.13803 18.673C7.40138 18.8072 7.74907 18.8994 8.35424 18.9488C8.97108 18.9992 9.76339 19 10.9 19H14.1C15.2366 19 16.0289 18.9992 16.6458 18.9488C17.2509 18.8994 17.5986 18.8072 17.862 18.673C18.4265 18.3854 18.8854 17.9265 19.173 17.362C19.3072 17.0986 19.3994 16.7509 19.4488 16.1458C19.4992 15.5289 19.5 14.7366 19.5 13.6V10.4C19.5 9.26339 19.4992 8.47108 19.4488 7.85424C19.3994 7.24907 19.3072 6.90138 19.173 6.63803C18.8854 6.07354 18.4265 5.6146 17.862 5.32698C17.5986 5.19279 17.2509 5.10062 16.6458 5.05118C16.0289 5.00078 15.2366 5 14.1 5H10.9C9.76339 5 8.97108 5.00078 8.35424 5.05118Z"
            fill={color}/>
    </svg>
  );
};

export default AdSpotPhoto;
