import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../Icon';
import './styles-empty.less';

export const EmptyState = ({ isAdmin }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-state empty-state-product">
      <div className="empty-state_icon">
        <Icon width={124} height={124} role="icon" type="empty_warning" />
      </div>
      <div className="empty-state_wrapper">
        <div
          className={
            !isAdmin ? 'empty-state_header' : 'empty-state_header admin'
          }
        >
          {!isAdmin
            ? t('common.no_product_image')
            : t('no_products_available_this_week_yet_try_again_later')}
        </div>
        {!isAdmin && (
          <div className={'empty-state_text'}>
            Go to
            <Link
              className="empty-state_link blue-link"
              style={{color: '#225aea'}}
              to="/portfolio"
            > Portfolio </Link>
            and save products you are interested in.
          </div>
        )}
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  isAdmin: PropTypes.bool,
};

export default EmptyState;
