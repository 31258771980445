import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router";
import cls from 'classname';
import actions from './actions';
import { navIconsArr, navIconsAdmin, NavLinkWithIcon } from './navigation';
import ButtonComponent from "../../Components/Button";
import { sendExtensionMessage } from '../../Utils/extension';
import Icon from "../../Icon";
import './styles.less';

export const SidebarComponent = (
  {
    nav,
    isAdmin,
    changeSizeSidebar,
    setAdSpotFilters,
    setAdSpotQuickPreset,
    setAdSpotSort,
    setAdSpotDefaultSearch,
    openSidebar,
    ...props }) => {
  const { isShowMenu, isShowSideBar, theme, isMobile } = nav;
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState([]);
  const [showExtensionBtn, setShowExtensionBtn] = useState(false);
  const subscription = props.userInfo?.subscriptions?.[0];

  const isFreePlan = subscription?.payment_status?.id === 6;

  const isActive = (path) => path.includes('sales-tracker') ?
      !location.pathname.includes(path.split('/')[1])
      :
      path.includes('portfolio') ?
        !(location.pathname.includes('collections') || location.pathname.includes('portfolio'))
        :
        !location.pathname.includes(path);

  const menuIcons = isAdmin
    ? navIconsAdmin
    : props?.userInfo?.need_block_pages ?
      navIconsArr['demo']
      : navIconsArr['desktop'];

  useEffect(() => {
    let page = document.querySelector('.main-content');
    page?.scrollTo({top: 0});
    if (location?.pathname.includes('sales-tracker')) setActiveKey(['salesTracker'])
    else if (location?.pathname.includes('collections') || location?.pathname.includes('portfolio')) setActiveKey(['portfolio'])
    else setActiveKey([]);
    if (!location?.pathname.includes('ad-spot')) {
      setAdSpotFilters({});
      setAdSpotQuickPreset({});
      setAdSpotSort('most_recent');
      setAdSpotDefaultSearch(true);
    }
    sendExtensionMessage(
      { ping: true },
      (response) => {
        if(response){
          setShowExtensionBtn(false)
        }else{
          setShowExtensionBtn(true)
        }
      })
  }, [location])

  return (
    <>
      <aside
        className={cls('main-sidebar', {
          'closed-sidebar': !isShowMenu,
          'opened-sidebar': isShowMenu,
          'big-sidebar': !isShowSideBar && !isMobile,
          'hidden': location.pathname.includes('shopify/app')
        })}
      >
        <div className="main-sidebar_wrap">

          {props.children}

          {
            isFreePlan ?
              <ButtonComponent className={'main-sidebar-free-button'}
                               text={t('Upgrade')}
                               onClick={() => {
                                 if (isMobile && isShowMenu) openSidebar(false);
                                 navigate('/setting/plan')
                               }}
                               />
              :
              null
          }

          <Divider className="main-sidebar_divider" />

          {menuIcons.map(({ url, iconDesk, text, comingSoon, markText, withDivider, markTextBordered, dropdown, options, flag }) => {
            const navIcon = (
              <NavLinkWithIcon
                key={url}
                url={url}
                theme={theme}
                text={t(text)}
                active={isActive(url)}
                iconType={iconDesk}
                isOpened={isShowSideBar}
                isSoon={comingSoon}
                markText={markText ? t(markText) : null}
                markTextBordered={markTextBordered}
                openSidebar={() => openSidebar(!isShowMenu)}
                location={location.pathname}
                isDropdown={dropdown}
                options={options}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                flag={flag}
                t={t}
              />
            );
            const divider = <Divider className="main-sidebar_divider" style={{margin: '8px 0'}}/>;
            return withDivider ? (
              <Fragment key={url}>
                {navIcon} {divider}
              </Fragment>
            ) : (
              navIcon
            );
          })}
        </div>
        <div className="main-sidebar_bottom">
          {
            !isAdmin
              ? (
                <NavLinkWithIcon
                  url="/setting/account"
                  theme={theme}
                  text={t('sidebar.settings')}
                  active={isActive('setting')}
                  iconType="settings"
                  isOpened={isShowSideBar}
                  openSidebar={() => openSidebar(!isShowMenu)}
                  location={location.pathname}
                  setActiveKey={setActiveKey}
                  activeKey={activeKey}
                />)
              : null
          }

          {
            (isMobile || !showExtensionBtn || isAdmin) ?
              null
              :
              <div className={'chrome-extension-link-wrapper'}
                   onClick={() => window.open(`https://chrome.google.com/webstore/detail/dropship-product-research/${process.env.REACT_APP_EXTENSION_ID}`, '_blank')}
              >
                <div className="chrome-extension-link-icon">
                  <Icon role="icon" type="chrome_icon" />
                </div>
                <div className="chrome-extension-link-title-wrapper">
                  <div className="chrome-extension-link-title">
                    Chrome Extension
                  </div>
                  <div className="chrome-extension-link-text">
                    Install For Free
                  </div>
                </div>
                <Icon role="icon" type="arrow_breadcrumb" color={'#225AEA'} width={24} height={24} />
              </div>
          }
        </div>
      </aside>
    </>
  );
};

SidebarComponent.propTypes = {
  nav: PropTypes.shape({
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isMobile: PropTypes.bool,
    theme: PropTypes.string,
  }),
  changeSizeSidebar: PropTypes.func,
};

const mapStateToProps = (state) => ({
  nav: state.nav,
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  changeSizeSidebar(value) {
    dispatch(actions.changeSizeSidebar('isShowSideBar', value));
  },
  setAdSpotFilters(data) {
    dispatch(actions.setAdSpotFilters(data))
  },
  setAdSpotQuickPreset(data) {
    dispatch(actions.setAdSpotQuickPreset(data))
  },
  setAdSpotSort(data) {
    dispatch(actions.setAdSpotSort(data))
  },
  setAdSpotDefaultSearch(data) {
    dispatch(actions.setAdSpotDefaultSearch(data))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
