import React, { useState, useEffect } from 'react';
import {Spin, Statistic} from 'antd';
import { useTranslation } from 'react-i18next';
import Images from '../../Images';

export default function CheckEmail({ data, changeEmails, email='',forgotPasswordModal,handleSendCodePassword,verify,verifyEmail }) {
  const { Countdown } = Statistic;
  const [resendFetching, setResendFetching] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const { t } = useTranslation();
  
  function handleResend() {
    if(verify){
      verifyEmail({email})
    }
    if(!forgotPasswordModal){
      !verify && changeEmails({ password: data?.current || '', new_email: data?.email || email });
      handleResetTime();
    }else{
      handleSendCodePassword(data?.email)
      handleResetTime();
    }
  }
  useEffect(() => {
    handleResetTime();
  }, []);

  function handleResetTime() {
    setDeadline(Date.now() + 1000 * 30);
    setResendFetching(true);
  }
  function onFinish() {
    setResendFetching(false);
    setDeadline(null);
  }

  const resendBlock = (
    <div
      className="sign-up-link check-mail"
      style={{ justifyContent: 'space-between' }}
    >
      {resendFetching ? (
        <div className="resend-timer">
          <Countdown
            title=""
            value={deadline}
            onFinish={onFinish}
            format="ss"
            prefix={<span>{t('Resend In')} </span>}
            suffix={<span> {t('Sec...')}</span>}
          />
          <Spin />
        </div>
      ) : (
        <span className='text-link' onClick={handleResend}>
          {t('Resend')}
        </span>
      )}
    </div>
  );
  return (
    <div className="confirm-email">
      <div className="change-password-subtitle">
          Check the email letter sent to{' '}
          <span className="blue-text">{verify || forgotPasswordModal ? email : data?.email ? data?.email : email}</span>.
          {
            !data?.email || verify ?
              null
              :
              'New email address won’t take effect until it is confirmed.'
          }
      </div>
      <img src={Images.verifyEmailMobile} alt="" />
      <div className="change-password-subtitle dont-see-confirm">
        {t('Don’t see a confirmation email?')} {resendBlock}
      </div>
    </div>
  );
}