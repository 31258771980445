import React, {useEffect, useRef, useState} from 'react';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import Icon from "../../../Icon";
import dayjs from "dayjs";
import ButtonComponent from "../../../Components/Button";
import {Carousel, Divider, Tooltip} from "antd";
import ArrowSelectDown from "../../../Icon/img/ArrowSelectDown";
import ReactPlayer from "react-player";
import {useNavigate} from "react-router";
import cls from "classname";
import {useTranslation} from "react-i18next";
import './AdItem.less';

const PostWrapper = (
  {
    data,
    isInnerPage,
    disabledNavigate,
    children
  }) => {

  const [href, setHref] = useState('');

  useEffect(() => {
    if (!isInnerPage) {
      let result = `/ad-spot/${data?.advertiser?.id}/overview/?ad_id=${data?.id}&domain=${data?.domain_fld}`;
      if (data?.internal_shop_id) result +=`&internal_shop_id=${data?.internal_shop_id}`
      setHref(result)
    }
  }, [isInnerPage])

  return (
    isInnerPage || disabledNavigate ?
      <div className={'fadspot-page-item-wrapper'}>
        {children}
      </div>
      :
      <a className={'fadspot-page-item-wrapper'}
         style={{cursor: 'default'}}
         href={href}
         onClick={e => e.preventDefault()}
      >
        {children}
      </a>
  )
}

const AdItem = (
  {
    data={},
    isInnerPage=false,
    isAlive=true,
    disabledNavigate=false,
    withCreated=false
  }) => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const carouselRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [visibleTooltipAction, setVisibleTooltipAction] = useState(false);
  const [visibleTooltipHeadline, setVisibleTooltipHeadline] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }

  const mouseOverAction = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisibleTooltipAction(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeaveAction = e => {
    let target = e.target;
    setVisibleTooltipAction(false);
    target.classList.remove('overflowing')
  }

  const mouseOverHeadline = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    setVisibleTooltipHeadline(true);
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeaveHeadline = e => {
    let target = e.target;
    setVisibleTooltipHeadline(false);
    target.classList.remove('overflowing')
  }

  const emojiHandler = () => {
    let array = [];
    for (let key in data?.reactions_count) {
      array.push({key: key, value: data?.reactions_count?.[key]})
    }
    return array.filter(el => el?.value !== 0)?.length ?
      array.filter(el => el?.value !== 0).map((el, index) => (
      <div key={el?.key}
           className={'fadspot-page-item-emoji-icon fadspot-page-item-emoji-icon-value'}
           style={{zIndex: 20 - index, position: 'relative'}}
           data-value={el?.value}
      >
        <Icon type={`ad_spot_${el?.key}`} role="icon" id={data?.id}/>
      </div>
    ))
      :
      <div key={'like'}
           className={'fadspot-page-item-emoji-icon'}
           style={{zIndex: 20}}
      >
        <Icon type={`ad_spot_like`} role={'icon'} />
      </div>
  }

  const countDuration = (start, end) => {
    let result = dayjs(start).startOf('day').diff(dayjs(end).startOf('day'), 'days')
    return  result === 1 ?
      `${result} ${t('day')}`
      :
      result < 1 ?
        `< 1 ${t('day')}`
          :
        `${result} ${t('days')}`
  }

  return (
    <PostWrapper data={data}
                 isInnerPage={isInnerPage}
                 disabledNavigate={disabledNavigate}
    >
      <div className="fadspot-page-item-header-wrapper">
        <div className="fadspot-page-item-header-title-wrapper"
             style={{position: 'relative'}}
        >
          <div className={cls('fadspot-page-item-header-logo-wrapper', {
            'inner': isInnerPage
          })}>
            <div className="fadspot-page-item-header-logo">
              {
                data?.advertiser?.icon ?
                  <img src={utils.changeImageSize(data?.advertiser?.icon, 496)}
                       alt=""
                       onError={utils.addDefaultSrc}
                  />
                  : <Image src={Images.defaultImg} small={false}/>
              }
              <div className={cls('header-logo-status', {
                'active': isAlive
              })}
              />
              {
                isAlive ?
                  <div className={'header-logo-status-tooltip'}>
                    {
                      data?.created ?
                        <p>
                          {t('Created')}: <span> {dayjs(data?.created).format('MMM DD, YYYY')}</span>
                        </p>
                        :
                        <p>
                          {t('Created')}: <span>No Data</span>
                        </p>
                    }
                    <p>
                      {t('Active')}: <span> {dayjs(data?.last_active ? data?.last_active : data?.last_seen_date).format('MMM DD, YYYY')}</span>
                    </p>
                    <p>
                      {t('Running Time')}: <span> {countDuration(data?.last_active ? data?.last_active : data?.last_seen_date, data?.created ? data?.created : data?.first_seen_date)}</span>
                    </p>
                  </div>
                  :
                  <div className={'header-logo-status-tooltip'}>
                    {
                      data?.created ?
                        <p>
                          {t('Created')}: <span> {dayjs(data?.created).format('MMM DD, YYYY')}</span>
                        </p>
                        :
                        <p>
                          {t('Created')}: <span>No Data</span>
                        </p>
                    }
                    <p>
                      {t('Inactive')}: <span> {dayjs(data?.last_active ? data?.last_active : data?.last_seen_date).format('MMM DD, YYYY')}</span>
                    </p>
                    <p>
                      {t('Ran for')}: <span> {countDuration(data?.last_active ? data?.last_active : data?.last_seen_date, data?.created ? data?.created : data?.first_seen_date)}</span>
                    </p>
                  </div>
              }
            </div>
            <div className={'fadspot-page-item-header-title-info-wrapper'}>
              <div className={cls("fadspot-page-item-header-title", {
                'inner': isInnerPage
              })}
                   onClick={() => {
                     if (!isInnerPage) {
                       let result = `/ad-spot/${data?.advertiser?.id}/overview/?ad_id=${data?.id}&domain=${data?.domain_fld}`;
                       if (data?.internal_shop_id) result +=`&internal_shop_id=${data?.internal_shop_id}`
                       !disabledNavigate && navigate(result);
                     }
                   }}
              >
                <span data-text={data?.advertiser?.name}
                      onMouseOver={mouseOver}
                      onMouseLeave={mouseLeave}
                >
                  {data?.advertiser?.name}
                </span>
                {
                  data?.advertiser?.is_verified ?
                    <Icon role={'icon'} type={'ad_spot_checkmark'}/>
                    :
                    null
                }
              </div>
              <div className="fadspot-page-item-header-title-info">
                <span>
                  {
                    withCreated ?
                      <span>
                        {t('Created')}
                        {': '}
                      </span>
                      :
                      null
                  }
                  {
                    data?.created ?
                      <span>{dayjs(data?.created).format('MMM DD, YYYY')}</span>
                      :
                      <span>No Data</span>
                  }
                </span>
                {
                  isInnerPage ?
                    null
                    :
                    <div className="fadspot-page-item-header-title-info-circle-wrapper">
                      <div className="fadspot-page-item-header-title-info-circle" />
                    </div>
                }
                {
                  isInnerPage ?
                    null
                    :
                    <span className={'quantity'}
                          onMouseOver={mouseOver}
                          onMouseLeave={mouseLeave}
                          data-text={`${data?.advertiser?.total_ads || 0} ${t(data?.advertiser?.total_ads === 1 ? 'ad' : 'ads')}`}
                    >
                      {data?.advertiser?.total_ads || 0} {t(data?.advertiser?.total_ads === 1 ? 'ad' : 'ads')}
                    </span>
                }
              </div>
            </div>
          </div>
          <div className="fadspot-page-item-header-title-actions">
            <ButtonComponent className={'fadspot-page-item-header-title-action'}
                             text={''}
                             onClick={() => window.open(data?.post_url, '_blank')}
            >
              <Icon role={'icon'} type={'search_facebook'} isDark={false}/>
            </ButtonComponent>
            <Divider type={'vertical'} style={{margin: '0 12px', height: 24}}/>
            <ButtonComponent className={'fadspot-page-item-header-title-action'}
                             text={''}
                             onClick={() => {
                               if (Object.keys(data?.publications || {})?.length > 1) window.open(data?.publications?.[0]?.call_to_action_url, '_blank');
                               else window.open(data?.call_to_action_url, '_blank');
                             }}
            >
              <Icon role={'icon'} type={'ad_spot_redirect'}/>
            </ButtonComponent>
            {
              isInnerPage ?
                null
                :
                <Divider type={'vertical'} style={{margin: '0 12px', height: 24}}/>
            }
            {
              isInnerPage ?
                null
                :
                <span className={cls('fadspot-page-item-header-title-more',{
                  'disabled': disabledNavigate
                })}
                      onClick={() => {
                        let result = `/ad-spot/${data?.advertiser?.id}/overview/?ad_id=${data?.id}&domain=${data?.domain_fld}`;
                        if (data?.internal_shop_id) result +=`&internal_shop_id=${data?.internal_shop_id}`
                        let min = dayjs().diff(dayjs(data?.created), 'days') < 30 ?
                          dayjs(data?.created).format('YYYY-MM-DD')
                          :
                          dayjs().subtract(30, 'days').format('YYYY-MM-DD');
                        let max = dayjs().format('YYYY-MM-DD')
                        !disabledNavigate && navigate(result);
                      }}
                >
                  {t('View More')}
                  <ArrowSelectDown/>
                </span>
            }
          </div>
        </div>
        <p className="fadspot-page-item-header-description"
           dangerouslySetInnerHTML={{__html: data?.ad_text || ''}}
        />
      </div>

      {
        Object.keys(data?.publications || {})?.length > 1 ?
          <div className="fadspot-page-item-action-wrapper">
            {
              currentSlide === 0 ?
                null
                :
                <div className="fadspot-page-item-image-prev"
                     style={{display: visibleTooltipAction || visibleTooltipHeadline ? 'none' : 'flex'}}
                     onClick={() => {
                       carouselRef.current.prev();
                       setPlaying(false);
                     }}
                >
                  <ArrowSelectDown />
                </div>
            }
            {
              currentSlide === [...Object.values(data?.publications)]?.length - 1 ?
                null
                :
                <div className="fadspot-page-item-image-next"
                     style={{display: visibleTooltipAction || visibleTooltipHeadline ? 'none' : 'flex'}}
                     onClick={() => {
                       carouselRef.current.next();
                       setPlaying(false);
                     }}
                >
                  <ArrowSelectDown />
                </div>
            }
            <div className="fadspot-page-item-image-counter">
              {currentSlide + 1}/{[...Object.values(data?.publications)]?.length}
            </div>
            <Carousel ref={carouselRef}
                      beforeChange={(current, next) => setCurrentSlide(next)}
                      infinite={false}
            >
              {
                [...Object.values(data?.publications)].map((el, index) => {
                  return (
                    <div key={el?.thumbnail + index}
                         style={{display: 'flex', flexDirection: 'column'}}
                    >
                      <div className="fadspot-page-item-image-wrapper">
                        {
                          el?.creative === 'video' ?
                            playing && currentSlide === index ?
                              <ReactPlayer controls={true}
                                           width={'100%'}
                                           height={'100%'}
                                           playing={true}
                                           url={el?.thumbnail}
                              />
                              :
                              <img src={el?.preview}
                                   alt=""
                                   onError={utils.addDefaultSrc}
                              />
                            :
                            el?.thumbnail ?
                              <img src={el?.thumbnail}
                                   alt=""
                                   onError={utils.addDefaultSrc}
                              />
                              :
                              <Image src={Images.defaultImg} small={false}/>
                        }
                        {
                          (el?.creative === 'video' && !playing) ? (
                              <div className={'fadspot-page-item-image-play'} onClick={() => setPlaying(prev => !prev)}>
                                <Icon type={'ad_spot_play'} role={'button'}/>
                              </div>
                            )
                            :
                            null
                        }
                      </div>
                      <div className="fadspot-page-item-action"
                           style={{position: visibleTooltipAction || visibleTooltipHeadline ? 'relative' : 'static'}}
                      >
                        <div className="fadspot-page-item-action-text">
                          {
                            el?.domain ?
                              <span className="fadspot-page-item-action-domain"
                                    onClick={() => window.open(el?.domain_url, '_blank')}
                              >
                                {el?.domain}
                              </span>
                              :
                              null
                          }
                          {
                            el?.ad_headline ?
                              <span className="fadspot-page-item-action-headline"
                                    data-text={el?.ad_headline}
                                    onMouseOver={mouseOverHeadline}
                                    onMouseLeave={mouseLeaveHeadline}
                              >
                                {el?.ad_headline}
                              </span>
                              :
                              null
                          }
                          {
                            el?.ad_description && !el?.ad_description?.includes('people likes this') ?
                              <span className="fadspot-page-item-action-description"
                                    data-text={el?.ad_description}
                                    onMouseOver={mouseOverAction}
                                    onMouseLeave={mouseLeaveAction}
                              >
                                {el?.ad_description}
                              </span>
                              :
                              null
                          }
                        </div>
                        <div className="fadspot-page-item-action-btn"
                             onClick={() => window.open(el?.call_to_action_url, '_blank')}
                        >
                          {el?.call_to_action}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Carousel>
          </div>
          :
          <div className="fadspot-page-item-action-wrapper">
            <div className="fadspot-page-item-image-wrapper">
              {
                data?.video_preview?.length ?
                  playing ?
                    <ReactPlayer controls={true}
                                 width={'100%'}
                                 height={'100%'}
                                 playing={playing}
                                 url={data?.thumbnail?.[0]}
                    />
                    :
                    <img src={data?.video_preview?.[0]}
                         alt=""
                         onError={utils.addDefaultSrc}
                    />
                  :
                  data?.thumbnail?.length ?
                    <img src={data?.thumbnail?.[0]}
                         alt=""
                         onError={utils.addDefaultSrc}
                    />
                    : <Image src={Images.defaultImg} small={false}/>
              }
              {
                (data?.video_preview?.length && !playing) ? (
                    <div className={'fadspot-page-item-image-play'} onClick={() => setPlaying(prev => !prev)}>
                      <Icon type={'ad_spot_play'} role={'button'}/>
                    </div>
                  )
                  :
                  null
              }
            </div>
            {
              <div className="fadspot-page-item-action"
                   style={{position: visibleTooltipAction || visibleTooltipHeadline ? 'relative' : 'static'}}
              >
                <div className="fadspot-page-item-action-text">
                  {
                    data?.domain ?
                      <span className="fadspot-page-item-action-domain"
                            onClick={() => window.open(data?.domain_url, '_blank')}
                      >
                      {data?.domain}
                    </span>
                      :
                      null
                  }
                  {
                    data?.ad_headline ?
                      <span className="fadspot-page-item-action-headline"
                            data-text={data?.ad_headline}
                            onMouseOver={mouseOverHeadline}
                            onMouseLeave={mouseLeaveHeadline}
                      >
                      {data?.ad_headline}
                    </span>
                      :
                      null
                  }
                  {
                    data?.ad_description && !data?.ad_description?.includes('people likes this') ?
                      <span className="fadspot-page-item-action-description"
                            data-text={data?.ad_description}
                            onMouseOver={mouseOverAction}
                            onMouseLeave={mouseLeaveAction}
                      >
                      {data?.ad_description}
                    </span>
                      :
                      null
                  }
                </div>
                {
                  data?.call_to_action ?
                    <div className="fadspot-page-item-action-btn"
                         onClick={() => window.open(data?.call_to_action_url, '_blank')}
                    >
                      {data?.call_to_action}
                    </div>
                    :
                    null
                }
              </div>
            }
          </div>
      }

      <div className="fadspot-page-item-emoji-wrapper">
        <div className="fadspot-page-item-emoji">
          {emojiHandler()}
          <span>
            { utils.convertNumberFb(data?.total_reactions_count, true) }
          </span>
        </div>
        <div className="fadspot-page-item-emoji-actions">
          <Tooltip placement="top"
                   title={utils.convertNumberFb(data?.comments_count || 0, true)}
                   destroyTooltipOnHide={true}
          >
            <span>
              <Icon type={'ad_spot_comments'} role={'icon'}/>
            </span>
          </Tooltip>

          <Tooltip placement="top"
                   title={utils.convertNumberFb(data?.shares_count || 0, true)}
                   destroyTooltipOnHide={true}
          >
            <span>
              <Icon type={'ad_spot_repost'} role={'icon'} />
            </span>
          </Tooltip>
          {
            data?.video_preview?.length || true ?
              <Tooltip placement="top"
                       title={utils.convertNumberFb(data?.views_count?.[0] || 0, true)}
                       destroyTooltipOnHide={true}
              >
                <span>
                  <Icon type={'ad_spot_views'} role={'icon'} />
                </span>
              </Tooltip>
              :
              null
          }
        </div>
      </div>
    </PostWrapper>
  );
};

export default AdItem;
