import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import cls from 'classname';
import {Row, Col, Divider, Switch, Spin, Modal, Collapse} from 'antd';
import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultMsgSaga} from '../../../Components/Notification/notification-message';
import openNotification, {openNotificationWithIcon} from '../../../Components/Notification/index';
import FiltersBlockSkeleton from './FiltersBlockSkeleton';
import {ArrowSelectDown} from "../../../Icon/img";
import LoadFilterPresetBlock from "../../ProductDatabasePage/components/LoadFilterPresetBlock";
import DeleteFilterPresetBlock from "../../ProductDatabasePage/components/DeleteFilterPresetBlock";
import SaveFilterPresetBlock from "../../ProductDatabasePage/components/SaveFilterPresetBlock";
import InputProductFilter from "../../ProductDatabasePage/components/InputProductFilter";
import MinMaxInput from "../../ProductDatabasePage/components/MinMaxInput";
import DropdownDateBlock from "../../ProductDatabasePage/components/DropdownDateBlock";
import DropdownBlockTest from "../../ProductDatabasePage/components/DropdownBlockTest";
import Creators from "../../ProductDatabasePage/reducer";
import acc from "accounting";
import dayjs from "dayjs";
import './FiltersBlock.less';
const {Panel} = Collapse;

const minMaxInputsData = [
  {
    field: 'price',
    iconType: 'database_price',
    text: 'Price',
    tooltip: 'The price products are sold for in USD'
  }, {
    field: 'images',
    iconType: 'database_product_images',
    text: 'Product Images',
    tooltip: 'Amount of images the product listing has'
  }, {
    field: 'products',
    iconType: 'database_store_products',
    text: 'Store Products',
    tooltip: 'Amount of product listings in the store'
  }, {
    field: 'variants',
    iconType: 'database_variants',
    text: 'Variants',
    tooltip: 'Amount of variants the product listing has'
  }];

const switchFilterOptions = [
  {text: 'Exclude Unavailable Products', key: 'exclude_unavailable', disabled: false},
];

const keyWords = ['Title', 'Description', 'Domain'];

const dateOptions = [
  {id: 0, name: 'Last 7 days'},
  {id: 1, name: 'Last 30 days'},
  {id: 2, name: 'Last 90 days'},
  {id: 3, name: 'Last 6 months'},
  {id: 4, name: 'Last 12 months'},
];

export const FiltersBlock = (
  {
    isAdmin,
    fetching,
    skeleton,
    isMobile,
    theme,
    savePreset,
    deletePreset,
    updatePreset,
    getProducts,
    showTable,
    setShowTable,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    isChanged,
    setIsChanged,
    setEnabled,
    disabledSearch,
    isVisibleLimitRequest,
    setVisibleTutorial,
    currentScroll,
    productsLoading
  }) => {

  const currencyOptions = useSelector(store => store.competitors.filters.store_currency) || [];
  const domainOptions = useSelector(store => store.competitors.filters.domain_tld) || [];
  const languageOptions = useSelector(store => store.competitors.filters.store_language) || [];
  const competitor = useSelector(store => store.productDatabase.competitor);
  const count = useSelector(store => store.competitors.count);
  const dispatch = useDispatch();

  const [switchFilters, setSwitchFilters] = useState({
    exclude_top_brands: false,
    exclude_unavailable: true,
  });
  const [inputFilters, setInputFilters] = useState({
    title: {include: null, exclude: null},
    description: {include: null, exclude: null},
    domain: {include: null, exclude: null},
  });
  const [minMaxFilters, setMinMaxFilters] = useState({
    price: {min: null, max: null},
    sales: {min: null, max: null},
    revenue: {min: null, max: null},
    products: {min: null, max: null},
    images: {min: null, max: null},
    variants: {min: null, max: null},
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    product_created_at: { min: null, max: null, id: null },
    store_created_at: { min: null, max: null, id: null },
    language: 'All',
    currency: 'All',
    domain_tld: 'All',
  });

  //modal show/hide
  const [visible, setVisible] = useState(false);
  //advanced filters show/hide
  const [showAdvanced, setShowAdvanced] = useState(false);
  //set modal type
  const [modal, setModal] = useState('');
  //preset to delete, show proper notification
  const [recordToDelete, setRecordToDelete] = useState(null);
  //loaded preset
  const [loadedPreset, setLoadedPreset] = useState(null);

  //first loading to avoid search on render
  const firstLoading = useRef(true);
  const {t} = useTranslation();

  //if filters were changed
  const checkIfChanged = () => {
    if (
      Object.keys(switchFilters).some((key) => {
        if (key === 'exclude_unavailable') return switchFilters[key] !== true;
        return switchFilters[key] !== false;
      }) ||
      Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null) ||
      Object.keys(minMaxFilters).some((key) => minMaxFilters[key].min !== null || minMaxFilters[key].max !== null) ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'store_created_at' || key === 'product_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return dropdownFilters[key] !== 'All';
      })
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(false);
  };

  //function to parse preset period dynamically
  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return {min: min, max: max}
  }

  //competitors research search api call
  const applyFilters = (new_search=false) => {
    if ((disabledSearch && !isAdmin) || !Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null)) return

    getProducts({
      page: pageNumber,
      page_size: pageSize,
      ordering: [sortOrder],
      new_search: new_search,
      filters: {
        ...switchFilters,
        ...inputFilters,
        ...minMaxFilters,
        product_created_at: dropdownFilters?.product_created_at?.id ? parseDates('product_created_at') : {min: dropdownFilters?.product_created_at?.min, max: dropdownFilters?.product_created_at?.max},
        store_created_at: dropdownFilters?.store_created_at?.id ? parseDates('store_created_at') : {min: dropdownFilters?.store_created_at?.min, max: dropdownFilters?.store_created_at?.max},
        language: dropdownFilters.language === 'All' ? null : dropdownFilters.language,
        currency: dropdownFilters.currency === 'All' ? null : dropdownFilters.currency,
        domain_tld: dropdownFilters.domain_tld === 'All' ? null : dropdownFilters.domain_tld,
      },
    });
    //hide banner -> show table
    setShowTable(true);
    setEnabled(true);
    currentScroll.current = null;
  };

  //search when pagination/loaded preset changed except 1st render
  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      applyFilters();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      if (loadedPreset !== null) {
        applyFilters(true);
        setLoadedPreset(null);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  //check if changed to enable/disable search btn
  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [switchFilters, minMaxFilters, inputFilters, dropdownFilters]);

  useEffect(() => {
    if (competitor) {
      setInputFilters(prev => ({
        ...prev,
        title: {
          include: competitor, exclude: null
        }
      }))
    }
  }, [competitor])

  const competitorsPage = document.querySelector('.competitors-page');

  useEffect(() => {
    if (competitorsPage) {
      if (!showTable) {
        if (showAdvanced) competitorsPage.style.height = 'fit-content';
        else competitorsPage.style.height = 'unset';
      } else competitorsPage.style.height = 'unset';
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [showAdvanced, showTable])

  const renderHeader = (
    <>
      <span className="title">
        {t('Search For Competitors')}
      </span>

      {
        isAdmin
          ? (
            <div
              className={cls('page-top-line-wrapper', {
                'page-top-line-wrapper_admin': true,
              })}
              style={{marginLeft: 90}}
            >
              <div className="status-count-block">
                  <span className="status-count-block-item">
                    Domains:{' '}
                    {count?.shops_count
                      ? acc.formatNumber(count?.shops_count, 0, ',', ',')
                      : 0}
                  </span>
                <span className="status-count-block-item" style={{marginLeft: 10}}>
                    Total products:{' '}
                  {count?.products_count
                    ? acc.formatNumber(count?.products_count, 0, ',', ',')
                    : 0}
                  </span>
              </div>
            </div>)
          : null
      }

      <div className="link-block">
        <span className={cls('link', {disabled: false})}
              onClick={() => {
                setModal('loadFilterPreset');
                setVisible(true);
              }}
        >
        {t('Load Filter Preset')}
        </span>

        <span className={cls('link', {
          disabled: !isChanged,
        })}
              onClick={() => {
                setVisible(true);
                setModal('saveFilterPreset');
              }}
        >
        {t('Save Filter Preset')}
      </span>
      </div>
    </>);

  //set preset values to filters
  const loadPreset = (data) => {
    if (disabledSearch) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error'
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach(val => {
        if (val === 'switchFilters') setSwitchFilters(state => ({...state, ...data.value[val]}));
        if (val === 'inputFilters') setInputFilters(state => ({...state, ...data.value[val]}));
        if (val === 'minMaxFilters') setMinMaxFilters(state => ({...state, ...data.value[val]}));
        if (val === 'dropdownFilters') setDropdownFilters(state => ({...state, ...data.value[val]}));
      });
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                preset_load_name_
              </span>
            }
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    }
  };

  //modal types
  const modalBlocks = {
    loadFilterPreset: <LoadFilterPresetBlock fetching={fetching}
                                             theme={theme}
                                             setVisible={setVisible}
                                             setRecordToDelete={setRecordToDelete}
                                             setModal={setModal}
                                             setPageSize={setPageSize}
                                             setPageNumber={setPageNumber}
                                             updatePreset={updatePreset}
                                             loadPreset={loadPreset}
                                             setLoadedPreset={setLoadedPreset}
                                             setSortOrder={setSortOrder}
                                             category={'competitors'}
    />,
    deleteFilterPreset: <DeleteFilterPresetBlock setModal={setModal}
                                                 deleteFilter={deletePreset}
                                                 record={recordToDelete}
    />,
    saveFilterPreset: <SaveFilterPresetBlock setVisible={setVisible}
                                             saveFilterPreset={savePreset}
                                             filters={{
                                               switchFilters: {...switchFilters},
                                               inputFilters: {...inputFilters},
                                               minMaxFilters: {...minMaxFilters},
                                               dropdownFilters: {...dropdownFilters},
                                             }}
                                             category={'competitors'}
    />,
  };

  const modalBlocksWidth = {
    loadFilterPreset: 800,
    deleteFilterPreset: 512,
    saveFilterPreset: 566,
  };

  //reset competitor selected in product database
  const setCompetitor = value => dispatch(Creators.setCompetitorResearch(value))

  //reset filters and pagination and competitor
  const resetFilters = () => {
    Object.keys(switchFilters).forEach((key) => {
      if (key === 'exclude_unavailable') setSwitchFilters(state => ({...state, [key]: true}));
      else setSwitchFilters(state => ({...state, [key]: false}));
    });
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({
      ...state,
      [key]: {include: null, exclude: null}
    })));
    Object.keys(minMaxFilters).forEach((key) => setMinMaxFilters(state => ({...state, [key]: {min: null, max: null}})));
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'product_created_at' || key === 'store_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({...state, [key]: 'All'}));
    });
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setCompetitor(false)
  };

  //switch filters hanndler
  const onChangeSwitch = (value, name) => {
    setSwitchFilters(state => ({...state, [name]: value}));
  };

  //tags input include/exclude handler
  const onChangeInputFilter = (e, field, type) => {
    e.persist();
    setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? e.target.value.split(',')[0].trim() + ',' : state[field][type] + ',' + e.target.value.split(',')[0].trim() } }));
  };

  const onPasteInputFilter = (e, field, type) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? val?.[i].trim() + ',' : [...new Set([...state[field][type].split(','), val?.[i].trim()])].join(',') } }));
    }
  };

  const onChangeMinMaxFilter = useCallback((e, field, type) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setMinMaxFilters(state => ({
        ...state,
        [field]: {...state[field], [type]: e.target.value.trim().length ? +parsedValue : null}
      }));
    }
  }, []);

  const handleSearch =(new_search=false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
    }
  }

  if (skeleton) return <FiltersBlockSkeleton isMobile={isMobile}/>;
  return (
    <>
      <ProductDetailsCard header={renderHeader}
                          skeleton={skeleton}
                          withLine={!isMobile}
                          headerClassName={'filters-header'}
                          bodyClassName={'filters-body'}
      >
        <Row className="product-main-block_wrapper" gutter={[48, 0]}>
          <Col xs={24} md={12} style={{paddingRight: isMobile ? 24 : 12}}>
            <div className="input-filter-wrapper">
              {keyWords.map(el =>
                <InputProductFilter key={'include_' + el.toLowerCase()}
                                    el={el}
                                    onChangeInputFilter={onChangeInputFilter}
                                    onPasteInputFilter={onPasteInputFilter}
                                    inputFilters={inputFilters}
                                    setInputFilters={setInputFilters}
                                    type={'include'}
                                    setCompetitor={setCompetitor}
                />,
              )}
            </div>
          </Col>

          <Col xs={24} md={12} style={{paddingLeft: isMobile ? 24 : 12, marginTop: isMobile ? 12 : 0}}>
            <div className="input-filter-wrapper">
              {keyWords.map(el =>
                <InputProductFilter key={'exclude_' + el.toLowerCase()}
                                    el={el}
                                    onChangeInputFilter={onChangeInputFilter}
                                    onPasteInputFilter={onPasteInputFilter}
                                    inputFilters={inputFilters}
                                    setInputFilters={setInputFilters}
                                    type={'exclude'}
                                    setCompetitor={setCompetitor}
                />,
              )}
            </div>
          </Col>


          <Collapse collapsible={'header'} ghost={true}>
            <Panel key={1} showArrow={false} header={
              <div className="advanced-filters-toggle-wrapper" onClick={() => setShowAdvanced(!showAdvanced)}>
                <div className="advanced-filters-toggle-divider"/>
                <span className={cls('advanced-filters-toggle')}
                >{t('Advanced Filters')} <ArrowSelectDown/> </span>
                <div className="advanced-filters-toggle-divider"/>
              </div>
            }>
              <div className={cls("advanced-options-wrapper")}
              >
                <div className={'advanced-min-max'} gutter={[48, 0]}>
                  <Col xs={24} md={24} style={{maxWidth: isMobile ? '100%' : '50%', paddingRight: 24}} className={'custom-competitor-filter-block'}>
                    {
                      minMaxInputsData
                        .map((el, index) => index % 2 === 0 &&
                          (
                            <MinMaxInput data={minMaxFilters}
                                         field={el.field}
                                         iconType={el.iconType}
                                         text={el.text}
                                         key={el.iconType}
                                         tooltip={el.tooltip}
                                         onChange={onChangeMinMaxFilter}
                                         theme={theme}
                            />)
                        )
                    }
                  </Col>
                  <Col xs={24} md={24} style={{maxWidth: isMobile ? '100%' : '50%', paddingLeft: 24, marginTop: isMobile ? 12 : 0}}>
                    {
                      minMaxInputsData
                        .map((el, index) => index % 2 !== 0 && (
                          <MinMaxInput data={minMaxFilters}
                                       field={el.field}
                                       iconType={el.iconType}
                                       text={el.text}
                                       tooltip={el.tooltip}
                                       key={el.iconType}
                                       onChange={onChangeMinMaxFilter}
                                       theme={theme}
                          />)

                        )
                    }
                  </Col>
                </div>
                <Divider style={{margin: isMobile ? '12px auto' : '24px auto', minWidth: 'unset', width: 'calc(100% - 48px)'}}/>

                <Col span={24} className="selects-wrapper">

                  <DropdownDateBlock
                    label={'Product Creation Date'}
                    id={'product_created_at'}
                    iconType={'database_calendar'}
                    data={dateOptions}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    hideTimeZone={true}
                  />

                  <DropdownDateBlock
                    label={'Store Creation Date'}
                    id={'store_created_at'}
                    iconType={'database_calendar'}
                    data={dateOptions}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    hideTimeZone={true}
                  />

                  <DropdownBlockTest
                    label={'Store Language'}
                    id={'language'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_globe'}
                    searchPlaceholder={'Search for language...'}
                    data={languageOptions}
                  />

                  <DropdownBlockTest
                    label={'Store Currency'}
                    id={'currency'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_currency'}
                    searchPlaceholder={'Search for currency...'}
                    data={currencyOptions}
                  />

                  <DropdownBlockTest
                    label={'Domain TLD'}
                    id={'domain_tld'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_domain'}
                    searchPlaceholder={'Search for TLD...'}
                    data={domainOptions}
                  />
                </Col>
              </div>

            </Panel>

          </Collapse>

          <Divider style={{margin: isMobile ? '0 auto 12px' : '0 auto 24px', minWidth: 'auto', width: isMobile ? 'calc(100% - 48px)' : '100%'}}/>

          <Col xs={24} md={24}>
            <div className="switch-wrapper">
              <div className="switch-options-wrapper">
                {switchFilterOptions.map(el =>
                  <div key={el.key + 1} className="switch-option">
                    <Switch checked={switchFilters[el.key]}
                            name={el.key}
                            key={el.key}
                            onChange={(state) => onChangeSwitch(state, el.key)}
                            disabled={el.disabled}
                    />
                    <span onClick={() => onChangeSwitch(!switchFilters[el.key], el.key)}
                          style={{cursor: 'pointer', pointerEvents: el.disabled ? 'none': 'unset'}}
                    >
                      {t(el.text)}
                    </span>
                  </div>)}
              </div>
              <div className="switch-button-wrapper">
                <ButtonComponent className={'switch-button-reset'}
                                 text={t('Reset Filter')}
                                 onClick={resetFilters}
                />
                <ButtonComponent className={'switch-button-apply switch-button-reset'}
                                 text={t('Search')}
                                 onClick={() => handleSearch(true)}
                                 disabled={ (disabledSearch && !isAdmin) || !Object.keys(inputFilters)
                                     .some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null)
                                   || productsLoading
                                 }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          className="change-modal custom-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? {transitionName: ''} : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => setVisible(false)}
          destroyOnClose
        >
          <Spin size="large" spinning={fetching}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>

      </ProductDetailsCard>
    </>
  );
};

export default FiltersBlock;
