import React from 'react';
import {useNavigate} from "react-router-dom";
import {formatDuration} from "../../../Utils/utils";
import Icon from "../../../Icon";
import './UniversityVideoCard.less';

const UniversityVideoCard = (
  {
    info,
    chapter,
    setSelected,
    setIsShort
  }
) => {

  const {id, title, description, duration, thumbnail, completed, chapter_id} = info;

  const navigate = useNavigate();

  const clickHandler = () => {
    setSelected(chapter);
    setIsShort(true);
    navigate(`/dropship-university/${chapter?.id}/${chapter_id}/${id}`, {
      state: {
        name: title
      }
    })
  }

  return (
    <div className={'university-video-card-wrapper'}
         onClick={clickHandler}
    >
        <div className="university-video-card-image"
             style={{backgroundImage: `url("${thumbnail}")`}}
        />
      <div className="university-video-card-info">
        <div className="university-video-card-status">
            {
              completed
                ? <><Icon role={'icon'} type={'university_watched'}/><span>Completed</span></>
                : <><Icon role={'icon'} type={'clock'} /><span>{formatDuration(duration)}</span></>
            }
        </div>
        <h4 className="university-video-card-title">
          {title}
        </h4>
        <p className="university-video-card-description">
          {description}
        </p>
      </div>
    </div>
  );
};

export default UniversityVideoCard;
