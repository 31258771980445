import React from 'react';
import './MoneyBack.less';

const MoneyBack = () => {
  return (
    <div className={'moneyback-wrapper'}>
      <div className={'moneyback-circle'}>
        <div className={'moneyback-circle-content'}>
          <span>
            guarantee
          </span>
          <p>
            365 day
          </p>
          <span>
            money back
          </span>
        </div>
      </div>

      <div className={'moneyback-text'}>
        <h4>
          365 Day Money Back Guarantee
        </h4>
        <p>
          If you're not absolutely satisfied with your store, you have 365 days to get a full refund.
        </p>
      </div>

    </div>
  );
};

export default MoneyBack;
