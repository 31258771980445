import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import Icon from '../Icon'
import ButtonComponent from "./Button";
import InputComponent from "./Input";
import './DiscountCard.less'

function DiscountCard({plan, period, onSubmit, error, clearError, discount, clearDiscount, disabled, style, isMobile, isSt=false,  ...props}) {
  const [code, setCode] = useState(null)

  useEffect(() => {
    clearDiscount();
    clearError();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const {t} = useTranslation()

  function handleClearShowError() {
    if (error) clearError()
  }

  function handleClearDiscount() {
    if (discount) clearDiscount()
  }

  function handleChange(event) {
    let target = event.target
    handleClearShowError()
    handleClearDiscount()
    setCode(target.value)
  }

  function onFocusHandler() {
    handleClearShowError()
    handleClearDiscount()
  }

  function handleSubmit() {
    if (props.fetching) return
    if (discount) {
      clearDiscount()
      setCode('')
    } else {
      onSubmit(code)
    }
  }

  return (
    <div className='discount-card' style={style ? {...style} : {}}>

      <div className={`input-block${code ? ' active' : ''}${error || discount? ' with-result' : ''}`}>
        <span className='label'>{(isSt || code) ? t('Discount Code') : t('Code')}</span>

        <div style={{display: 'flex', alignItems: 'center'}}>
          {error ? (
            <Icon
              type={'warning_slim'}
              role={'button'}
              btnType={'discount-tooltip-icon'}
              tooltipProps={{
                trigger: isMobile ? 'click' : 'hover',
                placement: 'topLeft',
              }}
              titleText={t('Make sure the code you have applied is correct, not used before, or has been expired')}
            />
          ) : null}

          {discount ? <Icon type={'check_mark_circle_border'} role={'icon'} /> : null}

          <InputComponent
            className={`input${error ? ' error' : ''}${discount ? ' active' : ''}`}
            name={'discount_code'}
            value={code}
            onChange={handleChange}
            onPressEnter={handleSubmit}
            onFocus={onFocusHandler}
            disabled={disabled}
          />
          <ButtonComponent className='continue' type={'primary'} disabled={!code || disabled} loading={props.fetching} onClick={handleSubmit}>
            {discount ? <Icon type='close' role='icon' color={'#FFFFFF'} width={24} height={24} /> : t('Apply')}
          </ButtonComponent>
        </div>
      </div>

    </div>
  )
}

export default DiscountCard
