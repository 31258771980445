import React from 'react';
import {useTranslation} from "react-i18next";
import {Divider} from "antd";
import ButtonComponent from "../../../Components/Button";

const DeleteTrackingModal = (props) => {
  const {setModal, deleteStore, record, setVisible, flag} = props;
  const { t } = useTranslation();

  return (
    <div className={'load-filter-preset-wrapper delete-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Are you sure?')}
      </div>
      <p className="load-filter-preset-subtitle">
        Are you sure you want to delete this store?
      </p>

      <Divider className={'delete-preset-divider'} />

      <div className="delete-filter-button-wrapper">
        <ButtonComponent className={'delete-preset-button-cancel'}
                         text={t('Cancel')}
                         onClick={() => {
                           setModal('');
                           setVisible(false);
                         }}
        />
        <ButtonComponent className={'delete-preset-button-apply delete-preset-button-cancel'}
                         text={t('Delete')}
                         onClick={() => {
                           //api call to delete
                           deleteStore({record: record?.data, flag: flag})
                           setModal('');
                           setVisible(false);
                         }}
        />
      </div>
    </div>
  );
};

export default DeleteTrackingModal;
