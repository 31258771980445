import React from 'react';
import {useTranslation} from "react-i18next";
import ButtonComponent from "../../../Components/Button";
import utils from "../../../Utils/utils";
import {Divider} from "antd";
import './StoreAppsModal.less';
import Icon from "../../../Icon";
import {useSelector} from "react-redux";

const StoreAppModal = ({name, average_rating, link, icon, review_count}) => {

  const {t} = useTranslation();

  return (
    <div className={'store-app-wrapper'}>
      <div className={'store-app-info'}>
        <div className={'store-app-image'}
        >
          <img src={utils.changeImageSize(icon, 124)}
               alt=""
               onError={utils.addDefaultSrc}
               width="62" height="62"
          />
        </div>
        <div className={'product-info-wrapper'} style={{flex: 1}}>
          <p className={'store-app-name'}>{name}</p>
          <div>
                    <span className={'store-app-rating'} style={{color: '#707ba0' }}>
                      {`${average_rating} Rating`}
                    </span>
            <Divider type={'vertical'} style={{height: 16, margin: '0 8px'}} />
            <span className={'product-variants'}>{`${review_count} Reviews`}</span>
          </div>
        </div>

      </div>

      <ButtonComponent text={t('View App')}
                       onClick={() => window.open(link, '_blank')}
                       className={'store-app-button'}
      />

    </div>
  )

}

const StoreAppsModal = ({data}) => {
  const {t} = useTranslation();

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <div className={'load-filter-preset-wrapper store-apps-modal-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Store Apps')}
      </div>
      <Divider style={{margin: '16px 24px 12px', width: 'calc(100% - 48px)', minWidth: 'unset'}} type={"horizontal"} />
      <div className="store-apps-wrapper">
        {
          data?.length ?
            data.map((el, i) => (
              <StoreAppModal key={i} {...el} />
            ))
            :
            <div className="empty-wrapper">
              <Icon width={64} height={64}
                    role="icon"
                    type="empty_warning"
                    fill={theme === 'dark' ? '#2B3450' : '#C5CCE3'}
              />
              <p>
                {t('Currently, no apps have been identified for this store.')}
              </p>
              <p>
                {t('There may be no apps installed, or please revisit at a later time for potential updates.')}
              </p>
            </div>
        }
      </div>
    </div>
  );
};

export default StoreAppsModal;
